import { Injectable } from '@angular/core';
import { ODataService } from '../../core/services/oData.service';
import { Unit } from './unit.model';

@Injectable({
  providedIn: 'root'
})
export class UnitsService extends ODataService<Unit> {
  public url = 'Unit';
}
