<syslink-title text="{{ 'Stock Move Customer Outgoings' }}"></syslink-title>
<app-stock-move-outgoing-grid
  #grid
  [permissionKey]="'list'"
  [canUpdate]="false"
  (add)="onAddButtonClicked()"
  [contextMenuItems]="contextMenuItems"
  [expand]="expand"
  detailsUrl="/stockmoves/customeroutgoings"
  storageKey="stock-move-customeroutgoings"
  [filter]="filter"
>
</app-stock-move-outgoing-grid>
<app-document-create-modal
  #createModal
  title="Create customer outgoing"
  [thirdFilter]="customerFilter"
  detailsUrl="/thirds/customers/"
  (validate)="onCreateValidated($event)"
  [showSubject]="false"
></app-document-create-modal>
<syslink-confirm-modal
  #confirmModal
  (onValidate)="onConfirmModalValidated($event)"
></syslink-confirm-modal>
<app-stock-move-outgoing-status-select-modal
  #statusModal
  (validate)="onConfirmModalValidated($event)"
></app-stock-move-outgoing-status-select-modal>
