import { Injectable } from '@angular/core';
import { LoadOptions } from 'devextreme/data';
import { PurchaseDocumentHeaderStatusesService } from '../../purchase-documents/purchase-document-header-status/purchase-document-header-statuses.service';
import { PurchaseInvoiceHeaderHeaderStatus } from './purchase-invoice-header-header-status.model';

@Injectable({
  providedIn: 'root'
})
export class PurchaseInvoiceHeaderHeaderStatusesService extends PurchaseDocumentHeaderStatusesService<PurchaseInvoiceHeaderHeaderStatus> {
  public override url: string = 'PurchaseInvoiceHeaderHeaderStatus';
  public override defaultOptions: LoadOptions = {
    expand: ["StatusId"]
  };

  public override format(element: Partial<PurchaseInvoiceHeaderHeaderStatus>): PurchaseInvoiceHeaderHeaderStatus {
    var result: any = {
      ...element,
      HeaderId:element.HeaderId?.Id,
      StatusId:element.StatusId?.Id
    };
    return result;
  }

  public override async getInstance(params?: Partial<PurchaseInvoiceHeaderHeaderStatus>): Promise<PurchaseInvoiceHeaderHeaderStatus> {
    var element = new PurchaseInvoiceHeaderHeaderStatus({
      Date: new Date(),
      ...params
    });
    return element;
  }


}
