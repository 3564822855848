<div class="d-flex flex-column">
    <label *ngIf="name" class="mb-1 dx-editor-underlined dx-texteditor-with-floating-label dx-texteditor-label">{{name | translate}}</label>

    <div
        [style.width]="width"
        [style.borderColor]="borderColor ?? fillColor"
        class="progress-bar-container mb-2"
        role="button"
        (click)="onModeSwitch()"
    >
        <div
            [style.width]="(currentPercentProgress > 100 ? 100 : currentPercentProgress) + '%'"
            [style.backgroundColor]="fillColor"
            [style.color]="textColor"
            [style.fontSize]="height"
            class="text-center"
        >
            {{getDisplayMode()}}
        </div>
    </div>
</div>