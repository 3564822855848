import { Component, Input } from '@angular/core';
import { InputComponent } from '../input/input.component';
import { NotificationsService } from '../../notifications/notifications.service';


@Component({
  selector: 'syslink-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss']
})
export class SwitchComponent extends InputComponent {
  @Input() public trueText: string = '';
  @Input() public falseText: string = '';
  @Input() public trueColor: string = 'success';
  @Input() public falseColor: string = 'danger';
  @Input() public disableMessage: string = 'Switch is disabled';
  @Input() public allowDisableMessage: boolean = false;
  
  @Input() public width: string = '34px';
  
  @Input() public type: SwitchType = 'switch';
  
  public showDisableMessage(){
    NotificationsService.sendErrorMessage(this.disableMessage);
  }
}

export type SwitchType = 'box' | 'switch';
