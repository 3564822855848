import { Entity } from "../../../core/models/entity";
import { AddressType } from "../address-types/address-type";
import { Locality } from "../localities/locality";
import { PostalCode } from "../postal-codes/postal-code";


export class Address extends Entity {
  public Street?: string;
  public Number?: string;
  public Box?: string;
  public PostalCodeId: PostalCode = new PostalCode();
  public LocalityId: Locality = new Locality();
  public FormattedAddress?: string;
  public Lat?: number;
  public Lng?: number;
  public PlaceId?: string;
  public AddressTypeId: AddressType = new AddressType();
  public IsPublic: boolean = false;
}
