import { Injectable } from '@angular/core';
import { LoadOptions } from 'devextreme/data';
import { Periodicity } from '../../../base/periodicities/periodicities/periodicity.model';
import { SaleDocumentsService } from '../../sale-documents/sale-documents/sale-documents.service';
import { SaleContract } from './sale-contract.model';
import { BehaviorSubject } from 'rxjs';
import { ApiService } from '../../../core/api.service';
import { IndexingTypesService } from '../../../base/indexing/indexing-types.service';
import { SaleContractHeaderStatus } from '../sale-contract-header-statuses/sale-contract-header-status.model';
import { ReportsService } from '../../../connectors/reports/reports.service';

@Injectable({
  providedIn: 'root'
})
export class SaleContractsService extends SaleDocumentsService<SaleContract, SaleContractHeaderStatus> {
  public override url: string = 'SaleContractHeader';

  constructor(
    public IndexingTypesService: IndexingTypesService,
    public override apiService: ApiService,
    public override reportsService: ReportsService
  ) {
    super(reportsService);
  }
  public override defaultOptions: LoadOptions = {
    expand: [
      'ThirdId.CommunicationMethods.ThirdId',
      'ThirdId.CommunicationMethods.CommunicationLabelId',
      'ThirdId.CommunicationMethods.CommunicationTypeId',
      'IntermediateThirdId.CommunicationMethods.CommunicationLabelId',
      'IntermediateThirdId.CommunicationMethods.CommunicationTypeId',
      'CurrentStatusLink.StatusId',
      'ResponsibleUserId',
      'Lines($orderby=LineNo;$expand=TaxRateId($expand=NameTranslationId),UnitId,Margin,Discount,Children,SaleDocumentLineDiscountOperations,ParentId, ProductId)',
      'Statuses.StatusId',
      'DocumentDataCollection.ThirdId.ThirdContacts',
      'DocumentDataCollection.ThirdId.Addresses',
      'DocumentDataCollection.ContactId',
      'DocumentDataCollection.AddressId',
      'PeriodicityId',
      'IndexingTypeId',
      'ThirdId.ContactInformations.ThirdId',
      'ThirdId.ContactInformations.ContactTypeId',
      'DocumentRelations'
    ]
  };

  public override async getInstance(contract?: Partial<SaleContract>): Promise<any> {
    let element = {
      Date: new Date(),
      StartPeriodDate: new Date(),
      PeriodicityId: new Periodicity({ Id: 1 }),
      StartPeriodDateOffset: 0,
      NotificationDelay: 3,
      ExtensionDate: new Date(),
      IndexingTypeId: await this.IndexingTypesService.findByID(1),
      ...contract,
    };
    element.LastIndexingDate = element.Date;
    element.NextIndexingDate = element.Deadline;
    return element;
  }

  private _contractSchedulerUpdater = new BehaviorSubject<boolean>(true);
  public contractSchedulerUpdater$ = this._contractSchedulerUpdater.asObservable();
  public updateContractScheduler(): void {
    this._contractSchedulerUpdater.next(true);
  }

  public async getStatistics(filter: any) {
    var filters = this.convertODataFiltersToString(filter);
    if (filters == "") return undefined;
    return this.apiService.sendRequest('/api/SaleContract/getStatistiques/' + filters, 'GET');
  }
}
