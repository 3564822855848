import { Component, Input, ViewChild } from '@angular/core';
import { BaseGridComponent } from 'projects/libraries/syslink-components/src/lib/grid/base-grid/base-grid.component';
import { SyslinkColumn } from 'projects/libraries/syslink-components/src/lib/helpers/SyslinkColumn';
import { GridComponent } from 'projects/libraries/syslink-components/src/public-api';

@Component({
  selector: 'app-product-stock-move-location-grid',
  templateUrl: './product-stock-move-location-grid.component.html',
  styleUrls: ['./product-stock-move-location-grid.component.scss']
})
export class ProductStockMoveLocationGridComponent extends BaseGridComponent<any> {

  @Input() storageKey: string = 'grid-products-stock-move';

  public _items: any[] = [];
  @Input() set items(value: any[]) {
    this._items = [];
    for (let index = 0; index < value.length; index++) {
      var element = value[index];
      element.Id = index + 1;
      this._items = [...this._items, element];
    }
  }
  get items() {
    return this._items;
  }

  @ViewChild('gridComponent') public gridComponent?: GridComponent;

  public columns: SyslinkColumn[] = [
    new SyslinkColumn({ field: "StockLocationName", label: "Name" }),
    new SyslinkColumn({ field: "Quantity", label: "Quantity" }),
  ];

}