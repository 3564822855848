import { Injectable } from '@angular/core';
import { LoadOptions } from 'devextreme/data';
import { SaleDocumentHeaderStatusesService } from '../../sale-documents/sale-document-header-status/sale-document-header-statuses.service';
import { SaleInvoiceHeaderHeaderStatus } from './sale-invoice-header-header-status.model';

@Injectable({
  providedIn: 'root'
})
export class SaleInvoiceHeaderHeaderStatusesService extends SaleDocumentHeaderStatusesService<SaleInvoiceHeaderHeaderStatus> {
  public override url: string = 'SaleInvoiceHeaderHeaderStatus';
  public override defaultOptions: LoadOptions = {
    expand: ["StatusId"]
  };

  public override format(element: Partial<SaleInvoiceHeaderHeaderStatus>): SaleInvoiceHeaderHeaderStatus {
    var result: any = {
      ...element,
      HeaderId:element.HeaderId?.Id,
      StatusId:element.StatusId?.Id
    };
    return result;
  }

  public override async getInstance(params?: Partial<SaleInvoiceHeaderHeaderStatus>): Promise<SaleInvoiceHeaderHeaderStatus> {
    var element = new SaleInvoiceHeaderHeaderStatus({
      Date: new Date(),
      ...params
    });
    return element;
  }


}
