import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { DocumentDatasService } from 'projects/erp-app/src/app/base/documents/document-datas/document-datas.service';
import { DocumentDelay } from 'projects/erp-app/src/app/base/documents/document-delays/document-delay';
import { DocumentStatus } from 'projects/erp-app/src/app/base/documents/document-statuses/document-status.model';
import { DocumentStatusChangedEvent } from 'projects/erp-app/src/app/base/documents/document-statuses/document-statuses.component';
import { ConfigurationsService } from 'projects/erp-app/src/app/base/modules/configurations/configurations.service';
import { ReportsService } from 'projects/erp-app/src/app/connectors/reports/reports.service';
import { ThirdsService } from 'projects/erp-app/src/app/thirds/thirds/thirds.service';
import { ModalComponent, NotificationsService } from 'projects/libraries/syslink-components/src/public-api';
import { SaleDocument } from '../sale-document.model';
import { SaleDocumentsService } from '../sale-documents.service';
import { SaleDocumentLine } from '../../sale-document-lines/sale-document-line.model';
import { SaleDocumentLinesService } from '../../sale-document-lines/sale-document-lines.service';
import { SaleDocumentStatus } from '../../sale-document-statuses/sale-document-status.model';
import { SaleDocumentHeaderStatus } from '../../sale-document-header-status/sale-document-header-status.model';
import { SaleDocumentHeaderStatusesService } from '../../sale-document-header-status/sale-document-header-statuses.service';
import { SaleDocumentStatusesService } from '../../sale-document-statuses/sale-document-statuses.service';
import { DocumentData } from 'projects/erp-app/src/app/base/documents/document-datas/document-data.model';
import { SaleInvoicesService } from '../../../sale-invoices/sale-invoices/sale-invoices.service';
import { SaleInvoice } from '../../../sale-invoices/sale-invoices/sale-invoice.model';
import { SaleContractsService } from '../../../sale-contracts/sale-contracts/sale-contracts.service';
import { getNew } from 'projects/libraries/syslink-components/src/lib/helpers/tools';
import { MailTemplateTypesService } from 'projects/erp-app/src/app/mails/mail-template-types/mail-template-types.service';
import { DocumentDetailsComponent } from 'projects/erp-app/src/app/base/documents/documents/document-details/document-details.component';
import { DocumentsService } from 'projects/erp-app/src/app/base/documents/documents/documents.service';
import { DocumentRelationsComponent } from 'projects/erp-app/src/app/base/documents/documents/document-relations/document-relations.component';

@Component({
  selector: 'app-sale-document-details',
  templateUrl: './sale-document-details.component.html',
  styleUrls: ['./sale-document-details.component.scss']
})
export class SaleDocumentDetailsComponent<
  TSaleDocument extends SaleDocument,
  TSaleDocumentService extends SaleDocumentsService<TSaleDocument, TSaleDocumentStatus>,
  TSaleDocumentLine extends SaleDocumentLine,
  TSaleDocumentLineService extends SaleDocumentLinesService<SaleDocumentLine>,
  TSaleDocumentStatus extends SaleDocumentStatus,
  TSaleDocumentStatusesService extends SaleDocumentStatusesService<TSaleDocumentStatus>,
  TSaleDocumentHeaderStatus extends SaleDocumentHeaderStatus,
  TSaleDocumentHeaderStatusesService extends SaleDocumentHeaderStatusesService<TSaleDocumentHeaderStatus>,
> extends DocumentDetailsComponent {

  public override element: TSaleDocument = <TSaleDocument>getNew<TSaleDocument>();
  public override documentUrlController: string = "SaleDocument";

  public saleDocumentService: TSaleDocumentService = <TSaleDocumentService>getNew<TSaleDocumentService>();
  public saleDocumentLinesService: TSaleDocumentLineService = <TSaleDocumentLineService>getNew<TSaleDocumentLineService>();
  public saleDocumentStatusesService: TSaleDocumentStatusesService = <TSaleDocumentStatusesService>getNew<TSaleDocumentStatusesService>();
  public saleDocumentHeaderStatusesService: TSaleDocumentHeaderStatusesService = <TSaleDocumentHeaderStatusesService>getNew<TSaleDocumentHeaderStatusesService>();

  public availableStatuses: TSaleDocumentStatus[] = [];

  public showExtensionDate: boolean = true;

  @ViewChild('createFromContractModal') public createFromContractModal: ModalComponent = new ModalComponent;
  @ViewChild('addInvoiceFromContractModal') public addInvoiceFromContractModal: ModalComponent = new ModalComponent;
  @ViewChild('saleDocumentRelation') public saleDocumentRelation?: DocumentRelationsComponent;
  @ViewChild('saleDocumentOther') public saleDocumentOther: any;
  @ViewChild('saleDocumentThird') public saleDocumentThird?: any;

  // Contract
  // --------
  public InvoiceFromContract?: SaleInvoice;
  public gridRelationToolbarItems: any[] = [];
  public filterLinkContract: string | string[] = ['ContractId.Id eq null'];

  constructor(
    public override  ngxUiLoaderService: NgxUiLoaderService,
    public override activatedRoute: ActivatedRoute,
    public override reportsService: ReportsService,
    public configurationsService: ConfigurationsService,
    public override documentDatasService: DocumentDatasService,
    public thirdsService: ThirdsService,
    public saleInvoicesService: SaleInvoicesService,
    private saleContractsService: SaleContractsService,
    public override mailTemplateTypesService: MailTemplateTypesService,
    public override documentService: DocumentsService
  ) {
    super(ngxUiLoaderService, reportsService, documentDatasService, documentService, mailTemplateTypesService);
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.ngxUiLoaderService.start();
    this.activatedRoute.data.subscribe(async ({ element }) => {

      this.loadDependencies();

      if (!element.Id) {
        element = await this.initFromParams(element);
        element.ResponsibleUserId = this.authService.user?.ThirdId != undefined ? this.thirdsService.format(this.authService.user.ThirdId) : null;
        element = await this.saleDocumentService?.insert(element);
        this.goToUrl('../' + element.Id);
      }
      else {
        this.element = element;
        this.element.Lines = this.saleDocumentLinesService.formatLines(element.Lines);
        await this.refresh();
      }
    });
  }

  private async initFromParams(element: any) {
    element = this.defaultInitFromParams(element);
    return element;
  }

  public override async refresh() {
    this.updateBreadCrumb(this.element.No);
    this.updateToolbar();
    this.initStatusBar();
    await this.updateDocumentData(this.element);

    // Hack for scheduler
    // TODO: Issue come from ng-content
    setTimeout(() => { this.saleContractsService.updateContractScheduler() }, 2000);
    setTimeout(() => { this.saleContractsService.updateContractScheduler() }, 4000);
    setTimeout(() => { this.saleContractsService.updateContractScheduler() }, 8000);

    if (this.reportType == "SaleInvoice") {
      await this.saleDocumentOther.loadFilterContract();
    }
    this.ngxUiLoaderService.stop();
  }

  private async reloadElement() {
    if (!this.element.Id) return;
    if (!this.saleDocumentService) return;

    const option: any = this.saleDocumentService.defaultOptions;
    const element: TSaleDocument = await this.saleDocumentService.findByID(this.element.Id, option);
    if (element.Lines) {
      this.element.Lines = this.saleDocumentLinesService.formatLines(element.Lines);
    }
    this.element = element;
  }

  // DocumentRelation
  public async reloadDocumentRelation() {
    if (!this.element.Id) return;
    if (!this.saleDocumentService) return;
    const element: TSaleDocument = await this.saleDocumentService.findByID(this.element.Id, { expand: ["DocumentRelations"] });
    if (element.DocumentRelations) {
      this.element.DocumentRelations = element.DocumentRelations;
    }
  }

  // Document Statuses
  // -----------------
  public override initStatusBar() {
    this.saleDocumentStatusesService.load({sort:{selector:"Sequence"}}).then((statuses: DocumentStatus[]) => {
      this.documentStatuses.statuses = statuses;
      this.documentStatuses.documentStatuses = this.element.Statuses || [];
      this.documentStatuses.refreshItems();
    });
  }
  public async onDocumentStatusChanged(event: DocumentStatusChangedEvent) {
    if (!this.authService.hasPermission(this.basePermissionKey + '.ChangeStatus')) {
      NotificationsService.sendErrorMessage("You do not have the required permission!");
      return;
    }

    const link = {
      HeaderId: { Id: this.element.Id },
      StatusId: { Id: event.status.Id },
      Date: new Date(),
    };

    if (event.documentStatus)
      throw "SaleDocumentHeaderStatus already exists";

    const documentStatus = await this.updateDocumentStatus(<TSaleDocumentHeaderStatus>link);
    if (!this.element.Statuses || !documentStatus) {
      throw "SaleDocumentHeaderStatus cannot be created";
    }

    this.element.Statuses.push(documentStatus);
    await this.reloadElement();
    await this.refresh();
  }

  private async updateDocumentStatus(link: TSaleDocumentHeaderStatus): Promise<TSaleDocumentHeaderStatus | undefined> {
    let documentStatus: TSaleDocumentHeaderStatus = await this.saleDocumentHeaderStatusesService.insert(link);
    if (!documentStatus.Id) return;

    return await this.saleDocumentHeaderStatusesService.findByID(documentStatus.Id);
  }
  //-------------------------------------------------------------------------


  // Document Delay
  // --------------
  override async updateDocumentDelay(delay: DocumentDelay) {
    if (this.reportType == 'SaleContract' || this.reportType == 'SaleOrder' || this.reportType == 'SaleContract') return;
    if (!delay.Id) return;

    const deadline = await this.saleDocumentService?.computeDeadline(this.element.Date, delay.Id, this.reportType);
    this.element.Deadline = new Date(deadline);
  }
  //-------------------------------------------------------------------------

  public async onDateChanged() {
    if (!this.authService.hasPermission(this.basePermissionKey + '.update')) {
      NotificationsService.sendErrorMessage("You do not have the required permission!");
      return;
    }
    if (this.reportType == "SaleContract") {
      this.showExtensionDate = false;
      this.element.NextIndexingDate = this.element.Deadline;
      await this.renewalChange();
      this.showExtensionDate = true;
      return;
    }
    if (!this.element.DocumentDelayId) return;
    await this.updateDocumentDelay(this.element.DocumentDelayId);
  }

  public override async update(showMessage: boolean = true) {
    if (!this.authService.hasPermission(this.basePermissionKey + '.update')) {
      NotificationsService.sendErrorMessage("You do not have the required permission!");
      return;
    }

    if (!this.element.Id) return;
    if (!this.saleDocumentService) return;

    await this.saveDocumentData();
    await this.saleDocumentService?.update(this.element.Id, await this.saleDocumentService?.format(this.element));
    let saleDocument = await this.saleDocumentService?.findByID(this.element.Id);
    this.updatePrices(saleDocument);
    await this.reloadDocumentData();
    await this.refresh();
    if (showMessage == true) {
      NotificationsService.sendSuccess("Record updated");
    }

    this.element.DocumentRelations = saleDocument.DocumentRelations;
    this.onElementLineChange()
  }

  // Document data
  // -------------
  public async reloadDocumentData() {
    if (!this.element.Id) return;
    let element = await this.saleDocumentService.findByID(this.element.Id, {
      expand: ['DocumentDataCollection.ThirdId.ThirdContacts',
        'DocumentDataCollection.ThirdId.Addresses',
        'DocumentDataCollection.ContactId', 'DocumentDataCollection.DeliveryAddressId',
        'DocumentDataCollection.AddressId', 'ThirdId', 'IntermediateThirdId']
    });
    this.element.DocumentDataCollection = [];
    this.element.DocumentDataCollection = element.DocumentDataCollection;
    await this.updateDocumentData(element);
  }

  public override async updateDocumentData(saleDocument: TSaleDocument) {
    this.element.ThirdDocumentDataIndex = saleDocument.DocumentDataCollection.findIndex((d: DocumentData) => d.ThirdId.Id == saleDocument.ThirdId.Id);
    this.element.ThirdDocumentData = saleDocument.DocumentDataCollection[this.element.ThirdDocumentDataIndex];
    if (saleDocument.IntermediateThirdId && saleDocument.IntermediateThirdId.Id) {
      this.element.IntermediateThirdIdDocumentDataIndex = saleDocument.DocumentDataCollection.findIndex((d: DocumentData) => d.ThirdId.Id == saleDocument.IntermediateThirdId.Id) ?? -1;
      this.element.IntermediateThirdIdDocumentData = saleDocument.DocumentDataCollection.find((d: DocumentData) => d.ThirdId.Id == saleDocument.IntermediateThirdId.Id) ?? await this.documentDatasService.getInstance();
    }
    else {
      this.element.IntermediateThirdIdDocumentData = await this.documentDatasService.getInstance();
    }
  }

  public async onDeleteConfirmed() {
    if (!this.element?.Id) return
    this.deleteConfirm.close();
    await this.saleDocumentService?.remove(this.element.Id);
    this.router.navigate(['../'], { relativeTo: this.activatedRoute });
    NotificationsService.sendSuccess("Record deleted");
  }

  public async setStatusTransmitted() {
    // Change Status if Sale.Status == Draft
    // -------------------------------------
    if (this.element.CurrentStatusLink?.StatusId?.Code != this.defaultStatusCode) return;
    const transmittedStatusId = this.documentStatuses.statuses.find((e: DocumentStatus) => e.Code == "Quote.Transmitted")?.Id;
    const link = {
      HeaderId: { Id: this.element.Id },
      StatusId: { Id: transmittedStatusId },
      Date: new Date(),
    };
    const documentStatus = await this.updateDocumentStatus(<TSaleDocumentHeaderStatus>link);
    if (!this.element.Statuses || !documentStatus) {
      throw "SaleDocumentHeaderStatus cannot be updated";
    }

    this.element.Statuses.push(documentStatus);
    await this.refresh();
  }

  public async setStatusConfirmed() {
    // Change Status if Sale.Status != Confirmed and != Canceled
    // -------------------------------------
    // if (this.element.headerStatusId != 3 && this.element.headerStatusId != 4) {
    //   await this.createStatusLink(3);
    //   this.element.headerStatusId = 3;
    //   if (!this.element.id) return;
    //   let element: SaleQuoteHeader = await this.saleQuoteService.findByID(this.element.id);
    //   this.element.documentNo = element.documentNo;
    //   this.updateBreadCrumb(element.documentNo);
    //   await this.update();
    // }
  }

  public async createStatusLink(statusID: number) {
    // let statusLink = new SaleQuoteheaderHeaderStatus();
    // statusLink.createdAt = new Date();
    // statusLink.saleQuoteHeaderId = this.element.id;
    // statusLink.saleQuoteHeaderStatusId = statusID;
    // return await this.saleQuoteHeaderHeaderStatutesService.insert(statusLink);
  }

  // Document line
  // -------------
  public async onElementLineChange(line?: TSaleDocumentLine) {
    if (!this.element.Id) return;
    if (!this.saleDocumentService) return;

    // for test
    const element = (await this.saleDocumentService?.findByID(this.element.Id)); // Add select and expand
    if (element.Lines) {
      this.element.Lines = this.saleDocumentLinesService.formatLines(element.Lines);
    }
    if (this.reportType == "SaleInvoice" && this.saleDocumentThird) {
      await this.saleDocumentThird.loadCanChangeThird();
    }
    this.updatePrices(element);
    this.saleDocumentRelation?.loadStock();
    this.reloadDocumentRelation();
   await this.reloadMargin();
  }

  // Margin
  // ------
  public async reloadMargin(){
    if (!this.element.Id || this.reportType == "SaleCreditNote") return;
    const element = (await this.saleDocumentService?.findByID(this.element.Id, { select: ['Id', 'Margin','MarginPercent'] }));
    this.element.Margin = element.Margin;
    this.element.MarginPercent = element.MarginPercent;
  }

  // Payments
  // --------
  public async onPaymentChange(e: any) {
    if (!this.element.Id) return;
    const element = (await this.saleDocumentService?.findByID(this.element.Id, { expand: ['Payments.PaymentStateId', 'Payments.PaymentMethodPrimary', 'Payments.PaymentTypeId'] }));
    this.element.Payments = element.Payments;
    this.updatePrices(element);
  }

  // Contract
  // --------
  addSaleInvoiceFromContract = (e: any) => {
    this.createFromContractModal.open();
  }
  public async addContractToInvoice() {
    if (!this.InvoiceFromContract || !this.InvoiceFromContract.Id) {
      NotificationsService.sendErrorMessage("Invoice cannot be empty");
      return;
    }
    this.InvoiceFromContract.ContractId = this.element;
    this.InvoiceFromContract.DateFrom = this.createFromContractFromDate;
    this.InvoiceFromContract.DateTo = this.createFromContractToDate;
    this.addInvoiceFromContractModal.close();
    await this.saleInvoicesService.update(this.InvoiceFromContract.Id, await this.saleInvoicesService.format(this.InvoiceFromContract));
    NotificationsService.sendSuccess("Linked invoice");
    await this.saleDocumentRelation?.loadData();
  }
  public async renewalChange() {
    await this.update(false);
    await this.reloadRenewalData();
  }
  public async reloadRenewalData() {
    if (!this.element.Id) return;
    let contract = await this.saleContractsService.findByID(this.element.Id, { select: ['Id', 'RenewalDate', 'ExtensionDate', 'RenewalEndDate'] });
    this.element.RenewalDate = contract.RenewalDate;
    this.element.ExtensionDate = contract.ExtensionDate;
    this.element.RenewalEndDate = contract.RenewalEndDate;
  }
}
