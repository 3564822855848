import { Injectable } from '@angular/core';
import { LoadOptions } from 'devextreme/data';
import { ApiService } from '../../../core/api.service';
import { PurchaseDocumentLinesService } from '../../purchase-documents/purchase-document-lines/purchase-document-lines.service';
import { PurchaseCreditNoteLine } from './purchase-credit-note-line.model';

@Injectable({
  providedIn: 'root'
})
export class PurchaseCreditNoteLinesService extends PurchaseDocumentLinesService<PurchaseCreditNoteLine> {
  public override url: string = 'PurchaseCreditNoteLine';
  public override documentType: string = 'PurchaseCreditNote';
  public override defaultOptions: LoadOptions = {
    expand: ['Discount', 'ParentId']
  };

  constructor(public override api: ApiService) {
    super(api);
  }

  public override async getInstance(quote?: Partial<PurchaseCreditNoteLine>): Promise<any> {
    let element = {
      ...quote,
    };
    return element;
  }

  public async updateParentId(purchaseCreditNoteLine: PurchaseCreditNoteLine, targetParentId: number) {
    return await this.api.sendRequest('/api/PurchaseDocumentLine/' + purchaseCreditNoteLine.Id + '/changeParent/' + targetParentId, 'POST');
  }
}
