import { Injectable } from '@angular/core';
import { LoadOptions } from 'devextreme/data';
import { ODataService } from '../../../core/services/oData.service';
import { ModuleInfo } from './module-info.model';

@Injectable({
  providedIn: 'root'
})
export class ModuleInfoService extends ODataService<ModuleInfo> {
  public url = 'ModuleInfo';
  public override defaultOptions: LoadOptions = {
    expand:[]
  };
}
