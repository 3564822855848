import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ApiService } from '../../api.service';

@Component({
  selector: 'app-reportpreview',
  templateUrl: './reportpreview.component.html',
  styleUrls: ['./reportpreview.component.scss']
})
export class ReportpreviewComponent implements OnInit {
  public reportUrl?: SafeResourceUrl;

  constructor(
    private activatedRoute: ActivatedRoute,
    private domSanitizer: DomSanitizer,
    private title: Title,
    private apiService: ApiService,
    private ngxUiLoaderService: NgxUiLoaderService,
  ) { }

  ngOnInit(): void {
    this.ngxUiLoaderService.start();
    this.activatedRoute.queryParams.subscribe(({ url, fileName }) => {
      this.ngxUiLoaderService.start();
      this.title.setTitle(fileName);
      this.apiService.getBlob(url).then(async (blob) => {
        const file = new File([blob], fileName, { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        this.reportUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(fileURL);
        this.ngxUiLoaderService.stop();
      });
    });
  }

  public onReportLoaded(): void {
    this.ngxUiLoaderService.stop();
  }
}