import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SaleDocumentLine } from '../../sale-document-lines/sale-document-line.model';
import { SaleDocumentLinesService } from '../../sale-document-lines/sale-document-lines.service';
import { SaleDocument } from '../sale-document.model';
import { SaleDocumentsService } from '../sale-documents.service';
import { getNew } from 'projects/libraries/syslink-components/src/lib/helpers/tools';
import { SaleDocumentStatus } from '../../sale-document-statuses/sale-document-status.model';

@Component({
  selector: 'app-sale-document-finance',
  templateUrl: './sale-document-finance.component.html',
  styleUrls: ['./sale-document-finance.component.scss']
})
export class SaleDocumentFinanceComponent<
  TSaleDocument extends SaleDocument,
  TSaleDocumentService extends SaleDocumentsService<TSaleDocument,SaleDocumentStatus>,
  TSaleDocumentLine extends SaleDocumentLine,
  TSaleDocumentLineService extends SaleDocumentLinesService<TSaleDocumentLine>
> {
  @Input() public element: TSaleDocument = <TSaleDocument>getNew<TSaleDocument>();
  @Output() public elementChange: EventEmitter<TSaleDocument> = new EventEmitter<TSaleDocument>();
  @Output() public paymentsChange: EventEmitter<any> = new EventEmitter<any>();

  @Input() public reportType: string = '';
  @Input() public subModuleCode: string = '';

  public getStructuredCommunication() {
    return '+++' + this.element.StructuredCommunication + '+++';
  }
}
