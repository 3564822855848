<div class="{{class}}" [hidden]="hidden">
    <dx-number-box
        id="number-{{name}}"
        [name]="name"

        [value]="model"
        (onValueChanged)="modelChange.emit($event.value)"
        (onFocusOut)="focusOut.emit(model)"
        valueChangeEvent="input"

        [stylingMode]="mode"
        [label]="getLabel()"
        [labelMode]="labelMode"

        [readOnly]="disabled"

        [format]="format"
        [min]="min"
        [max]="max"
        [showSpinButtons]="showSpinButtons"
        [step]="step"
        (onKeyDown)="onKeyDown($event)"
    ></dx-number-box>
</div>
