import { Account } from "../../accountings/accounts/account.model";
import { BasicEntity } from "../../core/models/basic-entity";

export class ProductCategory extends BasicEntity {
    public ParentProductCategoryId?:ProductCategory;
    public SaleAccountId:Account = new Account();
    public PurchaseAccountId:Account = new Account();
    

}
