import { StockMoveDocument } from "../../stock-move-documents/stock-move-documents/stock-move-document.model";
import { StockMoveOutgoingHeaderHeaderStatus } from "../stock-move-outgoing-header-header-statuses/stock-move-outgoing-header-header-status.model";

export class StockMoveOutgoing extends StockMoveDocument {
    public override CurrentStatusLink?: StockMoveOutgoingHeaderHeaderStatus;
    public override Statuses: StockMoveOutgoingHeaderHeaderStatus[] = [];

    constructor(entity?: Partial<StockMoveOutgoing>) {
        super();
        Object.assign(this, entity);
    }
}
