import { Injectable } from '@angular/core';
import { ODataService } from '../../../core/services/oData.service';
import { PurchaseDocumentLine } from '../purchase-document-lines/purchase-document-line.model';
import { PurchaseDocumentLineDiscountOperation } from './purchase-document-line-discount-operation.model';

@Injectable({
  providedIn: 'root'
})
export class PurchaseDocumentLineDiscountOperationsService extends ODataService<PurchaseDocumentLineDiscountOperation>{
  public url: string = 'PurchaseDocumentLineDiscountOperation';
 
  constructor() { 
    super();
  }
}
