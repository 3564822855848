import { BasicEntity } from "../../../core/models/basic-entity";
import { Task } from "../tasks/task.model";

export class TaskStatus extends BasicEntity {
    public Color?:string;
    public Sequence?:number;
    public Tasks?:Task[];
    
    constructor(entity?: TaskStatus) {
        super();
        Object.assign(this, entity);
    }
}

export enum TaskStatusCode{
    Draft="Draft",
    InProgress="InProgress"
}
