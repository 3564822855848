import { Component, HostListener, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { AuthInfo, AuthService } from '../../auth/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public userLogin: AuthInfo = new AuthInfo();

  constructor(
    private authService: AuthService
  ) { }

  @HostListener('window:keydown.enter', ['$event'])
  private async handleKeyDown(event: KeyboardEvent) {
    await this.onLoginButtonClicked();
  }

  ngOnInit(): void {
    this.setDefaultUserLoginInfo();
  }

  public async onLoginButtonClicked() {
    await this.authService.login(this.userLogin);
    this.setDefaultUserLoginInfo();
  }

  private setDefaultUserLoginInfo(): void {
    this.userLogin = {
      userName: !environment.production ? 'Admin' : '',
      password: !environment.production ? 'Syslink2022!' : ''
    }
  }
}
