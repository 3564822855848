import { Injectable } from '@angular/core';
import { SaleDocumentStatusesService } from '../../sale-documents/sale-document-statuses/sale-document-statuses.service';
import { SaleOrderHeaderStatus } from './sale-order-header-status.model';

@Injectable({
  providedIn: 'root'
})
export class SaleOrderHeaderStatusesService extends SaleDocumentStatusesService<SaleOrderHeaderStatus> {
  public override url: string = 'SaleOrderHeaderStatus';
 
  constructor() {
    super();
  }
}
