<syslink-link
    url="/"
    class="d-flex justify-content-center"
>
    <img
        src="../../../../assets/alpha.png"
        class="img-fluid"
    />
</syslink-link>
<div class="row">
    <div class="col-2"></div>
    <div class="col-8">
        <syslink-title [text]="'Alpha'"></syslink-title>
        <p>Version : {{moduleInfoSyslink.Version}}</p>
        <p>{{'WebSite' | translate}} : <syslink-link [url]="urlSyslink">{{urlSyslink}}</syslink-link></p>
        <syslink-button
            name="Detail"
            [text]="'Detail'"
            (click)="onDetailClick()"
        ></syslink-button>
        <syslink-title [text]="'DevExpress'"></syslink-title>
        <p>Version : {{moduleInfoDevExpress.Version}}</p>
        <p>{{'WebSite' | translate}} : <a
                href="http://www.devexpress.com"
                target="_blank"
            >{{urlDevExpress}}</a></p>
    </div>
    <div class="col-8"></div>
</div>
<syslink-modal
    #versionDetail
    [noFooter]="true"
    title="Detail"
    width="600px"
    height="80vh"
>
    <app-version-details
        *ngIf="moduleInfoSyslink.Version"
        [currentVersion]="moduleInfoSyslink.Version"
    ></app-version-details>
</syslink-modal>