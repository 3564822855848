<ng-container *ngIf="element">
  <syslink-title
    *ngIf="element.No"
    [text]="element.No"
  ></syslink-title>

  <syslink-toolbar [actions]="toolbarActions"></syslink-toolbar>

  <app-document-statuses
    #documentStatuses
    [documentStatuses]="element.Statuses"
    (statusChanged)="onDocumentStatusChanged($event)"
  ></app-document-statuses>

  <div class="mt-3 mb-5">
    <app-task-form
      #form
      [(element)]="element"
      (updated)="onFormUpdated()"
      (estimatedTimeChange)="reloadTime()"
    ></app-task-form>
  </div>
  <div
    *ngIf="element.Id"
    class="mt-4"
  >
    <syslink-tabs #tabs>
      <!-- <syslink-tab
        key="Planification"
        [visible]="element.IsPlannable"
        label="Plannable"
      >
        <div class="row">
          <div class="col-8">
            <app-appointment-scheduler
              #scheduler
              [canRefresh]="true"
              (add)="onAddAppointmentButtonClicked()"
              [filter]="appointmentFilters"
              (validate)="onValidateAppointmentCreation($event)"
              (selectionChanged)="onSchedulerSelectionChanged($event)"
              [canEditType]="false"
              [canEditTask]="false"
            ></app-appointment-scheduler>
            <app-appointment-modal
              #appointmentModal
              [canEditType]="false"
              [canEditTask]="false"
              (validate)="onValidateAppointmentCreation($event)"
            ></app-appointment-modal>
          </div>
          <div class="col-4">
            <app-appointment-grid
              #appointmenGrid
              [permissionKey]="'schedulings.list'"
              [filter]="appointmentFilters"
              [canRefresh]="false"
              [canDelete]="false"
              (add)="onAddAppointmentButtonClicked()"
              [columns]="appointmentColumns"
              (rowDoubleClicked)="appointmentModal.open($event)"
              storageKey="task-details-appointments"
            ></app-appointment-grid>
          </div>
        </div>
      </syslink-tab> -->
      <syslink-tab
        key="Performance"
        [visible]="authService.hasPermission(this.basePermissionKey + '.worktime.tab.view') && element.IsPerformable"
        label="Performable"
      >
        <app-work-time-grid
          #worktimeGrid
          [permissionKey]="'workTimes.list'"
          [filter]="['TaskId.Id eq ' + element.Id]"
          (add)="onAddWorkTimeButtonClicked()"
          (rowDoubleClicked)="worktimeModal.open($event)"
          (afterDelete)="onDeletedWorkTime($event)"
          [columns]="WorktimeColumns"
        ></app-work-time-grid>

        <app-work-time-modal
          #worktimeModal
          (validate)="onValidateWorkTimeCreation($event)"
          [canEditTask]="false"
        ></app-work-time-modal>
      </syslink-tab>
      <syslink-tab
        key="Billing"
        [visible]="authService.hasPermission(this.basePermissionKey + '.saleInvoice.tab.view') && element.IsBillable"
        label="Billable"
      >
        <app-sale-invoice-grid
          #saleInvoiceGrid
          [permissionKey]="'invoices.list'"
          [filter]="getInvoiceRelationFilter()"
          [canDelete]="false"
          (add)="showInvoiceModal()"
          [columns]="SaleInvoiceColumns"
        ></app-sale-invoice-grid>
      </syslink-tab>
      <syslink-tab
        key="Other"
        label="Other"
        [visible]="authService.hasPermission(this.basePermissionKey + '.other.tab.view')"
      >
        <app-task-other [(element)]="element"></app-task-other>
      </syslink-tab>
    </syslink-tabs>
  </div>
  <syslink-confirm-modal
    #deleteConfirm
    title="Delete"
    content="DeleteConfirmMessage"
    (onValidate)="onDeleteConfirmed()"
    [validateText]="'Confirm' | translate"
    [cancelText]="'Cancel' | translate"
  ></syslink-confirm-modal>
</ng-container>

<app-sale-invoice-select-modal
  #saleInvoiceSelectModal
  (validate)="onConfirmModalValidated($event)"
  (onNewInvoiceButtonClicked)="onConfirmModalValidated($event)"
  [selectFilters]="saleInvoiceSelectModalFilter"
  [expand]="['CurrentStatusLink.StatusId', 'ThirdId']"
></app-sale-invoice-select-modal>

<app-dynamic-price-invoicing
  #dynamicPriceInvoicing
  (onInvoiceCreated)="onInvoiceCreated()"
></app-dynamic-price-invoicing>