import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BreadcrumbItem } from './breadcrumbs.component';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbsService {
  public basePath: string = 'admin';

  public items: BreadcrumbItem[] = [];
  public textSegments: string[] = [];
  private excludedItems: string[] = ['', this.basePath];

  constructor(
    private router: Router
  ) { }

  /**
   * Convert route string param to string[] array
   * 
   * @param {string} route 
   * @returns {string[]}
   */
  public getTextSegments(route: string): string[] {
    const routeWithoutParams: string = this.removeParams(route);

    let segments: string[] = routeWithoutParams.split("/");
    segments = this.removeExcludedSegments(segments);

    return segments;
  };

  /**
   * Remove the segments included inside the list of segments to exclude
   * 
   * @param segments list of all the available segments
   * @returns 
   */
  private removeExcludedSegments(segments: string[]): string[] {
    return segments.filter((segment: string) => !this.excludedItems.includes(segment));
  }

  /**
   * Remove the GET params inside the route
   * 
   * @param route original route including GET params
   * @returns string
   */
  private removeParams(route: string): string {
    return route.substring(0, route.indexOf("?") !== -1 ? route.indexOf("?") : route.length);
  }

  /**
   * Method called when route is changed
   */
  public reloadItems(formatter: Function = this.defaultBreadcrumbItemFormatSegment): void {
    this.textSegments = this.getTextSegments(this.router.url);
    this.items = [];
    this.textSegments.forEach((segment: string, index: number) => this.items.push(formatter(segment, index)));
  }

  private defaultBreadcrumbItemFormatSegment: (segment: string, index: number) => BreadcrumbItem = (segment: string, index: number): BreadcrumbItem => {
    return new BreadcrumbItem({
      text: segment.charAt(0).toUpperCase() + segment.slice(1).toLowerCase(),
      path: index ? this.textSegments.slice(0, index).join('/') + '/' + segment : segment,
    });
  };

  // Visibility
  // ----------
  public hidden: boolean = false;

  public show(): void {
    this.hidden = false;
  }

  public hide(): void {
    this.hidden = true;
  }

  public toggleVisibility(): void {
    this.hidden = !this.hidden;
  }
}
