import { Injectable } from '@angular/core';
import { ApiService } from '../../../core/api.service';
import { ODataService } from '../../../core/services/oData.service';
import { StockMoveDocumentLine } from './stock-move-document-line.model';

@Injectable({
  providedIn: 'root'
})
export class StockMoveDocumentLinesService<T extends StockMoveDocumentLine> extends ODataService<T> {
  public url: string = 'StockMoveDocumentLine';
  public documentType: string = "";

  constructor(
    public api: ApiService
  ) {
    super();
  }

  public async changeLineNo(StockMoveLine: T, targetElementIsBefore: boolean = true) {
    return await this.api.sendRequest('/api/StockMoveDocumentLine/' + StockMoveLine.Id + '/changeLineNo', 'POST', {
      SelectedLine: StockMoveLine.LineNo,
      TargetElementIsBefore: targetElementIsBefore
    }
    );
  }

  public override async insert(data: any): Promise<any> {
    data.DocumentType = this.documentType;
    var result = await this.api.sendRequest('/api/StockMoveDocumentLine/AddDocumentLine', 'POST', data);
    return result;
  }

  // Format
  // ------
  public override format(params?: Partial<T>): T {
    var result: any = {
      ...params,
      HeaderId: params?.HeaderId?.Id,
      ParentId: params?.ParentId != null && params?.ParentId != undefined ? params?.ParentId?.Id : null,
      ProductId: params?.ProductId?.Id,
      UnitId: params?.UnitId?.Id,
      ToStockLocationId: params?.ToStockLocationId?.Id,
      FromStockLocationId: params?.FromStockLocationId?.Id,
    };

    delete result["Children"];
    return result;
  }
}
