import { Injectable } from '@angular/core';
import { LoadOptions } from 'devextreme/data';
import { ODataService } from '../../../core/services/oData.service';
import { Third } from '../../../thirds/thirds/third.model';
import { Document } from '../documents/document.model';
import { DocumentData } from './document-data.model';
import { ApiService } from '../../../core/api.service';

@Injectable({
  providedIn: 'root'
})
export class DocumentDatasService extends ODataService<DocumentData> {
  public url = 'DocumentData';
  public override defaultOptions: LoadOptions = {
    expand: ['ThirdId.ThirdContacts', 'ContactId', 'AddressId', 'ThirdId.Addresses']
  };
  constructor(
    private api: ApiService
  ) {
    super();

  }
  public override format(document: Partial<DocumentData>): any {
    var result: any = {
      ...document,
      ThirdId: document.ThirdId?.Id,
      ContactId: document.ContactId?.Id,
      SaleDocumentHeaderId: document.SaleDocumentHeaderId?.Id,
      PurchaseDocumentHeaderId: document.PurchaseDocumentHeaderId?.Id,
      StockMoveDocumentHeaderId: document.StockMoveDocumentHeaderId?.Id,
      AddressId: document.AddressId?.Id,
      DeliveryAddressId: document.DeliveryAddressId?.Id,
    };
    return result;
  }

  public override async getInstance(data?: DocumentData, type: string = 'StockMove', headerId?: Document, thirdId?: Third): Promise<DocumentData> {
    var addresses = undefined;
    var selectedAddress = undefined;
    var selectedDeliveryAddress = undefined;
    if (thirdId && thirdId.ContactInformations) {
      addresses = thirdId.ContactInformations.filter((a => a.AddressId != undefined));
      var deliveyAddress = thirdId.ContactInformations.filter(a => a.AddressId != undefined && a.UsedForDelivery == true);
      selectedDeliveryAddress = deliveyAddress.length > 1 ? deliveyAddress[0] : undefined;
    }
    if (addresses && addresses?.length == 1) {
      selectedAddress = addresses[0];
    }
    if (addresses && !selectedAddress && addresses?.length > 1 && addresses.filter((a => a.IsFavorite == true)).length > 0) {
      selectedAddress = addresses.filter((a => a.IsFavorite == true))[0];
    }
    if (addresses && !selectedAddress && addresses?.length > 1) {
      selectedAddress = addresses[0];
    }

    return new DocumentData({
      ...data,
      ThirdId: thirdId,
      AddressId: selectedAddress?.AddressId,
      DeliveryAddressId: selectedDeliveryAddress?.AddressId ?? selectedAddress?.AddressId,
      ContactId: thirdId,
      SaleDocumentHeaderId: type.includes("Sale") ? headerId : undefined,
      PurchaseDocumentHeaderId: type.includes("Purchase") ? headerId : undefined,
      StockMoveDocumentHeaderId: type.includes("StockMove") ? headerId : undefined,
      UsedForQuote: true,
      UsedForOrder: true,
      UsedForInvoice: true,
      UsedForContract: true,
    });
  }

  public async updateThird(oldThirdId: number, newThirdId: number) {
    return await this.api.sendRequest("/api/DocumentData/UpdateThird/" + oldThirdId + "/" + newThirdId, 'POST');
  }

}
