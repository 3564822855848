<syslink-toolbar
  *ngIf="toolbarItems.length"
  [actions]="toolbarItems"
></syslink-toolbar>

<dx-scheduler
  #scheduler
  [height]="height"
  (onAppointmentTooltipShowing)="onAppointmentTooltipShowing($event)"
  (onAppointmentClick)="onAppointmentClick($event)"
  (onAppointmentFormOpening)="onAppointmentFormOpening($event)"
  appointmentTemplate="AppointmentCellTemplate"
  [shadeUntilCurrentTime]="true"
  [showAllDayPanel]="false"
  (onCellContextMenu)="onCellContextMenu($event)"
  (onAppointmentContextMenu)="onAppointmentContextMenu($event)"
  (currentViewChange)="onCurrentViewChanged($event)"
>
  <!-- <div *dxTemplate="let model of 'dataCellTemplate'" [ngClass]="{ 'bg-success': true }">
    <div >
    </div>
  </div>

  <div *dxTemplate="let model of 'dateCellTemplate'">
    <div>
      {{ model.text }}
    </div>
  </div>

  <div *dxTemplate="let model of 'timeCellTemplate'">
    <div>
      {{ model.text }}
    </div>
  </div> -->

  <div *dxTemplate="let model of 'AppointmentCellTemplate'">
    <div
      class="title"
      [innerHTML]="fromDotNotation(model.appointmentData, textAppointmentField)"
    >
    </div>
    <div [innerHTML]="
        fromDotNotation(model.appointmentData, descriptionAppointmentField)
      "></div>
  </div>
</dx-scheduler>

<dx-context-menu
  #contextMenu
  [items]="contextMenuItems"
  (onItemClick)="onContextMenuItemClick($event)"
></dx-context-menu>