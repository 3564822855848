import { Entity } from "../../../core/models/entity";
import { DocumentStatus } from "../document-statuses/document-status.model";
import { Document } from "../documents/document.model";

export class DocumentHeaderStatus extends Entity {
    public Date: Date = new Date();
    public StatusId?: DocumentStatus;
    public HeaderId?: Document;

    constructor(entity?: Partial<DocumentHeaderStatus>) {
        super();
        Object.assign(this, entity);
    }
}

export enum DocumentHeaderStatusIds {
    Ventilated = 4
}