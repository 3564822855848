import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { SchedulerComponent, SyslinkToolbarAction } from 'projects/libraries/syslink-components/src/public-api';
import { WorkTimesService } from '../work-times.service';
import { CrudComponent } from 'projects/libraries/syslink-components/src/lib/helpers/crud-component/crud-component.component';
import { ContextMenuItemAction } from 'projects/libraries/syslink-components/src/lib/context-menus/context-menu-item-action';
import { WorkTimeModalComponent } from '../work-time-modal/work-time-modal.component';

@Component({
  selector: 'app-work-time-scheduler',
  templateUrl: './work-time-scheduler.component.html',
  styleUrls: ['./work-time-scheduler.component.scss']
})
export class WorkTimeSchedulerComponent extends CrudComponent {

  @Input() public toolbarActions: SyslinkToolbarAction[] = [];
  @Input() public contextMenuItems: ContextMenuItemAction[] = [];
  @Input() public appointmentContextMenuItems: ContextMenuItemAction[] = [];
  @Input() public override storageKey: string='worktime-scheduler';

  @Output() validate: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectionChanged: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild("scheduler") scheduler?: SchedulerComponent;
  @ViewChild("editModal") editModal?: WorkTimeModalComponent;

  constructor(
    public workTimesService: WorkTimesService,
  ) {
    super();
  }

}
