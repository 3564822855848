import { Injectable } from '@angular/core';
import { LoadOptions } from 'devextreme/data';
import { SaleDocumentsService } from '../../sale-documents/sale-documents/sale-documents.service';
import { SaleCreditNote } from './sale-credit-note.model';
import { SaleCreditNoteHeaderStatus } from '../sale-credit-note-header-statuses/sale-credit-note-header-status.model';

@Injectable({
  providedIn: 'root'
})
export class SaleCreditNotesService extends SaleDocumentsService<SaleCreditNote, SaleCreditNoteHeaderStatus> {
  public override url: string = 'SaleCreditNoteHeader';
  
  public override defaultOptions: LoadOptions = {
    expand: [
      'ThirdId.CustomerId.PaymentMethodId',
      'ThirdId.CommunicationMethods.ThirdId',
      'ThirdId.CommunicationMethods.CommunicationLabelId',
      'ThirdId.CommunicationMethods.CommunicationTypeId',
      'IntermediateThirdId.CommunicationMethods.CommunicationLabelId',
      'IntermediateThirdId.CommunicationMethods.CommunicationTypeId',
      'CurrentStatusLink.StatusId',
      'ResponsibleUserId',
      'DocumentDelayId',
      'OriginId',
      'Lines($orderby=LineNo;$expand=TaxRateId($expand=NameTranslationId),UnitId,Margin,Discount,Children,SaleDocumentLineDiscountOperations,ParentId,TaxId,AccountId, ProductId)',
      'Statuses.StatusId',
      'DocumentDataCollection.ThirdId.ThirdContacts',
      'DocumentDataCollection.ThirdId.Addresses',
      'DocumentDataCollection.ContactId',
      'DocumentDataCollection.AddressId',
      'DocumentDataCollection.ThirdId.CustomerId.SaleCreditNoteDocumentDelayId',
      'SaleCreditNoteHeaders.ThirdId',
      'SaleCreditNoteHeaders.CurrentStatusLink.StatusId',
      'SaleCreditNoteHeaders.ResponsibleUserId',
      'Payments.PaymentMethodPrimary',
      'Payments.ParentId',
      'Payments.PaymentStateId',    
      'Payments.PaymentTypeId',   
      'ContractId',
      'ThirdId.ContactInformations.ThirdId',
      'ThirdId.ContactInformations.ContactTypeId',
      'DocumentRelations'
    ]
  };

  // public getListByThird(thirdId: number) {
  //   return this.sendRequest(this.url + '/' + thirdId + '/salequoteheaderbythird');
  // }
  // public getListByRelation(headerId: number, type:string) {
  //   return this.sendRequest('/api/salequoterelation/' + headerId + '/type/'+type);
  // }
  // public getVersionByHeaderId(headerId: number) {
  //   return this.sendRequest(this.url + '/' + headerId + '/listheaderid');
  // }
  // public getLineByHeaderId(headerId: number) {
  //   return this.sendRequest(this.url + '/' + headerId + '/listline');
  // }
  // public createCreditNote(header: SaleCreditNoteHeader) {
  //   return this.sendRequest(this.url + '/cloneToCreditNote', 'POST', header);
  // }
  // public createCreditNote(header: SaleCreditNoteHeader) {
  //   return this.sendRequest(this.url + '/cloneToCreditNote', 'POST', header);
  // }

  public override async getInstance(quote?: Partial<SaleCreditNote>): Promise<any> {

    let element = {
      Date: new Date(),
      ...quote,
    };

    element = await this.refreshResponsibleUser(element);
    element = await this.refreshThirdDocumentDelayFromCustomer(element);

    return element;
  }
}
