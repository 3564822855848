<div class="row mb-3">
    <!-- <div class="col-3">
        <syslink-input-text
            name="SupplierReference"
            label="Supplier reference"
            [(model)]="element.ManufacturerReference"
        ></syslink-input-text>
    </div> -->
    <!-- <div class="col-3">
        <syslink-select label="Tax type" [store]="taxTypesService.store" [(model)]="element.PurchaseTaxTypeId" [displayKey]="'FormattedName'"></syslink-select>
    </div>
    <div class="col-3">
        <syslink-select label="Account" [store]="accountsService.store" [(model)]="element.PurchaseAccountId" [displayKey]="'FormattedName'"></syslink-select>
    </div> -->
</div>

<label class="mb-2">{{'Supplier catalog' | translate}}</label>

<div *ngIf="element.Id">
    <app-product-supplier-catalogs
        [(element)]="element"
        (elementChange)="supplierCatalogChanged.emit($event)"
    ></app-product-supplier-catalogs>
</div>
<div *ngIf="!element.Id">
    <div class="w-100 text-center p-5 bg-light">{{'SaveFirstMessage' | translate}}</div>
</div>