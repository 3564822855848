<div class="{{class}} py-2" [hidden]="hidden">
    <dx-date-box
        id="date-{{name}}"
        [name]="name"

        [value]="model"
        (onValueChanged)="modelChange.emit($event.value)"

        [stylingMode]="mode"
        [label]="getLabel()" 
        [labelMode]="labelMode"

        [readOnly]="disabled"

        [useMaskBehavior]="useMaskBehavior"

        [type]="type"
        [displayFormat]="displayFormat"
        [showClearButton]="showClearButton"

        [interval]="interval"
        [min]="min"
    ></dx-date-box>
</div>