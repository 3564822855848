import { Component, Input, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ModalComponent, NotificationsService, PageComponent } from 'projects/libraries/syslink-components/src/public-api';
import { ThirdsService } from '../../../thirds/thirds/thirds.service';
import { SaleQuote } from '../sale-quotes/sale-quote.model';
import { SaleQuotesService } from './sale-quotes.service';
import { NumberPlatesService } from '../../../vise/number-plates/number-plates.service';
import { jsonToOdataFormat } from 'projects/libraries/syslink-components/src/lib/helpers/tools';

@Component({
  selector: 'app-sale-quotes',
  templateUrl: './sale-quotes.component.html',
  styleUrls: ['./sale-quotes.component.scss']
})
export class SaleQuotesComponent extends PageComponent {
  @Input() public filter: string | string[] = "";
  public expand: Array<string> = ['ThirdId', 'ResponsibleUserId', 'Statuses', 'CurrentStatusLink.StatusId'];
  public customerFilter: string | string[] = ["Roles/any(r:r/Code eq 'customer')"];
  @Input() public items?: SaleQuote[];
  @Input() public canRefresh: boolean = false;

  @ViewChild('initModal') initModal?: ModalComponent;

  public globalResult?: { InTaxTotal: number, ExTaxTotal: number, DraftExTaxTotal: number, DraftInTaxTotal: number, 
    TransmittedExTaxTotal: number, TransmittedInTaxTotal: number, ConfirmedExTaxTotal: number, ConfirmedInTaxTotal: number };

  constructor(
    public saleQuotesService: SaleQuotesService,
    public override activatedRoute: ActivatedRoute,
    public thirdsService: ThirdsService,
    public NumberPlatesService: NumberPlatesService,
    public override translateService: TranslateService
  ) { super(); }

  public onAddButtonClicked() {
    this.initModal?.open();
  };

  @Input() public canDelete: boolean = false;
  @Input() public delete: Function = (e: any) => {
   };

  public onCreateValidated(e: any) {
    if (!e.ThirdId.Id) {
      NotificationsService.sendErrorMessage("Please select third");
      return;
    }
    if (e.ThirdId.Blocked == true) {
      NotificationsService.sendErrorMessage("Third is blocked");
      return;
    }
    this.router.navigateByUrl('/sales/quotes/new?ThirdId=' + e.ThirdId.Id + (e.Subject != undefined ? '&Subject=' + e.Subject : '') + (e.NumberPlate != undefined ? '&NumberPlate=' + e.NumberPlate : ''));
  }

  public async onFilterValueChange(e: any[]) {
    var request = await this.saleQuotesService.getStatistics(e);
    if(!request){
      this.globalResult = undefined;
      return;
    }
    this.globalResult = jsonToOdataFormat(request);
  }
}