import { BasicEntity } from "../../core/models/basic-entity";

export class TaxRate extends BasicEntity {
    public Value?: number;
    public Description?: string;
    public IsVisible?: boolean;
    
    constructor(taxRate?: Partial<TaxRate>) {
        super();
        Object.assign(this, taxRate);
    }
}