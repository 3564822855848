import { Injectable } from '@angular/core';
import { LoadOptions } from 'devextreme/data';
import { ODataService } from '../../../core/services/oData.service';
import { Locality } from './locality';

@Injectable({
  providedIn: 'root'
})
export class LocalityService extends ODataService<Locality>{
  public url: string = "Locality";
  constructor() {
    super();
  }

  public override defaultOptions: LoadOptions<Locality> = {
    expand: [
      "PostalCodes",
      "AdministrationAreaId",
      "CountryId"
    ]
  }
  public override format(element: Locality): any {
    var result: any = {
      ...element,
      CountryId: element.CountryId?.Id,
      AdministrationAreaId: element.AdministrationAreaId?.Id,
    };
    delete result.PostalCodes
    return result;
  }

  public override async getInstance(params?: Partial<Locality>): Promise<Locality> {
    const result = new Locality({
      ...params
    })
    return result;
  }
}
