<div class="row">
  <div class="col-7">
    <div class="row">
      <div class="col-12">
        <syslink-input-text
          label="Subject"
          [(model)]="element.Subject"
        ></syslink-input-text>
      </div>

      <div class="col-4">
        <syslink-select
          label="Third"
          displayKey="Fullname"
          [store]="thirdsService.store"
          [(model)]="element.ThirdId"
          (modelChange)="onChangeThird($event)"
          [filter]="[['IsSupplier eq true'], 'or', ['IsCustomer eq true']]"
          [canAdd]="authService.hasPermission('settings-thirds-thirds') && authService.hasPermission('basics.thirds.thirds.list.add')"
          [canView]="authService.hasPermission('settings-thirds-thirds')"
          detailsUrl="/basics/thirds/thirds/"
          [canRefresh]="true"
        ></syslink-select>
      </div>

      <div class="col-12">
        <syslink-wysiwyg
          name="Description"
          label="Description"
          [(model)]="element.Description"
          [table]="false"
          height="200"
        ></syslink-wysiwyg>
      </div>
      <div class="col-12">
        <div class="mt-2 switcher-row">
          <!-- <syslink-switch
            [(model)]="element.IsPlannable"
            type="box"
            [disabled]="element.ScheduledTime != 0 && element.IsPlannable"
            [allowDisableMessage]="true"
            disableMessage="{{ 'Scheduled time already Exists' | translate }}"
            [falseText]="'Not plannable' | translate"
            [trueText]="'Plannable' | translate"
            (modelChange)="updated.emit()"
          ></syslink-switch> -->
          <syslink-switch
            [(model)]="element.IsPerformable"
            type="box"
            [disabled]="element.WorkedTime != 0 && element.IsPerformable"
            [allowDisableMessage]="true"
            disableMessage="{{ 'Worked time already Exists' | translate }}"
            [falseText]="'Not performable' | translate"
            [trueText]="'Performable' | translate"
            (modelChange)="updated.emit()"
          ></syslink-switch>
          <syslink-switch
            [(model)]="element.IsBillable"
            type="box"
            [disabled]="
              (element.BilledTime != 0 || element.AmountInvoiced != 0) &&
              element.IsBillable
            "
            [allowDisableMessage]="true"
            disableMessage="{{
              'There is already a time or an amount billed' | translate
            }}"
            [falseText]="'Not billable' | translate"
            [trueText]="'Billable' | translate"
            (modelChange)="updated.emit()"
          ></syslink-switch>
          <syslink-switch
            [(model)]="element.IsFixedPrice"
            type="box"
            [disabled]="
              (element.IsFixedPrice && element.AmountInvoiced != 0) ||
              (!element.IsFixedPrice && element.BilledTime != 0)
            "
            [allowDisableMessage]="true"
            disableMessage="{{
              element.IsFixedPrice && element.AmountInvoiced != 0
                ? ('Amount already invoiced on Fixed price' | translate)
                : ('Worked time already invoiced' | translate)
            }}"
            [falseText]="'Time' | translate"
            falseColor="primary"
            trueColor="secondary"
            [trueText]="'Fixed price' | translate"
            (modelChange)="updated.emit()"
          ></syslink-switch>
        </div>
      </div>
    </div>
  </div>
  <div class="col-5">
    <div class="row">
      <div class="col-6">
        <div class="row">
          <!-- <syslink-select
            #selectQuote
            label="Quote"
            [store]="saleQuotesService.store"
            [expand]="['ThirdId']"
            [filter]="
              element.ThirdId?.Id
                ? ['ThirdId.Id eq ' + element.ThirdId?.Id]
                : undefined
            "
            [displayKey]="'NameFormatted'"
            [(model)]="element.SaleQuoteId"
            (modelChange)="updateThird($event)"
          ></syslink-select> -->
          <!-- [select]="defaultSelect" -->
          <!-- <syslink-select
            #selectContract
            label="Contract"
            [store]="saleContractsService.store"
            [expand]="['ThirdId']"
            [filter]="
              element.ThirdId?.Id
                ? ['ThirdId.Id eq ' + element.ThirdId?.Id]
                : undefined
            "
            [displayKey]="'NameFormatted'"
            [(model)]="element.SaleContractId"
            (modelChange)="updateThird($event)"
          ></syslink-select> -->
          <!-- [select]="defaultSelect" -->
          <syslink-input-text
            label="ExternalRef"
            [(model)]="element.ExternalRef"
          ></syslink-input-text>
          <syslink-input-text
            label="InternalRef"
            [(model)]="element.InternalRef"
          ></syslink-input-text>
          <syslink-input-date
            label="Start date"
            format="date"
            type="date"
            [(model)]="element.StartDate"
          ></syslink-input-date>
          <syslink-input-date
            label="Deadline"
            format="date"
            type="date"
            [(model)]="element.EndDate"
            [min]="element.StartDate"
          ></syslink-input-date>
          <syslink-select
            label="Priority"
            [store]="TaskPrioritiesService.store"
            [(model)]="element.TaskPriorityId"
            [select]="defaultSelect"
            [displayKey]="'NameFormatted'"
            [allowClearing]="true"
          ></syslink-select>
          <syslink-tagbox
            label="Tags"
            [store]="TaskTagsService.store"
            displayKey="Name"
            [select]="['Id', 'Name']"
            [(model)]="element.Tags"
          ></syslink-tagbox>
          <syslink-tagbox
            #tagBoxUsers
            label="Users"
            valueKey="Oid"
            [store]="usersService.store"
            [expand]="usersService.defaultOptions.expand"
            displayKey="Name"
            [(model)]="element.Users"
            [filter]="['IsActive eq true']"
          ></syslink-tagbox>
          <syslink-tagbox
            #tagBoxUserGroups
            *ngIf="userGroupsService"
            label="UserGroups"
            [store]="userGroupsService.store"
            displayKey="Name"
            [select]="['Id', 'Name']"
            [(model)]="element.UserGroups"
            [filter]="['IsActive eq true']"
          ></syslink-tagbox>
        </div>
      </div>
      <div class="col-6">
        <div class="row">
          <syslink-input-duration
            label="Estimated time"
            [textColor]="element.EstimatedTimeState.Color"
            [(model)]="element.EstimatedTime"
            (focusOut)="estimatedTimeChange.emit()"
          ></syslink-input-duration>

          <syslink-input-number
            *ngIf="element.IsFixedPrice"
            label="Fixed price"
            [(model)]="element.FixedPrice"
            [min]="0"
          ></syslink-input-number>

          <div *ngIf="element.IsFixedPrice && element.IsBillable" class="mb-1">
            <label
              class="mb-1 dx-editor-underlined dx-texteditor-with-floating-label dx-texteditor-label"
              >{{ "Invoice status" | translate }}</label
            >
            <syslink-tag
              [(color)]="element.BilledTimeState.Color"
              value="{{ element.BilledTimeState.Name }} ({{
                element.AmountInvoiced | syslinkCurrency
              }})"
            ></syslink-tag>
          </div>

          <ng-container *ngIf="element.IsPerformable">
            <syslink-progress-bar
              *ngIf="element.IsPerformable"
              #workedTimeProgressBar
              name="Worked time"
              [max]="element.EstimatedTime || element.WorkedTime"
              [value]="element.WorkedTime"
              [fillColor]="element.WorkedTimeState.Color"
            ></syslink-progress-bar>
          </ng-container>

          <!-- <ng-container *ngIf="element.IsPlannable">
            <syslink-progress-bar
              #scheduledTimeProgressBar
              name="Scheduled time"
              [max]="element.EstimatedTime || element.ScheduledTime"
              [value]="element.ScheduledTime"
              [fillColor]="element.ScheduledTimeState.Color"
            ></syslink-progress-bar>
          </ng-container> -->

          <ng-container *ngIf="!element.IsFixedPrice && element.IsBillable">
            <syslink-progress-bar
              #billedTimeProgressBar
              name="Billed time"
              [max]="element.EstimatedTime || element.BillableTime"
              [value]="element.BilledTime"
              [fillColor]="element.BilledTimeState.Color"
            ></syslink-progress-bar>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
