import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Product } from '../product.model';
import { ProductsService } from '../product.service';
import { AuthService } from '../../../core/auth/auth.service';
import { StockLocationService } from '../../../stocks/stock-location/stock-location.service';
import { ModalComponent } from 'projects/libraries/syslink-components/src/public-api';
import { StockMoveIncomingHeaderStatusesService } from '../../../stocks/stock-move-incomings/stock-move-incoming-header-statuses/stock-move-incoming-header-statuses.service';
import { StockMoveOutgoingHeaderStatusesService } from '../../../stocks/stock-move-outgoings/stock-move-outgoing-header-statuses/stock-move-outgoing-header-statuses.service';
import { ViewComponent } from 'projects/libraries/syslink-components/src/lib/helpers/view/view.component';

@Component({
  selector: 'app-product-stock-form',
  templateUrl: './product-stock-form.component.html',
  styleUrls: ['./product-stock-form.component.scss']
})
export class ProductStockFormComponent extends ViewComponent {
  @Input() public element: Product = new Product();
  @Output() public elementChange: EventEmitter<Product> = new EventEmitter<Product>();

  @ViewChild('stockModal') stockModal?: ModalComponent;

  public stockMoveLineIncomingsFilter: string | string[] | undefined = undefined;
  public stockMoveLineOutgoingsFilter: string | string[] | undefined = undefined;
  public showIncomingGrid: boolean = false;
  public showOutgoingGrid: boolean = false;

  constructor(
    public productsService: ProductsService,
    public stockLocationService: StockLocationService,
    public stockMoveIncomingHeaderStatusesService: StockMoveIncomingHeaderStatusesService,
    public stockMoveOutgoingHeaderStatusesService: StockMoveOutgoingHeaderStatusesService
  ) {
    super();
  }

  public getValorization() {
    if (!this.element || !this.element.DefaultSupplierCatalogId || !this.element.DefaultSupplierCatalogId.ExTaxNetPrice) return 0;
    return this.element.Stock * this.element.DefaultSupplierCatalogId.ExTaxNetPrice;
  }

  public async onClickGrid(gridName: string) {
    this.stockMoveLineIncomingsFilter = undefined;
    this.stockMoveLineOutgoingsFilter = undefined;
    this.showIncomingGrid = false;
    this.showOutgoingGrid = false;

    switch (gridName) {
      case "stockModalIncoming":
        var status = await this.stockMoveIncomingHeaderStatusesService.load();
        var statusId = status.find((s: any) => s.Code == "Incoming.Planned");
        if (!statusId) return;
        this.stockMoveLineIncomingsFilter = ["(HeaderId.CurrentStatusLink.StatusId.Id eq " + statusId.Id + ") and (ProductId.Id eq " + this.element.Id] + ")";
        this.showIncomingGrid = true;
        break;
      case "stockModalOutgoing":
        var status = await this.stockMoveOutgoingHeaderStatusesService.load();
        var statusId = status.find((s: any) => s.Code == "Outgoing.Planned");
        if (!statusId) return;
        this.stockMoveLineOutgoingsFilter = ["(HeaderId.CurrentStatusLink.StatusId.Id eq " + statusId.Id + ") and (ProductId.Id eq " + this.element.Id] + ")";
        this.showOutgoingGrid = true;
        break;
      case "stockMoveModal":
        var status = await this.stockMoveIncomingHeaderStatusesService.load();
        var statusId = status.find((s: any) => s.Code == "Incoming.Achieved");
        if (!statusId) return;
        this.stockMoveLineIncomingsFilter = ["(HeaderId.CurrentStatusLink.StatusId.Id eq " + statusId.Id + ") and (ProductId.Id eq " + this.element.Id] + ")";
        this.showIncomingGrid = true;

        statusId = undefined;
        status = await this.stockMoveOutgoingHeaderStatusesService.load();
        statusId = status.find((s: any) => s.Code == "Outgoing.Achieved");
        if (!statusId) return;
        this.stockMoveLineOutgoingsFilter = ["(HeaderId.CurrentStatusLink.StatusId.Id eq " + statusId.Id + ") and (ProductId.Id eq " + this.element.Id] + ")";
        this.showOutgoingGrid = true;
        break;
    }
    this.stockModal?.open();
  }

}
