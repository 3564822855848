import { Injectable } from '@angular/core';
import { LoadOptions } from 'devextreme/data';
import { SaleDocumentHeaderStatusesService } from '../../sale-documents/sale-document-header-status/sale-document-header-statuses.service';
import { SaleOrderHeaderHeaderStatus } from './sale-order-header-header-status.model';

@Injectable({
  providedIn: 'root'
})
export class SaleOrderHeaderHeaderStatusesService extends SaleDocumentHeaderStatusesService<SaleOrderHeaderHeaderStatus> {
  public override url: string = 'SaleOrderHeaderHeaderStatus';
  public override defaultOptions: LoadOptions = {
    expand: ["StatusId"]
  };

}
