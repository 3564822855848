import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ModalComponent } from '../modal.component';
import DataSource from 'devextreme/data/data_source';
import ODataStore from 'devextreme/data/odata/store';
import { AutoformComponent, AutoformField, SelectComponent } from '../../../public-api';

@Component({
  selector: 'syslink-third-modal',
  templateUrl: './third-modal.component.html',
  styleUrls: ['./third-modal.component.scss']
})
export class ThirdModalComponent{
  // Modal data
  // ----------
  @Input() public title: string = 'Choose third';
  @Input() public content?: string ;
  @Input() public width: string = '300px';

  // Two way binding variable
  // ------------------------
  @Input() public third: any;
  @Output() public onThirdChanged: EventEmitter<any> = new EventEmitter<any>();

  // Local items
  // -----------
  @Input() public items?: any[];

  // Remote items
  // ------------
  public dataSource: DataSource = new DataSource({});
  @Input() public store?: ODataStore;
  @Input() public expand?: string | string[]="";
  @Input() public select?: string | string[]="";
  @Input() public filter?: string | string[]="";

  // Function
  // --------
  @Input() public formatThirdName?: Function;

  // ExtraFields
  // -----------
  @Input() public displayKey: string = "Fullname";
  @Input() public extraFields?: AutoformField[] | undefined;
  @Input() public ODataService?: any;

  
  @Output() private onThirdSelected: EventEmitter<any> = new EventEmitter<any>();
  @Output() private onValidate: EventEmitter<any> = new EventEmitter<any>();
  @Output() private onCancel: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('modal') public modal: ModalComponent = new ModalComponent;
  @ViewChild('selectThird') public selectThird?: SelectComponent;
  @ViewChild('autoform') public autoform?: AutoformComponent;
  
  public open(data: any = null): void {
    this.modal.data = data;
    this.modal.open();
  }

  public close(): void {
    this.modal.close();
  }

  public onModalValidate() {
    this.onValidate.emit({ third: this.third, extraFields: this.extraFields });
    this.modal.close();
  }

  public onModalCancel() {
    if (this.onCancel.observed) {
      this.onCancel.emit();
    } else {
      this.modal.close();
    }
  }
  public thirdChange(e:any){
    this.onThirdSelected.emit({ third: this.third, extraFields: this.extraFields });
    this.onThirdChanged.emit(e)
  }

  public getAutoformStore(field: AutoformField) {
    return field.Store;
  }

  public getAutoformItems(field: AutoformField) {
    return field.Items;
  }
}
