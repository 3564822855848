import { Component, Input, ViewChild } from '@angular/core';
import { BaseModalComponent } from 'projects/libraries/syslink-components/src/lib/modal/base-modal/base-modal.component';
import { StockMoveIncomingHeaderStatus } from '../../stock-move-incoming-header-statuses/stock-move-incoming-header-status.model';
import { SelectComponent } from 'projects/libraries/syslink-components/src/public-api';
import { FilterDescriptor } from 'devextreme/data';
import { StockMoveIncomingHeaderStatusesService } from '../../stock-move-incoming-header-statuses/stock-move-incoming-header-statuses.service';

@Component({
  selector: 'app-stock-move-incoming-status-select-modal',
  templateUrl: './stock-move-incoming-status-select-modal.component.html',
  styleUrls: ['./stock-move-incoming-status-select-modal.component.scss']
})
export class StockMoveIncomingStatusSelectModalComponent extends BaseModalComponent<StockMoveIncomingHeaderStatus>{
  @Input() public selectFilters: FilterDescriptor | Array<FilterDescriptor>;
  @Input() public expand: FilterDescriptor | Array<FilterDescriptor>;
  @Input() public title: string = "Select incoming status";
  @Input() public content?: string;
  @Input() public data: any;
  @ViewChild('select') select?: SelectComponent;

  constructor(public stockMoveIncomingHeaderStatusesService: StockMoveIncomingHeaderStatusesService) {
    super();
  }

}