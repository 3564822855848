<app-stock-move-incoming-grid
    *ngIf="filter"
    #GridComponent
    [permissionKey]="'stockMoveCustomerIncomings.list'"
    [canUpdate]="false"
    [filter]="filter"
    (add)="onAddButtonClicked()"
    detailsUrl="/stockmoves/customerincomings"
    storageKey="stock-move-customerincomings"
    [columns]="columns"
></app-stock-move-incoming-grid>