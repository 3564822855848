import { Injectable } from '@angular/core';
import { LoadOptions } from 'devextreme/data';
import { ApiService } from '../../../core/api.service';
import { SaleDocumentLinesService } from '../../sale-documents/sale-document-lines/sale-document-lines.service';
import { SaleInvoiceLine } from './sale-invoice-line.model';

@Injectable({
  providedIn: 'root'
})
export class SaleInvoiceLinesService extends SaleDocumentLinesService<SaleInvoiceLine> {
  public override url: string = 'SaleInvoiceLine';
  public override documentType: string = 'SaleInvoice';
  public override defaultOptions: LoadOptions = {
    expand: ['Margin', 'Discount', 'ParentId']
  };

  constructor(public override api: ApiService) {
    super(api);
  }

  public override async getInstance(quote?: Partial<SaleInvoiceLine>): Promise<any> {
    let element = {
      ...quote,
    };
    return element;
  }

  public async updateParentId(saleInvoiceLine: SaleInvoiceLine, targetParentId: number) {
    return await this.api.sendRequest('/api/SaleDocumentLine/' + saleInvoiceLine.Id + '/changeParent/' + targetParentId, 'POST');
  }
}
