import { Injectable } from '@angular/core';
import { ODataService } from '../../../core/services/oData.service';
import { Address } from './address.model';

@Injectable({
  providedIn: 'root'
})
export class AddressesService extends ODataService<Address> {
  public url: string = "address";

  constructor(
  ) {
    super();
  }

  public async postPlaceResult(placeResult: any): Promise<Address> {
    return await this.apiService.sendRequest('/api/GooglePlaceApiAddress/fromplaceresult', 'POST', placeResult);
  }
}
