import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Supplier } from './supplier.model';
import { PageComponent } from 'projects/libraries/syslink-components/src/public-api';

@Component({
  selector: 'app-suppliers',
  templateUrl: './suppliers.component.html',
  styleUrls: ['./suppliers.component.scss']
})
export class SuppliersComponent extends PageComponent {


  constructor(
    protected override activatedRoute: ActivatedRoute
  ) {
    super();
  }

  public onAddButtonClicked() {
    this.router.navigate(['new'], { relativeTo: this.activatedRoute });
  }
  public onRowDoublelick(element: Supplier): void {
    this.router.navigate([element.ThirdId.Id], { relativeTo: this.activatedRoute });
  }
}
