import { Component, Input } from '@angular/core';
import { PurchaseCreditNote } from '../purchase-credit-notes/purchase-credit-note.model';
import { BaseGridComponent } from 'projects/libraries/syslink-components/src/lib/grid/base-grid/base-grid.component';
import { ContextMenuItemAction } from 'projects/libraries/syslink-components/src/lib/context-menus/context-menu-item-action';
import { SyslinkColumn } from 'projects/libraries/syslink-components/src/lib/helpers/SyslinkColumn';
import { PurchaseCreditNotesService } from '../purchase-credit-notes/purchase-credit-notes.service';
import { DXCell } from 'projects/libraries/syslink-components/src/lib/grid/cells/dxcell';

@Component({
  selector: 'app-purchase-credit-note-grid',
  templateUrl: './purchase-credit-note-grid.component.html',
  styleUrls: ['./purchase-credit-note-grid.component.scss']
})
export class PurchaseCreditNoteGridComponent extends BaseGridComponent<PurchaseCreditNote> {

  @Input() override detailsUrl: string = '/purchases/credit-notes';
  @Input() override storageKey: string = 'purchase-credit-notes';
  @Input() public contextMenuItems: ContextMenuItemAction[] = [];
  @Input() showPager: boolean = true;
  @Input() items?: any[];

  @Input() public override expand?: string | string[] = ['CurrentStatusLink.StatusId'];
  @Input() public override select?: string[] = ['Id', 'No', 'ThirdId.Fullname', 'Subject', 'Deadline', 'ExternalReference', 'ExTaxTotal', 'InTaxTotal', 'ResponsibleUserId.Fullname', 'Date', 'AmountRemaining','FormattedStructuredCommunication'];

  public columns: SyslinkColumn[] = [
    new SyslinkColumn({ field: "No", label: "GRID.CAPTION.NO", width: 150, sortIndex: 2, sortDirection: 'desc' }),
    new SyslinkColumn({ field: "ThirdId.Fullname", label: "Supplier", width: 250 }),
    new SyslinkColumn({ field: "Subject", label: "Subject" }),
    new SyslinkColumn({ field: "CurrentStatusLink.StatusId.Name", label: "Status", cellTemplate: 'statusText-cell', data: { getStatusLabel: this.getStatusCellLabel, getStatusDate: this.getStatusCellDate, getStatusClass: this.getStatusCellClass, getStatusColor: this.getStatusCellColor }, width: 120 }),
    new SyslinkColumn({ field: "Deadline", label: 'PurchaseCreditNote.Validity', type: "date", cellTemplate: "template-cell", width: 80, data: { template: "TemplateDeadline", currentDate: new Date(), getAmountRemaining: this.getAmountRemainingCellLabel, getCellOverdue: this.getCellOverdue } }),
    new SyslinkColumn({ field: "ExternalReference", label: "External reference", visible: true, width: 150 }),
    new SyslinkColumn({ field: "ExTaxTotal", label: "exTaxTotal", type: "number", data: { subModuleCode: 'Purchases.CreditNote', key: 'Precision.PurchaseCreditNoteHeader.ExTaxTotal' }, cellTemplate: 'currency-cell', width: 80 }),
    new SyslinkColumn({ field: "InTaxTotal", label: "Total", type: "number", data: { subModuleCode: 'Purchases.CreditNote', key: 'Precision.PurchaseCreditNoteHeader.InTaxTotal' }, cellTemplate: 'currency-cell', width: 80 }),
    new SyslinkColumn({ field: "ResponsibleUserId.Fullname", label: "Responsible", width: 120 }),
    new SyslinkColumn({ field: "Date", label: "Date", type: "date", visible: false, width: 150 }),
    new SyslinkColumn({ field: "AmountRemaining", label: 'Remaining amount', type: "number", visible: false, cellTemplate: 'currency-cell', data: { subModuleCode: 'Purchases.CreditNote', key: 'Precision.PurchaseCreditNoteHeader.AmountRemaining' }, width: 80, filterable: false, headerFilterable: false, sortable: false }),
    new SyslinkColumn({ field: "FormattedStructuredCommunication", label: 'Structured communication', visible: false, editable: false }),
    new SyslinkColumn({ field: "CurrentStatusLink.StatusId.Sequence", label: 'status order', sortIndex: 1, sortDirection: 'asc', filterable: false, headerFilterable: false, width: 0, visible: false })
  ];

  constructor(
    public purchaseCreditNotesService: PurchaseCreditNotesService,
  ) {
    super();
  }
  getStatusCellLabel(cell: DXCell) {
    return cell.data.CurrentStatusLink.StatusId.Name;
  }

  getStatusCellDate(cell: DXCell) {
    return cell.data.CurrentStatusLink.Date;
  }

  getStatusCellClass(cell: DXCell) {
    let statusClass = 'text-white text-center'

    const statusCode = (<string>cell.data.CurrentStatusLink.StatusId.Code).toLowerCase().split('.')[1];
    if (statusCode == undefined) return;
    statusClass += ' ' + statusCode;
    return statusClass;
  }

  getStatusCellColor(cell: DXCell) {
    return cell.data.CurrentStatusLink.StatusId.Color;
  }

  getAmountRemainingCellLabel(cell: DXCell) {
    if (cell.data && cell.data.CurrentStatusLink && cell.data.CurrentStatusLink.StatusId && cell.data.CurrentStatusLink.StatusId.Sequence == 1) {
      return 0;
    }
    return cell.data.AmountRemaining;
  }

  getCellOverdue(cell: DXCell) {
    if (!cell.data.Deadline) return 0;
    var deadline = cell.data.Deadline;
    var today = new Date();
    var one_day = 1000 * 60 * 60 * 24
    if (today.getMonth() == 11 && today.getDate() > 25)
      deadline.setFullYear(deadline.getFullYear() + 1)
    var Result = Math.round(deadline.getTime() - today.getTime()) / (one_day);
    if (Result < 0) {
      Result = Math.abs(Result);
    }
    else {
      Result = 0;
    }
    var Final_Result = Result.toFixed(0);
    return Final_Result;
  }
  public reload() {
    this.grid?.grid?.instance.refresh();
  }
}


