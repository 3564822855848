<syslink-title text="{{ 'Stock Move Internal' }}"></syslink-title>
<app-stock-move-internal-grid
  #grid
  [permissionKey]="'list'"
  [canUpdate]="false"
  (add)="onCreateValidated()"
  [contextMenuItems]="contextMenuItems"
  [expand]="expand"
  detailsUrl="/stockmoves/internalstockmoves"
  storageKey="stock-move-internalstockmoves"
>
</app-stock-move-internal-grid>
<syslink-confirm-modal
  #confirmModal
  (onValidate)="onConfirmModalValidated($event)"
></syslink-confirm-modal>
<app-stock-move-internal-status-select-modal
  #statusModal
  (validate)="onConfirmModalValidated($event)"
></app-stock-move-internal-status-select-modal>
