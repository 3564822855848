import { Component } from '@angular/core';
import { AppInjectorService } from 'projects/libraries/syslink-components/src/lib/services/app-injector.service';
import { PurchaseOrderHeaderHeaderStatusesService } from '../purchase-order-header-header-statuses/purchase-order-header-header-statuses.service';
import { PurchaseOrderHeaderHeaderStatus } from '../purchase-order-header-header-statuses/purchase-order-header-header-status.model';
import { PurchaseOrderHeaderStatusesService } from '../purchase-order-header-statuses/purchase-order-header-statuses.service';
import { PurchaseOrderHeaderStatus } from '../purchase-order-header-statuses/purchase-order-header-status.model';
import { PurchaseOrderLinesService } from '../purchase-order-lines/purchase-order-lines.service';
import { PurchaseOrderLine } from '../purchase-order-lines/purchase-order-line.model';
import { PurchaseOrdersService } from '../purchase-orders/purchase-orders.service';
import { PurchaseOrder } from '../purchase-orders/purchase-order.model';
import { PurchaseDocumentDetailsComponent } from '../../purchase-documents/purchase-documents/purchase-document-details/purchase-document-details.component';
import { SyslinkColumn } from 'projects/libraries/syslink-components/src/lib/helpers/SyslinkColumn';

@Component({
  selector: 'app-purchase-order-details',
  templateUrl: '../../purchase-documents/purchase-documents/purchase-document-details/purchase-document-details.component.html',
  styleUrls: ['./purchase-order-details.component.scss']
})
export class PurchaseOrderDetailsComponent extends PurchaseDocumentDetailsComponent<
  PurchaseOrder,
  PurchaseOrdersService,
  PurchaseOrderLine,
  PurchaseOrderLinesService,
  PurchaseOrderHeaderStatus,
  PurchaseOrderHeaderStatusesService,
  PurchaseOrderHeaderHeaderStatus,
  PurchaseOrderHeaderHeaderStatusesService
>{

  protected override loadDependencies() {
    this.PurchaseDocumentService = AppInjectorService.injector.get(PurchaseOrdersService);
    this.PurchaseDocumentLinesService = AppInjectorService.injector.get(PurchaseOrderLinesService);
    this.PurchaseDocumentStatusesService = AppInjectorService.injector.get(PurchaseOrderHeaderStatusesService);
    this.PurchaseDocumentHeaderStatusesService = AppInjectorService.injector.get(PurchaseOrderHeaderHeaderStatusesService);

    this.reportType = "PurchaseOrder";
    this.subModuleCode = "Purchases.Order";
    this.defaultStatusCode = "Order.Draft";
    this.documentLinesColumns = [...this.PurchaseDocumentService.getDefaultDocumentLineColumns(this.subModuleCode,this.reportType),  
      new SyslinkColumn({ order: 1, field: 'PurchasePriceStatus', showEditorAlways: true, label: '', type: 'boolean', cellTemplate: "template-cell",
      data: { template: 'colored-euro-cell' }, width: 20 }),
  ];
  }

  public override canEditDocument(): boolean {
    return !this.hasActiveStatus("Order.Confirmed");
  }

}
