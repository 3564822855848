import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ThirdsService } from '../../../thirds/thirds/thirds.service';
import { ModalComponent, PageComponent } from 'projects/libraries/syslink-components/src/public-api';

@Component({
  selector: 'app-document-copy-modal',
  templateUrl: './document-copy-modal.component.html',
  styleUrls: ['./document-copy-modal.component.scss']
})
export class DocumentCopyModalComponent extends PageComponent {

  @Output() validate: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('modal') modal: ModalComponent = new ModalComponent();

  constructor(
    public thirdsService: ThirdsService,
  ) {
    super();
  }

  public open(e:any){
    this.modal.open(e);
  }

  public close(){
    this.modal.close();
  }

  public onValidateButtonClicked(e: Document, selectedKey: any[] = []) {
    if (selectedKey.length == 0 && this.modal) {
      this.modal.data.selectedKey = undefined;
    }
    this.validate.emit(this.modal);
  }
}
