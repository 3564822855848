
    <syslink-button
    name="Work times"
    text="Work times"
    icon="fa-solid fa-stopwatch"
    (click)="onAddWorkTimes()"
    ></syslink-button>

<syslink-modal
    #workTimesModal
    title="Add workTimes"
    width="85%"
    height="80%"
    [deferRendering]="true"
    shading=""
    [resize]="true"
    (onValidate)="validate()"
    >
    <syslink-treelist
    [columns]="columns"
    [options]="{editing:{allowAdding:false, allowUpdating:false, allowDeleting:false},selection:{mode:'multiple'},pager:{visible:false}}"
    (onRowPrepared)="onRowPrepared($event)"
    parentIdExpr="ParentId"
    [canAdd]="false"
    storageKey="work-time-picker-treelist"
    [items]="lines"
    [showDragIcons]="false"
    [allowReordering]="false"
    [selectedKeys]="selectedKey"
    (selectedKeysChange)="onSelectedKeyChange($event)"
    (doubleClick)="doubleClick($event)"
    ></syslink-treelist>
</syslink-modal>