import { Injectable } from '@angular/core';
import { ODataService } from '../../core/services/oData.service';
import { AccountJournalType } from './account-journal-type.model';

@Injectable({
  providedIn: 'root'
})
export class AccountJournalTypesService extends ODataService<AccountJournalType> {
  public url = 'AccountJournalType';
}

