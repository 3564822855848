import { Entity } from "../../../core/models/entity";
import { Translation } from "../translations/translation";


export class TranslatableString extends Entity {
    public TranslatedValue?: string;
    public Translations: Array<Translation> = [];
    public Code? : string;

    constructor(translatableString?: Partial<TranslatableString>) {
        super(translatableString);
        Object.assign(this, translatableString);
    }

}
