import { Injectable } from '@angular/core';
import { StockMoveDocumentStatusesService } from '../../stock-move-documents/stock-move-document-statuses/stock-move-document-statuses.service';
import { StockMoveIncomingHeaderStatus } from './stock-move-incoming-header-status.model';

@Injectable({
  providedIn: 'root'
})
export class StockMoveIncomingHeaderStatusesService extends StockMoveDocumentStatusesService<StockMoveIncomingHeaderStatus> {
  public override url: string = 'StockMoveIncomingHeaderStatus';
 
  constructor() {
    super();
  }
}
