import { Injectable } from '@angular/core';
import { SaleDocumentStatusesService } from '../../sale-documents/sale-document-statuses/sale-document-statuses.service';
import { SaleInvoiceHeaderStatus } from './sale-invoice-header-status.model';

@Injectable({
  providedIn: 'root'
})
export class SaleInvoiceHeaderStatusesService extends SaleDocumentStatusesService<SaleInvoiceHeaderStatus> {
  public override url: string = 'SaleInvoiceHeaderStatus';
 
  constructor() {
    super();
  }
}
