import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ModalComponent } from 'projects/libraries/syslink-components/src/public-api';
import { AccountTypesService } from '../account-types/account-types.service';
import { Account } from './account.model';
import { AccountsService } from './accounts.service';

@Component({
  selector: 'app-accounts',
  templateUrl: './accounts.component.html',
  styleUrls: ['./accounts.component.scss']
})
export class AccountsComponent implements OnInit {
  public items: Account[] = [];

  public selectedRow: Account = new Account();
  @ViewChild('accountModal') public accountModal: ModalComponent = new ModalComponent();
  constructor(
    public translateService: TranslateService,
    public accountTypesService: AccountTypesService,
    public accountsService: AccountsService
  ) {
  }

  ngOnInit(): void {
  }

  // public async onDoubleClick(e: any) {
  //   this.selectedRow = await this.accountsService.findByID(e.Id);
  //   this.accountModal.open();
  // }
  // public async onValidateModal() {
  //   this.accountModal.close();
  //   if (this.selectedRow.Id == null)
  //     await this.accountsService.insert(await this.accountsService.format(this.selectedRow));
  //   else
  //     await this.accountsService.update(this.selectedRow.Id, await this.accountsService.format(this.selectedRow));
  //   await this.loadItems();
  // }
  // public async onAddClicked() {
  //   this.selectedRow = await this.accountsService.getInstance();
  //   this.accountModal.open();
  // }

  // public async onRemoveClick(e: any) {
  //   await this.accountsService.remove(e.key);
  //   await this.loadItems();
  // }
}
