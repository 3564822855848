<div class="row">
  <div class="col-2">
    <syslink-select
      label="Title"
      [store]="thirdTitlesService.store"
      [(model)]="element.TitleId"
      [disabled]="disabled"
    ></syslink-select>
  </div>
  <div class="col-5">
    <syslink-input-text
      label="Firstname"
      [(model)]="element.Firstname"
      [disabled]="disabled"
    ></syslink-input-text>
  </div>
  <div class="col-5">
    <syslink-input-text
      label="Lastname"
      [(model)]="element.Lastname"
      [disabled]="disabled"
    ></syslink-input-text>
  </div>
</div>