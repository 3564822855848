<syslink-modal
    #modal
    [title]="title"
    (onValidate)="onValidateButtonClicked(modal.data)"
    width="350"
>
    <p
        *ngIf="content"
        class="text-center m-0"
        [innerHTML]="content | translate"
    ></p>
    <syslink-tagbox
        *ngIf="modal.data.Users"
        #tagBoxUsers
        label="Users"
        valueKey="Oid"
        [store]="usersService.store"
        [expand]="usersService.defaultOptions.expand"
        displayKey="Name"
        [(model)]="modal.data.Users"
        [filter]="['IsActive eq true']"
    ></syslink-tagbox>
    <div class="row">
        <div class="col-6">{{'Assignment type :'| translate}}</div>
        <div class="col-6">
            <syslink-switch
                [(model)]="modal.data.AssignmentType"
                type="box"
                [falseText]="'Replace' | translate"
                falseColor="danger"
                trueColor="success"
                [trueText]="'Add' | translate"
            ></syslink-switch>
        </div>
    </div>
</syslink-modal>