<syslink-title text="{{ 'Tasks' | translate }}"></syslink-title>

<!-- <app-task-kanban
  [hidden]="viewType != 'kanban'"
  (add)="onAddButtonClicked()"
  [toolbarActions]="[gridSwitchToolbarButton]"
></app-task-kanban> -->

<app-task-grid
  #taskGrid
  [permissionKey]="'list'"
  [hidden]="viewType != 'grid'"
  (add)="onAddButtonClicked()"
  (rowDoubleClicked)="ondRowDoubleClicked($event)"
  [contextMenuItems]="taskGridContextMenuItems"
></app-task-grid>

<app-task-modal
  #taskModal
  (validate)="onValidateModalButtonClicked($event)"
></app-task-modal>

<app-task-multiple-edit-modal
  #taskMultipleEditModal
  (validated)="onValidateModalMultipleEdit()"
></app-task-multiple-edit-modal>

<syslink-confirm-modal
  #confirmModal
  width="350px"
  (onValidate)="onConfirmModalValidated($event)"
></syslink-confirm-modal>

<app-work-time-modal
  #worktimeModal
  (validate)="onValidateWorkTimeCreation($event)"
  [canEditType]="true"
  [canEditTask]="false"
></app-work-time-modal>

<!-- <app-appointment-modal
  #appointmentModal
  [canEditType]="false"
  [canEditTask]="false"
  (validate)="onValidateAppointmentCreation($event)"
></app-appointment-modal> -->

<app-task-task-status-modal
  #statusModal
  (validate)="onConfirmModalValidated($event)"
></app-task-task-status-modal>

<app-sale-invoice-select-modal
  #saleInvoiceSelectModal
  (validate)="onConfirmModalValidated($event)"
  (onNewInvoiceButtonClicked)="onConfirmModalValidated($event)"
  [selectFilters]="saleInvoiceSelectModalFilter"
  [expand]="['CurrentStatusLink.StatusId', 'ThirdId']"
></app-sale-invoice-select-modal>

<app-user-tagbox-modal
  #userTagboxModal
  (validate)="onConfirmModalValidated($event)"
></app-user-tagbox-modal>

<app-user-group-tagbox-modal
  #userGroupTagboxModal
  (validate)="onConfirmModalValidated($event)"
></app-user-group-tagbox-modal>

<app-dynamic-price-invoicing
  #dynamicPriceInvoicing
  (onInvoiceCreated)="taskGrid.refresh()"
></app-dynamic-price-invoicing>