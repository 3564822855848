import { Component, Input, ViewChild } from '@angular/core';
import { SyslinkColumn } from 'projects/libraries/syslink-components/src/lib/helpers/SyslinkColumn';
import { Third } from '../../../third.model';
import { ViewComponent } from 'projects/libraries/syslink-components/src/lib/helpers/view/view.component';
import { SaleContractsService } from 'projects/erp-app/src/app/sales/sale-contracts/sale-contracts/sale-contracts.service';
import { SaleContractGridComponent } from 'projects/erp-app/src/app/sales/sale-contracts/sale-contract-grid/sale-contract-grid.component';

@Component({
  selector: 'app-third-document-relation-sale-contracts',
  templateUrl: './third-document-relation-sale-contracts.component.html',
  styleUrls: ['./third-document-relation-sale-contracts.component.scss']
})
export class ThirdDocumentRelationSaleContractsComponent extends ViewComponent  {
  @Input() public element: Third = new Third();
  
  constructor(
    private saleContractsService: SaleContractsService,
  ){
    super();
  }

  @ViewChild('contractGridComponent') contractGridComponent: SaleContractGridComponent = new SaleContractGridComponent(this.saleContractsService);
  public SaleContractColumns: SyslinkColumn[] = [
    new SyslinkColumn({ field: "No", label: "GRID.CAPTION.NO", width: 150, sortIndex: 2, sortDirection: 'desc' }),
    new SyslinkColumn({ field: "Subject", label: "Subject" }),
    new SyslinkColumn({ field: "CurrentStatusLink.StatusId.Name", label: "Status", cellTemplate: 'statusText-cell', data: { getStatusLabel: this.contractGridComponent.getStatusCellLabel, getStatusDate: this.contractGridComponent.getStatusCellDate, getStatusClass: this.contractGridComponent.getStatusCellClass, getStatusColor: this.contractGridComponent.getStatusCellColor }, width: 120 }),
    new SyslinkColumn({ field: "RRule", label: "Repeat", visible: true, cellTemplate: 'rrule-cell' }),
    new SyslinkColumn({ field: "GenerateDocumentNextDate", label: "GenerateDocumentNextDate", type: 'date', visible: true, width: 110, filterable: false, sortable: false, headerFilterable: false }),
    new SyslinkColumn({ field: "ExternalReference", label: "External reference", visible: true, width: 150 }),
    new SyslinkColumn({ field: "Deadline", label: "SaleContract.Validity", type: 'date', width: 110 }),
    new SyslinkColumn({ field: "ResponsibleUserId.Fullname", label: "Responsible", width: 120 }),
    new SyslinkColumn({ field: "CurrentStatusLink.StatusId.Sequence", label: 'status order', sortIndex: 1, sortDirection: 'asc', width: 0, visible: false })
  ];
  onAddButtonClicked() {
    window.open('/sales/contracts/new?ThirdId='+this.element.Id);
  }
 
}