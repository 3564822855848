import { Injectable } from '@angular/core';
import { LoadOptions } from 'devextreme/data';
import { ApiService } from '../../../core/api.service';
import { PurchaseDocumentLinesService } from '../../purchase-documents/purchase-document-lines/purchase-document-lines.service';
import { PurchaseInvoiceLine } from './purchase-invoice-line.model';

@Injectable({
  providedIn: 'root'
})
export class PurchaseInvoiceLinesService extends PurchaseDocumentLinesService<PurchaseInvoiceLine> {
  public override url: string = 'PurchaseInvoiceLine';
  public override documentType: string = 'PurchaseInvoice';
  public override defaultOptions: LoadOptions = {
    expand: ['Discount', 'ParentId']
  };

  constructor(public override api: ApiService) {
    super(api);
  }

  public override async getInstance(quote?: Partial<PurchaseInvoiceLine>): Promise<any> {
    let element = {
      ...quote,
    };
    return element;
  }

  public async updateParentId(purchaseInvoiceLine: PurchaseInvoiceLine, targetParentId: number) {
    return await this.api.sendRequest('/api/PurchaseDocumentLine/' + purchaseInvoiceLine.Id + '/changeParent/' + targetParentId, 'POST');
  }
}
