import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { InputComponent } from '../input/input.component';
import { DxHtmlEditorComponent } from 'devextreme-angular';
import { Properties, ValueChangedEvent } from 'devextreme/ui/html_editor';
import { SyslinkToolbarAction } from '../../toolbar/toolbar.component';
import { ToolbarService } from '../../toolbar/toolbar.service';

@Component({
  selector: 'syslink-wysiwyg',
  templateUrl: './wysiwyg.component.html',
  styleUrls: ['./wysiwyg.component.scss']
})
export class WysiwygComponent extends InputComponent implements AfterViewInit {


  @Input() public override mode: 'outlined' | 'underlined' | 'filled' = 'outlined';
  public override labelMode: 'static' | 'floating' | 'hidden' = 'floating';

  @Input() public maxLength?: number;
  @Input() public height: string = '500px';
  @Input() public width: string | undefined;
  @Input() public minHeight: string = '200px';

  @Input() public allowSoftLineBreak: boolean = true;

  // Toolbar
  // -------
  @Input() public toolbar: boolean = true;
  @Input() public multilineToolbar: boolean = false;
  @Input() public toolbarActions: SyslinkToolbarAction[] = [];
  @Input() public prependName: boolean = false;

  // Content Styles Options
  // ----------------------
  @Input() public sizeOptions: string[] = ['4pt','5pt','6pt','8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt'];
  @Input() public fontOptions: string[] = [
    'Arial',
    'Courier New',
    'Georgia',
    'Impact',
    'Lucida Console',
    'Tahoma',
    'Times New Roman',
    'Verdana'
  ];
  @Input() public headerOptions: Array<false | number> = [false, 1, 2, 3, 4, 5];

  // Image
  // -----
  @Input() public image: boolean = true;
  public imageCurrentTab: string[] = ['file', 'url'];
  public fileUploadMode: string = 'base64';
  public allowMediaResizing: boolean = true;

  // Table
  // -----
  @Input() public table: boolean = true;

  // Change
  // ------
  public onValueChanged(e: ValueChangedEvent): void {
    this.modelChange.emit(e.value);
  }

  // Mentions
  // --------
  private _mentions: Array<any> = [];
  @Input()
  set mentions(value: Array<any>) {
    this._mentions = value;
    this.htmlEditor?.instance.option('mentions', this._mentions);
  }
  get mentions(): Array<any> {
    return this._mentions;
  }
  @Output() public mentionsChange = new EventEmitter<Array<any>>();

  // HTML Editor
  // -----------
  @ViewChild('htmlEditor') private htmlEditor?: DxHtmlEditorComponent;

  constructor(
    private toolbarService: ToolbarService
  ) {
    super();
  }
  public updateOptions(options: Properties): void {
    this.htmlEditor?.instance.option(options);
  }

  ngAfterViewInit(): void {
    this.toolbarActions.forEach(e => {
      if (this.prependName) {
        e.code = e.code?.split('.').pop();
        e.code = this.name + '.' + e.code;
      }
      this.htmlEditor?.instance.option().toolbar?.items?.push(ToolbarService.generateToolbarItem(e));
    });
    this.htmlEditor?.instance.option(this.htmlEditor?.instance.option());
  }

  ngOnInit(): void {    
    // this.updateOptions();
  }
}
