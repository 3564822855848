import { AddressType } from "projects/erp-app/src/app/base/addresses/address-types/address-type";

export class ThirdAddressType extends AddressType {

}

export enum ThirdAddressTypeCodes {
    HeadOffice = 'HeadOffice',
    Warehouse = 'Warehouse',
    Home = 'Home',
}

export enum ThirdAddressTypeIds {
    HeadOffice = 1,
    Warehouse = 2,
    Home = 3,
}