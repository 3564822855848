import { Injectable } from '@angular/core';
import { PaymentState } from './payment-state.model';
import { ODataService } from '../../../core/services/oData.service';
import { LoadOptions } from 'devextreme/data';

@Injectable({
  providedIn: 'root'
})
export class PaymentStatesService extends ODataService<PaymentState> {
  public url = 'PaymentState';
  public override defaultOptions: LoadOptions = {
    expand: [     
    ]
  };
}
