<div class="row">
    <div class="col-3 mb-2" *ngFor="let item of element.Report?.AdditionalInformation?.CompanySignificantEvents">
        <div class="card">
            <div *ngIf="item.EventDate" class="card-header">{{ item.EventDate | date : "dd/MM/yyyy" }}</div>
            <div *ngIf="item.EventDetails || item.EventDescription" class="card-body">
                <p *ngIf="item.EventDetails" class="card-text">{{item.EventDetails}}</p>
                <p *ngIf="item.EventDescription" class="card-text">{{item.EventDescription}}</p>
            </div>
        </div>
    </div>
</div>