import { Component, Input, OnInit } from '@angular/core';
import { ProductsService } from '../product.service';
import { ChartSerie } from 'projects/libraries/syslink-components/src/lib/helpers/ChartSeries';
import { TranslateService } from '@ngx-translate/core';
import { PageComponent } from 'projects/libraries/syslink-components/src/public-api';
import { Product } from '../product.model';

@Component({
  selector: 'app-product-statistics',
  templateUrl: './product-statistics.component.html',
  styleUrls: ['./product-statistics.component.scss']
})
export class ProductStatisticsComponent extends PageComponent implements OnInit {

  @Input() public element: Product = new Product();
  
  public confirmedSaleQuoteExTaxTotalPerMonth: any[] = [];

  public validatedVentilatedSaleInvoiceExTaxTotalPerMonth: any[] = [];

  public validatedVentilatedSaleCreditNoteExTaxTotalPerMonth: any[] = [];

  public validatedVentilatedPurchaseInvoiceExTaxTotalPerMonth: any[] = [];

  public validatedVentilatedPurchaseCreditNoteExTaxTotalPerMonth: any[] = [];


  private _startYear: number;
  public set startYear(value: number) {
    this._startYear = value;
    this.onYearChanged();
  }
  public get startYear() {
    return this._startYear;
  }

  private _endYear: number;
  public set endYear(value: number) {
    this._endYear = value;
    this.onYearChanged();
  }
  public get endYear() {
    return this._endYear;
  }

  private static monthNames: string[] = ProductStatisticsComponent.initMonthNames();

  public series: ChartSerie[] = [

  ];

  @Input() public productId!: number;


  constructor(private productService: ProductsService) {
    super();
    this._startYear = new Date().getFullYear();
    this._endYear = new Date().getFullYear();
  }

  override async ngOnInit(): Promise<void> {
    this.refreshData();
  }

  private async refreshData() {
    const data = await this.productService.getStatisticsForProduct(this.productId, this.startYear, this.endYear);

    this.resetData();

    for (let yearlyProductStatistic of data) {
      const year = yearlyProductStatistic.year;
      if (!year) continue;
      this.series.push(this.createChartSeriesForYear(yearlyProductStatistic.year));

      let formatter = this.getChartSeriesFormatter(year, this.confirmedSaleQuoteExTaxTotalPerMonth);
      this.confirmedSaleQuoteExTaxTotalPerMonth = yearlyProductStatistic
        .confirmedSaleQuoteExTaxTotalPerMonth.map(formatter);

      formatter = this.getChartSeriesFormatter(year, this.validatedVentilatedSaleInvoiceExTaxTotalPerMonth);
      this.validatedVentilatedSaleInvoiceExTaxTotalPerMonth = yearlyProductStatistic
        .validatedVentilatedSaleInvoiceExTaxTotalPerMonth.map(formatter);

      formatter = this.getChartSeriesFormatter(year, this.validatedVentilatedSaleCreditNoteExTaxTotalPerMonth);
      this.validatedVentilatedSaleCreditNoteExTaxTotalPerMonth = yearlyProductStatistic
        .validatedVentilatedSaleCreditNoteExTaxTotalPerMonth.map(formatter);

      formatter = this.getChartSeriesFormatter(year, this.validatedVentilatedPurchaseInvoiceExTaxTotalPerMonth);
      this.validatedVentilatedPurchaseInvoiceExTaxTotalPerMonth = yearlyProductStatistic
        .validatedVentilatedPurchaseInvoiceExTaxTotalPerMonth.map(formatter);

      formatter = this.getChartSeriesFormatter(year, this.validatedVentilatedPurchaseCreditNoteExTaxTotalPerMonth);
      this.validatedVentilatedPurchaseCreditNoteExTaxTotalPerMonth = yearlyProductStatistic
        .validatedVentilatedPurchaseCreditNoteExTaxTotalPerMonth.map(formatter);

    }
  }

  private resetData(){
      this.series = [];
      this.confirmedSaleQuoteExTaxTotalPerMonth = [];
      this.validatedVentilatedSaleInvoiceExTaxTotalPerMonth = [];
      this.validatedVentilatedSaleCreditNoteExTaxTotalPerMonth = [];
      this.validatedVentilatedPurchaseInvoiceExTaxTotalPerMonth = [];
      this.validatedVentilatedPurchaseCreditNoteExTaxTotalPerMonth = [];
  }

  private createChartSeriesForYear(year: number | undefined) {
    return new ChartSerie({ argumentField: "month", valueField: year?.toString(), name: year?.toString() ?? "unknown", type: "stackedBar" });
  }


  private onYearChanged() {
    this.refreshData();
  }


  /**
   * Inspired from https://stackoverflow.com/a/71512162
   */
  private static initMonthNames(): string[] {
    let result: string[] = [];
    let d = new Date(2000, 0); // January
    for (let i = 0; i < 12; i++) {
      result.push(d.toLocaleString('default', { month: 'long' }));
      d.setMonth(i + 1);
    }
    return result;
  }

  private getChartSeriesFormatter(year: number | undefined, currentData: any[]) {
    return (value: number, index: number) => {
      let monthName = ProductStatisticsComponent.monthNames[index];
      let existingData = currentData.find(el => el.month === monthName);
      let jsonObjectString = `{"month": "${ProductStatisticsComponent.monthNames[index]}","${year}": ${value}}`;
      return { ...existingData, ...JSON.parse(jsonObjectString) };
    };
  }
}
