import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ViewComponent } from '../../../../../../libraries/syslink-components/src/lib/helpers/view/view.component';
import { LegalFormsService } from '../../legal-forms/legal-forms.service';
import { Third } from '../third.model';

@Component({
  selector: 'app-third-professional-form',
  templateUrl: './third-professional-form.component.html',
  styleUrls: ['./third-professional-form.component.scss']
})
export class ThirdProfessionalFormComponent extends ViewComponent {
  @Input() public element: Third = new Third();
  @Input() public disabled: boolean = false;
  @Input() public isAutocompleteLoading: boolean = false;
  @Input() public vatDisabled: boolean = false;
  @Output() public elementChange: EventEmitter<Third> = new EventEmitter<Third>();

  @Output() public onVatChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() public onThirdFullAutocomplete: EventEmitter<string> = new EventEmitter<string>();

  private lastVatValueInput: string = "";
  constructor(
    public legalFormsService: LegalFormsService
  ) {
    super();
  }

  /**
   * update the company number and the vat number when the company number input change (for belgium)
   * @param $event 
   */
  onCompanyNumberInputChange($event: string): void {
    this.formatCompanyNumber($event);
    this.formatVatWithCompanyNumber($event);
    // console.log(this.element.CompanyNumber);
  }

  /**
   * update the company number when copy-paste
   * @param $event 
   */
  onCompanyNumberPaste($event: ClipboardEvent): void {
    let pastedText = this.getDataFromPaste($event);
    if (pastedText) {
      this.formatCompanyNumber(pastedText);
    }
  }

  /**
   * remove unwanted char from the company number
   * @param $event 
   */
  formatCompanyNumber($event: string): void {
    $event = $event.replace(/[^0-9]{0,}/g, '');
    this.element.CompanyNumber = $event.substring(0, 9);
    this.formatVatWithCompanyNumber(this.element.CompanyNumber);
  }

  /**
   * format the vat from the company number
   * @param $event 
   */
  formatVatWithCompanyNumber($event: string): void {
    if (this.element.CountryId.Code === 'BE') {
      this.formatVat("BE0" + $event);
    }
  }

  /**
   * update the vat number when the vat input change (for belgium)
   * @param $event 
   */
  onVatNumberInputUpdate($event: string): void {
    this.formatVat($event);
  }

  /**
   * update the vat number when copy-paste
   * @param $event 
   */
  onVatPaste($event: ClipboardEvent): void {
    let pastedText = this.getDataFromPaste($event);
    if (pastedText) {
      this.formatVat(pastedText);
    }
  }

  /**
   * format remove unwanted char from vat encoded
   * @param $event 
   */
  formatVat($event: string): void {
    $event = $event.replace(/[^a-zA-Z0-9]{0,}/g, '')
    $event = $event.substring(0, 12)
    this.element.VatNumber = $event;
    if (this.lastVatValueInput !== $event && this.element.VatNumber.length > 11) {
      this.lastVatValueInput = $event;
      this.onVatChange.emit(this.element.VatNumber);
    }
  }

  private getDataFromPaste($event: ClipboardEvent): string | undefined {
    $event.preventDefault();
    let clipboardData = $event.clipboardData;
    let pastedText = clipboardData?.getData('text');
    return pastedText;
  }

  onCompleteInformationsButtonClick(): void {
    this.onThirdFullAutocomplete.emit();
  }
}
