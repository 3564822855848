import { Injectable } from '@angular/core';
import { SaleDocumentStatusesService } from '../../sale-documents/sale-document-statuses/sale-document-statuses.service';
import { SaleQuoteHeaderStatus } from './sale-quote-header-status.model';

@Injectable({
  providedIn: 'root'
})
export class SaleQuoteHeaderStatusesService extends SaleDocumentStatusesService<SaleQuoteHeaderStatus> {
  public override url: string = 'SaleQuoteHeaderStatus';
 
  constructor() {
    super();
  }
}
