<div class="row">
    <div class="col-sm-3">
        <syslink-input-text
            name="ExternalReference"
            label="External reference"
            [(model)]="element.ExternalReference"
            [disabled]="disabled"
        ></syslink-input-text>
    </div>
</div>

<div class="row">
    <div class="col-12">
        <syslink-wysiwyg
            name="htmlEditor-Note"
            label="Note"
            [(model)]="element.Note"
            [table]="false"
            [multilineToolbar]="true"
            height="200"
            [disabled]="disabled"
        ></syslink-wysiwyg>
    </div>
</div>