import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ODataService } from 'projects/erp-app/src/app/core/services/oData.service';
import { NotificationsService, PageComponent, SyslinkToolbarAction, SyslinkToolbarActionButton } from 'projects/libraries/syslink-components/src/public-api';
import { Configuration } from '../../configurations/configuration.model';
import { ConfigurationsService } from '../../configurations/configurations.service';
import { SubModule } from '../../sub-modules/sub-module.model';
import { Module } from '../module.model';
import { ModulesService } from '../modules.service';
import { EventsService } from 'projects/libraries/syslink-components/src/lib/events/events.service';

@Component({
  selector: 'app-module-details',
  templateUrl: './module-details.component.html',
  styleUrls: ['./module-details.component.scss']
})
export class ModuleDetailsComponent extends PageComponent {
  public element: Module = new Module();
  private backupElement: Module = new Module();
  public override toolbarActions: SyslinkToolbarAction[] = [
    new SyslinkToolbarActionButton({ icon: 'save', text: 'Save', onClick: () => { this.onSaveBtnClicked() }, hotkey: 'control.s' })
  ];

  constructor(
    public modulesService: ModulesService,
    public override activatedRoute: ActivatedRoute,
    private configurationsService: ConfigurationsService,
    private eventsService: EventsService
  ) {
    super();
    this.activatedRoute.data.subscribe(async ({ element, typeId, roleId }) => {
      this.element = element;
      this.element.SubModules = this.element.SubModules?.filter((sm) => sm.Code !== 'Sales.Contract');
      this.backupElement = JSON.parse(JSON.stringify(element));
      this.updateBreadCrumb(this.element.Name);
    });
  }

  public getAutoformStore(field: Configuration) {
    if (!field.WebService) return;

    return ODataService.generateStore(field.WebService);
  }

  public getAutoformItems(field: Configuration) {
    return field.Items;
  }

  public async onSaveBtnClicked() {
    if (!this.authService.hasPermission(this.basePermissionKey + "." + this.element.Code + '.update')) {
      NotificationsService.sendErrorMessage("You do not have the required permission!");
      return;
    }
    let originalConfigurations: any[] = [];
    this.backupElement.SubModules?.forEach((e: SubModule) => originalConfigurations = [...originalConfigurations, ...e.Configurations]);
    originalConfigurations = originalConfigurations.map((e: Configuration) => {
      return { Id: e.Id, Value: typeof (e.Value) == 'object' ? e.Value.Id.toString() : e.Value.toString() };
    });

    let newConfigurations: any[] = [];
    this.element.SubModules?.forEach((e: SubModule) => newConfigurations = [...newConfigurations, ...e.Configurations]);
    newConfigurations = newConfigurations.map((e: Configuration) => {
      return { Id: e.Id, Value: typeof (e.Value) == 'object' ? e.Value.Id.toString() : e.Value.toString() };
    });


    originalConfigurations.every(async (c: Configuration, index: number) => {
      if (c.Value != newConfigurations[index].Value) {
        if (!c.Id) return;
        await this.configurationsService.update(c.Id, this.configurationsService.format(newConfigurations[index]));
      }
    });
    this.backupElement = JSON.parse(JSON.stringify(this.element));

    this.element.SubModules?.forEach((subModule: SubModule) => {
      this.eventsService.emit(ModuleEvents.SaveSubmodule, { subModule });
    });

    NotificationsService.sendSuccess("Record updated");
  }
}

export enum ModuleEvents {
  SaveSubmodule = 'base.modules.submodule.save'
}

export interface SaveSubmoduleEvent {
  subModule: SubModule;
}