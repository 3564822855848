<div class="row">
    <div
        class="col-4"
        *ngIf="element.Id && element.ThirdDocumentData"
    >
        <app-document-data
            #ThirdDocumentData
            [documentId]="element"
            [documentType]="'SaleQuote'"
            [(element)]="element.ThirdDocumentData"
            (elementChange)="documentDataThirdChange($event)"
            [canEdit]="!disabled && canUpdateThird"
            [third]="element.ThirdId"
            [filter]="thirdDocumentDataFilters"
            (elementUsedChange)="documentDataUsedChange($event)"
            [showDelivery]="reportType == 'SaleOrder'?true:false"
        ></app-document-data>
    </div>
     <div
        class="col-4"
        *ngIf="element.Id && element.IntermediateThirdIdDocumentData"
    >
       <!-- <app-document-data
            #IntermediateThirdDocumentData
            [label]="'DocumentDataIntermediateThird'"
            [documentId]="element"
            [documentType]="'SaleQuote'"
            [(element)]="element.IntermediateThirdIdDocumentData"
            (elementChange)="documentDataIntermediateThirdChange($event)"
            [canEdit]="!disabled"
            [third]="element.IntermediateThirdId"
            [filter]="IntermediateThirdDocumentDataFilters"
            (elementUsedChange)="documentDataUsedChange($event)"
        ></app-document-data>-->
    </div> 

    <!-- <div class="col-4">
        {{"Sponsor" | translate}}
        <app-sale-quote-third-detail name="sponsor" [(element)]="element" dataField="sponsorDocumentData" valueField="sponsorId"></app-sale-quote-third-detail> 
    </div>-->

    <div class="col-4">
        <div>
            <syslink-select
                label="Responsible"
                [store]="thirdsService.store"
                filter="UserId ne null"
                detailsUrl="/admin/customers/"
                displayKey="Fullname"
                [(model)]="element.ResponsibleUserId"
                [canView]="authService.hasPermission('settings-thirds-thirds')"
                [disabled]="disabled"
            ></syslink-select>
        </div>
        <div
            class="mt-3 p-3 border border-secondary"
            *ngIf="element.ResponsibleUserId"
        >
            <syslink-input-text
                label="Name"
                [(model)]="element.ResponsibleUserId.Fullname"
                [disabled]="true"
            ></syslink-input-text>

            <div *ngIf="element.ResponsibleUserId.DisplayEmail">
                <syslink-input-text
                    label="Email"
                    [disabled]="true"
                    [(model)]="element.ResponsibleUserId.DisplayEmail"
                ></syslink-input-text>
            </div>

            <div *ngIf="element.ResponsibleUserId.DisplayPhoneNumber">
                <syslink-input-text
                    label="Phone"
                    [disabled]="true"
                    [(model)]="element.ResponsibleUserId.DisplayPhoneNumber"
                ></syslink-input-text>
            </div>
        </div>
    </div>
</div>