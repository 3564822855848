import { Component, Input, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ButtonType } from 'devextreme/common';
import { NativeEventInfo, ItemInfo } from 'devextreme/events';
import { Options as DropDownOptions } from 'devextreme/ui/drop_down_button';
import dxMenu, { Item, dxMenuItem, dxMenuOptions } from 'devextreme/ui/menu';
import { Properties } from 'devextreme/ui/popup';
import { ToolbarItemLocation } from 'devextreme/ui/toolbar';
import { ModalComponent } from '../modal/modal.component';
import { FileManagerComponent } from '../file-manager/file-manager.component';

export type SyslinkToolbarAction = SyslinkToolbarActionButton | SyslinkToolbarActionDropDownButton | SyslinkToolbarActionMenu | SyslinkToolbarFileButton;

export class BaseSyslinkToolbarAction {
  public code?: string;
  public hint?: string;
  public hotkey?: string;
  public visible?: boolean = true;
  public elementAttr?: any;

  public inMenu: 'always' | 'auto' | 'never' = 'never';
  public location: ToolbarItemLocation = 'before';

  constructor(configuration?: Partial<BaseSyslinkToolbarAction>) {
    Object.assign(this, configuration);
    this.elementAttr = { id: this.code };
  }
}

class BaseSyslinkToolbarActionComponent extends BaseSyslinkToolbarAction {
  public textColor?: string;
  public bgColor?: string;
  public classes?: string;
  public type?: ButtonType = 'normal';
  public border?: string = 'auto';

  public icon?: string;
  public onClick?: Function;
  public text: string = '';
}

export class SyslinkToolbarActionButton extends BaseSyslinkToolbarActionComponent {
  public widget: 'dxButton' = 'dxButton';

  constructor(configuration?: Partial<SyslinkToolbarActionButton>) {
    super(configuration);
    Object.assign(this, configuration);
  }
}

export class SyslinkToolbarActionDropDownButton extends BaseSyslinkToolbarActionComponent implements DropDownOptions {
  public widget: 'dxDropDownButton' = 'dxDropDownButton';
  public items?: Array<{ text: string, onClick: Function, visible: boolean }> = [];
  public useItemTextAsTitle?: boolean | undefined = false;
  public dropDownOptions?: Properties | undefined;

  constructor(configuration?: Partial<SyslinkToolbarActionDropDownButton>) {
    super(configuration);
    Object.assign(this, configuration);
  }
}

interface SyslinkToolbarActionMenuItem extends Item {
  onClick?: Function;
  items?: SyslinkToolbarActionMenuItem[]
}

export class SyslinkToolbarActionMenu extends BaseSyslinkToolbarActionComponent implements dxMenuOptions {
  public widget: 'dxMenu' = 'dxMenu';

  public items: SyslinkToolbarActionMenuItem[] = [];
  public hideSubmenuOnMouseLeave: boolean = true;
  public onItemClick?: string | ((e: NativeEventInfo<dxMenu<any>, MouseEvent | PointerEvent> & ItemInfo<SyslinkToolbarActionMenuItem>) => void) | undefined;

  constructor(configuration?: Partial<SyslinkToolbarActionMenu>) {
    super();
    Object.assign(this, configuration);
  }
}

export class SyslinkToolbarFileButton extends SyslinkToolbarActionButton {
  public entityType?: string;
  public entityId?: number | string;

  constructor(configuration?: Partial<SyslinkToolbarFileButton>) {
    super(configuration);
    Object.assign(this, configuration);

    this.icon = 'fa-solid fa-folder';
    this.code = 'Files';
    this.text = 'Files';
  }
}

@Component({
  selector: 'syslink-toolbar',
  templateUrl: './toolbar.component.html'
})
export class ToolbarComponent {
  private _actions: Array<SyslinkToolbarAction> = [];
  @Input() public set actions(value: any) {
    this._actions = value;
    this.initActions();
  }

  public get actions(): Array<SyslinkToolbarAction> {
    return this._actions;
  }

  @Input() public withLine: boolean = true;

  constructor(
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.initActions();
  }

  private initActions() {
    this.actions.forEach((action: SyslinkToolbarAction) => {
      switch (action.widget) {
        case 'dxMenu':
          this.translateMenuItems(action.items);
          this.hideItemsWithoutChild(action.items);
          break;

        default:
          // File
          // ----
          if (action.hasOwnProperty('entityType') && action.hasOwnProperty('entityId')) {
            action.onClick = () => {
              if (!this.fileManager) return;
              this.fileManager.entityType = (action as SyslinkToolbarFileButton).entityType;
              this.fileManager.entityId = (action as SyslinkToolbarFileButton).entityId;
              this.fileManagerModal.toggle();
            }
          }

          // Others
          // ------
          if (action.text) {
            action.text = this.translateService.instant(action.text);
          }

          if (!action.hint || action.hint === '') {
            action.hint = action.text;
          } else if (action.hint) {
            action.hint = this.translateService.instant(action.hint);
          }

          if (action.widget === 'dxDropDownButton') {
            action.items?.forEach((item: any) => {
              item.text = this.translateService.instant(item.text);
            });
          }
          break;
      }
    });
  }

  // Menu
  // ----
  private translateMenuItems(items: Array<dxMenuItem>) {
    for (const item of items) {
      if (item.text) { item.text = this.translateService.instant(item.text); }
      if (item.items) { this.translateMenuItems(item.items); }
    }
  }

  private hideItemsWithoutChild(items: Array<dxMenuItem>) {
    for (const item of items) {
      if (item.items) {
        this.hideItemsWithoutChild(item.items);        
        if (this.flatChildrens(item).every((item: dxMenuItem) => !item.visible)) { item.visible = false; }
      }
    }
  }

  private flatChildrens(item: dxMenuItem): Array<dxMenuItem> {
    const childrens: Array<dxMenuItem> = [];
    if (item.items) {
      for(item of item.items){
        childrens.push(...this.flatChildrens(item));
      }
    } else {
      childrens.push(item);
    }
    return childrens;
  }

  // Files
  // -----
  @ViewChild('fileManagerModal') private fileManagerModal: ModalComponent = new ModalComponent();
  @ViewChild('fileManager') private fileManager?: FileManagerComponent;
}
