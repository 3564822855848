import { BasicEntity } from "../../../core/models/basic-entity";

export class TaskPriority extends BasicEntity {
    public Value?:number;

    public NameFormatted?:string;
       
    constructor(entity?: TaskPriority) {
        super();
        Object.assign(this, entity);
    }
}
