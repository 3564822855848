<div
  class="{{class}}"
  [hidden]="hidden"
>
  <dx-tag-box
    #tagbox
    id="tag-box-{{name}}"
    [dataSource]="items ?? dataSource"
    [displayExpr]="displayKey"
    [valueExpr]="valueKey"
    [value]="formatedModel"
    (onValueChanged)="onValueChanged($event)"
    [stylingMode]="mode"
    [label]="getLabel()"
    [labelMode]="labelMode"
    [searchEnabled]="allowSearch"
    [readOnly]="disabled"
    [hideSelectedItems]="true"
    [multiline]="false"
    [showSelectionControls]="showSelectionControls"
    [acceptCustomValue]="acceptCustomValue"
    customItemCreateEvent="focusout"
    (onCustomItemCreating)="onCustomValueChange.emit($event)"
  >
    <div *dxTemplate="let data of 'tag'">
      <div class="dx-tag">
        <div
          class="dx-tag-content text-white"
          [style.background-color]="data[colorKey] ?? '#1a2e38'"
        >
          <div class="d-flex flex-column">
            <div>
              <span>{{getDisplayValue(data)}}</span>
              <div
                id="{{name}}-remove-item-{{data[valueKey]}}"
                (click)="removeTag(data[valueKey])"
                class="dx-tag-remove-button"
              ></div>
            </div>
            <div
              *ngIf="emailLabel"
              class="mt-1"
            ><span>{{getEmail(data)}}</span></div>
          </div>
        </div>
      </div>
    </div>

    <div *dxTemplate="let data of 'item'">
      <div class="dx-tag">
        <div
          id="{{name}}-select-item-{{data[valueKey]}}"
          class="dx-tag-content text-white px-2 py-1"
          [style.background-color]="data[colorKey] ?? '#1a2e38'"
        >
          <div class="d-flex flex-column">
            <span>{{getDisplayValue(data)}}</span>
            <span
              *ngIf="emailLabel"
              class="mt-1"
            >{{getEmail(data)}}</span>
          </div>
        </div>
      </div>
    </div>
  </dx-tag-box>
</div>
