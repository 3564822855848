import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'syslink-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnInit {
  @Input() public name?: string;

  @Input() public width: string = '100%';
  @Input() public height: string = '0.8rem';

  @Input() public fillColor: string = '#ff0000';
  @Input() public textColor: string = '#ffffff';
  @Input() public borderColor?: string;

  @Input() public min: number = 0;
  @Input() public max: number = 100;

  @Input() public canPercentOverflow: boolean = true;

  @Input() public isPercentDisplayMode: boolean = true;
  @Input() public type: 'default' | 'duration' = 'default';

  public currentPercentProgress: number = 0;
  private _value: number = 0;
  @Input() set value(value: number) {
    this._value = value;
    if (this.max == 0) {
      this.currentPercentProgress = 0;
      return;
    }
    this.currentPercentProgress = Math.floor((100 / this.max) * this._value);
    if (this.currentPercentProgress > this.max && !this.canPercentOverflow) this.currentPercentProgress = this.max;
    if (this.currentPercentProgress < this.min) this.currentPercentProgress = this.min;
  }

  ngOnInit(): void {
    if (this.max < this.min) {
      console.error('Max value should be bigger than min value');
    }
  }

  public getDisplayMode(): string | number {
    if (this.isPercentDisplayMode) {
      return `${this.currentPercentProgress}%`;
    } else {
      switch (this.type) {
        case 'duration':
          return `${Math.floor(this._value / 60).toString()}:${(this._value % 60).toString().padStart(2, '0')}`;
        default:
          return this._value;
      }
    }
  }

  public onModeSwitch(): void {
    this.isPercentDisplayMode = !this.isPercentDisplayMode;
  }
}