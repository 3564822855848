import { Injectable } from '@angular/core';
import { ODataService } from '../../../core/services/oData.service';
import { LoadOptions } from 'devextreme/data';
import { TranslateService } from '@ngx-translate/core'
import { TaskTag } from './task-tag.model';

@Injectable({
  providedIn: 'root'
})
export class TaskTagsService extends ODataService<TaskTag> {
  public url = 'TaskTag';
  public override defaultOptions: LoadOptions = {
    expand: ['NameTranslationId.Translations.LanguageId']
  };

  constructor(
    private translateService: TranslateService
  ) {
    super();
  }

  public override format(element: Partial<TaskTag>): TaskTag {
    var result: any = {
      ...element,
    };
    delete result.Tasks;
    return result;
  }

  public override async getInstance(params?: Partial<TaskTag>): Promise<TaskTag> {
    var element = new TaskTag({
      Name: this.translateService.instant('New task tag'),
      ...params
    });
    return element;
  }
}
