<div class="row">
  <div class="col-4">
    <syslink-select
      label="Subjugation category"
      [store]="subjugationCategoriesService.store"
      [(model)]="element.SubjugationCategoryId"
    ></syslink-select>
  </div>
  <div class="col-4">
    <syslink-select
      label="Language"
      [store]="languagesService.store"
      [(model)]="element.LanguageId"
    ></syslink-select>
  </div>
  <div class="col-4">
    <syslink-select
      label="Country"
      [store]="countriesService.store"
      [(model)]="element.CountryId"
      [filter]="['IsVisible eq true']"
    ></syslink-select>
  </div>
  <div class="row mt-3">
    <app-bank-account-grid
      *ngIf="element.Id"
      [filter]="['ThirdId.Id eq ' + element.Id]"
      [expand]="['ThirdId', 'BankId']"
      (beforeAdd)="onBeforeAddBankAccount($event)"
      [permissionKey]="'other.bankAccounts.list'"
    ></app-bank-account-grid>
  </div>
  <div class="row mt-3">
    <app-number-plate-grid
      *ngIf="element.CustomerId?.Id"
      [permissionKey]="'other.numberPlate.list'"
      [filter]="['CustomerId.Id eq '+ element.CustomerId?.Id]"
      storageKey="grid-third-other-numberPlates"
      (beforeAdd)="onBeforeAddNumberPlate($event)"
    ></app-number-plate-grid>
  </div>
  <div class="col-12 mt-3">
    <syslink-wysiwyg
      name="Note"
      label="Note"
      [(model)]="element.Note"
      [table]="false"
      height="200"
    ></syslink-wysiwyg>
  </div>
</div>