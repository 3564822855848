import { Injectable } from '@angular/core';
import { ODataService } from '../../core/services/oData.service';
import { Brand } from './brand.model';

@Injectable({
  providedIn: 'root'
})
export class BrandsService extends ODataService<Brand> {
  public url = 'Brand';
}
