import { Component } from '@angular/core';
import { AppInjectorService } from 'projects/libraries/syslink-components/src/lib/services/app-injector.service';
import { SaleOrderHeaderHeaderStatusesService } from '../sale-order-header-header-statuses/sale-order-header-header-statuses.service';
import { SaleOrderHeaderHeaderStatus } from '../sale-order-header-header-statuses/sale-order-header-header-status.model';
import { SaleOrderHeaderStatusesService } from '../sale-order-header-statuses/sale-order-header-statuses.service';
import { SaleOrderHeaderStatus } from '../sale-order-header-statuses/sale-order-header-status.model';
import { SaleOrderLinesService } from '../sale-order-lines/sale-order-lines.service';
import { SaleOrderLine } from '../sale-order-lines/sale-order-line.model';
import { SaleOrdersService } from '../sale-orders/sale-orders.service';
import { SaleOrder } from '../sale-orders/sale-order.model';
import { SaleDocumentDetailsComponent } from '../../sale-documents/sale-documents/sale-document-details/sale-document-details.component';

@Component({
  selector: 'app-sale-order-details',
  templateUrl: '../../sale-documents/sale-documents/sale-document-details/sale-document-details.component.html',
  styleUrls: ['./sale-order-details.component.scss']
})
export class SaleOrderDetailsComponent extends SaleDocumentDetailsComponent<
  SaleOrder,
  SaleOrdersService,
  SaleOrderLine,
  SaleOrderLinesService,
  SaleOrderHeaderStatus,
  SaleOrderHeaderStatusesService,
  SaleOrderHeaderHeaderStatus,
  SaleOrderHeaderHeaderStatusesService
>{

  protected override loadDependencies() {
    this.saleDocumentService = AppInjectorService.injector.get(SaleOrdersService);
    this.saleDocumentLinesService = AppInjectorService.injector.get(SaleOrderLinesService);
    this.saleDocumentStatusesService = AppInjectorService.injector.get(SaleOrderHeaderStatusesService);
    this.saleDocumentHeaderStatusesService = AppInjectorService.injector.get(SaleOrderHeaderHeaderStatusesService);

    this.reportType = "SaleOrder";
    this.subModuleCode = "Sales.Order";
    this.defaultStatusCode = "Order.Draft";
    this.documentLinesColumns = [...this.saleDocumentService.getDefaultDocumentLineColumns(this.subModuleCode,this.reportType)];
  }

  public override canEditDocument(): boolean {
    return !this.hasActiveStatus("Order.Confirmed");
  }

}
