import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LoadOptions } from 'devextreme/data';
import { Language } from '../../../base/languages/language';
import { ConfigurationsService } from '../../../base/modules/configurations/configurations.service';
import { ApiService } from '../../api.service';
import { ODataService } from '../../services/oData.service';
import { User } from './user.model';

@Injectable({
  providedIn: 'root'
})
export class UsersService extends ODataService<User> {
  public url: string = "User";
  public override keyType: string = "Guid";
  public override key: string = "Oid";
  public override defaultOptions: LoadOptions = {
    expand: ["LanguageId", 
    "UserRoles.NameTranslationId",
    "Groups.NameTranslationId",
    "ActionGroups"
  ]
  };

  constructor(
    private translateService: TranslateService,
    private configurationsService: ConfigurationsService,
    private api: ApiService
  ) {
    super();
  }
  public override async insert(params: Partial<User>): Promise<User> {
    return await this.api.sendRequest('/api/User/CreateUser', 'POST', params);
  }

  public getExpand(){
    return this.defaultOptions.expand;
  }

  public async updatePassword(params: Partial<User>): Promise<User> {
    var result: any = {
      ...params,
      LanguageId: params.LanguageId?.Id,
    };
    return await this.api.sendRequest('/api/User/ChangePassword/' + params.Oid, 'POST', result);
  }

  public override format(element: Partial<User>): User {
   var result: any = {
      ...element,
      LanguageId: element.LanguageId?.Id
    };
    delete result.Password;
    delete result.PasswordConfirmation;
    delete result.AllPermissions;
    delete result.UserRoles;
    delete result.ActionGroups;
    
    return result;
  }

  public override async getInstance(params?: Partial<User>): Promise<User> {
    var user = new User({
      UserName: this.translateService.instant('New user'),
      LanguageId: new Language({ Id: await this.configurationsService.getConfigurationAsNumber('language', 'Base.General', 'Base') }),
      IsActive:true,
      ForegroundColor: await this.configurationsService.getConfigurationAsString("Users.ForegroundColor", "Base.Security", "Base"),
      BackgroundColor: await this.configurationsService.getConfigurationAsString("Users.BackgroundColor", "Base.Security", "Base"),
      ...params
    });
    return user;
  }

  public async removeUserGroup(userId: number | string, groupId: number) {
    return await this.api.sendRequest('/api/odata/User/' + userId + '/Groups/' + groupId + '/$ref', 'DELETE');
  }
  public async removeUserRole(userId: number | string, roleId: number) {
    return await this.api.sendRequest('/api/odata/User/' + userId + '/UserRoles/' + roleId + '/$ref', 'DELETE');
  }
  public async removeUserPermission(userId: number | string, permissionId: number) {
    return await this.api.sendRequest('/api/odata/User/' + userId + '/UserPermissions/' + permissionId + '/$ref', 'DELETE');
  }
  public async removeActionGroup(userId: number | string, actionGroupId: number) {
    return await this.api.sendRequest('/api/odata/User/' + userId + '/ActionGroups/' + actionGroupId + '/$ref', 'DELETE');
  }
}
