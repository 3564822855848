import { TaxRate } from "../../base/tax-rates/tax-rate";
import { Entity } from "../../core/models/entity";
import { Customer } from "../../thirds/customers/customers/customer.model";
import { PriceList } from "../../thirds/customers/price-lists/price-list";
import { ProductCategory } from "../product-categories/product-category.model";
import { Product } from "../products/product.model";
import { CustomerCatalogDiscountOperations } from "./customer-catalog-discount-operations/customer-catalog-discount-operations";

export class CustomerCatalog extends Entity {
    public ExTaxNetPrice?: number;
    public CustomerId?: Customer;
    public ProductCategoryId?: ProductCategory;
    public ProductId?: Product;
    public PriceListId?: PriceList;
    public TaxRateId?: TaxRate;
    public DiscountId?: CustomerCatalogDiscountOperations;
    public MarginId?: CustomerCatalogDiscountOperations;
    public PenaltyId?: CustomerCatalogDiscountOperations;
    
    constructor(entity?: Partial<CustomerCatalog>) {
        super(entity);
        Object.assign(this, entity);
    }
}
