import { Component, Input } from '@angular/core';
import { CreditsafeData } from '../creditsafe.model';

@Component({
  selector: 'app-creditsafe-company-significant-events',
  templateUrl: './creditsafe-company-significant-events.component.html',
  styleUrls: ['./creditsafe-company-significant-events.component.scss']
})
export class CreditsafeCompanySignificantEventsComponent {
  @Input() element: CreditsafeData = new CreditsafeData();
}