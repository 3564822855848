import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'syslink-google-map',
  templateUrl: './google-map.component.html',
  styleUrls: ['./google-map.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GoogleMapComponent {
  @Input() public query?: string = '';

  public baseUrl: string = 'https://www.google.com/maps/embed/v1/place?key=AIzaSyBgP226gepCc0IHEWx5beawmFy8iHAKvTI&q=';

  constructor(
    public domSanitizer: DomSanitizer
  ) { }
}
