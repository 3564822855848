import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { SyslinkDataSource } from '../helpers/SyslinkDataSource';
import ODataStore from 'devextreme/data/odata/store';
import ArrayStore from 'devextreme/data/array_store';
import { DxSortableComponent } from 'devextreme-angular';
import { CrudComponent } from '../helpers/crud-component/crud-component.component';
import { SyslinkToolbarAction, SyslinkToolbarActionButton } from '../toolbar/toolbar.component';
import { ODataService } from 'projects/erp-app/src/app/core/services/oData.service';

@Component({
  selector: 'syslink-kanban',
  templateUrl: './kanban.component.html',
  styleUrls: ['./kanban.component.scss']
})
export class KanbanComponent extends CrudComponent implements OnInit {
  @Input() public service?: ODataService<any>;
  @Input() public store?: ODataStore | ArrayStore;

  @Input() public columns: any[] = [];
  @Input() public key: string = 'Id';
  @Input() public options: any = {};

  public columnDatasources: SyslinkDataSource[] = [];

  override ngOnInit(): void {
    super.ngOnInit();
    this.initDatasource();
    this.initOptions();
  }

  private initDatasource() {
    this.columns.forEach(column => {
      let datasource = new SyslinkDataSource({
        store: this.store,
        expand: column.expand,
        filter: column.filter,
        // select: column.select && column.select.length > 0 ? column.select : [this.key, ...this.columns.map((c: any) => c.field)],
      });
      datasource.load();
      this.columnDatasources.push(datasource);
    });
  }

  private initOptions() {
    this.initToolbar();
  }

  private initToolbar() {
    // BEFORE LOCATION
    // ---------------
    // Custom Add
    // ----------
    this.toolbarActions.unshift(new SyslinkToolbarActionButton({
      code: 'add',
      icon: 'plus',
      location: 'before',
      onClick: () => this.add.emit()
    }));

    // this.toolbarActions.push(new BaseSyslinkToolbarAction({ code: 'searchPanel' }));

  }
  public onDragStart(e: any) {
    e.itemData = e.fromData[e.fromIndex];
  }
  // public onReorder(e: any) {
  //   e.fromData.splice(e.fromIndex, 1);
  //   e.toData.splice(e.toIndex, 0, e.itemData);
  // }
  public onAdd(e: any) {
    e.fromData.splice(e.fromIndex, 1);
    e.toData.splice(e.toIndex, 0, e.itemData);
  }

  // Toolbar
  // -------
  @Input() public toolbarActions: Array<SyslinkToolbarAction> = [];

  // Kanban
  // ------
  @ViewChild('kanban') kanban?: DxSortableComponent;

  public refresh(): void {
    this.kanban?.instance.update()
  }

}
