import { PurchaseDocumentHeaderStatus } from "../../purchase-documents/purchase-document-header-status/purchase-document-header-status.model";
import { PurchaseInvoiceHeaderStatus } from "../purchase-invoice-header-statuses/purchase-invoice-header-status.model";
import { PurchaseInvoice } from "../purchase-invoices/purchase-invoice.model";

export class PurchaseInvoiceHeaderHeaderStatus extends PurchaseDocumentHeaderStatus {
    public override HeaderId?: PurchaseInvoice;
    public override StatusId?: PurchaseInvoiceHeaderStatus = new PurchaseInvoiceHeaderStatus();

    constructor(entity?: Partial<PurchaseInvoiceHeaderHeaderStatus>) {
        super();
        Object.assign(this, entity);
    }

}