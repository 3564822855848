import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { BaseModalComponent } from 'projects/libraries/syslink-components/src/lib/modal/base-modal/base-modal.component';
import { UserGroup } from '../user-group.model';
import { UserGroupsService } from '../user-groups.service';
import { FilterDescriptor } from 'devextreme/data';
import { NotificationsService, SelectComponent } from 'projects/libraries/syslink-components/src/public-api';

@Component({
  selector: 'app-user-group-select-modal',
  templateUrl: './user-group-select-modal.component.html',
  styleUrls: ['./user-group-select-modal.component.scss']
})
export class UserGroupSelectModalComponent extends BaseModalComponent<UserGroup>{

  @Input() public selectFilters: FilterDescriptor | Array<FilterDescriptor>=["IsActive eq true"];
  @ViewChild('select') select?: SelectComponent;

  constructor(public userGroupsService: UserGroupsService) {
    super();
  }

  // public override open(data?: any): void {
  //   if (this.select) {
  //     console.log(this.select?.dataSource.items());
  //     if (this.select?.dataSource.totalCount() == 0) {
  //       NotificationsService.sendInfo("No user group available");
  //       return;
  //     }
  //   }
  //   super.open(data);

  // }
}
