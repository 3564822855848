import { Injectable } from '@angular/core';
import { ODataService } from '../../../core/services/oData.service';
import { PriceList } from './price-list';

@Injectable({
  providedIn: 'root'
})
export class PriceListsService extends ODataService<PriceList> {
  public url = 'PriceList';

}