import { Entity } from "../../../core/models/entity";
import { Locality } from "../localities/locality";


export class PostalCode extends Entity {

  public Value?: string;
  public Localities: Array<Locality> = [];

  constructor(postalCode?: Partial<PostalCode>) {
    super(postalCode);
    Object.assign(this, postalCode);
  }
}
