import { Injectable } from '@angular/core';
import { LoadOptions } from 'devextreme/data';
import { StockMoveDocumentHeaderStatusesService } from '../../stock-move-documents/stock-move-document-header-status/stock-move-document-header-statuses.service';
import { StockMoveInternalHeaderHeaderStatus } from './stock-move-internal-header-header-status.model';

@Injectable({
  providedIn: 'root'
})
export class StockMoveInternalHeaderHeaderStatusesService extends StockMoveDocumentHeaderStatusesService<StockMoveInternalHeaderHeaderStatus> {
  public override url: string = 'StockMoveInternalHeaderHeaderStatus';
  public override defaultOptions: LoadOptions = {
    expand: ["StatusId"]
  };

}
