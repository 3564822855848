<syslink-scheduler
  #scheduler
  [toolbarActions]="toolbarActions"
  [editModal]="editModal?.modal"
  [service]="workTimesService"
  [filter]="filter"
  [canRefresh]="canRefresh"
  textAppointmentField="ThirdId.Fullname"
  (add)="add.emit($event)"
  (selectionChanged)="selectionChanged.emit($event)"
  [appointmentContextMenuItems]="appointmentContextMenuItems"
  [storageKey]="storageKey"
></syslink-scheduler>
<app-work-time-modal
  #editModal
  (validate)="validate.emit($event)"
></app-work-time-modal>
