import { Component, ViewChild } from '@angular/core';
import { NotificationsService, PageComponent } from 'projects/libraries/syslink-components/src/public-api';
import { PurchaseInvoicesService } from './purchase-invoices.service';
import { PurchaseInvoice } from './purchase-invoice.model';
import { ContextMenuItemAction } from 'projects/libraries/syslink-components/src/lib/context-menus/context-menu-item-action';
import { ContextMenuItemClickEvent } from 'devextreme/ui/file_manager';
import { PurchaseInvoiceGridComponent } from '../purchase-invoice-grid/purchase-invoice-grid.component';
import { PurchaseInvoiceStatusSelectModalComponent } from '../purchase-invoice-status/purchase-invoice-status-select-modal/purchase-invoice-status-select-modal.component';
import { PurchaseInvoiceHeaderStatus } from '../purchase-invoice-header-statuses/purchase-invoice-header-status.model';
import { PurchaseInvoiceHeaderHeaderStatusesService } from '../purchase-invoice-header-header-statuses/purchase-invoice-header-header-statuses.service';
import { PurchaseInvoiceHeaderHeaderStatus } from '../purchase-invoice-header-header-statuses/purchase-invoice-header-header-status.model';
import { PaymentsComponent } from '../../../base/payments/payments.component';
import { Payment } from '../../../base/payments/payment.model';
import { PurchaseContexMenuItemActionCode } from '../../purchase-documents/purchase-documents/purchase-documents.service';
import { DocumentCreateModalComponent } from '../../../base/documents/document-create-modal/document-create-modal.component';

@Component({
  selector: 'app-purchase-invoices',
  templateUrl: './purchase-invoices.component.html',
  styleUrls: ['./purchase-invoices.component.scss']
})
export class PurchaseInvoicesComponent extends PageComponent {

  @ViewChild('createModal') createModal?: DocumentCreateModalComponent;
  @ViewChild('grid') grid?: PurchaseInvoiceGridComponent;
  // @ViewChild("confirmModal") confirmModal?: ConfirmModalComponent;
  @ViewChild("statusModal") statusModal?: PurchaseInvoiceStatusSelectModalComponent;
  @ViewChild("paymentModal") paymentModal?: PaymentsComponent;

  public supplierFilter: string | string[] = ["Roles/any(r:r/Code eq 'supplier')"];

  // ContextMenuItemAction
  // ---------------------
  public contextMenuItems: ContextMenuItemAction[] = [
    // { code: PurchaseContexMenuItemActionCode.PrintToPDF, text: "Preview to PDF", icon: "print", onItemClick: (e: any) => this.onMenuItemClicked(e), visible: this.authService.hasPermission('purchases.invoices.list.contextMenu.preview') },
    // { code: PurchaseContexMenuItemActionCode.PrintToZIP, text: "Download to ZIP", icon: "print", onItemClick: (e: any) => this.onMenuItemClicked(e), visible: this.authService.hasPermission('purchases.invoices.list.contextMenu.preview') },
    { code: PurchaseContexMenuItemActionCode.Status, text: "Change status", icon: "edit", onItemClick: (e: any) => this.onMenuItemClicked(e), visible: this.authService.hasPermission('purchases.invoices.list.contextMenu.changeStatus') },
    { code: PurchaseContexMenuItemActionCode.payment, text: "Add payment", icon: "money", onItemClick: (e: any) => this.onMenuItemClicked(e), visible: this.authService.hasPermission('purchases.invoices.list.contextMenu.addPayment') }
  ];

  // Payment
  // -------
  public paymentElement: Payment[] = [];
  public paymentDocument?: PurchaseInvoice;

  constructor(
    private purchaseInvoicesService: PurchaseInvoicesService,
    private purchaseInvoiceHeaderHeaderStatusesService: PurchaseInvoiceHeaderHeaderStatusesService
  ) {
    super();
  }

  public onAddButtonClicked() {
    this.createModal?.open();
  };

  public onCreateValidated(element: any) {
    if (!element.ThirdId.Id) {
      NotificationsService.sendErrorMessage("Please select third");
      return;
    }
    if (element.ThirdId.Blocked == true) {
      NotificationsService.sendErrorMessage("Third is blocked");
      return;
    }

    this.router.navigateByUrl('/purchases/invoices/new?ThirdId=' + element.ThirdId.Id + (element.Subject != undefined ? '&Subject=' + element.Subject : ''));
  }

  // ContextMenu
  // -----------
  public async onMenuItemClicked(e: ContextMenuItemClickEvent) {

    if (!this.grid) return;

    switch (e.itemData.code) {
      // // Printing
      // // ---------
      // case PurchaseContexMenuItemActionCode.PrintToPDF:
      //   this.showConfirmModal("Would you like preview selected rows?", e.itemData);
      //   break;
      // case PurchaseContexMenuItemActionCode.PrintToZIP:
      //   this.showConfirmModal("Would you like download selected rows?", e.itemData);
      //   break;
      case PurchaseContexMenuItemActionCode.Status:
        this.showStatusModal("Would you like change status selected rows?", e.itemData);
        break;
      case PurchaseContexMenuItemActionCode.payment:
        await this.showPaymentModal("Would you like add payment", e.itemData);
        break;
    }
  }

  // private showConfirmModal(text: string, data: ContextMenuItemAction) {
  //   if (!this.confirmModal) return;

  //   this.confirmModal.title = data.text ? data.text : "Confirm";
  //   this.confirmModal.content = text;
  //   this.confirmModal.open({ rows: this.grid?.getSelectedRows(data.rowData), action: data.code });
  // }
  private showStatusModal(text: string, data: ContextMenuItemAction) {
    if (!this.statusModal) return;

    this.statusModal.title = data.text ? data.text : "Confirm";
    this.statusModal.content = text;
    this.statusModal.open({ rows: this.grid?.getSelectedRows(data.rowData), action: data.code });
  }
  private async showPaymentModal(text: string, data: ContextMenuItemAction) {
    if (this.grid?.getSelectedRows(data.rowData) && this.grid?.getSelectedRows(data.rowData)?.length > 1) {
      NotificationsService.sendErrorMessage("Please select only one line");
      return;
    }
    this.paymentDocument = await this.purchaseInvoicesService.findByID(this.grid?.getSelectedRows(data.rowData)[0].Id, { expand: this.purchaseInvoicesService.defaultOptions.expand })
    // TODO add better method
    // Wait component is loaded
    setTimeout(async () => {
      if (!this.paymentModal || !this.paymentDocument) return;
      this.paymentElement = this.paymentDocument.Payments;
      await this.paymentModal.onAddPaymentClicked();
    }, 100);
  }

  public async onConfirmModalValidated(modalData: { rows: PurchaseInvoice[], action: string } | any) {
    switch (modalData.action) {
      // Invoicing
      // ---------
      // case PurchaseContexMenuItemActionCode.PrintToPDF:
      //   await this.purchaseInvoicesService.printRows(modalData.rows, "pdf", "PurchaseInvoice");
      //   break;
      // case PurchaseContexMenuItemActionCode.PrintToZIP:
      //   await this.purchaseInvoicesService.printRows(modalData.rows, "zip", "PurchaseInvoice");
      //   break;
      case PurchaseContexMenuItemActionCode.Status:
        await this.updateStatus(modalData.rows, modalData.InvoiceStatus);
        break;
    }

    this.grid?.refresh();
    // this.confirmModal?.close();
    this.statusModal?.close();
  }
  // ------------------------------------------------------------------------------------------------

  // Actions
  // -------
  private async updateStatus(selectedRows: PurchaseInvoice[], status: PurchaseInvoiceHeaderStatus) {
    if (!this.purchaseInvoicesService.canUpdateStatus(selectedRows, status)) return;
    selectedRows.sort((a, b) => { return a.Date.getTime() - b.Date.getTime(); });

    for (var element of selectedRows) {
      if (element.Id) {
        const link: PurchaseInvoiceHeaderHeaderStatus = {
          HeaderId: element,
          StatusId: { Id: status.Id },
          Date: new Date()
        };
        await this.updateDocumentStatus(link);
      }
    }
    NotificationsService.sendSuccess('Status updated');
  }
  private async updateDocumentStatus(link: any) {
    let documentStatus = await this.purchaseInvoiceHeaderHeaderStatusesService.insert(this.purchaseInvoiceHeaderHeaderStatusesService.format(link));
  }
}