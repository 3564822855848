<ng-container [ngSwitch]="type" *ngIf="!hidden">
  <ng-container *ngSwitchCase="'switch'">
    <div class="h-100 d-flex align-items-center {{ class }}">
      <span class="dx-field-label w-100">{{ getLabel() }}</span>
      <dx-switch
        id="switch-{{ name }}"
        [value]="model"
        (onValueChanged)="modelChange.emit($event.value)"
        [switchedOnText]="trueText | translate"
        [switchedOffText]="falseText | translate"
        [width]="width"
        [activeStateEnabled]="false"
        [focusStateEnabled]="false"
        [hoverStateEnabled]="false"
        [readOnly]="disabled"
      ></dx-switch>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'box'">
    <div class="w-100 h-100 d-flex align-items-center justify-content-center">
      <div
        class="w-100 text-white text-center m-0 py-1 px-1 bg-{{
          model ? trueColor : falseColor
        }}"
        (click)="
          !disabled
            ? modelChange.emit(!model)
            : allowDisableMessage
            ? showDisableMessage()
            : null
        "
        role="button"
        style="user-select: none"
      >
        {{ model ? (trueText| translate) : (falseText| translate) }}
      </div>
    </div>
  </ng-container>
  <div *ngSwitchDefault>SYSLINK-SWITCH WRONG TYPE</div>
</ng-container>
