import { Component, Input, OnInit } from '@angular/core';
import { SubModule } from '../../../base/modules/sub-modules/sub-module.model';
import { Timetable } from '../../timetables/timetables/timetable';
import { TimetablesService } from '../../timetables/timetables/timetables.service';

@Component({
  selector: 'app-general-tab-content',
  templateUrl: './general-tab-content.component.html',
  styleUrls: ['./general-tab-content.component.scss']
})
export class GeneralTabContentComponent implements OnInit {
  @Input() public subModule?: SubModule;
  @Input() public timetables: Timetable[] = [];

  constructor(
    private timetablesService:TimetablesService
  ){}

  async ngOnInit(): Promise<void> {
    this.timetables = await this.timetablesService.load();
  }


}
