import { Injectable } from '@angular/core';
import { ApiService } from '../../../core/api.service';
import { ODataService } from '../../../core/services/oData.service';
import { PurchaseDocumentLine } from './purchase-document-line.model';

@Injectable({
  providedIn: 'root'
})
export class PurchaseDocumentLinesService<T extends PurchaseDocumentLine> extends ODataService<T> {
  public url: string = 'PurchaseDocumentLine';
  public documentType: string = "";

  constructor(
    public api: ApiService
  ) {
    super();
  }

  // Format lines
  // ------------
  public formatLines(lines: T[]) {
    lines.forEach(line => {
      if (line.LineType == "post" || line.LineType == "text" || line.LineType == "page") {
        this.clearColumnsPost(line);
      }
      if (line.LineType == "text" || line.LineType == "page") {
        this.clearColumnsText(line);
      }
    });
    return lines;
  }

  private clearColumnsPost(line: any) { // TODO: check type
    line.Quantity = undefined;
    line.Discount = undefined;
    line.ExTaxGrossPrice = undefined;
    line.ForcedPrice = undefined;
    line.ExTaxUnitPrice = undefined;
    line.TaxAmount = undefined;
  }

  private clearColumnsText(line: any) { // TODO: check type
    this.clearColumnsPost(line);
    line.ExTaxTotalPrice = undefined;
    line.InTaxTotalPrice = undefined;
    line.PurchaseDocumentLineDiscountOperations = undefined;
  }

  public async updateParentPrice(targetParentId: number) {
    return await this.api.sendRequest('/api/PurchaseDocumentLine/' + targetParentId + '/updateParent/', 'POST');
  }

  public async changeLineNo(purchaseLine: T, targetElementIsBefore: boolean = true) {
    return await this.api.sendRequest('/api/PurchaseDocumentLine/' + purchaseLine.Id + '/changeLineNo', 'POST', {
      SelectedLine: purchaseLine.LineNo,
      TargetElementIsBefore: targetElementIsBefore
    }
    );
  }

  public override async insert(data: any): Promise<any> {
    data.DocumentType = this.documentType;
    var result = await this.api.sendRequest('/api/PurchaseDocumentLine/AddDocumentLine', 'POST', data);
    return result;
  }

  // Format
  // ------
  public override format(params?: Partial<T>): T {
    var result: any = {
      ...params,
      HeaderId: params?.HeaderId?.Id,
      ParentId: params?.ParentId != null && params?.ParentId != undefined ? params?.ParentId?.Id : null,
      ProductId: params?.ProductId?.Id,
      TaxRateId: params?.TaxRateId?.Id,
      UnitId: params?.UnitId?.Id,
    };

    delete result["Discount"];
    delete result["Children"];
    delete result["PurchaseDocumentLineDiscountOperations"];

    return result;
  }
}
