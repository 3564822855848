import { Injectable } from '@angular/core';
import { PurchaseDocumentStatusesService } from '../../purchase-documents/purchase-document-statuses/purchase-document-statuses.service';
import { PurchaseCreditNoteHeaderStatus } from './purchase-credit-note-header-status.model';

@Injectable({
  providedIn: 'root'
})
export class PurchaseCreditNoteHeaderStatusesService extends PurchaseDocumentStatusesService<PurchaseCreditNoteHeaderStatus> {
  public override url: string = 'PurchaseCreditNoteHeaderStatus';
 
  constructor() {
    super();
  }
}
