<syslink-grid
    #gridComponent
    [canView]="canView"
    [canSearch]="canSearch"
    [canRefresh]="canRefresh"
    [canSort]="canSort"
    [canDelete]="false"
    [canFilter]="canFilter"
    [canDblClck]="canDblClck"
    [canAdd]="false"
    [canUpdate]="false"
    [canExport]="false"
    [canCustomize]="false"
    [canSearch]="false"
    [items]="items"
    [columns]="columns"
    (add)="add.emit()"
    [storageKey]="storageKey"
    (doubleClick)="rowDoubleClicked.emit($event)"
    key="TaxRateId"
    [options]="{
        keyExpr: 'TaxRateId',
        headerFilter: { visible: false },
        filterRow: { visible: false },
        pager: { visible: false }
      }"
></syslink-grid>
