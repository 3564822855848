import { DeliveryTime } from "../../../base/delivery-times/delivery-time.model";
import { DocumentRelation } from "../../../base/documents/document-relations/document-relation.model";
import { Document } from "../../../base/documents/documents/document.model";
import { PurchaseDocumentFinance } from "../purchase-document-finances/purchase-document-finance.model";
import { PurchaseDocumentLine } from "../purchase-document-lines/purchase-document-line.model";

export class PurchaseDocument extends Document {
    public override DocumentFinances?: PurchaseDocumentFinance[];
    public override Lines?: PurchaseDocumentLine[];
    public FormattedDocumentPayment?: string;
    public FormattedStructuredCommunication?: string;
    public ExTaxTotalInvoiced: number = 0;
    public InTaxTotalInvoiced: number = 0;
    public DocumentRelations: DocumentRelation[] = [];
    public DeliveryTimeId?: DeliveryTime;
    //-------------------------------------------------------

    constructor(entity?: Partial<PurchaseDocument>) {
        super();
        Object.assign(this, entity);
    }
}
