import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DocumentLineType } from 'projects/erp-app/src/app/base/documents/document-lines/document-line.model';
import { Product } from 'projects/erp-app/src/app/products/products/product.model';
import { ConfirmModalComponent } from 'projects/libraries/syslink-components/src/public-api';
import { StockMoveDocumentLine } from '../../stock-move-document-lines/stock-move-document-line.model';
import { StockMoveDocumentLinesService } from '../../stock-move-document-lines/stock-move-document-lines.service';
import { StockMoveDocument } from '../stock-move-document.model';
import { StockMoveDocumentsService } from '../stock-move-documents.service';
import { SyslinkColumn } from 'projects/libraries/syslink-components/src/lib/helpers/SyslinkColumn';
import { getNew } from 'projects/libraries/syslink-components/src/lib/helpers/tools';
import { ViewComponent } from 'projects/libraries/syslink-components/src/lib/helpers/view/view.component';
import { StockMoveDocumentStatus } from '../../stock-move-document-statuses/stock-move-document-status.model';

@Component({
  selector: 'app-stock-move-document-content',
  templateUrl: './stock-move-document-content.component.html',
  styleUrls: ['./stock-move-document-content.component.scss']
})
export class StockMoveDocumentContentComponent<
  TStockMoveDocument extends StockMoveDocument,
  TStockMoveDocumentService extends StockMoveDocumentsService<TStockMoveDocument, StockMoveDocumentStatus>,
  TStockMoveDocumentLine extends StockMoveDocumentLine,
  TStockMoveDocumentLineService extends StockMoveDocumentLinesService<StockMoveDocumentLine>
> extends ViewComponent {
  @Input() public element: TStockMoveDocument = <TStockMoveDocument>getNew<TStockMoveDocument>();
  @Output() public elementChange: EventEmitter<TStockMoveDocument> = new EventEmitter<TStockMoveDocument>();

  @Input() public StockMoveDocumentService: TStockMoveDocumentService = <TStockMoveDocumentService>getNew<TStockMoveDocumentService>();
  @Input() public StockMoveDocumentLinesService: TStockMoveDocumentLineService = <TStockMoveDocumentLineService>getNew<TStockMoveDocumentLineService>();

  @Input() public selectedKey: number[] = [];
  @Output() public elementLineChange: EventEmitter<TStockMoveDocumentLine> = new EventEmitter<TStockMoveDocumentLine>();
  @Output() public selectedKeyChange: EventEmitter<number[]> = new EventEmitter<number[]>();

  @ViewChild('createNewProduct') createNewProduct: ConfirmModalComponent = new ConfirmModalComponent();

  @Input() disabled: boolean = false;
  @Input() public reportType: string = '';
  @Input() public type: string = '';
  @Input() public subModuleCode: string = '';
  @Input() public columns: SyslinkColumn[] = [];

  public isAddingLine: boolean = false;

  constructor(
    public activatedRoute: ActivatedRoute,
    private translateService: TranslateService
  ) {
    super();
  }

  // Add line
  // --------
  public async onAddLine(type: 'product', product?: Product): Promise<void> {
    if (this.element.Id == null) return;
    if (this.isAddingLine) return;

    this.isAddingLine = true;
    let line: TStockMoveDocumentLine;

    switch (type) {
      case 'product':
        if (!product) return;
        line = await this.onAdvancedProductSelected(product);
        break;
      default:
        return;
    }

    this.elementLineChange.emit(line);
    this.isAddingLine = false;
  }

  // Product
  // -------
  public onProductNotFound(textSearched: string) {
    if (this.createNewProduct) this.createNewProduct.open({ 'text': textSearched });
  }

  public async onCreateProduct() {
    if (this.createNewProduct) this.createNewProduct.close();

    const url = this.router.serializeUrl(this.router.createUrlTree(["/products/products/new"], {
      relativeTo: this.activatedRoute, queryParams: {
        ProductName: this.createNewProduct?.data?.text ? this.createNewProduct.data.text : null,
      }
    }));
    window.open(url, '_blank');
  }

  private async onAdvancedProductSelected(product: Product): Promise<TStockMoveDocumentLine> {
    const lineNo = await this.getLineNo();
    const line = await this.StockMoveDocumentLinesService.insert(this.StockMoveDocumentLinesService.format({
      HeaderId: this.element,
      LineNo: lineNo,
      Type: this.type,
      LineType: DocumentLineType.product,
      ProductId: product,
    }));
    return line;
  }

  //-------------------------------------------------------------------------

  // Delete Line
  // -----------
  public async onDeleteLine(event: any) {
    if (!event.key) return;
    let line = this.element.Lines?.find(s => s.Id == event.key);
    if (line && line.Id != null && line.ParentId != null && line.ParentId.Id) {
      var oldParentId = line.ParentId.Id;
      line.ParentId = undefined;
      await this.StockMoveDocumentLinesService.update(line.Id, this.StockMoveDocumentLinesService.format(line));
    }
    await this.StockMoveDocumentLinesService.remove(event.key);
    this.elementLineChange.emit(event.data);
  }
  //-------------------------------------------------------------------------

  // Update Line
  // -----------
  public async onUpdateLine(event: any) {

    let line: TStockMoveDocumentLine = await this.StockMoveDocumentLinesService.getInstance({ ...event.oldData, ...event.newData });

    if (line.Id) {
      await this.StockMoveDocumentLinesService.update(line.Id, this.StockMoveDocumentLinesService.format(line));
    }
    this.elementLineChange.emit(line);
  }
  //-------------------------------------------------------------------------

  public onSelectedKeyChange(selectedKeys: number[]) {
    this.selectedKey = selectedKeys;
    this.selectedKeyChange.emit(this.selectedKey);
  }


  public getReference(label: string) {
    if (!this.element.Lines)
      return this.translateService.instant(label);
    let count = (this.element.Lines.filter((e: any) => e.LineType == label.toLowerCase()).length);
    return this.translateService.instant(label) + ' ' + (count + 1);
  }

  public async getLineNo() {
    if (!this.element.Lines) return 1;

    var result: number = this.element.Lines.length;

    if (this.selectedKey.length !== 0) {
      result = this.element.Lines.find(l => l.Id === this.selectedKey[0])?.LineNo || 0;
    }

    return result + 1;
  }

  // Drag and drop
  // -------------
  public async OnReorder(e: any) {
    if (!this.element.Id) return;
    //this.ngxUiLoaderService.start();
    let targetElementIsBefore = e.fromIndex > e.toIndex; // Target element is the element before if the line is pulled up and the element after if it is dragged down.
    let realLines: TStockMoveDocumentLine[] = <TStockMoveDocumentLine[]>((await this.StockMoveDocumentService.findByID(this.element.Id)).Lines || []);
    var lines: TStockMoveDocumentLine[] = <TStockMoveDocumentLine[]>(this.element.Lines || []);
    var parent: TStockMoveDocumentLine | undefined = undefined;
    var toIndex = e.toIndex;
    if (e.dropInsideItem == true && targetElementIsBefore == true)
      toIndex++;

    this.arraymove(realLines, e.fromIndex, toIndex);
    if (toIndex > 0 && realLines[toIndex].LineType != "post") {
      if (realLines[toIndex - 1].LineType == "post")
        parent = realLines[toIndex - 1];
      else
        parent = <TStockMoveDocumentLine | undefined>(realLines[toIndex - 1].ParentId);
    }
    else
      parent = undefined;

    var oldParent: TStockMoveDocumentLine | undefined = <TStockMoveDocumentLine | undefined>(realLines[toIndex].ParentId);
    realLines[toIndex].ParentId = parent;
    const newline = realLines[toIndex];
    if (newline.Id != undefined) {
      await this.StockMoveDocumentLinesService.update(newline.Id, this.StockMoveDocumentLinesService.format(newline));
    }

    // Update LineNo
    // -------------
    for (let i = 0; i < realLines.length; i++) {
      realLines[i].LineNo = i + 1;
    }

    // Update lines
    // ------------
    for (let i = 0; i < realLines.length; i++) {
      if (realLines[i].Id == lines[i].Id) { }
      else {
        var localLine = this.StockMoveDocumentLinesService.format(realLines[i]);
        if (localLine.Id) {
          await this.StockMoveDocumentLinesService.update(localLine.Id, localLine)
          await this.StockMoveDocumentLinesService.changeLineNo(localLine, targetElementIsBefore);
          lines = <TStockMoveDocumentLine[]>((await this.StockMoveDocumentService.findByID(this.element.Id)).Lines || []);
        }
      }
    }
    for (let i = 0; i < realLines.length; i++) {
      if (realLines[i].Id == lines[i].Id) { }
      else {
        var localLine = this.StockMoveDocumentLinesService.format(realLines[i]);
        if (localLine.Id) {
          await this.StockMoveDocumentLinesService.update(localLine.Id, localLine)
          await this.StockMoveDocumentLinesService.changeLineNo(localLine, targetElementIsBefore);
          lines = <TStockMoveDocumentLine[]>((await this.StockMoveDocumentService.findByID(this.element.Id)).Lines || []);
        }
      }
    }
    var lineId = realLines[toIndex];
    this.elementLineChange.emit(e);
  }

  public arraymove(arr: any, fromIndex: any, toIndex: any) {
    var element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
  }

}

