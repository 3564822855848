import { Injectable } from '@angular/core';
import { ODataService } from '../../core/services/oData.service';
import { CustomerCatalogDiscountOperations } from './customer-catalog-discount-operations/customer-catalog-discount-operations';

@Injectable({
  providedIn: 'root'
})
export class CustomerCatalogDiscountOperationService extends ODataService<CustomerCatalogDiscountOperations> {
  public url = 'CustomerCatalogDiscountOperation';

  constructor() {
    super();
  }
}
