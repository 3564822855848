import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Third } from '../third.model';
import { NumberPlatesService } from '../../../vise/number-plates/number-plates.service';
import { LanguagesService } from '../../../base/languages/languages.service';
import { CountriesService } from '../../../base/addresses/countries/countries.service';
import { SubjugationCategoriesService } from '../../../accountings/subjugation-categories/subjugation-category.service';
import { SyslinkColumn } from 'projects/libraries/syslink-components/src/lib/helpers/SyslinkColumn';

@Component({
  selector: 'app-third-other-form',
  templateUrl: './third-other-form.component.html',
  styleUrls: ['./third-other-form.component.scss']
})
export class ThirdOtherFormComponent {
  @Input() public element: Third = new Third();
  @Output() public elementChange: EventEmitter<Third> = new EventEmitter<Third>();

  public numberPlatesColumns: SyslinkColumn[] = [
    new SyslinkColumn({ field: "Value", label: "Number plate" }),
    new SyslinkColumn({ field: "CustomerId", technical: true }),
  ];

  constructor(
    public NumberPlatesService: NumberPlatesService,
    public languagesService: LanguagesService,
    public countriesService: CountriesService,
    public subjugationCategoriesService: SubjugationCategoriesService
  ) { }

  public onBeforeAddBankAccount(e: any) {
    e.data.ThirdId = { Id: this.element.Id };
  }


  public onBeforeAddNumberPlate(e: any) {
    e.data.CustomerId = this.element.CustomerId;
  }

}
