import { Component } from '@angular/core';
import { BaseGridComponent } from 'projects/libraries/syslink-components/src/lib/grid/base-grid/base-grid.component';
import { ActionGroup } from '../action-group.model';
import { SyslinkColumn } from 'projects/libraries/syslink-components/src/lib/helpers/SyslinkColumn';
import { ActionGroupsService } from '../action-groups.service';

@Component({
  selector: 'app-action-group-grid',
  templateUrl: './action-group-grid.component.html',
  styleUrls: ['./action-group-grid.component.scss']
})
export class ActionGroupGridComponent extends BaseGridComponent<ActionGroup> {

  override storageKey?: string | undefined = "action-group-grid";
  
  public override columns: SyslinkColumn[] = [
    new SyslinkColumn({ field: 'Id', label: 'Id', editable: false, visible: false, filterable: false, headerFilterable: false, sortable: false }),
    new SyslinkColumn({ field: 'Code', label: 'Code' }),
    new SyslinkColumn({ field: 'NameTranslationId.TranslatedValue', label: 'Name', editCellTemplate: 'translations-cell' })
  ];

  constructor(public actionGroupsService: ActionGroupsService) {
    super();
  }

}
