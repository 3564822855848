import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { UsersService } from '../users.service';
import { User } from '../user.model';
import { ModalComponent } from 'projects/libraries/syslink-components/src/public-api';
import { BaseModalComponent } from 'projects/libraries/syslink-components/src/lib/modal/base-modal/base-modal.component';

@Component({
  selector: 'app-user-select-modal',
  templateUrl: './user-select-modal.component.html',
  styleUrls: ['./user-select-modal.component.scss']
})
export class UserSelectModalComponent extends BaseModalComponent<User> {

  constructor(public usersService: UsersService) {
    super();
  }

}
