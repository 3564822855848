<syslink-title text="{{ 'Purchase orders' }}"></syslink-title>

<app-purchase-order-grid
  #grid
  [permissionKey]="'list'"
  [canUpdate]="false"
  (add)="onAddButtonClicked()"
  [contextMenuItems]="contextMenuItems"
  [items]="items"
  [expand]="expand"
>
</app-purchase-order-grid>
<app-document-create-modal
  #createModal
  title="Create Order"
  [thirdFilter]="supplierFilter"
  detailsUrl="/thirds/suppliers/"
  (validate)="onCreateValidated($event)"
></app-document-create-modal>
<syslink-confirm-modal
  #confirmModal
  (onValidate)="onConfirmModalValidated($event)"
></syslink-confirm-modal>
<app-purchase-order-status-select-modal
  #statusModal
  (validate)="onConfirmModalValidated($event)"
></app-purchase-order-status-select-modal>
<app-payments
  *ngIf="paymentElement && paymentDocument"
  #paymentModal
  [reportType]="'PurchaseOrder'"
  [showGrid]="false"
  [element]="paymentElement"
  [document]="paymentDocument"
  (paymentsChange)="grid.refresh()"
  [filterPaymentType]="['Code eq \'Advance\'']"
></app-payments>