<syslink-modal
    #modal
    title="Select action group"
    (onValidate)="onValidateButtonClicked($event)"
    width="350"
>
    <syslink-select
        #select
        label="Action group"
        [store]="actionGroupsService.store"
        [(model)]="modal.data"
        displayKey="Name"
        [select]="['Id','Name']"
        [filter]="selectFilters"
    ></syslink-select>
</syslink-modal>