import DevExpress from "devextreme";
import dxDataGrid, { Column, Row } from "devextreme/ui/data_grid";

export type DXRowType =
    "data" | // A row containing data.
    "detail" | //A row that shows detail data. It appears when a user expands a master row or edits a row in "form" editing mode. To identify whether it is one or another, check the isEditing field's value. It is true while the editing takes place.
    "detailAdaptive" | //An adaptive detail row.
    "group" | //A group row.
    "groupFooter" | //A row in a group footer that shows the group summary.
    "header" | //The row containing column headers.
    "filter" | //The filter row.
    "totalFooter"; //The row that shows the total summary.

export class DXCell {
    cellElement?: DevExpress.core.DxElement;
    column?: Column<any, any>;
    columnIndex?: number;
    component?: dxDataGrid<any, any>;
    data?: any;
    displayValue?: any;
    isAltRow?: boolean;
    isEditing?: boolean;
    key?: number;
    oldValue?: any;
    resized?: any;
    row?: Row<any, any>;
    rowIndex?: number;
    rowType?: DXRowType;
    value?: any;
    text?: string;
    watch?: Function;

    setValue(value: any) {
        this.value = value;
    }
}
