import { Component, Input } from '@angular/core';
import { SyslinkColumn } from 'projects/libraries/syslink-components/src/lib/helpers/SyslinkColumn';
import { UserGroupsService } from '../user-groups.service';
import { UserGroup } from '../user-group.model';
import { BaseGridComponent } from 'projects/libraries/syslink-components/src/lib/grid/base-grid/base-grid.component';

@Component({
  selector: 'app-user-group-grid',
  templateUrl: './user-group-grid.component.html',
  styleUrls: ['./user-group-grid.component.scss']
})
export class UserGroupGridComponent extends BaseGridComponent<UserGroup> {

  override detailsUrl: string = "/securities/userGroups/";
  override storageKey?: string | undefined = "userGroups-grid";
  
  public override columns: SyslinkColumn[] = [
    new SyslinkColumn({ field: 'Id', label: 'Id', editable: false, visible: false, filterable: false, headerFilterable: false, sortable: false }),
    new SyslinkColumn({ field: 'Code', label: 'Code' }),
    new SyslinkColumn({ field: 'NameTranslationId.TranslatedValue', label: 'Name', editCellTemplate: 'translations-cell' })
  ];

  constructor(public userGroupsService: UserGroupsService) {
    super();
  }

}
