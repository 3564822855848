import { Component, Input, OnInit } from '@angular/core';
import { DXCell } from '../dxcell';

export type StatusTextCellMapping = {
  code:string,
  classString: string | string[]
}
@Component({
  selector: 'app-status-text-cell',
  templateUrl: './status-text-cell.component.html',
  styleUrls: ['./status-text-cell.component.scss']
})
export class StatusTextCellComponent{

  @Input() cell: DXCell = new DXCell();
  @Input() getStatusLabel: Function = (cell?: DXCell) => { return cell?.value };
  @Input() getStatusDate: Function = (cell?: DXCell) => { return cell?.value };
  @Input() getStatusClass: Function = (cell?: DXCell) => { return cell?.value };
  @Input() getStatusColor: Function = (cell?: DXCell) => { return cell?.value };

}
