import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ModalComponent, SelectComponent } from 'projects/libraries/syslink-components/src/public-api';
import { TimetableLine } from '../timetable-line';
import { TimetableLinesService } from '../timetable-lines.service';

@Component({
  selector: 'app-timetable-line-modal',
  templateUrl: './timetable-line-modal.component.html',
  styleUrls: ['./timetable-line-modal.component.scss']
})
export class TimetableLineModalComponent {
  @Input() public title: string = "Create timetable line";

  @ViewChild('daySelect') daySelect?: SelectComponent;

  @Output() validate: EventEmitter<TimetableLine> = new EventEmitter<TimetableLine>();

  @ViewChild("modal") modal: ModalComponent = new ModalComponent();

  public days: any[] = [
    { "Id": 1, "Name": "1" },
    { "Id": 2, "Name": "2" },
    { "Id": 3, "Name": "3" },
    { "Id": 4, "Name": "4" },
    { "Id": 5, "Name": "5" },
    { "Id": 6, "Name": "6" },
    { "Id": 7, "Name": "7" }
  ];

  constructor(
    private timetableLinesService: TimetableLinesService
  ) { }

  onValidateButtonClicked(e: TimetableLine) {
    if (!this.timetableLinesService.isValid(this.modal?.data)) return
    if (this.modal?.data.Day && this.modal?.data.Day.Name != undefined) {
      e.Day = this.modal?.data.Day.Name;
    }

    if (this.validate.observed) {
      this.validate.emit(e);
      return;
    }
  }

  // Functions
  // ---------
  public open(e?: TimetableLine) {
    this.modal?.open(e);
  }
  public close() {
    this.modal?.close();
  }
}
