import { ChangeDetectionStrategy, Component, Input, ViewChild } from '@angular/core';
import { BankAccount } from '../bank-account.model';
import { BaseGridComponent } from 'projects/libraries/syslink-components/src/lib/grid/base-grid/base-grid.component';
import { GridComponent } from 'projects/libraries/syslink-components/src/public-api';
import { SyslinkColumn } from 'projects/libraries/syslink-components/src/lib/helpers/SyslinkColumn';
import { BanksService } from '../../../base/banks/banks.service';
import { BankAccountsService } from '../bank-accounts.service';

@Component({
  selector: 'app-bank-account-grid',
  templateUrl: './bank-account-grid.component.html',
  styleUrls: ['./bank-account-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BankAccountGridComponent extends BaseGridComponent<BankAccount> {
  public override canDelete: boolean = true;
  @Input() storageKey: string = 'grid-third-other-bankAccount';

  @ViewChild('gridComponent') public gridComponent?: GridComponent;

  public columns: SyslinkColumn[] = [
    new SyslinkColumn({ field: "Iban", label: "Iban" }),
    new SyslinkColumn({ field: "BankId", label: "Bank", type: 'object', cellTemplate: 'select-value', editCellTemplate: "select-cell", data: { service: this.banksService, filter: "IsVisible eq true", displayKey: 'Code' } }),
    new SyslinkColumn({ field: "BankId.Bic", label: "Bic", editable: false }),
    new SyslinkColumn({ field: "ThirdId", technical: true }),
  ];

  constructor(
    public bankAccountsService: BankAccountsService,
    public banksService: BanksService,
  ) {
    super();
  }
}
