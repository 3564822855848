import { Component, Input } from '@angular/core';
import { CreditsafeData } from '../creditsafe.model';

@Component({
  selector: 'app-creditsafe-current',
  templateUrl: './creditsafe-current.component.html',
  styleUrls: ['./creditsafe-current.component.scss']
})
export class CreditsafeCurrentComponent {
  @Input() element: CreditsafeData = new CreditsafeData();

  public getCreditLimit() {
    return this.element.Report?.CompanySummary?.CreditRating?.CreditLimit?.Value + " " + this.element.Report?.CompanySummary?.CreditRating?.CreditLimit?.Currency;
  }

  public getDefaultProbability() {
    return ((this.element.Report?.CompanySummary?.CreditRating?.Pod ?? 0) * 100).toFixed(4) + " %";
  }
}
