<dx-button
    [id]="name"
    [text]="(text ?? name) | translate"
    [icon]="icon"
    [hint]="(hint ?? text ?? name) | translate"

    [visible]="visible"
    [disabled]="disabled"
    [type]="type"
    [stylingMode]="mode"
    [width]="width"
    [height]="height"
    [activeStateEnabled]="activeStateEnabled"
    [focusStateEnabled]="focusStateEnabled"
    [hoverStateEnabled]="hoverStateEnabled"
    [routerLink]="link"

    [elementAttr]="{class: '{{class}}'}"

    (onClick)="onClick.emit($event)"
></dx-button>
