import { Component, Input, OnInit } from '@angular/core';
import { DXCell } from '../dxcell';

@Component({
  selector: 'syslink-time-cell',
  templateUrl: './time-cell.component.html',
  styleUrls: ['./time-cell.component.scss']
})
export class TimeCellComponent implements OnInit {
  
  @Input() cell: DXCell = new DXCell();
  @Input() data: any = {};

  public value: string = '00:00';
  
  ngOnInit(): void {
    this.value = this.cell.value;
  }

}
