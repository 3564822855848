import { Injectable } from '@angular/core';
import { SyslinkColumn } from 'projects/libraries/syslink-components/src/lib/helpers/SyslinkColumn';
import { AppInjectorService } from 'projects/libraries/syslink-components/src/lib/services/app-injector.service';
import { ConfigurationsService } from '../../../base/modules/configurations/configurations.service';
import { UnitsService } from '../../../base/units/unit.service';
import { ApiService } from '../../../core/api.service';
import { AuthService } from '../../../core/auth/auth.service';
import { ODataService } from '../../../core/services/oData.service';
import { StockMoveDocument } from './stock-move-document.model';
import { NotificationsService } from 'projects/libraries/syslink-components/src/public-api';
import { StockMoveDocumentStatus } from '../stock-move-document-statuses/stock-move-document-status.model';
import { ReportsService } from '../../../connectors/reports/reports.service';
import { StockLocationService } from '../../stock-location/stock-location.service';

@Injectable({
  providedIn: 'root'
})
export abstract class StockMoveDocumentsService<TStockMoveDocument extends StockMoveDocument, TStockMoveDocumentHeaderStatus extends StockMoveDocumentStatus> extends ODataService<TStockMoveDocument> {
  public url: string = 'StockMoveDocument';
  protected configurationsService: ConfigurationsService;

  constructor(
    private api: ApiService,
    public reportsService: ReportsService
  ) {
    super();
    this.configurationsService = AppInjectorService.injector.get(ConfigurationsService);
  }

  public getDefaultDocumentLineColumns(subModuleCode: string, reportType: string): SyslinkColumn[] {
    const unitsService = AppInjectorService.injector.get(UnitsService);
    const stockLocationService = AppInjectorService.injector.get(StockLocationService);
    return [
      new SyslinkColumn({ order: 1, width: 75 }),
      new SyslinkColumn({ order: 2, field: 'Reference', label: 'Reference', width: 150 }),
      new SyslinkColumn({ order: 3, field: 'Description', label: 'Description', cellTemplate: 'html-cell', editCellTemplate: 'edit-html-cell' }),
      new SyslinkColumn({ order: 4, field: 'PlannedQuantity', label: 'Planned quantity', width: 70 }),
      new SyslinkColumn({ order: 4, field: 'Quantity', label: 'Real quantity', width: 70 }),
      new SyslinkColumn({ order: 5, field: 'UnitId', label: 'Unit', cellTemplate: 'select-value', editCellTemplate: 'select-cell', data: { service: unitsService, displayKey: 'Name' }, width: 100 }),
      new SyslinkColumn({ order: 7, field: 'ToStockLocationId', label: reportType+'.StockLocation', cellTemplate: 'select-value', editCellTemplate: 'select-cell', data: { service: stockLocationService, displayKey: 'Name' }, width: 250 }),
    ];
  }

  public ConvertToStockMoveOutgoing(documentId: number, type: string, idList: number[] = []): Promise<TStockMoveDocument> {
    return this.api.sendRequest('/api/StockMoveDocument/' + documentId + '/convertToStockMoveOutgoing', 'POST', { idList, type });
  }
  public ConvertToStockMoveIncoming(documentId: number, type: string, idList: number[] = []): Promise<TStockMoveDocument> {
    return this.api.sendRequest('/api/StockMoveDocument/' + documentId + '/convertToStockMoveIncoming', 'POST', { idList, type });
  }


  public CopyToStockMoveOutgoing(subject: string, third: number, documentId: number, idList: number[] = []): Promise<TStockMoveDocument> {
    return this.api.sendRequest('/api/StockMoveDocument/' + documentId + '/CopyToStockMoveOutgoing', 'POST', { idList, third });
  }
  public CopyToStockMoveIncoming(subject: string, third: number, documentId: number, idList: number[] = []): Promise<TStockMoveDocument> {
    return this.api.sendRequest('/api/StockMoveDocument/' + documentId + '/CopyToStockMoveIncoming', 'POST', { idList, third });
  }

  //   // Delivery time
  // // --------------
  // public async refreshThirdDeliveryTimeFromSupplier(element: any): Promise<TStockMoveDocument> {

  //   if (element.ThirdId && element.ThirdId.Id) {
  //     const suppliersService = AppInjectorService.injector.get(SuppliersService);
  //     const deliveryTime = (await suppliersService.getSupplierDeliveryTimeByThirdId(element.ThirdId.Id)).Id;
  //     if (deliveryTime) {
  //       element.DeliveryTimeId = { Id: deliveryTime };
  //     }
  //   }
  //   return element;
  // }

  // Responsible User
  // ----------------
  public async refreshResponsibleUser(element: any): Promise<TStockMoveDocument> {

    const authService = AppInjectorService.injector.get(AuthService);
    const third = await authService.getUserThird();
    if (third) {
      element.ResponsibleUserId = { Id: third.Id };
      // element.responsibleUser = await this.usersService.getThird(userId);
    }
    return element;
  }

  // Format
  // ------
  public override async format(params?: Partial<TStockMoveDocument>): Promise<TStockMoveDocument> {
    var result: any = {
      ...params,
      // DeliveryTimeId: params?.DeliveryTimeId?.Id,
      ThirdId: params?.ThirdId?.Id,
      IntermediateThirdId: params?.IntermediateThirdId?.Id,
      ResponsibleUserId: params?.ResponsibleUserId?.Id,
      CurrentStatusLink: params?.CurrentStatusLink?.Id,
    };

    delete result.DocumentDataCollection;
    delete result.ThirdDocumentData;
    delete result.IntermediateThirdIdDocumentData;
    delete result.ThirdDocumentDataIndex;
    delete result.IntermediateThirdIdDocumentDataIndex;
    delete result.Lines;
    delete result.Statuses;
    // delete result.DocumentFinances;
    // delete result.Payments;
    delete result.DocumentRelations;

    // let dateFrom: Date | undefined = result.DateFrom;
    // if (!dateFrom || dateFrom.getFullYear().toString() == "1") {
    //   delete result.DateFrom;
    //   delete result.DateTo;
    // }
    let deadline: Date | undefined = result.Deadline;
    if (!deadline || deadline.getFullYear().toString() == "1") {
      delete result.Deadline;
    }
    return result;
  }

  public async printRows(elements: TStockMoveDocument[], extensionType: string, reportType: string, type: string) {
    await this.reportsService.printRows(elements.map((row: TStockMoveDocument) => row?.Id), reportType, reportType + ".pdf", extensionType,type);
  }

  /***********************************/
  /****   ContextMenuItemAction   ****/
  /***********************************/

  // Action
  // ------
  public canUpdateStatus(elements: TStockMoveDocument[], status: TStockMoveDocumentHeaderStatus): boolean {
    var result: boolean = true;

    // Checking status is not empty
    // ----------------------------
    if (status == null) {
      NotificationsService.sendErrorMessage("Status is empty");
      result = false;
    }

    // Checking status is not empty
    // ----------------------------
    if (elements?.some(row => row.CurrentStatusLink?.StatusId?.Sequence != null && status.Sequence != null && row.CurrentStatusLink?.StatusId?.Sequence >= status?.Sequence)) {
      NotificationsService.sendErrorMessage("Current status is greater or equal than selected status");
      result = false;
    }
    return result;
  }
}

export enum StockMoveContexMenuItemActionCode {
  PrintToZIP = 'PrintToZIP',
  PrintToPDF = 'PrintToPDF',
  Status = 'Status',
  payment = 'Payment'
}
