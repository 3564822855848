import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DocumentDelay } from 'projects/erp-app/src/app/base/documents/document-delays/document-delay';
import { DocumentDelaysService } from 'projects/erp-app/src/app/base/documents/document-delays/document-delays.service';
import { OriginsService } from 'projects/erp-app/src/app/base/origins/origins.service';
import { sprintf } from 'sprintf-js';
import { SaleDocumentLine } from '../../sale-document-lines/sale-document-line.model';
import { SaleDocumentLinesService } from '../../sale-document-lines/sale-document-lines.service';
import { SaleDocument } from '../sale-document.model';
import { SaleDocumentsService } from '../sale-documents.service';
import { NumberPlatesService } from 'projects/erp-app/src/app/vise/number-plates/number-plates.service';
import { SaleContractsService } from '../../../sale-contracts/sale-contracts/sale-contracts.service';
import { SelectComponent } from 'projects/libraries/syslink-components/src/public-api';
import { getNew } from 'projects/libraries/syslink-components/src/lib/helpers/tools';
import { ViewComponent } from 'projects/libraries/syslink-components/src/lib/helpers/view/view.component';
import { SaleDocumentStatus } from '../../sale-document-statuses/sale-document-status.model';

@Component({
  selector: 'app-sale-document-other',
  templateUrl: './sale-document-other.component.html',
  styleUrls: ['./sale-document-other.component.scss']
})
export class SaleDocumentOtherComponent<
  TSaleDocument extends SaleDocument,
  TSaleDocumentService extends SaleDocumentsService<SaleDocument,SaleDocumentStatus>,
  TSaleDocumentLine extends SaleDocumentLine,
  TSaleDocumentLineService extends SaleDocumentLinesService<SaleDocumentLine>
> extends ViewComponent  implements OnInit {
  @Input() public element: TSaleDocument = <TSaleDocument>getNew<TSaleDocument>();
  @Output() public elementChange: EventEmitter<TSaleDocument> = new EventEmitter<TSaleDocument>();

  @Output() public globalDiscountChanged: EventEmitter<number> = new EventEmitter<number>();
  @Output() public documentDelayChanged: EventEmitter<DocumentDelay> = new EventEmitter<DocumentDelay>();
  @Output() public onCreateContract: EventEmitter<any> = new EventEmitter<any>();

  @Input() public disabled: boolean = false;
  @Input() public reportType: String = '';

  public filterContractList: string | string[] = "";

  @ViewChild('contractSelect') public contractSelect?: SelectComponent;

  constructor(
    public originsService: OriginsService,
    public documentDelaysService: DocumentDelaysService,
    public NumberPlatesService: NumberPlatesService,
    private translateService: TranslateService,
    public saleContractsService: SaleContractsService
  ) {
    super();
  }

  public async loadFilterContract() {
    if (this.element.ThirdId.Id) {
      this.filterContractList = ['ThirdId.Id eq ' + this.element.ThirdId.Id];
      this.contractSelect?.selectComponent?.instance.getDataSource().filter(this.filterContractList);
      this.contractSelect?.selectComponent?.instance.getDataSource().reload();
    }
  }

  public DateFromExist() {
    if (this.element.DateFrom?.getFullYear() == 1) return false;
    return true;
  }

  public FormatPeriodText() {
    if (!this.element.DateFrom || !this.element.DateTo) return "";
    // console.log(formatDate(this.element.DateFrom, "dd/MM/yyyy", "FR-fr"));
    return sprintf(this.translateService.instant("Period from %s to %s"),
      this.element.DateFrom?.getDate() + "/" +
      (this.element.DateFrom?.getMonth() + 1) + "/" +
      this.element.DateFrom?.getFullYear(),
      this.element.DateTo?.getDate() + "/" +
      (this.element.DateTo?.getMonth() + 1) + "/" +
      this.element.DateTo?.getFullYear());
  }

  public onChangeContract() {
    this.element.DateFrom = new Date(Date.now());
    this.element.DateTo = new Date(Date.now());
  }

}
