import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SyslinkColumn } from 'projects/libraries/syslink-components/src/lib/helpers/SyslinkColumn';
import { AppInjectorService } from 'projects/libraries/syslink-components/src/lib/services/app-injector.service';
import { ConfigurationsService } from '../../../base/modules/configurations/configurations.service';
import { TaxRatesService } from '../../../base/tax-rates/tax-rates.service';
import { UnitsService } from '../../../base/units/unit.service';
import { AuthService } from '../../../core/auth/auth.service';
import { ODataService } from '../../../core/services/oData.service';
import { CustomersService } from '../../../thirds/customers/customers/customers.service';
import { SaleDocument } from './sale-document.model';
import { NotificationsService } from 'projects/libraries/syslink-components/src/public-api';
import { SaleDocumentStatus } from '../sale-document-statuses/sale-document-status.model';
import { ReportsService } from '../../../connectors/reports/reports.service';

@Injectable({
  providedIn: 'root'
})
export abstract class SaleDocumentsService<TSaleDocument extends SaleDocument, TSaleDocumentHeaderStatus extends SaleDocumentStatus> extends ODataService<TSaleDocument> {
  public url: string = 'saleDocument';
  protected configurationsService: ConfigurationsService;

  constructor(
    public reportsService: ReportsService
  ) {
    super();
    this.configurationsService = AppInjectorService.injector.get(ConfigurationsService);
  }

  public getDefaultDocumentLineColumns(subModuleCode: string, reportType: string): SyslinkColumn[] {
    const translateService = AppInjectorService.injector.get(TranslateService);
    const unitsService = AppInjectorService.injector.get(UnitsService);
    const taxRatesService = AppInjectorService.injector.get(TaxRatesService);

    return [
      new SyslinkColumn({ order: 1, width: 75 }),
      new SyslinkColumn({ order: 2, field: 'Reference', label: translateService.instant('Reference'), width: 150 }),
      new SyslinkColumn({ order: 3, field: 'Description', label: translateService.instant('Description'), cellTemplate: 'html-cell', editCellTemplate: 'edit-html-cell' }),
      new SyslinkColumn({ order: 4, field: 'Quantity', label: translateService.instant('Quantity'), width: 70 }),
      new SyslinkColumn({ order: 5, field: 'UnitId', label: translateService.instant('Unit'), cellTemplate: 'select-value', editCellTemplate: 'select-cell', data: { service: unitsService, displayKey: 'Name' }, width: 100 }),
      new SyslinkColumn({ order: 6, field: 'ExTaxPurchasePrice', label: 'ExTaxPurchasePrice', type: 'number', cellTemplate: 'currency-cell', width: 70, data: { subModuleCode: subModuleCode, key: 'Precision.' + reportType + 'Line.ExTaxPurchasePrice' }, editable: false }),
      new SyslinkColumn({ order: 6, field: 'ExTaxGrossPrice', label: translateService.instant('ExTaxGrossPrice'), type: 'number', cellTemplate: 'currency-cell', editCellTemplate: 'edit-currency-cell', width: 70, data: { subModuleCode: subModuleCode, key: 'Precision.' + reportType + 'Line.ExTaxGrossPrice' } }),
      new SyslinkColumn({ order: 7, field: 'Margin', label: translateService.instant('Discount margin'), cellTemplate: 'object-discount-cell', editCellTemplate: 'edit-object-discount-cell', data: { discountTypeField: 'IsDiscountFixed' }, width: 90 }),
      // new SyslinkColumn({ order: 8, field: 'Labor', label: translateService.instant('Qty M.O'), type: 'number', width: 70 }),
      // new SyslinkColumn({ order: 9, field: 'HourlyRate', label: translateService.instant('H. Rate'), type: 'number', cellTemplate: 'currency-cell', width: 70 }),
      new SyslinkColumn({ order: 10, field: 'ExTaxSaleGrossPrice', label: translateService.instant('ExTaxSaleGrossPrice'), type: 'number', data: { subModuleCode: subModuleCode, key: 'Precision.' + reportType + 'Line.ExTaxSaleGrossPrice' }, cellTemplate: 'currency-cell', editable: false, width: 70 }),
      new SyslinkColumn({ order: 11, field: 'Discount', label: translateService.instant('Discount'), cellTemplate: 'object-discount-cell', editCellTemplate: 'edit-object-discount-cell', data: { discountTypeField: 'IsDiscountFixed' }, width: 90 }),
      new SyslinkColumn({ order: 12, field: 'ExTaxUnitPrice', label: translateService.instant('ExTaxUnitPrice'), type: 'number', cellTemplate: 'currency-cell', data: { subModuleCode: subModuleCode, key: 'Precision.' + reportType + 'Line.ExTaxUnitPrice' }, editable: false, width: 70 }),
      new SyslinkColumn({ order: 13, field: 'ForcedPrice', label: translateService.instant('ForcedPrice'), type: 'number', data: { subModuleCode: subModuleCode, key: 'Precision.' + reportType + 'Line.ForcedPrice' }, cellTemplate: 'currency-cell', editable: true, width: 70 }),
      new SyslinkColumn({ order: 14, field: 'TaxRateId', label: translateService.instant('Tax rate'), type: 'string', cellTemplate: 'select-value', editCellTemplate: 'select-cell', data: { service: taxRatesService, displayKey: 'Name', filter: "IsVisible eq true" }, width: 70 }),
      new SyslinkColumn({ order: 15, field: 'TaxAmount', label: translateService.instant('Tax Amount'), type: 'number', data: { subModuleCode: subModuleCode, key: 'Precision.' + reportType + 'Line.TaxAmount' }, cellTemplate: 'currency-cell', editable: false, width: 70 }),
      new SyslinkColumn({ order: 16, field: 'ExTaxTotalPrice', label: translateService.instant('ExTaxTotalPrice'), data: { subModuleCode: subModuleCode, key: 'Precision.' + reportType + 'Line.ExTaxTotalPrice' }, type: 'number', cellTemplate: 'currency-cell', editable: false, width: 70, fixed: { position: 'right' } }),
      new SyslinkColumn({ order: 17, field: 'InTaxTotalPrice', label: translateService.instant('InTaxTotalPrice'), data: { subModuleCode: subModuleCode, key: 'Precision.' + reportType + 'Line.InTaxTotalPrice' }, type: 'number', cellTemplate: 'currency-cell', editable: false, width: 70, fixed: { position: 'right' } }),
      // new SyslinkColumn({ order: 18, field: 'PenaltyValue', label: translateService.instant('Penalty Value'), type: 'number', cellTemplate: 'currency-cell', editable: false, width: 70, visible: false })
    ];
  }

  public computeDeadline(startDate: Date, documentDelayId: number, reportType: string, documentId?: number): Promise<Date> {
    return this.apiService.sendRequest('/api/SaleDocument/computeDeadline', 'POST', {
      DocumentId: documentId,
      Date: startDate,
      DocumentDelayId: documentDelayId,
      ReportType: reportType
    });
  }


  // Document delay
  // --------------
  public async refreshThirdDocumentDelayFromCustomer(element: any): Promise<TSaleDocument> {

    if (element.ThirdId && element.ThirdId.Id) {
      const customersService = AppInjectorService.injector.get(CustomersService);
      const documentDelayId = (await customersService.getCustomerDocumentDelayByThirdId(element.ThirdId.Id)).Id;
      if (documentDelayId) {
        element.DocumentDelayId = { Id: documentDelayId };
      }
    }
    return element;
  }

  // Responsible User
  // ----------------
  public async refreshResponsibleUser(element: any): Promise<TSaleDocument> {

    const authService = AppInjectorService.injector.get(AuthService);
    const third = await authService.getUserThird();
    if (third) {
      element.ResponsibleUserId = { Id: third.Id };
      // element.responsibleUser = await this.usersService.getThird(userId);
    }
    return element;
  }


  // Format
  // ------
  public override async format(params?: Partial<TSaleDocument>): Promise<TSaleDocument> {
    var result: any = {
      ...params,
      DocumentDelayId: params?.DocumentDelayId?.Id,
      ThirdId: params?.ThirdId?.Id,
      IntermediateThirdId: params?.IntermediateThirdId?.Id,
      ResponsibleUserId: params?.ResponsibleUserId?.Id,
      CurrentStatusLink: params?.CurrentStatusLink?.Id,
      ContractId: params?.ContractId?.Id ?? null,
      PeriodicityId: params?.PeriodicityId?.Id,
      IndexingTypeId: params?.IndexingTypeId?.Id
    };

    delete result.DocumentDataCollection;
    delete result.ThirdDocumentData;
    delete result.IntermediateThirdIdDocumentData;
    delete result.ThirdDocumentDataIndex;
    delete result.IntermediateThirdIdDocumentDataIndex;
    delete result.Lines;
    delete result.Statuses;
    delete result.DocumentFinances;
    delete result.SaleQuoteHeaders;
    delete result.Payments;
    delete result.ExtensionDate;
    delete result.RenewalEndDate;
    delete result.DocumentRelations;
    //delete result.LastIndexingDate;

    let dateFrom: Date | undefined = result.DateFrom;
    if (!dateFrom || dateFrom.getFullYear().toString() == "1") {
      delete result.DateFrom;
      delete result.DateTo;
    }
    let stopDate: Date = result.StopDate;
    if (!stopDate || stopDate.getFullYear().toString() == "1") {
      delete result.StopDate;
    }
    let currentPeriodStartDate: Date = result.CurrentPeriodStartDate;
    if (!currentPeriodStartDate || currentPeriodStartDate.getFullYear().toString() == "1") {
      delete result.CurrentPeriodStartDate;
    }
    let currentPeriodEndDate: Date = result.CurrentPeriodEndDate;
    if (!currentPeriodEndDate || currentPeriodEndDate.getFullYear().toString() == "1") {
      delete result.CurrentPeriodEndDate;
    }
    let generateDocumentNextDate: Date = result.GenerateDocumentNextDate;
    if (!generateDocumentNextDate || generateDocumentNextDate.getFullYear().toString() == "1") {
      delete result.GenerateDocumentNextDate;
    }

    return result;
  }
  
  public async printRows(elements: TSaleDocument[], extensionType: string, type: string) {
    await this.reportsService.printRows(elements.map((row: TSaleDocument) => row?.Id), type, type + ".pdf", extensionType);
  }

  /***********************************/
  /****   ContextMenuItemAction   ****/
  /***********************************/

  // Action
  // ------
  public canUpdateStatus(elements: TSaleDocument[], status: TSaleDocumentHeaderStatus): boolean {
    var result: boolean = true;

    // Checking status is not empty
    // ----------------------------
    if (status == null) {
      NotificationsService.sendErrorMessage("Status is empty");
      result = false;
    }

    // Checking status is not empty
    // ----------------------------
    if (elements?.some(row => row.CurrentStatusLink?.StatusId?.Sequence != null && status.Sequence != null && row.CurrentStatusLink?.StatusId?.Sequence >= status?.Sequence)) {
      NotificationsService.sendErrorMessage("Current status is greater or equal than selected status");
      result = false;
    }
    return result;
  }

  // Statistics
  // ----------
  public convertODataFiltersToString(filters: any[]): string {
    if (!filters || filters == null) return '';
    var result = '';

    // single filter
    if (typeof (filters[0]) === 'string' && (filters[0] == "Deadline" || filters[0] == "Date")) {
      result = this.formatDate(filters[2], filters[1], filters[0]);
    }
    else if (typeof (filters[0]) === 'string' && (filters[0] == "ExTaxTotal" || filters[0] == "InTaxTotal" || filters[0] == "AmountRemaining")) {
      result = this.formatNumber(filters[2], filters[1], filters[0]);
    }
    else if (typeof (filters[0]) === 'string') {
      switch (filters[1]) {
        case 'contains':
          result = `contains(${filters[0]}%2C%27${filters[2]}%27)`;
          break;
        case 'notcontains':
          result = `notcontains(${filters[0]}%2C%27${filters[2]}%27)`;
          break;
        case 'startswith':
          result = `startswith(${filters[0]}%2C%27${filters[2]}%27)`;
          break;
        case 'endswith':
          result = `endswith(${filters[0]}%2C%27${filters[2]}%27)`;
          break;
        case '=':
          result = `${filters[0]}%20eq%20%27${filters[2]}%27)`;
          break;
        case '<>':
          result = `${filters[0]}%20ne%20%27${filters[2]}%27)`;
          break;
      }
    }
    // multiple filter
    else if (Array.isArray(filters[0])) {
      for (var i = 0; i < filters.length; i++) {
        if (filters[i + 1] != undefined) {
          result += `(${this.convertODataFiltersToString(filters[i])})%20${filters[i + 1]}%20`;
          i++;
        }
        else {
          result += `(${this.convertODataFiltersToString(filters[i])})`
        }
      }
    } else return '';

    return result;
  }
  private formatDate(value: string, type: string, field: string) {
    if (type == 'between') {
      const firstdate = new Date(value[0]);
      const firstyear = firstdate.getFullYear();
      const firstmonth = String(firstdate.getMonth() + 1).padStart(2, '0');
      const firstday = String(firstdate.getDate()).padStart(2, '0');

      const seconddate = new Date(value[1]);
      const secondyear = seconddate.getFullYear();
      const secondmonth = String(seconddate.getMonth() + 1).padStart(2, '0');
      const secondday = String(seconddate.getDate()).padStart(2, '0');

      return `${field} >= %23${firstyear}-${firstmonth}-${firstday}%23  AND ${field} <= %23${secondyear}-${secondmonth}-${secondday}%23 `;
    }
    const date = new Date(value);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    const nextValue = new Date(date.setDate(date.getDate() + 1))
    const nextyear = nextValue.getFullYear();
    const nextmonth = String(nextValue.getMonth() + 1).padStart(2, '0');
    const nextday = String(nextValue.getDate()).padStart(2, '0');

    switch (type) {
      case '=':
        return `${field} >= %23${year}-${month}-${day}%23 AND ${field} < %23${nextyear}-${nextmonth}-${nextday}%23 `;
      case '<>':
        return `${field} != %23${year}-${month}-${day}%23 `;
      case '<':
        return `${field} < %23${year}-${month}-${day}%23 `;
      case '>':
        return `${field} > %23${nextyear}-${nextmonth}-${nextday}%23 `;
      case '<=':
        return `${field} <= %23${year}-${month}-${day}%23 `;
      case '>=':
        return `${field} >= %23${year}-${month}-${day}%23 `;
    }
    return "";
  }

  private formatNumber(value: string, type: string, field: string) {
    switch (type) {
      case '=':
        return `${field} ==  ${value}`;
      case '<>':
        return `${field} !=  ${value}`;
      case '<':
        return `${field} <  ${value}`;
      case '>':
        return `${field} >  ${value}`;
      case '<=':
        return `${field} <=  ${value}`;
      case '>=':
        return `${field} >=  ${value}`;
      case 'between':
        return `${field} >= ${value[0]} AND ${field} <= ${value[1]}`;
    }
    return "";
  }
}

export enum SaleContexMenuItemActionCode {
  PrintToZIP = 'PrintToZIP',
  PrintToPDF = 'PrintToPDF',
  Status = 'Status',
  payment = 'Payment'
}


