<syslink-title
    *ngIf="element.Code!=undefined"
    [text]="element.Code"
></syslink-title>
Add permission
<syslink-toolbar [actions]="toolbarActions"></syslink-toolbar>
<app-user-role-form [(element)]="element"></app-user-role-form>
<p></p>
<syslink-tabs #userRoles>
    <!-- <syslink-tab label="Permission">
       <app-user-role-user-permission
       [userRole]="element"
       (userPermissionChange)="onSavebtnClicked()"
       ></app-user-role-user-permission>
    </syslink-tab>
    <syslink-tab label="User">
        <app-user-role-user-user
        [userRole]="element"
        (userChange)="onSavebtnClicked()"
        ></app-user-role-user-user>
     </syslink-tab>
     <syslink-tab label="Group">
        <app-user-role-user-group
        [userRole]="element"
        (userGroupChange)="onSavebtnClicked()"
        ></app-user-role-user-group>
     </syslink-tab> -->
</syslink-tabs>
<syslink-confirm-modal
    #deleteConfirm
    title="Delete"
    content="DeleteConfirmMessage"
    (onValidate)="roleDelete()"
    [validateText]="'Confirm'"
    [cancelText]="'Cancel'"
></syslink-confirm-modal>