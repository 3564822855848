import { Injectable } from '@angular/core';
import { ODataService } from '../../core/services/oData.service';
import { AccountType } from './account-type.model';

@Injectable({
  providedIn: 'root'
})
export class AccountTypesService extends ODataService<AccountType> {
  public url = 'AccountType';
}

