import { Injectable } from '@angular/core';
import { LoadOptions } from 'devextreme/data';
import { TranslateService } from '@ngx-translate/core';
import { WorkType } from './work-type.model';
import { ODataService } from '../../../core/services/oData.service';

@Injectable({
  providedIn: 'root'
})
export class WorkTypesService extends ODataService<WorkType> {
  public url = 'WorkType';
  public override defaultOptions: LoadOptions = {
    expand: ['NameTranslationId.Translations.LanguageId']
  };

  constructor(
    private translateService: TranslateService
  ) {
    super();
  }

  public override format(element: Partial<WorkType>): WorkType {
    var result: any = {
      ...element,
    };
    delete result.Tasks;
    return result;
  }

  public override async getInstance(params?: Partial<WorkType>): Promise<WorkType> {
    var element = new WorkType({
      Name: this.translateService.instant('New work type'),
      ...params
    });
    return element;
  }
}
