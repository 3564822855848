<dx-tree-list
  id="treelist"
  *ngIf="canView"
  #treelist
  [dataSource]="items ?? dataSource"
  (onRowDblClick)="onRowDblClick($event)"
  (onInitNewRow)="onPrepareRow.emit($event)"
  (onRowInserting)="onBeforeInsert.emit($event)"
  (onRowInserted)="onAfterInsert.emit($event)"
  (onRowUpdating)="onBeforeUpdate.emit($event)"
  (onRowUpdated)="onAfterUpdate.emit($event)"
  (onRowRemoving)="onBeforeDelete.emit($event)"
  (onRowRemoved)="onAfterDelete.emit($event)"
  (onRowPrepared)="onRowPrepared.emit($event)"
  (onEditingStart)="onEditingStart.emit($event)"
  (onFocusedCellChanging)="onFocusedCellChanging.emit($event)"
  [(selectedRowKeys)]="selectedKeys"
  (onSelectionChanged)="onSelectionChanged($event)"
  [allowColumnReordering]="allowColumnReordering"
  [rowDragging]="{onReorder:onReorderEvent,allowDropInsideItem:allowDropInsideItem,allowReordering:allowReordering,showDragIcons:showDragIcons}"
  [rootValue]="null"
  [parentIdExpr]="parentIdExpr"
  [expandedRowKeys]="[0]"
  [remoteOperations]="remoteOperations"
  [hasItemsExpr]="hasItemsExpr"
  [filterRow]="{visible:canFilter}"
>
  <!-- [remoteOperations]="'auto'" -->
  <dxo-state-storing
    *ngIf="stateStoringEnabled && storageKey"
    [enabled]="true"
    type="localstorage"
    [storageKey]="storageKey"
    [savingTimeout]="100"
  ></dxo-state-storing>
  <ng-container *ngFor="let column of columns; let columnIndex = index">
    <dxi-column
      [dataField]="column.field"
      [dataType]="column.type"
      [sortIndex]="column.sortIndex"
      [sortOrder]="column.sortDirection"
      [caption]="(column.label ?? column.field) | translate"
      headerCellTemplate="headerCellTemplate"
      [cellTemplate]="column.cellTemplate"
      [editCellTemplate]="column.editCellTemplate"
      [allowEditing]="column.editable"
      [width]="column.width"
      [visible]="column.visible"
      [showEditorAlways]="column.editCellTemplate === 'boolean-cell'"
      [allowEditing]="canUpdate"
      [allowSorting]="canSort"
      [allowFiltering]="canFilter"
      [allowHeaderFiltering]="canFilter"
    >
      <!-- HEADER Template -->
      <ng-container *dxTemplate="let header of 'headerCellTemplate'">
        <span
          [id]="'headerColumn_' + (header.column.dataField).replace('.', '_')"
          [title]="header.column.title || ''"
        >{{header.column.caption}}</span>
      </ng-container>

      <!-- CELL Templates -->
      <ng-container *dxTemplate="let cell of 'currency-cell'">
        <div class="text-end">
          {{ cell.value | syslinkCurrency:(getColumn(cell.column.dataField).data?.subModuleCode):getColumn(cell.column.dataField).data?.key }}
        </div>
      </ng-container>

      <ng-container *dxTemplate="let cell of 'date-cell'">
        <div class="text-end">{{ cell.value }}</div>
      </ng-container>

      <ng-container *dxTemplate="let cell of 'text-cell'">
        {{ cell.value }}
      </ng-container>

      <ng-container *dxTemplate="let cell of 'number-cell'">
        {{ cell.value }}
      </ng-container>

      <ng-container *dxTemplate="let cell of 'html-cell'">
        <dx-html-editor
          class="border-none"
          [readOnly]="true"
          [(value)]="cell.value"
          [elementAttr]="{ class: 'custom-html-editor' }"
        ></dx-html-editor>
      </ng-container>

      <ng-container *dxTemplate="let cell of 'statusText-cell'">
        <app-status-text-cell
          [cell]="cell"
          [getStatusLabel]="getColumn(cell.column.dataField).data.getStatusLabel"
          [getStatusDate]="getColumn(cell.column.dataField).data.getStatusDate"
          [getStatusClass]="getColumn(cell.column.dataField).data.getStatusClass"
          [getStatusColor]="getColumn(cell.column.dataField).data.getStatusColor"
        ></app-status-text-cell>
      </ng-container>

      <ng-container *dxTemplate="let cell of 'select-value'">
        <ng-container *ngIf="
            cell.value &&
            getColumn(cell.column.dataField).data &&
            getColumn(cell.column.dataField).data.displayKey
          ">
          {{ cell.value[getColumn(cell.column.dataField).data.displayKey] }}
        </ng-container>
      </ng-container>

      <ng-container *dxTemplate="let cell of 'object-discount-cell'">
        <ng-container *ngIf="isNewObjectDiscountCell(cell)">
          {{fromDotNotation(cell, getColumn(cell.column.dataField).data.discountTypeField) ? cell.value.Value : cell.value.Value * 100}}
          {{fromDotNotation(cell, getColumn(cell.column.dataField).data.discountTypeField) ? "€" : "%"}}
        </ng-container>
      </ng-container>

      <!-- EDIT Templates -->
      <ng-container *dxTemplate="let cell of 'translations-cell'">
        <!-- <syslink-edit-translations-cell
          (onTranslationsBtnClicked)="
            onTranslationsBtnClicked($event, cell, column)
          "
          [cell]="cell"
          [column]="column"
        ></syslink-edit-translations-cell> -->
        <syslink-edit-translations-cell
          (modelUpdate)="cell.setValue($event)"
          (onTranslationsBtnClicked)="onTranslationsBtnClicked(cell.data['NameTranslationId'].Translations, cell)"
          [model]="cell.value"
          [canShowIcon]="cell.data.Id"
        ></syslink-edit-translations-cell>
      </ng-container>

      <ng-container *dxTemplate="let cell of 'edit-currency-cell'">
        <syslink-input-number
          class=""
          labelMode="hidden"
          [model]="cell.value"
          [subModuleCode]="getColumn(cell.column.dataField).data?.subModuleCode"
          [key]="getColumn(cell.column.dataField).data?.key"
          (modelChange)="cell.setValue($event)"
        ></syslink-input-number>
      </ng-container>
      <ng-container *dxTemplate="let cell of 'badge-list-cell'">
        <syslink-badge-list-cell
          *ngIf="cell.value!=undefined"
          [cell]="cell"
          [data]="getColumn(cell.column.dataField).data"
        ></syslink-badge-list-cell>
      </ng-container>
      <ng-container *dxTemplate="let cell of 'boolean-cell'">
        <syslink-boolean-cell
          *ngIf="cell.value!=undefined"
          [cell]="cell"
          [data]="getColumn(cell.column.dataField).data"
        ></syslink-boolean-cell>
      </ng-container>
      <ng-container *dxTemplate="let cell of 'select-cell'">
        <syslink-select
          [store]="getColumn(cell.column.dataField).data.service.store"
          [filter]="getColumn(cell.column.dataField).data.filter"
          [(model)]="cell.value"
          (modelChange)="cell.setValue($event)"
          [displayKey]="getColumn(cell.column.dataField).data.displayKey"
          class=""
        ></syslink-select>
      </ng-container>

      <ng-container *dxTemplate="let cell of 'edit-object-discount-cell'">
        <div class="d-flex">
          <ng-container>
            <syslink-input-number
              labelMode="hidden"
              [min]="0"
              [max]="fromDotNotation(cell, getColumn(cell.column.dataField).data.discountTypeField) ? undefined : 100"
              [model]="fromDotNotation(cell, getColumn(cell.column.dataField).data.discountTypeField) ? cell.value.Value : cell.value.Value * 100"
              (modelChange)="onObjectDiscountCellUpdated($event, cell, fromDotNotation(cell, getColumn(cell.column.dataField).data.discountTypeField))"
              class=""
            ></syslink-input-number>

            <syslink-select
              labelMode="hidden"
              [displayKey]="'name'"
              valueKey="id"
              [items]="[
                { id: 0, name: '%' },
                { id: 1, name: '€' }
              ]"
              [model]="fromDotNotation(cell, getColumn(cell.column.dataField).data.discountTypeField) ? 1 : 0"
              (modelChange)="onObjectDiscountTypeChange($event, cell, getColumn(cell.column.dataField).data.discountTypeField)"
              class=""
            ></syslink-select>
          </ng-container>
        </div>
      </ng-container>

      <ng-container *dxTemplate="let cell of 'edit-html-cell'">
        <syslink-wysiwyg
          *ngIf="cell.data.lineType !== 'post'"
          labelMode="hidden"
          height="150px"
          [table]="false"
          [image]="false"
          class="bg-white"
          [multilineToolbar]="false"
          [model]="cell.value"
          (modelChange)="cell.setValue($event)"
        ></syslink-wysiwyg>
        <syslink-input-text
          *ngIf="cell.data.lineType === 'post'"
          class=""
          [model]="cell.value"
          (modelChange)="cell.setValue($event)"
        ></syslink-input-text>
      </ng-container>
      <ng-container *dxTemplate="let cell of 'duration-cell'">
        <syslink-duration-cell
          [cell]="cell"
          [data]="getColumn(cell.column.dataField).data"
        ></syslink-duration-cell>
      </ng-container>
      <ng-container *dxTemplate="let cell of 'colored-date-cell'">
        <syslink-colored-date-cell
          [cell]="cell"
          [data]="getColumn(cell.column.dataField).data"
        ></syslink-colored-date-cell>
      </ng-container>
      <ng-container *dxTemplate="let cell of 'tag-cell'">
        <syslink-tag-cell
          [cell]="cell"
          [data]="getColumn(cell.column.dataField).data"
        >
        </syslink-tag-cell>
      </ng-container>

      <!-- CUSTOM Template -->
      <ng-container *dxTemplate="let itemData of 'template-cell'; let itemIndex = index">
        <div *ngIf="
        getColumn(itemData.column.dataField)?.data.template ==
        'colored-euro-cell'
      ">
      <!-- {{itemData.value|json}} -->
          <i
            role="button"
            *ngIf="itemData.value"
            class="fas fa-euro-sign cl-{{itemData.value}}"
          ></i>
        </div>
      </ng-container>

      <dxo-lookup
        *ngIf="column.type == 'object'"
        [dataSource]="column.data.service.store"
        [valueExpr]="column.data.valueKey ?? 'Id'"
        [displayExpr]="column.data.displayKey ?? 'Name'"
      ></dxo-lookup>
    </dxi-column>
  </ng-container>
  <dxi-column
    *ngIf="options.editing?.allowDeleting || options.editing?.allowUpdating"
    type="buttons"
  ></dxi-column>
</dx-tree-list>