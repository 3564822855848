import { Component } from '@angular/core';
import { PageComponent} from 'projects/libraries/syslink-components/src/public-api';
import { UsersService } from './users.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent extends PageComponent {
  constructor(
  ) {
    super();
  }
}
