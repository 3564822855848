import { Component } from '@angular/core';
import { PurchaseInvoice } from '../purchase-invoices/purchase-invoice.model';
import { PurchaseInvoicesService } from '../purchase-invoices/purchase-invoices.service';
import { PurchaseInvoiceLine } from '../purchase-invoice-lines/purchase-invoice-line.model';
import { PurchaseInvoiceLinesService } from '../purchase-invoice-lines/purchase-invoice-lines.service';
import { PurchaseInvoiceHeaderStatus } from '../purchase-invoice-header-statuses/purchase-invoice-header-status.model';
import { PurchaseInvoiceHeaderStatusesService } from '../purchase-invoice-header-statuses/purchase-invoice-header-statuses.service';
import { PurchaseInvoiceHeaderHeaderStatus } from '../purchase-invoice-header-header-statuses/purchase-invoice-header-header-status.model';
import { PurchaseInvoiceHeaderHeaderStatusesService } from '../purchase-invoice-header-header-statuses/purchase-invoice-header-header-statuses.service';
import { PurchaseDocumentDetailsComponent } from '../../purchase-documents/purchase-documents/purchase-document-details/purchase-document-details.component';
import { AppInjectorService } from 'projects/libraries/syslink-components/src/lib/services/app-injector.service';
import { TaxesService } from '../../../accountings/taxes/taxes.service';
import { AccountsService } from '../../../accountings/accounts/accounts.service';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-purchase-invoice-details',
  templateUrl: '../../purchase-documents/purchase-documents/purchase-document-details/purchase-document-details.component.html',
  styleUrls: ['./purchase-invoice-details.component.scss']
})
export class PurchaseInvoiceDetailsComponent extends PurchaseDocumentDetailsComponent<
  PurchaseInvoice,
  PurchaseInvoicesService,
  PurchaseInvoiceLine,
  PurchaseInvoiceLinesService,
  PurchaseInvoiceHeaderStatus,
  PurchaseInvoiceHeaderStatusesService,
  PurchaseInvoiceHeaderHeaderStatus,
  PurchaseInvoiceHeaderHeaderStatusesService
>{
  protected override loadDependencies() {
    this.PurchaseDocumentService = AppInjectorService.injector.get(PurchaseInvoicesService);
    this.PurchaseDocumentLinesService = AppInjectorService.injector.get(PurchaseInvoiceLinesService);
    this.PurchaseDocumentStatusesService = AppInjectorService.injector.get(PurchaseInvoiceHeaderStatusesService);
    this.PurchaseDocumentHeaderStatusesService = AppInjectorService.injector.get(PurchaseInvoiceHeaderHeaderStatusesService);

    const taxesService = AppInjectorService.injector.get(TaxesService);
    const accountsService = AppInjectorService.injector.get(AccountsService);
    const translateService = AppInjectorService.injector.get(TranslateService);

    this.reportType = 'PurchaseInvoice';
    this.subModuleCode = 'Purchases.Invoice';
    this.defaultStatusCode = 'Invoice.Proforma';
    this.documentLinesColumns = [...this.PurchaseDocumentService.getDefaultDocumentLineColumns(this.subModuleCode,this.reportType)];

  }

  public override canEditDocument(): boolean {
    return !this.hasActiveStatus('Invoice.Ventilated') && !this.hasActiveStatus('Invoice.Canceled');
  }
}