<syslink-modal
  #modal
  title="Select user group"
  (onValidate)="onValidateButtonClicked($event)"
  width="350"
>
  <syslink-select
    #select
    label="User group"
    [filter]="selectFilters"
    [store]="userGroupsService.store"
    [(model)]="modal.data"
    displayKey="Name"
  ></syslink-select>
</syslink-modal>
