<syslink-title text="Sale invoices"></syslink-title>

<ng-container *ngIf="globalResult">
  <div class="row gap-2">
    <div class="offset-4 col-1 rounded bg-success text-center text-white border border-dark">
      <div class="h-100 d-flex flex-column justify-content-between">
        <p class="h6 fw-bold my-2"> {{'Total amount' | translate}}</p>
        <div>
          <p class="my-1">{{globalResult.ExTaxTotal | currency}} H.T.V.A</p>
          <p class="my-1">{{globalResult.InTaxTotal | currency}} T.T.C</p>
        </div>
      </div>
    </div>
    <div class="col-1 rounded text-center border border-secondary">
      <div class="h-100 d-flex flex-column justify-content-between">
        <p class="h6 fw-bold my-2">{{'Open amount' | translate}}</p>
        <div>
          <p class="my-1">&nbsp;</p>
          <p class="my-1">{{globalResult.AmountRemaining | currency}} T.T.C</p>
        </div>
      </div>
    </div>
    <div class="col-1 rounded bg-danger text-center text-white border border-secondary">
      <div class="h-100 d-flex flex-column justify-content-between">
        <p class="h6 fw-bold my-2">{{'Amount due' | translate}}</p>
        <div>
          <p class="my-1">&nbsp;</p>
          <p class="my-1">{{globalResult.InTaxOverdueAmount | currency}} T.T.C</p>
        </div>
      </div>
    </div>
    <div class="col-1 rounded bg-gray text-center text-white border border-secondary">
      <div class="h-100 d-flex flex-column justify-content-between">
        <p class="h6 fw-bold my-2">{{'Unvalidated amount' | translate}}</p>
        <div>
          <p class="my-1">{{globalResult.ExTaxUnvalidatedTotalAmount | currency}} H.T.V.A</p>
          <p class="my-1">{{globalResult.InTaxUnvalidatedTotalAmount | currency}} T.T.C</p>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<app-sale-invoice-grid
  #grid
  [toolbarActions]="toolbarActions"
  [permissionKey]="'list'"
  [canDelete]="false"
  (add)="onAddButtonClicked()"
  [contextMenuItems]="contextMenuItems"
  (onFilterValueChange)="onFilterValueChange($event)"
></app-sale-invoice-grid>

<app-document-create-modal
  #createModal
  title="Create Invoice"
  [thirdFilter]="customerFilter"
  detailsUrl="/thirds/customers/"
  (validate)="onCreateValidated($event)"
></app-document-create-modal>

<syslink-confirm-modal
  #confirmModal
  (onValidate)="onConfirmModalValidated($event)"
></syslink-confirm-modal>
<app-sale-invoice-status-select-modal
  #statusModal
  (validate)="onConfirmModalValidated($event)"
></app-sale-invoice-status-select-modal>
<app-payments
  *ngIf="paymentElement && paymentDocument"
  #paymentModal
  [reportType]="'SaleInvoice'"
  [showGrid]="false"
  [element]="paymentElement"
  [document]="paymentDocument"
  (paymentsChange)="grid.refresh()"
></app-payments>
<syslink-modal
  #globalReportModal
  title="Select year"
  width="350px"
  (onValidate)="previewSaleGlobalReportPreview()"
>
  <syslink-input-number
    [max]="globalReportModal.data.maxDate"
    [min]="2023"
    label="Year"
    [(model)]="globalReportModal.data.date"
  ></syslink-input-number>
  <syslink-switch
    label="byMonth"
    [(model)]="globalReportModal.data.byMonth"
  ></syslink-switch>
</syslink-modal>
