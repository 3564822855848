<div
  id="login-container"
  class="h-100 w-100 m-0 p-0 row align-items-center bg-secondary"
>
  <form
    #loginForm
    class="d-flex flex-column mx-auto p-5"
  >
    <img
      class="img-fluid mb-4"
      src="/assets/logo.png"
    />
    <syslink-input-text
      name="username"
      label="Username"
      mode="outlined"
      labelMode="hidden"
      [(model)]="userLogin.userName"
    ></syslink-input-text>
    <syslink-input-text
      id="#password-password"
      class="my-2"
      name="password"
      label="Password"
      type="password"
      mode="outlined"
      labelMode="hidden"
      [(model)]="userLogin.password"
    ></syslink-input-text>
    <syslink-button
      class="mt-4"
      name="login"
      text="Login"
      (click)="onLoginButtonClicked()"
      (keydown.enter)="onLoginButtonClicked()"
      width="100%"
      height="40px"
    ></syslink-button>
  </form>
</div>