<syslink-switch
  width="30px"
  [disabled]="data.disabled"
  [trueText]="getText ? (getText(cell) | translate) : (data.trueText || '')"
  [trueColor]="getColor?getColor(cell): data.trueColor"
  [falseText]="getText ? (getText(cell) | translate) : (data.falseText || '')"
  [falseColor]="getColor?getColor(cell): data.falseColor"
  [(model)]="cell.value"
  [type]="data.type"
  (modelChange)="cell.setValue(!cell.value)"
>
</syslink-switch>