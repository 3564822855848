import { Injectable } from '@angular/core';
import { LoadOptions } from 'devextreme/data';
import { ApiService } from '../../../core/api.service';
import { SaleDocumentLinesService } from '../../sale-documents/sale-document-lines/sale-document-lines.service';
import { SaleOrderLine } from './sale-order-line.model';

@Injectable({
  providedIn: 'root'
})
export class SaleOrderLinesService extends SaleDocumentLinesService<SaleOrderLine> {
  public override url: string = 'SaleOrderLine';
  public override documentType: string = 'SaleOrder';
  public override defaultOptions: LoadOptions = {
    expand: ['Margin', 'Discount', 'ParentId']
  };

  constructor(public override api: ApiService) {
    super(api);
  }

  public override async getInstance(order?: Partial<SaleOrderLine>): Promise<any> {
    let element = {
      ...order,
    };
    return element;
  }

  public async updateParentId(saleOrderLine: SaleOrderLine, targetParentId: number) {
    return await this.api.sendRequest('/api/SaleDocumentLine/' + saleOrderLine.Id + '/changeParent/' + targetParentId, 'POST');
  }
}
