import { Component, Input, OnInit } from '@angular/core';
import { CreditsafesService } from '../creditsafe.service';

@Component({
  selector: 'app-creditsafe-financial-statements',
  templateUrl: './creditsafe-financial-statements.component.html',
  styleUrls: ['./creditsafe-financial-statements.component.scss']
})
export class CreditsafeFinancialStatementsComponent implements OnInit {
  @Input() element: any;

  public items: any[] = [];
  public dataList: any[] = []

  constructor(public creditsafesService: CreditsafesService) { }

  ngOnInit(): void {
    this.items = this.element.Report?.FinancialStatements ?? [];
    this.loadDataList(this.items[0], undefined);
    this.dataList = this.dataList.filter((s: string) => s !== 'Currency');    
  }

  public loadDataList(obj: any, parent: string | undefined) {
    for (let key in obj) {
      if (key == "Type" || key == "YearEndDate" || key == "NumberOfWeeks") {
        continue;
      }
      if (obj.hasOwnProperty(key)) {
        if (typeof obj[key] === 'object' && obj[key] !== null) {
          this.dataList.push(key);
          this.loadDataList(obj[key], key);
        } else {
          this.dataList.push(parent == undefined ? key : parent + '.' + key);
        }
      }
    }
  }

  public isSubTitle(string: string): boolean {
    return !string.includes('.');
  }
}
