import { Injectable } from '@angular/core';
import { LoadOptions } from 'devextreme/data';
import { ConfigurationsService } from '../../base/modules/configurations/configurations.service';
import { TaxRate } from '../../base/tax-rates/tax-rate';
import { ODataService } from '../../core/services/oData.service';
import { PriceList } from '../../thirds/customers/price-lists/price-list';
import { Product } from '../products/product.model';
import { ProductsService } from '../products/product.service';
import { CustomerCatalog } from './customer-catalog.model';

@Injectable({
  providedIn: 'root'
})
export class CustomerCatalogsService extends ODataService<CustomerCatalog> {
  public url = 'CustomerCatalog';

  constructor(
    private configurationsService: ConfigurationsService,
    private productsService: ProductsService
  ) {
    super();
  }

  public async getInstanceWithProduct(product: Product, customerCatalogData?: CustomerCatalog): Promise<CustomerCatalog> {
    var customerCatalog = new CustomerCatalog({
      ProductId: new Product(this.productsService.format(product)),
      PriceListId: new PriceList({ Id: await this.configurationsService.getConfigurationAsNumber('priceList', 'Products.CustomerCatalogs', 'Products') }),
      TaxRateId: new TaxRate({ Id: await this.configurationsService.getConfigurationAsNumber('taxRate', 'Products.CustomerCatalogs', 'Products') }),
      ...customerCatalogData,
    });

    return customerCatalog;
  }

  public override format(element: CustomerCatalog): any {
    var result: any = {
      ...element,
      CustomerId: element.CustomerId?.Id || null,
      PriceListId: element.PriceListId?.Id || null,
      ProductCategoryId: element.ProductCategoryId?.Id,
      ProductId: element.ProductId?.Id,
      TaxRateId: element.TaxRateId?.Id,
    };

    delete result.DiscountId;
    delete result.MarginId;
    delete result.PenaltyId;

    return result;
  }
}