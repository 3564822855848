import { Entity } from "../../../core/models/entity";
import { Third } from "../../../thirds/thirds/third.model";
import { Address } from "../../addresses/addresses/address.model";
import { Document } from "../documents/document.model";

export class DocumentData extends Entity {
    public AddressId?: Address;
    public DeliveryAddressId?: Address;
    public UsedForQuote?: boolean;
    public UsedForOrder?: boolean;
    public UsedForInvoice?: boolean;
    public UsedForContract?: boolean;
    public VatNumber?: string;

    public SaleDocumentHeaderId: Document = new Document();
    public PurchaseDocumentHeaderId: Document = new Document();
    public StockMoveDocumentHeaderId: Document = new Document();
    public ThirdId: Third = new Third();
    public ContactId: Third = new Third();
    
    constructor(entity?: Partial<DocumentData>) {
        super(entity);
        Object.assign(this, entity);
    }
}
