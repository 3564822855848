import { Injectable } from '@angular/core';
import { Bank } from './bank.model';
import { ODataService } from '../../core/services/oData.service';
import { LoadOptions } from 'devextreme/data';

@Injectable({
  providedIn: 'root'
})
export class BanksService extends ODataService<Bank> {
  public url = 'Bank';
  public override defaultOptions: LoadOptions = {
    filter:['IsVisible eq true']
   };
  public override format(params: Bank): Bank {
    var result : any = {
      ...params,
    };
    return result;
  }
  public override async getInstance(params?: Partial<Bank>): Promise<Bank> {
    var element = new Bank({
      ...params
    });
    return element;
  }
}
