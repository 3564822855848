import { Component, Input } from '@angular/core';
import { BaseGridComponent } from 'projects/libraries/syslink-components/src/lib/grid/base-grid/base-grid.component';
import { NumberPlate } from '../number-plate.model';
import { SyslinkColumn } from 'projects/libraries/syslink-components/src/lib/helpers/SyslinkColumn';
import { SelectDescriptor } from 'devextreme/data';
import { NumberPlatesService } from '../number-plates.service';

@Component({
  selector: 'app-number-plate-grid',
  templateUrl: './number-plate-grid.component.html',
  styleUrls: ['./number-plate-grid.component.scss']
})
export class NumberPlateGridComponent  extends BaseGridComponent<NumberPlate> {
  @Input() override storageKey: string = 'vise-number-plate-grid';
  @Input() public override expand?: string | string[] = ["CustomerId"];
  @Input() public override select?: SelectDescriptor<any> = ['Id', 'Value'];

  public columns: SyslinkColumn[] = [
    new SyslinkColumn({ field: "Value", label: "Number plate" }),
    new SyslinkColumn({ field: "CustomerId", technical: true }),];

  constructor(
    public NumberPlatesService: NumberPlatesService,
  ) {
    super();
  }
}


