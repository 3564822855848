import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { SaleDocument } from '../../sale-documents/sale-documents/sale-document.model';
import { IndexingTypesService } from '../../../base/indexing/indexing-types.service';
import { ConfirmModalComponent, NotificationsService } from 'projects/libraries/syslink-components/src/public-api';
import { getNew } from 'projects/libraries/syslink-components/src/lib/helpers/tools';

@Component({
  selector: 'app-sale-contract-renewal',
  templateUrl: './sale-contract-renewal.component.html',
  styleUrls: ['./sale-contract-renewal.component.scss']
})
export class SaleContractRenewalComponent<
  TSaleDocument extends SaleDocument,
>  {
  @Input() public element: TSaleDocument = <TSaleDocument>getNew<TSaleDocument>();
  @Output() public elementChange: EventEmitter<TSaleDocument> = new EventEmitter<TSaleDocument>();
  @Output() public renewalChange: EventEmitter<TSaleDocument> = new EventEmitter<TSaleDocument>();

  @ViewChild('onEndRenewalClickedConfirmModal') onEndRenewalClickedConfirmModal!: ConfirmModalComponent;
  @ViewChild('onForceEndRenewalClickedConfirmModal') onForceEndRenewalClickedConfirmModal!: ConfirmModalComponent;

  constructor(
    public indexingTypesService: IndexingTypesService
  ) { }

  public onEndRenewalClicked() {
    this.onEndRenewalClickedConfirmModal.open();
  }

  public onForceEndRenewalClicked() {
    this.onForceEndRenewalClickedConfirmModal.open();
  }

  public addMonths(date: Date, months: number) {
    const dateCopy = new Date(date);
    dateCopy.setMonth(dateCopy.getMonth() + months);
    return dateCopy;
  }

  public addDays(date: Date, days: number): Date {
    const dateCopy = new Date(date);
    dateCopy.setDate(dateCopy.getDate() + days);
    return dateCopy;
  }

  public onEndRenewalConfirmed() {
    this.onEndRenewalClickedConfirmModal.close();
    if (!this.element.ExtensionDate) return;
    if (!this.element.NotificationDelay) {
      this.onForceEndRenewalConfirmed();
      return;
    }
    var dateToCompare = this.addMonths(this.element.ExtensionDate, -this.element.NotificationDelay);
    if (dateToCompare >= new Date()) {
      this.element.StopDate = new Date();
      NotificationsService.sendInfo("Stop date is " + this.element.StopDate);
    }
    else {
      this.element.StopDate = this.addDays(this.element.ExtensionDate, 1);
      NotificationsService.sendInfo("Stop date is " + this.element.StopDate);
    }
    this.renewalChange.emit();
  }

  public onForceEndRenewalConfirmed() {
    this.onForceEndRenewalClickedConfirmModal.close();
    this.element.StopDate = new Date();
    NotificationsService.sendInfo("Stop date is " + this.element.StopDate);
    this.renewalChange.emit();
  }

  public indexingChange(){
    if(!this.element.LastIndexingDate || !this.element.Indexing) return;
    this.element.NextIndexingDate =this.addMonths(this.element.LastIndexingDate,this.element.Indexing);
  }
}
