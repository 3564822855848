<syslink-title text="{{ 'Sale orders' }}"></syslink-title>

<app-sale-order-grid
  #grid
  [permissionKey]="'list'"
  [canUpdate]="false"
  (add)="onAddButtonClicked()"
  [contextMenuItems]="contextMenuItems"
  [items]="items"
  [expand]="expand"
>
</app-sale-order-grid>
<app-document-create-modal
  #initModal
  title="Create Order"
  [thirdFilter]="customerFilter"
  detailsUrl="/thirds/customers/"
  (validate)="onCreateValidated($event)"
></app-document-create-modal>

<syslink-confirm-modal
  #confirmModal
  (onValidate)="onConfirmModalValidated($event)"
></syslink-confirm-modal>
<app-sale-order-status-select-modal
  #statusModal
  (validate)="onConfirmModalValidated($event)"
></app-sale-order-status-select-modal>
<app-payments
  *ngIf="paymentElement && paymentDocument"
  #paymentModal
  [reportType]="'SaleOrder'"
  [showGrid]="false"
  [element]="paymentElement"
  [document]="paymentDocument"
  (paymentsChange)="grid.refresh()"
  [filterPaymentType]="['Code eq \'Advance\'']"
></app-payments>