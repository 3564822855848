import { BasicEntity } from "../../../core/models/basic-entity";

export class ThirdRole extends BasicEntity {
    public color?: string;

}
export enum ThirdRoleCodes {
    Customer = 'customer',
    Supplier = 'supplier',
    User = 'user',
}

export enum ThirdRoleIds {
    Customer = 1,
    Supplier = 2,
    User = 3,
}