import { Entity } from "../../../core/models/entity";
import { Third } from "../third.model";
import { RelationType } from "./third-relation-types/third-relation-type";

export class ThirdRelation extends Entity {
    public FromThirdId?: Third;
    public ToThirdId?: Third;
    public ThirdRelationTypeId?: RelationType = new RelationType();

    public IsPublic?: boolean;

    constructor(thirdRelation?: Partial<ThirdRelation>) {
        super();
        Object.assign(this, thirdRelation);
    }
}
