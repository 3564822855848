<syslink-toolbar [actions]="toolbarActions"></syslink-toolbar>
<div class="kanban mt-3">
  <dx-scroll-view direction="horizontal" [useNative]="true">
    <div class="d-flex">
      <div
        class="col-3 mx-3"
        *ngFor="let column of columns; let columnIndex = index"
      >
        <div
          class="kanban-column-header text-center fw-bold py-3  {{
            column.classes
          }}"
          [style.color]="column.textColor"
          [style.backgroundColor]="column.backgoundColor"
        >
          {{ column.label | translate }}
        </div>
        <div class="kanban-column-content bg-light">
          <dx-scroll-view direction="vertical" [useNative]="true">
            <dx-sortable
              group="cardsGroup"
              (onDragStart)="onDragStart($event)"
              (onAdd)="onAdd($event)"
              [data]="columnDatasources[columnIndex].items()"
              [style.minHeight]="'50px'"
            >
              <syslink-kanban-card
                *ngFor="let item of columnDatasources[columnIndex].items()"
                [element]="item"
              > 
              </syslink-kanban-card>
            </dx-sortable>
          </dx-scroll-view>
        </div>
      </div>
    </div>
  </dx-scroll-view>
</div>
