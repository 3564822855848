import { Component, Input, ViewChild } from '@angular/core';
import { TaskTaskStatus } from '../task-task-status.model';
import { BaseModalComponent } from 'projects/libraries/syslink-components/src/lib/modal/base-modal/base-modal.component';
import { FilterDescriptor } from 'devextreme/data';
import { SelectComponent } from 'projects/libraries/syslink-components/src/public-api';
import { TaskStatusesService } from '../../task-statuses/task-statuses.service';

@Component({
  selector: 'app-task-task-status-modal',
  templateUrl: './task-task-status-modal.component.html',
  styleUrls: ['./task-task-status-modal.component.scss']
})
export class TaskTaskStatusModalComponent extends BaseModalComponent<TaskTaskStatus>{
  @Input() public selectFilters: FilterDescriptor | Array<FilterDescriptor>;
  @Input() public expand: FilterDescriptor | Array<FilterDescriptor>;
  @Input() public title: string = "Select invoice status";
  @Input() public content?: string;
  @Input() public data: any;
  @ViewChild('select') select?: SelectComponent;

  constructor(public taskStatusesService: TaskStatusesService) {
    super();
  }
}
