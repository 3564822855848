import { Entity } from "../../../core/models/entity";
import { AdministrationArea } from "../administration-areas/administration-area";
import { Country } from "../countries/country";
import { PostalCode } from "../postal-codes/postal-code";

export class Locality extends Entity {

  public ShortName?: string;
  public LongName?: string;
  public AdministrationAreaId: AdministrationArea = new AdministrationArea();
  public CountryId: Country = new Country();
  public PostalCodes: Array<PostalCode> = [];

  constructor(locality?: Partial<Locality>) {
    super(locality);
    Object.assign(this, locality);
  }
}
