<syslink-grid
   #grid
   [permissionKey]="'list'"
   [gridName]="storageKey"
   [canView]="canView"
   [canSearch]="canSearch"
   [canRefresh]="canRefresh"
   [canAdd]="canAdd"
   [canSort]="canSort"
   [canFilter]="canFilter"
   [canExport]="canExport"
   [canDblClck]="canDblClck"
   [service]="customerCatalogsService"
   [expand]="expand"
   [columns]="columns"
   exportFileName="Customer-catalog-grid-basic"
   storageKey="storageKey"
   (onPrepareRow)="onNewElement($event)"
   (beforeAdd)="onBeforeInserting($event)"
   (afterAdd)="onAfterInserting($event)"
   (beforeUpdate)="onBeforeUpdate($event)"
   (beforeDelete)="onBeforeDelete($event)"
></syslink-grid>