<div class="row">
    <div class="col-12">
        <div class="row">
            <div class="col-5">

                <syslink-input-text #productNameInput name="Name" label="Name" [(model)]="element.Name"></syslink-input-text>

                <syslink-input-text
                    name="Reference"
                    label="Reference"
                    [model]="element.ManufacturerReference"
                    (modelChange)="element.ManufacturerReference = $event; element.InternalReference = $event"
                ></syslink-input-text>

                <!-- <syslink-select label="Category" [store]="productCategoriesService.store" [model]="element.ProductCategoryId" (modelChange)="onChangeCategory($event)"></syslink-select> -->
                <!-- <div class="row">
                    <div class="col-3">
                        <syslink-switch [(model)]="element.Purchasable" (modelChange)="productPurchasableChanged.emit($event); productsService.onStatusSwitch('Purchasable')" type="box" [falseText]="'Not Purchasable' | translate" [trueText]="'Purchasable' | translate"></syslink-switch>
                    </div>
                    <div class="col-3">
                        <syslink-switch [(model)]="element.Sellable" (modelChange)="productsService.onStatusSwitch('Sellable')" type="box" [falseText]="'Not Sellable' | translate" [trueText]="'Sellable' | translate"></syslink-switch>
                    </div>
                    <div class="col-3">
                        <syslink-switch [(model)]="element.Stockable" (modelChange)="productsService.onStatusSwitch('Stockable'); element.Stock = 0" type="box" [falseText]="'Not Stockable' | translate" [trueText]="'Stockable' | translate"></syslink-switch>
                    </div>
                    <div class="col-3">
                        <syslink-switch [model]="!element.Blocked" (modelChange)="element.Blocked = !$event"  type="box" [falseText]="'Blocked' | translate" [trueText]="'Active' | translate"></syslink-switch>
                    </div>
                </div> -->
            </div>

            <div class="col-5 offset-2">
                <div *ngIf="element.Purchasable && element.DefaultSupplierCatalogId" class="row d-flex align-items-center">
                    <div class="col-4">
                        <label>{{'Purchase price excl. VAT' | translate}}</label>
                    </div>
                    <div class="col-6 product-header-price-box">
                        <div class="border py-2 h6 text-gray text-center m-0">{{element.DefaultSupplierCatalogId.ExTaxNetPrice | syslinkCurrency:'Products.General':'Precision.Product.PurchasePriceExTax':''}} {{element.DefaultSupplierCatalogId.CurrencyId?.Symbol}}</div>
                        <div class="border py-2 h6 text-gray text-center m-0">{{element.DefaultSupplierCatalogId.UnitId?.Name}}</div>
                    </div>
                    <div *ngIf="element.DefaultSupplierCatalogId.IsFavorite" class="col-1"><span class="d-flex justify-content-center h5 m-0">
                            <i class="fa-solid fa-dollar-sign text-center" [class.text-success]="element.PurchasePriceStatus === 'best'" [class.text-warning]="element.PurchasePriceStatus === 'middle'" [class.text-danger]="element.PurchasePriceStatus === 'worst'"></i>
                        </span></div>
                    <div class="col-1"><span class="d-flex justify-content-center h5 m-0"><i class="fa-{{element.DefaultSupplierCatalogId.IsFavorite ? 'solid' : 'regular'}} fa-star text-center text-primary"></i></span></div>
                </div>

                <div *ngIf="element.Sellable && element.Id" class="mt-2 row d-flex align-items-center">
                    <div class="col-4">
                        <label>{{'Selling price excl. VAT' | translate}}</label>
                    </div>
                    <div class="col-6 product-header-price-box" *ngIf="element.SalePrice !== undefined">
                        <div class="py-2 h6 text-white text-center bg-primary m-0">{{element.SalePrice | syslinkCurrency:'Products.General':'Precision.Product.SalePriceExTax':''}} {{element.CurrencyId.Symbol}}</div>
                        <div class="py-2 h6 text-white text-center bg-primary m-0">{{element.DefaultUnitId.Name}}</div>
                    </div>
                </div> 

                <div *ngIf="element.Stockable" class="mt-2 row d-flex align-items-center">
                    <div class="col-4">
                        <label>{{'Stock' | translate}}</label>
                    </div>
                    <div class="col-6 product-header-price-box">
                        <div *ngIf="!authService.hasPermission('root-stock-moves')" class="py-2 h6 text-white text-center bg-black m-0">{{element.Stock}}</div>
                        <div *ngIf="authService.hasPermission('root-stock-moves')" class="py-2 h6 text-white text-center bg-black m-0">{{element.StockMove}}</div>
                        <div class="py-2 h6 text-white text-center bg-black m-0">Principal</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<syslink-confirm-modal
    #changeAccountConfirm
    title="Change category"
    (onValidate)="onChangeAccount()"
    validateText="Validate"
    cancelText="Cancel"
    width="350px"
    content="Do you want to change sale and purchase accounts?"
></syslink-confirm-modal>
