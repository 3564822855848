import { Injectable } from '@angular/core';
import { LoadOptions } from 'devextreme/data';
import { ODataService } from '../../../core/services/oData.service';
import { Module } from './module.model';

@Injectable({
  providedIn: 'root'
})
export class ModulesService extends ODataService<Module> {
  public url = 'module';
  public override defaultOptions: LoadOptions = {
    expand:['SubModules', 'SubModules.Configurations']
  };
  

  static MODULE_ICONS: any = {
    'Base': 'fa-solid fa-house',
    'Thirds': 'fa-solid fa-users',
    'Products': 'fa-solid fa-store',
    'Sales': 'fa-solid fa-euro-sign',
    'Stocks': 'fa-solid fa-warehouse',
    'Default': 'fa-solid fa-user',
  }

  /**
   * Return the icon class of this module
   * @param {string} moduleCode
   * @returns {string}
   */
  public getIcon(moduleCode: string = 'Default'): string {
    return ModulesService.MODULE_ICONS[moduleCode || 'Default'] || ModulesService.MODULE_ICONS['Default'];
  }
}
