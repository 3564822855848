import { Injectable, Injector } from '@angular/core';
import { Config } from '../syslink-components.module';

@Injectable({
  providedIn: 'root'
})
export class AppInjectorService {
  private static _injector: Injector;

  static set injector(injector: Injector) {
    this._injector = injector;
  }

  static get injector(): Injector {
    return this._injector;
  }

  private static _config: Config;

  static set config(config: Config) {
    this._config = config;
  }

  static get config(): Config {
    return this._config;
  }
}
