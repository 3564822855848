<dx-chart
    [(dataSource)]="data"
    [title]="title"
    [legend]="{visible: showLegend, title:legendHeader, position:'bottom',  horizontalAlignment: 'center'}"
    [series]="series"
    [size]="{width: width}"
    [tooltip]=" {
        enabled: true, 
        customizeTooltip: customizeTooltip 
      }"
></dx-chart>
