import { BasicEntity } from "../../../core/models/basic-entity";

export class WorkTimeState extends BasicEntity {
    public Color: string="";
}

export enum WorkTimeStateCode {
    Default = "Default",
    NotRespected = "NotRespected",
    Respected = "Respected"
}