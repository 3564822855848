<syslink-title
    *ngIf="element.Code!=undefined"
    [text]="element.Code"
></syslink-title>
add permission
<syslink-toolbar [actions]="toolbarActions"></syslink-toolbar>
<app-user-permission-form [(element)]="element"></app-user-permission-form>
<p></p>
<syslink-tabs #userPermissions>
    <!-- <syslink-tab [label]="'Group'">
       <app-user-permission-user-group
       [userPermission]="element"
       (userGroupChange)="onSavebtnClicked()"
       ></app-user-permission-user-group>
    </syslink-tab>
    <syslink-tab [label]="'User'">
        <app-user-permission-user-user
        [userPermission]="element"
        (userChange)="onSavebtnClicked()"
        ></app-user-permission-user-user>
     </syslink-tab>
     <syslink-tab [label]="'Role'">
        <app-user-permission-user-role
        [userPermission]="element"
        (userRoleChange)="onSavebtnClicked()"
        ></app-user-permission-user-role>
     </syslink-tab> -->
</syslink-tabs>
<syslink-confirm-modal
    #deleteConfirm
    title="Delete"
    content="DeleteConfirmMessage"
    (onValidate)="permissionDelete()"
    [validateText]="'Confirm'"
    [cancelText]="'Cancel'"
></syslink-confirm-modal>