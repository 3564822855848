import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalComponent, NotificationsService, PageComponent, SyslinkToolbarActionButton } from 'projects/libraries/syslink-components/src/public-api';
import { Product } from './product.model';
import { ProductGridComponent } from './product-grid/product-grid.component';
import { ProductsService } from './product.service';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent extends PageComponent {

  public toolbarItems: any =  [new SyslinkToolbarActionButton({
    code: 'addStockArrival',
    widget: 'dxButton',
    elementAttr: {
      id: 'addStockArrival',
    },
    icon: 'download',
      hint: this.translateService.instant('Goods arrival'),
      visible: true,
      onClick: this.onAddGoodsArrivalBtnClick.bind(this),   
    location: 'before'
  })];

  @Input() expand: string | string[] = ["ProductCategoryId", "DefaultUnitId"];
  @Input() canRefresh: boolean = false;

  @Output() dblClicked: EventEmitter<Product> = new EventEmitter<Product>();

  @Output() onAddNewProductBtn: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('productGrid') public productGrid?: ProductGridComponent;

  constructor(
    public productsService: ProductsService,
    protected override activatedRoute: ActivatedRoute
  ) {
    super();
  }

  public addNewProduct() {
    if (this.onAddNewProductBtn.observed) return this.onAddNewProductBtn.emit();
    this.router.navigate(['new'], { relativeTo: this.activatedRoute });
  }

  public onDoubleClicked(product: Product) {
    if (this.dblClicked.observed) {
      this.dblClicked.emit(product);
    }
    else {
      this.router.navigate([product.Id], { relativeTo: this.activatedRoute });
    }
  }

  public foo() {
    this.productGrid?.gridComponent?.grid?.instance.refresh();
  }

  // Goods arrival
  public goodsArrivalModalData: {
    productId?: number,
    product?: Product,
    stockQuantityToAdd: 0
  } = {
      product: undefined,
      stockQuantityToAdd: 0
    };
  @ViewChild('GoodsArrivalModal') private GoodsArrivalModal: ModalComponent = new ModalComponent();

  private onAddGoodsArrivalBtnClick() {
    this.GoodsArrivalModal.open();
  }

  public async onGoodsArrivalValidated() {
    if (!this.goodsArrivalModalData.product?.Id) {
      return NotificationsService.sendErrorMessage('GOODS_ARRIVALS_NO_PRODUCT_SELECTED');
    }

    if (!this.goodsArrivalModalData.product?.Stockable) {
      return NotificationsService.sendErrorMessage('GOODS_ARRIVALS_PRODUCT_NOT_STOCKABLE');
    }

    if (this.goodsArrivalModalData.stockQuantityToAdd <= 0) {
      return NotificationsService.sendErrorMessage('GOODS_ARRIVALS_STOCK_QUANTITY_TO_ADD_CANNOT_BE_NULL');
    }

    this.goodsArrivalModalData.product.Stock += this.goodsArrivalModalData.stockQuantityToAdd

    await this.productsService.update(this.goodsArrivalModalData.product.Id, this.productsService.format(this.goodsArrivalModalData.product));

    this.productGrid?.refresh();
    NotificationsService.sendSuccess('GOODS_ARRIVALS_SUCCESS', [this.goodsArrivalModalData.product.InternalReference || '', String(this.goodsArrivalModalData.product.Stock)]);

    this.goodsArrivalModalData = {
      product: undefined,
      stockQuantityToAdd: 0
    };
    this.productGrid?.gridComponent?.grid?.instance.refresh();
  }

  public async onGoodsArrivalProductSelected(productId?: number) {
    if (!productId) {
      this.goodsArrivalModalData.product = undefined;
      return;
    }

    this.goodsArrivalModalData.product = await this.productsService.findByID(productId);
  }
}

