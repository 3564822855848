<syslink-grid
    #grid
    [canView]="canView"
    [canSearch]="canSearch"
    [canRefresh]="canRefresh"
    [canAdd]="canAdd"
    [canSort]="canSort"
    [canUpdate]="canUpdate"
    [canDelete]="canDelete"
    [canFilter]="canFilter"
    [canExport]="canExport"
    [canDblClck]="canDblClck"
    [store]="assignementPriceCoefService.store"
    [archivedStore]="assignementPriceCoefService.archivedStore"
    [restoreService]="assignementPriceCoefService"
    [columns]="columns"
    [expand]="expand"
    [storageKey]="storageKey"
    (beforeUpdate)="onBeforeUpdate($event)"
    (beforeAdd)="onBeforeAdd($event)"
></syslink-grid>

