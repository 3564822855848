import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BaseGridComponent } from 'projects/libraries/syslink-components/src/lib/grid/base-grid/base-grid.component';
import { SyslinkColumn } from 'projects/libraries/syslink-components/src/lib/helpers/SyslinkColumn';

@Component({
  selector: 'app-basic-grid',
  templateUrl: './basic-grid.component.html',
  styleUrls: ['./basic-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BasicGridComponent extends BaseGridComponent<any> {
  @Input() service: any;
  @Input() languages: any[] = [];
  @Input() storageKey: string = 'basic';

  public columns: SyslinkColumn[] = [];
  public gridName = this.storageKey;
}


