<div class="{{class}}" [hidden]="hidden">
    <dx-color-box
        id="colorPicker-{{name}}"
        [value]="model"
        (onValueChanged)="modelChange.emit($event.value)"
        [stylingMode]="mode"
        [label]="getLabel()"
        [labelMode]="labelMode"
        [readOnly]="disabled"
    ></dx-color-box>
</div>