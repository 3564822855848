import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Task } from '../task.model';

@Component({
  selector: 'app-task-other',
  templateUrl: './task-other.component.html',
  styleUrls: ['./task-other.component.scss']
})
export class TaskOtherComponent {
  @Input() public element: Task = new Task();
  @Output() public elementChange: EventEmitter<Task> = new EventEmitter<Task>();
}
