import { Component, Input } from '@angular/core';
import { ChartSerie } from '../helpers/ChartSeries';

@Component({
  selector: 'syslink-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss']
})
export class ChartComponent {
  @Input() public title: string = "Chart";
  @Input() public series: ChartSerie[] = [];
  @Input() public showLegend: boolean = true;
  @Input() public legendHeader: string = "";
  @Input() public width: number = 500;
  @Input() public data?: any;
  @Input() public size: any = {width: 500};

  public customizeTooltip(arg: any) {
    
    return {
      text: `${arg.valueText}`
    };
  }

}


