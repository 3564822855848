import { Component } from '@angular/core';
import { PageComponent } from 'projects/libraries/syslink-components/src/public-api';
import { UserGroupsService } from './user-groups.service';

@Component({
  selector: 'app-user-groups',
  templateUrl: './user-groups.component.html',
  styleUrls: ['./user-groups.component.scss']
})
export class UserGroupsComponent extends PageComponent {
  constructor(
    public userGroupsService: UserGroupsService
  ) {
    super();
  }
}
