import { BasicEntity } from "../../../core/models/basic-entity";

export class PaymentMethod extends BasicEntity {
    public ApplyRound?: boolean;
    public ApplyReturn?: boolean;
    public IsVisible?: boolean;

    constructor(paymentMethod?: Partial<PaymentMethod>) {
        super();
        Object.assign(this, paymentMethod);
    }
}