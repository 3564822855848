import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ItemClickEvent } from 'devextreme/ui/tree_view';

export class DrawerItem {
  public id!: string;
  public text!: string;
  public icon?: string;
  public path?: string;
  public expanded?: boolean;
  public active?: boolean;
  public items?: DrawerItem[];

  constructor(user?: Partial<DrawerItem>) {
    Object.assign(this, user);
  }
}

// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! //
// ! WARNING : A Drawer can't contains another drawer ! //
// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! //
@Component({
  selector: 'syslink-drawer',
  templateUrl: './drawer.component.html',
  styleUrls: []
})
export class DrawerComponent implements OnInit {
  @Input() public opened: boolean = true;

  private _drawerItems: DrawerItem[] = []
  @Input() public set drawerItems(value: DrawerItem[]) {
    this._drawerItems = value;
    this.expandItems();
  }
  public get drawerItems(): DrawerItem[] {
    return this._drawerItems;
  }

  constructor(
    public router: Router,
    public route: ActivatedRoute
  ) {
    this.router.events.subscribe(() => {
      this.resetActive();
      this.expandItems();
    });
  }

  ngOnInit(): void {
    this.expandItems();
  }

  public expandItems(): void {
    this.getItemsToExpand(this.router.url).forEach((item: DrawerItem) => {
      item.expanded = true;
      item.active = true;
    });
  }

  public getItemsToExpand(path: string): DrawerItem[] {
    const result = this.findCurrentItem(path);
    return result.itemFound ? result.itemArray : this.findCurrentItem(path.split('/').slice(0, path.split('/').length - 1).join('/')).itemArray
  }

  public findCurrentItem(path: string, items: DrawerItem[] = this.drawerItems): { itemFound: boolean, itemArray: DrawerItem[] } {
    let itemArray: DrawerItem[] = [];
    let itemFound: boolean = false;

    items.forEach((item: DrawerItem) => {
      if (itemFound) return;
      if (item.items?.length) {
        itemArray.push(item);

        const result = this.findCurrentItem(path, item.items);
        itemFound = result.itemFound;

        if (itemFound) {
          itemArray.push(...result.itemArray);
        } else {
          itemArray.pop();
        }
      } else if (item.path === path) {
        itemFound = true;
        itemArray.push(item);
      };
    });

    return { itemArray, itemFound };
  }

  public resetActive(items: DrawerItem[] = this.drawerItems): void {
    items.forEach((item: DrawerItem) => {
      item.active = false;
      if (item.items) this.resetActive(item.items);
    });
  }

  public onItemClick(event: ItemClickEvent): void {
    const item: DrawerItem = <DrawerItem>event.itemData;

    if (!item.items) return;

    if (item.expanded) {
      item.active = false;
      event.component.collapseItem(item.id);
    } else {
      item.active = true;
      event.component.expandItem(item.id);
    }
  }
}
