import { Currency } from "../../base/currencies/currency.model";
import { TaxRate } from "../../base/tax-rates/tax-rate";
import { Unit } from "../../base/units/unit.model";
import { Entity } from "../../core/models/entity";
import { Supplier } from "../../thirds/suppliers/supplier.model";
import { Brand } from "../brands/brand.model";
import { Product } from "../products/product.model";
import { SupplierCatalogDiscountOperations } from "./supplier-catalog-discount-operations/supplier-catalog-discount-operations";

export class SupplierCatalog extends Entity {
    public SupplierReference?: string;

    public ExTaxGrossPrice?: number;
    public ExTaxNetPrice: number = 0;
    public SupplierStock?: number;
    public MinimumQty?: number;

    public DiscountId?: SupplierCatalogDiscountOperations;
    public IsFavorite?: boolean;

    public SupplierId?: Supplier;
    public UnitId?: Unit;
    public TaxRateId?: TaxRate;
    public BrandId?: Brand;
    public Make?: string;
    public CurrencyId?: Currency;
    public ProductId?: Product;

    constructor(entity?: Partial<SupplierCatalog>) {
        super(entity);
        Object.assign(this, entity);
    }
}
