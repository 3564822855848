import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { SyslinkToolbarActionButton, SyslinkToolbarFileButton } from '../../../../../../libraries/syslink-components/src/lib/toolbar/toolbar.component';
import { ConfirmModalComponent, NotificationsService, PageComponent, TabsComponent } from '../../../../../../libraries/syslink-components/src/public-api';
import { CustomersService } from '../../customers/customers/customers.service';
import { Customer } from '../../customers/customers/customer.model';
import { Third } from '../third.model';
import { ThirdsService } from '../thirds.service';
import { SuppliersService } from '../../suppliers/suppliers.service';
import { Supplier } from '../../suppliers/supplier.model';
import { ThirdRole, ThirdRoleIds } from '../third-roles/third-role.model';
import { ThirdRelation } from '../third-relations/third-relation.model';
import { ThirdRelationsService } from '../third-relations/third-relations.service';
import { ThirdCommunicationMethodsService } from '../third-communications/third-communication-methods/third-communication-method.service';
import { ThirdAddressService } from '../third-addresses/third-addresses/third-addresses.service';
import { ThirdAddress } from '../third-addresses/third-addresses/third-address.model';
import { ViesService } from '../../../connectors/vies/vies.service';
import { LocalityService } from '../../../base/addresses/localities/locality.service';
import { PostalCodeService } from '../../../base/addresses/postal-codes/postal-code.service';
import { AddressTypeService } from '../../../base/addresses/address-types/address-type.service';
import { UsersService } from '../../../core/auth/users/users.service';
import { AddressType } from '../../../base/addresses/address-types/address-type';
import { ThirdCommunicationMethod } from '../third-communications/third-communication-methods/third-communication-method.model';
import { Entity } from '../../../core/models/entity';
import { ODataService } from '../../../core/services/oData.service';
import { CountriesService } from '../../../base/addresses/countries/countries.service';
import { AddressesComponent } from '../third-addresses/third-addresses/third-addresses.component';
import { DocumentDatasService } from '../../../base/documents/document-datas/document-datas.service';
import { LoadOptions } from 'devextreme/data';
import { ThirdModalComponent } from 'projects/libraries/syslink-components/src/lib/modal/third-modal/third-modal.component';
import { DocumentData } from '../../../base/documents/document-datas/document-data.model';
import { jsonToOdataFormat } from 'projects/libraries/syslink-components/src/lib/helpers/tools';
import { ThirdContactInformationsService } from '../third-contacts/third-contact-informations/third-contact-informations.service';
import { ThirdContactInformation } from '../third-contacts/third-contact-informations/third-contact-information.model';
import { CreditsafeModalComponent } from '../../../connectors/creditsafe/creditsafe-modal/creditsafe-modal.component';
import { CreditsafeData } from '../../../connectors/creditsafe/creditsafe.model';

@Component({
  templateUrl: './third-details.component.html',
  styleUrls: ['./third-details.component.scss']
})
export class ThirdDetailsComponent extends PageComponent implements OnInit, AfterViewInit {
  ThirdRoleIds = ThirdRoleIds;

  public tabIndex?: number;

  // Third
  public element: Third = new Third();
  public formattedTitle: string = '';

  // Addresses
  public defaultAddressType?: AddressType;

  public isAutocompleteLoading: boolean = false;

  @ViewChild('deleteConfirm') deleteConfirm?: ConfirmModalComponent;
  @ViewChild('updateThirdAutocomplete') public updateThirdAutocomplete?: ConfirmModalComponent = new ConfirmModalComponent();
  @ViewChild('infoTabs') infoTabs: TabsComponent = new TabsComponent();
  @ViewChild('addresses') addresses?: AddressesComponent;
  @ViewChild('thirdModaltransfert') thirdModaltransfert?: ThirdModalComponent;
  @ViewChild('creditsafeModal') creditsafeModal?: CreditsafeModalComponent;

  public thirdModaltransfertFilter: string | string[] = ["Roles/any(r:r/Code eq 'customer')"];
  public thirdModaltransfertFields: any[] = [];

  public currentMainAddress: ThirdAddress = new ThirdAddress();
  public newMainAddress: ThirdAddress | undefined;

  public currentOtherAddresses: ThirdAddress[] = [];
  public newOtherAddresses: ThirdAddress[] = [];

  // Modification modal Autocomplete : choices

  public currentOtherAddressChoices: boolean[] = [];
  public newOtherAddressChoices: boolean[] = [];

  public currentCommunicationChoices: boolean[] = [];
  public newCommunicationChoices: boolean[] = [];

  public currentRelationChoices: boolean[] = [];
  public newRelationChoices: boolean[] = [];

  public detailChoice: boolean = false;
  public mainAddressChoice: boolean = false;

  public onModificationDetailCheck($event: boolean) {
    this.detailChoice = $event;
  }

  public onModificationMainAddressCheck($event: boolean) {
    this.mainAddressChoice = $event;
  }

  // VIES
  // ----
  public thirdAutocompleted?: Third;
  public usedCurrentDenomination?: boolean;
  public usedCurrentLegalForm?: boolean;
  public usedCurrentCountry?: boolean;
  public viesContactInformations: ThirdContactInformation[] = [];

  // -----------------------

  // CreditSafe
  // ----
  public creditSafeElement?: CreditsafeData;
  // -----------------------

  constructor(
    private customersService: CustomersService,
    private suppliersService: SuppliersService,
    public postalCodeService: PostalCodeService,
    public addressTypeService: AddressTypeService,
    public localityService: LocalityService,
    public thirdAddressService: ThirdAddressService,
    private ngxUiLoaderService: NgxUiLoaderService,
    private thirdRelationsService: ThirdRelationsService,
    private thirdCommunicationMethodsService: ThirdCommunicationMethodsService,
    override activatedRoute: ActivatedRoute,
    public thirdsService: ThirdsService,
    private companyDataService: ViesService,
    public addressTypesService: AddressTypeService,
    public countriesService: CountriesService,
    private documentDatasService: DocumentDatasService,
    private thirdContactInformationsService: ThirdContactInformationsService
  ) {
    super();
  }
  override async ngAfterViewInit(): Promise<void> {
    super.ngAfterViewInit();
    await this.refreshElement();


    // // Hack for scheduler
    // // TODO: Issue come from ng-content
    // setTimeout(() => { this.scheduler?.scheduler?.refresh(); }, 2000);
    // setTimeout(() => { this.scheduler?.scheduler?.refresh(); }, 4000);
    // setTimeout(() => { this.scheduler?.scheduler?.refresh(); }, 8000);
  }

  // Lifecycle Events
  // ----------------
  override ngOnInit(): void {
    super.ngOnInit();

    this.activatedRoute.data.subscribe(async ({ element }) => {
      this.ngxUiLoaderService.start();
      this.element = element;
      //   await this.refreshElement();
      this.ngxUiLoaderService.stop();
    });
  }
  //------------------------------------------------------------------


  private async refreshElement() {
    this.updateBreadCrumb(this.getFormattedTitle());
    this.formattedTitle = this.getFormattedTitle();
    // this.defaultAddressType = await this.addressTypesService.findByID(this.element.ThirdTypeId?.Id == ThirdTypeIds.Person ? ThirdAddressTypeIds.Home : ThirdAddressTypeIds.HeadOffice);

    if (this.hasSupplierRole()) {
      this.infoTabs?.selectTab('Supplier');
    }

    if (this.hasCustomerRole()) {
      this.infoTabs?.selectTab('Customer');
    }

    if (this.hasUserRole()) {
      this.infoTabs?.selectTab('User');
    }

    if (this.element.IsProfessional == false) {
      this.infoTabs?.selectTab('Third.Private');
    }

    if (this.element.IsProfessional == true) {
      this.infoTabs?.selectTab('Professional');
    }
    this.initToolbarActions();
    this.reloadContactInformation();
    // this.reloadAppointment();
  }

  public initToolbarActions() {
    this.toolbarActions = [];
    this.toolbarActions.push(new SyslinkToolbarActionButton({ icon: 'save', text: 'Save', onClick: () => this.save(), location: 'before', inMenu: 'never', hotkey: 'control.s', visible: this.element.Id ? this.authService.hasPermission(this.basePermissionKey + '.update') : this.authService.hasPermission(this.basePermissionKey + '.add') }));
    if (this.element.Id) {
      this.toolbarActions.push(new SyslinkToolbarActionButton({ code: 'add', icon: 'plus', text: 'New', onClick: () => this.goToUrl('../new'), hotkey: 'control.n', visible: this.authService.hasPermission(this.basePermissionKey + '.add') }));
      // // { icon: 'clone', text: 'Duplicate', onClick: () => this.onThirdDuplicateButtonClicked(), hotkey: 'control.d' },
      if (this.element.VatNumber) {
        this.toolbarActions.push(new SyslinkToolbarActionButton({ code: 'CreditSafe', icon: 'chart', text: 'Show creditSafe', onClick: () => this.showCreditSafeModal(), visible: this.authService.hasPermission(this.basePermissionKey + '.creditsafe') }));
      }
      this.toolbarActions.push(new SyslinkToolbarFileButton({ entityType: 'Third', entityId: this.element.Id }));
      this.toolbarActions.push(new SyslinkToolbarActionButton({ code: 'delete', icon: 'trash', text: 'Delete', onClick: () => this.deleteConfirm?.modal.open(), visible: this.authService.hasPermission(this.basePermissionKey + '.delete') }));
      // this.toolbarActions.push(new ToolbarAction({ code: 'mail', icon: 'envelope', text: 'Mail', onClick: () => this.onSendMailButtonClicked() }));
    }
  }

  // Save
  // ----
  public async save() {
    if (!this.validateElementInformations(this.element)) {
      return;
    }
    this.ngxUiLoaderService.start();
    const isCreate = this.element.Id ? false : true;
    var contactInformations: ThirdContactInformation[] = [];
    if (isCreate == true && this.element.ContactInformations) {
      contactInformations = this.element.ContactInformations;
    }
    await this.createOrUpdateThird();

    if (!isCreate && this.element.Id) {
      NotificationsService.sendSuccess("Record updated");
      this.element = await this.thirdsService.find(this.element.Id);
      await this.refreshElement();
    }
    else {
      contactInformations.forEach(async element => {
        element.ThirdId = this.element
        if (!element.Id) {
          if (element.AddressId != undefined && element.AddressId != null) {
            element.AddressId = await this.thirdAddressService.insert(this.thirdAddressService.format(element.AddressId));
          }
          await this.thirdContactInformationsService.insert(this.thirdContactInformationsService.format(element))
        }
        if (element.Id) {
          await this.thirdContactInformationsService.update(element.Id, this.thirdContactInformationsService.format(element))
        }
      });
      NotificationsService.sendSuccess("Record created");
      this.goToUrl('../' + this.element?.Id);
    }
    this.ngxUiLoaderService.stop();
  }

  // Third
  // -----
  private async createOrUpdateThird() {
    // await this.createOrUpdateThirdTypes();

    const createThird: Third | undefined = await this.createOrUpdate<Third>(this.thirdsService, this.element);

    if (!this.element.Id) {
      this.element.Id = createThird?.Id;

      for (const address of this.element.Addresses) {
        address.ThirdId = this.element;
        delete address.Id;
        await this.createOrUpdate<ThirdAddress>(this.thirdAddressService, address);
      }

      for (const relation of this.element.ThirdRelations) {
        relation.FromThirdId = this.element;
        delete relation.Id;
        await this.createOrUpdate<ThirdRelation>(this.thirdRelationsService, relation);
      }

      for (const communication of this.element.CommunicationMethods) {
        communication.ThirdId = this.element;
        delete communication.Id;
        await this.createOrUpdate<ThirdCommunicationMethod>(this.thirdCommunicationMethodsService, communication);
      }
    }

    // Save addresses from VAT
    // for (const address of this.element.Addresses) {
    //   if (!address.Id && createThird) {
    //     address.ThirdId = createThird;
    //     await this.createOrUpdate<ThirdAddress>(this.thirdAddressService, address, true);
    //   }
    // }

    await this.createorUpdateThirdRoles();
  }

  // -------------------------------------------------------

  validateElementInformations(element: Third) {
    let result = true;

    // Checking person fields
    // ----------------------
    if (this.element.IsProfessional == false) {
      if (this.element.Firstname == '') {
        NotificationsService.sendErrorMessage(this.translateService.instant("Field is required: %s"), [this.translateService.instant("Firstname")])
        result = false;
      }

      if (this.element.Lastname == '') {
        NotificationsService.sendErrorMessage(this.translateService.instant("Field is required: %s"), [this.translateService.instant("Lastname")])
        result = false;
      }
    }

    // Checking company fields
    // -----------------------
    if (this.element.IsProfessional == true) {

      if (this.element.Name == '') {
        NotificationsService.sendErrorMessage(this.translateService.instant("Field is required: %s"), [this.translateService.instant("Name")])
        result = false;
      }
    }
    return result;
  }

  // Delete
  // ------
  public async delete(third: Third) {
    if (!this.element.Id) return;

    // Check if a documentData exist
    // -----------------------------
    var options: LoadOptions = {
      filter: ['ThirdId.Id eq ' + this.element.Id + ' or ContactId.Id eq ' + this.element.Id],
      expand: ['SaleDocumentHeaderId']
    };
    var documentDataList = await this.documentDatasService.load(options);
    if (documentDataList.length > 0) {
      this.thirdModaltransfertFields = [];
      documentDataList.forEach((documentdate: DocumentData) => {
        this.thirdModaltransfertFields.push(
          {
            Key: documentdate.SaleDocumentHeaderId.Id,
            Type: "string",
            Name: this.getSaleDocumentType(documentdate.SaleDocumentHeaderId),
            Disabled: true,
            ColumnWidth: 12,
            Value: documentdate.SaleDocumentHeaderId.No
          }
        );
      });
      this.deleteConfirm?.close();
      this.thirdModaltransfertFilter = ["Roles/any(r:r/Code eq 'customer') and Id ne " + this.element.Id];
      this.thirdModaltransfert?.selectThird?.forceReload();
      this.thirdModaltransfert?.selectThird?.selectComponent?.instance.getDataSource().filter(this.thirdModaltransfertFilter);
      this.thirdModaltransfert?.selectThird?.selectComponent?.instance.getDataSource().reload();
      this.thirdModaltransfert?.open();
      return;
    }

    // Remove Customer/Supplier
    // ------------------------
    if (this.element.Roles.find(e => e.Id == ThirdRoleIds.Customer) && this.element.CustomerId && this.element.CustomerId.Id) {
      await this.customersService.remove(this.element.CustomerId.Id);
    }
    if (this.element.SupplierId && this.element.SupplierId.Id) {
      await this.suppliersService.remove(this.element.SupplierId.Id);
    }
    if (this.element.UserId && this.element.UserId.Oid) {
      await this.usersService.remove(this.element.UserId.Oid);
    }

    await this.thirdsService.remove(this.element.Id);
    this.goToUrl('../');

    NotificationsService.sendSuccess("Record deleted");
  }

  public async deleteThirdRole(role: ThirdRole | undefined) {
    if (!this.element.Id || !role || !role.Id) return;
    await this.thirdsService.removeThirdRole(this.element.Id, role.Id)
  }
  //------------------------------------------------------------------

  // Transfer document data third
  // ----------------------------
  public getSaleDocumentType(document: any): string {
    if (document["@odata.type"].includes("SaleQuoteHeader")) {
      return "Quote";
    }
    if (document["@odata.type"].includes("SaleInvoiceHeader")) {
      return "Invoice";
    }
    if (document["@odata.type"].includes("SaleCreditNoteHeader")) {
      return "Credit note";
    }
    if (document["@odata.type"].includes("SaleContractHeader")) {
      return "Contract";
    }
    return "Other";
  }

  public async thirdModaltransfertValidate(e: any) {
    if (!e.third.Id || !this.element.Id) return;
    await this.documentDatasService.updateThird(this.element.Id, e.third.Id);
    await this.delete(this.element);
  }
  //------------------------------------------------------------------

  // Types
  // -----
  public async onIsProfessionalChanged(e: any) {
    await this.refreshElement();
  }

  // Roles
  // -----
  public async onThirdRoleChanged(e: any) {
    if (this.element.Roles.find(e => e.Id == ThirdRoleIds.Customer) && !this.element.CustomerId) {
      this.element.CustomerId = await this.customersService.getInstance({ ThirdId: this.element });
    }
    if (this.element.Roles.find(e => e.Id == ThirdRoleIds.Supplier) && !this.element.SupplierId) {
      this.element.SupplierId = await this.suppliersService.getInstance({ ThirdId: this.element });
    }
  }

  public async createorUpdateThirdRoles() {
    if (!this.element.Roles || !this.element.Id) return;
    var third = await this.thirdsService.findByID(this.element.Id, { expand: ['Roles'] });
    if (third.Roles.find(e => e.Id == ThirdRoleIds.Customer) && !this.element.Roles.find(e => e.Id == ThirdRoleIds.Customer)) {
      this.deleteThirdRole(third.Roles.find(e => e.Id == ThirdRoleIds.Customer));
    }
    if (third.Roles.find(e => e.Id == ThirdRoleIds.Supplier) && !this.element.Roles.find(e => e.Id == ThirdRoleIds.Supplier)) {
      this.deleteThirdRole(third.Roles.find(e => e.Id == ThirdRoleIds.Supplier));
    }
    if (third.Roles.find(e => e.Id == ThirdRoleIds.User) && !this.element.Roles.find(e => e.Id == ThirdRoleIds.User)) {
      this.deleteThirdRole(third.Roles.find(e => e.Id == ThirdRoleIds.User));
    }

    // Creating Customer
    // ---------------
    if (this.element.Roles.find(e => e.Id == ThirdRoleIds.Customer) && this.element.CustomerId) {
      this.element.CustomerId.ThirdId = this.element;
      this.element.CustomerId = await this.createOrUpdate<Customer>(this.customersService, this.element.CustomerId, true);
    }

    // Creating Supplier
    // ---------------
    if (this.element.Roles.find(e => e.Id == ThirdRoleIds.Supplier) && this.element.SupplierId) {
      this.element.SupplierId.ThirdId = this.element;
      this.element.SupplierId = await this.createOrUpdate<Supplier>(this.suppliersService, this.element.SupplierId, true);
    }
    await this.createOrUpdate<Third>(this.thirdsService, this.element);
  }
  //------------------------------------------------------------------

  // Contact informations
  // --------------------
  public async reloadContactInformation() {
    if (!this.element.Id) return;
    this.element.ContactInformations = [];
    this.element.ContactInformations.push(...await this.thirdContactInformationsService.load({
      ...this.thirdContactInformationsService.defaultOptions,
      filter: ['ThirdId.Id eq ' + this.element.Id]
    }));
  }
  //------------------------------------------------------------------

  // Relations
  // ---------
  public async onThirdRelationDeleted(element: ThirdRelation) {
    if (!element.Id) return;
    await this.thirdRelationsService.remove(element.Id);
    NotificationsService.sendSuccess('Record deleted');
  }

  public async onThirdRelationChanged(element: ThirdRelation, isCreate: boolean = false) {
    element.FromThirdId = this.element;
    element = <ThirdRelation>await this.createOrUpdate<ThirdRelation>(this.thirdRelationsService, element, true);
    NotificationsService.sendSuccess(isCreate ? 'Record created' : 'Record updated');
  }
  //------------------------------------------------------------------

  public getFormattedTitle(): string {
    let formattedTitle = '';
    formattedTitle += this.element.No ? this.element.No + ' - ' : '';

    let typePart = 'third';
    typePart = this.hasCustomerRole() && !this.hasSupplierRole() ? 'customer' : typePart;
    typePart = !this.hasCustomerRole() && this.hasSupplierRole() ? 'supplier' : typePart;

    formattedTitle += this.element.Id ? this.element.Fullname : this.translateService.instant('New ' + typePart);

    return formattedTitle;
  }

  public hasUserRole(): boolean {
    return this.element.Roles.find((e: ThirdRole) => e.Id == ThirdRoleIds.User) != undefined;
  }

  public hasCustomerRole(): boolean {
    return this.element.Roles.find((e: ThirdRole) => e.Id == ThirdRoleIds.Customer) != undefined;
  }

  public hasSupplierRole(): boolean {
    return this.element.Roles.find((e: ThirdRole) => e.Id == ThirdRoleIds.Supplier) != undefined;
  }

  //------------------------------------------------------------------

  // Autocompletion with VAT
  public getThirdDataSummaryFromVat(): void {
    let vat: string | undefined = this.element.VatNumber;
    if (this.element.Subjugated && vat?.length === 12) {
      this.isAutocompleteLoading = true;
      this.companyDataService.getSummaryDataFromThird(vat, this.element.IsProfessional).then((response: Third) => {
        response = jsonToOdataFormat(response);
        this.thirdAutocompleted = response;

        this.updateThirdOrOpenUpdateModal(response);

        this.isAutocompleteLoading = false;
      }).catch((e: any) => {
        this.isAutocompleteLoading = false;
        NotificationsService.sendErrorMessage(e.error)
      });
    }
  }

  public async getThirdDataCompleteFromVat(): Promise<void> {
    if (this.element.Subjugated && this.element.VatNumber?.length === 12) {
      this.isAutocompleteLoading = true;
      this.companyDataService.getCompleteDataFromThird(this.element.Id, this.element.VatNumber).then((newThird: Third) => {
        this.thirdAutocompleted = newThird;

        // this.updateThirdAutocomplete?.open();

        this.isAutocompleteLoading = false;
      }).catch((error: any) => {
        this.isAutocompleteLoading = false;
        NotificationsService.sendErrorMessage(error.error);
      });
    }
  }

  public async onModalModificationReplace(): Promise<void> {
    this.updateThirdAutocomplete?.close();
    if (this.thirdAutocompleted) {
      await this.replaceThirdDataFrom(this.thirdAutocompleted);
    }
  }

  private async updateThirdOrOpenUpdateModal(newThird: Third): Promise<void> {
    if (!this.element.Name && this.element.LegalFormId.Id == 1 && this.element.CountryId.Id == 20) {
      this.usedCurrentDenomination = false;
      this.usedCurrentLegalForm = false;
      this.usedCurrentCountry = false;
      newThird.ContactInformations.forEach(e => e.IsChecked = true);
      await this.replaceThirdDataFrom(newThird, false);
    }
    else {
      this.updateThirdAutocomplete?.open();
    }
  }

  private async replaceThirdDataFrom(thirdAutocompleted: Third, autosave: boolean = true): Promise<void> {
    if (this.usedCurrentDenomination == false) {
      this.element.Name = thirdAutocompleted.Name;
    }
    this.element.CompanyNumber = this.element.VatNumber?.substring(3);
    if (this.usedCurrentLegalForm == false) {
      this.element.LegalFormId = thirdAutocompleted.LegalFormId;
    }
    if (this.usedCurrentCountry == false) {
      this.element.CountryId = thirdAutocompleted.CountryId;
    }
    await this.replaceThirdContactInformationFrom(thirdAutocompleted);
    if (autosave)
      await this.save();
    this.thirdAutocompleted = undefined;
  }

  private async replaceThirdContactInformationFrom(thirdAutocompleted: Third): Promise<void> {
    this.viesContactInformations = [];
    for (var contact of this.element.ContactInformations) {
      await this.addOrRemoveThirdContactInformation(contact);
    }
    for (var contact of thirdAutocompleted.ContactInformations) {
      await this.addOrRemoveThirdContactInformation(contact);
    }
    this.element.ContactInformations = [];
    this.element.ContactInformations = this.viesContactInformations;
  }
  private async addOrRemoveThirdContactInformation(contact: ThirdContactInformation) {
    if (contact.IsChecked == true) {
      contact.ThirdId = this.element;
      if (contact.ContactTypeId?.Code == "Address" && contact.AddressId && contact.AddressId.Id == undefined) {
        contact.AddressId.ThirdId = this.element;
        contact.AddressId = await this.thirdAddressService.insert(this.thirdAddressService.format(contact.AddressId));
      }
      contact.IsFavorite = this.checkIsFavorite(this.viesContactInformations, contact);
      var result = await this.createOrUpdate<ThirdContactInformation>(this.thirdContactInformationsService, contact, true);
      if (result != undefined) {
        this.viesContactInformations.push(result);
      }
    }
    if (contact.IsChecked == false && contact.Id != undefined) {
      await this.thirdContactInformationsService.remove(contact.Id);
    }
    return undefined;
  }

  public checkIsFavorite(elements: ThirdContactInformation[], newElement: ThirdContactInformation): boolean {
    var favoriteElement = elements.find((c: ThirdContactInformation) => c.IsFavorite == true && c.ContactTypeId?.Id == newElement.ContactTypeId?.Id && c.Id != newElement.Id);
    if (favoriteElement != null) {
      return false;
    }
    return true;
  }

  private async createOrUpdate<T extends Entity>(service: ODataService<T>, object?: T, forceRefresh: boolean = false): Promise<T | undefined> {
    if (!object) return;
    let response: T = object?.Id ? await service.update(object.Id, service.format(object)) : await service.insert(service.format(object));
    if (!response?.Id) {
      NotificationsService.sendErrorMessage("Unable to create or update record: %s", [this.translateService.instant("Person")]);
      return;
    }
    if (forceRefresh) {
      response = await service.findByID(response.Id);
    }
    return response;
  }

  // // Appointments
  // // ------------
  // public async onAddAppointmentButtonClicked() {
  //   this.appointmentModal?.open(await this.appointmentsService.getInstance({
  //     ThirdId: this.element,
  //   }));
  // }
  // public async onValidateAppointmentCreation(e: Appointment) {
  //   if (!this.appointmentsService.validateElementInformations(e)) return;
  //   await this.appointmentsService.createOrUpdate(e);
  //   this.appointmentModal?.close();
  //   this.appointmenGrid?.reload();
  //   this.reloadAppointment();
  // }
  // public async onSchedulerSelectionChanged(schedulerSelectDates: SchedulerSelectionChangedData[]) {
  //   await this.onAddAppointmentButtonClicked();

  //   let startDate;
  //   let endDate;
  //   if (schedulerSelectDates?.length > 0) {
  //     startDate = new Date(Math.min(...schedulerSelectDates.map(e => e.startDate.getTime())));
  //     endDate = new Date(Math.max(...schedulerSelectDates.map(e => e.endDate.getTime())));
  //     if (this.appointmentModal?.modal?.data) {
  //       this.appointmentModal.modal.data.StartDate = startDate;
  //       this.appointmentModal.modal.data.EndDate = endDate;
  //       this.appointmentModal.modal.data.updateDuration();
  //     }
  //   }
  // }
  // public reloadAppointment() {
  //   this.appointmenGrid?.reload();
  //   this.scheduler?.scheduler?.refresh();
  // }

  // CreditSafe
  // ----------
  public showCreditSafeModal() {
    if (this.element.CreditSafeLineId != null) {
      var data = this.element.CreditSafeLineId.Data;
      this.creditSafeElement = JSON.parse(data);
    }
    this.creditsafeModal?.open();
  }
}
