import { Component } from '@angular/core';
import { SaleCreditNote } from '../sale-credit-notes/sale-credit-note.model';
import { SaleCreditNotesService } from '../sale-credit-notes/sale-credit-notes.service';
import { SaleCreditNoteLine } from '../sale-credit-note-lines/sale-credit-note-line.model';
import { SaleCreditNoteLinesService } from '../sale-credit-note-lines/sale-credit-note-lines.service';
import { SaleCreditNoteHeaderStatus } from '../sale-credit-note-header-statuses/sale-credit-note-header-status.model';
import { SaleCreditNoteHeaderStatusesService } from '../sale-credit-note-header-statuses/sale-credit-note-header-statuses.service';
import { SaleCreditNoteHeaderHeaderStatus } from '../sale-credit-note-header-header-statuses/sale-credit-note-header-header-status.model';
import { SaleCreditNoteHeaderHeaderStatusesService } from '../sale-credit-note-header-header-statuses/sale-credit-note-header-header-statuses.service';
import { SaleDocumentDetailsComponent } from '../../sale-documents/sale-documents/sale-document-details/sale-document-details.component';
import { AppInjectorService } from 'projects/libraries/syslink-components/src/lib/services/app-injector.service';
import { SyslinkColumn } from 'projects/libraries/syslink-components/src/lib/helpers/SyslinkColumn';
import { TaxesService } from '../../../accountings/taxes/taxes.service';
import { AccountsService } from '../../../accountings/accounts/accounts.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-sale-credit-note-details',
  templateUrl: '../../sale-documents/sale-documents/sale-document-details/sale-document-details.component.html',
  styleUrls: ['./sale-credit-note-details.component.scss']
})
export class SaleCreditNoteDetailsComponent extends SaleDocumentDetailsComponent<
  SaleCreditNote,
  SaleCreditNotesService,
  SaleCreditNoteLine,
  SaleCreditNoteLinesService,
  SaleCreditNoteHeaderStatus,
  SaleCreditNoteHeaderStatusesService,
  SaleCreditNoteHeaderHeaderStatus,
  SaleCreditNoteHeaderHeaderStatusesService
>{
  protected override loadDependencies() {
    this.saleDocumentService = AppInjectorService.injector.get(SaleCreditNotesService);
    this.saleDocumentLinesService = AppInjectorService.injector.get(SaleCreditNoteLinesService);
    this.saleDocumentStatusesService = AppInjectorService.injector.get(SaleCreditNoteHeaderStatusesService);
    this.saleDocumentHeaderStatusesService = AppInjectorService.injector.get(SaleCreditNoteHeaderHeaderStatusesService);

    const taxesService = AppInjectorService.injector.get(TaxesService);
    const accountsService = AppInjectorService.injector.get(AccountsService);
    const translateService = AppInjectorService.injector.get(TranslateService);

    this.reportType = 'SaleCreditNote';
    this.subModuleCode = 'Sales.CreditNote';
    this.defaultStatusCode = 'CreditNote.Proforma';
    this.documentLinesColumns = [
      ...this.saleDocumentService.getDefaultDocumentLineColumns(this.subModuleCode,this.reportType),
      // new SyslinkColumn({ order: 15, field: 'TaxId', label: translateService.instant('Tax'), type: 'string', cellTemplate: 'select-value', editCellTemplate: 'select-cell', data: { service: taxesService, displayKey: 'Alias', filter:"IsVisible eq true"  }, width: 70 }),
      // new SyslinkColumn({ order: 15, field: 'AccountId', label: translateService.instant('Account'), type: 'string', cellTemplate: 'select-value', editCellTemplate: 'select-cell', data: { service: accountsService, displayKey: 'Name' }, width: 70 }),
    ];

  }

  public override canEditDocument(): boolean {
    return !this.hasActiveStatus('CreditNote.Ventilated') && !this.hasActiveStatus('CreditNote.Canceled');
  }
}