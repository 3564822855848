import { Third } from "../../third.model";
import { ThirdContactQualifier } from "../third-contact-qualifiers/third-contact-qualifier.model";
import { ThirdContactType } from "../third-contact-types/third-contact-type.model";
import { Entity } from "projects/erp-app/src/app/core/models/entity";
import { ThirdAddress } from "../../third-addresses/third-addresses/third-address.model";

export class ThirdContactInformation extends Entity {
    public ThirdId?: Third;
    public AddressId?: ThirdAddress;
    public IsPublic?: boolean;
    public Value?: string;
    public UsedForQuote?: boolean;
    public UsedForDelivery?: boolean;
    public UsedForInvoice?: boolean;
    public UsedForNewsletter?: boolean;
    public ContactQualifierId?: ThirdContactQualifier;
    public ContactTypeId?: ThirdContactType;
    public IsFavorite?: boolean;

    // VIES
    // ----
    public IsChecked?: boolean;

    constructor(entity?: Partial<ThirdContactInformation>) {
        super(entity);
        Object.assign(this, entity);
    }
}
