import { Component, EventEmitter, Input, Output} from '@angular/core';
import { DocumentDelay } from 'projects/erp-app/src/app/base/documents/document-delays/document-delay';
import { DocumentDelaysService } from 'projects/erp-app/src/app/base/documents/document-delays/document-delays.service';
import { PurchaseDocumentLine } from '../../purchase-document-lines/purchase-document-line.model';
import { PurchaseDocumentLinesService } from '../../purchase-document-lines/purchase-document-lines.service';
import { PurchaseDocument } from '../purchase-document.model';
import { PurchaseDocumentsService } from '../purchase-documents.service';
import { getNew } from 'projects/libraries/syslink-components/src/lib/helpers/tools';
import { PurchaseDocumentStatus } from '../../purchase-document-statuses/purchase-document-status.model';
import { DeliveryTimesService } from 'projects/erp-app/src/app/base/delivery-times/delivery-times.service';

@Component({
  selector: 'app-purchase-document-other',
  templateUrl: './purchase-document-other.component.html',
  styleUrls: ['./purchase-document-other.component.scss']
})
export class PurchaseDocumentOtherComponent<
  TPurchaseDocument extends PurchaseDocument,
  TPurchaseDocumentService extends PurchaseDocumentsService<PurchaseDocument,PurchaseDocumentStatus>,
  TPurchaseDocumentLine extends PurchaseDocumentLine,
  TPurchaseDocumentLineService extends PurchaseDocumentLinesService<PurchaseDocumentLine>
> {
  @Input() public element: TPurchaseDocument = <TPurchaseDocument>getNew<TPurchaseDocument>();
  @Output() public elementChange: EventEmitter<TPurchaseDocument> = new EventEmitter<TPurchaseDocument>();

  @Output() public globalDiscountChanged: EventEmitter<number> = new EventEmitter<number>();
  @Output() public documentDelayChanged: EventEmitter<DocumentDelay> = new EventEmitter<DocumentDelay>();

  @Input() public disabled: boolean = false;
  @Input() public reportType: String = '';

  constructor(
    public documentDelaysService: DocumentDelaysService,
    public deliveryTimesService : DeliveryTimesService,
  ) { }


}
