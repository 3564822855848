<div class="form">
    <div class="row">
        <div class="col-6">
            <syslink-select label="Purchase tax rate" [store]="taxRatesService.store" [filter]="['IsVisible eq true']"
                [(model)]="element.PurchaseTaxRateId"></syslink-select>
        </div>
        <div class="col-6">
            <syslink-select label="Deliverytime" [store]="deliveryTimesService.store"
                [(model)]="element.DeliveryTimeId"></syslink-select>
        </div>
    </div>
    <div class="row">
        <div class="col-6">
            <syslink-select label="Purchase invoices DocumentDelay" [store]="documentDelaysService.store"
                [(model)]="element.PurchaseInvoiceDocumentDelayId"></syslink-select>
        </div>
        <div class="col-6">
            <syslink-select label="Purchase credit notes DocumentDelay" [store]="documentDelaysService.store"
                [(model)]="element.PurchaseCreditNoteDocumentDelayId"></syslink-select>
        </div>
    </div>
</div>