import { Component, Input, ViewChild } from '@angular/core';
import { BaseGridComponent } from 'projects/libraries/syslink-components/src/lib/grid/base-grid/base-grid.component';
import { SyslinkColumn } from 'projects/libraries/syslink-components/src/lib/helpers/SyslinkColumn';
import { GridComponent } from 'projects/libraries/syslink-components/src/public-api';
import { PurchaseDocumentFinance } from '../purchase-document-finance.model';

@Component({
  selector: 'app-purchase-document-finance-grid',
  templateUrl: './purchase-document-finance-grid.component.html',
  styleUrls: ['./purchase-document-finance-grid.component.scss']
})
export class PurchaseDocumentFinanceGridComponent extends BaseGridComponent<any> {

  public override canDelete: boolean = true;
  @Input() storageKey: string = 'purchase-document-finance-grid';

  @ViewChild('gridComponent') public gridComponent?: GridComponent;
  @Input() public subModuleCode: string = '';
  @Input() public reportType: String = '';
  @Input() public items: PurchaseDocumentFinance[] | undefined = [];
  
  public columns: SyslinkColumn[] = [
    new SyslinkColumn({ field: 'TaxRateId', label: 'Id', visible: false }),
    new SyslinkColumn({ field: 'TaxRateName', label: 'Tax rate', width: 90 }),
    new SyslinkColumn({ field: 'ExTaxTotalAmount', label: 'ExTaxTotalAmount', width: 120, type: 'number', cellTemplate: 'currency-cell', data: { subModuleCode: this.subModuleCode, key: 'Precision.' + this.reportType + 'Finance.ExTaxTotalAmount' } }),
    new SyslinkColumn({ field: 'Vat', label: 'VAT', width: 120, type: 'number', cellTemplate: 'currency-cell', data: { subModuleCode: this.subModuleCode, key: 'Precision.' + this.reportType + 'Line.TaxAmount' } }),
    new SyslinkColumn({ field: 'InTaxTotalAmount', label: 'InTaxTotalAmount', width: 120, type: 'number', cellTemplate: 'currency-cell', data: { subModuleCode: this.subModuleCode, key: 'Precision.' + this.reportType + 'Finance.InTaxTotalAmount' } }),
  ];

  constructor(
 
  ) {
    super();
  }

}
