import { Injectable } from '@angular/core';
import DevExpress from 'devextreme';
import { merge } from '../helpers/tools';
import { ColumnChooser, ColumnFixing, Editing, FilterRow, HeaderFilter, Pager, Properties, SearchPanel, Selection, Sorting, Toolbar, ToolbarItem, TreeListPredefinedToolbarItem } from 'devextreme/ui/tree_list';
import { PagingBase, SelectionBase } from 'devextreme/common/grids';
import { Export } from 'devextreme/ui/data_grid';

@Injectable({
  providedIn: 'root'
})
export class TreelistService {
  public getBaseConfiguration = (): Properties => ({
    keyExpr: 'Id',
    wordWrapEnabled: true,
    hoverStateEnabled: true,
    showBorders: true,
    showColumnLines: true,
    focusedRowEnabled: true,
    showRowLines: true,
    rowAlternationEnabled: true,
    allowColumnReordering: true,
    allowColumnResizing: true,
    columnAutoWidth: true,
  });

  public getDefaultExportConfiguration = (): Export => ({
    enabled: true,
    allowExportSelectedData: false
  });

  public getDefaultEditingConfiguration = (): Editing<any, any> => ({
    allowAdding: false,
    allowUpdating: true,
    allowDeleting: true,
    mode: 'cell',
    useIcons: true
  });

  public getDefaultSearchPanelConfiguration = (): SearchPanel => ({
    visible: true,
    highlightCaseSensitive: false,
    width: 250,
  });

  public getDefaultPagingConfiguration = (): PagingBase => ({
    enabled: true,
  });

  public getDefaultPagerConfiguration = (): Pager => ({
    visible: true,
    showInfo: true,
    displayMode: 'compact',
    showNavigationButtons: true,
    showPageSizeSelector: false,
  });

  public getDefaultColumnChooserConfiguration = (): ColumnChooser => ({
    enabled: true,
    mode: 'select',
    allowSearch: true,
  });

  public getDefaultColumnFixingConfiguration = (): ColumnFixing => ({
    enabled: true,
  });

  public getDefaultSortingConfiguration = (): Sorting => ({
    mode: 'multiple'
  });

  public getDefaultFilterRowConfiguration = (): FilterRow => ({
    visible: true
  });

  public getDefaultHeaderFilterConfiguration = (): HeaderFilter => ({
    visible: false,
    allowSearch: true,
  });

  public getDefaultSelectionConfiguration = (): SelectionBase => ({
    mode: 'multiple',
    allowSelectAll: true,
  });

  public generateEditingConfiguration(customConfiguration: DevExpress.ui.dxDataGrid.Editing<any, any> = {}) {
    return merge(this.getDefaultEditingConfiguration(), customConfiguration);
  }

  public generateExportConfiguration(customConfiguration: DevExpress.ui.dxDataGrid.Export = {}) {
    return merge(this.getDefaultExportConfiguration(), customConfiguration);
  }

  public generateSearchPanelConfiguration(customConfiguration: SearchPanel = {}) {
    return merge(this.getDefaultSearchPanelConfiguration(), customConfiguration);
  }

  public generatePagingConfiguration(customConfiguration: PagingBase = {}) {
    return merge(this.getDefaultPagingConfiguration(), customConfiguration);
  }

  public generatePagerConfiguration(customConfiguration: Pager = {}) {
    return merge(this.getDefaultPagerConfiguration(), customConfiguration);
  }

  public generateColumnChooserConfiguration(customConfiguration: ColumnChooser = {}) {
    return merge(this.getDefaultColumnChooserConfiguration(), customConfiguration);
  }

  public generateColumnFixingConfiguration(customConfiguration: ColumnFixing = {}) {
    return merge(this.getDefaultColumnFixingConfiguration(), customConfiguration);
  }

  public generateSortingConfiguration(customConfiguration: Sorting = {}) {
    return merge(this.getDefaultSortingConfiguration(), customConfiguration);
  }

  public generateFilterRowConfiguration(customConfiguration: FilterRow = {}) {
    return merge(this.getDefaultFilterRowConfiguration(), customConfiguration);
  }

  public generateHeaderFilterConfiguration(customConfiguration: HeaderFilter = {}) {
    return merge(this.getDefaultHeaderFilterConfiguration(), customConfiguration);
  }

  public generateSelectionConfiguration(customConfiguration: Selection = {}) {
    return merge(this.getDefaultSelectionConfiguration(), customConfiguration);
  }

  public generateToolbarConfiguration<T>(toolbarItems?: Array<T>, customConfiguration: Toolbar = {}) {
    var toolbar: Toolbar = {
      items: <Array<ToolbarItem | TreeListPredefinedToolbarItem>>toolbarItems,
      visible: true,
      disabled: false,
    }
    return merge(toolbar, customConfiguration);
  }

  public getConfiguration(customConfiguration: Properties = {}): Properties {
    var configuration: Properties = this.getBaseConfiguration();

    configuration.editing = this.generateEditingConfiguration();
    configuration.searchPanel = this.generateSearchPanelConfiguration();
    configuration.paging = this.generatePagingConfiguration({ pageSize: 20 });
    configuration.pager = this.generatePagerConfiguration({ allowedPageSizes: [20] });
    configuration.columnChooser = this.generateColumnChooserConfiguration();
    configuration.sorting = this.generateSortingConfiguration();
    configuration.filterRow = this.generateFilterRowConfiguration();
    configuration.headerFilter = this.generateHeaderFilterConfiguration();
    configuration.selection = this.generateSelectionConfiguration();
    configuration.toolbar = this.generateToolbarConfiguration();

    return merge(configuration, customConfiguration);
  }
}
