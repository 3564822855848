import { Component, Input, OnInit } from '@angular/core';
import { InputComponent } from '../input/input.component';
import { AppInjectorService } from '../../services/app-injector.service';
import { KeyDownEvent } from 'devextreme/ui/number_box';

@Component({
  selector: 'syslink-input-number',
  templateUrl: './input-number.component.html',
  styleUrls: ['./input-number.component.scss']
})
export class InputNumberComponent extends InputComponent implements OnInit {
  @Input() public format: string = '';
  /**
   * If set, will override format
   */
  @Input() public precision?: number;

  @Input() public subModuleCode?: string;
  @Input() public key?: string;

  @Input() public min: number | undefined = undefined;
  @Input() public max: number | undefined = undefined;
  @Input() public showSpinButtons: boolean = true;
  @Input() public step: number = 1;

  @Input() public allowExponent : boolean = false;

  public override class: string = 'py-2';

  ngOnInit(): void {
    if (this.subModuleCode && this.key) {
      this.precision = AppInjectorService.config.getPrecision(this.subModuleCode, this.key);
    }

    if (this.precision) {
      this.format = `#0.${'0'.repeat(this.precision)}`;
    }
  }

  public onKeyDown(e: KeyDownEvent){
    if(e.event === undefined) return;
    let input = e.event.key
    const forbiddenCharRegex = new RegExp(`^[${this.getForbiddenCharacterConcatened}]$`);
    if(forbiddenCharRegex.test(input)){
      e.event.preventDefault();
    }
  }

  /**
   * Return the forbidden character concatenated without any separator.
   * The format chosen allows to easily create a regex expression that could be used to check the presence
   * of forbidden characters in a string.
   */
  private getForbiddenCharacterConcatened() : string{
    let result = '';
    if(!this.allowExponent) result += 'e';
    return result;
  }
}
