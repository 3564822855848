
import { Component, Input, ViewChild } from '@angular/core';
import { SyslinkColumn } from 'projects/libraries/syslink-components/src/lib/helpers/SyslinkColumn';
import { Third } from '../../../third.model';
import { ViewComponent } from 'projects/libraries/syslink-components/src/lib/helpers/view/view.component';
import { SaleOrdersService } from 'projects/erp-app/src/app/sales/sale-orders/sale-orders/sale-orders.service';
import { SaleOrderGridComponent } from 'projects/erp-app/src/app/sales/sale-orders/sale-order-grid/sale-order-grid.component';

@Component({
  selector: 'app-third-document-relation-sale-orders',
  templateUrl: './third-document-relation-sale-orders.component.html',
  styleUrls: ['./third-document-relation-sale-orders.component.scss']
})
export class ThirdDocumentRelationSaleOrdersComponent  extends ViewComponent  {
  @Input() public element: Third = new Third();
  
  constructor(
    private saleOrdersService: SaleOrdersService,
  ){
    super();
  }
  @ViewChild('orderGridComponent') orderGridComponent: SaleOrderGridComponent = new SaleOrderGridComponent(this.saleOrdersService);
  public SaleOrderColumns: SyslinkColumn[] = [
    new SyslinkColumn({ field: "No", label: "GRID.CAPTION.NO", width: 150, sortIndex: 2, sortDirection: 'desc' }),
    new SyslinkColumn({ field: "Subject", label: "Subject" }),
    new SyslinkColumn({ field: "CurrentStatusLink.StatusId.Name", label: "Status", cellTemplate: 'statusText-cell', data: { getStatusLabel: this.orderGridComponent.getStatusCellLabel, getStatusDate: this.orderGridComponent.getStatusCellDate, getStatusClass: this.orderGridComponent.getStatusCellClass, getStatusColor: this.orderGridComponent.getStatusCellColor }, width: 120 }),
    new SyslinkColumn({ field: "Deadline", label: 'SaleOrder.Validity', type: "date" }),
    new SyslinkColumn({ field: "ExternalReference", label: "External reference", visible: true, width: 150 }),
    new SyslinkColumn({ field: "ExTaxTotal", label: "exTaxTotal", type: "number", cellTemplate: 'currency-cell', data: { subModuleCode: 'Sales.Order', key: 'Precision.SaleOrderHeader.ExTaxTotal' }, width: 80 }),
    new SyslinkColumn({ field: "InTaxTotal", label: "Total", type: "number", cellTemplate: 'currency-cell', data: { subModuleCode: 'Sales.Order', key: 'Precision.SaleOrderHeader.InTaxTotal' }, width: 80 }),
    new SyslinkColumn({ field: "ResponsibleUserId.Fullname", label: "Responsible", width: 120 }),
    new SyslinkColumn({ field: "Date", label: "Date", type: "date", visible: false, width: 150 }),
    new SyslinkColumn({ field: "AmountRemaining", label: 'Remaining amount', type: "number", visible: false, cellTemplate: 'currency-cell', data: { subModuleCode: 'Sales.Order', key: 'Precision.SaleOrderHeader.AmountRemaining' }, width: 80, filterable: false, headerFilterable: false, sortable: false }),
    new SyslinkColumn({ field: "FormattedStructuredCommunication", label: 'Structured communication', visible: false, editable: false }),
    new SyslinkColumn({ field: "CurrentStatusLink.StatusId.Sequence", label: 'status order', sortIndex: 1, sortDirection: 'asc', width: 0, visible: false, filterable: false, headerFilterable: false, sortable: false })
  ];
  onAddButtonClicked() {
    window.open('/sales/orders/new?ThirdId='+this.element.Id);
  }
}