<div class="row border border-light">
  <h2 class="mb-4">{{"Period" | translate}}</h2>
  <syslink-input-number
    class="col-sm-6 ml-2"
    [(model)]="startYear"
    [label]="'StartYear' | translate"
  >
  </syslink-input-number>
  <syslink-input-number
    class="col-sm-6 ml-2"
    [(model)]="endYear"
    [label]="'EndYear' | translate"
  >
  </syslink-input-number>
</div>
<div id="productStatsChartsContainer">
  <div
    *ngIf="element.Sellable"
    class="row mb-5"
  >
    <h2>{{"Sales" | translate}}</h2>
    <syslink-chart
      *ngIf="authService.hasPermission(basePermissionKey + '.statistics.saleQuote.view')"
      class="col-sm-6"
      [title]="'Confirmed sale quote ex tax total per month' | translate"
      [data]="confirmedSaleQuoteExTaxTotalPerMonth"
      [series]="series"
      [size]="undefined"
    ></syslink-chart>
    <syslink-chart
      *ngIf="authService.hasPermission(basePermissionKey + '.statistics.saleInvoice.view')"
      class="col-sm-6"
      [title]="'Validated and ventilated sale invoices ex tax total per month' | translate"
      [data]="validatedVentilatedSaleInvoiceExTaxTotalPerMonth"
      [series]="series"
      [size]="undefined"
    ></syslink-chart>
    <syslink-chart
      *ngIf="authService.hasPermission(basePermissionKey + '.statistics.saleCreditNote.view')"
      class="col-sm-6"
      [title]="'Validated and ventilated sale credit note ex tax total per month' | translate"
      [data]="validatedVentilatedSaleCreditNoteExTaxTotalPerMonth"
      [series]="series"
      [size]="undefined"
    ></syslink-chart>
  </div>
  <hr />

  <div
    *ngIf="element.Purchasable"
    class="row mb-5"
  >
    <h2>{{"Purchases" | translate}}</h2>
    <syslink-chart
      *ngIf="authService.hasPermission(basePermissionKey + '.statistics.purchaseInvoice.view')"
      class="col-sm-6"
      [title]="'Validated and ventilated purchase invoices ex tax total per month' | translate"
      [data]="validatedVentilatedPurchaseInvoiceExTaxTotalPerMonth"
      [series]="series"
      [size]="undefined"
    ></syslink-chart>
    <syslink-chart
      *ngIf="authService.hasPermission(basePermissionKey + '.statistics.purchaseCreditNote.view')"
      class="col-sm-6"
      [title]="'Validated and ventilated purchase credit note ex tax total per month' | translate"
      [data]="validatedVentilatedPurchaseCreditNoteExTaxTotalPerMonth"
      [series]="series"
      [size]="undefined"
    ></syslink-chart>
  </div>
</div>
