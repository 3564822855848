import { Injectable } from '@angular/core';
import { ODataService } from '../../core/services/oData.service';
import { SupplierCatalogDiscountOperations } from './supplier-catalog-discount-operations/supplier-catalog-discount-operations';

@Injectable({
  providedIn: 'root'
})
export class SupplierCatalogDiscountOperationService extends ODataService<SupplierCatalogDiscountOperations> {
  public url = 'SupplierCatalogDiscountOperation';

  constructor() {
    super();
  }
}
