import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import notify from 'devextreme/ui/notify';
import { sprintf } from "sprintf-js";
import { AppInjectorService } from '../services/app-injector.service';

type NotificationType = 'error' | 'info' | 'success' | 'warning';

const notificationDuration: { [k in NotificationType]: number } = {
  error: 10000,
  warning: 10000,
  info: 4000,
  success: 4000,
}

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  constructor() { }

  static sendNotification(message: string, type: NotificationType, params: string[] = []) {
    const translateService: TranslateService = AppInjectorService.injector.get(TranslateService);
    notify({
      message: `${sprintf(translateService.instant(message), ...params)}`,
      type,
      displayTime: notificationDuration[type],
      animation: {
        show: { type: 'fade', duration: 400, from: 0, to: 1 },
        hide: { type: 'fade', duration: 300, from: 1, to: 0 },
      },
      closeOnClick: true
    },
      {
        position: 'bottom center',
        direction: 'up-push'
      }
    );
  }

  static sendInfo(message: string, params?: string[]) {
    NotificationsService.sendNotification(message, 'info', params);
  }

  static sendSuccess(message: string, params?: string[]) {
    NotificationsService.sendNotification(message, 'success', params);
  }

  static sendWarning(message: string, params?: string[]) {
    NotificationsService.sendNotification(message, 'warning', params);
  }
  static sendErrorMessage(message: string, params?: string[]) {
    NotificationsService.sendNotification(message, 'error', params);
  }
  static sendError(error: Error, params?: string[]) {
    NotificationsService.sendNotification(error.message, 'error', params);
  }


}
