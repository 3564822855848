<dx-file-manager
  #fileManager
  id="fileManager"

  [rootFolderName]="rootFolderName"

  [fileSystemProvider]="fileSystemProvider"
  [selectedItemKeys]="selectedItemKeys"
  
  (onErrorOccurred)="onErrorOccurred($event)"
  (onSelectionChanged)="selectedItemKeysChange.emit($event.selectedItemKeys)"
></dx-file-manager>