import { Component } from '@angular/core';
import { ProductGridComponent } from '../product-grid/product-grid.component';

@Component({
  selector: 'app-readonly-product-grid',
  templateUrl: '../product-grid/product-grid.component.html',
  styleUrls: ['../product-grid/product-grid.component.scss']
})
export class ReadonlyProductGridComponent extends ProductGridComponent{
  override  ngOnInit(): void {
    super.ngOnInit();
    this.canDelete = false;
    this.canUpdate = false;
    this.canAdd = false;
  }

}
