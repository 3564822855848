import { Injectable } from '@angular/core';
import { LoadOptions } from 'devextreme/data';
import { ODataService } from '../../core/services/oData.service';
import { TaxType } from './tax-type.model';

@Injectable({
  providedIn: 'root'
})
export class TaxTypesService extends ODataService<TaxType> {
  public url = 'TaxType';
}

