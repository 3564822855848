{{'Renewal' | translate}}
<div class="row">
  <div class="col-3">
    <syslink-input-number
      label="Renewal (month)"
      [min]="0"
      [(model)]="element.Renewal"
      (modelChange)="renewalChange.emit()"
    ></syslink-input-number>
  </div>
  <div
    *ngIf="element.Renewal!=0"
    class="col-1"
  >
    <syslink-button
      hint="End renewal"
      icon="fa-solid fa-circle-exclamation"
      (click)="onEndRenewalClicked()"
    ></syslink-button>
    <syslink-button
      hint="Force end renewal"
      icon="fa-solid fa-triangle-exclamation"
      type="danger"
      (click)="onForceEndRenewalClicked()"
    ></syslink-button>
    </div>
    <div
    *ngIf="element.Renewal!=0"
    class="col-2"
  >
    <syslink-input-date
      *ngIf="this.element.RenewalEndDate && this.element.RenewalEndDate.getFullYear() >1"
      [disabled]="true"
      label="Renewal end date"
      [model]="this.element.RenewalEndDate"
      type="date"
    ></syslink-input-date>
  </div>
  <div
    *ngIf="element.Renewal!=0"
    class="col-3"
  >
    <syslink-input-date
      label="Deadline after renewal"
      [(model)]="element.RenewalDate"
      type="date"
      format="date"
    ></syslink-input-date>
  </div>
  <div
    *ngIf="element.Renewal!=0"
    class="col-3"
  >
    <syslink-input-number
      label="Délai de préavis (mois)"
      [min]="0"
      [(model)]="element.NotificationDelay"
    ></syslink-input-number>
  </div>
</div>
{{'Indexing' | translate}}
<div class="row">
  <div class="col-3">
    <syslink-select
      label="Indexing type"
      [(model)]="element.IndexingTypeId"
      [store]="indexingTypesService.store"
    ></syslink-select>
  </div>
  <div
    *ngIf="element.IndexingTypeId && element.IndexingTypeId.Code!='None'"
    class="col-3"
  >
    <syslink-input-number
      label="Indexing (month)"
      [min]="0"
      [(model)]="element.Indexing"
      (modelChange)="indexingChange()"
    ></syslink-input-number>
  </div>
  <div
    *ngIf="element.IndexingTypeId && element.IndexingTypeId.Code!='None' && element.LastIndexingDate"
    class="col-3"
  >
    <syslink-input-date
      label="Last indexing date"
      [(model)]="element.LastIndexingDate"
      (modelChange)="indexingChange()"
      type="date"
      format="date"
      [disabled]="element.LastIndexingValue!=0"
    ></syslink-input-date>
  </div>
  <div
    *ngIf="element.IndexingTypeId && element.IndexingTypeId.Code!='None' && element.NextIndexingDate"
    class="col-3"
  >
    <syslink-input-date
      label="Next indexing date"
      [(model)]="element.NextIndexingDate"
      type="date"
      format="date"
      [disabled]="true"
    ></syslink-input-date>
  </div>
  <!-- <div *ngIf="element.IndexingTypeId && element.IndexingTypeId.Code!='None'" class="col-3">
        <syslink-input-number
            label="Index coefficient"
            [min]="0"
            [(model)]="element.IndexCoefficient"
        ></syslink-input-number>
    </div> -->
  <div
    *ngIf="element.IndexingTypeId && element.IndexingTypeId.Code!='None'"
    class="col-12"
  >
    <syslink-input-text
      label="Index formula"
      [(model)]="element.IndexingTypeId.IndexFormula"
      [disabled]="true"
    ></syslink-input-text>
  </div>
</div>


<syslink-confirm-modal
  #onEndRenewalClickedConfirmModal
  title="Confirm end renewal"
  content="Confirm end renewal"
  (onValidate)="onEndRenewalConfirmed()"
  [validateText]="'Confirm' | translate"
  [cancelText]="'Cancel' | translate"
></syslink-confirm-modal>

<syslink-confirm-modal
  #onForceEndRenewalClickedConfirmModal
  title="Confirm force end renewal"
  content="Confirm force end renewal"
  (onValidate)="onForceEndRenewalConfirmed()"
  [validateText]="'Confirm' | translate"
  [cancelText]="'Cancel' | translate"
></syslink-confirm-modal>