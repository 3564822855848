<syslink-title
  *ngIf="element.UserName != undefined"
  [text]="element.UserName"
  [shouldTranslate]="false"
></syslink-title>

<syslink-toolbar [actions]="toolbarActions"></syslink-toolbar>
<app-user-form [(user)]="element"></app-user-form>
<p></p>
<syslink-tabs #userPermissions *ngIf="element.Oid">
  <syslink-tab label="User groups" [visible]="authService.hasPermission(this.basePermissionKey + '.userGroup.tab.view')">
    <app-user-group-grid
      #userGroupsGrid
      [canUpdate]="false"
      [permissionKey]="'userGroups.list'"
      storageKey="user-details-grid-user-groups"
      [filter]="userGroupsFilters"
      (add)="addUserGroupButtonClicked()"
      (delete)="onDeleteUserGroup($event)"
    >
    </app-user-group-grid>
    
    <app-user-group-select-modal
      #userGroupSelectModal
      (validate)="onValidateUserGroupAddButtonClicked($event)"
    ></app-user-group-select-modal>
  </syslink-tab>
  <syslink-tab label="Action groups" [visible]="authService.hasPermission(this.basePermissionKey + '.actionGroup.tab.view')">
    <app-action-group-grid
      #actionGroupsGrid
      [canUpdate]="false"
      [permissionKey]="'actionGroups.list'"
      storageKey="user-details-grid-action-groups"
      [filter]="actionGroupsFilters"
      (add)="addActionGroupButtonClicked()"
      (delete)="onDeleteActionGroup($event)"
      [select]="['Id', 'Code','Name']"
      [expand]="['NameTranslationId.Translations.LanguageId']"
      >
    </app-action-group-grid>
    
    <app-action-group-select-modal
      #actionGroupSelectModal
      (validate)="onValidateActionGroupAddButtonClicked($event)"
    ></app-action-group-select-modal>
  </syslink-tab>
</syslink-tabs>
<syslink-confirm-password
  #passwordConfirm
  (onValidate)="onSavebtnClicked($event)"
></syslink-confirm-password>
<syslink-confirm-modal
  #deleteConfirm
  title="Delete"
  [content]="'DeleteConfirmMessage'"
  (onValidate)="userDelete()"
  validateText="Confirm"
  cancelText="Cancel"
></syslink-confirm-modal>
