<div id="sale-contract-schedule" class="row">
    <div class="col-6">
        <syslink-scheduler
            #invoiceScheduler
            [items]="schedulerDatasource"
            height="unset"
            [views]="['month']"
            currentView="month"
            [canAdd]="false"
            [canUpdate]="false"
            [canDelete]="false"
            [canDrag]="false"
            [adaptivityEnabled]="false"
            [canResize]="false"
        ></syslink-scheduler>
    </div>
    <div class="col-6">
        <div class="row">
            <div class="col-6">
                <syslink-input-date
                    name="Start period date"
                    label="Start period date"
                    [(model)]="element.StartPeriodDate"
                    (modelChange)="saleContractsService.updateContractScheduler(); element.StartPeriodDateOffset = 0; element.IsStartPeriodDateOffsetPositive = false"
                    type="date"
                ></syslink-input-date>
                <div class="row">
                    <div class="col-6">
                        <syslink-input-number
                            label="Days"
                            [min]="0"
                            [(model)]="element.StartPeriodDateOffset"
                            (modelChange)="saleContractsService.updateContractScheduler()"
                        ></syslink-input-number>
                    </div>
                    <div class="col-6">
                        <div class="h-100 d-flex align-items-center">
                            <syslink-switch
                                class="w-100"
                                label="Before/After"
                                [(model)]="element.IsStartPeriodDateOffsetPositive"
                                (modelChange)="saleContractsService.updateContractScheduler()"
                            ></syslink-switch>
                        </div>
                    </div>
                </div>
                <div class="my-2">
                    <syslink-switch
                        label="Automatically count"
                        [(model)]="element.AutomaticallyValidated"
                        (modelChange)="$event === false ? element.AutomaticallySend = false : {}"
                    ></syslink-switch>
                </div>
            </div>
            <div class="col-6">
                <div class="h-100 d-flex flex-column justify-content-between">
                    <syslink-select
                        name="Periodicity"
                        label="Periodicity"
                        [store]="periodicitiesService.store"
                        displayKey="Name"
                        [(model)]="element.PeriodicityId"
                        (modelChange)="saleContractsService.updateContractScheduler()"
                    ></syslink-select>
                    <div class="my-2">
                        <syslink-switch
                            *ngIf="element.AutomaticallyValidated"
                            label="Automatically send"
                            [(model)]="element.AutomaticallySend"
                        ></syslink-switch>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>