<dx-drawer id="drawer" openedStateMode="shrink" template="template" [opened]="opened">
    <div *dxTemplate="let data of 'template'" style="width: 250px" class="bg-secondary">

        <syslink-link url="/" class="d-flex justify-content-center border-bottom border-white">
            <img src="/assets/logo-white.png" class="p-2 img-fluid w-75" />
        </syslink-link>

        <dx-tree-view *ngIf="drawerItems.length" id="drawer-tree-view" [items]="drawerItems" keyExpr="id" (onItemClick)="onItemClick($event)" itemTemplate="drawerItem" [focusStateEnabled]="false" [activeStateEnabled]="false">
            <div *dxTemplate="let item of 'drawerItem'">

                <syslink-link *ngIf="item.path" [url]="item.path">
                    <div class="d-flex align-items-center drawerItemContainer my-1" [class.active]="item.active">
                        <span class="drawerItemIcon text-white me-2 text-center py-2"><i class="{{item.icon || 'fa-solid fa-gear'}}"></i></span>
                        <span class="drawerItemText fw-bold">{{item.text | translate}}</span>
                    </div>
                </syslink-link>

                <div *ngIf="!item.path" class="d-flex align-items-center drawerItemContainer my-1" [class.active]="item.active">
                    <span class="drawerItemIcon text-white me-2 text-center py-2"><i class="{{item.icon || 'fa-solid fa-gear'}}"></i></span>
                    <span class="drawerItemText fw-bold">{{item.text | translate}}</span>
                </div>

            </div>
        </dx-tree-view>
    </div>
    <div id="mainContent" class="h-100">
        <dx-scroll-view [useNative]="true">
            <ng-content></ng-content>
        </dx-scroll-view>
    </div>
</dx-drawer>