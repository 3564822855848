import { Component, Input } from '@angular/core';
import { UserGroup } from '../user-group.model';
import { BaseModalComponent } from 'projects/libraries/syslink-components/src/lib/modal/base-modal/base-modal.component';
import { UserGroupsService } from '../user-groups.service';

@Component({
  selector: 'app-user-group-tagbox-modal',
  templateUrl: './user-group-tagbox-modal.component.html',
  styleUrls: ['./user-group-tagbox-modal.component.scss']
})
export class UserGroupTagboxModalComponent extends BaseModalComponent<UserGroup> {

  @Input() public title: string = "Select user group";
  @Input() public content?: string;

  constructor(public userGroupsService: UserGroupsService) {
    super();
  }
  public reload(userGroups: UserGroup[] = []) {
    if (!this.modal) return;
    this.modal.data.UserGroups = userGroups ;
  }
}