import { Injectable } from '@angular/core';
import { LoadOptions } from 'devextreme/data';
import { PurchaseDocumentHeaderStatusesService } from '../../purchase-documents/purchase-document-header-status/purchase-document-header-statuses.service';
import { PurchaseOrderHeaderHeaderStatus } from './purchase-order-header-header-status.model';

@Injectable({
  providedIn: 'root'
})
export class PurchaseOrderHeaderHeaderStatusesService extends PurchaseDocumentHeaderStatusesService<PurchaseOrderHeaderHeaderStatus> {
  public override url: string = 'PurchaseOrderHeaderHeaderStatus';
  public override defaultOptions: LoadOptions = {
    expand: ["StatusId"]
  };

}
