import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NotificationsService, PageComponent, SelectComponent } from 'projects/libraries/syslink-components/src/public-api';
import { ThirdAddressService } from '../../../thirds/thirds/third-addresses/third-addresses/third-addresses.service';
import { Third } from '../../../thirds/thirds/third.model';
import { ThirdsService } from '../../../thirds/thirds/thirds.service';
import { Document } from '../documents/document.model';
import { DocumentData } from './document-data.model';
import { DocumentDatasService } from './document-datas.service';
import { ThirdContactInformationsService } from '../../../thirds/thirds/third-contacts/third-contact-informations/third-contact-informations.service';

@Component({
  selector: 'app-document-data',
  templateUrl: './document-data.component.html',
  styleUrls: ['./document-data.component.scss']
})
export class DocumentDataComponent extends PageComponent{
  @Input() public element: DocumentData = new DocumentData();
  @Output() public elementChange: EventEmitter<DocumentData> = new EventEmitter<DocumentData>();
  @Output() public elementUsedChange: EventEmitter<any> = new EventEmitter<any>();

  @Input() public canEdit: boolean = true;
  @Input() public showDelivery: boolean = false;
  @Input() public third: Third = new Third();
  @Input() public documentType?: string;
  @Input() public detailsUrl: string = "/thirds/customers/";
  @Input() public label: string = 'Third';
  @Input() public documentId: Document = new Document();

  @Input() public filter: string | string[] = [];

  @ViewChild('ThirdSelect') public ThirdSelect?: SelectComponent;
  // @ViewChild('selectAddress') public selectAddress?: SelectComponent;

  public thirdContacts: Third[] = [];
  public expand: string | string[] = ['Roles', 'ContactInformations.AddressId']
  constructor(
    public thirdsService: ThirdsService,
    public thirdAddressService: ThirdAddressService,
    private documentDatasService: DocumentDatasService,
    public thirdContactInformationsService: ThirdContactInformationsService
  ) {
    super();
  }

  // Third
  // -----
  public async onThirdChanged(thirdId?: Third) {
    if (this.element.ThirdId.Id == thirdId?.Id) return;
    // New DocumentData
    if (thirdId != null) {
      if (thirdId.Blocked == true) {
        NotificationsService.sendErrorMessage("Third is blocked");
        return;
      }
      this.element.ThirdId = thirdId;
      // // Remove old document data
      // if (this.element.Id)
      //   await this.documentDatasService.remove(this.element.Id);

      // Create new document data
      this.element = await this.documentDatasService.getInstance(undefined, this.documentType, this.documentId, thirdId);
      this.element.VatNumber = this.element.ThirdId.VatNumber;
      // this.element.AddressId = this.element.AddressId;
    }

    if (this.element.ThirdId?.ThirdContacts == null) {
      if (this.element.ThirdId && this.element.ThirdId.Id != null) {
        this.element.ThirdId = await this.thirdsService.findByID(this.element.ThirdId.Id);
        await this.onContactChanged(this.element.ThirdId.Id);
      }
    }
    if (this.element.ContactId == null && this.element.ThirdId != undefined && this.element.ThirdId.Id) {
      this.element.ContactId = await this.thirdsService.findByID(this.element.ThirdId.Id);
    }
  }

  public async onAddressesChanged(addressId: any) {
    if (!addressId) return;
    if (this.element.AddressId?.Id != addressId.Id) {
      this.element.AddressId = addressId;
      this.elementChange.emit(this.element);
    }
  }

  // Contacts
  // --------
  public async onContactChanged(contactId: any) {
    if (this.element.ThirdId.ThirdContacts == undefined) return;
    const third = this.element.ThirdId.ThirdContacts.find((contact: any) => contact.Id == contactId);
    if (!third) return;
    if (third.Blocked == true) {
      NotificationsService.sendErrorMessage("Third is blocked");
      return;
    }
    this.element.ContactId.Id = contactId;

    if (third) {
      this.element.ContactId = third;
    }
    this.elementChange.emit(this.element);
  }
  public onUsedChange(bool: boolean, type: string) {
    this.elementUsedChange.emit({ used: bool, type: type, element: this.element });
  }
}
