import { BasicEntity } from "projects/erp-app/src/app/core/models/basic-entity";
import { ThirdContactInformation } from "../third-contact-informations/third-contact-information.model";

export class ThirdContactType extends BasicEntity {
    public ContactInformations?: ThirdContactInformation;
    public Icon?:string;
   
    constructor(entity?: Partial<ThirdContactType>) {
        super(entity);
        Object.assign(this, entity);
    }
}
