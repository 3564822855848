import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'syslink-kanban-card',
  templateUrl: './kanban-card.component.html',
  styleUrls: ['./kanban-card.component.scss']
})
export class KanbanCardComponent {
  @Input() public element: any = {};
  @Output() elementChange: EventEmitter<any> = new EventEmitter<any>();

}
