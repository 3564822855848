<div class="row">

  <div class="col-{{reportType === 'SaleContract' ? 4 : 6}}">
    <syslink-input-text
      name="Subject"
      label="Subject"
      [(model)]="element.Subject"
      [disabled]="disabled"
    ></syslink-input-text>
  </div>

  <div
    *ngIf="reportType === 'SaleContract'"
    class="col-2"
  >
    <syslink-input-date
      *ngIf="element.ExtensionDate?.getTime() != element.Deadline?.getTime() && element.ExtensionDate && element.ExtensionDate.getFullYear() > 1 && showExtensionDate"
      name="Validity"
      label="Extension date"
      [(model)]="element.ExtensionDate"
      [disabled]="true"
      type="date"
      format="date"
    ></syslink-input-date>
  </div>

  <div class="col-3">
    <ng-container *ngIf="reportType === 'SaleOrder' || reportType === 'SaleInvoice' || reportType === 'SaleCreditNote'">
      <div class="row">
        <div class="col-6">
          <div class="totalPriceLabel fw-bold py-2 text-end">
            {{'Remaining amount' | translate }} :
          </div>
        </div>
        <div class="col-6">
          <div class="totalPriceValue py-2 text-white text-center bg-{{getAmountRemainingColor()}}">
            {{ (element.AmountRemaining ?? 0) | syslinkCurrency:subModuleCode:'Precision.'+reportType+'Header.AmountRemaining' }}
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="reportType === 'SaleQuote'">
      <div class="row">
        <div class="col-6">
          <div class="totalPriceLabel fw-bold py-2 text-end">
            {{'TotalMargin' | translate }} :
          </div>
        </div>
        <div class="col-6">
          <div class="totalPriceValue py-2 bg-secondary text-white text-center">
            {{ (element.Margin ?? 0) | syslinkCurrency:subModuleCode:'Precision.'+reportType+'Header.ExTaxTotal' }}
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="reportType == 'SaleContract'">
      <div class="row">
        <div class="col-6">
          <div class="totalPriceLabel fw-bold py-2 text-end">
            {{ 'ExTaxTotalInvoiced' | translate }} :
          </div>
        </div>
        <div class="col-6">
          <div class="totalPriceValue py-2 bg-secondary text-white text-center">
            {{ element.ExTaxTotalInvoiced | syslinkCurrency:subModuleCode:'Precision.SaleInvoiceHeader.ExTaxTotal' }}
          </div>
        </div>
      </div>
    </ng-container>
  </div>

  <div class="col-3">
    <div class="row">
      <div class="col-6">
        <div class="totalPriceLabel fw-bold py-2 text-end">
          {{ 'ExTaxTotalPrice' | translate }} :
        </div>
      </div>
      <div class="col-6">
        <div class="totalPriceValue py-2 bg-secondary text-white text-center">
          {{ element.ExTaxTotal | syslinkCurrency:subModuleCode:'Precision.'+reportType+'Header.ExTaxTotal' }}
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row">

  <div class="col-{{reportType === 'SaleContract' ? 2 : 3}}">
    <syslink-input-date
      name="Date"
      [label]="(reportType === 'SaleContract'?(reportType + '.Date') : 'Date')|translate"
      [(model)]="element.Date"
      [disabled]="(reportType === 'SaleQuote' || reportType === 'SaleInvoice' || reportType === 'SaleCreditNote') && disabled"
      (modelChange)="onDateChanged($event)"
      type="date"
    ></syslink-input-date>
  </div>

  <div class="col-{{reportType === 'SaleContract' ? 2 : 3}}">
    <syslink-input-date
      *ngIf="element.Deadline && reportType != 'SaleOrder'"
      name="Validity"
      [label]="(reportType + '.Validity') | translate"
      [(model)]="element.Deadline"
      (modelChange)="onChangeDeadLine()"
      [disabled]="reportType!='SaleContract' && (reportType === 'SaleQuote' || reportType === 'SaleInvoice' || reportType === 'SaleCreditNote' || disabled)"
      type="date"
      format="date"
    ></syslink-input-date>
  </div>

  <div
    *ngIf="reportType === 'SaleContract'"
    class="col-2"
  >
    <syslink-input-text
      *ngIf="element.Date && element.ExtensionDate && dateDiff != ''"
      name="Month"
      label="Month"
      [model]="dateDiff"
      [disabled]="true"
      [labelMode]="'hidden'"
    ></syslink-input-text>
  </div>

  <div class="col-3">
    <ng-container *ngIf="reportType === 'SaleOrder' || reportType === 'SaleInvoice' || reportType === 'SaleCreditNote'">
      <div class="row">
        <div class="col-6">
          <div class="totalPriceLabel fw-bold py-2 text-end">
            {{'Amount received' | translate }} :
          </div>
        </div>
        <div class="col-6">
          <div class="totalPriceValue py-2 text-white text-center bg-{{element.AmountReceived != 0 ? 'success' : 'secondary'}}">
            {{(element.AmountReceived??0) | syslinkCurrency:subModuleCode:'Precision.'+reportType+'Header.AmountReceived' }}
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="reportType === 'SaleQuote'">
      <div class="row">
        <div class="col-6">
          <div class="totalPriceLabel fw-bold py-2 text-end">
            {{'Margin (%)' | translate }} :
          </div>
        </div>
        <div class="col-6">
          <div class="totalPriceValue py-2 bg-secondary text-white text-center">
            {{ (element.MarginPercent ?? 0) }}&nbsp;%
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="reportType == 'SaleContract'">
      <div class="row">
        <div class="col-6">
          <div class="totalPriceLabel fw-bold py-2 text-end">
            {{ 'InTaxTotalInvoiced' | translate }} :
          </div>
        </div>
        <div class="col-6">
          <div class="totalPriceValue py-2 bg-secondary text-white text-center">
            {{ element.InTaxTotalInvoiced | syslinkCurrency:subModuleCode:'Precision.SaleInvoiceHeader.InTaxTotal' }}
          </div>
        </div>
      </div>
    </ng-container>
  </div>

  <div class="col-3">
    <div class="row">
      <div class="col-6">
        <div class="totalPriceLabel fw-bold py-2 text-end">
          {{ 'InTaxTotalPrice' | translate }} :
        </div>
      </div>
      <div class="col-6">
        <div class="totalPriceValue py-2 bg-secondary text-white text-center">
          {{ element.InTaxTotal | syslinkCurrency:subModuleCode:'Precision.'+reportType+'Header.InTaxTotal' }}
        </div>
      </div>
      <ng-container *ngIf="reportType != 'SaleQuote' && reportType != 'SaleCreditNote'">
        <div class="col-4"></div>
        <div class="col-4">
          <syslink-input-number
            [disabled]="true"
            label="TotalMargin"
            [labelMode]="'static'"
            [model]="element.Margin"
            format="#0.00 €"
          ></syslink-input-number>
        </div>
        <div class="col-4">
          <syslink-input-number
            [disabled]="true"
            label="Margin (%)"
            [labelMode]="'static'"
            [model]="(element.MarginPercent??0)/100"
            format="#0 %"
          ></syslink-input-number>
        </div>
      </ng-container>
    </div>
  </div>
</div>