import { BasicEntity } from "../../../core/models/basic-entity";
import { SubModule } from "../../../base/modules/sub-modules/sub-module.model";

export class Module extends BasicEntity {
    public description?: string;
    public SubModules?: SubModule[];
    public Enabled: boolean = false;

    constructor(basic?: Module) {
        super();
        Object.assign(this, basic);
    }
}
