import { Entity } from "../../../core/models/entity";
import { Third } from "../../../thirds/thirds/third.model";
import { WorkType } from "../work-types/work-type.model";

export class Work extends Entity {

    public TypeId: WorkType = new WorkType();
    public ThirdId?: Third;
    public Description?: string;
    public StartDate: Date = new Date();
    public StartDateDate?: Date;
    public StartDateHours?: number;


    constructor(entity?: Work) {
        super();
        Object.assign(this, entity);
    }
}
