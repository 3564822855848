import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-creditsafe-financial-statements-percent-col',
  templateUrl: './creditsafe-financial-statements-percent-col.component.html',
  styleUrls: ['./creditsafe-financial-statements-percent-col.component.scss']
})
export class CreditsafeFinancialStatementsPercentColComponent implements OnInit {
  @Input() public leftValue?: number;
  @Input() public rightValue?: number;

  public computedValue?: number;

  ngOnInit(): void {
    if (this.leftValue && this.rightValue) {
      this.computedValue = (Math.round((((100 / this.rightValue) * this.leftValue) - 100) * 10)) / 10;
    }
  }

  public getAbsoluteValue(value: number): number {
    return Math.abs(value);
  }
}