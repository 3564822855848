import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Task } from '../task.model';
import { ConfirmModalComponent, NotificationsService, PageComponent, SyslinkToolbarActionButton, TabsComponent } from 'projects/libraries/syslink-components/src/public-api';
import { TaskStatus } from '../../task-statuses/task-status.model';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ActivatedRoute } from '@angular/router';
import { ThirdsService } from 'projects/erp-app/src/app/thirds/thirds/thirds.service';
import { TaskActionCode, TasksService } from '../tasks.service';
import { DocumentStatusChangedEvent, DocumentStatusesComponent } from 'projects/erp-app/src/app/base/documents/document-statuses/document-statuses.component';
import { TaskTaskStatusesService } from '../../task-task-statuses/task-task-statuses.service';
import { TaskStatusesService } from '../../task-statuses/task-statuses.service';
import { WorkTimeModalComponent } from '../../../work-times/work-times/work-time-modal/work-time-modal.component';
import { WorkTimesService } from '../../../work-times/work-times/work-times.service';
import { WorkTime } from '../../../work-times/work-times/work-time';
import { WorkTimeGridComponent } from '../../../work-times/work-times/work-time-grid/work-time-grid.component';
import { SaleInvoiceGridComponent } from 'projects/erp-app/src/app/sales/sale-invoices/sale-invoice-grid/sale-invoice-grid.component';
import { SyslinkColumn } from 'projects/libraries/syslink-components/src/lib/helpers/SyslinkColumn';
// import { AppointmentGridComponent } from '../../../appointments/appointments/appointment-grid/appointment-grid.component';
// import { AppointmentModalComponent } from '../../../appointments/appointments/appointment-modal/appointment-modal.component';
// import { Appointment } from '../../../appointments/appointments/appointment';
// import { AppointmentsService } from '../../../appointments/appointments/appointments.service';
import { TaskFormComponent } from '../task-form/task-form.component';
// import { AppointmentSchedulerComponent } from '../../../appointments/appointments/appointment-scheduler/appointment-scheduler.component';
import { SaleInvoiceSelectModalComponent } from 'projects/erp-app/src/app/sales/sale-invoices/sale-invoice-select-modal/sale-invoice-select-modal.component';
import { BooleanCellData } from 'projects/libraries/syslink-components/src/lib/grid/cells/boolean-cell/boolean-cell.component';
import { SaleInvoicesService } from 'projects/erp-app/src/app/sales/sale-invoices/sale-invoices/sale-invoices.service';
import { DynamicPriceInvoicingComponent } from '../../dynamic-price-invoicing/dynamic-price-invoicing.component';

@Component({
  selector: 'app-task-detail',
  templateUrl: './task-detail.component.html',
  styleUrls: ['./task-detail.component.scss']
})
export class TaskDetailComponent extends PageComponent implements OnInit, AfterViewInit {
  public element: Task = new Task();

  @ViewChild('form') form?: TaskFormComponent;
  @ViewChild('deleteConfirm') deleteConfirm: ConfirmModalComponent = new ConfirmModalComponent();
  @ViewChild('documentStatuses') documentStatuses: DocumentStatusesComponent = new DocumentStatusesComponent();

  @ViewChild('tabs') tabs: TabsComponent = new TabsComponent();
  @ViewChild('worktimeModal') worktimeModal?: WorkTimeModalComponent;
  @ViewChild('worktimeGrid') worktimeGrid?: WorkTimeGridComponent;
  @ViewChild('saleInvoiceGrid') saleInvoiceGrid?: SaleInvoiceGridComponent;
  @ViewChild('saleInvoiceSelectModal') saleInvoiceSelectModal?: SaleInvoiceSelectModalComponent;
  @ViewChild('dynamicPriceInvoicing') dynamicPriceInvoicing?: DynamicPriceInvoicingComponent;

  // Filter
  // ------
  public saleInvoiceSelectModalFilter: string | string[] = ["CurrentStatusLink.StatusId.Sequence lt 3"];

  // // Appointments
  // // ------------
  // @ViewChild('appointmentModal') appointmentModal?: AppointmentModalComponent;
  // @ViewChild('appointmenGrid') appointmenGrid?: AppointmentGridComponent;
  // public appointmentColumns: SyslinkColumn[] = [
  //   new SyslinkColumn({ field: "StartDateDay", label: "Start date", type: "date", width: 120 }),
  //   new SyslinkColumn({ field: "EndDate", label: "End date", type: "datetime", width: 120 }),
  //   new SyslinkColumn({ field: "Duration", label: "Duration", type: "number", cellTemplate: 'duration-cell', width: 100 }),
  //   new SyslinkColumn({ field: "UserId.ThirdId.Fullname", label: "User" }),
  // ];
  // public appointmentFilters: string | string[] = "";

  // @ViewChild('scheduler') scheduler?: AppointmentSchedulerComponent;
  // @ViewChild("workTimeGrid") workTimeGrid?: WorkTimeGridComponent;

  public WorktimeColumns: SyslinkColumn[] = [];

  // Invoices
  // --------
  @ViewChild('invoiceGridComponent') invoiceGridComponent: SaleInvoiceGridComponent = new SaleInvoiceGridComponent(this.saleInvoicesService);
  public SaleInvoiceColumns: SyslinkColumn[] = [
    new SyslinkColumn({ field: "No", label: "GRID.CAPTION.NO", width: 150, sortIndex: 2, sortDirection: 'desc' }),
    new SyslinkColumn({ field: "Subject", label: "Subject" }),
    new SyslinkColumn({ field: "CurrentStatusLink.StatusId.Name", label: "Status", cellTemplate: 'statusText-cell', data: { getStatusLabel: this.invoiceGridComponent.getStatusCellLabel, getStatusDate: this.invoiceGridComponent.getStatusCellDate, getStatusClass: this.invoiceGridComponent.getStatusCellClass, getStatusColor: this.invoiceGridComponent.getStatusCellColor }, width: 120 }),
    new SyslinkColumn({ field: "Deadline", label: 'SaleInvoice.Validity', type: "date", cellTemplate: "template-cell", width: 80, data: { template: "TemplateDeadline", currentDate: new Date(), getAmountRemaining: this.invoiceGridComponent.getAmountRemainingCellLabel, getCellOverdue: this.invoiceGridComponent.getCellOverdue } }),
    new SyslinkColumn({ field: "ExternalReference", label: "External reference", visible: true, width: 150 }),
    new SyslinkColumn({ field: "ExTaxTotal", label: "exTaxTotal", type: "number", cellTemplate: 'currency-cell', data: { subModuleCode: 'Sales.Invoice', key: 'Precision.SaleInvoiceHeader.ExTaxTotal' }, width: 80 }),
    new SyslinkColumn({ field: "InTaxTotal", label: "Total", type: "number", cellTemplate: 'currency-cell', data: { subModuleCode: 'Sales.Invoice', key: 'Precision.SaleInvoiceHeader.InTaxTotal' }, width: 80 }),
    new SyslinkColumn({ field: "ContractId.No", label: "Contract", width: 150 }),
    new SyslinkColumn({ field: "PeriodLabel", label: "Period", filterable: false, headerFilterable: false, sortable: false }),
    new SyslinkColumn({ field: "ResponsibleUserId.Fullname", label: "Responsible", width: 120 }),
    new SyslinkColumn({ field: "Date", label: "Date", type: "date", visible: false, width: 150 }),
    new SyslinkColumn({ field: "AmountRemaining", label: 'Remaining amount', type: "number", visible: false, cellTemplate: 'currency-cell', data: { subModuleCode: 'Sales.Invoice', key: 'Precision.SaleInvoiceHeader.AmountRemaining' }, width: 80, filterable: false, headerFilterable: false, sortable: false }),
    new SyslinkColumn({ field: "CurrentStatusLink.StatusId.Sequence", label: 'status order', sortIndex: 1, sortDirection: 'asc', width: 0, visible: false, filterable: false, headerFilterable: false, sortable: false })
  ];

  constructor(
    private ngxUiLoaderService: NgxUiLoaderService,
    public override activatedRoute: ActivatedRoute,
    public thirdsService: ThirdsService,
    public tasksService: TasksService,
    private taskStatusesService: TaskStatusesService,
    private taskTaskStatusesService: TaskTaskStatusesService,
    private workTimesService: WorkTimesService,
    // private appointmentsService: AppointmentsService,
    private saleInvoicesService: SaleInvoicesService
  ) {
    super();
  }

  override ngAfterViewInit(): void {
    super.ngAfterViewInit();
    this.selectTab();
    
    this.WorktimeColumns = [
      new SyslinkColumn({ field: "TypeId.Name", label: "Type", width: 100 }),
      new SyslinkColumn({ field: "StartDate", name: 'StartDate-date', label: "Start date", type: "date", cellTemplate: "date-cell", width: 80 }),
      new SyslinkColumn({ field: "StartDate", name: 'StartDate-hours', label: "Start hours", type: "datetime", cellTemplate: 'time-cell', width: 80 }),
      new SyslinkColumn({ field: "EndDate", name: "EndDate-date", label: "End date", type: "date", cellTemplate: "date-cell", width: 100 }),
      new SyslinkColumn({ field: "EndDate", name: "EndDate-hours", label: "End hours", type: "datetime", cellTemplate: 'time-cell', width: 80 }),
      new SyslinkColumn({ field: "Duration", label: "Duration", type: "number", cellTemplate: 'duration-cell', width: 60 }),
      new SyslinkColumn({ field: "Description", label: "Description", cellTemplate: 'html-cell' }),
      new SyslinkColumn({ field: "UserId.ThirdId.Fullname", label: "User", width: 100 }),
      new SyslinkColumn({ field: "IsBillable", label: "Billable", width: 100, cellTemplate: 'boolean-cell', data: new BooleanCellData({ type: 'box', getColor: this.worktimeGrid?.getIsBillableColor }) }),
      new SyslinkColumn({ field: "IsBilled", label: "Billed", width: 100, cellTemplate: 'boolean-cell', data: new BooleanCellData({ type: 'box', getColor: this.worktimeGrid?.getIsBilledColor, getText: this.worktimeGrid?.getIsBilledText }) }),
      new SyslinkColumn({ field: "TaskId.FormattedName", label: "Task", visible: false }),
    ];
   
    // // Hack for scheduler
    // // TODO: Issue come from ng-content
    // setTimeout(() => { this.scheduler?.scheduler?.refresh(); }, 2000);
    // setTimeout(() => { this.scheduler?.scheduler?.refresh(); }, 4000);
  }

  override ngOnInit(): void {
    this.ngxUiLoaderService.start();
    this.activatedRoute.data.subscribe(async ({ element }) => {
      if (!element.Id) {
        element = await this.tasksService?.insert(await this.tasksService?.format(element));
        this.goToUrl('../' + element.Id);
      }
      else {
        this.element = element;
        await this.refresh();
        this.ngxUiLoaderService.stop();
      }
    });
  }

  private async refresh() {
    this.updateBreadCrumb(this.element.No);
    this.updateToolbar();
    this.initStatusBar();
    // this.reloadAppointment();
    if (this.element.EndDate && this.element.EndDate.getFullYear() <= 1) {
      this.element.EndDate = undefined;
    }
  }

  private selectTab() {
    // if (this.element.IsPlannable) {
    //   this.tabs.selectTab("Planification");
    // }
    if (this.element.IsPerformable) {
      this.tabs.selectTab("Performance");
    }
    else if (this.element.IsBillable) {
      this.tabs.selectTab("Billing");
    }
    else {
      this.tabs.selectTab("Other");
    }
  }

  public async reloadElement() {
    if (!this.element.Id) return;
    this.element = await this.tasksService.findByID(this.element.Id);
    if(this.element.EndDate != undefined && new Date(this.element.EndDate).getFullYear() == 1){
      this.element.EndDate = undefined;
    }
  }

  public async reloadTime() {
    await this.update(false);
    if (!this.element.Id) return;
    const element = await this.tasksService.findByID(this.element.Id);

    this.element.BilledTime = element.BilledTime;
    this.element.BilledTimeState = element.BilledTimeState;
    // this.element.ScheduledTime = element.ScheduledTime;
    // this.element.ScheduledTimeState = element.ScheduledTimeState;
    this.element.WorkedTime = element.WorkedTime;
    this.element.WorkedTimeState = element.WorkedTimeState;
    this.form?.reloadProgressBar();
  }

  public updateToolbar() {
    this.toolbarActions = [];
    this.toolbarActions.push(new SyslinkToolbarActionButton({ code: this.translateService.instant('save'), icon: 'save', text: 'Save', onClick: async () => { await this.update() }, hotkey: 'control.s', visible: this.authService.hasPermission(this.basePermissionKey + '.update') }));
    if (!this.element.Id) return;
    this.toolbarActions.push(new SyslinkToolbarActionButton({ code: this.translateService.instant('delete'), icon: 'trash', text: 'Delete', onClick: () => { this.onDeleteBtnClicked() }, visible: this.authService.hasPermission(this.basePermissionKey + '.delete') }));
  }

  // Document Statuses
  // -----------------
  public initStatusBar() {
    this.taskStatusesService.load().then((statuses: TaskStatus[]) => {
      statuses.map((status: TaskStatus) => {
        status.Color = status.Sequence && this.element.CurrentStatusLink?.StatusId?.Sequence && this.element.CurrentStatusLink?.StatusId?.Sequence < status.Sequence ? "#A0A0A0" : status.Color;
      });
      this.documentStatuses.statuses = statuses;
      this.documentStatuses.documentStatuses = this.element.Statuses || [];
      this.documentStatuses.refreshItems();
    });
  }
  public async onDocumentStatusChanged(event: DocumentStatusChangedEvent) {
    if (!this.authService.hasPermission(this.basePermissionKey + '.update')) {
      NotificationsService.sendErrorMessage("You do not have the required permission!");
      return;
    }
    
    const link = await this.taskTaskStatusesService.getInstance({
      TaskId: this.element,
      StatusId: { Id: event.status.Id },
      Date: new Date()
    })

    // Checking not updating to same status
    // ------------------------------------
    if (this.element.CurrentStatusLink?.StatusId.Id == link.StatusId.Id) {
      NotificationsService.sendInfo("Task already has this status");
    }

    var documentStatus = await this.taskTaskStatusesService.insert(this.taskTaskStatusesService.format(link));
    if (!documentStatus.Id) return;
    documentStatus = await this.taskTaskStatusesService.findByID(documentStatus.Id);
    if (!this.element.Statuses || !documentStatus) {
      throw "This status cannot be created";
    }
    this.element.CurrentStatusLink = documentStatus;
    await this.reloadElement();
    await this.refresh();
  }

  // Save/Update
  // -----------
  public async update(showMessage: boolean = true) {
    if (!this.authService.hasPermission(this.basePermissionKey + '.update')) {
      NotificationsService.sendErrorMessage("You do not have the required permission!");
      return;
    }
    
    if (this.tasksService.validateElementInformations(this.element) == false) {
      throw new Error();
    }

    await this.tasksService.createOrUpdate(this.element);
    await this.tasksService.updateRelations(this.element);

    await this.reloadElement();
    await this.refresh();

    if (showMessage == true) {
      NotificationsService.sendSuccess("Record updated");
    }
  }

  // delete
  // --------------
  public onDeleteBtnClicked() {
    this.deleteConfirm.open();
  }

  public async onDeleteConfirmed() {
    if (!this.element?.Id) return
    this.deleteConfirm.close();
    await this.tasksService?.remove(this.element.Id);
    this.router.navigate(['../'], { relativeTo: this.activatedRoute });
    NotificationsService.sendSuccess("Record deleted");
  }

  public onFormUpdated() {
    this.selectTab();
  }

  // WorkTime
  // --------
  public async onAddWorkTimeButtonClicked() {
    this.worktimeModal?.open(await this.workTimesService.getInstance({
      ThirdId: this.element.ThirdId,
      TaskId: this.element,
    }));
  }

  public async onValidateWorkTimeCreation(e: WorkTime) {
    try {
      await this.update();

      if (!e.Id) {
        await this.workTimesService.insert(await this.workTimesService.format(e));
        NotificationsService.sendSuccess("Record created");
      }
      else {
        await this.workTimesService.update(e.Id, await this.workTimesService.format(e));
        NotificationsService.sendSuccess("Record created");
      }

      this.worktimeModal?.close();
      this.worktimeGrid?.grid?.grid?.instance.refresh();
      await this.reloadElement();
      await this.refresh();
    } catch (_error) { }
  }

  public async onDeletedWorkTime(e: any) {
    await this.reloadElement();
    await this.refresh();
  }

  // Invoicing
  // ---------
  public showInvoiceModal() {
    if (!this.saleInvoiceSelectModal) return;
    this.saleInvoiceSelectModalFilter = [`CurrentStatusLink.StatusId.Sequence lt 3 and ThirdId.Id eq ${this.element.ThirdId?.Id}`];

    this.saleInvoiceSelectModal.content = "Would you like to invoice selected rows?";
    this.saleInvoiceSelectModal.open();
  }

  public getInvoiceRelationFilter() {
    // return `(Lines/any(l:l/WorkTimeId/TaskId/Id eq ${this.element.Id})) or Lines/any(l:l/TaskId/Id eq ${this.element.Id})`;
    let saleInvoiceLineIds: Set<number> = new Set<number>();

    if (this.element.SaleInvoiceLineId?.Id) {
      saleInvoiceLineIds.add(this.element.SaleInvoiceLineId.Id);
    }

    for (let workTime of this.element.WorkTimeTasks) {
      if (workTime.SaleInvoiceLineId?.Id) {
        saleInvoiceLineIds.add(workTime.SaleInvoiceLineId.Id);
      }
    }

    return `(Lines/any(l:l/Id in (${Array.from(saleInvoiceLineIds).join(',')})))`;
  }

  public async onConfirmModalValidated(event: any) {
    const selectedRows = [this.element];

    if (!this.tasksService.can(TaskActionCode.Invoice, selectedRows)) return;

    let dynamicPriceTasks = selectedRows.filter(task => !task.IsFixedPrice);

    if (dynamicPriceTasks.length > 0) {
      if (this.dynamicPriceInvoicing === undefined) throw new Error("Error with dynamic price invoicing component.");
      NotificationsService.sendInfo("Some tasks are not fixed-price, please select the work time you want to add to the invoice.");
      this.dynamicPriceInvoicing.tasks = dynamicPriceTasks;
      this.dynamicPriceInvoicing.tasksToInvoice = selectedRows.filter(task => task.IsFixedPrice);
      this.dynamicPriceInvoicing.invoiceId = event.Invoice?.Id;
      this.dynamicPriceInvoicing.onAddWorkTimes();
      // Let the dynamic price invoicing component handle the communication with the backend.
    } else {
      await this.tasksService.invoice({
        taskIds: selectedRows.map(e => e.Id!),
        invoiceId: event.Invoice?.Id
      });
      await this.reloadElement();
      await this.onInvoiceCreated();
      NotificationsService.sendSuccess('Invoice created');
    }

    this.saleInvoiceSelectModal?.close();
  }

  public async onInvoiceCreated() {
    await this.reloadElement();

    this.saleInvoiceGrid?.grid?.dataSource.filter(this.getInvoiceRelationFilter());
    this.saleInvoiceGrid?.grid?.dataSource.reload();
  }

  // Appointments
  // ------------
  // public async onAddAppointmentButtonClicked() {
  //   this.appointmentModal?.open(await this.appointmentsService.getInstance({
  //     ThirdId: this.element.ThirdId,
  //     TaskId: this.element,
  //   }));
  // }
  // public reloadAppointment() {
  //   if (this.element.Id) {
  //     this.appointmentFilters = ["TaskId.Id eq " + this.element.Id];
  //   }
  //   else {
  //     this.appointmentFilters = ["TaskId.Id eq 0"];
  //   }
  //   this.appointmenGrid?.reload();
  //   this.scheduler?.scheduler?.reload();
  // }
  // public async onValidateAppointmentCreation(e: Appointment) {
  //   if(!this.appointmentsService.validateElementInformations(e)) return;
  //   try {
  //     await this.update();

  //     if (!e.Id) {
  //       await this.appointmentsService.insert(await this.appointmentsService.format(e));
  //       NotificationsService.sendSuccess("Record created");
  //     }
  //     else {
  //       await this.appointmentsService.update(e.Id, await this.appointmentsService.format(e));
  //       NotificationsService.sendSuccess("Record updated");
  //     }
  //     this.appointmentModal?.close();
  //     await this.reloadElement();
  //     await this.refresh();
  //   } catch (_error) { }
  // }

  // public async onSchedulerSelectionChanged(schedulerSelectDates: SchedulerSelectionChangedData[]) {
  //   this.appointmentModal?.open();

  //   this.appointmentModal?.open(await this.appointmentsService.getInstance({
  //     TaskId: this.element
  //   }));

  //   let startDate;
  //   let endDate;
  //   if (schedulerSelectDates?.length > 0) {
  //     startDate = new Date(Math.min(...schedulerSelectDates.map(e => e.startDate.getTime())));
  //     endDate = new Date(Math.max(...schedulerSelectDates.map(e => e.endDate.getTime())));
  //     if (this.appointmentModal?.modal?.data) {
  //       this.appointmentModal.modal.data.StartDate = startDate;
  //       this.appointmentModal.modal.data.EndDate = endDate;
  //       this.appointmentModal.modal.data.updateDuration();
  //     }
  //   }
  // }
}
