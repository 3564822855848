<syslink-title text="Sale creditNotes"></syslink-title>

<app-sale-credit-note-grid
    #grid
    [permissionKey]="'list'"
    [canDelete]="false"
    [items]="items"
    (add)="onAddButtonClicked()"
    [contextMenuItems]="contextMenuItems"
></app-sale-credit-note-grid>

<app-document-create-modal
    #initModal
    title="Create Credit Note"
    [thirdFilter]="customerFilter"
    detailsUrl="/thirds/customers/"
    (validate)="onCreateValidated($event)"
></app-document-create-modal>
<syslink-confirm-modal
    #confirmModal
    (onValidate)="onConfirmModalValidated($event)"
></syslink-confirm-modal>
<app-sale-credit-note-status-select-modal
    #statusModal
    (validate)="onConfirmModalValidated($event)"
></app-sale-credit-note-status-select-modal>
<app-payments
    *ngIf="paymentElement && paymentDocument"
    #paymentModal
    [reportType]="'SaleInvoice'"
    [showGrid]="false"
    [element]="paymentElement"
    [document]="paymentDocument"
    (paymentsChange)="grid.refresh()"
></app-payments>