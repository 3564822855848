import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ModalComponent } from '../modal.component';

@Component({
  selector: 'syslink-confirm-password',
  templateUrl: './confirm-password.component.html',
  styleUrls: ['./confirm-password.component.scss']
})
export class ConfirmPasswordComponent {
  @Output() private onValidate: EventEmitter<any> = new EventEmitter<any>();
  @Output() private onCancel: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('modal') public modal: ModalComponent = new ModalComponent;

  public password: string = '';
  public title: string = this.translateService.instant('Password confirmation');
  public width: string = '300px';

  constructor(
    private translateService: TranslateService,
  ) { }

  public open(data: any = null): void {
    this.modal.data = data;
    this.modal.open();
  }

  public close(): void {
    this.modal.close();
  }

  public onModalValidate() {
    this.onValidate.emit(this.password);
    this.password = '';
  }

  public onModalCancel() {
    if (this.onCancel.observed) {
      this.onCancel.emit();
    } else {
      this.modal.close()
    }
    this.password = '';
  }
}
