import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NotificationsService } from 'projects/libraries/syslink-components/src/lib/notifications/notifications.service';
import { CustomerCatalog } from '../../customer-catalogs/customer-catalog.model';
import { CustomerCatalogsService } from '../../customer-catalogs/customer-catalog.service';
import { CustomerCatalogDiscountOperationService } from '../../customer-catalogs/customer-catalog-discount-operation.service';
import { CustomerCatalogDiscountOperations } from '../../customer-catalogs/customer-catalog-discount-operations/customer-catalog-discount-operations';
import { Product } from '../product.model';
import { AuthService } from '../../../core/auth/auth.service';
import { InitNewRowEvent as InitNewRowEventGrid } from 'devextreme/ui/data_grid';
import { InitNewRowEvent as InitNewRowEventTree } from 'devextreme/ui/tree_list';
@Component({
  selector: 'app-product-customer-catalogs',
  templateUrl: './product-customer-catalogs.component.html',
  styleUrls: ['./product-customer-catalogs.component.scss']
})
export class ProductCustomerCatalogsComponent {
  @Input() public element: Product = new Product();
  @Output() public elementChange: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private customerCatalogsService: CustomerCatalogsService,
    private customerCatalogDiscountOperationService: CustomerCatalogDiscountOperationService,
    public authService:AuthService
  ) { }

  // New CustomerCatalog
  // -------------------
  public onNewElement(event: InitNewRowEventGrid<CustomerCatalog> | InitNewRowEventTree<CustomerCatalog>) {
    event.promise = new Promise(async (resolve) => {
      event.data = await this.customerCatalogsService.getInstanceWithProduct(this.element, event.data);
      event.data.ProductId = this.element;
      event.data.DiscountId = new CustomerCatalogDiscountOperations({ Value: 0, IsDiscountFixed: false });
      event.data.MarginId = new CustomerCatalogDiscountOperations({ Value: 0, IsDiscountFixed: false });
      event.data.PenaltyId = new CustomerCatalogDiscountOperations({ Value: 0, IsDiscountFixed: false });
      resolve();
    });
  }

  public async onBeforeInserting(event: any) {
    const customerCatalog: CustomerCatalog = event.data;

    const catalogList = (this.element.FilteredCustomerCatalogs)?.find(c => c.CustomerId?.Id == customerCatalog.CustomerId?.Id && c.PriceListId?.Id == customerCatalog.PriceListId?.Id && c.ProductId?.Id == customerCatalog.ProductId?.Id && c.ProductCategoryId?.Id == customerCatalog.ProductCategoryId?.Id);
    if (catalogList != null || catalogList != undefined) {
      NotificationsService.sendInfo("Record exist");
      event.cancel = true;
      return;
    }

    const createdCustomerCatalog = await this.customerCatalogsService.insert(this.customerCatalogsService.format(customerCatalog));

    const discount: any = customerCatalog.DiscountId;
    if (discount) {
      discount.CustomerCatalogId = createdCustomerCatalog.Id;
      discount.DiscountOperationId = 1;
      discount.Order = 2;
      await this.customerCatalogDiscountOperationService.insert(discount);
    }

    const margin: any = customerCatalog.MarginId;
    if (margin) {
      margin.CustomerCatalogId = createdCustomerCatalog.Id;
      margin.DiscountOperationId = 2;
      margin.Order = 1;
      await this.customerCatalogDiscountOperationService.insert(margin);
    }

    const penalty: any = customerCatalog.PenaltyId
    if (penalty) {
      penalty.CustomerCatalogId = createdCustomerCatalog.Id;
      penalty.DiscountOperationId = 3;
      penalty.Order = 3;
      await this.customerCatalogDiscountOperationService.insert(penalty);
    }

    NotificationsService.sendSuccess("Record created");
    this.elementChange.emit(event);
  }

  // Delete CustomerCatalog
  // ----------------------
  public async onBeforeDelete(event: any) {
    if (!event.key) return;
    if (event.key == 1){
      this.elementChange.emit(event);
      return;
    } 
    const customerCatalog: CustomerCatalog = await this.customerCatalogsService.findByID(event.key, { expand: ['ProductId'] });

    if (!customerCatalog.ProductId) {
      NotificationsService.sendInfo("You can not delete customer catalog not linked to this product");
      return;
    }

    await this.customerCatalogsService.remove(event.key)
    NotificationsService.sendSuccess("Record deleted");
    this.elementChange.emit(event);
  }

  // Update CustomerCatalog
  // ----------------------
  public async onBeforeUpdate(event: any) {
    const customerCatalog: CustomerCatalog = JSON.parse(JSON.stringify(event.oldData));
    const newData: Partial<CustomerCatalog> = JSON.parse(JSON.stringify(event.newData));

    if (!event.oldData.ProductId || newData.ProductId) {
      delete newData.ProductId;
      await this.createCustomerCatalogFromNotLinked(customerCatalog, newData);
    } else {
      await this.saveCustomerCatalog(customerCatalog, newData);
    }
    this.elementChange.emit(event);
  }

  public async createCustomerCatalogFromNotLinked(initialCustomerCatalog: CustomerCatalog, newValues: Partial<CustomerCatalog>) {
    const customerCatalog: CustomerCatalog = new CustomerCatalog({
      ...(await this.customerCatalogsService.getInstanceWithProduct(this.element, initialCustomerCatalog)),
      ...newValues
    });
    customerCatalog.ProductId = this.element;
    customerCatalog.ProductCategoryId = undefined;

    // Checking exclusion between Customer and PriceList
    if (customerCatalog.CustomerId !== undefined && customerCatalog.PriceListId !== undefined) {
      if (initialCustomerCatalog.CustomerId && newValues.PriceListId) {
        (<any>customerCatalog.CustomerId) = null;
      }
      if (initialCustomerCatalog.PriceListId && newValues.CustomerId) {
        (<any>customerCatalog.PriceListId) = null;
      }
    }

    // Checking required of Customer and PriceList
    if (!customerCatalog.CustomerId && !customerCatalog.PriceListId) {
      NotificationsService.sendInfo("Price list or customer cannot be empty");
      return;
    }

    // Checking for existing line
    const existingCatalog = this.element.FilteredCustomerCatalogs.find(c =>
      c.CustomerId?.Id == customerCatalog.CustomerId?.Id &&
      c.PriceListId?.Id == customerCatalog.PriceListId?.Id &&
      c.ProductId?.Id == customerCatalog.ProductId?.Id &&
      c.ProductCategoryId?.Id == customerCatalog.ProductCategoryId?.Id &&
      c.Id != customerCatalog.Id);

    if (existingCatalog !== undefined) {
      NotificationsService.sendInfo("Record exist");
      return;
    }

    const createdCustomerCatalog = await this.customerCatalogsService.insert(this.customerCatalogsService.format(customerCatalog));

    const discount: any = customerCatalog.DiscountId;
    if (discount && discount.Id) {
      discount.CustomerCatalogId = createdCustomerCatalog.Id;
      discount.DiscountOperationId = 1
      await this.customerCatalogDiscountOperationService.insert(discount);
    }

    const margin: any = customerCatalog.MarginId;
    if (margin && margin.Id) {
      margin.CustomerCatalogId = createdCustomerCatalog.Id;
      margin.DiscountOperationId = 2
      await this.customerCatalogDiscountOperationService.insert(margin);
    }

    const penalty: any = customerCatalog.PenaltyId
    if (penalty && penalty.Id) {
      penalty.CustomerCatalogId = createdCustomerCatalog.Id;
      penalty.DiscountOperationId = 3
      await this.customerCatalogDiscountOperationService.insert(penalty);
    }

    this.elementChange.emit();
  }

  public async saveCustomerCatalog(initialCustomerCatalog: CustomerCatalog, newValues: Partial<CustomerCatalog>) {
    const customerCatalog: CustomerCatalog = new CustomerCatalog({
      ...(await this.customerCatalogsService.getInstanceWithProduct(this.element, initialCustomerCatalog)),
      ...newValues
    });

    if (!customerCatalog.Id) return;

    // Checking exclusion between Customer and PriceList
    if (customerCatalog.CustomerId !== undefined && customerCatalog.PriceListId !== undefined) {
      if (initialCustomerCatalog.CustomerId && newValues.PriceListId) {
        (<any>customerCatalog.CustomerId) = null;
      }
      if (initialCustomerCatalog.PriceListId && newValues.CustomerId) {
        (<any>customerCatalog.PriceListId) = null;
      }
    }

    // Checking required of Customer and PriceList
    if (!customerCatalog.CustomerId && !customerCatalog.PriceListId) {
      NotificationsService.sendInfo("Price list or customer cannot be empty");
      return;
    }

    this.customerCatalogsService.update(customerCatalog.Id, this.customerCatalogsService.format(customerCatalog));

    if (customerCatalog.DiscountId?.Id) {
      await this.customerCatalogDiscountOperationService.update(customerCatalog.DiscountId?.Id, customerCatalog.DiscountId);
    }
    if (customerCatalog.MarginId?.Id) {
      await this.customerCatalogDiscountOperationService.update(customerCatalog.MarginId?.Id, customerCatalog.MarginId);
    }
    if (customerCatalog.PenaltyId?.Id) {
      await this.customerCatalogDiscountOperationService.update(customerCatalog.PenaltyId?.Id, customerCatalog.PenaltyId);
    }

    this.elementChange.emit();
  }
}
