<div
  *ngIf="reportConfiguration"
  class="mt-3"
>
  <!-- <syslink-toolbar [actions]="toolbarActions"></syslink-toolbar> -->
  <div class="row">
    <syslink-title text="Document customizations - portrait"></syslink-title>

    <!-- Header portrait -->
    <div class="row">
      <div class="col-12">
        <syslink-wysiwyg
          name="header-portrait"
          label="Header"
          [height]="'50mm'"
          [(model)]="reportConfiguration.ReportHeaderPortrait"
          [toolbarActions]="wysiwygToolbarActions"
          [prependName]="true"
        ></syslink-wysiwyg>
      </div>

      <div class="col-3 mt-3">
        <syslink-input-number
          label="Size (mm)"
          [(model)]="reportConfiguration.ReportHeightHeaderPortrait"
          [min]="0"
          [max]="450"
        ></syslink-input-number>
      </div>

      <div class="col-12 mt-3">
        <syslink-wysiwyg
          name="footer-portrait"
          label="Footer"
          minHeight="10mm"
          [height]="getHeight('ReportFooterPortrait')"
          [(model)]="reportConfiguration.ReportFooterPortrait"
          [toolbarActions]="wysiwygToolbarActions"
          [prependName]="true"
        ></syslink-wysiwyg>
      </div>

      <div class="col-3 mt-3">
        <syslink-input-number
          label="Size (mm)"
          [(model)]="reportConfiguration.ReportHeightFooterPortrait"
          [min]="0"
          [max]="50"
        ></syslink-input-number>
      </div>
    </div>
  </div>

  <div class="row">
    <syslink-title text="Document customizations - landscape" class="mt-4"></syslink-title>

    <div class="row">
      <!-- Header landscape -->
      <div class="col-12">
        <syslink-wysiwyg
          name="header-portrait"
          label="Header"
          [height]="'50mm'"
          [(model)]="reportConfiguration.ReportHeaderLandscape"
          [toolbarActions]="wysiwygToolbarActions"
          [prependName]="true"
        ></syslink-wysiwyg>
      </div>
        
      <div class="col-3 mt-3">
        <syslink-input-number
          label="Size (mm)"
          [(model)]="reportConfiguration.ReportHeightHeaderLandscape"
          [min]="0"
          [max]="450"
        ></syslink-input-number>
      </div>

      <!-- Footer landscape -->
      <div class="col-12 mt-3">
        <syslink-wysiwyg
          name="footer-landscape"
          label="Footer"
          minHeight="10mm"
          [height]="getHeight('ReportFooterLandscape')"
          [(model)]="reportConfiguration.ReportFooterLandscape"
          [toolbarActions]="wysiwygToolbarActions"
          [prependName]="true"
        ></syslink-wysiwyg>
      </div>

      <div class="col-3 mt-3">
        <syslink-input-number
          label="Size (mm)"
          [(model)]="reportConfiguration.ReportHeightFooterLandscape"
          [min]="0"
          [max]="50"
        ></syslink-input-number>
      </div>
    </div>
  </div>

  <div class="row">
    <syslink-title text="Comments" class="mt-4" ></syslink-title>

    <!-- Header landscape -->
    <syslink-wysiwyg
      name="comments"
      label="Comments"
      [height]="'50mm'"
      [(model)]="reportConfiguration.Comment"
      [toolbarActions]="wysiwygToolbarActions"
      [prependName]="true"
    ></syslink-wysiwyg>
  </div>

  <syslink-modal
    #codeModal
    (onValidate)="onValidateCodeModalButtonClicked($event)"
  >
    <syslink-textarea
      #codeArea
      height="700"
      (modelChange)="onCodeAreaTextChanged($event)"
    ></syslink-textarea>
  </syslink-modal>
</div>