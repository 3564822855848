import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { BadgeListCellData, ModalComponent } from 'projects/libraries/syslink-components/src/public-api';
import { WorkTimesService } from '../work-times.service';
import { WorkTimeInvoicingLine } from '../../work-time-invoicing-line/work-time-invoicing-line.model';
import { SyslinkColumn } from 'projects/libraries/syslink-components/src/lib/helpers/SyslinkColumn';

@Component({
  selector: 'app-work-times-picker',
  templateUrl: './work-times-picker.component.html',
  styleUrls: ['./work-times-picker.component.scss']
})
export class WorkTimesPickerComponent {
  @Input() public thirdId: number = -1;
  @Output('onAddTimeBtn') private onAddTimeBtn: EventEmitter<WorkTimeInvoicingLine[]> = new EventEmitter<WorkTimeInvoicingLine[]>();
  @Output('onDoubleClickTime') private onDoubleClickTime: EventEmitter<WorkTimeInvoicingLine[]> = new EventEmitter<WorkTimeInvoicingLine[]>();

  @ViewChild('workTimesModal') public workTimesModal: ModalComponent = new ModalComponent;


  public lines: WorkTimeInvoicingLine[] = [];
  public selectedKey: number[] = [];

  public columns: SyslinkColumn[] = [
    // new SyslinkColumn({ field: 'Id', label: "Id" }),
    // new SyslinkColumn({ field: 'ParentId', label: "ParentId" }),
    // new SyslinkColumn({ field: 'ElementId', label: "ElementId" }),
    new SyslinkColumn({ field: 'Reference', label: "Reference" }),
    new SyslinkColumn({ field: 'Description', label: "Description", cellTemplate: 'html-cell', editCellTemplate: 'edit-html-cell' }),
    new SyslinkColumn({ field: 'EstimatedTime', label: "EstimatedTime", type: 'number' }),
    new SyslinkColumn({ field: 'WorkedTime', label: "WorkedTime", type: 'number' }),
    new SyslinkColumn({ field: 'BilledTime', label: "BilledTime", type: 'number' }),
    new SyslinkColumn({ field: 'FixedPrice', label: "FixedPrice", type: 'number' }),
    new SyslinkColumn({ field: "IsFixedPrice", label: "IsFixedPrice", cellTemplate: "boolean-cell", editable: false }),
    new SyslinkColumn({ field: "IsBillable", label: "IsBillable", cellTemplate: "boolean-cell", editable: false }),
    new SyslinkColumn({ field: "Users", label: "Users", type: 'collection', width: 100, cellTemplate: 'badge-list-cell', filterable: false, headerFilterable: false, sortable: false, data: new BadgeListCellData({ contentKey: 'Name' }) }),
    new SyslinkColumn({ field: "UserGroups", label: "UserGroups", type: 'collection', width: 100, cellTemplate: 'badge-list-cell', filterable: false, headerFilterable: false, sortable: false, data: new BadgeListCellData({ contentKey: 'Name' }) }),
  ];

  constructor(
    private workTimesService: WorkTimesService
  ) {
  }
  // Functions
  // ---------
  public async onAddWorkTimes() {
    await this.loadData();
    this.open();
  }
  public open() {
    this.workTimesModal.open();
  }
  public close() {
    this.workTimesModal.close();
  }

  public onSelectedKeyChange(selectedKeys: number[]) {
    this.selectedKey = selectedKeys;
  }

  public async loadData() {
    this.lines = [];
    this.lines = await this.workTimesService.getInvoicingLines(this.thirdId);
    this.lines.map((line: any) => {
      line.Id = line.id,
        line.Type = line.type,
        line.ParentId = line.parentId,
        line.Reference = line.reference,
        line.Description = line.description,
        line.EstimatedTime = line.estimatedTime,
        line.WorkedTime = line.workedTime,
        line.BilledTime = line.billedTime,
        line.FixedPrice = line.fixedPrice,
        line.IsFixedPrice = line.isFixedPrice,
        line.IsBillable = line.isBillable,
        line.Users = line.users,
        line.UserGroups = line.userGroups,
        line.ElementId = line.elementId
    })
  }


  // Format Row
  // ----------
  public onRowPrepared(e: any) {
    if (e.rowType != 'data' || !e.data.type) return;
    switch (e.data.type) {
      case 'Task': this.formatPostRow(e);
        break;
    }
  }
  public formatPostRow(e: any) {
    e.rowElement.classList.add('taskCell');
  }

  // DoubleClick
  // -----------
  public async doubleClick(e: any) {
    this.onDoubleClickTime.emit([e]);
  }

  // Invoicing
  // ---------
  public validate() {
    var result: WorkTimeInvoicingLine[] = [];
    this.selectedKey.forEach(e => {
      result.push(this.lines[e])
    });
    this.onAddTimeBtn.emit(result);
    this.close()
  }
}
