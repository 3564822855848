<table class="table table-hover">
    <thead>
        <tr>
            <th></th>
            <ng-container *ngFor="let item of items; let last = last">
                <th class="text-end">{{creditsafesService.gridGetYear(item.YearEndDate)}}</th>
                <th *ngIf="!last"></th>
            </ng-container>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td class="text-end fw-bold">{{'Currency' | translate}}</td>
            <ng-container *ngFor="let item of items; let last = last">
                <td class="text-end">{{item.Currency || '-'}}</td>
                <th *ngIf="!last"></th>
            </ng-container>
        </tr>
        <tr>
            <td class="text-end fw-bold">{{'Weeks' | translate}}</td>
            <ng-container *ngFor="let item of items; let last = last">
                <td class="text-end">{{item.NumberOfWeeks || '-'}}</td>
                <th *ngIf="!last"></th>
            </ng-container>
        </tr>

        <tr *ngFor="let list of dataList" [class.table-secondary]="isSubTitle(list)">
            <th [class.h6]="isSubTitle(list)">{{list | translate}}</th>

            <ng-container *ngFor="let item of items; let index = index; let last = last">                
                <ng-container *ngIf="!isSubTitle(list)">
                    <td class="text-end">{{creditsafesService.getDataValue(list, item) || '-'}}</td>

                    <td class="text-end" *ngIf="!last">
                        <app-creditsafe-financial-statements-percent-col
                            [leftValue]="creditsafesService.getDataValue(list, item)"
                            [rightValue]="creditsafesService.getDataValue(list, items[index + 1])"
                        ></app-creditsafe-financial-statements-percent-col>
                    </td>
                </ng-container>
                <ng-container *ngIf="isSubTitle(list)">
                    <td></td>
                    <td *ngIf="!last"></td>
                </ng-container>
            </ng-container>
        </tr>
    </tbody>
</table>