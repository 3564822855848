import { Component, Input, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConfirmModalComponent, NotificationsService, PageComponent } from 'projects/libraries/syslink-components/src/public-api';
import { StockMoveOutgoing } from '../stock-move-outgoings/stock-move-outgoings/stock-move-outgoing.model';
import { DocumentCreateModalComponent } from '../../base/documents/document-create-modal/document-create-modal.component';
import { StockMoveOutgoingGridComponent } from '../stock-move-outgoings/stock-move-outgoing-grid/stock-move-outgoing-grid.component';
import { StockMoveOutgoingStatusSelectModalComponent } from '../stock-move-outgoings/stock-move-outgoing-status/stock-move-outgoing-status-select-modal/stock-move-outgoing-status-select-modal.component';
import { ContextMenuItemAction } from 'projects/libraries/syslink-components/src/lib/context-menus/context-menu-item-action';
import { StockMoveOutgoingContextMenuItemActionCode, StockMoveOutgoingsService } from '../stock-move-outgoings/stock-move-outgoings/stock-move-outgoings.service';
import { StockMoveOutgoingHeaderHeaderStatusesService } from '../stock-move-outgoings/stock-move-outgoing-header-header-statuses/stock-move-outgoing-header-header-statuses.service';
import { ContextMenuItemClickEvent } from 'devextreme/ui/file_manager';
import { StockMoveOutgoingHeaderStatus } from '../stock-move-outgoings/stock-move-outgoing-header-statuses/stock-move-outgoing-header-status.model';

@Component({
  selector: 'app-stock-move-customer-outgoings',
  templateUrl: './stock-move-customer-outgoings.component.html',
  styleUrls: ['./stock-move-customer-outgoings.component.scss']
})
export class StockMoveCustomerOutgoingsComponent extends PageComponent {
  @Input() public filter: string | string[] = ["Type eq 'customer'"];
  public expand: Array<string> = ['ThirdId', 'ResponsibleUserId', 'Statuses', 'CurrentStatusLink.StatusId'];

  @Input() public canRefresh: boolean = false;
  @Input() public canDelete: boolean = false;
  @Input() public delete: Function = (e: any) => { };

  @ViewChild('createModal') createModal?: DocumentCreateModalComponent
  @ViewChild('grid') grid?: StockMoveOutgoingGridComponent;
  @ViewChild("confirmModal") confirmModal?: ConfirmModalComponent;
  @ViewChild("statusModal") statusModal?: StockMoveOutgoingStatusSelectModalComponent;

  public customerFilter: string | string[] = ["Roles/any(r:r/Code eq 'customer')"];

  public purchaseOrderHeader: StockMoveOutgoing = new StockMoveOutgoing();
  // ContextMenuItemAction
  // ---------------------
  public contextMenuItems: ContextMenuItemAction[] = [
    { code: StockMoveOutgoingContextMenuItemActionCode.PrintToPDF, text: "Preview to PDF", icon: "print", onItemClick: (e: any) => this.onMenuItemClicked(e), visible: this.authService.hasPermission('stockMove.customeroutgoings.list.contextMenu.preview') },
    { code: StockMoveOutgoingContextMenuItemActionCode.PrintToZIP, text: "Download to ZIP", icon: "print", onItemClick: (e: any) => this.onMenuItemClicked(e), visible: this.authService.hasPermission('stockMove.customeroutgoings.list.contextMenu.preview') },
    { code: StockMoveOutgoingContextMenuItemActionCode.Status, text: "Change status", icon: "edit", onItemClick: (e: any) => this.onMenuItemClicked(e), visible: this.authService.hasPermission('stockMove.customeroutgoings.list.contextMenu.changeStatus') },
  ];

  constructor(
    public stockMoveOutgoingsService: StockMoveOutgoingsService,
    public override activatedRoute: ActivatedRoute,
    private stockMoveOutgoingHeaderHeaderStatusesService: StockMoveOutgoingHeaderHeaderStatusesService
  ) { super(); }

  public onAddButtonClicked() {
    this.createModal?.open();
  };

  public onCreateValidated(element: any) {
    if (!element.ThirdId.Id) {
      NotificationsService.sendErrorMessage("Please select third");
      return;
    }
    if (element.ThirdId.Blocked == true) {
      NotificationsService.sendErrorMessage("Third is blocked");
      return;
    }
    this.router.navigateByUrl('/stockmoves/customeroutgoings/new?ThirdId=' + element.ThirdId.Id );
  }

  // ContextMenu
  // -----------
  public async onMenuItemClicked(e: ContextMenuItemClickEvent) {

    if (!this.grid) return;

    switch (e.itemData.code) {
      // Printing
      // ---------
      case StockMoveOutgoingContextMenuItemActionCode.PrintToPDF:
        this.showConfirmModal("Would you like preview selected rows?", e.itemData);
        break;
      case StockMoveOutgoingContextMenuItemActionCode.PrintToZIP:
        this.showConfirmModal("Would you like download selected rows?", e.itemData);
        break;
      case StockMoveOutgoingContextMenuItemActionCode.Status:
        this.showStatusModal("Would you like change status selected rows?", e.itemData);
        break;
    }
  }

  private showConfirmModal(text: string, data: ContextMenuItemAction) {
    if (!this.confirmModal) return;

    this.confirmModal.title = data.text ? data.text : "Confirm";
    this.confirmModal.content = text;
    this.confirmModal.open({ rows: this.grid?.getSelectedRows(data.rowData), action: data.code });
  }
  private showStatusModal(text: string, data: ContextMenuItemAction) {
    if (!this.statusModal) return;

    this.statusModal.title = data.text ? data.text : "Confirm";
    this.statusModal.content = text;
    this.statusModal.open({ rows: this.grid?.getSelectedRows(data.rowData), action: data.code });
  }

  public async onConfirmModalValidated(modalData: { rows: StockMoveOutgoing[], action: string } | any) {
    switch (modalData.action) {
      case StockMoveOutgoingContextMenuItemActionCode.PrintToPDF:
        await this.stockMoveOutgoingsService.printRows(modalData.rows, "pdf", "CustomerOutgoing", "StockMoveCustomerOutgoing");
        break;
      case StockMoveOutgoingContextMenuItemActionCode.PrintToZIP:
        await this.stockMoveOutgoingsService.printRows(modalData.rows, "zip", "CustomerOutgoing", "StockMoveCustomerOutgoing");
        break;
      case StockMoveOutgoingContextMenuItemActionCode.Status:
        await this.updateStatus(modalData.rows, modalData.OutgoingStatus);
        break;
    }

    this.grid?.refresh();
    this.confirmModal?.close();
    this.statusModal?.close();
  }
  // ------------------------------------------------------------------------------------------------

  // Actions
  // -------
  private async updateStatus(selectedRows: StockMoveOutgoing[], status: StockMoveOutgoingHeaderStatus) {
    if (!this.stockMoveOutgoingsService.canUpdateStatus(selectedRows, status)) return;
    selectedRows.sort((a, b) => { return a.Date.getTime() - b.Date.getTime(); });

    for (var element of selectedRows) {
      if (element.Id) {
        const link: any = {
          HeaderId: { Id: element.Id },
          StatusId: { Id: status.Id },
          Date: new Date()
        };
        await this.updateDocumentStatus(link);
      }
    }
    NotificationsService.sendSuccess('Status updated');
  }
  private async updateDocumentStatus(link: any) {
    await this.stockMoveOutgoingHeaderHeaderStatusesService.insert(this.stockMoveOutgoingHeaderHeaderStatusesService.format(link));
  }
}