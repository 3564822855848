import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppInjectorService } from '../../services/app-injector.service';

@Component({
  selector: 'syslink-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.css']
})
export abstract class InputComponent {
  /**
   * Two way binding variable
   */
  @Input() public model: any;
  @Output() public modelChange: EventEmitter<any> = new EventEmitter<any>();

  /**
   * Snakecase input name
   */
  @Input() public name: string = '';

  /**
   * Class
   */
  @Input() public class: string = '';

  /**
   * Input label
   * Will construct readable label from name if not provided
   */
  @Input() public label?: string;

  /**
   * Input styling mode
   */
  @Input() public mode: 'outlined' | 'underlined' | 'filled';

  /**
   * Label styling mode
   */
  @Input() public labelMode: 'static' | 'floating' | 'hidden' = 'floating';

  /**
   * Hide the input if true
   */
  @Input() public hidden: boolean = false;

  /**
   * Disable the input if true
   */
  @Input() public disabled: boolean = false;

  /**
   * On Focus out
   */
  @Output() public focusOut: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    public translateService?: TranslateService
  ) {
    this.mode = AppInjectorService.config.defaultInputStylingMode;
  }

  /**
   * Return label or convert snake case name to human readable string
   * Then translate it
   * @returns The label
   */
  protected getLabel(): string {
    if (!this.label && !this.name.length) return '';
    return this.translateService?.instant(this.label || this.name?.replace(/([A-Z])/g, ' $1')) || this.label || this.name?.replace(/([A-Z])/g, ' $1');
  };
}
