import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Document } from '../documents/document.model';
import { ModalComponent } from 'projects/libraries/syslink-components/src/public-api';
import { ThirdsService } from '../../../thirds/thirds/thirds.service';
import { NumberPlatesService } from '../../../vise/number-plates/number-plates.service';
import { ViewComponent } from 'projects/libraries/syslink-components/src/lib/helpers/view/view.component';


@Component({
  selector: 'app-document-create-modal',
  templateUrl: './document-create-modal.component.html',
  styleUrls: ['./document-create-modal.component.scss']
})
export class DocumentCreateModalComponent extends ViewComponent {
  @Input() public element: Document = new Document();
  @Input() public title: string = "Create";
  @Input() public thirdFilter: string | string[] = ["Roles/any(r:r/Code eq 'supplier')"];
  @Input() public detailsUrl: string = "";
  @Input() public showThird: boolean = true;
  @Input() public showSubject: boolean = true;

  @Output() validate: EventEmitter<Document> = new EventEmitter<Document>();

  @ViewChild('modal') modal?: ModalComponent;

  constructor(
    public thirdsService: ThirdsService,
    public NumberPlatesService: NumberPlatesService
  ) {
    super();
  }

  public open() {
    this.modal?.open();
  }

  public onValidateButtonClicked(e: Document) {
    this.validate.emit(e);
  }

  public onNumberPlateCustomValue(customeValue: string): void {
    if (customeValue === '') return;
    this.element.NumberPlate = customeValue;
  }
}