<app-work-time-grid
    #workTimeGrid
    [permissionKey]="'workTimes.list'"
    [filter]="['ThirdId.Id eq ' + element.Id]"
    (add)="onAddButtonClicked()"
    (rowDoubleClicked)="workTimeModal?.open($event)"
    [columns]="WorktimeColumns"
></app-work-time-grid>
<app-work-time-modal
    #workTimeModal
    (validate)="onValidateModalWorkTimeButtonClicked($event)"
></app-work-time-modal>