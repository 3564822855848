import { Injectable } from '@angular/core';
import { LoadOptions } from 'devextreme/data';
import { ODataService } from '../../core/services/oData.service';
import { ProductCategory } from './product-category.model';

@Injectable({
  providedIn: 'root'
})
export class ProductCategoriesService extends ODataService<ProductCategory> {
  public url = 'ProductCategory';
  public override defaultOptions: LoadOptions = {
    expand: [
      'SaleAccountId',
      'PurchaseAccountId',
    ]
  };

}
