import { Component, EventEmitter, Input, Output } from '@angular/core';
import { WorkTimesService } from '../work-times.service';
import { WorkTime } from '../work-time';
import { SyslinkColumn } from 'projects/libraries/syslink-components/src/lib/helpers/SyslinkColumn';
import { NotificationsService, SyslinkToolbarAction } from 'projects/libraries/syslink-components/src/public-api';
import { BooleanCellData } from 'projects/libraries/syslink-components/src/lib/grid/cells/boolean-cell/boolean-cell.component';
import { ContextMenuItemAction } from 'projects/libraries/syslink-components/src/lib/context-menus/context-menu-item-action';
import { ContextMenuItemClickEvent } from 'devextreme/ui/file_manager';
import { BaseGridComponent } from 'projects/libraries/syslink-components/src/lib/grid/base-grid/base-grid.component';
import { DXCell } from 'projects/libraries/syslink-components/src/lib/grid/cells/dxcell';

@Component({
  selector: 'app-work-time-grid',
  templateUrl: './work-time-grid.component.html',
  styleUrls: ['./work-time-grid.component.scss']
})
export class WorkTimeGridComponent extends BaseGridComponent<WorkTime> {
  @Input() public toolbaActions: SyslinkToolbarAction[] = [];
  @Input() public override storageKey: string = 'work-times';
  @Input() public override detailsUrl: string = '/time-management/work-times';
  @Input() public contextMenuItems: ContextMenuItemAction[] = [];

  @Output() workContextMenuClicked: EventEmitter<ContextMenuItemClickEvent> = new EventEmitter<ContextMenuItemClickEvent>();

  public override canDelete: boolean = false;
  public override canUpdate: boolean = false;

  @Input() public columns: SyslinkColumn[] = [
    new SyslinkColumn({ field: "ThirdId.Fullname", label: "Third", width: 150 }),
    new SyslinkColumn({ field: "TypeId.Name", label: "Type", width: 100 }),
    new SyslinkColumn({ field: "StartDate", label: "Start", type: "datetime", cellTemplate: "datetime-cell", width: 120 }),
    new SyslinkColumn({ field: "EndDate", label: "End", type: "datetime", cellTemplate: "datetime-cell", width: 120 }),
    new SyslinkColumn({ field: "Duration", label: "Duration", type: "number", cellTemplate: 'duration-cell', width: 60, filterable:false, headerFilterable: false }),
    new SyslinkColumn({ field: "Description", label: "Description", cellTemplate: 'html-cell' }),
    new SyslinkColumn({ field: "UserId.ThirdId.Fullname", label: "User", width: 100 }),
    new SyslinkColumn({ field: "IsBillable", label: "Billable", type: 'boolean', width: 100, cellTemplate: 'boolean-cell', data: new BooleanCellData({ type: 'box', getColor: this.getIsBillableColor }) }),
    new SyslinkColumn({ field: "IsBilled", label: "Billed", type: 'boolean', width: 100, cellTemplate: 'boolean-cell', data: new BooleanCellData({ type: 'box', getColor: this.getIsBilledColor, getText: this.getIsBilledText }) }),
    new SyslinkColumn({ field: "TaskId.FormattedName", label: "Task", visible: false }),
  ];

  constructor(
    public workTimesService: WorkTimesService
  ) { super(); }

  public onDeleteButtonClicked(e: any) {
    if (e.data.IsBilled) {
      NotificationsService.sendErrorMessage("Worked time already invoiced")
      e.cancel = true;
      return;
    }
    super.onDeleteClicked(e);
  }

  public getIsBillableColor(cell: DXCell): string {
    if (cell.data == undefined || cell.data.IsBillable == undefined || cell.data.IsBilled == undefined) return '';

    if (cell.data.IsBillable == false) {
      return "gray"
    }
    if (cell.data.IsBillable == true && cell.data.IsBilled == false) {
      return "danger"
    }
    if (cell.data.IsBillable == true && cell.data.IsBilled == true) {
      return "success"
    }
    return '';// 'gray'| 'danger' | 'success'
  }

  public getIsBilledColor(cell: DXCell): string {
    if (cell.data == undefined || cell.data.IsBillable == undefined || cell.data.IsBilled == undefined) return '';

    if (cell.data.IsBillable == false) {
      return ""
    }
    if (cell.data.IsBillable == true && cell.data.IsBilled == false) {
      return "danger"
    }
    if (cell.data.IsBillable == true && cell.data.IsBilled == true) {
      return "success"
    }
    return '';// 'gray'| 'danger' | 'success'
  }

  public getIsBilledText(cell: DXCell): string {
    if (cell.data == undefined || cell.data.IsBillable == undefined || cell.data.IsBilled == undefined) return '';

    if (cell.data.IsBillable == false) {
      return ""
    }
    if (cell.data.IsBillable == true && cell.data.IsBilled == false) {
      return "No";
    }
    if (cell.data.IsBillable == true && cell.data.IsBilled == true) {
      return cell.data.SaleInvoiceLineId.HeaderId.No;
    }
    return '';
  }
}
