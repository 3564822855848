import { Component, Input, OnInit } from '@angular/core';
import { DXCell } from '../dxcell';

@Component({
  selector: 'syslink-day-cell',
  templateUrl: './day-cell.component.html',
  styleUrls: ['./day-cell.component.scss']
})
export class DayCellComponent implements OnInit {
  @Input() cell: DXCell = new DXCell();
  @Input() data: any = {};

  public value: string = '';

  public days: any[] = [
    { "Id": 0, "Value": "" },
    { "Id": 1, "Value": "Sunday" },
    { "Id": 2, "Value": "Monday" },
    { "Id": 3, "Value": "Tuesday" },
    { "Id": 4, "Value": "Wednesday" },
    { "Id": 5, "Value": "Thursday" },
    { "Id": 6, "Value": "Friday" },
    { "Id": 7, "Value": "Saturday" }
  ];

  ngOnInit(): void {
    this.value = (this.days[this.cell.value]).Value ?? "";
  }
}

