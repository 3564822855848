import { DocumentRelation } from "../../../base/documents/document-relations/document-relation.model";
import { Document } from "../../../base/documents/documents/document.model";
import { StockMoveDocumentLine } from "../stock-move-document-lines/stock-move-document-line.model";

export class StockMoveDocument extends Document {
    public override Lines?: StockMoveDocumentLine[];
    public FormattedDocumentPayment?: string;
    public DocumentRelations: DocumentRelation[] = [];
    //-------------------------------------------------------

    constructor(entity?: Partial<StockMoveDocument>) {
        super();
        Object.assign(this, entity);
    }
}
