<div class="row">
    <div
        class="col-4"
        *ngIf="element.Id && element.ThirdDocumentData"
    >
        <app-document-data
            #ThirdDocumentData
            [documentId]="element"
            [documentType]="'PurchaseOrder'"
            [(element)]="element.ThirdDocumentData"
            (elementChange)="documentDataThirdChange($event)"
            [canEdit]="!disabled"
            [third]="element.ThirdId"
            [filter]="thirdDocumentDataFilters"
            (elementUsedChange)="documentDataUsedChange($event)"
            detailsUrl="/thirds/suppliers/"
            [showDelivery]="reportType == 'PurchaseOrder'?true:false"
        ></app-document-data>
    </div>
    <div class="col-4"> </div>
    <div class="col-4">
        <div>
            <syslink-select
                label="Responsible"
                [store]="thirdsService.store"
                filter="UserId ne null"
                detailsUrl="/basics/thirds/thirds/"
                displayKey="Fullname"
                [(model)]="element.ResponsibleUserId"
                [canView]="authService.hasPermission('settings-thirds-thirds')"
                [disabled]="disabled"
            ></syslink-select>
        </div>
        <div
            class="mt-3 p-3 border border-secondary"
            *ngIf="element.ResponsibleUserId"
        >
            <syslink-input-text
                label="Name"
                [(model)]="element.ResponsibleUserId.Fullname"
                [disabled]="true"
            ></syslink-input-text>

            <div *ngIf="element.ResponsibleUserId.DisplayEmail">
                <syslink-input-text
                    label="Email"
                    [disabled]="true"
                    [(model)]="element.ResponsibleUserId.DisplayEmail"
                ></syslink-input-text>
            </div>

            <div *ngIf="element.ResponsibleUserId.DisplayPhoneNumber">
                <syslink-input-text
                    label="Phone"
                    [disabled]="true"
                    [(model)]="element.ResponsibleUserId.DisplayPhoneNumber"
                ></syslink-input-text>
            </div>
        </div>
    </div>
</div>