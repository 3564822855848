import { Component } from '@angular/core';
import { BreadcrumbsService } from './breadcrumbs.service';

export class BreadcrumbItem {
  public text?: string;
  public path?: string;

  constructor(item?: BreadcrumbItem) {
    Object.assign(this, item);
  }
}

@Component({
  selector: 'syslink-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent {
  constructor(
    public breadcrumbsService: BreadcrumbsService
  ) {
    if (!history.state.prevPage) {
      this.breadcrumbsService.reloadItems();
    }
  }
}
