import { Injectable } from '@angular/core';
import { BasicEntity } from '../../core/models/basic-entity';
import { ODataService } from '../../core/services/oData.service';

@Injectable({
  providedIn: 'root'
})
export class BasicService extends ODataService<BasicEntity> {
  public url: string = '';
}
