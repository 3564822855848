import { Component } from '@angular/core';
import { InputComponent } from '../input/input.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'syslink-select-day',
  templateUrl: './select-day.component.html',
  styleUrls: ['./select-day.component.scss']
})
export class SelectDayComponent extends InputComponent {
 
 // @Input() data: any = {};

  public days: any[] = [
    { "Id": 1, "Value": this.translateService.instant("Sunday") },
    { "Id": 2, "Value": this.translateService.instant("Monday") },
    { "Id": 3, "Value": this.translateService.instant("Tuesday") },
    { "Id": 4, "Value": this.translateService.instant("Wednesday") },
    { "Id": 5, "Value": this.translateService.instant("Thursday") },
    { "Id": 6, "Value": this.translateService.instant("Friday") },
    { "Id": 7, "Value": this.translateService.instant("Saturday") }
  ];

  constructor(
    public override translateService: TranslateService
  ) {
    super();
  }

  public onChangeDay(e: any) {
    this.model = e.Id;
    
  }
}
