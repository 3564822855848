<syslink-modal
    #modal
    [title]="title | translate"
    [width]="width"
    [height]="height"
    (onValidate)="onModalValidate($event)"
    (onCancel)="onModalCancel()"
    [cancelText]="cancelText | translate"
    [validateText]="validateText | translate"
>
    <p class="text-center m-0" [innerHTML]="content | translate"></p>
</syslink-modal>