import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { InputComponent } from '../form/input/input.component';

@Component({
  selector: 'syslink-stock-card',
  templateUrl: './stock-card.component.html',
  styleUrls: ['./stock-card.component.scss']
})
export class StockCardComponent {
  @Input() public icon: string = 'user';
  @Input() public number: number = 123;
  @Output() public numberChange: EventEmitter<number> = new EventEmitter<number>();
  @Input() public text: string = 'Quantity';
  @Input() public canEdit: boolean = true;

  @ViewChild('numberInput') public numberInput!: InputComponent

  public isInEditMode: boolean = false;

  public onOpenNumberEditMode(): void {
    if(this.canEdit==false) return;
    this.isInEditMode = true;
   // this.numberInput.focus();
  }

  public onCloseNumberEditMode(): void {
    this.isInEditMode = false;
    //this.numberInput.blur();
  }
}
