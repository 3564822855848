import { Injectable, Injector } from '@angular/core';
import { Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { AppInjectorService } from 'projects/libraries/syslink-components/src/lib/services/app-injector.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FindByIdResolver implements Resolve<any> {
  constructor(
    private injector: Injector,
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<Observable<any>> {
    const id = route.paramMap.get('id');
    const { service, params } = route.data;
    let injectedService: any = AppInjectorService.injector.get(service);

    if (id === 'new') {
      return injectedService.getInstance(params);
    }
    else {
      return injectedService.findByID(Number(id));
    }

  }
}
