import DataSource from 'devextreme/data/data_source';
import { SyslinkDataSourceOptions } from './SyslinkDataSourceOptions';

export class SyslinkDataSource extends DataSource {
  constructor(options: Partial<SyslinkDataSourceOptions>) {
    super({
      store: options.store,
      expand: options.expand,
      filter: options.filter,
      select: options.select
    });
  }
}
