import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {

  constructor(
    private authService: AuthService,
    private router: Router
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
    ): boolean{

      const expectedPermissions = route.data['expectedPermissions'];

      if (AuthService.getToken() !== null) {
        if (
          this.authService.isExpired() 
          // this.authService.getPermissions() !== expectedPermissions.toString()
        ) {          
          return false;
        }      
        return true;
      }
      return false;  
  }
  
}
