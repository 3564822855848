import { Component, ViewChild } from '@angular/core';
import { ModalComponent, PageComponent } from 'projects/libraries/syslink-components/src/public-api';
import { ModuleInfoService } from '../../../base/modules/module-info/module-info.service';
import { ModuleInfo } from '../../../base/modules/module-info/module-info.model';
import { environment } from 'projects/erp-app/src/environments/environment';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent extends PageComponent {

  public moduleInfoSyslink: ModuleInfo = new ModuleInfo();
  public moduleInfoDevExpress: ModuleInfo = new ModuleInfo();
  public urlSyslink = environment.base_url;
  public urlDevExpress = 'www.devexpress.com';

  @ViewChild('versionDetail') public versionDetail: ModalComponent = new ModalComponent();

  constructor(
    private moduleInfoService: ModuleInfoService
  ) {
    super();
  }

  override async ngOnInit(): Promise<void> {
    this.moduleInfoSyslink = (await this.moduleInfoService.load({ filter: ["Name eq 'ApplicationModule'"] }))[0];
    this.moduleInfoDevExpress = (await this.moduleInfoService.load({ filter: ["Name eq 'SystemModule'"] }))[0];
  }
  public onDetailClick() {
    this.versionDetail.open();
  }
}
