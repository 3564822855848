import { Injectable } from '@angular/core';
import { ODataService } from '../../core/services/oData.service';
import { VersionDetail } from './version-detail.model';
import { LoadOptions } from 'devextreme/data';

@Injectable({
  providedIn: 'root'
})
export class VersionDetailsService extends ODataService<VersionDetail> {
  public url = 'VersionDetail';
  public override defaultOptions: LoadOptions = {
    expand: [],
    sort: ['Version']
  };

}
