<div class="row">

  <div class="col-6">
    <syslink-input-text
      name="Subject"
      label="Subject"
      [(model)]="element.Subject"
      [disabled]="disabled"
    ></syslink-input-text>
  </div>

  <div class="col-3">
    <div class="row">
      <div class="col-6">
        <div class="totalPriceLabel fw-bold py-2 text-end">
          {{'Remaining amount' | translate }} :
        </div>
      </div>
      <div class="col-6">
        <div class="totalPriceValue py-2 text-white text-center bg-{{getAmountRemainingColor()}}">
          {{ (element.AmountRemaining ?? 0) | syslinkCurrency:subModuleCode:'Precision.'+reportType+'Header.AmountRemaining' }}
        </div>
      </div>
    </div>
  </div>

  <div class="col-3">
    <div class="row">
      <div class="col-6">
        <div class="totalPriceLabel fw-bold py-2 text-end">
          {{ 'ExTaxTotalPrice' | translate }} :
        </div>
      </div>
      <div class="col-6">
        <div class="totalPriceValue py-2 bg-secondary text-white text-center">
          {{ element.ExTaxTotal | syslinkCurrency:subModuleCode:'Precision.'+reportType+'Header.ExTaxTotal' }}
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-3">
    <syslink-input-date
      name="Date"
      label="Date"
      [(model)]="element.Date"
      [disabled]="disabled"
      type="date"
      (modelChange)="onChangeDate($event)"
    ></syslink-input-date>
  </div>

  <div class="col-3">
    <syslink-input-date
      name="Validity"
      [label]="(reportType + '.Validity') | translate"
      [model]="getDeadline()"
      [disabled]="reportType!='PurchaseOrder' && (reportType === 'PurchaseInvoice' || reportType === 'PurchaseCreditNote' || disabled)"
      type="date"
      format="date"
      (modelChange)="onChangeDeadLine($event)"
      [min]="element.Date"
    ></syslink-input-date>
  </div>

  <div class="col-3">
    <div class="row">
      <div class="col-6">
        <div class="totalPriceLabel fw-bold py-2 text-end">
          {{'Amount paid' | translate }} :
        </div>
      </div>
      <div class="col-6">
        <div class="totalPriceValue py-2 text-white text-center bg-{{element.AmountReceived != 0 ? 'success' : 'secondary'}}">
          {{(element.AmountReceived??0) | syslinkCurrency:subModuleCode:'Precision.'+reportType+'Header.AmountReceived' }}
        </div>
      </div>
    </div>
  </div>

  <div class="col-3">
    <div class="row">
      <div class="col-6">
        <div class="totalPriceLabel fw-bold py-2 text-end">
          {{ 'InTaxTotalPrice' | translate }} :
        </div>
      </div>
      <div class="col-6">
        <div class="totalPriceValue py-2 bg-secondary text-white text-center">
          {{ element.InTaxTotal | syslinkCurrency:subModuleCode:'Precision.'+reportType+'Header.InTaxTotal' }}
        </div>
      </div>
    </div>
  </div>
</div>