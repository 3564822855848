import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UserPermission } from '../user-permission.model';

@Component({
  selector: 'app-user-permission-form',
  templateUrl: './user-permission-form.component.html',
  styleUrls: ['./user-permission-form.component.scss']
})
export class UserPermissionFormComponent {
  @Input() element: UserPermission = new UserPermission();
  @Output() elementChange: EventEmitter<UserPermission> = new EventEmitter<UserPermission>();

  constructor(
  ) { }
}
