import { Injectable } from '@angular/core';
import { BasicEntity } from '../../core/models/basic-entity';
import { ODataService } from '../../core/services/oData.service';

export class Origin extends BasicEntity { }

@Injectable({
  providedIn: 'root'
})
export class OriginsService extends ODataService<Origin> {
  public url: string = 'Origin';
}
