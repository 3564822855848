import { Injectable } from '@angular/core';
import { LoadOptions } from 'devextreme/data';
import { ODataService } from '../../../core/services/oData.service';
import { Periodicity } from './periodicity.model';

@Injectable({
  providedIn: 'root'
})
export class PeriodicitiesService extends ODataService<Periodicity> {
  public override url: string = 'Periodicity';
  
  public override defaultOptions: LoadOptions = {
    expand: [
      'NameTranslationId',
    ]
  };
}
