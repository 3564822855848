import { Injectable } from '@angular/core';
import { IndexingType } from './indexing-type.model';
import { ODataService } from '../../core/services/oData.service';
import { LoadOptions } from 'devextreme/data';

@Injectable({
  providedIn: 'root'
})
export class IndexingTypesService extends ODataService<IndexingType> {
  public override url: string = 'IndexingType';
  
  public override defaultOptions: LoadOptions = {
    expand: [
      'NameTranslationId',
    ]
  };

  public async GetHealthIndex(date: Date): Promise<number|undefined> {
    return (await this.apiService.sendRequest('/api/statbel/GetHealthIndex', 'POST', {
      Month:this.MonthList[date.getMonth()],
      Year:date.getFullYear
    }));
   }

  public MonthList = new Array(
    '',
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
    );
}
