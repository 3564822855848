import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SyslinkColumn } from 'projects/libraries/syslink-components/src/lib/helpers/SyslinkColumn';
import { PageComponent, SyslinkGridOption } from 'projects/libraries/syslink-components/src/public-api';
import { UserPermission } from './user-permission.model';
import { UserPermissionsService } from './user-permissions.service';

@Component({
  selector: 'app-user-permissions',
  templateUrl: './user-permissions.component.html',
  styleUrls: ['./user-permissions.component.scss']
})
export class UserPermissionsComponent  extends PageComponent {
  @Input() filter: string | string[] = "";

  public columns: SyslinkColumn[] = [
    new SyslinkColumn({ field: 'Id', label: 'Id', visible: false }),
    new SyslinkColumn({ field: 'Code', label: 'Code' }),
    new SyslinkColumn({ field: 'Description', label: 'Description'}),
  ];
  public storageKey: string = 'grid-user-permissions';
  constructor(
    public userPermissionsService: UserPermissionsService,
    protected override activatedRoute: ActivatedRoute
  ) {
    super();
  }

  public addNew() {
    this.router.navigate(['new'], { relativeTo: this.activatedRoute });
  }

  public onDoubleClicked(data: UserPermission) {
    this.router.navigate([data.Id], { relativeTo: this.activatedRoute });
  }
}
