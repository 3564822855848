import { Injectable } from '@angular/core';
import { PaymentType } from './payment-type.model';
import { ODataService } from '../../../core/services/oData.service';

@Injectable({
  providedIn: 'root'
})
export class PaymentTypesService extends ODataService<PaymentType> {
  public url = 'PaymentType';

}