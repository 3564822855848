import { Component, Input } from '@angular/core';
import { BaseGridComponent } from 'projects/libraries/syslink-components/src/lib/grid/base-grid/base-grid.component';
import { StockMoveOutgoingLine } from '../stock-move-outgoing-line.model';
import { SelectDescriptor } from 'devextreme/data';
import { SyslinkColumn } from 'projects/libraries/syslink-components/src/lib/helpers/SyslinkColumn';
import { StockMoveOutgoingLinesService } from '../stock-move-outgoing-lines.service';

@Component({
  selector: 'app-stock-move-outgoing-lines-grid',
  templateUrl: './stock-move-outgoing-lines-grid.component.html',
  styleUrls: ['./stock-move-outgoing-lines-grid.component.scss']
})
export class StockMoveOutgoingLinesGridComponent extends BaseGridComponent<StockMoveOutgoingLine> {

  @Input() override storageKey: string = 'stock-move-outgoing-lines';
  @Input() public override expand?: string | string[] = ["HeaderId.CurrentStatusLink", "HeaderId.CurrentStatusLink.StatusId", "ToStockLocationId"];
  @Input() public override select?: SelectDescriptor<any> = ['Id','Quantity'];

  public columns: SyslinkColumn[] = [
    new SyslinkColumn({ field: 'HeaderId.No', label: 'No', sortable: true, sortIndex: 0, sortDirection: 'desc' }),
    new SyslinkColumn({ field: 'Quantity', label: 'Quantity', type:"number"}),
    new SyslinkColumn({ field: 'HeaderId.Date', label: 'Date', type:"date"}),   
    new SyslinkColumn({ field: 'ToStockLocationId.Name', label: 'StockMoveCustomerOutgoing.StockLocation'}),   
 ];

  constructor(
    public stockMoveOutgoingLinesService: StockMoveOutgoingLinesService,
  ) {
    super();
  }
}