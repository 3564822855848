import { Component } from '@angular/core';
import { SaleInvoice } from '../sale-invoices/sale-invoice.model';
import { SaleInvoicesService } from '../sale-invoices/sale-invoices.service';
import { SaleInvoiceLine } from '../sale-invoice-lines/sale-invoice-line.model';
import { SaleInvoiceLinesService } from '../sale-invoice-lines/sale-invoice-lines.service';
import { SaleInvoiceHeaderStatus } from '../sale-invoice-header-statuses/sale-invoice-header-status.model';
import { SaleInvoiceHeaderStatusesService } from '../sale-invoice-header-statuses/sale-invoice-header-statuses.service';
import { SaleInvoiceHeaderHeaderStatus } from '../sale-invoice-header-header-statuses/sale-invoice-header-header-status.model';
import { SaleInvoiceHeaderHeaderStatusesService } from '../sale-invoice-header-header-statuses/sale-invoice-header-header-statuses.service';
import { SaleDocumentDetailsComponent } from '../../sale-documents/sale-documents/sale-document-details/sale-document-details.component';
import { AppInjectorService } from 'projects/libraries/syslink-components/src/lib/services/app-injector.service';
import { SyslinkColumn } from 'projects/libraries/syslink-components/src/lib/helpers/SyslinkColumn';
import { TaxesService } from '../../../accountings/taxes/taxes.service';
import { AccountsService } from '../../../accountings/accounts/accounts.service';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-sale-invoice-details',
  templateUrl: '../../sale-documents/sale-documents/sale-document-details/sale-document-details.component.html',
  styleUrls: ['./sale-invoice-details.component.scss']
})
export class SaleInvoiceDetailsComponent extends SaleDocumentDetailsComponent<
  SaleInvoice,
  SaleInvoicesService,
  SaleInvoiceLine,
  SaleInvoiceLinesService,
  SaleInvoiceHeaderStatus,
  SaleInvoiceHeaderStatusesService,
  SaleInvoiceHeaderHeaderStatus,
  SaleInvoiceHeaderHeaderStatusesService
>{
  protected override loadDependencies() {
    this.saleDocumentService = AppInjectorService.injector.get(SaleInvoicesService);
    this.saleDocumentLinesService = AppInjectorService.injector.get(SaleInvoiceLinesService);
    this.saleDocumentStatusesService = AppInjectorService.injector.get(SaleInvoiceHeaderStatusesService);
    this.saleDocumentHeaderStatusesService = AppInjectorService.injector.get(SaleInvoiceHeaderHeaderStatusesService);

    const taxesService = AppInjectorService.injector.get(TaxesService);
    const accountsService = AppInjectorService.injector.get(AccountsService);
    const translateService = AppInjectorService.injector.get(TranslateService);

    this.reportType = 'SaleInvoice';
    this.subModuleCode = 'Sales.Invoice';
    this.defaultStatusCode = 'Invoice.Proforma';
    this.documentLinesColumns = [
      ...this.saleDocumentService.getDefaultDocumentLineColumns(this.subModuleCode,this.reportType),
      // new SyslinkColumn({ order: 15, field: 'TaxId', label: translateService.instant('Tax'), type: 'string', cellTemplate: 'select-value', editCellTemplate: 'select-cell', data: { service: taxesService, displayKey: 'Alias', filter:"IsVisible eq true"  }, width: 70 }),
      // new SyslinkColumn({ order: 15, field: 'AccountId', label: translateService.instant('Account'), type: 'string', cellTemplate: 'select-value', editCellTemplate: 'select-cell', data: { service: accountsService, displayKey: 'Name' }, width: 70 }),
    ];

  }

  public override canEditDocument(): boolean {
    return !this.hasActiveStatus('Invoice.Ventilated') && !this.hasActiveStatus('Invoice.Canceled');
  }
}