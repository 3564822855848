<ng-container *ngIf="element">
    <syslink-title
        *ngIf="element.No"
        [text]="element.No"
    ></syslink-title>

    <syslink-toolbar [actions]="toolbarActions"></syslink-toolbar>

    <app-document-statuses
        #documentStatuses
        [statuses]="availableStatuses"
        [documentStatuses]="element.Statuses"
        (statusChanged)="onDocumentStatusChanged($event)"
    ></app-document-statuses>

    <div class="mt-3 mb-5">
        <app-sale-document-form
            [(element)]="element"
            [reportType]="reportType"
            [subModuleCode]="subModuleCode"
            (dateChange)="onDateChanged()"
            [disabled]="!canEditDocument()"
            [showExtensionDate]="showExtensionDate"
        ></app-sale-document-form>
    </div>

    <div class="mt-4">
        <syslink-tabs>
            <syslink-tab
                label="Content"
                key="Content"
                [visible]="authService.hasPermission(this.basePermissionKey + '.content.tab.view')"
            >
                <app-sale-document-content
                    [(element)]="element"
                    [reportType]="reportType"
                    [subModuleCode]="subModuleCode"
                    (selectedKeyChange)="onSelectedKeyChange($event)"
                    (elementLineChange)="onElementLineChange($event)"
                    [saleDocumentService]="saleDocumentService"
                    [saleDocumentLinesService]="saleDocumentLinesService"
                    [columns]="documentLinesColumns"
                    [disabled]="!canEditDocument()"
                ></app-sale-document-content>
            </syslink-tab>

            <syslink-tab
                label="Third"
                key="Third"
                [visible]="authService.hasPermission(this.basePermissionKey + '.third.tab.view')"
            >
                <app-sale-document-third
                    #saleDocumentThird
                    [(element)]="element"
                    [reportType]="reportType"
                    (documentData)="update(false)"
                    [disabled]="!canEditDocument()"
                ></app-sale-document-third>
            </syslink-tab>

            <syslink-tab
                *ngIf="reportType != 'SaleContract'"
                [label]="'Finance' | translate"
                key="Finance"
                [visible]="authService.hasPermission(this.basePermissionKey + '.finance.tab.view')"
            >
                <app-sale-document-finance
                    [(element)]="element"
                    [reportType]="reportType"
                    [subModuleCode]="subModuleCode"
                    (paymentsChange)="onPaymentChange($event)"
                ></app-sale-document-finance>
            </syslink-tab>

            <syslink-tab
                *ngIf="reportType == 'SaleContract'"
                [label]="'Schedule'"
                key="Schedule"
                [visible]="authService.hasPermission(this.basePermissionKey + '.scheduler.tab.view')"
            >
                <app-sale-contract-schedule
                    [(element)]="element"
                    (updateRRule)="update(false)"
                ></app-sale-contract-schedule>
            </syslink-tab>
            <syslink-tab
                *ngIf="reportType == 'SaleContract'"
                label="Renewal"
                key="Renewal"
                [visible]="authService.hasPermission(this.basePermissionKey + '.renewal.tab.view')"
            >
                <app-sale-contract-renewal
                    [(element)]="element"
                    (renewalChange)="renewalChange()"
                ></app-sale-contract-renewal>
            </syslink-tab>

            <syslink-tab
                label="Other"
                key="Other"
                [visible]="authService.hasPermission(this.basePermissionKey + '.other.tab.view')"
            >
                <app-sale-document-other
                    #saleDocumentOther
                    [(element)]="element"
                    [reportType]="reportType"
                    (documentDelayChanged)="onDocumentDelayChanged($event)"
                    (globalDiscountChanged)="update()"
                    [disabled]="!canEditDocument()"
                    (onCreateContract)="onTransformTo('Contract','../../contracts/',true,'',selectedKey)"
                ></app-sale-document-other>
            </syslink-tab>
        </syslink-tabs>
    </div>
    <div
        class="mt-4"
        *ngIf="element.Id && reportType!='SaleContract'"
    >
        <app-document-relations
            #saleDocumentRelation
            [reportType]="reportType"
            [documentId]="element.Id"
            [service]="saleDocumentService"
            [documentRelations]="element.DocumentRelations"
            (onRefreshClicked)="reloadDocumentRelation()"
        ></app-document-relations>
    </div>
    <div
        class="mt-4"
        *ngIf="element.Id && reportType=='SaleContract'"
    >
        <app-document-relations
            #saleDocumentRelation
            [reportType]="reportType"
            [documentId]="element.Id"
            [service]="saleDocumentService"
            [documentRelations]="element.DocumentRelations"
            [addSaleInvoice]="addSaleInvoiceFromContract"
            (onRefreshClicked)="reloadDocumentRelation()"
        ></app-document-relations>
    </div>
    <app-mail-modal
        *ngIf="mailModel"
        #emailModal
        [(mailModel)]="mailModel"
        [mailTemplateType]="mailTemplateType"
        [element]="element"
        [reportType]="reportType"
    ></app-mail-modal>
    <syslink-confirm-modal
        #deleteConfirm
        title="Delete"
        content="DeleteConfirmMessage"
        (onValidate)="onDeleteConfirmed()"
        validateText="Confirm"
        cancelText="Cancel"
    ></syslink-confirm-modal>
    <syslink-modal
        #createFromContractModal
        title="Create"
        width="500"
        (onValidate)="onTransformTo('Invoice','../../invoices/', false, '', selectedKey, undefined,createFromContractFromDate,createFromContractToDate)"
        [validateText]="'Create'"
        [cancelText]="'Cancel'"
    >
        <div class="d-flex justify-content-between w-30">
            <syslink-input-date
                name="Start date"
                [label]="'Start date'"
                [(model)]="createFromContractFromDate"
                type="date"
            ></syslink-input-date>
            <syslink-input-date
                name="End date"
                [label]="'End date'"
                [(model)]="createFromContractToDate"
                type="date"
            ></syslink-input-date>
        </div>
    </syslink-modal>

    <syslink-modal
        #addInvoiceFromContractModal
        title="Create"
        width="500"
        (onValidate)="addContractToInvoice()"
        [validateText]="'Create'"
        [cancelText]="'Cancel'"
    >
        <syslink-select
            [label]="'Invoice'"
            [store]="saleInvoicesService.store"
            [filter]="filterLinkContract"
            [(model)]="InvoiceFromContract"
            displayKey="No"
        ></syslink-select>
        <div class="d-flex justify-content-between w-30">
            <syslink-input-date
                name="Start date"
                [label]="'Start date'"
                [(model)]="createFromContractFromDate"
            ></syslink-input-date>
            <syslink-input-date
                name="End date"
                [label]="'End date'"
                [(model)]="createFromContractToDate"
            ></syslink-input-date>
        </div>
    </syslink-modal>

    <app-document-copy-modal
        #copyModal
        (validate)="onCopyButtonClicked($event)"
    ></app-document-copy-modal>
</ng-container>