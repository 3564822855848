<dx-drop-down-button
    id="user-menu"
    icon="user"
    [text]="userName"
    [items]="userMenuItems"

    keyExpr="id"
    displayExpr="text"

    stylingMode="text"
    [hoverStateEnabled]="true"
    [focusStateEnabled]="false" 
    [dropDownOptions]="{ width: 'auto' }"
>
    <dxi-item *ngFor="let item of userMenuItems" >
        <div *dxTemplate (click)="onItemClick(item)">
            <div id="user-menu-{{item.text.toLowerCase()}}" class="py-2 d-flex justify-content-start align-items-center">
                <i class="{{item.icon}} pe-2 ms-1"></i> {{item.text | translate}}
            </div>
        </div>
    </dxi-item>
</dx-drop-down-button>