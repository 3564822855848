import { Component, Input } from '@angular/core';
import { SyslinkColumn } from 'projects/libraries/syslink-components/src/lib/helpers/SyslinkColumn';
import { TaxRatesService } from '../../base/tax-rates/tax-rates.service';
import { SubjugationCategoriesService } from '../subjugation-categories/subjugation-category.service';
import { TaxTypesService } from '../tax-types/tax-type.service';
import { TaxesService } from './taxes.service';
import { BaseGridComponent } from 'projects/libraries/syslink-components/src/lib/grid/base-grid/base-grid.component';
import { Tax } from './tax.model';
import { FilterDescriptor } from 'devextreme/data';

@Component({
  selector: 'app-taxes',
  templateUrl: './taxes.component.html',
  styleUrls: ['./taxes.component.scss']
})
export class TaxesComponent extends BaseGridComponent<Tax>  {

  @Input() storageKey: string = 'basic-tax-grid';

  public columns: SyslinkColumn[] = [
    new SyslinkColumn({ field: 'IsVisible', label: 'Visible', type: 'boolean', showEditorAlways: true, cellTemplate: "icon-cell", editCellTemplate: 'icon-cell', data: { icon: 'fa-light fa-eye', activeClass: "cl-accent", inactiveClass: "cl-gray" }, width: 80 }),
    new SyslinkColumn({ field: 'Code', label: 'Code' }),
    new SyslinkColumn({ field: 'Alias', label: 'Alias' }),
    new SyslinkColumn({ field: 'TaxRateId', label: 'Tax rate', type: 'object', cellTemplate: 'select-value', editCellTemplate: 'select-cell', data: { service: this.taxRatesService, displayKey: 'Name' } }),
    new SyslinkColumn({ field: 'TaxTypeId', label: 'Tax type', type: 'object', cellTemplate: 'select-value', editCellTemplate: 'select-cell', data: { service: this.taxTypesService, displayKey: 'Name' } }),
    new SyslinkColumn({ field: 'SubjugationCategoryId', label: 'Subjugation category', type: 'object', cellTemplate: 'select-value', editCellTemplate: 'select-cell', data: { service: this.subjugationCategoriesService, displayKey: 'Name' } }),
    new SyslinkColumn({ field: 'UsedForSale', label: 'Used for sale', type: 'boolean', showEditorAlways: true }),
  ];

  public override expand: Array<string> = ['TaxRateId', 'TaxTypeId', 'SubjugationCategoryId'];
  public override filter: FilterDescriptor | Array<FilterDescriptor> = "";

  constructor(
    public taxesService: TaxesService,
    public taxTypesService: TaxTypesService,
    public taxRatesService: TaxRatesService,
    public subjugationCategoriesService: SubjugationCategoriesService
  ) {
    super();
    this.taxesService.defaultOptions = {};
  }

  public addNewTax = async (e: any) => {
    let tax = await this.taxesService.getInstance(e.data);
    await this.taxesService.insert(this.taxesService.format(tax));
  }
}

