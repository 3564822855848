<syslink-modal
    #modal
    [title]="title"
    [width]="width"
    (onValidate)="onModalValidate()"
    (onCancel)="onModalCancel()"
>
    <div *ngIf="content">
        {{content | translate}}
    </div>
    <syslink-select
        #selectThird
        label="Thirds"
        [store]="store"
        [displayKey]="displayKey"
        [(model)]="third"
        [items]="items"
        (modelChange)="thirdChange($event)"
        [filter]="filter"
    ></syslink-select>
    <!-- 
        {{filter | json}}
        [expand]="expand"
        [select]="select" -->

    <syslink-autoform
        #autoform
        *ngIf="extraFields"
        [fields]="extraFields"
        [getItems]="getAutoformItems"
        [getStore]="getAutoformStore"
    > </syslink-autoform>

</syslink-modal>

<!-- [items]="element.ThirdId.Addresses"
displayKey="FormattedAddress"
(modelChange)="onAddressesChanged($event)"
[disabled]="!canEdit" -->