import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { SubModule } from '../../../base/modules/sub-modules/sub-module.model';
import { ReportConfiguration } from './report-configuration.model';
import { ReportConfigurationsService } from './report-configuration.service';
import { ModalComponent, NotificationsService, SyslinkToolbarAction, SyslinkToolbarActionButton, TextareaComponent } from 'projects/libraries/syslink-components/src/public-api';
import { EventsService } from 'projects/libraries/syslink-components/src/lib/events/events.service';
import { ModuleEvents, SaveSubmoduleEvent } from '../../../base/modules/modules/module-details/module-details.component';

@Component({
  selector: 'app-report-configurations',
  templateUrl: './report-configurations.component.html',
  styleUrls: ['./report-configurations.component.scss']
})
export class ReportConfigurationsComponent implements OnInit {
  @Input() public subModule?: SubModule;
  public reportConfiguration?: ReportConfiguration;

  @ViewChild('codeModal') codeModal?: ModalComponent;
  @ViewChild('codeArea') codeArea?: TextareaComponent;

  public wysiwygToolbarActions: SyslinkToolbarAction[] = [
    new SyslinkToolbarActionButton({ code: "showCode", icon: 'variable', border: "none", onClick: (e: any) => { this.onHtmlButtonClicked(e) } })
  ];

  constructor(
    private reportConfigurationsService: ReportConfigurationsService,
    private eventsService: EventsService
  ) { }

  async ngOnInit(): Promise<void> {

    this.eventsService.on(ModuleEvents.SaveSubmodule)?.subscribe(async (e: SaveSubmoduleEvent) => {
      if (e.subModule.Code == "Base.General") {
        if (!this.reportConfiguration || !this.reportConfiguration.Id) return;
        await this.reportConfigurationsService.update(this.reportConfiguration.Id, this.reportConfigurationsService.format(this.reportConfiguration));
      }

    });

    await this.loadConfiguration();
  }

  private async loadConfiguration() {
    this.reportConfiguration = (await this.reportConfigurationsService.load({ filter: ['DepartmentId.Id eq 1'] }))[0];
  }


  public getHeight(type: string): string {
    if (!this.reportConfiguration) return "15mm";
    switch (type) {
      case "ReportFooterPortrait":
        return (this.reportConfiguration.ReportHeightFooterPortrait + 10) + "mm";
      case "ReportFooterLandscape":
        return (this.reportConfiguration.ReportHeightFooterLandscape + 10) + "mm";
      default:
        return "15mm";
    }
  }

  public onHtmlButtonClicked(e: any) {
    if (!this.codeArea) return;

    var editorId = e.element.id.split('.')[0];
    this.codeArea.model = undefined;

    switch (editorId) {
      case 'header-portrait':
        this.codeArea.model = this.reportConfiguration?.ReportHeaderPortrait;
        break;
      case 'footer-portrait':
        this.codeArea.model = this.reportConfiguration?.ReportFooterPortrait;
        break;
      case 'header-landscape':
        this.codeArea.model = this.reportConfiguration?.ReportHeaderLandscape;
        break;
      case 'footer-landscape':
        this.codeArea.model = this.reportConfiguration?.ReportFooterLandscape;
        break;
        case 'comments':
        this.codeArea.model = this.reportConfiguration?.Comment;
        break;
      default:
        console.log(editorId);
    }

    this.codeModal?.open({ editorId });
  }

  public onValidateCodeModalButtonClicked(e: any) {
    if (!this.reportConfiguration) return;
    var editorId = e.editorId;
    debugger;
    switch (editorId) {
      case 'header-portrait':
        this.reportConfiguration.ReportHeaderPortrait = this.codeArea?.model;
        break;
      case 'footer-portrait':
        this.reportConfiguration.ReportFooterPortrait = this.codeArea?.model;
        break;
      case 'header-landscape':
        this.reportConfiguration.ReportHeaderLandscape = this.codeArea?.model;
        break;
      case 'footer-landscape':
        this.reportConfiguration.ReportFooterLandscape = this.codeArea?.model;
        break;
        case 'comment':
          this.reportConfiguration.Comment = this.codeArea?.model;
          break;
      default:
        console.log(editorId);
    }

    this.codeModal?.close();
  }

  public onCodeAreaTextChanged(e: string) {
    if (!this.codeArea) return;

    this.codeArea.model = e;
  }

}
