import { BasicEntity } from "../../core/models/basic-entity";
import { AccountType } from "../account-types/account-type.model";

export class Account extends BasicEntity {
    public Taxable?: boolean;
    public Letterable?: boolean;
    public AccountTypeId: AccountType = new AccountType();
    public ParentId?: Account;
    public FormattedName?: string;
    public HasChildren?: boolean;
    constructor(entity?: Partial<Account>) {
        super();
        Object.assign(this, entity);
    }
}