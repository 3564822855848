import { Component, Input, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmModalComponent, ModalComponent, NotificationsService, PageComponent } from 'projects/libraries/syslink-components/src/public-api';
import { ThirdsService } from '../../../thirds/thirds/thirds.service';
import { SaleCreditNotesService } from './sale-credit-notes.service';
import { SaleCreditNote } from './sale-credit-note.model';
import { PaymentsComponent } from '../../../base/payments/payments.component';
import { Payment } from '../../../base/payments/payment.model';
import { ContextMenuItemAction } from 'projects/libraries/syslink-components/src/lib/context-menus/context-menu-item-action';
import { ContextMenuItemClickEvent } from 'devextreme/ui/file_manager';
import { SaleCreditNoteGridComponent } from '../sale-credit-note-grid/sale-credit-note-grid.component';
import { NumberPlatesService } from '../../../vise/number-plates/number-plates.service';
import { SaleContexMenuItemActionCode } from '../../sale-documents/sale-documents/sale-documents.service';
import { SaleCreditNoteStatusSelectModalComponent } from '../sale-credit-note-status-select-modal/sale-credit-note-status-select-modal.component';
import { SaleCreditNoteHeaderHeaderStatus } from '../sale-credit-note-header-header-statuses/sale-credit-note-header-header-status.model';
import { SaleCreditNoteHeaderHeaderStatusesService } from '../sale-credit-note-header-header-statuses/sale-credit-note-header-header-statuses.service';

@Component({
  selector: 'app-sale-credit-notes',
  templateUrl: './sale-credit-notes.component.html',
  styleUrls: ['./sale-credit-notes.component.scss']
})
export class SaleCreditNotesComponent extends PageComponent {
  @Input() public filter: string | string[] = "";
  @Input() public canRefresh: boolean = false;

  @Input() public items?: SaleCreditNote[];

  @ViewChild('initModal') initModal?: ModalComponent;
  @ViewChild("paymentModal") paymentModal?: PaymentsComponent;
  @ViewChild('grid') grid?: SaleCreditNoteGridComponent;
  @ViewChild("confirmModal") confirmModal?: ConfirmModalComponent;
  @ViewChild("statusModal") statusModal?: SaleCreditNoteStatusSelectModalComponent;

  // ContextMenuItemAction
  // ---------------------
  public contextMenuItems: ContextMenuItemAction[] = [
    { code: SaleContexMenuItemActionCode.PrintToPDF, text: "Preview to PDF", icon: "print", onItemClick: (e: any) => this.onMenuItemClicked(e), visible: this.authService.hasPermission('sales.credit-notes.list.contextMenu.preview') },
    { code: SaleContexMenuItemActionCode.PrintToZIP, text: "Download to ZIP", icon: "print", onItemClick: (e: any) => this.onMenuItemClicked(e), visible: this.authService.hasPermission('sales.credit-notes.list.contextMenu.preview') },
    { code: SaleContexMenuItemActionCode.Status, text: "Change status", icon: "edit", onItemClick: (e: any) => this.onMenuItemClicked(e), visible: this.authService.hasPermission('sales.credit-notes.list.contextMenu.changeStatus') },
    { code: SaleContexMenuItemActionCode.payment, text: "Add payment", icon: "money", onItemClick: (e: any) => this.onMenuItemClicked(e), visible: this.authService.hasPermission('sales.credit-notes.list.contextMenu.addPayment') }
  ];

  public customerFilter: string | string[] = ["Roles/any(r:r/Code eq 'customer')"];

  constructor(
    public saleCreditNotesService: SaleCreditNotesService,
    public override activatedRoute: ActivatedRoute,
    public thirdsService: ThirdsService,
    public override translateService: TranslateService,
    public NumberPlatesService: NumberPlatesService,
    public saleCreditNoteHeaderHeaderStatusesService: SaleCreditNoteHeaderHeaderStatusesService
  ) { super(); }

  // Payment
  // -------
  public paymentElement: Payment[] = [];
  public paymentDocument?: SaleCreditNote;


  public onAddButtonClicked() {
    this.initModal?.open();
  };

  @Input() public canDelete: boolean = false;
  @Input() public delete: Function = (e: any) => {
    console.log("DeleteButtonClicked: " + e);
  };

  public onCreateValidated(e: any) {
    if (!e.ThirdId.Id) {
      NotificationsService.sendErrorMessage("Please select third");
      return;
    }
    if (e.ThirdId.Blocked == true) {
      NotificationsService.sendErrorMessage("Third is blocked");
      return;
    }
    this.router.navigateByUrl('/sales/credit-notes/new?ThirdId=' + e.ThirdId.Id + (e.Subject != undefined ? '&Subject=' + e.Subject : '') + (e.NumberPlate != undefined ? '&NumberPlate=' + e.NumberPlate : ''));
  }
  // ContextMenu
  // -----------
  public async onMenuItemClicked(e: ContextMenuItemClickEvent) {

    if (!this.grid) return;

    switch (e.itemData.code) {
      case SaleContexMenuItemActionCode.PrintToPDF:
        this.showConfirmModal("Would you like preview selected rows?", e.itemData);
        break;
      case SaleContexMenuItemActionCode.PrintToZIP:
        this.showConfirmModal("Would you like download selected rows?", e.itemData);
        break;
      case SaleContexMenuItemActionCode.Status:
        this.showStatusModal("Would you like change status selected rows?", e.itemData);
        break;
      case SaleContexMenuItemActionCode.payment:
        await this.showPaymentModal("Would you like add payment", e.itemData);
        break;
    }
  }

  private showConfirmModal(text: string, data: ContextMenuItemAction) {
    if (!this.confirmModal) return;

    this.confirmModal.title = data.text ? data.text : "Confirm";
    this.confirmModal.content = text;
    this.confirmModal.open({ rows: this.grid?.getSelectedRows(data.rowData), action: data.code });
  }
  private showStatusModal(text: string, data: ContextMenuItemAction) {
    if (!this.statusModal) return;

    this.statusModal.title = data.text ? data.text : "Confirm";
    this.statusModal.content = text;
    this.statusModal.open({ rows: this.grid?.getSelectedRows(data.rowData), action: data.code });
  }

  private async showPaymentModal(text: string, data: ContextMenuItemAction) {
    if (this.grid?.getSelectedRows(data.rowData) && this.grid?.getSelectedRows(data.rowData)?.length > 1) {
      NotificationsService.sendErrorMessage("Please select only one line");
      return;
    }
    this.paymentDocument = await this.saleCreditNotesService.findByID(this.grid?.getSelectedRows(data.rowData)[0].Id, { expand: this.saleCreditNotesService.defaultOptions.expand })
    // TODO add better method
    // Wait component is loaded
    setTimeout(async () => {
      if (!this.paymentModal || !this.paymentDocument) return;
      this.paymentElement = this.paymentDocument.Payments;
      await this.paymentModal.onAddPaymentClicked();
    }, 100);
  }

  public async onConfirmModalValidated(modalData: { rows: SaleCreditNote[], action: string } | any) {
    switch (modalData.action) {
      case SaleContexMenuItemActionCode.PrintToPDF:
        await this.saleCreditNotesService.printRows(modalData.rows, "pdf", "SaleCreditNote");
        break;
      case SaleContexMenuItemActionCode.PrintToZIP:
        await this.saleCreditNotesService.printRows(modalData.rows, "zip", "SaleCreditNote");
        break;
      case SaleContexMenuItemActionCode.Status:
        await this.updateStatus(modalData.rows, modalData.CreditNoteStatus);
        break;
    }

    this.grid?.refresh();
    this.confirmModal?.close();
    this.statusModal?.close();
  }
  // Actions
  // -------
  private async updateStatus(selectedRows: SaleCreditNote[], status: SaleCreditNoteHeaderHeaderStatus) {
    if (!this.saleCreditNotesService.canUpdateStatus(selectedRows, status)) return;
    selectedRows.sort((a, b) => { return a.Date.getTime() - b.Date.getTime(); });

    for (var element of selectedRows) {
      if (element.Id) {
        const link: any = {
          HeaderId: { Id: element.Id },
          StatusId: { Id: status.Id },
          Date: new Date()
        };
        await this.updateDocumentStatus(link);
      }
    }
    NotificationsService.sendSuccess('Status updated');
  }

  private async updateDocumentStatus(link: any) {
    await this.saleCreditNoteHeaderHeaderStatusesService.insert(this.saleCreditNoteHeaderHeaderStatusesService.format(link));
  }
}