<syslink-title text="Purchase creditNotes"></syslink-title>

<app-purchase-credit-note-grid
    #grid
    [permissionKey]="'list'"
    [canDelete]="false"
    [items]="items"
    (add)="onAddButtonClicked()"
    [contextMenuItems]="contextMenuItems"
></app-purchase-credit-note-grid>

<app-document-create-modal
  #createModal
  title="Create Credit Note"
  [thirdFilter]="supplierFilter"
  detailsUrl="/thirds/suppliers/"
  (validate)="onCreateValidated($event)"
></app-document-create-modal>

<app-purchase-credit-note-status-select-modal
  #statusModal
  (validate)="onConfirmModalValidated($event)"
></app-purchase-credit-note-status-select-modal>
<app-payments
  *ngIf="paymentElement && paymentDocument"
  #paymentModal
  [reportType]="'PurchaseCreditNote'"
  [showGrid]="false"
  [element]="paymentElement"
  [document]="paymentDocument"
  (paymentsChange)="grid.refresh()"
></app-payments>