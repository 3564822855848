import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ConfirmModalComponent, NotificationsService, PageComponent, SyslinkToolbarAction, SyslinkToolbarActionButton, SyslinkToolbarFileButton, TabsComponent } from 'projects/libraries/syslink-components/src/public-api';
import { Product } from '../product.model';
import { ProductDetailStatusTab, ProductsService } from '../product.service';
import { ActivatedRoute } from '@angular/router';
import { jsonToOdataFormat } from 'projects/libraries/syslink-components/src/lib/helpers/tools';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.scss']
})
export class ProductDetailsComponent extends PageComponent implements OnInit, OnDestroy, AfterViewInit {
  public hasNewProductLoaded: boolean = false;
  public element: Product = new Product();

  public override toolbarActions: SyslinkToolbarAction[] = [];

  @ViewChild('productTabs') private productTabs: TabsComponent = new TabsComponent;
  @ViewChild('deleteConfirm') deleteConfirm: ConfirmModalComponent = new ConfirmModalComponent;

  private statusSwitchSubscription: Subscription = new Subscription();

  constructor(
    private ngxUiLoaderService: NgxUiLoaderService,
    private productsService: ProductsService,
    public override activatedRoute: ActivatedRoute,
  ) {
    super();
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
    this.statusSwitchSubscription.unsubscribe();
  }

  override ngAfterViewInit(): void {
    super.ngAfterViewInit();
    this.updateSeletedTab();
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.activatedRoute.data.subscribe(async ({ element }) => {
      this.ngxUiLoaderService.start();

      this.element = element;
      if (this.activatedRoute.snapshot.queryParamMap.has('ProductInternalRef') && !this.element?.Id) {
        this.element.InternalReference = this.activatedRoute.snapshot.queryParamMap.get('ProductInternalRef') || '';
        this.element.ManufacturerReference = this.activatedRoute.snapshot.queryParamMap.get('ProductInternalRef') || '';
      };

      let name: string | null = null;
      if (this.activatedRoute.snapshot.queryParamMap.has('ProductName')) {
        name = this.activatedRoute.snapshot.queryParamMap.get('ProductName')
      };

      if (name != null && name != "") {
        this.element.Name = name;
      }
      await this.refresh();

      this.ngxUiLoaderService.stop();
      this.hasNewProductLoaded = true;
    });

    this.statusSwitchSubscription = this.productsService.statusSwitchSubscription$.subscribe(this.updateSeletedTab.bind(this));
  }
  public async refresh() {
    this.updateBreadCrumb(this.getFormattedTitle());
    this.initToolbar();
    await this.refreshStocks();
    await this.refreshPrices();
  }

  private async reloadElement() {
    if (!this.element.Id) return;
    this.element = await this.productsService.findByID(this.element.Id);
  }

  private initToolbar() {
    this.toolbarActions = [];

    this.toolbarActions.push(new SyslinkToolbarActionButton({ icon: 'save', text: 'Save', onClick: () => this.save(), location: 'before', inMenu: 'never', hotkey: 'control.s', visible: this.element.Id ? this.authService.hasPermission(this.basePermissionKey + '.update') : this.authService.hasPermission(this.basePermissionKey + '.add') }));
    if (this.element.Id) {
      this.toolbarActions.push(new SyslinkToolbarFileButton({ entityType: 'Product', entityId: this.element.Id }));
      this.toolbarActions.push(new SyslinkToolbarActionButton({ code: 'delete', icon: 'trash', text: 'Delete', onClick: () => this.delete(), visible: this.authService.hasPermission(this.basePermissionKey + '.delete') }));
    }
  }

  private updateSeletedTab(first: ProductDetailStatusTab = 'Purchasable'): void {
    const order: Array<ProductDetailStatusTab> = [first, 'Purchasable', 'Sellable', 'Stockable'];
    let selectedTab: ProductDetailStatusTab = 'Other';

    for (const status of order) {
      if (this.element[status as 'Purchasable' | 'Sellable' | 'Stockable']) {
        selectedTab = status;
        break;
      }
    }

    this.productTabs.selectTab(selectedTab);
  }

  private async refreshStocks() {
    if (!this.element.Id) return;
    const stockAudits = await this.productsService.getStockAudits(this.element.Id);
    this.element.StockMoveHistorical = (await this.productsService.getStockAudits(this.element.Id)).map((e: any) => jsonToOdataFormat(e));
    //   if (this.product.id != null){
    //     this.product.stockMoveHistorical = (await this.productsService.getStockMove(this.product.id)).data;

    //     await this.product.stockMoveHistorical.map(async (e: any) => {
    //       e.user = e.userId ? (await this.usersService.getThird(e.userId)).fullname : null;
    //     });
    //   }
  }

  private async refreshPrices() {
    await this.getPurchasePriceStatus();
  }


  // Create/Update
  // -------------
  public async save() {
    if ((!this.element.Id && !this.authService.hasPermission(this.basePermissionKey + '.add')) || (this.element.Id && !this.authService.hasPermission(this.basePermissionKey + '.update'))) {
      NotificationsService.sendErrorMessage("You do not have the required permission!");
      return;
    }
    // if (this.element.Purchasable && this.element.SupplierCatalogs.length == 0 && this.element.Id) {
    //   NotificationsService.sendErrorMessage("Purchasable product must have at least one line in his supplier catalog");
    //   return;
    // }

    if (!this.element.Id) {
      const product: Product = <Product>(await this.productsService.insert(this.productsService.format(this.element)));
      if (!product) {
        NotificationsService.sendErrorMessage("Unable to create record");
        return;
      }
      NotificationsService.sendSuccess("Record created");
      this.goToUrl('../' + product.Id);
    } else {
      const product: Product = await this.productsService.update(this.element.Id, this.productsService.format(this.element));
      if (!product) {
        NotificationsService.sendErrorMessage("Unable to update record");
        return;
      }
      NotificationsService.sendSuccess("Record updated");

      await this.reloadElement();
      await this.refresh();
    }
  }

  // Delete
  // ------
  public delete() {
    this.deleteConfirm.open();
  }

  public async productDelete() {
    if (!this.element?.Id) return

    await this.productsService.remove(this.element.Id);
    NotificationsService.sendInfo('Record deleted');
    this.goToUrl('../');
  }

  // Get purchase price status
  // -------------------------
  public getPurchasePriceStatus() {
    this.element.PurchasePriceStatus = "middle";
    if (this.element.SupplierCatalogs?.length == 1)
      return;
    if (this.element.SupplierCatalogs && this.element.DefaultSupplierCatalogId && this.element.DefaultSupplierCatalogId.ExTaxNetPrice) {
      const basePrice = this.element.DefaultSupplierCatalogId.ExTaxNetPrice;
      let catalogs = this.element.SupplierCatalogs.filter((e: any) => e.ExTaxNetPrice > basePrice)
      if (catalogs.length == 0) {
        this.element.PurchasePriceStatus = "worst";
        return;
      }
      catalogs = this.element.SupplierCatalogs.filter((e: any) => e.ExTaxNetPrice <= basePrice)
      if (catalogs.length == 1)
        this.element.PurchasePriceStatus = "best";
    }
  }

  public getFormattedTitle(): string {
    let formattedTitle = '';
    formattedTitle += this.element.No ? this.element.No + ' - ' : '';

    formattedTitle += this.element.Name;
    return formattedTitle;
  }
}
