<div class="text-end {{ data.getClass ? data.getClass(cell,data) : '' }}" 
    [style.color]="data.getColor ? data.getColor(cell,data) : null"
    [style.fontWeight]="data.getFontWeight ? data.getFontWeight(cell,data) : null"
    >
  {{ getValue() }}
</div>

<!-- 
<div *ngIf="data.getAmountRemaining(itemData) == '0'">
  <div class="text-end">{{ itemData.value | date : "dd/MM/yyyy" }}</div>
</div>
<div
  *ngIf="
    getColumn(itemData.column.dataField).data.getAmountRemaining(itemData) !=
      '0' &&
    getColumn(itemData.column.dataField).data.getCellOverdue(itemData) > 0
  "
>
  <div class="text-end TemplateDeadlineRed fw-bold">
    {{ itemData.value | date : "dd/MM/yyyy" }}
    {{ " - " }}
    {{ getColumn(itemData.column.dataField).data.getCellOverdue(itemData) }}
    {{ "day(s)" | translate }}
  </div>
</div>
<div
  *ngIf="
    getColumn(itemData.column.dataField).data.getAmountRemaining(itemData) !=
      '0' &&
    getColumn(itemData.column.dataField).data.getCellOverdue(itemData) <= 0
  "
>
  <div class="text-end TemplateDeadlineOrange fw-bold">
    {{ itemData.value | date : "dd/MM/yyyy" }}
  </div>
</div> -->
