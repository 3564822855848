<div class="row">
  <div class="col-1">
    <i
      *ngIf="!element.IsProfessional"
      class="text-primary text-center w-100 h5 py-1 m-0 fa-solid fa-user"
      style="font-size: 5rem"
    ></i>
    <i
      *ngIf="element.IsProfessional"
      class="text-primary text-center w-100 h5 py-1 m-0 fa-solid fa-building"
      style="font-size: 5rem"
    ></i>
  </div>
  <div class="col-11">
    <div class="row">
      <div class="col-3">
        <syslink-tagbox
          label="Roles"
          [store]="thirdRolesService.store"
          [(model)]="element.Roles"
          (modelChange)="thirdRolesChange.emit($event)"
        ></syslink-tagbox>
      </div>
      <div class="col-3">
        <syslink-switch
          label="Professional"
          [model]="element.IsProfessional"
          (modelChange)="onIsProfessionalChanged($event)"
        ></syslink-switch>
      </div>
      <div class="col-3"></div>
      <div class="col-3">
        <div class="row">
          <ng-container *ngIf="element.HasCustomerRole && element.CustomerId">
            <div class="col-6 ">
              <div class="totalPriceLabel fw-bold py-2 text-end">{{'InTaxOpenAmount' | translate }} :</div>
            </div>
            <div class="col-6  ">
              <div class="totalPriceValue py-2 bg-success text-white text-center">{{element.CustomerId.InTaxOpenAmount |
                currency }}
              </div>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="col-3">
        <syslink-input-text
          label="Alias"
          [(model)]="element.Alias"
        ></syslink-input-text>
      </div>
      <div class="col-3">
        <syslink-input-text
          label="External reference"
          [(model)]="element.ExternalRef"
        ></syslink-input-text>
      </div>

      <div class="col-3"></div>
      <div class="col-3">
        <div class="row">
          <ng-container *ngIf="element.HasCustomerRole && element.CustomerId">
            <div class="col-6 ">
              <div class="totalPriceLabel fw-bold py-2 text-end">{{'InTaxDueAmount' | translate }} :</div>
            </div>
            <div class="col-6  ">
              <div class="totalPriceValue py-2 bg-danger text-white text-center">{{element.CustomerId.InTaxDueAmount |
                currency }}
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <div class="col-1">
    <syslink-switch
      [(model)]="!element.Blocked"
      (modelChange)="element.Blocked = !$event"
      type="box"
      [falseText]="'Blocked' | translate"
      [trueText]="'Active' | translate"
    ></syslink-switch>
  </div>
</div>