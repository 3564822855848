import { Component, Input, ViewChild } from '@angular/core';
import { SyslinkColumn } from 'projects/libraries/syslink-components/src/lib/helpers/SyslinkColumn';
import { Third } from '../../../third.model';
import { ViewComponent } from 'projects/libraries/syslink-components/src/lib/helpers/view/view.component';
import { PurchaseCreditNotesService } from 'projects/erp-app/src/app/purchases/purchase-credit-notes/purchase-credit-notes/purchase-credit-notes.service';
import { PurchaseCreditNoteGridComponent } from 'projects/erp-app/src/app/purchases/purchase-credit-notes/purchase-credit-note-grid/purchase-credit-note-grid.component';

@Component({
  selector: 'app-third-document-relation-purchase-credit-notes',
  templateUrl: './third-document-relation-purchase-credit-notes.component.html',
  styleUrls: ['./third-document-relation-purchase-credit-notes.component.scss']
})
export class ThirdDocumentRelationPurchaseCreditNotesComponent extends ViewComponent {
  @Input() public element: Third = new Third();

  constructor(
    private purchaseCreditNotesService: PurchaseCreditNotesService,
  ) {
    super();
  }
  @ViewChild('creditNoteGridComponent') purchaseCreditNoteGridComponent: PurchaseCreditNoteGridComponent = new PurchaseCreditNoteGridComponent(this.purchaseCreditNotesService);
  public PurchaseCreditNoteColumns: SyslinkColumn[] = [
    new SyslinkColumn({ field: "No", label: "GRID.CAPTION.NO", width: 150, sortIndex: 2, sortDirection: 'desc' }),
    new SyslinkColumn({ field: "Subject", label: "Subject" }),
    new SyslinkColumn({ field: "CurrentStatusLink.StatusId.Name", label: "Status", cellTemplate: 'statusText-cell', data: { getStatusLabel: this.purchaseCreditNoteGridComponent.getStatusCellLabel, getStatusDate: this.purchaseCreditNoteGridComponent.getStatusCellDate, getStatusClass: this.purchaseCreditNoteGridComponent.getStatusCellClass, getStatusColor: this.purchaseCreditNoteGridComponent.getStatusCellColor }, width: 120 }),
    new SyslinkColumn({ field: "Deadline", label: 'PurchaseCreditNote.Validity', type: "date", cellTemplate: "template-cell", width: 80, data: { template: "TemplateDeadline", currentDate: new Date(), getAmountRemaining: this.purchaseCreditNoteGridComponent.getAmountRemainingCellLabel, getCellOverdue: this.purchaseCreditNoteGridComponent.getCellOverdue } }),
    new SyslinkColumn({ field: "ExternalReference", label: "External reference", visible: true, width: 150 }),
    new SyslinkColumn({ field: "ExTaxTotal", label: "exTaxTotal", type: "number", data: { subModuleCode: 'Purchases.CreditNote', key: 'Precision.PurchaseCreditNoteHeader.ExTaxTotal' }, cellTemplate: 'currency-cell', width: 80 }),
    new SyslinkColumn({ field: "InTaxTotal", label: "Total", type: "number", data: { subModuleCode: 'Purchases.CreditNote', key: 'Precision.PurchaseCreditNoteHeader.InTaxTotal' }, cellTemplate: 'currency-cell', width: 80 }),
    new SyslinkColumn({ field: "ResponsibleUserId.Fullname", label: "Responsible", width: 120 }),
    new SyslinkColumn({ field: "Date", label: "Date", type: "date", visible: false, width: 150 }),
    new SyslinkColumn({ field: "AmountRemaining", label: 'Remaining amount', type: "number", visible: false, cellTemplate: 'currency-cell', data: { subModuleCode: 'Purchases.CreditNote', key: 'Precision.PurchaseCreditNoteHeader.AmountRemaining' }, width: 80, filterable: false, headerFilterable: false, sortable: false }),
    new SyslinkColumn({ field: "FormattedStructuredCommunication", label: 'Structured communication', visible: false, editable: false }),
    new SyslinkColumn({ field: "CurrentStatusLink.StatusId.Sequence", label: 'status order', sortIndex: 1, sortDirection: 'asc', width: 0, visible: false })
  ];

  onAddButtonClicked() {
    window.open('/purchases/credit-notes/new?ThirdId=' + this.element.Id);
  }

}