import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AddressTypeService } from 'projects/erp-app/src/app/base/addresses/address-types/address-type.service';
import { PostalCodeService } from 'projects/erp-app/src/app/base/addresses/postal-codes/postal-code.service';
import { LocalityService } from 'projects/erp-app/src/app/base/addresses/localities/locality.service';
import { ThirdAddress } from '../third-addresses/third-address.model';
import { AddressesService } from 'projects/erp-app/src/app/base/addresses/addresses/addresses.service';
import { jsonToOdataFormat } from 'projects/libraries/syslink-components/src/lib/helpers/tools';
import { Locality } from 'projects/erp-app/src/app/base/addresses/localities/locality';
import { PostalCode } from 'projects/erp-app/src/app/base/addresses/postal-codes/postal-code';

@Component({
  selector: 'app-address-form',
  templateUrl: './third-address-form.component.html',
  styleUrls: ['./third-address-form.component.scss']
})
export class AddressFormComponent implements OnInit {
  @Input() public address: ThirdAddress = new ThirdAddress();
  @Output() public addressChange: EventEmitter<ThirdAddress> = new EventEmitter<ThirdAddress>();

  // @Input() public defaultAddressType?: ThirdAddressType;
  @Input() public disabled: boolean = false;

  public initialPlace?: any;
  public place?: any;

  @ViewChild('addressContainer') addressContainer?: ElementRef;

  @Input() public showMap: boolean = true;

  public localities: Locality[] = [];
  public postalCodes: PostalCode[] = [];

  constructor(
    public postalCodeService: PostalCodeService,
    public localityService: LocalityService,
    public addressTypesService: AddressTypeService,
    public addressesService: AddressesService
  ) { }


  async ngOnInit(): Promise<void> {
    await this.loadLocalities();
    await this.loadPostalCodes();
  }

  public async loadLocalities() {
    this.localities = [];
    this.localities = await this.localityService.load({ select: ['Id', 'LongName'] });
  }
  public async loadPostalCodes() {
    this.postalCodes = [];
    this.postalCodes = await this.postalCodeService.load({ select: ['Id', 'Value'] });
  }

  public async onGooglePlace(place: any) {
    if (!place.address_components) return;

    const formatedPlace = {
      addressComponents: place.address_components.reduce((addressComponents: any, addressComponent: any) => {
        return [
          ...addressComponents,
          {
            longName: addressComponent.long_name,
            shortName: addressComponent.short_name,
            types: addressComponent.types,
          }
        ];
      }, []),
      formattedAddress: place.formatted_address,
      placeId: place.place_id,
      geometry: {
        location: {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        }
      }
    };

    // Début des requêtes
    const placeResult: any = await this.addressesService.postPlaceResult(formatedPlace);
    this.initialPlace = placeResult;
    this.place = Object.assign({}, placeResult);

    this.address = Object.assign(this.address, jsonToOdataFormat(placeResult));
    // this.address.AddressTypeId = this.defaultAddressType ? this.defaultAddressType : await this.addressTypesService.findByID(this.address.ThirdId.ThirdTypeId?.Id == ThirdTypeIds.Person ? ThirdAddressTypeIds.Home : ThirdAddressTypeIds.HeadOffice);
    this.addressContainer!.nativeElement.click();
  }

  public async onLocalityCustomValue(name: any) {
    var index = this.localities.findIndex((l: Locality) => l.LongName == name);
    if (index == -1) {
      var locality = await this.localityService.getInstance({ LongName: name, ShortName: name, CountryId: this.address.LocalityId.CountryId });
      locality = await this.localityService.insert(this.localityService.format(locality));
      this.address.LocalityId = locality;
      this.addressChange.emit(this.address);
      await this.loadLocalities();
    }
  }

  public async onPostalCodeCustomValue(name: any) {
    var index = this.postalCodes.findIndex((l: PostalCode) => l.Value == name);
    if (index == -1) {
      var postalCode = await this.postalCodeService.insert(this.postalCodeService.format(await this.postalCodeService.getInstance({ Value: name })));
      this.address.PostalCodeId = postalCode;
      this.addressChange.emit(this.address);
      await this.loadPostalCodes();
    }

  }
}
