<syslink-grid
    #grid
    [gridName]="storageKey"
    [canView]="canView"
    [canSearch]="canSearch"
    [canRefresh]="canRefresh"
    [canAdd]="canAdd"
    [canSort]="canSort"
    [canFilter]="canFilter"
    [canExport]="canExport"
    [canDblClck]="canDblClck"
    [canDelete]="canDelete"
    [canUpdate]="canUpdate"
    [service]="service"
    [columns]="columns"
    [filter]="filter"
    [storageKey]="storageKey"
    [select]="select"
    [expand]="expand"
    [filter]="filter"
    [languages]="languages"
    (onPrepareRow)="onPrepareRow.emit($event)"
    (beforeAdd)="beforeAdd.emit($event)"
    (beforeUpdate)="beforeUpdate.emit($event)"
></syslink-grid>