<div class="form">
  <div class="row">
    <div class="col-1">
      <div
        class="text-primary text-center w-100 h5 py-1 m-0"
        style="font-size: 5rem"
      >
        <i class="fa-solid fa-user"></i>
      </div>
      <syslink-switch
        name="isActive"
        [(model)]="element.IsActive"
        type="box"
        [falseText]="'Blocked' | translate"
        [trueText]="'Active' | translate"
      ></syslink-switch>
    </div>
    <div class="col-3">
      <syslink-input-text
        name="code"
        label="Code"
        [(model)]="element.Code"
      ></syslink-input-text>

      <syslink-input-text
        *ngIf="!element.Id"
        name="nameText"
        label="Name"
        [(model)]="element.Name"
      ></syslink-input-text>

      <syslink-edit-translations-cell
        *ngIf="element.Id"
        class="py-2"
        name="name"
        (modelUpdate)="onTranslationsUpdate($event)"
        [model]="element.NameTranslationId?.TranslatedValue"
        (onTranslationsBtnClicked)="onTranslationsBtnClicked()"
      ></syslink-edit-translations-cell>
    </div>
    <div class="col-3">
      <syslink-color-picker
        name="backgroundColor"
        label="BackgroundColor"
        [(model)]="element.BackgroundColor"
      ></syslink-color-picker>

      <syslink-color-picker
        name="foregroundColor"
        label="ForegroundColor"
        [(model)]="element.ForegroundColor"
      ></syslink-color-picker>
    </div>
  </div>
</div>

<syslink-translations-modal
  #translationsCellModal
  (onValidated)="onTranslationsModalValidated($event)"
  [languages]="languagesService.languages"
></syslink-translations-modal>