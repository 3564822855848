import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ModalComponent, ConfirmModalComponent, SyslinkToolbarAction, SyslinkToolbarActionButton } from 'projects/libraries/syslink-components/src/public-api';
import { Third } from '../third.model';
import { ThirdRelation } from './third-relation.model';
import { ThirdRelationsService } from './third-relations.service';
import { ThirdRelationTypesService } from './third-relation-types/third-relation-types.service';
import { ViewComponent } from 'projects/libraries/syslink-components/src/lib/helpers/view/view.component';

@Component({
  selector: 'app-third-relations',
  templateUrl: './third-relations.component.html',
  styleUrls: ['./third-relations.component.scss']
})
export class ThirdRelationsComponent extends ViewComponent {
  @Input() public isLocal: boolean = true;
  private localId: number = 0;

  public element: ThirdRelation = new ThirdRelation();

  @Input() elements: ThirdRelation[] = [];
  @Output() elementsChange: EventEmitter<ThirdRelation[]> = new EventEmitter<ThirdRelation[]>();

  @Output() public create: EventEmitter<ThirdRelation> = new EventEmitter<ThirdRelation>();
  @Output() public update: EventEmitter<ThirdRelation> = new EventEmitter<ThirdRelation>();
  @Output() public delete: EventEmitter<ThirdRelation> = new EventEmitter<ThirdRelation>();

  @Input() public third: Third = new Third();

  @ViewChild('editModal') public editModal: ModalComponent = new ModalComponent();
  @ViewChild('deleteConfirm') public deleteConfirm: ConfirmModalComponent = new ConfirmModalComponent();

  public toolbarActions: SyslinkToolbarAction[] = [
    new SyslinkToolbarActionButton({ icon: 'plus', text: this.translateService.instant('Add a new relation'), onClick: () => this.onCreateBtnClicked(), visible: this.authService.hasPermission(this.basePermissionKey + '.relations.list.contextMenu.add') })
  ];

  public cardContextItems = [
    { code: 'update', text: this.translateService.instant('Edit'), icon: 'fa-solid fa-pen', visible: this.authService.hasPermission(this.basePermissionKey + '.relations.list.contextMenu.update') },
    { code: 'delete', text: this.translateService.instant('Delete'), icon: 'fa-solid fa-trash', visible: this.authService.hasPermission(this.basePermissionKey + '.relations.list.contextMenu.delete') }
  ];

  constructor(
    private thirdRelationsService: ThirdRelationsService,
    private translateService: TranslateService,
    private thirdRelationTypesService: ThirdRelationTypesService
  ) {
    super();
  }

  public onContextMenuItemClicked(e: { event: any, model: ThirdRelation }) {
    this.element = e.model;
    switch (e.event.itemData.code) {
      case 'delete':
        this.onDeleteBtnClicked(this.element);
        break;
      case 'update':
        this.onUpdateBtnClicked(this.element);
        break;
      default:
        break;
    }
  }

  public async initModalDatas(relation?: ThirdRelation) {
    // TODO fix FROM and TO relation
    this.element = relation ?? await this.thirdRelationsService.getInstance(this.third);
  }

  // Create
  // ------
  public async onCreateBtnClicked() {
    await this.initModalDatas();
    this.editModal.open();
  }

  public async onCreate(relation: ThirdRelation) {
    if (relation.ThirdRelationTypeId?.Id) {
      relation.ThirdRelationTypeId = await this.thirdRelationTypesService.findByID(relation.ThirdRelationTypeId?.Id);
    }

    this.elements.push({ ...relation, Id: ++this.localId });

    if (!this.isLocal && this.create.observed) {
      this.create.emit(relation);
    }

    this.editModal.close();
  }
  // ----------------------------------------------

  // Update
  // ------
  public async onUpdateBtnClicked(relation: ThirdRelation) {
    await this.initModalDatas(relation);
    this.editModal.open();
  }

  public async onUpdate(relation: ThirdRelation) {
    if (!this.isLocal && this.update.observed) {
      this.update.emit(relation);
    } else {
      this.elements.map((e: ThirdRelation) => {
        if (e.Id !== relation.Id) return;
        e = relation;
      });
    }

    this.editModal.close();
  }
  // ----------------------------------------------

  // Delete
  // ------
  public onDeleteBtnClicked(relation: ThirdRelation) {
    this.element = relation;
    this.deleteConfirm.open();
  }

  public async onDelete(relation: ThirdRelation) {
    this.elements = this.elements.filter((e: ThirdRelation) => e.Id !== relation.Id);
    this.elementsChange.emit(this.elements);

    if (!this.isLocal && this.delete.observed) {
      this.delete.emit(relation);
    }

    this.deleteConfirm.close();
  }
  // ----------------------------------------------
}
