import { Component, Input } from '@angular/core';

// SOURCE : https://github.com/lipis/flag-icons

@Component({
  selector: 'syslink-flag',
  templateUrl: './flag.component.html'
})
export class FlagComponent {
  /**
   * TODO: Add all flags as types ('be' | 'af' | 'nl')
   * Code of the flag
   */
  @Input() public flag?: string = 'xx';

  /**
   * Force the flag to take a 1x1 shape
   */
  @Input() public isSquare: boolean = false;

  /**
   * Percent
   */
  @Input() public size: number = 100;
}
