<i
  role="button"
  *ngIf="!cell.value || cell.value == false"
  (click)="cell.setValue(!cell.value)"
  [style.color]="data.inactiveColor"
  class="text-center fa-solid {{ data.icon }} {{ data.inactiveClass }}"

></i>
<i
  role="button"
  *ngIf="cell.value && cell.value != false"
  (click)="cell.setValue(!cell.value)"
  [style.color]="data.activeColor"
  class="text-center fa-solid {{ data.icon }} {{ data.activeClass }}"
></i>
<!-- *ngIf="cell.value && cell.value == true" -->
