import { Injectable } from '@angular/core';
import { LoadOptions } from 'devextreme/data';
import { ODataService } from '../../../core/services/oData.service';
import { SaleDocumentHeaderStatus } from './sale-document-header-status.model';

@Injectable({
  providedIn: 'root'
})
export class SaleDocumentHeaderStatusesService<TSaleDocumentHeaderStatus extends SaleDocumentHeaderStatus> extends ODataService<TSaleDocumentHeaderStatus> {
  public url: string = 'SaleDocumentHeaderHeaderStatus';

  public override defaultOptions: LoadOptions = {
    expand: ["StatusId"]
  };
}
