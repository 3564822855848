import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PurchaseDocumentLine } from '../../purchase-document-lines/purchase-document-line.model';
import { PurchaseDocumentLinesService } from '../../purchase-document-lines/purchase-document-lines.service';
import { PurchaseDocument } from '../purchase-document.model';
import { PurchaseDocumentsService } from '../purchase-documents.service';
import { getNew } from 'projects/libraries/syslink-components/src/lib/helpers/tools';
import { PurchaseDocumentStatus } from '../../purchase-document-statuses/purchase-document-status.model';

@Component({
  selector: 'app-purchase-document-finance',
  templateUrl: './purchase-document-finance.component.html',
  styleUrls: ['./purchase-document-finance.component.scss']
})
export class PurchaseDocumentFinanceComponent<
  TPurchaseDocument extends PurchaseDocument,
  TPurchaseDocumentService extends PurchaseDocumentsService<TPurchaseDocument,PurchaseDocumentStatus>,
  TPurchaseDocumentLine extends PurchaseDocumentLine,
  TPurchaseDocumentLineService extends PurchaseDocumentLinesService<TPurchaseDocumentLine>
> {
  @Input() public element: TPurchaseDocument = <TPurchaseDocument>getNew<TPurchaseDocument>();
  @Output() public elementChange: EventEmitter<TPurchaseDocument> = new EventEmitter<TPurchaseDocument>();
  @Output() public paymentsChange: EventEmitter<any> = new EventEmitter<any>();

  @Input() public reportType: string = '';

}
