import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { BaseModalComponent } from 'projects/libraries/syslink-components/src/lib/modal/base-modal/base-modal.component';
import { SaleInvoice } from '../sale-invoices/sale-invoice.model';
import { FilterDescriptor } from 'devextreme/data';
import { SelectComponent } from 'projects/libraries/syslink-components/src/public-api';
import { SaleInvoicesService } from '../sale-invoices/sale-invoices.service';

@Component({
  selector: 'app-sale-invoice-select-modal',
  templateUrl: './sale-invoice-select-modal.component.html',
  styleUrls: ['./sale-invoice-select-modal.component.scss']
})
export class SaleInvoiceSelectModalComponent extends BaseModalComponent<SaleInvoice>{
  @Input() public selectFilters: FilterDescriptor | Array<FilterDescriptor>;
  @Input() public expand: FilterDescriptor | Array<FilterDescriptor>;
  @Input() public title: string = "Select invoice";
  @Input() public content?: string;
  @Input() public data: any;
  @ViewChild('select') select?: SelectComponent;

  constructor(public saleInvoicesService: SaleInvoicesService) {
    super();
  }

  public onNewInvoiceButtonClicked() {
    if (!this.modal) return;
    this.modal.data.Invoice = undefined;
    this.onValidateButtonClicked(this.modal?.data);
  }
}