import { BasicEntity } from "../../../core/models/basic-entity";
import { Task } from "../tasks/task.model";

export class TaskTag extends BasicEntity {
    public Tasks?:Task[];
    
    constructor(entity?: TaskTag) {
        super();
        Object.assign(this, entity);
    }
}
