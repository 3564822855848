import { Injectable } from '@angular/core';
import DevExpress from 'devextreme';
import { ColumnChooser, ColumnFixing, FilterRow, HeaderFilter, Pager, PagingBase, SearchPanel, Sorting } from 'devextreme/common/grids';
import { DataGridPredefinedToolbarItem, Properties, Selection, Toolbar, ToolbarItem } from 'devextreme/ui/data_grid';
import { merge } from '../helpers/tools';

@Injectable({
  providedIn: 'root'
})
export class GridService {
  public getBaseConfiguration = (key: string): Properties => ({
    keyExpr: key,
    wordWrapEnabled: true,
    hoverStateEnabled: true,
    showBorders: true,
    showColumnLines: true,
    focusedRowEnabled: false, // Set to false waiting for devextreme v23.1.5
    showRowLines: true,
    rowAlternationEnabled: true,
    allowColumnReordering: true,
    allowColumnResizing: true,
    columnAutoWidth: true,
  });

  public getDefaultExportConfiguration = (): DevExpress.ui.dxDataGrid.Export => ({
    enabled: true,
    allowExportSelectedData: false
  });

  public getDefaultEditingConfiguration = (): DevExpress.ui.dxDataGrid.Editing<any, any> => ({
    allowAdding: true,
    allowUpdating: true,
    allowDeleting: true,
    mode: 'cell',
    useIcons: true
  });

  public getDefaultSearchPanelConfiguration = (): SearchPanel => ({
    visible: true,
    highlightCaseSensitive: false,
    width: 250,
  });

  public getDefaultPagingConfiguration = (): PagingBase => ({
    enabled: true,
  });

  public getDefaultPagerConfiguration = (): Pager => ({
    visible: true,
    showInfo: true,
    displayMode: 'compact',
    showNavigationButtons: true,
    showPageSizeSelector: false,
  });

  public getDefaultColumnChooserConfiguration = (): ColumnChooser => ({
    enabled: true,
    mode: 'select',
    allowSearch: true,
  });

  public getDefaultColumnFixingConfiguration = (): ColumnFixing => ({
    enabled: true,
  });

  public getDefaultSortingConfiguration = (): Sorting => ({
    mode: 'multiple'
  });

  public getDefaultFilterRowConfiguration = (): FilterRow => ({
    visible: true
  });

  public getDefaultHeaderFilterConfiguration = (): HeaderFilter => ({
    visible: false,
    allowSearch: true,
  });

  public getDefaultSelectionConfiguration = (): Selection => ({
    mode: 'multiple',
    allowSelectAll: true,
    showCheckBoxesMode: 'onLongTap',
    selectAllMode: 'allPages'
  });

  public getDefaultFilterPanelConfiguration = (): DevExpress.common.grids.FilterPanel<DevExpress.ui.dxDataGrid<any, any>, any, any> | undefined => ({
    filterEnabled: true,
    visible: true
  });


  public generateEditingConfiguration(customConfiguration: DevExpress.ui.dxDataGrid.Editing<any, any> = {}) {
    return merge(this.getDefaultEditingConfiguration(), customConfiguration);
  }

  public generateExportConfiguration(customConfiguration: DevExpress.ui.dxDataGrid.Export = {}) {
    return merge(this.getDefaultExportConfiguration(), customConfiguration);
  }

  public generateSearchPanelConfiguration(customConfiguration: SearchPanel = {}) {
    return merge(this.getDefaultSearchPanelConfiguration(), customConfiguration);
  }

  public generatePagingConfiguration(customConfiguration: PagingBase = {}) {
    return merge(this.getDefaultPagingConfiguration(), customConfiguration);
  }

  public generatePagerConfiguration(customConfiguration: Pager = {}) {
    return merge(this.getDefaultPagerConfiguration(), customConfiguration);
  }

  public generateColumnChooserConfiguration(customConfiguration: ColumnChooser = {}) {
    return merge(this.getDefaultColumnChooserConfiguration(), customConfiguration);
  }

  public generateColumnFixingConfiguration(customConfiguration: ColumnFixing = {}) {
    return merge(this.getDefaultColumnFixingConfiguration(), customConfiguration);
  }

  public generateSortingConfiguration(customConfiguration: Sorting = {}) {
    return merge(this.getDefaultSortingConfiguration(), customConfiguration);
  }

  public generateFilterRowConfiguration(customConfiguration: FilterRow = {}) {
    return merge(this.getDefaultFilterRowConfiguration(), customConfiguration);
  }

  public generateHeaderFilterConfiguration(customConfiguration: HeaderFilter = {}) {
    return merge(this.getDefaultHeaderFilterConfiguration(), customConfiguration);
  }

  public generateSelectionConfiguration(customConfiguration: Selection = {}) {
    return merge(this.getDefaultSelectionConfiguration(), customConfiguration);
  }

  public generateToolbarConfiguration(toolbarItems?: Array<DataGridPredefinedToolbarItem | ToolbarItem>, customConfiguration: Toolbar = {}) {
    var toolbar: Toolbar = {
      items: toolbarItems,
      visible: true,
      disabled: false,
    }
    return merge(toolbar, customConfiguration);
  }

  public generateFilterPanelConfiguration(customConfiguration: Sorting = {}) {
    return merge(this.getDefaultFilterPanelConfiguration(), customConfiguration);
  }


  public getConfiguration(customConfiguration: Properties = {}, key: string): Properties {
    var configuration: Properties = this.getBaseConfiguration(key);

    configuration.editing = this.generateEditingConfiguration();
    configuration.export = this.generateExportConfiguration();
    configuration.searchPanel = this.generateSearchPanelConfiguration();
    configuration.paging = this.generatePagingConfiguration({ pageSize: 20 });
    configuration.pager = this.generatePagerConfiguration({ showPageSizeSelector:true, allowedPageSizes: [10,20,50,100] });
    configuration.columnChooser = this.generateColumnChooserConfiguration();
    configuration.sorting = this.generateSortingConfiguration();
    configuration.filterRow = this.generateFilterRowConfiguration();
    configuration.headerFilter = this.generateHeaderFilterConfiguration();
    configuration.selection = this.generateSelectionConfiguration();
    configuration.toolbar = this.generateToolbarConfiguration();
    configuration.filterPanel = this.generateFilterPanelConfiguration();
    // configuration.groupPanel = {
    //   allowColumnDragging: true,
    //   visible: true,
    // }
    // configuration.grouping = {
    //   allowCollapsing: true,
    //   contextMenuEnabled: true,
    //   expandMode: 'rowClick',

    // }

    // Avoid merge recusion error
    // --------------------------
    if (customConfiguration.dataSource) {
      configuration.dataSource = customConfiguration.dataSource;
      delete customConfiguration.dataSource;
    }

    return merge(configuration, customConfiguration);
  }
}