import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { WorkTypesService } from '../../../works/work-types/work-types.service';
import { TasksService } from '../../../tasks/tasks/tasks.service';
import { ThirdsService } from 'projects/erp-app/src/app/thirds/thirds/thirds.service';
import { UserGroupsService } from 'projects/erp-app/src/app/core/auth/user-groups/user-groups.service';
import { WorkTime } from '../work-time';
import { ModalComponent, NotificationsService, SelectComponent } from 'projects/libraries/syslink-components/src/public-api';
import { FilterDescriptor } from 'devextreme/data';
import { Third } from 'projects/erp-app/src/app/thirds/thirds/third.model';
import { UsersService } from 'projects/erp-app/src/app/core/auth/users/users.service';
import { WorkType } from '../../../works/work-types/work-type.model';
import { User } from 'projects/erp-app/src/app/core/auth/users/user.model';
import { UserGroup } from 'projects/erp-app/src/app/core/auth/user-groups/user-group.model';
import { ViewComponent } from 'projects/libraries/syslink-components/src/lib/helpers/view/view.component';

@Component({
  selector: 'app-work-time-modal',
  templateUrl: './work-time-modal.component.html',
  styleUrls: ['./work-time-modal.component.scss']
})
export class WorkTimeModalComponent extends ViewComponent implements OnInit {
  public title: string = "Create Work time";

  @Output() validate: EventEmitter<WorkTime> = new EventEmitter<WorkTime>();

  @ViewChild("modal") modal?: ModalComponent;

  // Task Field
  public taskFilters?: FilterDescriptor[];
  public hideTaskField: boolean = false;
  @ViewChild('taskSelect') taskSelect?: SelectComponent;
  // -------------------

  // Users field
  // -----------
  public usersFilters?: FilterDescriptor[]=['IsActive eq true'];
  @ViewChild("usersSelect") usersSelect?: SelectComponent;

  // Configurations
  // --------------
  @Input() public canEditType: boolean = true;
  @Input() public canEditTask: boolean = true;

  constructor(
    public workTypesService: WorkTypesService,
    public tasksService: TasksService,
    public thirdsService: ThirdsService,
    public userGroupsService: UserGroupsService,
    public usersService: UsersService,
    private changeDetector : ChangeDetectorRef
  ) {
    super();
  }

  // Events
  // ------
  onValidateButtonClicked(e: WorkTime) {
    if (this.modal && this.modal?.data && ((this.modal?.data?.Id && !this.authService.hasPermission('time-management.work-times.modals.update')) || (!this.modal?.data?.Id && !this.authService.hasPermission('time-management.work-times.list.add')))) {
      NotificationsService.sendErrorMessage("You do not have the required permission!");
      return;
    }   

    if (!this.isValid()) return

    if (this.validate.observed) {
      this.validate.emit(e);
      return;
    }

  }

  public onStartDateChanged($event: any) {
    if (this.modal?.data.StartDate > this.modal?.data.EndDate) {
      NotificationsService.sendErrorMessage("Start date must be before end Date");
      return;
    }

    this.modal?.data?.updateDuration();
  }

  public onEndDateChanged($event: any) {
    if (this.modal?.data.EndDate < this.modal?.data.StartDate) {
      NotificationsService.sendErrorMessage("End date must be after start Date");
      return;
    }

    this.modal?.data?.updateDuration();
  }


  public onThirdChanged($event: any) {
    if (!this.modal) return;
    let thirds : Partial<Third>[] = $event != null ? [$event] : [];
    let users : Partial<User>[] = this.modal.data.UserId != null ? [this.modal.data.UserId] : [];
    let types : Partial<WorkType>[] = [];
    let userGroups : Partial<UserGroup>[] = [];

    this.updateTaskFilters(thirds, types, users, userGroups);
  }

  public async onUserChanged($event: User) {
    if (!this.modal) return;
    let thirds : Partial<Third>[] = this.modal.data.ThirdId != null ? [this.modal.data.ThirdId] : [];
    let users : Partial<User>[] = $event != null ? [$event] : [];
    let types : Partial<WorkType>[] = [];
    let userGroups : Partial<UserGroup>[] = [];

    this.updateTaskFilters(thirds, types, users, userGroups);
    this.changeDetector.detectChanges();

  }

  public onTypeChanged($event: any) {
  }

  public onTaskChanged($event: any) {
    if (!this.modal) return;
    
    this.modal.data.IsBillable = this.modal.data.TaskId?.IsBillable;

    // Update ThirdId
    // --------------
    if (this.modal.data.TaskId){
      this.modal.data.ThirdId = this.modal.data.TaskId?.ThirdId;
    }
    this.usersFilters = this.tasksService.getUserFilters([this.modal.data.TaskId], this.modal.data.UserGroups);
    this.changeDetector.detectChanges();
  }



  private async updateTaskFilters(thirds: Array<Partial<Third>>, types: Array<Partial<WorkType>>, users: Array<Partial<User>>, userGroups: Array<Partial<UserGroup>>) {
    this.taskFilters = [];

    // Add default task filters
    // ------------------------

    this.taskFilters.push(['IsPerformable eq true']);
    this.taskFilters.push("and");
    this.taskFilters.push(this.tasksService.getTaskFilter(thirds, types, users, userGroups));
  }




  // Functions
  // ---------
  public open(e?: WorkTime) {
    this.modal?.open(e);
  }
  public close() {
    this.modal?.close();
  }

  private isValid() {
    if (this.modal?.data.StartDate > this.modal?.data.EndDate) {
      NotificationsService.sendErrorMessage("Start date must be before end Date");
      return false;
    }

    // if (!this.modal?.data.TaskId?.Users?.some(e => e.Id == this.modal?.data.UserId?.Id)) {
    //   NotificationsService.sendErrorMessage("Your are not allowed to work on this task");
    //   return false;
    // }

    return true;
  }
  // -----------------------------------------------------------------

}
