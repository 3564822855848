import { Injectable } from '@angular/core';
import { ODataService } from '../../core/services/oData.service';
import { LoadOptions } from 'devextreme/data';
import { StockLocation } from './stock-location.model';

@Injectable({
  providedIn: 'root'
})
export class StockLocationService extends ODataService<StockLocation> {
  public url = 'StockLocation';
  public override defaultOptions: LoadOptions = {
    expand: [
      'ParentId',
      'DepartmentId',
    ]
  };

  public override format(element: Partial<StockLocation>): StockLocation {
    var result: any = {
      ...element,
      DepartmentId: element.DepartmentId?.Id,
      ParentId: element.ParentId?.Id,
    };

    return result;
  }
}