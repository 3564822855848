<syslink-modal
    #modal
    [title]="title"
    (onValidate)="onValidateButtonClicked(modal.data)"
    width="350"
>
    <p
        *ngIf="content"
        class="text-center m-0"
        [innerHTML]="content | translate"
    ></p>

    <syslink-select
        #select
        label="Status"
        [filter]="selectFilters"
        [store]="taskStatusesService.store"
        [(model)]="modal.data.TaskStatus"
        displayKey="Name"
        [expand]="expand"
    ></syslink-select>
</syslink-modal>