import { AuthService } from "./core/auth/auth.service";

export function appFactory(
    authService: AuthService
) {
    return async () => {

        // Use this to initialize something before app is showed to the user

        // Init translations
        // -----------------
        // await TranslationsService.updateTranslations(TranslationsService.currentLanguage);

        // Init current user
        // -----------------
        await authService.reloadUser();
    };
}