<div class="row mb-3">
    <!-- <div  class="col-3">
        <syslink-input-text
            label="Reference"
            [(model)]="element.InternalReference"
        ></syslink-input-text>
    </div> -->
    <div class="col-3">
        <syslink-select
            label="Brand"
            [store]="brandsService.store"
            (onCustomValue)="customNewBrandValue($event)"
            [(model)]="element.BrandId"
        ></syslink-select>
    </div>
    <div class="col-3">
        <syslink-input-text
            label="Make"
            [(model)]="element.Make"
        ></syslink-input-text>
    </div>
    <!-- <div class="col-3">
        <syslink-input-number
            label="Labor (Hours)"
            [(model)]="element.Labor"
        ></syslink-input-number>
    </div> -->
    <div class="offset-3"></div>
    <div class="col-3">
        <syslink-input-number
            label="Sale base price"
            subModuleCode="Products.General"
            key="Precision.Product.SaleBasePrice"
            [(model)]="element.ForcedSalePrice"
            (focusOut)="onBasePriceChange($event)"
        ></syslink-input-number>
    </div>
    <div class="col-3">
        <syslink-select
            label="Currency"
            [store]="currenciesService.store"
            [(model)]="element.CurrencyId"
            [disabled]="true"
        ></syslink-select>
    </div>
    <div class="col-3">
        <syslink-select
            label="Unit"
            [store]="unitsService.store"
            [(model)]="element.DefaultUnitId"
        ></syslink-select>
    </div>
    <div class="col-3">
        <syslink-select
            label="Sale tax"
            [store]="taxRatesService.store"
            [filter]="['IsVisible eq true']"
            [(model)]="element.SaleTaxRateId"
        ></syslink-select>
    </div>
    <div class="col-3">
        <syslink-select
            label="Tax type"
            [store]="taxTypesService.store"
            [(model)]="element.SaleTaxTypeId"
            [displayKey]="'FormattedName'"
        ></syslink-select>
    </div>
    <div class="col-3">
        <syslink-select
            label="Account"
            [store]="accountsService.store"
            [(model)]="element.SaleAccountId"
            [displayKey]="'FormattedName'"
        ></syslink-select>
    </div>
    <label>{{'Description' | translate}}</label>
    <syslink-wysiwyg
        [(model)]="element.Description"
        [table]="false"
        [multilineToolbar]="true"
        height="200"
    ></syslink-wysiwyg>
</div>

<label>{{'Customer catalog' | translate}}</label>

<div *ngIf="element.Id">
    <app-product-customer-catalogs
        [(element)]="element"
        (elementChange)="customerCatalogChanged.emit($event)"
    ></app-product-customer-catalogs>
</div>
<div *ngIf="!element.Id">
    <div class="w-100 text-center p-5 bg-light">{{'SaveFirstMessage' | translate}}</div>
</div>