import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SyslinkColumn } from 'projects/libraries/syslink-components/src/lib/helpers/SyslinkColumn';
import { Customer } from './customer.model';
import { CustomersService } from './customers.service';
import { PageComponent } from 'projects/libraries/syslink-components/src/public-api';

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.scss']
})
export class CustomersComponent extends PageComponent {
  
  constructor(
    public customersService: CustomersService,
    protected override activatedRoute: ActivatedRoute,
  ) {
    super();
  }

  public onAddButtonClicked () {
    this.router.navigate(['new'], { relativeTo: this.activatedRoute });
  }
  public onRowDoubleClicked(element: Customer): void {
    this.router.navigate([element.ThirdId.Id], { relativeTo: this.activatedRoute });
  }

}
