<dx-popover
  [target]="targetElementSelector"
  [showEvent]="showConfiguration"
  [position]="position"
  [width]="width"
>
  <div class="">
    <div class="bg-light text-center p-3" *ngIf="title">
      <label >{{ title | translate }}</label>
    </div>
    <div class="px-3">
      <ng-content select="[popover-content]"></ng-content>
    </div>
  </div>
</dx-popover>
