import { Injectable } from '@angular/core';
import { LoadOptions } from 'devextreme/data';
import { ODataService } from 'projects/erp-app/src/app/core/services/oData.service';
import { ThirdAddress } from './third-address.model';

@Injectable({
  providedIn: 'root'
})
export class ThirdAddressService extends ODataService<ThirdAddress>{
  public url: string = "Address";
  constructor() {
    super();
  }
  public override async getInstance(): Promise<any> {
    return new ThirdAddress();
  };

  public override format(element: ThirdAddress): any {
    var result: any = {
      ...element,
      AddressTypeId: element.AddressTypeId?.Id,
      LocalityId: element.LocalityId?.Id,
      PostalCodeId: element.PostalCodeId?.Id,
      ThirdId: element.ThirdId?.Id,
    };

    return result;
  }


  public override defaultOptions: LoadOptions<ThirdAddress> = {
    expand: [
      "PostalCodeId",
      "LocalityId.CountryId",
      "AddressTypeId"
    ]
  }
}
