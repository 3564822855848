import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { ModalComponent } from '../modal.component';
import { getNew } from '../../helpers/tools';

@Component({
  selector: 'syslink-base-modal',
  templateUrl: './base-modal.component.html',
  styleUrls: ['./base-modal.component.scss']
})
export class BaseModalComponent<T> {
  public element: T = <T>getNew<T>();
  public elementChange: EventEmitter<T> = new EventEmitter<T>();

  @ViewChild('modal') modal?: ModalComponent;

  @Output() public validate: EventEmitter<T> = new EventEmitter<T>();

  public close() {
    this.modal?.close();
  }

  public open(data?: any) {
    this.modal?.open(data);
  }

  public onValidateButtonClicked(e: any) {
    this.validate.emit(e);
  }
}
