import { Injectable } from '@angular/core';
import { ApiService } from '../../../core/api.service';
import { Third } from '../../../thirds/thirds/third.model';

@Injectable({
  providedIn: 'root'
})
export abstract class DocumentsService {
  public url: string = 'saleDocument';

  constructor(
    private api: ApiService,
    // public reportsService: ReportsService
  ) {

  }

  public TransformToType(url: string, toType: string, documentId: number, type:string, idList: number[] = [], third:number|undefined = undefined, fromDate: Date = new Date(), toDate: Date = new Date()): Promise<number> {
    return this.api.sendRequest('/api/' + url + '/' + documentId + '/transformTo' + toType, 'POST', { idList, type, third, fromDate, toDate });
  }

  public CopyToType(url: string, toType: string, documentId: number, subject: string, third: number, idList: number[] = [], fromDate: Date = new Date(), toDate: Date = new Date()): Promise<number> {
    return this.api.sendRequest('/api/' + url + '/' + documentId + '/copyTo' + toType, 'POST', { idList, subject, third, fromDate, toDate });
  }

  public ConvertToType(url: string, toType: string, documentId: number, subject: string, third: number, idList: number[] = [], fromDate: Date = new Date(), toDate: Date = new Date()): Promise<number> {
    return this.api.sendRequest('/api/' + url + '/' + documentId + '/convertTo' + toType, 'POST', { idList, subject, third });
  }
}
