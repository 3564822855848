import { Component, Input, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConfirmModalComponent, NotificationsService, PageComponent } from 'projects/libraries/syslink-components/src/public-api';
import { StockMoveIncoming } from '../stock-move-incomings/stock-move-incomings/stock-move-incoming.model';
import { DocumentCreateModalComponent } from '../../base/documents/document-create-modal/document-create-modal.component';
import { StockMoveIncomingGridComponent } from '../stock-move-incomings/stock-move-incoming-grid/stock-move-incoming-grid.component';
import { StockMoveIncomingStatusSelectModalComponent } from '../stock-move-incomings/stock-move-incoming-status/stock-move-incoming-status-select-modal/stock-move-incoming-status-select-modal.component';
import { ContextMenuItemAction } from 'projects/libraries/syslink-components/src/lib/context-menus/context-menu-item-action';
import { StockMoveIncomingContextMenuItemActionCode, StockMoveIncomingsService } from '../stock-move-incomings/stock-move-incomings/stock-move-incomings.service';
import { StockMoveIncomingHeaderHeaderStatusesService } from '../stock-move-incomings/stock-move-incoming-header-header-statuses/stock-move-incoming-header-header-statuses.service';
import { ContextMenuItemClickEvent } from 'devextreme/ui/file_manager';
import { StockMoveIncomingHeaderStatus } from '../stock-move-incomings/stock-move-incoming-header-statuses/stock-move-incoming-header-status.model';

@Component({
  selector: 'app-stock-move-customer-incomings',
  templateUrl: './stock-move-customer-incomings.component.html',
  styleUrls: ['./stock-move-customer-incomings.component.scss']
})
export class StockMoveCustomerIncomingsComponent extends PageComponent {
  @Input() public filter: string | string[] = ["Type eq 'customer'"];
  public expand: Array<string> = ['ThirdId', 'ResponsibleUserId', 'Statuses', 'CurrentStatusLink.StatusId'];

  @Input() public canRefresh: boolean = false;
  @Input() public canDelete: boolean = false;
  @Input() public delete: Function = (e: any) => { };

  @ViewChild('createModal') createModal?: DocumentCreateModalComponent
  @ViewChild('grid') grid?: StockMoveIncomingGridComponent;
  @ViewChild("confirmModal") confirmModal?: ConfirmModalComponent;
  @ViewChild("statusModal") statusModal?: StockMoveIncomingStatusSelectModalComponent;

  public customerFilter: string | string[] = ["Roles/any(r:r/Code eq 'customer')"];

  public purchaseOrderHeader: StockMoveIncoming = new StockMoveIncoming();
  // ContextMenuItemAction
  // ---------------------
  public contextMenuItems: ContextMenuItemAction[] = [
    { code: StockMoveIncomingContextMenuItemActionCode.PrintToPDF, text: "Preview to PDF", icon: "print", onItemClick: (e: any) => this.onMenuItemClicked(e), visible: this.authService.hasPermission('stockMove.customerincomings.list.contextMenu.preview') },
    { code: StockMoveIncomingContextMenuItemActionCode.PrintToZIP, text: "Download to ZIP", icon: "print", onItemClick: (e: any) => this.onMenuItemClicked(e), visible: this.authService.hasPermission('stockMove.customerincomings.list.contextMenu.preview') },
    { code: StockMoveIncomingContextMenuItemActionCode.Status, text: "Change status", icon: "edit", onItemClick: (e: any) => this.onMenuItemClicked(e), visible: this.authService.hasPermission('stockMove.customerincomings.list.contextMenu.changeStatus') },
  ];

  constructor(
    public stockMoveIncomingsService: StockMoveIncomingsService,
    public override activatedRoute: ActivatedRoute,
    private stockMoveIncomingHeaderHeaderStatusesService: StockMoveIncomingHeaderHeaderStatusesService
  ) { super(); }

  public onAddButtonClicked() {
    this.createModal?.open();
  };

  public onCreateValidated(element: any) {
    if (!element.ThirdId.Id) {
      NotificationsService.sendErrorMessage("Please select third");
      return;
    }
    if (element.ThirdId.Blocked == true) {
      NotificationsService.sendErrorMessage("Third is blocked");
      return;
    }
    this.router.navigateByUrl('/stockmoves/customerincomings/new?ThirdId=' + element.ThirdId.Id);
  }

  // ContextMenu
  // -----------
  public async onMenuItemClicked(e: ContextMenuItemClickEvent) {

    if (!this.grid) return;

    switch (e.itemData.code) {
      // Printing
      // ---------
      case StockMoveIncomingContextMenuItemActionCode.PrintToPDF:
        this.showConfirmModal("Would you like preview selected rows?", e.itemData);
        break;
      case StockMoveIncomingContextMenuItemActionCode.PrintToZIP:
        this.showConfirmModal("Would you like download selected rows?", e.itemData);
        break;
      case StockMoveIncomingContextMenuItemActionCode.Status:
        this.showStatusModal("Would you like change status selected rows?", e.itemData);
        break;
    }
  }

  private showConfirmModal(text: string, data: ContextMenuItemAction) {
    if (!this.confirmModal) return;

    this.confirmModal.title = data.text ? data.text : "Confirm";
    this.confirmModal.content = text;
    this.confirmModal.open({ rows: this.grid?.getSelectedRows(data.rowData), action: data.code });
  }
  private showStatusModal(text: string, data: ContextMenuItemAction) {
    if (!this.statusModal) return;

    this.statusModal.title = data.text ? data.text : "Confirm";
    this.statusModal.content = text;
    this.statusModal.open({ rows: this.grid?.getSelectedRows(data.rowData), action: data.code });
  }

  public async onConfirmModalValidated(modalData: { rows: StockMoveIncoming[], action: string } | any) {
    switch (modalData.action) {
      case StockMoveIncomingContextMenuItemActionCode.PrintToPDF:
        await this.stockMoveIncomingsService.printRows(modalData.rows, "pdf", "CustomerIncoming", "StockMoveCustomerIncoming");
        break;
      case StockMoveIncomingContextMenuItemActionCode.PrintToZIP:
        await this.stockMoveIncomingsService.printRows(modalData.rows, "zip", "CustomerIncoming", "StockMoveCustomerIncoming");
        break;
      case StockMoveIncomingContextMenuItemActionCode.Status:
        await this.updateStatus(modalData.rows, modalData.IncomingStatus);
        break;
    }

    this.grid?.refresh();
    this.confirmModal?.close();
    this.statusModal?.close();
  }
  // ------------------------------------------------------------------------------------------------

  // Actions
  // -------
  private async updateStatus(selectedRows: StockMoveIncoming[], status: StockMoveIncomingHeaderStatus) {
    if (!this.stockMoveIncomingsService.canUpdateStatus(selectedRows, status)) return;
    selectedRows.sort((a, b) => { return a.Date.getTime() - b.Date.getTime(); });

    for (var element of selectedRows) {
      if (element.Id) {
        const link: any = {
          HeaderId: { Id: element.Id },
          StatusId: { Id: status.Id },
          Date: new Date()
        };
        await this.updateDocumentStatus(link);
      }
    }
    NotificationsService.sendSuccess('Status updated');
  }
  private async updateDocumentStatus(link: any) {
    await this.stockMoveIncomingHeaderHeaderStatusesService.insert(this.stockMoveIncomingHeaderHeaderStatusesService.format(link));
  }
}