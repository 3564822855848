import { Component, Input, OnInit } from '@angular/core';
import { CreditsafeData } from '../creditsafe.model';
import { ChartSerie } from 'projects/libraries/syslink-components/src/lib/helpers/ChartSeries';
import { CreditsafesService } from '../creditsafe.service';

@Component({
  selector: 'app-creditsafe-credit-limit-history',
  templateUrl: './creditsafe-credit-limit-history.component.html',
  styleUrls: ['./creditsafe-credit-limit-history.component.scss']
})
export class CreditsafeCreditLimitHistoryComponent implements OnInit {
  @Input() element: CreditsafeData = new CreditsafeData();
  public data: any = [];
  public series: ChartSerie[] = [
    new ChartSerie({ argumentField: "Date", valueField: "Value", name: "Value" })
  ];

  constructor(
    private creditsafesService: CreditsafesService
  ) { }

  ngOnInit(): void {
    (this.element.Report?.AdditionalInformation?.CreditLimitHistory ?? []).reverse().map((e: any) => {
      this.data.push({
        Date: this.creditsafesService.formatDate(new Date(e.Date)),
        Value: e.Value ? (e.Value * 1) : undefined,
      })
    });
  }
}
