<syslink-popover [targetElementSelector]="'#filter'" title="Filter by">
  <div popover-content>
    <div class="py-2">
      <syslink-tagbox
        label="Thirds"
        [store]="thirdsService.store"
        [filter]="popoverThirdsFilters"
        [select]="['Fullname', 'Id', 'Blocked']"
        displayKey="Fullname"
        [(model)]="thirds"
        (modelChange)="onThirdChanged($event)"
      ></syslink-tagbox>

      <syslink-tagbox
        #taskSelect
        label="Task"
        [store]="tasksService.store"
        displayKey="FormattedName"
        [filter]="popoverTasksilters"
        [expand]="['Users', 'ThirdId', 'UserGroups']"
        [select]="['Id', 'FormattedName', 'ThirdId.Id', 'ThirdId.Fullname']"
        [(model)]="tasks"
        (modelChange)="onTaskChanged($event)"
      ></syslink-tagbox>

      <syslink-tagbox
        label="Type"
        [store]="workTypesService.store"
        [(model)]="types"
        (modelChange)="onTypeChanged($event)"
      ></syslink-tagbox>

      <syslink-tagbox
        label="User"
        [store]="usersService.store"
        valueKey="Oid"
        [filter]="popoverUsersFilters"
        [expand]="['ThirdId.TaskThirds']"
        displayKey="Name"
        [(model)]="users"
        (modelChange)="onUserChanged($event)"
      ></syslink-tagbox>

      <syslink-tagbox
        #tagBoxUserGroups
        *ngIf="userGroupsService"
        label="UserGroups"
        [store]="userGroupsService.store"
        valueKey="Oid"
        displayKey="Name"
        [select]="['Id', 'Name']"
        [(model)]="userGroups"
        (modelChange)="onUserGroupsChanged($event)"
      ></syslink-tagbox>
    </div>
  </div>
</syslink-popover>
