import { Injectable } from '@angular/core';
import { ODataService } from '../../core/services/oData.service';
import { NumberPlate } from './number-plate.model';

@Injectable({
  providedIn: 'root'
})
export class NumberPlatesService extends ODataService<NumberPlate> {
  public override url: string = 'NumberPlate';

}
