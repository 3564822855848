import { Component, Input, ViewChild } from '@angular/core';
import { ModalComponent } from 'projects/libraries/syslink-components/src/public-api';
import { CreditsafeData } from '../creditsafe.model';

@Component({
  selector: 'app-creditsafe-modal',
  templateUrl: './creditsafe-modal.component.html',
  styleUrls: ['./creditsafe-modal.component.scss']
})
export class CreditsafeModalComponent {
  @Input() public vatNumber?: string;
  private _element?: CreditsafeData;
  @Input()
  set element(value: any) {
    this._element = value;
  }
  get element(): any | undefined {
    return this._element;
  }
  @ViewChild('modal') modal?: ModalComponent;

  public open() {
    this.modal?.open();
  }

  public close() {
    this.modal?.open();
  }
}