import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FilterDescriptor } from 'devextreme/data';
import { ModalComponent, NotificationsService } from 'projects/libraries/syslink-components/src/public-api';
import { Product } from '../product.model';
import { ProductGridComponent } from '../product-grid/product-grid.component';

@Component({
  selector: 'app-product-picker',
  templateUrl: './product-picker.component.html',
  styleUrls: ['./product-picker.component.scss']
})
export class ProductPickerComponent implements OnInit {
  public searchInput: string = '';
  @Input() public searchFields: string[] = [];
  @Input() public permissionKey: string = 'list';
  @Input() public sellable: boolean = false;
  @Input() public purchasable: boolean = false;
  @Input() public stockable: boolean = false;
  public filters: any[] = [];
  @Input() expand: string | string[] = ["ProductCategoryId", "DefaultUnitId"];
  @ViewChild('productModal') private productModal?: ModalComponent;
  @ViewChild('productsGrid') private productsGrid?: ProductGridComponent;

  @Output('noResult') private noResult: EventEmitter<string> = new EventEmitter<string>();
  @Output('singleResult') private singleResult: EventEmitter<Product> = new EventEmitter<Product>();
  @Output('multipleResult') private multipleResult: EventEmitter<Product[]> = new EventEmitter<Product[]>();
  @Output() public onAddNewProductBtn: EventEmitter<void> = new EventEmitter<void>();

  ngOnInit(): void {
    this.initFilters();
  }

  private initFilters() {
    this.filters = [];
    if (this.sellable) {
      this.filters.push(["Sellable eq true"]);
    }
    if (this.purchasable) {
      this.filters.push(["Purchasable eq true"]);
    }
    if (this.stockable) {
      this.filters.push(["Stockable eq true"]);
    }
  }

  public onEnterKeyPressed(e?: any) {
    let gridInstance = this.productsGrid?.gridComponent?.grid?.instance;
    if (!gridInstance) return;

    var searchText = this.searchInput;
    if (!searchText) {
      gridInstance.clearFilter();
      return;
    }

    let filters: FilterDescriptor[] = [];
    this.searchFields.forEach((field, index) => {
      filters.push([field, 'contains', searchText]);
      if (index < this.searchFields.length - 1) {
        filters.push('or');
      }
    });

    filters = [
      this.filters,
      'and',
      filters,
    ]

    gridInstance?.filter(filters);
    gridInstance.refresh().then((r:any) =>{
      const resultCount = gridInstance?.totalCount();
      switch (resultCount) {
        case 0: this.onNoResult(e.model);
          break;
        case 1: this.onSingleResult(gridInstance?.getDataSource().items()[0]);
          break;
        default:
          this.onMultipleResults(e.model);
      }
    });
    this.productModal?.close();
  }

  public onNoResult(name: string) {
    this.noResult.emit(this.searchInput);
  }

  public onSingleResult(product: Product) {
    if(product.Blocked==true){
      NotificationsService.sendErrorMessage("Product is blocked");
      return;
    }
    
    this.singleResult.emit(product);
    this.productModal?.close();
  }

  public onMultipleResults(name: string) {
    if (this.multipleResult.observed) {
      let gridInstance = this.productsGrid?.gridComponent?.grid?.instance;
      if (!gridInstance) return;

      this.multipleResult.emit(gridInstance?.getDataSource().items());
    }
    else {
      if (!this.productModal) return;
      this.productModal?.open();
    }

  }

  public async onShowAdvancedSearchButtonClicked(e: any) {
    if (!this.productModal) return;

    this.resetDatasource();
    this.productModal?.open();
  }

  public resetDatasource() {
    this.initFilters();
    let gridInstance = this.productsGrid?.gridComponent?.grid?.instance;
    if (!gridInstance) return;
    gridInstance.clearFilter();
    gridInstance.refresh();
  }

  public onRowDblClicked(product: any) {
    this.onSingleResult(product);
  }
}
