import { TaxRate } from "../../base/tax-rates/tax-rate";
import { Entity } from "../../core/models/entity";
import { SubjugationCategory } from "../subjugation-categories/subjugation-category.model";
import { TaxType } from "../tax-types/tax-type.model";

export class Tax  extends Entity {
    public Code:string = "";
    public Alias:string = "";
    public UsedForSale?:boolean;
    public TaxRateId?:TaxRate;
    public TaxTypeId?:TaxType;
    public SubjugationCategoryId?:SubjugationCategory;
    public IsVisible?:boolean;

    constructor(entity?: Partial<Tax>) {
        super();
        Object.assign(this, entity);
    }
}
