<syslink-grid
    #grid
    [canView]="canView"
    [canRefresh]="canRefresh"
    [canSort]="canSort"
    [canUpdate]="canUpdate"
    [canFilter]="canFilter"
    [canDelete]="canDelete"
    [canDblClck]="canDblClck"
    [columns]="columns"
    [expand]="expand"
    [canAdd]="false"
    [storageKey]="storageKey"
    [(items)]="items"
    [canCustomize]="false"
    (beforeDelete)="beforeDelete.emit($event)"
    (afterUpdate)="afterUpdate.emit($event)"
    [options]="{
      pager: { visible: false }
    }"
    [toolbaActions]="toolbaActions"
    (refresh)="itemsChange.emit()"
    (doubleClick)="rowDoubleClicked.emit($event)"
></syslink-grid>
