import { Component } from '@angular/core';
import { PageComponent } from '../../../../../../libraries/syslink-components/src/public-api';
import { environment } from 'projects/erp-app/src/environments/environment';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent extends PageComponent {
  public environment = environment;
}