import { Injectable } from '@angular/core';
import { ODataService } from '../../../core/services/oData.service';
import { SaleDocumentStatus } from './sale-document-status.model';

@Injectable({
  providedIn: 'root'
})
export class SaleDocumentStatusesService<TSaleDocumentStatus extends SaleDocumentStatus> extends ODataService<TSaleDocumentStatus> {
  public url: string = 'SaleDocumentHeaderStatus';
 
  constructor() {
    super();
  }
}
