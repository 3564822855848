import { PurchaseDocument } from "../../purchase-documents/purchase-documents/purchase-document.model";
import { PurchaseCreditNoteFinance } from "../purchase-credit-note-finances/purchase-credit-note-finance.model";
import { PurchaseCreditNoteHeaderHeaderStatus } from "../purchase-credit-note-header-header-statuses/purchase-credit-note-header-header-status.model";

export class PurchaseCreditNote extends PurchaseDocument {
    public override DocumentFinances?: PurchaseCreditNoteFinance[];
    public override CurrentStatusLink?: PurchaseCreditNoteHeaderHeaderStatus;
    public override Statuses: PurchaseCreditNoteHeaderHeaderStatus[] = [];

    constructor(entity?: Partial<PurchaseCreditNote>) {
        super();
        Object.assign(this, entity);
    }
}
