import { DocumentDelay } from "../../../base/documents/document-delays/document-delay";
import { PriceList } from "../price-lists/price-list";
import { PaymentMethod } from "../../../base/payments/payment-methods/payment-method";
import { TaxRate } from "../../../base/tax-rates/tax-rate";
import { Entity } from "../../../core/models/entity";
import { Third } from "../../thirds/third.model";
import { NumberPlate } from "../../../vise/number-plates/number-plate.model";

export class Customer extends Entity {
    public ThirdId: Third = new Third();
    public HourlyRate?:number;
    public PriceListId? : PriceList;
    public PaymentMethodId? : PaymentMethod;
    public SaleTaxRateId? : TaxRate;
    public Debt?:number;
    public Balance?:number;
    public SaleQuoteDocumentDelayId?: DocumentDelay;
    public SaleInvoiceDocumentDelayId?: DocumentDelay;
    public SaleCreditNoteDocumentDelayId?: DocumentDelay;
    public InTaxOpenAmount?: number;
    public InTaxDueAmount?: number;    
    public NumberPlates?: NumberPlate[];
    
    constructor(customer?: Partial<Customer>) {
        super();
        Object.assign(this, customer);
    }
}
