<app-task-grid
    #taskGrid
    [permissionKey]="'tasks.list'"
    [filter]="['ThirdId.Id eq ' + element.Id]"
    (add)="onAddButtonClicked()"
    (rowDoubleClicked)="onTaskRowDoubleClicked($event)"
    [columns]="TaskColumns"
></app-task-grid>
<app-task-modal
    #taskModal
    (validate)="onValidateModalTaskButtonClicked($event)"
></app-task-modal>