import { Component, EventEmitter, Input, Output} from '@angular/core';

import { StockMoveDocumentLine } from '../../stock-move-document-lines/stock-move-document-line.model';
import { StockMoveDocumentLinesService } from '../../stock-move-document-lines/stock-move-document-lines.service';
import { StockMoveDocument } from '../stock-move-document.model';
import { StockMoveDocumentsService } from '../stock-move-documents.service';
import { getNew } from 'projects/libraries/syslink-components/src/lib/helpers/tools';
import { StockMoveDocumentStatus } from '../../stock-move-document-statuses/stock-move-document-status.model';
import { DeliveryTimesService } from 'projects/erp-app/src/app/base/delivery-times/delivery-times.service';

@Component({
  selector: 'app-stock-move-document-other',
  templateUrl: './stock-move-document-other.component.html',
  styleUrls: ['./stock-move-document-other.component.scss']
})
export class StockMoveDocumentOtherComponent<
  TStockMoveDocument extends StockMoveDocument,
  TStockMoveDocumentService extends StockMoveDocumentsService<StockMoveDocument,StockMoveDocumentStatus>,
  TStockMoveDocumentLine extends StockMoveDocumentLine,
  TStockMoveDocumentLineService extends StockMoveDocumentLinesService<StockMoveDocumentLine>
> {
  @Input() public element: TStockMoveDocument = <TStockMoveDocument>getNew<TStockMoveDocument>();
  @Output() public elementChange: EventEmitter<TStockMoveDocument> = new EventEmitter<TStockMoveDocument>();

  @Output() public globalDiscountChanged: EventEmitter<number> = new EventEmitter<number>();

  @Input() public disabled: boolean = false;
  @Input() public reportType: String = '';

}
