<app-stock-move-outgoing-grid
*ngIf="filter"
    #GridComponent
    [permissionKey]="'stockMoveSupplierOutgoings.list'"
    [canUpdate]="false"
    [filter]="filter"
    (add)="onAddButtonClicked()"
    detailsUrl="/stockmoves/supplieroutgoings"
    storageKey="stock-move-supplieroutgoings"
    [columns]="columns"
></app-stock-move-outgoing-grid>
