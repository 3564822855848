import { TranslatableString } from "../../base/translations/translatable-strings/translatable-string";
import { Entity } from "./entity";

export abstract class BasicEntity extends Entity {
    public Code?: string;
    public NameTranslationId?: TranslatableString;
    public Name?: string;
    
    constructor(basicEntity?: Partial<BasicEntity>) {
        super(basicEntity);
        Object.assign(this, basicEntity);
    }
}
