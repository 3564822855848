import { Injectable } from '@angular/core';
import { ODataService } from '../../core/services/oData.service';
import { MailTemplateType } from './mail-template-type.model';

@Injectable({
  providedIn: 'root'
})
export class MailTemplateTypesService extends ODataService<MailTemplateType> {
  public url = 'MailTemplateType';
}
