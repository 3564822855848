<div class="row">
    <!-- Toolbar -->
    <syslink-toolbar [actions]="toolbarActions"></syslink-toolbar>
    <ng-container *ngFor="let element of elements">
        <div class="col-6">
            <syslink-card
                [icon]="element.ToThirdId?.IsProfessional == true ? 'building':'user'"
                [model]="element"
                [title]="element.ThirdRelationTypeId?.Name"
                id="relation-{{element.Id}}"
                [contextItems]="cardContextItems"
                [boxText]="(element.IsPublic ? 'Public' : 'Private') | translate"
                (contextMenuItemClicked)="onContextMenuItemClicked($event)"
            >
                <syslink-link
                    url="/basics/thirds/thirds/{{element.ToThirdId?.Id}}"
                    class="fst-italic fw-light text-truncate"
                    [text]="element.ToThirdId?.Fullname"
                    [newTab]="true"
                >
                </syslink-link>
            </syslink-card>
        </div>
    </ng-container>
</div>

<!-- Relations -->
<syslink-modal
    #editModal
    title="Relation"
    width="300px"
    (onValidate)="element.Id ? onUpdate(element) : onCreate(element)"
    [validateText]="(element.Id ? 'Save' : 'Create')"
    cancelText="Cancel"
>
    <app-third-relation-form [(element)]="element"></app-third-relation-form>
</syslink-modal>
<syslink-confirm-modal
    #deleteConfirm
    title="Delete"
    content="DeleteConfirmMessage"
    validateText="Confirm"
    cancelText="Cancel"
    (onValidate)="onDelete(element)"
></syslink-confirm-modal>