import { BasicEntity } from "../../models/basic-entity";
import { UserGroup } from "../user-groups/user-group.model";
import { UserPermission } from "../user-permissions/user-permission.model";
import { User } from "../users/user.model";

export class UserRole  extends BasicEntity {

    public IsActive:boolean = false;
    public Users?:User[];
    public Permissions:UserPermission[] = [];
    public UserGroups?:UserGroup[];

    constructor(user?: Partial<UserRole>) {
        super();
        Object.assign(this, user);
    }
}
