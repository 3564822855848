import { Component, Input, ViewChild } from '@angular/core';
import { SyslinkColumn } from 'projects/libraries/syslink-components/src/lib/helpers/SyslinkColumn';
import { Third } from '../../../third.model';
import { ViewComponent } from 'projects/libraries/syslink-components/src/lib/helpers/view/view.component';
import { PurchaseOrdersService } from 'projects/erp-app/src/app/purchases/purchase-orders/purchase-orders/purchase-orders.service';
import { PurchaseOrderGridComponent } from 'projects/erp-app/src/app/purchases/purchase-orders/purchase-order-grid/purchase-order-grid.component';

@Component({
  selector: 'app-third-document-relation-purchase-orders',
  templateUrl: './third-document-relation-purchase-orders.component.html',
  styleUrls: ['./third-document-relation-purchase-orders.component.scss']
})
export class ThirdDocumentRelationPurchaseOrdersComponent extends ViewComponent  {
  @Input() public element: Third = new Third();
  
  constructor(
    private purchaseOrdersService: PurchaseOrdersService,
  ){
    super();
  }

  @ViewChild('orderGridComponent') purchaseOrderGridComponent: PurchaseOrderGridComponent = new PurchaseOrderGridComponent(this.purchaseOrdersService);
  public PurchaseOrderColumns: SyslinkColumn[] = [
    new SyslinkColumn({ field: "No", label: "GRID.CAPTION.NO", width: 150, sortIndex: 2, sortDirection: 'desc' }),
    new SyslinkColumn({ field: "Subject", label: "Subject" }),
    new SyslinkColumn({ field: "CurrentStatusLink.StatusId.Name", label: "Status", cellTemplate: 'statusText-cell', data: { getStatusLabel: this.purchaseOrderGridComponent.getStatusCellLabel, getStatusDate: this.purchaseOrderGridComponent.getStatusCellDate, getStatusClass: this.purchaseOrderGridComponent.getStatusCellClass, getStatusColor: this.purchaseOrderGridComponent.getStatusCellColor }, width: 120 }),
    new SyslinkColumn({ field: "Deadline", label: 'PurchaseOrder.Validity', type: "date" }),
    new SyslinkColumn({ field: "ExternalReference", label: "External reference", visible: true, width: 150 }),
    new SyslinkColumn({ field: "ExTaxTotal", label: "exTaxTotal", type: "number", cellTemplate: 'currency-cell', data: { subModuleCode: 'Purchases.Order', key: 'Precision.PurchaseOrderHeader.ExTaxTotal' }, width: 80 }),
    new SyslinkColumn({ field: "InTaxTotal", label: "Total", type: "number", cellTemplate: 'currency-cell', data: { subModuleCode: 'Purchases.Order', key: 'Precision.PurchaseOrderHeader.InTaxTotal' }, width: 80 }),
    new SyslinkColumn({ field: "ResponsibleUserId.Fullname", label: "Responsible", width: 120 }),
    new SyslinkColumn({ field: "Date", label: "Date", type: "date", visible: false, width: 150 }),
    new SyslinkColumn({ field: "AmountRemaining", label: 'Remaining amount', type: "number", visible: false, cellTemplate: 'currency-cell', data: { subModuleCode: 'Purchases.Order', key: 'Precision.PurchaseOrderHeader.AmountRemaining' }, width: 80, filterable: false, headerFilterable: false, sortable: false }),
    new SyslinkColumn({ field: "FormattedStructuredCommunication", label: 'Structured communication', visible: false, editable: false }),
    new SyslinkColumn({ field: "CurrentStatusLink.StatusId.Sequence", label: 'status order', sortIndex: 1, sortDirection: 'asc', width: 0, visible: false, filterable: false, headerFilterable: false, sortable: false })
  ];
  
  onAddButtonClicked() {
    window.open('/purchases/orders/new?ThirdId='+this.element.Id);
  }
 
}