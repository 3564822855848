import { Injectable } from '@angular/core';
import { LoadOptions } from 'devextreme/data';
import { ODataService } from '../../../core/services/oData.service';
import { StockMoveDocumentHeaderStatus } from './stock-move-document-header-status.model';

@Injectable({
  providedIn: 'root'
})
export class StockMoveDocumentHeaderStatusesService<TStockMoveDocumentHeaderStatus extends StockMoveDocumentHeaderStatus> extends ODataService<TStockMoveDocumentHeaderStatus> {
  public url: string = 'StockMoveDocumentHeaderHeaderStatus';

  public override defaultOptions: LoadOptions = {
    expand: ["StatusId"]
  };
}
