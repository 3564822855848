import { Component, Input } from '@angular/core';
import { SyslinkColumn } from 'projects/libraries/syslink-components/src/lib/helpers/SyslinkColumn';
import { CurrenciesService } from '../../base/currencies/currency.service';
import { TaxRatesService } from '../../base/tax-rates/tax-rates.service';
import { UnitsService } from '../../base/units/unit.service';
import { SuppliersService } from '../../thirds/suppliers/suppliers.service';
import { BrandsService } from '../brands/brand.service';
import { SupplierCatalog } from './supplier-catalog.model';
import { BaseGridComponent } from 'projects/libraries/syslink-components/src/lib/grid/base-grid/base-grid.component';
import { AuthService } from '../../core/auth/auth.service';

@Component({
  selector: 'app-supplier-catalogs',
  templateUrl: './supplier-catalogs.component.html',
  styleUrls: ['./supplier-catalogs.component.scss']
})
export class SupplierCatalogsComponent extends BaseGridComponent<SupplierCatalog>{

  @Input() storageKey: string = 'grid-products-purchase-supplier-catalog';
  @Input() supplierCatalogs: SupplierCatalog[] = [];

  public columns: SyslinkColumn[] = [
    new SyslinkColumn({ field: 'IsFavorite', showEditorAlways: true, label: 'Favorite', type: 'boolean', cellTemplate: 'icon-cell', editCellTemplate: 'icon-cell', data: { icon: 'fa-star', activeClass: 'cl-accent', inactiveClass: 'cl-gray' }, width: 80 }),
    new SyslinkColumn({ field: 'SupplierId', label: 'Supplier name', cellTemplate: 'select-value', editCellTemplate: 'select-cell', data: { service: this.suppliersService, expand: 'ThirdId', displayKey: 'ThirdId.Fullname' }, sortIndex: 1, sortDirection: 'asc' }),
    new SyslinkColumn({ field: 'SupplierReference', label: 'Supplier reference' }),
    new SyslinkColumn({ field: 'ExTaxGrossPrice', label: 'Gross price excl. VAT', type: 'number', data: { subModuleCode: 'Products.SupplierCatalogs', key: 'Precision.Product.GrossPriceExTax' }, cellTemplate: 'currency-cell' }),
    new SyslinkColumn({ field: 'DiscountId', label: 'Discount', cellTemplate: 'object-discount-cell', editCellTemplate: 'edit-object-discount-cell', data: { discountTypeField: 'IsDiscountFixed' } }),
    new SyslinkColumn({ field: 'ExTaxNetPrice', label: 'Net price excl. VAT', type: 'number', editable: false, sortIndex: 3, sortDirection: 'asc', data: { subModuleCode: 'Products.SupplierCatalogs', key: 'Precision.Product.NetPriceExTax' }, cellTemplate: 'currency-cell' }),
//    new SyslinkColumn({ field: 'CurrencyId', label: 'Currency', type: 'string', cellTemplate: 'select-value', editCellTemplate: 'select-cell', data: { service: this.currenciesService, displayKey: 'Name' } }),
    new SyslinkColumn({ field: 'UnitId', label: 'Unit', type: 'string', cellTemplate: 'select-value', editCellTemplate: 'select-cell', data: { service: this.unitsService, displayKey: 'Name' } }),
    new SyslinkColumn({ field: 'TaxRateId', label: 'Tax rate', type: 'string', cellTemplate: 'select-value', editCellTemplate: 'select-cell', data: { service: this.taxRatesService, displayKey: 'Name' } }),
    new SyslinkColumn({ field: 'BrandId', label: 'Brand', type: 'select', cellTemplate: 'select-value', editCellTemplate: 'select-cell', data: { service: this.brandsService, displayKey: 'Name' } }),
    new SyslinkColumn({ field: 'Make', label: 'Make', type: 'string' }),
    new SyslinkColumn({ field: 'MinimumQty', label: 'Minimum quantity', type: 'number' }),
    new SyslinkColumn({ field: 'SupplierStock', label: 'Stock', type: 'number' }),
  ];

  constructor(
    public taxRatesService: TaxRatesService,
    public brandsService: BrandsService,
    public unitsService: UnitsService,
    public currenciesService: CurrenciesService,
    public suppliersService: SuppliersService,
  ) {
    super();
  }

}
