import { Component, Input, ViewChild } from '@angular/core';
import { SaleQuoteGridComponent } from 'projects/erp-app/src/app/sales/sale-quotes/sale-quote-grid/sale-quote-grid.component';
import { SaleQuotesService } from 'projects/erp-app/src/app/sales/sale-quotes/sale-quotes/sale-quotes.service';
import { SyslinkColumn } from 'projects/libraries/syslink-components/src/lib/helpers/SyslinkColumn';
import { Third } from '../../../third.model';
import { ViewComponent } from 'projects/libraries/syslink-components/src/lib/helpers/view/view.component';

@Component({
  selector: 'app-third-document-relation-sale-quotes',
  templateUrl: './third-document-relation-sale-quotes.component.html',
  styleUrls: ['./third-document-relation-sale-quotes.component.scss']
})
export class ThirdDocumentRelationSaleQuotesComponent  extends ViewComponent  {
  @Input() public element: Third = new Third();
  
  constructor(
    private saleQuotesService: SaleQuotesService,
  ){
    super();
  }

  @ViewChild('quoteGridComponent') quoteGridComponent: SaleQuoteGridComponent = new SaleQuoteGridComponent(this.saleQuotesService);
  public SaleQuoteColumns: SyslinkColumn[] = [
    new SyslinkColumn({ field: "No", label: 'GRID.CAPTION.NO', width: 150, sortIndex: 2, sortDirection: 'desc' }),
    new SyslinkColumn({ field: "Subject", label: "Subject" }),
    new SyslinkColumn({ field: "CurrentStatusLink.StatusId.Name", label: "Status", cellTemplate: 'statusText-cell', data: { getStatusLabel: this.quoteGridComponent.getStatusCellLabel, getStatusDate: this.quoteGridComponent.getStatusCellDate, getStatusClass: this.quoteGridComponent.getStatusCellClass, getStatusColor: this.quoteGridComponent.getStatusCellColor }, width: 120 }),
    new SyslinkColumn({ field: "Deadline", label: "SaleQuote.Validity", type: "date", visible: false, width: 150 }),
    new SyslinkColumn({ field: "ExternalReference", label: "External reference", visible: true, width: 150 }),
    new SyslinkColumn({ field: "ExTaxTotal", label: "exTaxTotal", type: "number", data: { subModuleCode: 'Sales.Quote', key: 'Precision.SaleQuoteHeader.ExTaxTotal' }, cellTemplate: 'currency-cell', width: 80 }),
    new SyslinkColumn({ field: "InTaxTotal", label: "Total", type: "number", data: { subModuleCode: 'Sales.Quote', key: 'Precision.SaleQuoteHeader.InTaxTotal' }, cellTemplate: 'currency-cell', width: 80 }),
    new SyslinkColumn({ field: "ResponsibleUserId.Fullname", label: "Responsible", width: 120 }),
    new SyslinkColumn({ field: "Date", label: "Date", type: "date", visible: false, width: 150 }),
    new SyslinkColumn({ field: "FormattedStructuredCommunication", label: 'Structured communication', visible: false, editable: false }),
    new SyslinkColumn({ field: "CurrentStatusLink.StatusId.Sequence", label: 'status order', sortIndex: 1, sortDirection: 'asc', width: 0, visible: false })
  ];
  
  onAddButtonClicked() {
    window.open('/sales/quotes/new?ThirdId='+this.element.Id);
  }
}
