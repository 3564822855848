import { Component, Input, OnInit } from '@angular/core';
import { CreditsafesService } from '.././creditsafe.service';
import { CreditsafeData } from '../creditsafe.model';
import { jsonToOdataFormat } from 'projects/libraries/syslink-components/src/lib/helpers/tools';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-creditsafe',
  templateUrl: './creditsafe-detail.component.html',
  styleUrls: ['./creditsafe-detail.component.scss']
})
export class CreditsafeDetailComponent implements OnInit{
  @Input() vatNumber: string = "";
  private _element?: CreditsafeData;
  @Input() public set element(value: any) {
    if (value) {
      this._element = jsonToOdataFormat(value);
    }
  }

  get element(): undefined | CreditsafeData {
    return this._element;
  }

  public creditLimit:number= 0;
  constructor(
    private creditsafesService: CreditsafesService,
    private ngxUiLoaderService: NgxUiLoaderService
  ) { }

  ngOnInit(): void {
   this.loadCreditLimit();
  }

  public async loadCreditLimit(){
    this.creditLimit = await this.creditsafesService.GetCreditSafeRemainingLimitAsync();
  }

  public async loadCreditSafeData() {
    this.ngxUiLoaderService.start();
    this.element = await this.creditsafesService.getThirdStatisticsByVat(this.vatNumber);
    await this.loadCreditLimit();
    this.ngxUiLoaderService.stop();
  }
}