<syslink-grid
    #gridComponent
    [gridName]="storageKey"
    [canView]="canView"
    [canSearch]="canSearch"
    [canRefresh]="canRefresh"
    [canAdd]="canAdd"
    [canSort]="canSort"
    [canUpdate]="canUpdate"
    [canDelete]="canDelete"
    [canFilter]="canFilter"
    [canExport]="canExport"
    [canDblClck]="canDblClck"
    [store]="productsService.store"
    [archivedStore]="productsService.archivedStore"
    [restoreService]="productsService"
    [columns]="columns"
    [expand]="expand"
    (add)="add.emit()"
    [storageKey]="storageKey"
    (doubleClick)="rowDoubleClicked.emit($event)"
    [toolbaActions]="toolbarActions"
    [filter]="filter"
></syslink-grid>

