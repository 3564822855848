import { Injectable } from '@angular/core';
import { ODataService } from '../../../core/services/oData.service';
import { AssignementPriceCoef } from './assignement-price-coef.model';
import { LoadOptions } from 'devextreme/data';

@Injectable({
  providedIn: 'root'
})
export class AssignementPriceCoefService extends ODataService<AssignementPriceCoef> {
  public url = 'AssignementPriceCoef';
  public override defaultOptions: LoadOptions = {
    expand: ['WorkTypeId','TimetableLineId','PriceCoefId']
  };

  public override format(element: Partial<AssignementPriceCoef>): AssignementPriceCoef {
    var result: any = {
      ...element,
      WorkTypeId: element.WorkTypeId?.Id,    
      PriceCoefId: element.PriceCoefId?.Id,    
      TimetableLineId: element.TimetableLineId?.Id,    
    };
    delete result.FormattedName;
    return result;
  }
}
