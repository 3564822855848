import { Injectable } from '@angular/core';
import { ODataService } from '../../../core/services/oData.service';
import { ReportConfiguration } from './report-configuration.model';

@Injectable({
  providedIn: 'root'
})
export class ReportConfigurationsService extends ODataService<ReportConfiguration> {
  public url: string = 'ReportConfiguration';
  constructor() { super(); }
  public override format(params: Partial<ReportConfiguration>): ReportConfiguration {
    var result: any = {
      ...params,
    };
 
    return result;
  }
}
