import { Injectable } from '@angular/core';
import { LoadOptions } from 'devextreme/data';
import { SaleDocumentsService } from '../../sale-documents/sale-documents/sale-documents.service';
import { SaleInvoice } from './sale-invoice.model';
import { ApiService } from '../../../core/api.service';
import { ReportsService } from '../../../connectors/reports/reports.service';
import { SaleInvoiceHeaderStatus } from '../sale-invoice-header-statuses/sale-invoice-header-status.model';

@Injectable({
  providedIn: 'root'
})
export class SaleInvoicesService extends SaleDocumentsService<SaleInvoice, SaleInvoiceHeaderStatus> {
  public override url: string = 'SaleInvoiceHeader';

  constructor(
    public override apiService: ApiService,
    public override reportsService: ReportsService
  ) {
    super(reportsService);
  }
  public override defaultOptions: LoadOptions = {
    select: [],
    expand: [
      'ThirdId',
      'ThirdId.CustomerId',
      'ThirdId.CustomerId.PaymentMethodId',
      'ThirdId.CommunicationMethods.ThirdId',
      'ThirdId.CommunicationMethods.CommunicationLabelId',
      'ThirdId.CommunicationMethods.CommunicationTypeId',
      'IntermediateThirdId.CommunicationMethods.CommunicationLabelId',
      'IntermediateThirdId.CommunicationMethods.CommunicationTypeId',
      'CurrentStatusLink.StatusId',
      'ResponsibleUserId',
      'DocumentDelayId',
      'OriginId',
      'Lines($orderby=LineNo;$expand=TaxRateId($expand=NameTranslationId),UnitId,Margin,Discount,Children,SaleDocumentLineDiscountOperations,ParentId,TaxId,AccountId, ProductId)',
      'Statuses.StatusId',
      'DocumentDataCollection.ThirdId.ThirdContacts',
      'DocumentDataCollection.ThirdId.Addresses',
      'DocumentDataCollection.ContactId',
      'DocumentDataCollection.AddressId',
      'DocumentDataCollection.ThirdId.CustomerId.SaleInvoiceDocumentDelayId',
      'Payments.PaymentMethodPrimary',
      'Payments.ParentId',
      'Payments.PaymentStateId',
      'Payments.PaymentTypeId',
      'ContractId',
      'ThirdId.ContactInformations.ThirdId',
      'ThirdId.ContactInformations.ContactTypeId',
      'DocumentRelations'
    ]
  };

  public override async getInstance(quote?: Partial<SaleInvoice>): Promise<any> {

    let element = {
      Date: new Date(),
      ...quote,
    };

    element = await this.refreshResponsibleUser(element);
    element = await this.refreshThirdDocumentDelayFromCustomer(element);

    return element;
  }

  public async getStatistics(filter: any) {
    var filters = this.convertODataFiltersToString(filter);
    if (filters == "") return undefined;
    return this.apiService.sendRequest('/api/SaleInvoice/getStatistiques/' + filters, 'GET');
  }
}
