<syslink-treelist
    #treelistComponent
    [canView]="canView"
    [canSearch]="canSearch"
    [canRefresh]="canRefresh"
    [canSort]="canSort"
    [canUpdate]="canUpdate"
    [canDelete]="canDelete"
    [canFilter]="canFilter"
    [canExport]="canExport"
    [canDblClck]="canDblClck"
    [canAdd]="false"
    [columns]="columns"
    [store]="accountsService.store"
    parentIdExpr="ParentAccountId.Id"
    [options]="{editing:{allowAdding:false, allowUpdating:false, allowDeleting:false},filterRow:{visible:false},sorting:{mode:'none'},selection:{mode:'none'},pager:{visible:false}}"
    [storageKey]="storageKey"
    [showDragIcons]="false"
    [allowReordering]="false"
    [remoteOperations]="{filtering:true, grouping:true, sorting:true}"
    [select]="['AccountTypeId.Id', 'AccountTypeId.Name', 'ParentAccountId.Id']"
></syslink-treelist>