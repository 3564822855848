import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AccountsService } from '../../../accountings/accounts/accounts.service';
import { TaxTypesService } from '../../../accountings/tax-types/tax-type.service';
import { CurrenciesService } from '../../../base/currencies/currency.service';
import { TaxRatesService } from '../../../base/tax-rates/tax-rates.service';
import { UnitsService } from '../../../base/units/unit.service';
import { Brand } from '../../brands/brand.model';
import { BrandsService } from '../../brands/brand.service';
import { Product } from '../product.model';

@Component({
  selector: 'app-product-sale-form',
  templateUrl: './product-sale-form.component.html',
  styleUrls: ['./product-sale-form.component.scss']
})
export class ProductSaleFormComponent {
  @Input() public element: Product = new Product();
  @Output() public elementChange: EventEmitter<Product> = new EventEmitter<Product>();
  @Output() public basePriceChanged: EventEmitter<number> = new EventEmitter<number>();
  @Output() public customerCatalogChanged: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    public brandsService: BrandsService,
    public currenciesService: CurrenciesService,
    public taxRatesService: TaxRatesService,
    public unitsService: UnitsService,
    public taxTypesService: TaxTypesService,
    public accountsService: AccountsService
  ) { }

  public onBasePriceChange(event: any) {
    if (this.element.ForcedSalePrice == null) this.element.ForcedSalePrice = 0;
    this.basePriceChanged.emit(event);
  }

  public async customNewBrandValue(customeValue: string) {
    if (customeValue == '') return;
    this.element.BrandId = await this.brandsService.insert(new Brand({ Code: customeValue, Name: customeValue }));
  }
}


