<app-stock-move-incoming-grid
*ngIf="filter"
    #GridComponent
    [permissionKey]="'stockMoveSupplierIncomings.list'"
    [canUpdate]="false"
    [filter]="filter"
    (add)="onAddButtonClicked()"
    detailsUrl="/stockmoves/supplierincomings"
    storageKey="stock-move-supplierincomings"
    [columns]="columns"
></app-stock-move-incoming-grid>
