<syslink-title
  *ngIf="element.Name != undefined"
  [text]="element.Name"
></syslink-title>

<syslink-toolbar [actions]="toolbarActions"></syslink-toolbar>

<div class="row mt-1">
  <app-user-group-form [(element)]="element"></app-user-group-form>
</div>

<div
  class="row mt-3"
  *ngIf="element.Id"
>
  <syslink-tabs #userPermissions>
    <!-- <syslink-tab label="Permission">
       <app-user-group-user-permission
       [userGroup]="element"
       (userPermissionChange)="onSavebtnClicked()"
       ></app-user-group-user-permission>
    </syslink-tab> -->
    <syslink-tab
      label="Users"
      [visible]="authService.hasPermission(this.basePermissionKey + '.user.tab.view')"
    >
      <app-user-grid
        #userGrid
        [permissionKey]="'users.list'"
        [canUpdate]="false"
        (add)="onAddUserButtonClicked()"
        (delete)="onDeleteButtonClicked($event)"
        [filter]="usersFilters"
        [expand]="['Groups']"
      ></app-user-grid>
      <app-user-select-modal
        #userSelectModal
        (validate)="onValidateUserAddButtonClicked($event)"
      ></app-user-select-modal>
    </syslink-tab>
    <!-- <syslink-tab label="Role">
        <app-user-group-user-role
        [userGroup]="element"
        (userRoleChange)="onSavebtnClicked()"
        ></app-user-group-user-role>
     </syslink-tab> -->
  </syslink-tabs>
</div>

<syslink-confirm-modal
  #deleteConfirm
  title="Delete"
  content="DeleteConfirmMessage"
  (onValidate)="groupDelete()"
  [validateText]="'Confirm'"
  [cancelText]="'Cancel'"
></syslink-confirm-modal>