<div class="row">

  <div class="col-3">
    <syslink-input-date
      name="Date"
      label="Date"
      [(model)]="element.Date"
      [disabled]="disabled"
      type="date"
      (modelChange)="onChangeDate($event)"
    ></syslink-input-date>
  </div>

  <div class="col-3">
    <syslink-input-date
      name="Validity"
      [label]="(reportType + '.Validity') | translate"
      [model]="getDeadline()"
      type="date"
      format="date"
      (modelChange)="onChangeDeadLine($event)"
      [min]="element.Date"
    ></syslink-input-date>
  </div>
</div>