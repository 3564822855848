import { Task } from "ngx-ui-loader";
import { BasicEntity } from "../../../core/models/basic-entity";
import { Product } from "../../../products/products/product.model";


export class WorkType extends BasicEntity {

    // Navigation properties
    // ---------------------
    public Tasks?: Task[];
    public Product?: Product;

    constructor(entity?: WorkType) {
        super();
        Object.assign(this, entity);
    }
}
