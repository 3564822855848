import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Task, TaskTimeStateCode } from '../task.model';
import { ThirdsService } from 'projects/erp-app/src/app/thirds/thirds/thirds.service';
import { UserGroupsService } from 'projects/erp-app/src/app/core/auth/user-groups/user-groups.service';
import { SaleQuotesService } from 'projects/erp-app/src/app/sales/sale-quotes/sale-quotes/sale-quotes.service';
import { SaleContractsService } from 'projects/erp-app/src/app/sales/sale-contracts/sale-contracts/sale-contracts.service';
import { TaskPrioritiesService } from '../../task-priorities/task-priorities.service';
import { TaskTagsService } from '../../task-tags/task-tags.service';
import { PageComponent, ProgressBarComponent, SelectComponent } from 'projects/libraries/syslink-components/src/public-api';
import { WorkTypesService } from '../../../works/work-types/work-types.service';
import { WorkTimeState } from '../../../works/work-time-states/work-time-state';
import { UsersService } from '../../../../core/auth/users/users.service';
import { expand } from 'rxjs';

@Component({
  selector: 'app-task-form',
  templateUrl: './task-form.component.html',
  styleUrls: ['./task-form.component.scss']
})
export class TaskFormComponent extends PageComponent {
  @Input() public element: Task = new Task();
  @Output() public elementChange: EventEmitter<Task> = new EventEmitter<Task>();
  @Output() public updated: EventEmitter<void> = new EventEmitter<void>();
  @Output() public estimatedTimeChange: EventEmitter<any> = new EventEmitter<any>();

  public defaultSelect: string | string[] = ['Id', 'NameFormatted'];
  @ViewChild('selectQuote') public selectQuote: SelectComponent = new SelectComponent();
  @ViewChild('selectContract') public selectContract: SelectComponent = new SelectComponent();

  @ViewChild('workedTimeProgressBar') public workedTimeProgressBar: ProgressBarComponent = new ProgressBarComponent();
  // @ViewChild('scheduledTimeProgressBar') public scheduledTimeProgressBar: ProgressBarComponent = new ProgressBarComponent();
  @ViewChild('billedTimeProgressBar') public billedTimeProgressBar: ProgressBarComponent = new ProgressBarComponent();

  TaskTimeStateCodes = TaskTimeStateCode;

  constructor(
    public WorkTypesService: WorkTypesService,
    public thirdsService: ThirdsService,
    public override usersService: UsersService,
    public userGroupsService: UserGroupsService,
    public saleQuotesService: SaleQuotesService,
    public saleContractsService: SaleContractsService,
    public TaskPrioritiesService: TaskPrioritiesService,
    public TaskTagsService: TaskTagsService
  ) {
    super();
  }

  public reloadSaleDocumentFilter() {
    // this.selectQuote.forceReload();
    // this.selectContract.forceReload();
    // this.selectQuote.selectComponent?.instance.getDataSource().filter((this.element.ThirdId?.Id) ? ['ThirdId.Id eq ' + this.element.ThirdId?.Id] : null);
    // this.selectQuote.selectComponent?.instance.getDataSource().reload();
    // this.selectContract.selectComponent?.instance.getDataSource().filter((this.element.ThirdId?.Id) ? ['ThirdId.Id eq ' + this.element.ThirdId?.Id] : null);
    // this.selectContract.selectComponent?.instance.getDataSource().reload();
  }

  public onChangeThird(e: any) {
    if (e == null) {
      this.element.ThirdId = undefined;
    }
    if (this.element.ThirdId?.Id && this.element.SaleQuoteLineId && this.element.ThirdId.Id != this.element.SaleQuoteLineId?.HeaderId.ThirdId.Id) {
      this.element.SaleQuoteLineId = undefined;
    }
    if (this.element.ThirdId?.Id && this.element.SaleContractId && this.element.ThirdId.Id != this.element.SaleContractId?.ThirdId.Id) {
      this.element.SaleContractId = undefined;
    }
    this.reloadSaleDocumentFilter();
  }

  public updateThird(e: any) {
    if (this.element.ThirdId || e == null) return;
    this.element.ThirdId = e.ThirdId;
    this.reloadSaleDocumentFilter();
  }

  public getDurationColor(stateKey: string): string {
    const state: WorkTimeState = (<any>this.element)[stateKey];
    return state.Color;
  }

  public reloadProgressBar(){
      this.workedTimeProgressBar.value = 0;
      // this.scheduledTimeProgressBar.value = 0;
      this.billedTimeProgressBar.value = 0;
      this.workedTimeProgressBar.value =  this.element.WorkedTime;
      // this.scheduledTimeProgressBar.value =  this.element.ScheduledTime;
      this.billedTimeProgressBar.value =  this.element.BilledTime;
  }
}
