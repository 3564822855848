<div
  *ngIf="reportType=='SaleContract' || !disabled"
  class="d-flex justify-content-between align-items-center mb-3"
>
  <div class="col-1">
    <syslink-button
      *ngIf="authService.hasPermission(this.basePermissionKey + '.content.text.add')"
      name="Text"
      text="Text"
      icon="fa-solid fa-paragraph"
      [disabled]="isAddingLine"
      (click)="onAddLine('text')"
    ></syslink-button>
  </div>

  <div class="col-1">
    <syslink-button
      *ngIf="authService.hasPermission(this.basePermissionKey + '.content.title.add')"
      name="Title"
      text="Title"
      icon="fa-solid fa-heading"
      [disabled]="isAddingLine"
      (click)="onAddLine('title')"
    ></syslink-button>
  </div>
  
  <div class="col-2">
  <app-work-times-picker
      *ngIf="authService.hasPermission(this.basePermissionKey + '.content.worktime.add') && authService.hasPermission('time-management-work-times') && this.element.ThirdId.Id"
      #workTimePicker
      [thirdId]="this.element.ThirdId.Id"
      (onAddTimeBtn)="onCreateTime($event)"
      (onDoubleClickTime)="onDoubleClickTime($event)"
    ></app-work-times-picker>
  </div>
  
  <div class="col-6">
    <app-product-picker
      *ngIf="authService.hasPermission(this.basePermissionKey + '.content.product.add') && this.element.Id"
      [permissionKey]="'productPicker.list'"
      (noResult)="onProductNotFound($event)"
      [sellable]="true"
      (singleResult)="onAddLine('product', $event)"
      [searchFields]="['InternalReference']"
      (onAddNewProductBtn)="onCreateProduct()"
    ></app-product-picker>
  </div>

  <div class="col-1 d-flex justify-content-end">
    <syslink-button
      *ngIf="authService.hasPermission(this.basePermissionKey + '.content.various.add')"
      name="Various"
      text="Various"
      icon="fa-solid fa-border-all"
      [disabled]="isAddingLine"
      (click)="onAddLine('various')"
    ></syslink-button>
  </div>

  <div class="col-1 d-flex justify-content-end">
    <syslink-button
      *ngIf="authService.hasPermission(this.basePermissionKey + '.content.page.add')"
      name="Page"
      text="Page"
      icon="fa-solid fa-file"
      [disabled]="isAddingLine"
      (click)="onAddLine('page')"
    ></syslink-button>
  </div>
</div>

<app-document-lines
  [permissionKey]="'content.list'"
  [documentLines]="element.Lines"
  (deleteLine)="onDeleteLine($event)"
  [(selectedKey)]="selectedKey"
  (selectedKeyChange)="onSelectedKeyChange($event)"
  (updateLine)="onUpdateLine($event)"
  (onReorder)="OnReorder($event)"
  [columns]="columns"
  [reportType]="reportType"
  [subModuleCode]="subModuleCode"
  [disabled]="reportType!='SaleContract' && disabled"
></app-document-lines>

<syslink-modal
  #createNewProduct
  title="Create"
  width="500px"
  validateText="Yes"
  cancelText="No"
  (onValidate)="onCreateProduct()"
>
  <div class="text-center p-3">
    {{ 'Searched text not found.' | translate }} <br />
    {{ 'Do you want to create a new One?' | translate }}
  </div>
</syslink-modal>