import { Injectable } from '@angular/core';
import { PurchaseDocumentStatusesService } from '../../purchase-documents/purchase-document-statuses/purchase-document-statuses.service';
import { PurchaseOrderHeaderStatus } from './purchase-order-header-status.model';

@Injectable({
  providedIn: 'root'
})
export class PurchaseOrderHeaderStatusesService extends PurchaseDocumentStatusesService<PurchaseOrderHeaderStatus> {
  public override url: string = 'PurchaseOrderHeaderStatus';
 
  constructor() {
    super();
  }
}
