import { Injectable } from '@angular/core';
import { LoadOptions } from 'devextreme/data';
import { ApiService } from '../../../core/api.service';
import { SaleDocumentLinesService } from '../../sale-documents/sale-document-lines/sale-document-lines.service';
import { SaleQuoteLine } from './sale-quote-line.model';

@Injectable({
  providedIn: 'root'
})
export class SaleQuoteLinesService extends SaleDocumentLinesService<SaleQuoteLine> {
  public override url: string = 'SaleQuoteLine';
  public override documentType: string = 'SaleQuote';
  public override defaultOptions: LoadOptions = {
    expand: ['Margin', 'Discount', 'ParentId']
  };

  constructor(public override api: ApiService) {
    super(api);
  }

  public override async getInstance(quote?: Partial<SaleQuoteLine>): Promise<any> {
    let element = {
      ...quote,
    };
    return element;
  }

  public async updateParentId(saleQuoteLine: SaleQuoteLine, targetParentId: number) {
    return await this.api.sendRequest('/api/SaleDocumentLine/' + saleQuoteLine.Id + '/changeParent/' + targetParentId, 'POST');
  }
}
