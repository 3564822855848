<div class="border py-1 ps-3">
    <div class="row">
        <div class="col-3">
            <div class="w-100 h-100 d-flex justify-content-center align-items-center h4">
                <i class="fa-solid fa-{{icon}} text-primary text-center"></i>
            </div>
        </div>
        <div class="col-9 d-flex flex-column">
            <span [hidden]="isInEditMode" class="h5 text-primary fw-bold" role="button" (click)="onOpenNumberEditMode()">{{number}}</span>
            <syslink-input-number [hidden]="!isInEditMode || canEdit == false" #numberInput name="{{text}}" [(model)]="number" (modelChange)="numberChange.emit($event)" (onFocusOut)="onCloseNumberEditMode()" (onEnterKey)=" onCloseNumberEditMode()"></syslink-input-number>
            <span>{{text | translate}}</span>
        </div>
    </div>
</div>