import { Entity } from "../../../core/models/entity";
import { TaskStatus } from "../task-statuses/task-status.model";
import { Task } from "../tasks/task.model";

export class TaskTaskStatus extends Entity {
    public Date:Date = new Date();
    public TaskId:Task = new Task();
    public StatusId:TaskStatus = new TaskStatus();
    
    constructor(entity?: TaskTaskStatus) {
        super();
        Object.assign(this, entity);
    }
}
