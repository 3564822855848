import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LoadOptions } from 'devextreme/data';
import { ApiService } from '../../api.service';
import { ODataService } from '../../services/oData.service';
import { UserRole } from './user-role.model';

@Injectable({
  providedIn: 'root'
})
export class UserRolesService extends ODataService<UserRole> {
  public url = 'UserRole';
  public override defaultOptions: LoadOptions = {
    expand: ["NameTranslationId",
      "Users",
      "Permissions",
      "UserGroups.NameTranslationId"
    ]
  };

  constructor(
    private api: ApiService,
    private translateService:TranslateService
  ) {
    super();
  }
  public override format(element: Partial<UserRole>): UserRole {
    var result: any = {
      ...element,
    };
    // delete result.Users;
    // delete result.UserGroups;
    // delete result.Permissions;
    return result;
  }

  public override async getInstance(params?: Partial<UserRole>): Promise<UserRole> {
    var role = new UserRole({
      Name: this.translateService.instant('New User role'),
      IsActive: true,
      ...params
    });
    return role;
  }
  public async removeUser(userRoleId: number, userId: number) {
    return await this.api.sendRequest('/api/odata/UserRole/' + userRoleId + '/Users/' + userId + '/$ref', 'DELETE');
  }
  public async removeUserPermission(userRoleId: number, permissionId: number) {
    return await this.api.sendRequest('/api/odata/UserRole/' + userRoleId + '/Permissions/' + permissionId + '/$ref', 'DELETE');
  }
  public async removeUserGroup(userRoleId: number, groupId: number) {
    return await this.api.sendRequest('/api/odata/UserRole/' + userRoleId + '/UserGroups/' + groupId + '/$ref', 'DELETE');
  }
}
