import { Injectable } from '@angular/core';
import { ODataService } from '../../../core/services/oData.service';
import { ThirdRole } from './third-role.model';

@Injectable({
  providedIn: 'root'
})

export class ThirdRolesService extends ODataService<ThirdRole> {
  public url = 'ThirdRole';
}


