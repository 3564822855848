import { Component, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ConfirmPasswordComponent } from 'projects/libraries/syslink-components/src/lib/modal/confirm-password/confirm-password.component';
import { ConfirmModalComponent, NotificationsService, PageComponent, SyslinkToolbarAction, SyslinkToolbarActionButton, SyslinkToolbarFileButton } from 'projects/libraries/syslink-components/src/public-api';
import { AuthService } from '../../auth.service';
import { User } from '../user.model';
import { UsersService } from '../users.service';
import { FilterDescriptor } from 'devextreme/data';
import { UserGroup } from '../../user-groups/user-group.model';
import { UserGroupGridComponent } from '../../user-groups/user-group-grid/user-group-grid.component';
import { UserGroupSelectModalComponent } from '../../user-groups/user-group-select-modal/user-group-select-modal.component';
import { UserGroupsService } from '../../user-groups/user-groups.service';
import { ActionGroupGridComponent } from '../../action-groups/action-group-grid/action-group-grid.component';
import { ActionGroupSelectModalComponent } from '../../action-groups/action-group-select-modal/action-group-select-modal.component';
import { ActionGroup } from '../../action-groups/action-group.model';
import { ActionGroupsService } from '../../action-groups/action-groups.service';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss']
})
export class UserDetailsComponent extends PageComponent implements OnInit {

  public element: User = new User();
  public elementChange: EventEmitter<User> = new EventEmitter<User>();

  public checkUserPassword: boolean = false;
  @ViewChild('passwordConfirm') public passwordConfirm: ConfirmPasswordComponent = new ConfirmPasswordComponent(this.translateService);//ConfirmPasswordComponent

  @ViewChild('deleteConfirm') deleteConfirm: ConfirmModalComponent = new ConfirmModalComponent;

  // User groups
  // -----------
  public userGroupsFilters: FilterDescriptor | Array<FilterDescriptor>;
  @ViewChild("userGroupsGrid") public userGroupsGrid?: UserGroupGridComponent;
  @ViewChild("userGroupSelectModal") public userGroupSelectModal?: UserGroupSelectModalComponent;
  
  // User groups
  // -----------
  public actionGroupsFilters: FilterDescriptor | Array<FilterDescriptor>;
  @ViewChild("actionGroupsGrid") public actionGroupsGrid?: ActionGroupGridComponent;
  @ViewChild("actionGroupSelectModal") public actionGroupSelectModal?: ActionGroupSelectModalComponent;

  constructor(
    private ngxUiLoaderService: NgxUiLoaderService,
    public override activatedRoute: ActivatedRoute,
    private userGroupsService: UserGroupsService,
    private actionGroupsService: ActionGroupsService,
  ) {
    super();
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.ngxUiLoaderService.start();
    this.activatedRoute.data.subscribe(async ({ element }) => {
      this.element = element;
      await this.refreshElement();
      this.ngxUiLoaderService.stop();
    });
  }
  public refreshElement() {
    this.updateBreadCrumb(this.element.UserName);
    this.updateToolbar();
    this.updateUserGroupsFilters();
    this.updateActionGroupsFilters();

  }
  public updateToolbar() {
    this.toolbarActions = [];
    this.toolbarActions.push(new SyslinkToolbarActionButton({ icon: "save", text: "Save", onClick: () => this.onSavebtnClicked(''), hotkey: 'control.s', visible: this.element.Oid? this.authService.hasPermission(this.basePermissionKey + '.update'):this.authService.hasPermission(this.basePermissionKey + '.add') }));
    if (this.element.Oid) {
      this.toolbarActions.push(new SyslinkToolbarFileButton({ entityType: 'User', entityId: this.element.Oid }));
      this.toolbarActions.push(new SyslinkToolbarActionButton({ icon: "trash", text: "Delete", onClick: () => this.onUserDeleteBtn(), visible: this.authService.hasPermission(this.basePermissionKey + '.delete') }));
    }
  }

  // Save btn
  // --------
  public async onSavebtnClicked(password: string) {
    if (((this.element.Oid && !this.authService.hasPermission(this.basePermissionKey + '.update')) || (!this.element.Oid && !this.authService.hasPermission(this.basePermissionKey + '.add')))) {
      NotificationsService.sendErrorMessage("You do not have the required permission!");
      return;
    } 
    if (this.checkUserPassword == true) {
      // let currentUser = this.authService.user;
      this.passwordConfirm.modal.close();
    }

    if (this.element.Email == "" || this.element.UserName == "") {
      NotificationsService.sendErrorMessage("Value cannot be empty");
      return;
    }
    if (this.element.Oid) {
      if (this.element.Password != null || this.element.PasswordConfirmation != null) {
        if (this.element.Password != this.element.PasswordConfirmation) {
          NotificationsService.sendErrorMessage("Password and password confirmation is not correct");
          return;
        }
        await this.usersService.updatePassword(this.element);
      }
      await this.usersService.update(this.element.Oid, this.usersService.format(this.element));
    } else {
      if (this.element.Password == "" || this.element.PasswordConfirmation == "") {
        NotificationsService.sendErrorMessage("Value cannot be empty");
        return;
      }
      if (this.element.Password != this.element.PasswordConfirmation) {
        NotificationsService.sendErrorMessage("Password and password confirmation is not correct");
        return;
      }
      var user = await this.usersService.insert(this.usersService.format(this.element));
      user.Password = this.element.Password;
      await this.usersService.updatePassword(user);
      this.goToUrl('../' + user.Oid);
    }

    NotificationsService.sendSuccess("Record updated");
    await this.authService.reloadUser();
  }

  // User delete
  // --------------
  public onUserDeleteBtn() {
    this.deleteConfirm.open();
  }
  public async userDelete() {
    this.deleteConfirm.close();
    if (!this.element?.Oid) return;
    await this.usersService.remove(this.element.Oid);
    NotificationsService.sendInfo('Record deleted');
    this.goToUrl('../');
  }

  // User roles
  // ----------
  public async updateRoles(e: any) {
    if (!this.element.Oid) return;
    await this.onSavebtnClicked('test');
    this.element = (await this.usersService.load({ filter: ['Oid eq ' + this.element.Oid], expand: this.usersService.getExpand() }))[0];
  }

  // User Groups
  // -----------
  public updateUserGroupsFilters() {
    if (this.element.Oid)
      this.userGroupsFilters = ['Users/any(e:e/Oid eq ' + this.element.Oid + ')'];
  }

  
  public async addUserGroupButtonClicked() {
    if (!this.userGroupSelectModal) return;

    const currentGroupsFilter = ["not(Id in (" + this.element.Groups.map(e => e.Id).join(',') + "))"];

    // Checking if groups are available
    // --------------------------------
    if (this.element.Groups.length != 0) {
      const availableGroups = await this.userGroupsService.filter(currentGroupsFilter);
      if (availableGroups.length == 0) {
        NotificationsService.sendInfo("No user group available");
        return;
      }

      this.userGroupSelectModal.selectFilters = currentGroupsFilter;
    }

    this.userGroupSelectModal?.open();

  }
  
  public async onValidateUserGroupAddButtonClicked(e: UserGroup) {
    if (!this.element.Oid || !e.Id) return;
    
    this.element.Groups.push(this.userGroupsService.format(e));
    await this.usersService.update(this.element.Oid, this.element);
    
    this.userGroupsGrid?.refresh();
    this.userGroupSelectModal?.close();
  }
  
  public async onDeleteUserGroup(group: UserGroup) {
    if (!this.element.Oid || !group.Id) return;
    
    this.element.Groups = this.element.Groups.filter(e => e.Id != group.Id);
    await this.usersService.removeUserGroup(this.element.Oid, group.Id);
    
    this.userGroupsGrid?.refresh();
  }
  
  // Action Groups
  // -----------
  public updateActionGroupsFilters() {
   if (this.element.Oid)
     this.actionGroupsFilters = ['Users/any(e:e/Oid eq ' + this.element.Oid + ')'];
  }

  public async addActionGroupButtonClicked() {
    if (!this.actionGroupSelectModal) return;
    
    const currentGroupsFilter = ["not(Id in (" + this.element.ActionGroups.map(e => e.Id).join(',') + "))"];
    
    // Checking if groups are available
    // --------------------------------
    if (this.element.ActionGroups.length != 0) {
      const availableGroups = await this.actionGroupsService.filter(currentGroupsFilter);
      if (availableGroups.length == 0) {
        NotificationsService.sendInfo("No action group available");
        return;
      }

      this.actionGroupSelectModal.selectFilters = currentGroupsFilter;
    }

    this.actionGroupSelectModal?.open();

  }
  
  public async onValidateActionGroupAddButtonClicked(e: ActionGroup) {
    if (!this.element.Oid || !e.Id) return;
    
    this.element.ActionGroups.push(this.actionGroupsService.format(e));
    await this.usersService.update(this.element.Oid, this.element);
    
    this.actionGroupsGrid?.refresh();
    this.actionGroupSelectModal?.close();
  }
  
  public async onDeleteActionGroup(group: ActionGroup) {
    if (!this.element.Oid || !group.Id) return;
    
    this.element.Groups = this.element.Groups.filter(e => e.Id != group.Id);
    await this.usersService.removeActionGroup(this.element.Oid, group.Id);
    
    this.actionGroupsGrid?.refresh();
  }

}
