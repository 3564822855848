import { Injectable } from '@angular/core';
import { ODataService } from '../../../core/services/oData.service';
import { LoadOptions } from 'devextreme/data';
import { TranslateService } from '@ngx-translate/core'
import { TaskPriority } from './task-priority.model';

@Injectable({
  providedIn: 'root'
})
export class TaskPrioritiesService extends ODataService<TaskPriority> {
  public url = 'TaskPriority';
  public override defaultOptions: LoadOptions = {
    expand: ['NameTranslationId.Translations.LanguageId']
  };

  public override format(element: Partial<TaskPriority>): TaskPriority {
    var result: any = {
      ...element,
    };
    delete result.Tasks;
    return result;
  }

  public override async getInstance(params?: Partial<TaskPriority>): Promise<TaskPriority> {
    var element = new TaskPriority({
      ...params
    });
    return element;
  }
}
