<!-- Toolbar -->
<syslink-toolbar
    *ngIf="toolbarActions.length"
    [actions]="toolbarActions"
></syslink-toolbar>
<div class="mt-2">
    <ng-container *ngFor="let element of elements">
        <div class="communicationGrid">
            <div class="d-flex justify-content-center align-items-center">
                <syslink-checkbox
                    *ngIf="canCheck"
                    [(model)]="element.IsChecked"
                ></syslink-checkbox>
                <i
                    class="fa-{{element.IsFavorite? 'solid' : 'regular'}} fa-star text-center text-primary mx-2"
                    [class.isButton]="editingId === element.Id"
                    [title]="'Favorite' | translate"
                    (click)="toggleButton('IsFavorite',element)"
                ></i>
            </div>

            <!-- Type -->
            <div class="h-100 d-flex justify-content-center align-items-center">
                <ng-container *ngIf="editingId !== element.Id || canUpdate == false; else editType">
                    <i
                        class="fa-solid fa-{{element.ContactTypeId?.Icon ?? 'diamond'}} text-center text-primary mx-2"
                        [title]="element.ContactTypeId?.Name??'Other' | translate"
                    ></i>
                </ng-container>
                <ng-template #editType>
                    <i
                        class="fa-solid fa-trash text-center text-danger"
                        role="button"
                        [title]="'Delete' | translate"
                        (click)="onDeleteDraftClicked(element)"
                    ></i>
                    <syslink-select
                        class="mx-1"
                        label="Type"
                        [store]="thirdContactTypesService.store"
                        [(model)]="element.ContactTypeId"
                        [expand]="basicExpand"
                        (modelChange)="onChangeContactType(element)"
                    ></syslink-select>
                </ng-template>
            </div>

            <!-- Value -->
            <div class="flex-grow-1">
                <ng-container *ngIf="(editContactValue !== element.Id && editingId !== element.Id) || canUpdate == false; else editValue">
                    <div class="h-100 d-flex align-items-center">
                        <span (click)="authService.hasPermission(this.basePermissionKey + '.contacts.update') && (element.ContactTypeId?.Code === 'Address' &&  canUpdate == true?onEditAddressClicked(element):onEditValue(element.Id))">{{element.AddressId?.FormattedAddress ?? element.Value}}</span>
                    </div>
                </ng-container>
                <ng-template #editValue>
                    <syslink-input-text
                        (click)="element.ContactTypeId?.Code === 'Address' ?onEditAddressClicked(element):null"
                        class="mx-1"
                        [(model)]="element.ContactTypeId?.Code === 'Address' ?element.AddressId?.FormattedAddress:element.Value"
                        (focusOut)="editingId !== element.Id?onSaveClicked(element):editQualifierValue=0"
                    ></syslink-input-text>
                </ng-template>
            </div>

            <!-- Qualifier -->
            <div class="flex-grow-1">
                <ng-container *ngIf="(editQualifierValue !== element.Id && editingId !== element.Id) || canUpdate == false; else editValueQualifier">
                    <div class="h-100 d-flex align-items-center">
                        <span (click)="authService.hasPermission(this.basePermissionKey + '.contacts.update') && ( canUpdate == true?onEditQualifierValue(element.Id):null)">{{element.ContactQualifierId?.Name??'---' | translate}}</span>
                    </div>
                </ng-container>
                <ng-template #editValueQualifier>
                    <syslink-select
                        class="mx-1"
                        label="Qualifier"
                        [store]="thirdContactQualifiersService.store"
                        [model]="element.ContactQualifierId"
                        (modelChange)="editingId !== element.Id?onChangeQualifier(element, $event):onCHangeQualifierEditingMode(element, $event)"
                        (onCustomValue)="onCustomValue(element, $event)"
                    ></syslink-select>
                </ng-template>
            </div>



            <div class="d-flex align-items-center gap-3 mx-2">
                <i
                    class="fa-solid {{element.UsedForQuote ? 'cl-accent' : 'cl-gray'}} fa-file-lines text-center text-primary"
                    [class.isButton]="editingId === element.Id"
                    [title]="'Used for quotes' | translate"
                    (click)="toggleButton('UsedForQuote',element)"
                ></i>
                <i
                    class="fa-solid {{element.UsedForDelivery ? 'cl-accent' : 'cl-gray'}} fas fa-shipping-fast text-center text-primary"
                    [class.isButton]="editingId === element.Id"
                    [title]="'Used for deliveries' | translate"
                    (click)="toggleButton('UsedForDelivery',element)"
                ></i>
                <i
                    class="fa-solid {{element.UsedForInvoice ? 'cl-accent' : 'cl-gray'}} fa-file-invoice text-center text-primary"
                    [class.isButton]="editingId === element.Id"
                    [title]="'Used for invoices' | translate"
                    (click)="toggleButton('UsedForInvoice',element)"
                ></i>
                <i
                    class="fa-solid {{element.UsedForNewsletter ? 'cl-accent' : 'cl-gray'}} fa-circle-info text-center text-primary"
                    [class.isButton]="editingId === element.Id"
                    [title]="'Used for newsletters' | translate"
                    (click)="toggleButton('UsedForNewsletter',element)"
                ></i>
                <i
                    class="fa-{{element.IsPublic? 'solid' : 'regular'}} fa-eye text-center text-primary"
                    [class.isButton]="editingId === element.Id"
                    [title]="'Public' | translate"
                    (click)="toggleButton('IsPublic',element)"
                ></i>
                <ng-container *ngIf="editingId !== element.Id; else editTypeButton">
                    <i
                        *ngIf="authService.hasPermission(this.basePermissionKey + '.contacts.delete') && canDelete"
                        class="fa-solid fa-trash text-center text-danger"
                        role="button"
                        [title]="'Delete' | translate"
                        (click)="onDeleteClicked(element)"
                    ></i>
                </ng-container>
                <ng-template #editTypeButton>
                    <i
                        *ngIf="authService.hasPermission(this.basePermissionKey + '.contacts.update')"
                        class="fa-solid fa-save text-center text-primary"
                        role="button"
                        [title]="'Save' | translate"
                        (click)="onSaveClicked(element)"
                    ></i>
                </ng-template>
            </div>
        </div>
    </ng-container>
</div>

<syslink-modal
    #editAddressModal
    title="Address"
    width="600px"
    (onValidate)="editingId !== editedAddressElement.Id?onSaveClicked(editedAddressElement):null;editAddressModal.close()"
>
    <app-address-form
        #addressForm
        [address]="editedAddress"
    ></app-address-form>
</syslink-modal>

<syslink-confirm-modal
    #deleteConfirm
    title="Delete"
    content="DeleteConfirmMessage"
    cancelText="Cancel"
    validateText="Confirm"
    (onValidate)="delete()"
></syslink-confirm-modal>