import { Component, Input } from '@angular/core';
import { Third } from '../third.model';
import { CustomersService } from '../../customers/customers/customers.service';
import { SuppliersService } from '../../suppliers/suppliers.service';
import { ChartSerie } from 'projects/libraries/syslink-components/src/lib/helpers/ChartSeries';
import { TranslateService } from '@ngx-translate/core';
import { ViewComponent } from 'projects/libraries/syslink-components/src/lib/helpers/view/view.component';

@Component({
  selector: 'app-third-statistics',
  templateUrl: './third-statistics.component.html',
  styleUrls: ['./third-statistics.component.scss']
})
export class ThirdStatisticsComponent extends ViewComponent {
  @Input() public element: Third = new Third();

  public saleQuoteData: any[] = [];
  public saleInvoiceData: any[] = [];
  public saleCreditNoteData: any[] = [];
  public purchaseInvoiceData: any[] = [];
  public purchaseCreditNoteData: any[] = [];

  public saleQuoteLegendHeader: string = "";
  public saleInvoiceLegendHeader: string = "";
  public saleCreditNoteLegendHeader: string = "";
  public purchaseInvoiceLegendHeader: string = "";
  public purchaseCreditNoteLegendHeader: string = "";

  public selectedYear?: number;

  public series: ChartSerie[] = [
    new ChartSerie({ argumentField: "Month", valueField: "ExTaxAmount", name: this.translateService.instant("ExTaxAmount"), type: "stackedBar", barWidth: 20, label: { visible: false } }),
    new ChartSerie({ argumentField: "Month", valueField: "InTaxAmount", name: this.translateService.instant("InTaxAmount"), type: "stackedBar", barWidth: 20, label: { visible: false } })
  ];

  public MonthList: string[] = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];

  constructor(
    private customersService: CustomersService,
    private suppliersService: SuppliersService,
    private translateService: TranslateService
  ) {
    super();
  }

  override async ngOnInit(): Promise<void> {
    var currentDate = new Date();
    this.selectedYear = currentDate.getFullYear();
    await this.onChangeDate(this.selectedYear);
  }


  public getMaxDate() {
    var currentDate = new Date();
    return currentDate.getFullYear();
  }

  public async onChangeDate(year: number) {
    if (!this.element.Id) return;
    if (this.element.HasCustomerRole) {
      var statistics = await this.customersService.getStatistics(this.element.Id, year);
      this.loadSaleQuoteData(statistics);
      this.loadSaleInvoiceData(statistics);
      this.loadSaleCreditNoteData(statistics);
    }
    if (this.element.HasSupplierRole) {
      var statistics = await this.suppliersService.getStatistics(this.element.Id, year);
      this.loadPurchaseInvoiceData(statistics);
      this.loadPurchaseCreditNoteData(statistics);
    }

  }

  private loadSaleQuoteData(statistics: any) {
    this.saleQuoteLegendHeader = statistics.saleQuoteExTaxTotal + '€ H.T.V.A / ' + statistics.saleQuoteInTaxTotal + '€ T.V.A.C';
    this.saleQuoteData = []
    for (var i = 0; i < 12; i++) {
      this.saleQuoteData.push({
        Month: this.translateService.instant(this.MonthList[i]),
        ExTaxAmount: statistics.saleQuoteExTaxTotalByMonth[i],
        InTaxAmount: statistics.saleQuoteInTaxTotalByMonth[i] - statistics.saleQuoteExTaxTotalByMonth[i]
      })
    }
  }
  private loadSaleInvoiceData(statistics: any) {
    this.saleInvoiceLegendHeader = statistics.saleInvoiceExTaxTotal + '€ H.T.V.A / ' + statistics.saleInvoiceInTaxTotal + '€ T.V.A.C';
    this.saleInvoiceData = []
    for (var i = 0; i < 12; i++) {
      this.saleInvoiceData.push({
        Month: this.translateService.instant(this.MonthList[i]),
        ExTaxAmount: statistics.saleInvoiceExTaxTotalByMonth[i],
        InTaxAmount: statistics.saleInvoiceInTaxTotalByMonth[i] - statistics.saleInvoiceExTaxTotalByMonth[i]
      })
    }
  }
  private loadSaleCreditNoteData(statistics: any) {
    this.saleCreditNoteLegendHeader = statistics.saleCreditNoteExTaxTotal + '€ H.T.V.A / ' + statistics.saleCreditNoteInTaxTotal + '€ T.V.A.C';
    this.saleCreditNoteData = []
    for (var i = 0; i < 12; i++) {
      this.saleCreditNoteData.push({
        Month: this.translateService.instant(this.MonthList[i]),
        ExTaxAmount: statistics.saleCreditNoteExTaxTotalByMonth[i],
        InTaxAmount: statistics.saleCreditNoteInTaxTotalByMonth[i] - statistics.saleCreditNoteExTaxTotalByMonth[i]
      })
    }
  }

  private loadPurchaseInvoiceData(statistics: any) {
    this.purchaseInvoiceLegendHeader = statistics.purchaseInvoiceExTaxTotal + '€ H.T.V.A / ' + statistics.purchaseInvoiceInTaxTotal + '€ T.V.A.C';
    this.purchaseInvoiceData = []
    for (var i = 0; i < 12; i++) {
      this.purchaseInvoiceData.push({
        Month: this.translateService.instant(this.MonthList[i]),
        ExTaxAmount: statistics.purchaseInvoiceExTaxTotalByMonth[i],
        InTaxAmount: statistics.purchaseInvoiceInTaxTotalByMonth[i] - statistics.purchaseInvoiceExTaxTotalByMonth[i]
      })
    }
  }
  private loadPurchaseCreditNoteData(statistics: any) {
    this.purchaseCreditNoteLegendHeader = statistics.purchaseCreditNoteExTaxTotal + '€ H.T.V.A / ' + statistics.purchaseCreditNoteInTaxTotal + '€ T.V.A.C';
    this.purchaseCreditNoteData = []
    for (var i = 0; i < 12; i++) {
      this.purchaseCreditNoteData.push({
        Month: this.translateService.instant(this.MonthList[i]),
        ExTaxAmount: statistics.purchaseCreditNoteExTaxTotalByMonth[i],
        InTaxAmount: statistics.purchaseCreditNoteInTaxTotalByMonth[i] - statistics.purchaseCreditNoteExTaxTotalByMonth[i]
      })
    }
  }
}
