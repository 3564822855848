import { Injectable } from '@angular/core';
import { ApiService } from '../../core/api.service';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'projects/libraries/syslink-components/src/public-api';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {
  public urlPrint: string = '/api/ReportPrint';
  public urlMail: string = '/api/ReportSendMail';

  constructor(private api: ApiService,
    private translateService: TranslateService,
    private ngxUiLoaderService: NgxUiLoaderService,) { }

  public preview(headerId: number, reportType: string, fileName: string, type: string = "") {
    fileName = this.updateFileName(reportType, fileName, type);
    window.open(`/reportpreview?url=/api/ReportPreview?reportType=${reportType}%26headerId=${headerId}%26fileName=${fileName}%26type=${type}&fileName=${fileName}`, '_blank');
  }

  public async print(headerId: number, reportType: string, fileName: string) {
    fileName = this.updateFileName(reportType, fileName);
    let body = {
      "reportType": reportType,
      "headerId": headerId.toString(),
     
      "fileName": fileName,
      subject: null,
      content: null,
      fromMail: null,
      toMail: null
    };
    return await this.api.sendRequest(this.urlPrint, 'POST', body);
  }

  public async printRows(rowIds: (number | undefined)[], reportType: string, fileName: string, extensionType: string, type: string = "") {
    fileName = this.updateFileName(reportType, fileName);
    switch (extensionType) {
      case "pdf":
        var formatRow: string = "";
        rowIds.forEach(rowId => {
          formatRow += `rowIds=${rowId}%26`;
        });
        formatRow = formatRow.slice(0, formatRow.length - 3);
        window.open(`/reportpreview?url=/api/ReportPreviewRows?reportType=${reportType}%26${formatRow}%26extensionType=${extensionType}%26fileName=${fileName}%26type=${type}`, '_blank');
        break;
      case "zip":
        this.ngxUiLoaderService.start();
        var formatRow: string = "";
        rowIds.forEach(rowId => {
          formatRow += `rowIds=${rowId}&`;
        });
        formatRow = formatRow.slice(0, formatRow.length - 1);
        var url = `/api/ReportPreviewRows?reportType=${reportType}&${formatRow}&extensionType=${extensionType}&fileName=${fileName}&type=${type}`;
        var downloadLink = document.createElement("a");
        downloadLink.href = URL.createObjectURL(await this.api.getBlob(url));
        if (reportType == "SaleInvoice") {
          downloadLink.download = this.translateService.instant("Invoices") + ".zip";
        }
        else {
          downloadLink.download = this.translateService.instant('download') + '.zip';
        }
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        this.ngxUiLoaderService.stop();
        break;
      default: NotificationsService.sendErrorMessage("Extension type error");
    }
  }

  public previewWorkTimes(headerIds: number[], reportType: string, fileName: string, taskActionCode: string) {
    var formatRow: string = "";
    headerIds.forEach(rowId => {
      formatRow += `rowIds=${rowId}%26`;
    });
    formatRow = formatRow.slice(0, formatRow.length - 3);
    window.open(`/reportpreview?url=/api/ReportWorkTimePreview?${formatRow}%26fileName=${this.translateService.instant(fileName)}%26reportType'${reportType}%26taskActionCode=${taskActionCode}`, '_blank');
  }

  public previewSaleGlobalReportPreview(year: number = 2022, byMonth:boolean = true) {
   window.open(`/reportpreview?url=/api/SaleGlobalReportPreview?year=${year}%26byMonth=${byMonth}`, '_blank');
  }

  public updateFileName(reportType: string, fileName: string, type: string = ""): string {
    if (reportType == "SaleQuote") {
      fileName = fileName.replace("#", "Devis-");
    }
    if (reportType == "SaleOrder") {
      fileName = fileName.replace("#", "Commande-");
    }
    if (reportType == "SaleInvoice") {
      fileName = fileName.replace("#", "Facture-");
    }
    if (reportType == "SaleCreditNote") {
      fileName = fileName.replace("#", "Note de crédit-");
    }
    if (reportType == "PurchaseOrder") {
      fileName = fileName.replace("#", "Commande-");
    }
    if (reportType == "PurchaseInvoice") {
      fileName = fileName.replace("#", "Facture-");
    }
    if (reportType == "PurchaseCreditNote") {
      fileName = fileName.replace("#", "Note de crédit-");
    }
    if (reportType == "StockMove" && type == "StockMoveCustomerOutgoing") {
      fileName = fileName.replace("#", "Livraison client-");
    }
    if (reportType == "StockMove" && type == "StockMoveCustomerIncoming") {
      fileName = fileName.replace("#", "Retour client-");
    }
    if (reportType == "StockMove" && type == "StockMoveSupplierIncoming") {
      fileName = fileName.replace("#", "Arrivée fournisseur-");
    }
    if (reportType == "StockMove" && type == "StockMoveSupplierOutgoing") {
      fileName = fileName.replace("#", "Retour fournisseur-");
    }
    if (reportType == "StockMoveInternal") {
      fileName = fileName.replace("#", "Mouvement interne-");
    }
    return fileName;
  }
}
