<syslink-modal
    #modal
    [title]="modal.data.title"
    width="350px"
    [noFooter]="true"
>
<ng-container *ngIf="modal.data.showThird">
    <syslink-select
        name="third"
        label="Third"
        [store]="thirdsService.store"
        [filter]="modal.data.thirdFilter"
        [select]="['Fullname', 'Id', 'Blocked']"
        displayKey="Fullname"
        [(model)]="modal.data.ThirdId"
        detailsUrl="/basics/thirds/thirds/"
        [canAdd]="modal.data.thirdFilter[0].includes('customer')?(authService.hasPermission('thirds-customers') && authService.hasPermission('thirds.customers.list.add')):(authService.hasPermission('thirds-suppliers') && authService.hasPermission('thirds.suppliers.list.add'))"
        [canView]="modal.data.thirdFilter[0].includes('customer')?authService.hasPermission('thirds-customers'):authService.hasPermission('thirds-suppliers')"
        [canRefresh]="true"
    ></syslink-select>
</ng-container>
<ng-container *ngIf="modal.data.showSubject">
    <syslink-input-text
        name="subject"
        label="Subject"
        [(model)]="modal.data.subject"
    ></syslink-input-text>
</ng-container>
    <ng-container *ngIf="modal.data.selectedKey && modal.data.selectedKey.length >0">
        <syslink-button
            class="d-flex flex-column mb-2"
            name="Copy selected rows"
            text="Copy selected rows"
            (click)="onValidateButtonClicked(modal.data,modal.data.selectedKey)"
        ></syslink-button>
        <syslink-button
            class="d-flex flex-column mb-2"
            [name]="'Copy all rows'"
            text="Copy all rows"
            (click)="onValidateButtonClicked(modal.data)"
        ></syslink-button>
    </ng-container>
    <ng-container *ngIf="!modal.data.selectedKey || modal.data.selectedKey.length == 0">
        <syslink-button
            class="d-flex flex-column mb-2"
            [name]="'Validate'"
            text="Validate"
            type="default"
            (click)="onValidateButtonClicked(modal.data)"
        ></syslink-button>
    </ng-container>

    <syslink-button
        class="d-flex flex-column mb-2"
        [name]="'Cancel'"
        text="Cancel"
        type="normal"
        (click)="modal.close()"
    ></syslink-button>

</syslink-modal>