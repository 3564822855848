import { Component, Input, ViewChild } from '@angular/core';
import { BaseGridComponent } from 'projects/libraries/syslink-components/src/lib/grid/base-grid/base-grid.component';
import { SyslinkColumn } from 'projects/libraries/syslink-components/src/lib/helpers/SyslinkColumn';
import { GridComponent } from 'projects/libraries/syslink-components/src/public-api';

@Component({
  selector: 'app-product-stock-stock-move-grid',
  templateUrl: './product-stock-stock-move-grid.component.html',
  styleUrls: ['./product-stock-stock-move-grid.component.scss']
})
export class ProductStockStockMoveGridComponent extends BaseGridComponent<any> {

  @Input() storageKey: string = 'grid-products-stock-move';
  @Input() items: any[] = [];
  @ViewChild('gridComponent') public gridComponent?: GridComponent;

  public columns: SyslinkColumn[] = [
    new SyslinkColumn({ field: 'DateTime', label: 'Date', type: 'date' }),
    new SyslinkColumn({ field: 'UserName', label: 'User' }),
    new SyslinkColumn({ field: 'OldValue', label: 'Old value' }),
    new SyslinkColumn({ field: 'NewValue', label: 'New value' }),
  ];

  constructor(
  ) {
    super();
  }
}