export class ModuleInfo {
    public ID?: number;
    public Version?: string;
    public Name?: string;
    public AssemblyFileName?: string;
    public IsMain?: boolean;

    constructor(basic?: ModuleInfo) {
        Object.assign(this, basic);
    }
}
