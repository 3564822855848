<div class="row">
    <ng-container *ngFor="let module of modules">
        <div
            *ngIf="module.Code && authService.hasPermission(basePermissionKey+'.'+module.Code+'.view')"
            class="col-3 mb-3"
        >
            <syslink-link [url]="'/settings/modules/'+ module.Id">
                <div class="card">
                    <div class="card-img-top">
                        <div
                            class="text-primary bg-light text-center py-3 rounded-top"
                            style="font-size: 4rem;"
                        >
                            <i class="{{modulesService.getIcon(module.Code)}}"></i>
                        </div>
                    </div>
                    <div class="card-body">
                        <h5 class="card-title">{{(module.Name==undefined?"": module.Name ) || (module.Code==undefined?"": module.Code )}}</h5>
                        <p class="card-text">{{module.description}}</p>
                    </div>
                </div>
            </syslink-link>
        </div>
    </ng-container>
</div>