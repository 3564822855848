import { Component, EventEmitter, Output } from '@angular/core';
import { FilterDescriptor } from 'devextreme/data';
import { UserGroupsService } from 'projects/erp-app/src/app/core/auth/user-groups/user-groups.service';
import { UsersService } from 'projects/erp-app/src/app/core/auth/users/users.service';
import { ThirdsService } from 'projects/erp-app/src/app/thirds/thirds/thirds.service';
import { TasksService } from '../../../tasks/tasks/tasks.service';
import { WorkTypesService } from '../../../works/work-types/work-types.service';
import { WorkTimesService } from '../work-times.service';
import { Third } from 'projects/erp-app/src/app/thirds/thirds/third.model';
import { Task } from '../../../tasks/tasks/task.model';
import { WorkType } from '../../../works/work-types/work-type.model';
import { User } from 'projects/erp-app/src/app/core/auth/users/user.model';
import { UserGroup } from 'projects/erp-app/src/app/core/auth/user-groups/user-group.model';

@Component({
  selector: 'app-work-time-filters-popover',
  templateUrl: './work-time-filters-popover.component.html',
  styleUrls: ['./work-time-filters-popover.component.scss']
})
export class WorkTimeFiltersPopoverComponent {


  @Output() valueChanged: EventEmitter<Array<FilterDescriptor | Array<FilterDescriptor>>> = new EventEmitter<Array<FilterDescriptor | Array<FilterDescriptor>>>();

  // Filters Popover
  // ---------------
  public popoverThirdsFilters: FilterDescriptor | Array<FilterDescriptor>;
  public popoverTasksilters: FilterDescriptor | Array<FilterDescriptor>;
  public popoverTypesFilters: FilterDescriptor | Array<FilterDescriptor>;
  public popoverUsersFilters: FilterDescriptor | Array<FilterDescriptor>;
  public popoverUserGroupsFilters: FilterDescriptor | Array<FilterDescriptor>;
  public allFilters: FilterDescriptor | Array<FilterDescriptor>;

  // Models
  // ------
  public thirds: Third[] = [];
  public tasks: Task[] = [];
  public types: WorkType[] = [];
  public users: User[] = [];
  public userGroups: UserGroup[] = [];

  constructor(
    public workTimesService: WorkTimesService,
    public thirdsService: ThirdsService,
    public tasksService: TasksService,
    public workTypesService: WorkTypesService,
    public usersService: UsersService,
    public userGroupsService: UserGroupsService,
  ) {
  }

  onThirdChanged($event: any) {
    this.combineFilters();
    this.valueChanged.emit(this.allFilters);
  }
  onTaskChanged($event: any) {
    this.valueChanged.emit(this.allFilters);
  }
  onTypeChanged($event: any) {
    this.valueChanged.emit(this.allFilters);
  }
  onUserChanged($event: any) {
    this.valueChanged.emit(this.allFilters);
  }
  onUserGroupsChanged($event: any) {
    this.valueChanged.emit(this.allFilters);
  }

  public combineFilters() {
    this.allFilters = [];

    // Thirds
    // ------
    const thirdsFilters = this.buildThirdsFilters(this.thirds);
    if (thirdsFilters && thirdsFilters.length > 0) {
      if (this.allFilters.length > 0) {
        this.allFilters.push("and");
      }
      this.allFilters.push(thirdsFilters);
    }

    if (this.allFilters.length == 0) {
      this.allFilters = undefined;
    }
  }

  public buildThirdsFilters(thirds: Third[]) {
    let filters: FilterDescriptor | Array<FilterDescriptor> = [];

    if (thirds.length > 0) {
      filters.push(["ThirdId.Id in (" + thirds.map(e => e.Id).join(',') + ')']);
    }
    
    if (filters.length == 0) {
      filters = undefined;
    }
    return filters;
  }

  public async updateThirdsFilters() {
    this.popoverThirdsFilters = [];

    this.popoverThirdsFilters.push(["Roles/any(r:r/Code eq 'customer')"]);

    if (this.popoverThirdsFilters.length == 0)
      this.popoverThirdsFilters = undefined;

    // Reload datasource
    // -----------------
    // await this.taskSelect?.dataSource.load();
  }
}
