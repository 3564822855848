import { Injectable } from '@angular/core';
import { ODataService } from '../../../core/services/oData.service';
import { Configuration } from './configuration.model';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationsService extends ODataService<Configuration> {
  public url: string = 'configuration';

  public async getConfiguration(key: string, subModuleCode: string, moduleCode: string = ''): Promise<Configuration | undefined> {
    const configurations: Configuration[] = (await this.store.load({
      filter: [
        ['SubModuleId.ModuleId.Code', '=', moduleCode],
        'and',
        ['SubModuleId.Code', '=', subModuleCode],
        'and',
        ['Key', '=', key]
      ],
      expand: ['SubModuleId.ModuleId']
    }));

    if (!configurations)
      throw "No configurations found for Module " + moduleCode + " / " + subModuleCode + " / " + key;

    return configurations[0];
  }

  public async getConfigurationAsNumber(key: string, subModuleCode: string, moduleCode: string = ''): Promise<number | undefined> {
    return parseInt(<string>(await this.getConfiguration(key, subModuleCode, moduleCode))?.Value);
  }

  public async getConfigurationAsBoolean(key: string, subModuleCode: string, moduleCode: string = ''): Promise<boolean | undefined> {
    return <boolean>((await this.getConfiguration(key, subModuleCode, moduleCode))?.Value === 'true' ? true : false);
  }

  public async getConfigurationAsString(key: string, subModuleCode: string, moduleCode: string = ''): Promise<string | undefined> {
    return <string>(await this.getConfiguration(key, subModuleCode, moduleCode))?.Value;
  }
  public async getConfigurationAsText(key: string, subModuleCode: string, moduleCode: string = ''): Promise<string | undefined> {
    return <string>(await this.getConfiguration(key, subModuleCode, moduleCode))?.Value;
  }

  public async getConfigurationAsArray(key: string, subModuleCode: string, moduleCode: string = ''): Promise<Array<number> | undefined> {
    const stringValue = await this.getConfigurationAsString(key, subModuleCode, moduleCode);
    if (!stringValue) return;
    return stringValue.split(',').map(e => parseInt(e));
  }

  public async getConfigurations(subModuleCode: string): Promise<Configuration[] | undefined> {
    const configurations: Configuration[] = (await this.store.load({
      filter: [
        ['SubModuleId.Code', '=', subModuleCode]
      ]
    }));

    if (!configurations)
      throw "No configurations found for Module " + subModuleCode;

    return configurations;
  }

  static currentConfigurations: any = {};
  public setConfigurations(configuration: any) {
    ConfigurationsService.currentConfigurations = configuration;
  }

  // Pas encore trouvé de manière plus élégante de passer cette méthode
  static getDefaultPrecision(): number {
    if (ConfigurationsService.currentConfigurations.hasOwnProperty('Base.General') && ConfigurationsService.currentConfigurations['Base.General'].find((c: any) => c.Key === 'Precision.General.DefaultPrecision')) {
      return ConfigurationsService.currentConfigurations['Base.General'].find((c: any) => c.Key === 'Precision.General.DefaultPrecision').Value;
    }
    return environment.defaultPrecision;
  }

  static getPrecision(subModuleCode: string = '', key: string = ''): number {
    if (ConfigurationsService.currentConfigurations.hasOwnProperty(subModuleCode) && ConfigurationsService.currentConfigurations[subModuleCode].find((c: any) => c.Key === key)) {
      return ConfigurationsService.currentConfigurations[subModuleCode].find((c: any) => c.Key === key).Value;
    }
    return ConfigurationsService.getDefaultPrecision();
  }
}

