import { BasicEntity } from "../../models/basic-entity";
import { UserPermission } from "../user-permissions/user-permission.model";
import { UserRole } from "../user-roles/user-role.model";
import { User } from "../users/user.model";

export class UserGroup extends BasicEntity {

    public IsActive:boolean = false;
    
    public BackgroundColor:string = "";
    public ForegroundColor:string = "black";

    public Users?:User[];
    public Roles?:UserRole[];
    public Permissions?:UserPermission[];


    constructor(userGroup?: Partial<UserGroup>) {
        super();
        Object.assign(this, userGroup);
    }
}
