import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseGridComponent } from 'projects/libraries/syslink-components/src/lib/grid/base-grid/base-grid.component';
import { MailTemplate } from '../mail-template.model';
import { SyslinkColumn } from 'projects/libraries/syslink-components/src/lib/helpers/SyslinkColumn';
import { MailTemplatesService } from '../mail-templates.service';

@Component({
  selector: 'app-mail-template-grid',
  templateUrl: './mail-template-grid.component.html',
  styleUrls: ['./mail-template-grid.component.scss']
})
export class MailTemplateGridComponent  extends BaseGridComponent<MailTemplate>  {

  @Input() storageKey: string = 'mail-template-grid';
  @Output() doubleClick: EventEmitter<any> = new EventEmitter<any>();
  public columns: SyslinkColumn[] = [
    new SyslinkColumn({ field: 'Code', label: 'Code' }),
    new SyslinkColumn({ field: 'Name', label: 'Name' }),
    new SyslinkColumn({ field: 'Subject', label: 'Subject', cellTemplate: 'html-cell' }),
    new SyslinkColumn({ field: 'Content', label: 'Content', cellTemplate: 'html-cell' }),
    new SyslinkColumn({ field: 'MailTemplateTypeId.Name', label: 'Mail template type' }),
    new SyslinkColumn({ field: 'AttachFile', label: 'AttachFile', type: 'boolean', showEditorAlways:true }),
  ];

  constructor(public mailTemplatesService:MailTemplatesService){
    super();
  }
}
