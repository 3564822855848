import { DurationCellData } from "../grid/cells/duration-cell/duration-cell.component";

export class SyslinkColumn {
  public field: string = '';
  public name?: string;
  public label?: string;
  /**
   * Only use type 'object' when using object that need sort/filter fix
   */
  public type: 'string' | 'boolean' | 'number' | 'date' | 'datetime' | 'select' | 'object' | 'collection' = 'string';
  public sortIndex?: number;
  public sortDirection?: undefined | 'asc' | 'desc';
  public cellTemplate?: 'tag-cell' | 'currency-cell' | 'date-cell' | 'text-cell' | 'number-cell' | 'link-cell' | 'rrule-cell' | 'html-cell' | 'statusText-cell' | 'select-value' | 'discount-cell' | 'translations-cell' | 'boolean-cell' | 'select-cell' | 'edit-discount-cell' | 'template-cell' | 'object-discount-cell' | 'duration-cell' | 'badge-list-cell' | 'colored-date-cell' | 'icon-cell' | 'time-cell' | 'day-cell' | 'datetime-cell';
  public editCellTemplate?: string;
  public editable: boolean = true;
  public visible: boolean = true;
  public width?: string | Number;
  public data?: DurationCellData | any;
  public fixed: { position: 'right' } | boolean = false;
  public technical: boolean = false;
  public showEditorAlways: boolean = false;

  public sortable: boolean = true;
  public filterable: boolean = true;
  public headerFilterable: boolean = true;
  public order: number = 0;

  public title?: string = undefined
  public permissions?: string[] = [];
  public useMemberPermission?: boolean = true;

  constructor(syslinkGridColumn: Partial<SyslinkColumn>) {
    Object.assign(this, syslinkGridColumn);
  }
}
