import { BasicEntity } from "projects/erp-app/src/app/core/models/basic-entity";
import { TimetableLine } from "../timetable-lines/timetable-line";

export class Timetable extends BasicEntity {
    public IsDefault?: boolean;
    public TimetableLines?:TimetableLine[];

    constructor(element?: Partial<Timetable>) {
        super();
        Object.assign(this, element);
    }
}
