import { Injectable } from '@angular/core';
import { ODataService } from '../../../core/services/oData.service';
import { Country } from './country';
import { LoadOptions } from 'devextreme/data';

@Injectable({
  providedIn: 'root'
})
export class CountriesService extends ODataService<Country> {
  public url: string = 'Country';
  public override defaultOptions: LoadOptions = {
    filter:['IsVisible eq true']
   };
}
