import { Component, Input, ViewChild } from '@angular/core';
import { SyslinkColumn } from 'projects/libraries/syslink-components/src/lib/helpers/SyslinkColumn';
import { Third } from '../../../third.model';
import { ViewComponent } from 'projects/libraries/syslink-components/src/lib/helpers/view/view.component';
import { BooleanCellData } from 'projects/libraries/syslink-components/src/lib/grid/cells/boolean-cell/boolean-cell.component';
import { WorkTimesService } from 'projects/erp-app/src/app/time-management/work-times/work-times/work-times.service';
import { WorkTimeModalComponent } from 'projects/erp-app/src/app/time-management/work-times/work-times/work-time-modal/work-time-modal.component';
import { WorkTimeGridComponent } from 'projects/erp-app/src/app/time-management/work-times/work-times/work-time-grid/work-time-grid.component';
import { WorkTime } from 'projects/erp-app/src/app/time-management/work-times/work-times/work-time';

@Component({
  selector: 'app-third-document-relation-time-management-work-times',
  templateUrl: './third-document-relation-time-management-work-times.component.html',
  styleUrls: ['./third-document-relation-time-management-work-times.component.scss']
})
export class ThirdDocumentRelationTimeManagementWorkTimesComponent extends ViewComponent {
  @Input() public element: Third = new Third();

  @ViewChild("workTimeModal") workTimeModal?: WorkTimeModalComponent;
  @ViewChild("workTimeGrid") workTimeGrid?: WorkTimeGridComponent;

  constructor(
    private workTimesService: WorkTimesService,
  ) {
    super();
  }

  public WorktimeColumns: SyslinkColumn[] = [];

  public async onAddButtonClicked() {
    this.workTimeModal?.open(await this.workTimesService.getInstance({
      ThirdId: this.element
    }));
  }

  public async onValidateModalWorkTimeButtonClicked(e: WorkTime) {
    await this.workTimesService.createOrUpdate(e);
    this.workTimeModal?.close();
    this.workTimeGrid?.refresh();
  }

  override ngAfterViewInit(): void {
    super.ngAfterViewInit();

    this.WorktimeColumns = [
      new SyslinkColumn({ field: "TypeId.Name", label: "Type", width: 100 }),
      new SyslinkColumn({ field: "StartDate", name: 'StartDate-date', label: "Start date", type: "date", cellTemplate: "date-cell", width: 80 }),
      new SyslinkColumn({ field: "StartDate", name: 'StartDate-hours', label: "Start hours", type: "datetime", cellTemplate: 'time-cell', width: 80 }),
      new SyslinkColumn({ field: "EndDate", name: "EndDate-date", label: "End date", type: "date", cellTemplate: "date-cell", width: 100 }),
      new SyslinkColumn({ field: "EndDate", name: "EndDate-hours", label: "End hours", type: "datetime", cellTemplate: 'time-cell', width: 80 }),
      new SyslinkColumn({ field: "Duration", label: "Duration", type: "number", cellTemplate: 'duration-cell', width: 60 }),
      new SyslinkColumn({ field: "Description", label: "Description", cellTemplate: 'html-cell' }),
      new SyslinkColumn({ field: "UserId.ThirdId.Fullname", label: "User", width: 100 }),
      new SyslinkColumn({ field: "IsBillable", label: "Billable", width: 100, cellTemplate: 'boolean-cell', data: new BooleanCellData({ type: 'box', getColor: this.workTimeGrid?.getIsBillableColor }) }),
      new SyslinkColumn({ field: "IsBilled", label: "Billed", width: 100, cellTemplate: 'boolean-cell', data: new BooleanCellData({ type: 'box', getColor: this.workTimeGrid?.getIsBilledColor, getText: this.workTimeGrid?.getIsBilledText }) }),
      new SyslinkColumn({ field: "TaskId.FormattedName", label: "Task", visible: false }),
    ];

  }
}