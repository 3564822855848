import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ThirdsService } from '../../thirds.service';
import { ThirdRelationTypesService } from '../third-relation-types/third-relation-types.service';
import { ThirdRelation } from '../third-relation.model';

@Component({
  selector: 'app-third-relation-form',
  templateUrl: './third-relation-form.component.html',
  styleUrls: ['./third-relation-form.component.scss']
})
export class ThirdRelationFormComponent {
  @Input() element: ThirdRelation = new ThirdRelation;
  @Output() elementChange: EventEmitter<ThirdRelation> = new EventEmitter<ThirdRelation>();
  @Input() public readOnly:boolean = false;

  constructor(
    public thirdsService: ThirdsService,
    public relationTypesService: ThirdRelationTypesService
  ) { }
}
