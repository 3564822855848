import { Component, Input } from '@angular/core';
import { DXCell } from '../dxcell';

@Component({
  selector: 'syslink-icon-cell',
  templateUrl: './icon-cell.component.html',
  styleUrls: ['./icon-cell.component.scss']
})
export class IconCellComponent {

  @Input() cell: DXCell = new DXCell();
  @Input() data: IconCellData = new IconCellData();

}

export class IconCellData {
  public icon?: string;
  
  public activeColor?: string;
  public inactiveColor?: string;

  public activeClass?: string;
  public inactiveClass?: string;

  constructor(entity?: Partial<IconCellData>) {
    Object.assign(this, entity);
  }
}