import { Injectable } from '@angular/core';
import { ActionGroup } from './action-group.model';
import { ODataService } from '../../services/oData.service';
import { LoadOptions } from 'devextreme/data';

@Injectable({
  providedIn: 'root'
})
export class ActionGroupsService  extends ODataService<ActionGroup> {
  public url = 'ActionGroup';
  public override defaultOptions: LoadOptions = {
    expand: [   
      //'NameTranslationId.Translations.LanguageId'  
    ],
    select:['Name']
  };

  public override format(element: Partial<ActionGroup>): ActionGroup {
    var result: any = {
      ...element,
      ModuleId:element.ModuleId?.Id,
      SubModuleId:element.SubModuleId?.Id
    };
    return result;
  }
}