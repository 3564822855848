import { StockMoveDocument } from "../../stock-move-documents/stock-move-documents/stock-move-document.model";
import { StockMoveIncomingHeaderHeaderStatus } from "../stock-move-incoming-header-header-statuses/stock-move-incoming-header-header-status.model";

export class StockMoveIncoming extends StockMoveDocument {
    public override CurrentStatusLink?: StockMoveIncomingHeaderHeaderStatus;
    public override Statuses: StockMoveIncomingHeaderHeaderStatus[] = [];

    constructor(entity?: Partial<StockMoveIncoming>) {
        super();
        Object.assign(this, entity);
    }
}
