<syslink-chart
    [title]="'RatingHistory' | translate"
    [showLegend]="false"
    [data]="data"
    [series]="seriesCompanyValue"
></syslink-chart>
<syslink-chart
    [title]="'PrevisionOfDefault' | translate"
    [showLegend]="false"
    [data]="data"
    [series]="seriesPod"
></syslink-chart>