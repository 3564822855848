import { Injectable } from '@angular/core';
import { Currency } from '../../base/currencies/currency.model';
import { CurrenciesService } from '../../base/currencies/currency.service';
import { ConfigurationsService } from '../../base/modules/configurations/configurations.service';
import { TaxRate } from '../../base/tax-rates/tax-rate';
import { Unit } from '../../base/units/unit.model';
import { ODataService } from '../../core/services/oData.service';
import { Product } from '../products/product.model';
import { ProductsService } from '../products/product.service';
import { SupplierCatalogDiscountOperations } from './supplier-catalog-discount-operations/supplier-catalog-discount-operations';
import { SupplierCatalog } from './supplier-catalog.model';
import { UnitsService } from '../../base/units/unit.service';
import { TaxRatesService } from '../../base/tax-rates/tax-rates.service';

@Injectable({
  providedIn: 'root'
})
export class SupplierCatalogsService extends ODataService<SupplierCatalog> {
  public url = 'SupplierCatalog';
  constructor(
    private configurationsService: ConfigurationsService,
    private productsService: ProductsService,
    private currenciesService: CurrenciesService,
    private unitsService : UnitsService,
    private taxRatesService: TaxRatesService
  ) {
    super();
  }

  public async getSupplierCatalogsByProductId(productId: number): Promise<SupplierCatalog[]> {
    let options = {
      ...this.defaultOptions,
      filter: "ProductId.Id eq " + productId,
      expand: ["TaxRateId", "SupplierId.ThirdId", "UnitId", "BrandId", "CurrencyId"]//, "SaleTaxRateId"
    };
    var result = await this.load(options);
    return result;
  }

  public async getInstanceWithProduct(product: Product, supplierCatalogData?: SupplierCatalog): Promise<SupplierCatalog> {
    var supplierCatalog = {
      ...await this.getInstance(supplierCatalogData),
      ProductId: new Product(this.productsService.format(product)),
    };

    return supplierCatalog;
  }

  public override async getInstance(params?: Partial<SupplierCatalog>): Promise<SupplierCatalog> {
    var supplierCatalog = new SupplierCatalog({
      IsFavorite: false,
      ExTaxGrossPrice: 0,
      MinimumQty: 0,
      SupplierStock: 0,
      ExTaxNetPrice: 0,
      CurrencyId: new Currency(await this.currenciesService.findByID(await this.configurationsService.getConfigurationAsNumber('currency', 'Products.General', 'Products') || 0)),
      UnitId: new Unit(await this.unitsService.findByID(await this.configurationsService.getConfigurationAsNumber('unit', 'Products.General', 'Products') || 0)),
      TaxRateId: new TaxRate(await this.taxRatesService.findByID(await this.configurationsService.getConfigurationAsNumber('purchaseTaxRate', 'Products.General', 'Products') || 0)),
      DiscountId: new SupplierCatalogDiscountOperations({ Value: 0, IsDiscountFixed: false }),
      ...params
    });



    return supplierCatalog;
  }

  public override format(element: SupplierCatalog): any {
    var result: any = {
      ...element,
      ProductId: element.ProductId?.Id,
      TaxRateId: element.TaxRateId?.Id,
      SupplierId: element.SupplierId?.Id,
      UnitId: element.UnitId?.Id,
      BrandId: element.BrandId?.Id,
      CurrencyId: element.CurrencyId?.Id,
    };

    delete result.DiscountId;
   // delete result.IsFavorite;

    return result;
  }
}
