import { Injectable } from '@angular/core';
import { ODataService } from '../../../core/services/oData.service';
import { SaleDocumentLine } from '../sale-document-lines/sale-document-line.model';
import { SaleDocumentLineDiscountOperation } from './sale-document-line-discount-operation.model';

@Injectable({
  providedIn: 'root'
})
export class SaleDocumentLineDiscountOperationsService extends ODataService<SaleDocumentLineDiscountOperation>{
  public url: string = 'SaleDocumentLineDiscountOperation';
 
  constructor() { 
    super();
  }
}
