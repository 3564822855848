import { Injectable } from '@angular/core';
import { LoadOptions } from 'devextreme/data';
import { DocumentDelay } from '../../../base/documents/document-delays/document-delay';
import { ConfigurationsService } from '../../../base/modules/configurations/configurations.service';
import { ODataService } from '../../../core/services/oData.service';
import { Customer } from './customer.model';
import { ApiService } from '../../../core/api.service';

@Injectable({
  providedIn: 'root'
})
export class CustomersService extends ODataService<Customer> {
  public url = 'customer';
  public override defaultOptions: LoadOptions = {
    expand: [
      'ThirdId', 'PriceListId', 'PaymentMethodId', 'SaleTaxRateId', 'SaleInvoiceDocumentDelayId', 'SaleQuoteDocumentDelayId', 'SaleCreditNoteDocumentDelayId','NumberPlates'
    ],
    filter: ["ThirdId.HasCustomerRole eq true"]
  };

  constructor(
    private api: ApiService,
    private configurationsService: ConfigurationsService
  ) {
    super();
  }

  public override format(element: Customer): any {
    var result: any = {
      ...element,
      SaleQuoteDocumentDelayId: element.SaleQuoteDocumentDelayId?.Id,
      SaleInvoiceDocumentDelayId: element.SaleInvoiceDocumentDelayId?.Id,
      SaleCreditNoteDocumentDelayId: element.SaleCreditNoteDocumentDelayId?.Id,
      PaymentMethodId: element.PaymentMethodId?.Id,
      PriceListId: element.PriceListId?.Id,
      ThirdId: element.ThirdId?.Id,
      SaleTaxRateId: element.SaleTaxRateId?.Id
    };

    delete result['NumberPlates'];

    return result;
  }

  public override async getInstance(params?: Partial<Customer>): Promise<Customer> {
    return new Customer({
      SaleQuoteDocumentDelayId: { Id: await this.configurationsService.getConfigurationAsNumber('SaleQuoteValidity', 'Thirds.Customers', 'Thirds') },
      SaleInvoiceDocumentDelayId: { Id: await this.configurationsService.getConfigurationAsNumber('SaleInvoiceValidity', 'Thirds.Customers', 'Thirds') },
      SaleCreditNoteDocumentDelayId: { Id: await this.configurationsService.getConfigurationAsNumber('SaleCreditNoteValidity', 'Thirds.Customers', 'Thirds') },
      SaleTaxRateId: { Id: await this.configurationsService.getConfigurationAsNumber('saleTaxRate', 'Thirds.Customers', 'Thirds') },
      PriceListId: { Id: await this.configurationsService.getConfigurationAsNumber('priceList', 'Thirds.Customers', 'Thirds') },
      PaymentMethodId: { Id: await this.configurationsService.getConfigurationAsNumber('paymentMethod', 'Thirds.Customers', 'Thirds') },
      HourlyRate: await this.configurationsService.getConfigurationAsNumber('hourlyRate', 'Thirds.Customers', 'Thirds'),
      ...params
    });
  }

  public async getCustomerByThirdId(thirdId: number): Promise<Customer> {
    let options = {
      ...this.defaultOptions,
      filter: "ThirdId.Id eq " + thirdId,
      expand: [ "PaymentMethodId", "PriceListId", "SaleTaxRateId", 'SaleInvoiceDocumentDelayId', 'SaleQuoteDocumentDelayId', 'SaleCreditNoteDocumentDelayId']
    };
    var result = await this.load(options);
    return result[0];
  }

  public async getCustomerDocumentDelayByThirdId(thirdId: number): Promise<DocumentDelay> {
    // let options = {
    //   ...this.defaultOptions,
    //   filter: "ThirdId.Id eq " + thirdId,
    //   expand: ["DocumentDelayId"],
    //   select: ["DocumentDelayId"]
    // };
    // var result = await this.load(options);
    // return result[0].DocumentDelayId ? result[0].DocumentDelayId : new DocumentDelay();
    return new DocumentDelay();;
  }
    
  public async getStatistics(elementId: number, year: number) {
    return await this.api.sendRequest('/api/Customer/GetStatistics/' + elementId + '/' + year, 'GET');
  }
}
