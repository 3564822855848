import { Component, Input } from '@angular/core';
import { DXCell } from '../dxcell';

@Component({
  selector: 'syslink-tag-cell',
  templateUrl: './tag-cell.component.html',
  styleUrls: ['./tag-cell.component.scss']
})
export class TagCellComponent {
  @Input() cell: DXCell = new DXCell();
  @Input() data: TagCellData = new TagCellData();

  public color: string = "black";
  public value: string = "";

  ngOnInit(): void {
    this.color = this.data.getColor(this.cell);
    this.value = this.data.getValue(this.cell);
  }
}

export class TagCellData {
  getColor: (cell: DXCell) => string = (cell: DXCell) => "black";
  getValue: (cell: DXCell) => string = (cell: DXCell) => "";

  constructor(entity?: Partial<TagCellData>) {
    Object.assign(this, entity);
  }
}
