import { Component, Input } from '@angular/core';
import { DXCell } from '../dxcell';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'syslink-colored-date-cell',
  templateUrl: './colored-date-cell.component.html',
  styleUrls: ['./colored-date-cell.component.scss']
})
export class ColoredDateCellComponent {
  @Input() cell: DXCell = new DXCell();
  @Input() data: ColoredDateCellData = new ColoredDateCellData();

  constructor(private datePipe: DatePipe) { }

  public getValue(): string {
    if ((this.cell.value as Date)?.getFullYear() === 1) return '';

    if (this.data.formatValue) {
      return this.data.formatValue(this.cell, this.data);
    }
    return this.datePipe.transform(this.cell.value, this.data.dateFormat) ?? '';
  }
}

export class ColoredDateCellData {
  currentDate: Date = new Date();
  dateFormat: string = 'dd/MM/yyyy';
  getClass: Function | undefined;
  getColor: Function | undefined;
  getFontWeight: Function | undefined;
  formatValue: Function | undefined;

  constructor(entity?: Partial<ColoredDateCellData>) {
    Object.assign(this, entity);
  }
}
