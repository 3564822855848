<div class="dx-tag {{fill?'w-100':''}}">
  <div
    id="{{name}}-select-item-{{name}}"
    class="dx-tag-content text-white px-2 py-1 {{fill?'w-100':''}}"
    [style.background-color]="color ?? '#1a2e38'"
  >
    <div class="d-flex flex-column">
      <span>{{value}}</span>
    </div>
  </div>
</div>
