<syslink-modal
  #modal
  [title]="title"
  (onValidate)="onValidateButtonClicked(modal.data)"
  width="350"
>
  <p
    *ngIf="content"
    class="text-center m-0"
    [innerHTML]="content | translate"
  ></p>
  <syslink-select
    #select
    label="Task"
    [filter]="selectFilters"
    [store]="tasksService.store"
    [(model)]="modal.data.Task"
    displayKey="FormattedName"
    [expand]="expand"
  ></syslink-select>
</syslink-modal>