import { Injectable } from '@angular/core';
import { LoadOptions } from 'devextreme/data';
import { ODataService } from '../../../core/services/oData.service';
import { PurchaseDocumentHeaderStatus } from './purchase-document-header-status.model';

@Injectable({
  providedIn: 'root'
})
export class PurchaseDocumentHeaderStatusesService<TPurchaseDocumentHeaderStatus extends PurchaseDocumentHeaderStatus> extends ODataService<TPurchaseDocumentHeaderStatus> {
  public url: string = 'PurchaseDocumentHeaderHeaderStatus';

  public override defaultOptions: LoadOptions = {
    expand: ["StatusId"]
  };
}
