import { Injectable } from '@angular/core';
import { ODataService } from '../../../core/services/oData.service';
import { PriceCoef } from './price-coef.model';
import { LoadOptions } from 'devextreme/data';

@Injectable({
  providedIn: 'root'
})
export class PriceCoefsService extends ODataService<PriceCoef> {
  public url = 'PriceCoef';
  public override defaultOptions: LoadOptions = {
    expand: []
  };
}
