<syslink-title text="{{'Accounts'}}"></syslink-title>
<!-- <syslink-button
name="Add Account"
text="{{'Add Account' }}"
icon="fa-solid fa-border-all"
(click)="onAddClicked()"
></syslink-button> -->
<div class="accountTreeList">
  <app-account-treelist
  [permissionKey]="'list'"
  ></app-account-treelist>
  <!-- <syslink-treelist
    [columns]="columns"
    [store]="accountsService.store"
    parentIdExpr="ParentAccountId.Id"
    [options]="{editing:{allowAdding:false, allowUpdating:false, allowDeleting:false},filterRow:{visible:false},sorting:{mode:'none'},selection:{mode:'none'},pager:{visible:false}}"
    storageKey="accounts-treelist"
    [canAdd]="false"
    [showDragIcons]="false"
    [allowReordering]="false"
    [remoteOperations]="{filtering:true, grouping:true, sorting:true}"
    [select]="['AccountTypeId.Id', 'AccountTypeId.Name', 'ParentAccountId.Id']"
  ></syslink-treelist> -->
</div>

<!-- (doubleClick)="onDoubleClick($event)"
(onBeforeDelete)="onRemoveClick($event)" -->


<!-- 
<syslink-modal
  #accountModal
  title="Account"
  (onValidate)="onValidateModal()"
>

  <div class="justify-content-between align-items-center">
    <syslink-input-text
      name="code"
      [(model)]="selectedRow.Code"
      icon="fa-solid fa-paragraph"
    ></syslink-input-text>
    <syslink-input-text
      name="name"
      [(model)]="selectedRow.Name"
      icon="fa-solid fa-paragraph"
    ></syslink-input-text>

    <syslink-switch
      name="taxable"
      [(model)]="selectedRow.Taxable"
    ></syslink-switch>
    <syslink-switch
      name="letterable"
      [(model)]="selectedRow.Letterable"
    ></syslink-switch>
    <syslink-select
      name="accountType"
      [displayKey]="'Name'"
      [(model)]="selectedRow.AccountTypeId"
      [store]="accountTypesService.store"
      displayKey="name"
    ></syslink-select>
    <syslink-select
      name="Parent"
      [displayKey]="'FormattedName'"
      [(model)]="selectedRow.ParentId"
      [store]="accountsService.store"
      syslink
    ></syslink-select>
  </div>
</syslink-modal> -->