import { lastValueFrom } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { AuthService } from './auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export abstract class ApiService {
  public abstract url: string;

  constructor(protected http: HttpClient) { }

  // public load(loadOptions?: any, sort?: boolean, sortBy: string = 'name', filter: boolean = false, filters?: StoreOptionsFilter[]): Promise<any> {
  //   var storeOptions = new StoreOptions();
  //   var options: any = {};

  //   if (sort) {
  //     options.sort = [{ selector: sortBy }];
  //   }
  //   if (filter && filters) {
  //     storeOptions.filters = filters;
  //     options.filter = [];
  //   }

  //   loadOptions = loadOptions ?? storeOptions.formatLoadOptions(options);

  //   return this.sendRequest(this.url, 'GET', loadOptions);
  // }

  public insert<T>(values: any): Promise<T> {
    return this.sendRequest(this.url, 'POST', values);
  }

  public update(key: any, values: any) {
    return this.sendRequest(this.url + '/' + key, 'PUT', values);
  }

  public remove(key: any): Promise<void> {
    return <Promise<void>>this.sendRequest(this.url + '/' + key, 'DELETE', null);
  }

  public findByID<T>(id: number): Promise<T> {
    return <Promise<T>>this.sendRequest(this.url + '/' + id);
  }

  public getBlob(url: string): Promise<any> {
    return this.sendRequest(url, 'GET', {}, true);
  }

  public get(url: string): Promise<any> {
    return this.sendRequest(this.url + '/' + url);
  }

  public async sendRequest(url: string, method: 'GET' | 'PUT' | 'POST' | 'DELETE' | 'PATCH' = 'GET', data: any = {}, isBlob: boolean = false): Promise<any> {
    const httpParams = new HttpParams({
      fromObject: data,
    });
    const httpOptions = this.getHttpOptions(httpParams, method);
    if (isBlob) {
      httpOptions.responseType = 'blob';
    }
    let result: any;
    url = environment.base_url + url;
    switch (method) {
      case 'GET':
        result = this.http.get(url, httpOptions);
        break;
      case 'PUT':
        result = this.http.put(url, data, httpOptions);
        break;
      case 'POST':
        result = this.http.post(url, data, httpOptions);
        break;
      case 'DELETE':
        result = this.http.delete(url, httpOptions);
        break;
      case 'PATCH':
        result = this.http.patch(url, data, httpOptions);
        break;
    }

    return lastValueFrom(result);
  }

  public getHttpOptions(httpParams: any | null = null, method: string = 'GET') {
    let token = 'Bearer ' + AuthService.getToken();
    let options: any = {
      headers: {
        Authorization: token,
        'Accept-Language': "fr-BE"
      },
      withCredentials: true,
    };
    if (httpParams) {
      switch (method) {
        case 'GET':
          options.params = httpParams;
          break;
        default:
          options.body = httpParams;
          break;
      }
    }
    return options;
  }
}

