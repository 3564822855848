import { Component, Input, ViewChild } from '@angular/core';
import { BaseModalComponent } from 'projects/libraries/syslink-components/src/lib/modal/base-modal/base-modal.component';
import { SaleInvoiceHeaderStatus } from '../../sale-invoice-header-statuses/sale-invoice-header-status.model';
import { FilterDescriptor } from 'devextreme/data';
import { SelectComponent } from 'projects/libraries/syslink-components/src/public-api';
import { SaleInvoiceHeaderStatusesService } from '../../sale-invoice-header-statuses/sale-invoice-header-statuses.service';

@Component({
  selector: 'app-sale-invoice-status-select-modal',
  templateUrl: './sale-invoice-status-select-modal.component.html',
  styleUrls: ['./sale-invoice-status-select-modal.component.scss']
})
export class SaleInvoiceStatusSelectModalComponent extends BaseModalComponent<SaleInvoiceHeaderStatus>{
  @Input() public selectFilters: FilterDescriptor | Array<FilterDescriptor>;
  @Input() public expand: FilterDescriptor | Array<FilterDescriptor>;
  @Input() public title: string = "Select invoice status";
  @Input() public content?: string;
  @Input() public data: any;
  @ViewChild('select') select?: SelectComponent;

  constructor(public saleInvoiceHeaderStatusesService: SaleInvoiceHeaderStatusesService) {
    super();
  }

}