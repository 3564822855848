<syslink-title text="Work Times"></syslink-title>

<app-work-time-grid
  #grid
  *ngIf="viewType === WorkTimeViewTypeCode.Grid"
  [permissionKey]="'list'"
  (add)="onAddButtonClicked()"
  (rowDoubleClicked)="onRowDoubleClicked($event)"
  [toolbaActions]="gridToolbaActions"
  [contextMenuItems]="contextMenuItems"
  [filter]="workTimeFilters"
></app-work-time-grid>

<app-work-time-scheduler
  #scheduler
  *ngIf="viewType === WorkTimeViewTypeCode.Scheduler"
  [toolbarActions]="SchedulerToolbaActions"
  [canRefresh]="true"
  (add)="onAddButtonClicked()"
  (selectionChanged)="onSchedulerSelectionChanged($event)"
  (validate)="onCreateValidateButtonClicked($event)"
  [filter]="workTimeFilters"
  [appointmentContextMenuItems]="contextMenuItems"
>
</app-work-time-scheduler>

<app-work-time-modal
  #modal
  (validate)="onCreateValidateButtonClicked($event)"
></app-work-time-modal>

<app-work-time-filters-popover (valueChanged)="onWorkItemsFiltersPopoverValueChanged($event)"></app-work-time-filters-popover>

<syslink-confirm-modal
  #confirmModal
  (onValidate)="onConfirmModalValidated($event)"
></syslink-confirm-modal>
<app-sale-invoice-select-modal
  #saleInvoiceSelectModal
  (validate)="onConfirmModalValidated($event)"
  (onNewInvoiceButtonClicked)="onConfirmModalValidated($event)"
  [selectFilters]="saleInvoiceSelectModalFilter"
  [expand]="'CurrentStatusLink.StatusId'"
>
</app-sale-invoice-select-modal>
<app-task-select-modal
  #taskSelectModal
  (validate)="onConfirmModalValidated($event)"
  [expand]="'ThirdId'"
></app-task-select-modal>