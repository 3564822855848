import { Injectable } from '@angular/core';
import { ODataService } from '../../core/services/oData.service';
import { Tax } from './tax.model';
import { LoadOptions } from 'devextreme/data';

@Injectable({
  providedIn: 'root'
})
export class TaxesService extends ODataService<Tax> {
  public url = 'Tax';
  public override defaultOptions: LoadOptions = {
    filter:['IsVisible eq true']
   };
  public override format(params: Tax): Tax {
    var result : any = {
      ...params,
      SubjugationCategoryId:params?.SubjugationCategoryId?.Id,
      TaxRateId:params?.TaxRateId?.Id,
      TaxTypeId:params?.TaxTypeId?.Id,
    };
    return result;
  }
  public override async getInstance(params?: Partial<Tax>): Promise<Tax> {
    var tax = new Tax({
      ...params
    });
    return tax;
  }
}

