import { Component, Input } from '@angular/core';

export class userMenuItem {
  id?: string;
  text: string = '';
  icon?: string;
  click?: () => void;
}

@Component({
  selector: 'syslink-user-menu',
  templateUrl: './user-menu.component.html'
})
export class UserMenuComponent {
  @Input() userName: string = '';
  @Input() userMenuItems: userMenuItem[] = [];

  public onItemClick(item: userMenuItem): void {
    if (item.click) {
      item.click();
    }
  }
}
