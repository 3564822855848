import { Entity } from "../../../core/models/entity";
import { Country } from "../countries/country";
import { Locality } from "../localities/locality";

export class AdministrationArea extends Entity {
  public LongName?: string;
  public ShortName?: string;
  public Level?: number;
  public CountryId: Country = new Country();
  public ParentId?: AdministrationArea;
  public Children: Array<AdministrationArea> = [];
  public Localities: Array<Locality> = [];

  constructor(administrationArea?: Partial<AdministrationArea>) {
    super(administrationArea);
    Object.assign(this, administrationArea);
  }
}
