import { Component, Input, ViewChild } from '@angular/core';
import { SyslinkColumn } from 'projects/libraries/syslink-components/src/lib/helpers/SyslinkColumn';
import { Third } from '../../../third.model';
import { ViewComponent } from 'projects/libraries/syslink-components/src/lib/helpers/view/view.component';
import { StockMoveOutgoingsService } from 'projects/erp-app/src/app/stocks/stock-move-outgoings/stock-move-outgoings/stock-move-outgoings.service';
import { StockMoveOutgoingGridComponent } from 'projects/erp-app/src/app/stocks/stock-move-outgoings/stock-move-outgoing-grid/stock-move-outgoing-grid.component';

@Component({
  selector: 'app-third-document-relation-stock-stock-move-supplier-outgoings',
  templateUrl: './third-document-relation-stock-stock-move-supplier-outgoings.component.html',
  styleUrls: ['./third-document-relation-stock-stock-move-supplier-outgoings.component.scss']
})
export class ThirdDocumentRelationStockStockMoveSupplierOutgoingsComponent extends ViewComponent {
  @Input() public element: Third = new Third();

  public filter: string | string[] | undefined = undefined;

  constructor(
    private stockMoveOutgoingsService: StockMoveOutgoingsService,
  ) {
    super();
  }

  override ngOnInit(): void {
    if (this.element.Id) {
      this.filter = ["ThirdId.Id eq " + this.element.Id + " and Type eq 'supplier'"];
    }
  }

  @ViewChild('GridComponent') GridComponent: StockMoveOutgoingGridComponent = new StockMoveOutgoingGridComponent(this.stockMoveOutgoingsService);
  public columns: SyslinkColumn[] = [
    new SyslinkColumn({ field: "No", label: "GRID.CAPTION.NO", width: 150, sortIndex: 2, sortDirection: 'desc' }),
    new SyslinkColumn({ field: "Subject", label: "Subject" }),
    new SyslinkColumn({ field: "CurrentStatusLink.StatusId.Name", label: "Status", cellTemplate: 'statusText-cell', data: { getStatusLabel: this.GridComponent.getStatusCellLabel, getStatusDate: this.GridComponent.getStatusCellDate, getStatusClass: this.GridComponent.getStatusCellClass, getStatusColor: this.GridComponent.getStatusCellColor }, width: 120 }),
    new SyslinkColumn({ field: "Deadline", label: 'StockMoveOutgoing.Validity', type: "date" }),
    new SyslinkColumn({ field: "ExternalReference", label: "External reference", visible: true, width: 150 }),
    new SyslinkColumn({ field: "ResponsibleUserId.Fullname", label: "Responsible", width: 120 }),
    new SyslinkColumn({ field: "Date", label: "Date", type: "date", visible: false, width: 150 }),
    new SyslinkColumn({ field: "CurrentStatusLink.StatusId.Sequence", label: 'status outgoing', sortIndex: 1, sortDirection: 'asc', width: 0, visible: false, filterable: false, headerFilterable: false, sortable: false })
  ];

  onAddButtonClicked() {
    window.open('/stockmoves/supplieroutgoings/new?ThirdId=' + this.element.Id);
  }


}