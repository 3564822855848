import { Injectable } from '@angular/core';
import { LoadOptions } from 'devextreme/data';
import { SaleDocumentsService } from '../../sale-documents/sale-documents/sale-documents.service';
import { SaleQuote } from './sale-quote.model';
import { SaleQuoteHeaderStatus } from '../sale-quote-header-statuses/sale-quote-header-status.model';

@Injectable({
  providedIn: 'root'
})
export class SaleQuotesService extends SaleDocumentsService<SaleQuote, SaleQuoteHeaderStatus> {
  public override url: string = 'SaleQuoteHeader';
  
  public override defaultOptions: LoadOptions = {
    expand: [
      'ThirdId',
      'ThirdId.CustomerId',
      'ThirdId.CommunicationMethods.ThirdId',
      'ThirdId.CommunicationMethods.CommunicationLabelId',
      'ThirdId.CommunicationMethods.CommunicationTypeId',
      'IntermediateThirdId.CommunicationMethods.CommunicationLabelId',
      'IntermediateThirdId.CommunicationMethods.CommunicationTypeId',
      'CurrentStatusLink.StatusId',
      'ResponsibleUserId',
      'DocumentDelayId',
      'OriginId',
      'Lines($orderby=LineNo;$expand=TaxRateId($expand=NameTranslationId),UnitId,Margin,Discount,Children,SaleDocumentLineDiscountOperations,ParentId, ProductId)',
      'Statuses.StatusId',
      'DocumentDataCollection.ThirdId.ThirdContacts',
      'DocumentDataCollection.ThirdId.Addresses',
      'DocumentDataCollection.ContactId',
      'DocumentDataCollection.AddressId',
      'DocumentDataCollection.ThirdId.CustomerId.SaleQuoteDocumentDelayId',
      'ThirdId.ContactInformations.ThirdId',
      'ThirdId.ContactInformations.ContactTypeId',
      'DocumentRelations'
    ]
  };

  public override async getInstance(quote?: Partial<SaleQuote>): Promise<any> {

    let element = {
      Date: new Date(),
      ...quote,
    };

    element = await this.refreshResponsibleUser(element);

    return element;
  }

  public async getStatistics(filter: any) {
    var filters = this.convertODataFiltersToString(filter);
    if (filters == "") return undefined;
    return this.apiService.sendRequest('/api/SaleQuote/getStatistiques/' + filters, 'GET');
  }
}
