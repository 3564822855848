<div
    class="{{class}}"
    [hidden]="hidden"
>
    <dx-text-box
        id="duration-{{name}}"
        class="duration-input"
        [style.color]="textColor"
        [name]="name"

        [value]="getValue()"
        (onValueChanged)="onValueChanged($event.value)"

        [stylingMode]="mode"
        [label]="getLabel()"
        [labelMode]="labelMode"
        [readOnly]="disabled"

        valueChangeEvent="input"

        (click)="onClick($event)"
        (onFocusOut)="focusOut.emit()"
    ></dx-text-box>
</div>