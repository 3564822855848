import { Injectable } from '@angular/core';
import { ODataService } from '../../core/services/oData.service';
import { BankAccount } from './bank-account.model';

@Injectable({
  providedIn: 'root'
})
export class BankAccountsService extends ODataService<BankAccount> {
  public url: string="BankAccount";

  constructor() {
    super();
  }
}
