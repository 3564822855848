<div class="form">
  <div class="row">
    <div class="col-1">
      <div
        class="text-primary text-center w-100 h5 py-1 m-0"
        style="font-size: 5rem"
      >
        <i class="fa-solid fa-user"></i>
      </div>
    </div>
    <div class="col-11">
      <div class="row">
        <div class="col-{{ columnWidth }}">
          <syslink-input-text
            label="Username"
            [(model)]="user.UserName"
            [disabled]="disabled"
          ></syslink-input-text>
        </div>
        <div class="col-{{ columnWidth }}">
          <syslink-input-text
            label="Email"
            type="email"
            [(model)]="user.Email"
            [disabled]="disabled"
          ></syslink-input-text>
        </div>
        <div class="col-{{ columnWidth }}">
          <syslink-input-text
            label="New password"
            [(model)]="user.Password"
            type="password"
            [disabled]="disabled"
            [hidden]="hidePassword"
          ></syslink-input-text>
        </div>
        <div class="col-{{ columnWidth }}">
          <syslink-input-text
            label="New password confirmation"
            type="password"
            [(model)]="user.PasswordConfirmation"
            type="password"
            [disabled]="disabled"
            [hidden]="hidePassword"
          ></syslink-input-text>
        </div>
        <div class="col-{{ columnWidth }}">
          <syslink-select
            label="Language"
            [store]="languagesService.store"
            [(model)]="user.LanguageId"
            [disabled]="disabled"
          ></syslink-select>
        </div>
        <div class="col-{{ columnWidth }}">
          <syslink-color-picker
            label="BackgroundColor"
            [(model)]="user.BackgroundColor"
          ></syslink-color-picker>
        </div>
        <div class="col-{{ columnWidth }}">
          <syslink-color-picker
            label="ForegroundColor"
            [(model)]="user.ForegroundColor"
          ></syslink-color-picker>
        </div>
      </div>
    </div>
    <div class="col-1">
      <syslink-switch
        [(model)]="user.IsActive"
        type="box"
        [falseText]="'Blocked' | translate"
        [trueText]="'Active' | translate"
      ></syslink-switch>
    </div>
  </div>
</div>
