<syslink-grid
  #grid
  [canView]="canView"
  [canSearch]="canSearch"
  [canRefresh]="canRefresh"
  [canAdd]="canAdd"
  [canSort]="canSort"
  [canFilter]="canFilter"
  [canExport]="canExport"
  [canDblClck]="canDblClck"
  [canDelete]="canDelete"
  [canUpdate]="canUpdate"
  [columns]="columns"
  [filter]="filter" 
  (add)="onAddButtonClicked()"
  [service]="workTimesService"
  (doubleClick)="onDoubleClicked($event)"
  [toolbaActions]="toolbaActions"
  (afterDelete)="afterDelete.emit($event)"
  (beforeDelete)="onDeleteButtonClicked($event)"
  [contextMenuItems]="contextMenuItems"
  (refresh)="refresh()"
></syslink-grid>
