import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DocumentLineType } from 'projects/erp-app/src/app/base/documents/document-lines/document-line.model';
import { ConfigurationsService } from 'projects/erp-app/src/app/base/modules/configurations/configurations.service';
import { TaxRate } from 'projects/erp-app/src/app/base/tax-rates/tax-rate';
import { Unit } from 'projects/erp-app/src/app/base/units/unit.model';
import { Product } from 'projects/erp-app/src/app/products/products/product.model';
import { ConfirmModalComponent } from 'projects/libraries/syslink-components/src/public-api';
import { SaleDocumentLineDiscountOperationsService } from '../../sale-document-line-discount-operations/sale-document-line-discount-operations.service';
import { SaleDocumentLine } from '../../sale-document-lines/sale-document-line.model';
import { SaleDocumentLinesService } from '../../sale-document-lines/sale-document-lines.service';
import { SaleDocument } from '../sale-document.model';
import { SaleDocumentsService } from '../sale-documents.service';
import { SyslinkColumn } from 'projects/libraries/syslink-components/src/lib/helpers/SyslinkColumn';
import { getNew } from 'projects/libraries/syslink-components/src/lib/helpers/tools';
import { WorkTimeInvoicingLine } from 'projects/erp-app/src/app/time-management/work-times/work-time-invoicing-line/work-time-invoicing-line.model';
import { WorkTimesService } from 'projects/erp-app/src/app/time-management/work-times/work-times/work-times.service';
import { ViewComponent } from 'projects/libraries/syslink-components/src/lib/helpers/view/view.component';
import { SaleDocumentStatus } from '../../sale-document-statuses/sale-document-status.model';
import { WorkTimesPickerComponent } from 'projects/erp-app/src/app/time-management/work-times/work-times/work-times-picker/work-times-picker.component';

@Component({
  selector: 'app-sale-document-content',
  templateUrl: './sale-document-content.component.html',
  styleUrls: ['./sale-document-content.component.scss']
})
export class SaleDocumentContentComponent<
  TSaleDocument extends SaleDocument,
  TSaleDocumentService extends SaleDocumentsService<TSaleDocument, SaleDocumentStatus>,
  TSaleDocumentLine extends SaleDocumentLine,
  TSaleDocumentLineService extends SaleDocumentLinesService<SaleDocumentLine>
> extends ViewComponent {
  @Input() public element: TSaleDocument = <TSaleDocument>getNew<TSaleDocument>();
  @Output() public elementChange: EventEmitter<TSaleDocument> = new EventEmitter<TSaleDocument>();

  @Input() public saleDocumentService: TSaleDocumentService = <TSaleDocumentService>getNew<TSaleDocumentService>();
  @Input() public saleDocumentLinesService: TSaleDocumentLineService = <TSaleDocumentLineService>getNew<TSaleDocumentLineService>();

  @Input() public selectedKey: number[] = [];
  @Output() public elementLineChange: EventEmitter<TSaleDocumentLine> = new EventEmitter<TSaleDocumentLine>();
  @Output() public selectedKeyChange: EventEmitter<number[]> = new EventEmitter<number[]>();

  @ViewChild('createNewProduct') createNewProduct: ConfirmModalComponent = new ConfirmModalComponent();
  @ViewChild('workTimePicker') workTimePicker?: WorkTimesPickerComponent;

  @Input() disabled: boolean = false;
  @Input() public reportType: String = '';
  @Input() public subModuleCode: string = '';
  @Input() public columns: SyslinkColumn[] = [];// TSaleDocumentLineService.getDefaultDocumentLineColumns();//this.saleDocumentService.getDefaultDocumentLineColumns();//reportType=='SaleInvoice'

  public isAddingLine: boolean = false;

  constructor(
    public activatedRoute: ActivatedRoute,
    private saleDocumentLineDiscountOperationsService: SaleDocumentLineDiscountOperationsService,
    private configurationsService: ConfigurationsService,
    private translateService: TranslateService,
    private workTimesService: WorkTimesService
  ) {
    super();
  }

  // Add line
  // --------
  public async onAddLine(type: 'text' | 'title' | 'various' | 'page' | 'product' | 'workTime', product?: Product): Promise<void> {
    if (this.element.Id == null) return;
    if (this.isAddingLine) return;

    this.isAddingLine = true;
    let line: TSaleDocumentLine;

    switch (type) {
      case 'text':
        line = await this.onAddTextClicked();
        break;

      case 'title':
        line = await this.onAddTitleClicked();
        break;

      case 'various':
        line = await this.onAddVariousClicked();
        break;

      case 'page':
        line = await this.onAddPageClicked();
        break;

      case 'product':
        if (!product) return;
        line = await this.onAdvancedProductSelected(product);
        break;

      default:
        return;
    }

    this.elementLineChange.emit(line);
    this.isAddingLine = false;
  }

  private async onAddTextClicked(): Promise<TSaleDocumentLine> {
    const lineNo = await this.getLineNo();
    const parent: TSaleDocumentLine | undefined = await this.getParentId();
    const line = await this.saleDocumentLinesService.insert(this.saleDocumentLinesService.format({
      HeaderId: this.element,
      LineNo: lineNo,
      LineType: DocumentLineType.text,
      ParentId: parent
    }));
    return line;
  }

  private async onAddTitleClicked(): Promise<TSaleDocumentLine> {
    const reference = this.getReference("Post");
    const lineNo = await this.getLineNo();
    const line = await this.saleDocumentLinesService.insert(this.saleDocumentLinesService.format({
      HeaderId: this.element,
      LineNo: lineNo,
      LineType: DocumentLineType.post,
      Reference: reference
    }));
    return line;
  }

  private async onAddVariousClicked(): Promise<TSaleDocumentLine> {
    const reference = this.getReference("Various");
    const parent: TSaleDocumentLine | undefined = await this.getParentId();
    const lineNo = await this.getLineNo();
    const quantity = await this.configurationsService.getConfigurationAsNumber('variousDefaultQuantity', 'Sales.General', 'Sales');
    const unitId = new Unit({ Id: await this.configurationsService.getConfigurationAsNumber('variousDefaultUnit', 'Sales.General', 'Sales') });
    const taxRateId = new TaxRate({ Id: await this.configurationsService.getConfigurationAsNumber('variousDefaultTaxRate', 'Sales.General', 'Sales') });
    const hourlyRate = this.element.ThirdId?.CustomerId?.HourlyRate;

    const line = await this.saleDocumentLinesService.insert(this.saleDocumentLinesService.format({
      HeaderId: this.element,
      LineNo: lineNo,
      LineType: DocumentLineType.various,
      Reference: reference,
      ParentId: parent,
      Quantity: quantity,
      UnitId: unitId,
      TaxRateId: taxRateId,
      HourlyRate: hourlyRate
    }));
    return line;
  }

  private async onAddPageClicked(): Promise<TSaleDocumentLine> {
    const reference = this.getReference("Page");
    const parent: TSaleDocumentLine | undefined = await this.getParentId();
    const lineNo = await this.getLineNo();
    const line = await this.saleDocumentLinesService.insert(this.saleDocumentLinesService.format({
      HeaderId: this.element,
      LineNo: lineNo,
      LineType: DocumentLineType.page,
      Reference: reference,
      ParentId: parent
    }));
    return line;
  }

  // Product
  // -------
  public onProductNotFound(textSearched: string) {
    if (this.createNewProduct) this.createNewProduct.open({ 'text': textSearched });
  }

  public async onCreateProduct() {
    if (this.createNewProduct) this.createNewProduct.close();
    const url = this.router.serializeUrl(this.router.createUrlTree(["/products/products/new"], {
      relativeTo: this.activatedRoute, queryParams: {
        ProductInternalRef: this.createNewProduct?.data?.text ? this.createNewProduct.data.text : null,
      }
    }));
    window.open(url, '_blank');
  }

  private async onAdvancedProductSelected(product: Product): Promise<TSaleDocumentLine> {
    const parent: TSaleDocumentLine | undefined = await this.getParentId();
    const lineNo = await this.getLineNo();
    const quantity = await this.configurationsService.getConfigurationAsNumber('variousDefaultQuantity', 'Sales.General', 'Sales');
    const hourlyRate = this.element.ThirdId?.CustomerId?.HourlyRate;
    const line = await this.saleDocumentLinesService.insert(this.saleDocumentLinesService.format({
      HeaderId: this.element,
      LineNo: lineNo,
      LineType: DocumentLineType.product,
      ParentId: parent,
      ProductId: product,
      Quantity: quantity,
      HourlyRate: hourlyRate
    }));
    return line;
  }

  // Time management
  // ---------------
  public async onCreateTime(lines: WorkTimeInvoicingLine[]) {
    if (!this.element.Id) return;
    await this.workTimesService.invoice({ InvoiceId: this.element.Id, WorkTimeIds: lines.map(curr => curr.ElementId) });
    this.elementLineChange.emit();
  }
  public async onDoubleClickTime(lines: WorkTimeInvoicingLine[]) {
    await this.onCreateTime(lines)
    this.workTimePicker?.loadData();
  }

  //-------------------------------------------------------------------------

  // Delete Line
  // -----------
  public async onDeleteLine(event: any) {
    if (!event.key) return;
    let line = this.element.Lines?.find(s => s.Id == event.key);
    if (line && line.Id != null && line.ParentId != null && line.ParentId.Id) {
      var oldParentId = line.ParentId.Id;
      line.ParentId = undefined;
      await this.saleDocumentLinesService.update(line.Id, this.saleDocumentLinesService.format(line));
      await this.saleDocumentLinesService.updateParentPrice(oldParentId);
    }
    await this.saleDocumentLinesService.remove(event.key);
    this.elementLineChange.emit(event.data);
  }
  //-------------------------------------------------------------------------

  // Update Line
  // -----------
  public async onUpdateLine(event: any) {
    const line: TSaleDocumentLine = await this.saleDocumentLinesService.getInstance({ ...event.oldData, ...event.newData });

    if (line.Margin && line.Margin.Id) {
      await this.saleDocumentLineDiscountOperationsService.update(line.Margin.Id, line.Margin);
    }

    if (line.Discount && line.Discount.Id) {
      await this.saleDocumentLineDiscountOperationsService.update(line.Discount.Id, line.Discount);
    }

    if (line.ForcedPrice == null && (line.LineType == "product" || line.LineType == "various")) {
      line.ForcedPrice = 0;
    }
    if (line.Id) {
      await this.saleDocumentLinesService.update(line.Id, this.saleDocumentLinesService.format(line));
    }
    if (line.ParentId != null && line.ParentId.Id != null)
      await this.saleDocumentLinesService.updateParentPrice(line.ParentId.Id);

    this.elementLineChange.emit(line);
  }
  //-------------------------------------------------------------------------

  public onSelectedKeyChange(selectedKeys: number[]) {
    this.selectedKey = selectedKeys;
    this.selectedKeyChange.emit(this.selectedKey);
  }


  public getReference(label: string) {
    if (!this.element.Lines)
      return this.translateService.instant(label);
    let count = (this.element.Lines.filter((e: any) => e.LineType == label.toLowerCase()).length);
    return this.translateService.instant(label) + ' ' + (count + 1);
  }

  public async getLineNo() {
    if (!this.element.Lines) return 1;

    var result: number = this.element.Lines.length;

    if (this.selectedKey.length !== 0) {
      result = this.element.Lines.find(l => l.Id === this.selectedKey[0])?.LineNo || 0;
    }

    return result + 1;
  }

  public async getParentId(): Promise<TSaleDocumentLine | undefined> {
    if (this.selectedKey.length != 0) {
      let line = this.element.Lines?.find(l => l.Id === this.selectedKey[0]);
      var parent = line && (line.LineType === DocumentLineType.post ? line.Id : line.ParentId?.Id) ? await this.saleDocumentLinesService.getInstance({
        Id: (line.LineType == DocumentLineType.post ? line.Id : line.ParentId?.Id),
        HeaderId: await this.saleDocumentService.format(this.element)
      }) : undefined;

      if (parent) {
        parent.HeaderId = await this.saleDocumentService.format(this.element);
      }

      return parent;
    }
    if (this.element.Lines && this.element.Lines.length > 0) {
      let parents = this.element.Lines[this.element.Lines.length - 1];
      if (parents != null && parents.LineType == "post")
        return await this.saleDocumentLinesService.getInstance({
          Id: parents.Id,
          HeaderId: await this.saleDocumentService.format(this.element)
        });
      if (parents.ParentId != null && parents.ParentId.Id != null)
        return await this.saleDocumentLinesService.getInstance({
          Id: parents.ParentId.Id,
          HeaderId: await this.saleDocumentService.format(this.element)
        });
    }
    return undefined;
  }

  // Drag and drop
  // -------------
  public async OnReorder(e: any) {
    if (!this.element.Id) return;
    //this.ngxUiLoaderService.start();
    let targetElementIsBefore = e.fromIndex > e.toIndex; // Target element is the element before if the line is pulled up and the element after if it is dragged down.
    let realLines: TSaleDocumentLine[] = <TSaleDocumentLine[]>((await this.saleDocumentService.findByID(this.element.Id)).Lines || []);
    var lines: TSaleDocumentLine[] = <TSaleDocumentLine[]>(this.element.Lines || []);
    var parent: TSaleDocumentLine | undefined = undefined;
    var toIndex = e.toIndex;
    if (e.dropInsideItem == true && targetElementIsBefore == true)
      toIndex++;

    this.arraymove(realLines, e.fromIndex, toIndex);
    if (toIndex > 0 && realLines[toIndex].LineType != "post") {
      if (realLines[toIndex - 1].LineType == "post")
        parent = realLines[toIndex - 1];
      else
        parent = <TSaleDocumentLine | undefined>(realLines[toIndex - 1].ParentId);
    }
    else
      parent = undefined;

    var oldParent: TSaleDocumentLine | undefined = <TSaleDocumentLine | undefined>(realLines[toIndex].ParentId);
    realLines[toIndex].ParentId = parent;
    const newline = realLines[toIndex];
    if (newline.Id != undefined) {
      await this.saleDocumentLinesService.update(newline.Id, this.saleDocumentLinesService.format(newline));
    }

    // Update LineNo
    // -------------
    for (let i = 0; i < realLines.length; i++) {
      realLines[i].LineNo = i + 1;
    }

    // Update lines
    // ------------
    for (let i = 0; i < realLines.length; i++) {
      if (realLines[i].Id == lines[i].Id) { }
      else {
        var localLine = this.saleDocumentLinesService.format(realLines[i]);
        if (localLine.Id) {
          await this.saleDocumentLinesService.update(localLine.Id, localLine)
          await this.saleDocumentLinesService.changeLineNo(localLine, targetElementIsBefore);
          lines = <TSaleDocumentLine[]>((await this.saleDocumentService.findByID(this.element.Id)).Lines || []);
        }
      }
    }
    for (let i = 0; i < realLines.length; i++) {
      if (realLines[i].Id == lines[i].Id) { }
      else {
        var localLine = this.saleDocumentLinesService.format(realLines[i]);
        if (localLine.Id) {
          await this.saleDocumentLinesService.update(localLine.Id, localLine)
          await this.saleDocumentLinesService.changeLineNo(localLine, targetElementIsBefore);
          lines = <TSaleDocumentLine[]>((await this.saleDocumentService.findByID(this.element.Id)).Lines || []);
        }
      }
    }
    var lineId = realLines[toIndex];
    if (oldParent != realLines[toIndex].ParentId && oldParent != null && oldParent.Id != null) {
      await this.saleDocumentLinesService.updateParentPrice(oldParent.Id);
    }
    if (lineId && lineId.ParentId && lineId.ParentId.Id != null) {
      await this.saleDocumentLinesService.updateParentPrice(lineId.ParentId.Id);
    }
    this.elementLineChange.emit(e);
    //this.ngxUiLoaderService.stop();
  }

  public arraymove(arr: any, fromIndex: any, toIndex: any) {
    var element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
  }

}

