<label>{{element.Name}}</label>

<app-timetable-line-grid
    #grid
    [permissionKey]="'timetableLine.list'"
    [filter]="['TimetableId.Id eq '+element.Id]"
    (add)="beforeAdd($event)"
>
</app-timetable-line-grid>

<app-timetable-line-modal
    #modal
    (validate)="onCreateValidateButtonClicked($event)"
></app-timetable-line-modal>