import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DXCell } from 'projects/libraries/syslink-components/src/lib/grid/cells/dxcell';
import { rtrim } from 'projects/libraries/syslink-components/src/lib/helpers/tools';
import { BadgeListCellData, ColoredDateCellData } from 'projects/libraries/syslink-components/src/public-api';
import { WorkTimeStateCode } from '../../../works/work-time-states/work-time-state';
import { Task, TaskTimeStateCode } from '../task.model';
import { TasksService } from '../tasks.service';
import { SyslinkColumn } from 'projects/libraries/syslink-components/src/lib/helpers/SyslinkColumn';
import { BooleanCellData } from 'projects/libraries/syslink-components/src/lib/grid/cells/boolean-cell/boolean-cell.component';
import { BaseGridComponent } from 'projects/libraries/syslink-components/src/lib/grid/base-grid/base-grid.component';
import { ContextMenuItemAction } from 'projects/libraries/syslink-components/src/lib/context-menus/context-menu-item-action';
import { WorkTimesService } from '../../../work-times/work-times/work-times.service';

@Component({
  selector: 'app-task-grid',
  templateUrl: './task-grid.component.html',
  styleUrls: ['./task-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskGridComponent extends BaseGridComponent<Task> {

  public override canDelete: boolean = true;
  @Input() public override storageKey: string = 'tasks';
  @Input() public override detailsUrl: string = '/time-management/tasks/';
  @Input() public columns: SyslinkColumn[] = [
    new SyslinkColumn({ field: "No", label: 'GRID.CAPTION.NO', sortIndex: 1, sortDirection: 'desc', width: 120 }),
    new SyslinkColumn({ field: "Subject", label: "Subject" }),
    new SyslinkColumn({ field: "ThirdId.Fullname", label: "Third", width: 150 }),
    // new SyslinkColumn({ field: "TypeId.Name", label: "Type", width: 100, visible: false }),
    new SyslinkColumn({ field: "ExternalRef", label: "External reference", width: 100, visible: false }),
    new SyslinkColumn({ field: "EstimatedTime", label: "Estimated time", filterable: false, headerFilterable: false, sortable: false, width: 60, cellTemplate: "duration-cell", data: { key: TaskTimeStateCode.EstimatedTimeState, getColor: this.getDurationCellColor, getValue: this.getDurationCellValue, getVisibility: this.getDurationCellVisibility }, visible: false }),
    new SyslinkColumn({ field: "WorkedTime", label: "Worked time", filterable: false, headerFilterable: false, sortable: false, width: 60, cellTemplate: "duration-cell", data: { key: TaskTimeStateCode.WorkedTimeState, getColor: this.getDurationCellColor, getValue: this.getDurationCellValue, getVisibility: this.getDurationCellVisibility }, visible: false }),
    new SyslinkColumn({ field: "BilledTime", label: "Billed time", filterable: false, headerFilterable: false, sortable: false, width: 60, cellTemplate: "duration-cell", data: { key: TaskTimeStateCode.BilledTimeState, getColor: this.getDurationCellColor, getValue: this.getDurationCellValue, getVisibility: this.getDurationCellVisibility }, visible: false }),
    new SyslinkColumn({ field: "CurrentStatusLink.StatusId.Name", label: "Status", cellTemplate: 'statusText-cell', data: { getStatusLabel: this.getStatusCellLabel, getStatusDate: this.getStatusCellDate, getStatusClass: this.getStatusCellClass, getStatusColor: this.getStatusCellColor }, width: 120 }),
    new SyslinkColumn({ field: "Users", label: "Users", type: 'collection', width: 100, cellTemplate: 'badge-list-cell', filterable: true, headerFilterable: true, sortable: false, data: new BadgeListCellData({ contentKey: 'Name' }) }),
    new SyslinkColumn({ field: "UserGroups", label: "UserGroups", type: 'collection', width: 100, cellTemplate: 'badge-list-cell', filterable: true, headerFilterable: true, sortable: false, data: new BadgeListCellData({ contentKey: 'Name' }) }),
    new SyslinkColumn({ field: "EndDate", label: "Deadline", type: "date", width: 100, cellTemplate: 'colored-date-cell', data: new ColoredDateCellData({ getColor: this.getEndDateColor, getFontWeight: this.getEndDateFontWeight }) }),
    new SyslinkColumn({ field: "TaskPriorityId.Name", label: "Priority", width: 100, visible: false }),
    // new SyslinkColumn({ field: "IsPlannable", label: "Plannable", type: "boolean", width: 100, cellTemplate: 'boolean-cell', data: new BooleanCellData({ type: 'box' }) }),
    new SyslinkColumn({ field: "IsPerformable", label: "Performable", type: "boolean", width: 100, cellTemplate: 'boolean-cell', data: new BooleanCellData({ type: 'box' }) }),
    new SyslinkColumn({ field: "IsBillable", label: "Billable", type: "boolean", width: 100, cellTemplate: 'boolean-cell', data: new BooleanCellData({ type: 'box' }) }),
    new SyslinkColumn({ field: "AmountInvoiced", label: 'Billed', type: "number", cellTemplate: "template-cell", width: 80, data: { template: "colored-currency-cell", getColor: this.getIsBilledColor, showValue: this.showIsBilledValue }, filterable: false, headerFilterable: false, sortable: false }),
    new SyslinkColumn({ field: "IsFixedPrice", label: "Fixed price", type: "boolean", width: 100, cellTemplate: 'boolean-cell', data: new BooleanCellData({ type: 'box' }), }),
  ];

  @Input() public contextMenuItems: ContextMenuItemAction[] = [];

  constructor(
    public tasksService: TasksService,
    public workTimesService: WorkTimesService,
  ) {
    super();
  }

  // Status Cells
  // ------------
  getStatusCellLabel(cell: DXCell) {
    return cell.data.CurrentStatusLink.StatusId.Name;
  }

  getStatusCellDate(cell: DXCell) {
    return cell.data.CurrentStatusLink.Date;
  }

  getStatusCellClass(cell: DXCell) {
    let statusClass = 'text-white text-center'
    let statusCode: string | undefined = (<string>cell.data.CurrentStatusLink.StatusId.Code).toLowerCase();
    statusCode = statusCode.includes('.') ? statusCode.split('.').pop() : statusCode;
    if (statusCode == undefined) return;
    statusClass += ' ' + statusCode;
    return statusClass;
  }

  getStatusCellColor(cell: DXCell) {
    return cell.data.CurrentStatusLink.StatusId.Color;
  }
  // ---------------------------------

  // Duration Cells
  // --------------
  getDurationCellColor(cell: DXCell, key: string) {
    return cell.data[key].Color;
  }

  getDurationCellValue(cell: DXCell, key: string) {
    key = rtrim(key, "State");
    return cell.data[key];
  }

  getDurationCellVisibility(cell: DXCell, key: string) {
    const stateKey = key;
    const stateCode = cell.data[stateKey].Code;
    const isDefaultState = stateCode == WorkTimeStateCode.Default;

    return !isDefaultState;
  }
  // ---------------------------------

  // EndDate Cell
  // -------------
  getEndDateColor(cell: DXCell, data: ColoredDateCellData) {
    return cell.data[TaskTimeStateCode.DeadlineState].Color;
  }
  getEndDateFontWeight(cell: DXCell, data: ColoredDateCellData) {
    return cell.data[TaskTimeStateCode.DeadlineState].Code == WorkTimeStateCode.NotRespected ? 'bold' : '';
  }
  // ---------------------------------

  // EndDate Cell
  // -------------
  getIsBilledColor(cell: DXCell) {
    if (cell.data.BilledTimeState && cell.data.BilledTimeState.Code == 'PartiallyBilled') return "warning";
    if (cell.data.BilledTimeState && cell.data.BilledTimeState.Code == 'Billed') return "success";
    return null;
  }
  showIsBilledValue(cell: DXCell) {
    if (cell.data.BilledTimeState && cell.data.BilledTimeState.Code == 'NotBilled') return false;
    return true;
  }

  // ---------------------------------
}
