import { Injectable } from '@angular/core';
import { ODataService } from '../../core/services/oData.service';
import { MailTemplate } from './mail-template.model';
import { LoadOptions } from 'devextreme/data';

@Injectable({
  providedIn: 'root'
})
export class MailTemplatesService extends ODataService<MailTemplate> {
  public url = 'MailTemplate';
  public override defaultOptions: LoadOptions = {
    expand: ['MailTemplateTypeId']
  };
}
