import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ConfirmModalComponent, NotificationsService, PageComponent, SyslinkToolbarAction, SyslinkToolbarActionButton } from 'projects/libraries/syslink-components/src/public-api';
import { UserRole } from '../user-role.model';
import { UserRolesService } from '../user-roles.service';

@Component({
  selector: 'app-user-role-details',
  templateUrl: './user-role-details.component.html',
  styleUrls: ['./user-role-details.component.scss']
})
export class UserRoleDetailsComponent extends PageComponent implements OnInit {

  public element: UserRole = new UserRole();

  @ViewChild('deleteConfirm') deleteConfirm: ConfirmModalComponent = new ConfirmModalComponent;

  constructor(
    private ngxUiLoaderService: NgxUiLoaderService,
    public override activatedRoute: ActivatedRoute,
    private userRolesService: UserRolesService
  ) {
    super();
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.activatedRoute.data.subscribe(async ({ element }) => {
      this.ngxUiLoaderService.start();
      this.element = element;
      await this.refreshElement();
      this.ngxUiLoaderService.stop();
    });
  }
  public refreshElement() {
    this.updateBreadCrumb(this.element.Code);
    this.updateToolbar();
  }
  public updateToolbar() {
    this.toolbarActions = [];
    this.toolbarActions.push(new SyslinkToolbarActionButton({ icon: "save", text: "Save", onClick: () => this.onSavebtnClicked(), hotkey: 'control.s' }));
    if (!this.element.Id) return;
    this.toolbarActions.push(new SyslinkToolbarActionButton({ icon: "trash", text: "Delete", onClick: () => this.onRoleDeleteBtn() }));
  }

  // Save btn
  // --------
  public async onSavebtnClicked() {

    if (this.element.Code == "" ) {
      NotificationsService.sendErrorMessage("Code cannot be empty");
      return;
    }
    if (this.element.Id) {
      await this.userRolesService.update(this.element.Id, this.userRolesService.format(this.element));
    } else {
      var element = await this.userRolesService.insert(this.userRolesService.format(this.element));
      this.goToUrl('../' + element.Id);
    }
    NotificationsService.sendSuccess("Record updated");
  }

  // User delete
  // --------------
  public onRoleDeleteBtn() {
    this.deleteConfirm.open();
  }
  public async roleDelete() {
    this.deleteConfirm.close();
    if (!this.element?.Id) return;
    await this.userRolesService.remove(this.element.Id);
    NotificationsService.sendInfo('Record deleted');
    this.goToUrl('../');
  }

}
