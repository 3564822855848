import { Component, Injectable, Type } from '@angular/core';
import { ComponentContainerDirective } from './component-container.directive';
import { AppInjectorService } from '../services/app-injector.service';


@Injectable({
  providedIn: 'root'
})
export class ComponentContainerService {

  public register(key: string = "", component: ComponentEntry) {
    AppInjectorService.config.componentContainers.find(e => e.key == key)?.componentEntries.push(component);
  }

  public createComponent(container: ComponentContainerDirective) {

    const components = this.getComponentsByKey(container.key)
    components?.forEach(c => {
      let containerRef = container.viewContainerRef.createComponent(c.type).instance;
      if (c.init)
        c.init(containerRef, container.data)
    })
  }

  private getComponentsByKey(key: string = ""): ComponentEntry[] | undefined {
    return AppInjectorService.config.componentContainers.find(e => e.key == key)?.componentEntries;
  }
}

export type ComponentContainerEntry = {
  key: string;
  componentEntries: ComponentEntry[];
};

export type ComponentEntry = {
  type: Type<any>,
  init?: (instance: any, data: any) => void
}
