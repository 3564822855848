import { Component, Input } from '@angular/core';

@Component({
  selector: 'syslink-tab',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.scss']
})
export class TabComponent {
  public id?: number;
  @Input() public key: string = '';
  @Input() public label: string = 'label';
  @Input() public active: boolean = false;
  @Input() public visible: boolean = true;
  
  // @Input() public isCloseable: boolean = false;

  // @Input() public template?: any;
  // @Input() public data?: any;
}
