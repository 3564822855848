import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { DocumentDatasService } from 'projects/erp-app/src/app/base/documents/document-datas/document-datas.service';
import { DocumentStatus } from 'projects/erp-app/src/app/base/documents/document-statuses/document-status.model';
import { DocumentStatusChangedEvent } from 'projects/erp-app/src/app/base/documents/document-statuses/document-statuses.component';
import { ConfigurationsService } from 'projects/erp-app/src/app/base/modules/configurations/configurations.service';
import { ReportsService } from 'projects/erp-app/src/app/connectors/reports/reports.service';
import { ThirdsService } from 'projects/erp-app/src/app/thirds/thirds/thirds.service';
import { NotificationsService } from 'projects/libraries/syslink-components/src/public-api';
import { StockMoveDocument } from '../stock-move-document.model';
import { StockMoveDocumentsService } from '../stock-move-documents.service';
import { StockMoveDocumentLine } from '../../stock-move-document-lines/stock-move-document-line.model';
import { StockMoveDocumentLinesService } from '../../stock-move-document-lines/stock-move-document-lines.service';
import { StockMoveDocumentStatus } from '../../stock-move-document-statuses/stock-move-document-status.model';
import { StockMoveDocumentHeaderStatus } from '../../stock-move-document-header-status/stock-move-document-header-status.model';
import { StockMoveDocumentHeaderStatusesService } from '../../stock-move-document-header-status/stock-move-document-header-statuses.service';
import { StockMoveDocumentStatusesService } from '../../stock-move-document-statuses/stock-move-document-statuses.service';
import { DocumentData } from 'projects/erp-app/src/app/base/documents/document-datas/document-data.model';
import { getNew } from 'projects/libraries/syslink-components/src/lib/helpers/tools';
import { MailTemplateTypesService } from 'projects/erp-app/src/app/mails/mail-template-types/mail-template-types.service';
import { DocumentDetailsComponent } from 'projects/erp-app/src/app/base/documents/documents/document-details/document-details.component';
import { DocumentsService } from 'projects/erp-app/src/app/base/documents/documents/documents.service';

@Component({
  selector: 'app-stock-move-document-details',
  templateUrl: './stock-move-document-details.component.html',
  styleUrls: ['./stock-move-document-details.component.scss']
})
export class StockMoveDocumentDetailsComponent<
  TStockMoveDocument extends StockMoveDocument,
  TStockMoveDocumentService extends StockMoveDocumentsService<TStockMoveDocument, TStockMoveDocumentStatus>,
  TStockMoveDocumentLine extends StockMoveDocumentLine,
  TStockMoveDocumentLineService extends StockMoveDocumentLinesService<StockMoveDocumentLine>,
  TStockMoveDocumentStatus extends StockMoveDocumentStatus,
  TStockMoveDocumentStatusesService extends StockMoveDocumentStatusesService<TStockMoveDocumentStatus>,
  TStockMoveDocumentHeaderStatus extends StockMoveDocumentHeaderStatus,
  TStockMoveDocumentHeaderStatusesService extends StockMoveDocumentHeaderStatusesService<TStockMoveDocumentHeaderStatus>,
> extends DocumentDetailsComponent {

  public override element: TStockMoveDocument = <TStockMoveDocument>getNew<TStockMoveDocument>();

  public type: string = '';
  public override documentUrlController: string = "StockMoveDocument";
  public StockMoveDocumentService: TStockMoveDocumentService = <TStockMoveDocumentService>getNew<TStockMoveDocumentService>();
  public StockMoveDocumentLinesService: TStockMoveDocumentLineService = <TStockMoveDocumentLineService>getNew<TStockMoveDocumentLineService>();
  public StockMoveDocumentStatusesService: TStockMoveDocumentStatusesService = <TStockMoveDocumentStatusesService>getNew<TStockMoveDocumentStatusesService>();
  public StockMoveDocumentHeaderStatusesService: TStockMoveDocumentHeaderStatusesService = <TStockMoveDocumentHeaderStatusesService>getNew<TStockMoveDocumentHeaderStatusesService>();

  public availableStatuses: TStockMoveDocumentStatus[] = [];

  constructor(
    public override ngxUiLoaderService: NgxUiLoaderService,
    public override activatedRoute: ActivatedRoute,
    public override reportsService: ReportsService,
    public configurationsService: ConfigurationsService,
    public override documentDatasService: DocumentDatasService,
    public thirdsService: ThirdsService,
    public override mailTemplateTypesService: MailTemplateTypesService,
    public override documentService: DocumentsService
  ) {
    super(ngxUiLoaderService, reportsService, documentDatasService, documentService, mailTemplateTypesService);
  }

  override async ngOnInit(): Promise<void> {
    this.ngxUiLoaderService.start();
    this.activatedRoute.data.subscribe(async ({ element }) => {
      this.loadDependencies();

      if (!element.Id) {
        element = await this.initFromParams(element);
        element.ResponsibleUserId = this.authService.user?.ThirdId != undefined ? this.thirdsService.format(this.authService.user.ThirdId) : null;
        element.Type = this.type;
        element = await this.StockMoveDocumentService?.insert(element);
        this.goToUrl('../' + element.Id);
      }
      else {
        this.element = element;
        await this.refresh();
      }
    });
  }

  private async initFromParams(element: any) {
    if (this.activatedRoute.snapshot.queryParamMap.has('ThirdId')) {
      element = {
        ...element,
        ThirdId: { Id: this.activatedRoute.snapshot.queryParamMap.get('ThirdId') }
      };
    }
    return element;
  }

  private async reloadElement() {
    if (!this.element.Id) return;
    if (!this.StockMoveDocumentService) return;

    const option: any = this.StockMoveDocumentService.defaultOptions;
    const element: TStockMoveDocument = await this.StockMoveDocumentService.findByID(this.element.Id, option);
    this.element = element;
  }


  // Document Statuses
  // -----------------
  public override initStatusBar() {
    this.StockMoveDocumentStatusesService.load().then((statuses: DocumentStatus[]) => {
      this.documentStatuses.statuses = statuses;
      this.documentStatuses.documentStatuses = this.element.Statuses || [];
      this.documentStatuses.refreshItems();
    });
  }
  public async onDocumentStatusChanged(event: DocumentStatusChangedEvent) {
    if (!this.authService.hasPermission(this.basePermissionKey + '.update')) {
      NotificationsService.sendErrorMessage("You do not have the required permission!");
      return;
    }
    const link = {
      HeaderId: { Id: this.element.Id },
      StatusId: { Id: event.status.Id },
      Date: new Date(),
    };

    if (event.documentStatus)
      throw "StockMoveDocumentHeaderStatus already exists";

    const documentStatus = await this.updateDocumentStatus(<TStockMoveDocumentHeaderStatus>link);
    if (!this.element.Statuses || !documentStatus) {
      throw "StockMoveDocumentHeaderStatus cannot be created";
    }

    this.element.Statuses.push(documentStatus);
    await this.reloadElement();
    await this.refresh();
  }

  private async updateDocumentStatus(link: TStockMoveDocumentHeaderStatus): Promise<TStockMoveDocumentHeaderStatus | undefined> {
    let documentStatus: TStockMoveDocumentHeaderStatus = await this.StockMoveDocumentHeaderStatusesService.insert(link);
    if (!documentStatus.Id) return;

    return await this.StockMoveDocumentHeaderStatusesService.findByID(documentStatus.Id);
  }

  //-------------------------------------------------------------------------

  public override async update(showMessage: boolean = true) {
    if (!this.authService.hasPermission(this.basePermissionKey + '.update')) {
      NotificationsService.sendErrorMessage("You do not have the required permission!");
      return;
    }

    if (!this.element.Id) return;
    if (!this.StockMoveDocumentService) return;

    await this.saveDocumentData();
    await this.StockMoveDocumentService?.update(this.element.Id, await this.StockMoveDocumentService?.format(this.element));
    let StockMoveDocument = await this.StockMoveDocumentService?.findByID(this.element.Id);
    await this.reloadDocumentData();
    await this.refresh();
    if (showMessage == true) {
      NotificationsService.sendSuccess("Record updated");
    }
    this.element.DocumentRelations = StockMoveDocument.DocumentRelations;
  }

  // Document data
  // -------------
  public async reloadDocumentData() {
    if (!this.element.Id) return;
    let element = await this.StockMoveDocumentService.findByID(this.element.Id, {
      expand: ['DocumentDataCollection.ThirdId.ThirdContacts',
        'DocumentDataCollection.ThirdId.Addresses',
        'DocumentDataCollection.ContactId', 'DocumentDataCollection.DeliveryAddressId',
        'DocumentDataCollection.AddressId', 'ThirdId', 'IntermediateThirdId']
    });
    this.element.DocumentDataCollection = [];
    this.element.DocumentDataCollection = element.DocumentDataCollection;
    await this.updateDocumentData(element);
  }

  public override async updateDocumentData(StockMoveDocument: TStockMoveDocument) {
    this.element.ThirdDocumentDataIndex = StockMoveDocument.DocumentDataCollection.findIndex((d: DocumentData) => d.ThirdId.Id == StockMoveDocument.ThirdId.Id);
    this.element.ThirdDocumentData = StockMoveDocument.DocumentDataCollection[this.element.ThirdDocumentDataIndex];
    if (StockMoveDocument.IntermediateThirdId && StockMoveDocument.IntermediateThirdId.Id) {
      this.element.IntermediateThirdIdDocumentDataIndex = StockMoveDocument.DocumentDataCollection.findIndex((d: DocumentData) => d.ThirdId.Id == StockMoveDocument.IntermediateThirdId.Id) ?? -1;
      this.element.IntermediateThirdIdDocumentData = StockMoveDocument.DocumentDataCollection.find((d: DocumentData) => d.ThirdId.Id == StockMoveDocument.IntermediateThirdId.Id) ?? await this.documentDatasService.getInstance();
    }
    else {
      this.element.IntermediateThirdIdDocumentData = await this.documentDatasService.getInstance();
    }
  }

  // DocumentRelation
  // ----------------
  public async reloadDocumentRelation() {
    if (!this.element.Id) return;
    if (!this.StockMoveDocumentService) return;
    const element: TStockMoveDocument = await this.StockMoveDocumentService.findByID(this.element.Id, { expand: ["DocumentRelations"] });
    if (element.DocumentRelations) {
      this.element.DocumentRelations = element.DocumentRelations;
    }
  }

  // delete
  // --------------

  public async onDeleteConfirmed() {
    if (!this.element?.Id) return
    this.deleteConfirm.close();
    await this.StockMoveDocumentService?.remove(this.element.Id);
    this.router.navigate(['../'], { relativeTo: this.activatedRoute });
    NotificationsService.sendSuccess("Record deleted");
  }

  // Document line
  // -------------
  public async onElementLineChange(line?: TStockMoveDocumentLine) {
    if (!this.element.Id) return;
    if (!this.StockMoveDocumentService) return;

    // for test
    var expand: string[] = ['Lines($orderby=LineNo;$expand=UnitId,Children,ParentId,ToStockLocationId)'];
    if (this.reportType == "StockMoveInternal") {
      expand = ['Lines($orderby=LineNo;$expand=UnitId,Children,ParentId,ToStockLocationId, FromStockLocationId)'];
    }
    const element = (await this.StockMoveDocumentService?.findByID(this.element.Id, { expand: expand }));
    if (element.Lines) {
      this.element.Lines = element.Lines;
    }
  }
}
