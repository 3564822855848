import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Item, ItemClickEvent } from 'devextreme/ui/context_menu';

@Component({
  selector: 'syslink-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent {
  @Input() public boxText?: string;
  @Input() public icon?: string;
  @Input() public title?: string;

  @Input() public id?: string;
  @Input() public contextItems: Item[] = [{}];

  @Input() public model: any;
  @Output() public modelChange: EventEmitter<any> = new EventEmitter<any>();

  @Output() public contextMenuItemClicked: EventEmitter<{ event: any, model: any }> = new EventEmitter<{ event: any, model: any }>();

  @Input() public showSelector: boolean = false;

  @Input() public selected: boolean = false;
  @Output() public selectedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  public onCtxMenuItemClicked(event: ItemClickEvent) {
    this.contextMenuItemClicked.emit({ event, model: this.model });
  }

  public selectorChanged(event: boolean): void{
    this.selectedChange.emit(event);
  }
}
