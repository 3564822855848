<syslink-grid
    #gridComponent
    [canView]="canView"
    [canSearch]="canSearch"
    [canRefresh]="canRefresh"
    [canAdd]="canAdd"
    [canSort]="canSort"
    [canUpdate]="canUpdate"
    [canDelete]="canDelete"
    [canFilter]="canFilter"
    [canExport]="canExport"
    [canDblClck]="canDblClck"
    [store]="bankAccountsService.store"
    [archivedStore]="bankAccountsService.archivedStore"
    [restoreService]="bankAccountsService"
    [columns]="columns"
    [expand]="expand"
    [storageKey]="storageKey"
    (doubleClick)="rowDoubleClicked.emit($event)"
    [filter]="filter"
    [showPager]="false"
    (beforeAdd)="beforeAdd.emit($event)"
></syslink-grid>