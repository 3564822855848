import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'syslink-edit-translations-cell',
  templateUrl: './edit-translations-cell.component.html',
  styleUrls: ['./edit-translations-cell.component.scss']
})
export class EditTranslationsCellComponent {
  @Input() class: string = '';
  @Input() name?: string = undefined;

  @Input() model: any;
  @Output() public modelUpdate: EventEmitter<any> = new EventEmitter<any>();

  @Input() canShowIcon: boolean = true;
  @Output() public onTranslationsBtnClicked: EventEmitter<void> = new EventEmitter<void>();
}
