import { Component, Input } from '@angular/core';
import DevExpress from 'devextreme';

@Component({
  selector: 'syslink-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss']
})
export class PopoverComponent {
  @Input() targetElementSelector?: string;
  @Input() showConfiguration?: { name: string, delay?: number } = { name: 'click', delay: 0 };
  @Input() position: DevExpress.PositionConfig | string = "bottom";
  @Input() width: number | Function | string = 300;
  @Input() title?: string;
}
