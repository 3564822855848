import { Component } from '@angular/core';
import { InputComponent } from '../input/input.component';

@Component({
  selector: 'syslink-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss']
})
export class ColorPickerComponent extends InputComponent { 
  public override class: string = 'py-2';
}
