import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NotificationsService, SyslinkToolbarAction, SyslinkToolbarActionButton } from 'projects/libraries/syslink-components/src/public-api';
import { Document } from '../documents/documents/document.model';
import { PaymentMethodsService } from './payment-methods/payment-methods.service';
import { TranslateService } from '@ngx-translate/core';
import { Payment } from './payment.model';
import { PaymentsService } from './payments.service';
import { ConfigurationsService } from '../modules/configurations/configurations.service';
import { PaymentModalComponent } from './payment-modal/payment-modal.component';

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.scss']
})
export class PaymentsComponent {
  @Input() public element: Payment[] = [];
  @Input() public filterPaymentType: string | string[] = "";
  @Input() public document?: Document;
  @Input() public reportType: string = '';
  @Input() public showGrid: boolean = true;
  @Output() public paymentsChange: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('paymentModal') public paymentModal?: PaymentModalComponent;

  public paymentToolbarActions: SyslinkToolbarAction[] = [
    new SyslinkToolbarActionButton({ text: this.translateService.instant("Register payment"), onClick: () => this.onAddPaymentClicked(), location: 'before', icon: 'plus', type: 'default' })
  ];

  public payment?: Payment;
  public refundablePayments: Payment[] = [];

  constructor(
    private paymentsService: PaymentsService,
    public translateService: TranslateService,
    public paymentMethodsService: PaymentMethodsService,
    private configurationsService: ConfigurationsService
  ) { }

  /**************/
  /**** GRID ****/
  /**************/

  // Update Payment grid
  // -------------------
  public async onGridUpdatePayment(payment: Payment) {
    if (!payment.Id) return;
    await this.paymentsService.update(payment.Id, this.paymentsService.format(payment));
    NotificationsService.sendSuccess("Payment is updated");
    this.paymentsChange.emit();
  }

  // Delete Payment
  // --------------
  public async onPaymentDelete(e: any) {
    if (e.data.PaymentMethodPrimary && (e.data.PaymentMethodPrimary.Code == "Invoice" || e.data.PaymentMethodPrimary.Code == "CreditNote")) {
      var secondPayment: Payment;
      if (e.data.ParentId) {
        secondPayment = e.data.ParentId;
      }
      else {
        secondPayment = (await this.paymentsService.load({ filter: ['ParentId.Id eq ' + e.key] }))[0];
      }
      if (secondPayment && secondPayment.Id) {
        await this.paymentsService.remove(secondPayment.Id);
      }
    }
    await this.paymentsService.remove(e.key);
    this.paymentsChange.emit();
  }

  public rowDoubleClicked(e: Payment) {
    this.payment = undefined;
    this.payment = e;
    if (this.payment.PaymentTypeId && this.payment.PaymentTypeId?.Code == "Refund") {
      this.payment.Amount = - this.payment.Amount;
      this.payment.RoundedAmount = - this.payment.RoundedAmount;
    }
    setTimeout(async () => {
      this.paymentModal?.modal.open();
    }, 100);
  }

  // Add Payment
  // -----------
  public async onAddPaymentClicked() {
    await this.loadPayment();
    this.updateRefundablePayments();
    setTimeout(async () => {
      this.paymentModal?.modal.open();
    }, 100);
  }

  // LoadPayment
  // -----------
  public async loadPayment(amount?: number | undefined) {
    this.payment = await this.paymentsService.getInstance();
    this.payment.DocumentId = this.document?.Id;
    this.payment.Amount = amount ?? this.document?.AmountRemaining ?? 0;

    if (this.document?.ThirdId.CustomerId?.PaymentMethodId != undefined && !amount) {
      this.payment.PaymentMethodPrimary = this.document?.ThirdId.CustomerId?.PaymentMethodId;
    }
    if (this.payment.PaymentMethodPrimary == undefined || this.payment.PaymentMethodPrimary.Id == undefined) {
      var configurations = await this.configurationsService.getConfigurationAsNumber("paymentMethod", "Thirds.Customers", "Thirds");
      this.payment.PaymentMethodPrimary = await this.paymentMethodsService.findByID(configurations ?? 1);
    }
    this.payment.RoundedAmount = 0;
  }

  private updateRefundablePayments(): void {
    this.refundablePayments = this.element.filter((payment: Payment) => {
      if (!payment.PaymentMethodPrimary) return false;
      if (
        // Mollie
        (payment.PaymentMethodPrimary.Code == "Mollie-Terminal") &&
        // Not refund
        payment.Amount > 0 &&
        // Paid
        payment.PaymentStateId != null && payment.PaymentStateId.Code == "paid") {

        const totalRefunded = payment.Refunds?.reduce((total, refund) => total + refund.Amount, 0) ?? 0;
        return payment.Amount > totalRefunded;
      }
      return false;
    });
  }
}