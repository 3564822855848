import { Component, Input, OnInit } from '@angular/core';
import { DXCell } from '../dxcell';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'syslink-duration-cell',
  templateUrl: './duration-cell.component.html',
  styleUrls: ['./duration-cell.component.scss']
})
export class DurationCellComponent implements OnInit {

  @Input() cell: DXCell = new DXCell();
  @Input() data: any = {};
  public textColor: string = "black";
  public value: string = '00:00';
  public visible: boolean = true;

  constructor(
    private translateService?: TranslateService
  ) { }

  ngOnInit(): void {
    if(this.data?.getColor !== undefined) this.textColor = this.data?.getColor(this.cell, this.data?.key);
    this.value = this.formatValue(this.data?.getValue ? this.data?.getValue(this.cell, this.data?.key) : this.cell.value);
    if(this.data?.getVisibility !== undefined) this.visible = this.data?.getVisibility(this.cell, this.data?.key);
  }

  public formatValue(value: number | null): string {
    value = value || 0;
    let formattedValue = "";
    let hours = Math.floor(value / 60);
    let minutes = Math.floor(value % 60);

    let days = Math.floor(hours / 24);
    hours = days > 0 ? Math.floor(hours % 24) : hours;
    if (days > 0) {
      formattedValue += days + " " + this.translateService?.instant('day(s)') + " ";
    }
    formattedValue += `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;

    return formattedValue;
  }
}

export class DurationCellData {
  key?: string;
  getColor?: (cell: DXCell, key: string) => string;
  getValue?: (cell: DXCell, key: string) => string;
  getVisibility?: (cell: DXCell, key: string) => boolean;

  constructor(value : Partial<DurationCellData>){
    Object.assign(this,value);
  }
}
