import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AccountsService } from '../../../accountings/accounts/accounts.service';
import { TaxTypesService } from '../../../accountings/tax-types/tax-type.service';
import { Product } from '../product.model';

@Component({
  selector: 'app-product-purchase-form',
  templateUrl: './product-purchase-form.component.html',
  styleUrls: ['./product-purchase-form.component.scss']
})
export class ProductPurchaseFormComponent{
  @Input() public element: Product = new Product();
  @Output() public elementChange: EventEmitter<Product> = new EventEmitter<Product>();

  @Output() public supplierCatalogChanged: EventEmitter<any> = new EventEmitter<any>();
  constructor(
    public taxTypesService:TaxTypesService,
    public accountsService:AccountsService
  ) { }
}
