import { SaleDocumentHeaderStatus } from "../../sale-documents/sale-document-header-status/sale-document-header-status.model";
import { SaleInvoiceHeaderStatus } from "../sale-invoice-header-statuses/sale-invoice-header-status.model";
import { SaleInvoice } from "../sale-invoices/sale-invoice.model";

export class SaleInvoiceHeaderHeaderStatus extends SaleDocumentHeaderStatus {
    public override HeaderId?: SaleInvoice;
    public override StatusId?: SaleInvoiceHeaderStatus = new SaleInvoiceHeaderStatus();

    constructor(entity?: Partial<SaleInvoiceHeaderHeaderStatus>) {
        super();
        Object.assign(this, entity);
    }

}