import { Injectable } from '@angular/core';
import { ODataService } from '../../../core/services/oData.service';
import { DocumentDelay } from './document-delay';

@Injectable({
  providedIn: 'root'
})
export class DocumentDelaysService extends ODataService<DocumentDelay> {
  public url = 'DocumentDelay';

}