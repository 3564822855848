import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AddressType } from '../../../base/addresses/address-types/address-type';
import { ThirdContactInformation } from './third-contact-informations/third-contact-information.model';
import { ThirdContactQualifiersService } from './third-contact-qualifiers/third-contact-qualifiers.service';
import { ConfirmModalComponent, ModalComponent, NotificationsService, SyslinkToolbarAction, SyslinkToolbarActionButton } from 'projects/libraries/syslink-components/src/public-api';
import { ThirdContactInformationsService } from './third-contact-informations/third-contact-informations.service';
import { ThirdContactTypesService } from './third-contact-types/third-contact-types.service';
import { Third } from '../third.model';
import { TranslatableStringsService } from '../../../base/translations/translatable-strings/translatable-strings.service';
import { LanguagesService } from '../../../base/languages/languages.service';
import { TranslationsService } from '../../../base/translations/translations/translations.service';
import { ThirdAddress } from '../third-addresses/third-addresses/third-address.model';
import { ThirdAddressService } from '../third-addresses/third-addresses/third-addresses.service';
import { ThirdsService } from '../thirds.service';
import { TranslateService } from '@ngx-translate/core';
import { ViewComponent } from 'projects/libraries/syslink-components/src/lib/helpers/view/view.component';

@Component({
  selector: 'app-third-contacts',
  templateUrl: './third-contacts.component.html',
  styleUrls: ['./third-contacts.component.scss']
})
export class ThirdContactsComponent extends ViewComponent implements OnInit {
  @Input() elements: ThirdContactInformation[] = [];
  @Input() third?: Third;
  @Input() canAdd: boolean = true;
  @Input() canUpdate: boolean = true;
  @Input() canDelete: boolean = true;
  @Input() canCheck: boolean = false;
  @Output() elementsChange: EventEmitter<ThirdContactInformation[]> = new EventEmitter<ThirdContactInformation[]>();

  @Output() public updateContact: EventEmitter<any> = new EventEmitter<any>();

  public contacts: any[] = [];
  public addressTypes: AddressType[] = [];
  public basicExpand: string[] = ['NameTranslationId.Translations.LanguageId'];
  private selectedElement?: ThirdContactInformation;
  public editingId: number | undefined = 0;
  public editContactValue: number | undefined = 0;
  public editQualifierValue: number | undefined = 0;
  public editedAddress: ThirdAddress = new ThirdAddress();
  public toolbarActions: SyslinkToolbarAction[] = [];
  public editedAddressElement: ThirdContactInformation = new ThirdContactInformation();
  public changeQualifierEnable: boolean = false;

  @ViewChild('editAddressModal') editAddressModal?: ModalComponent;
  @ViewChild('deleteConfirm') deleteConfirm?: ConfirmModalComponent;

  constructor(
    public thirdContactQualifiersService: ThirdContactQualifiersService,
    public thirdContactTypesService: ThirdContactTypesService,
    private thirdContactInformationsService: ThirdContactInformationsService,
    private translatableStringsService: TranslatableStringsService,
    private languagesService: LanguagesService,
    private translationsService: TranslationsService,
    private thirdAddressService: ThirdAddressService,
    private thirdsService: ThirdsService,
    private translateService: TranslateService
  ) {
    super();
  }

  override ngOnInit(): void {
    this.initToolbarActions();
  }

  public initToolbarActions() {
    this.toolbarActions = [];
    if (this.canAdd)
      this.toolbarActions.push(new SyslinkToolbarActionButton({ code: 'add', icon: 'plus', text: 'New', onClick: () => this.onAddContact(), hotkey: 'control.n' , visible:this.authService.hasPermission(this.basePermissionKey + '.contacts.add')}));
  }

  public async onAddContact() {
    if (this.editingId != 0) {
      NotificationsService.sendErrorMessage(this.translateService.instant("Please save contact information"));
      return;
    }
    this.editContactValue = 0;
    this.editQualifierValue = 0;
    var newElement = await this.thirdContactInformationsService.getInstance();
    newElement.ThirdId = this.third;
    this.elements.push(newElement);
    this.editingId = newElement.Id;
  }

  public onDeleteDraftClicked(draftElement: ThirdContactInformation) {
    var index = this.elements.findIndex((item: ThirdContactInformation) => item == draftElement);
    this.elements.splice(index, 1);
    this.editingId = 0;
  }

  public async onDeleteClicked(element: ThirdContactInformation) {
    if (this.editingId != undefined && this.editingId != 0) {
      var editedElement = this.elements.find((e: ThirdContactInformation) => e.Id == this.editingId);
      editedElement != undefined ? await this.onSaveClicked(editedElement) : null;
    }
    this.selectedElement = element;
    this.deleteConfirm?.open();
  }
  public async delete() {
    this.editingId = 0;
    if (!this.selectedElement) return;
    // Remove local element
    // --------------------
    if (this.selectedElement && !this.selectedElement.Id) {
      this.elements.filter((element: ThirdContactInformation) => element !== this.selectedElement);
    }
    else if (this.selectedElement && this.selectedElement.Id) {
      if (this.selectedElement.AddressId?.Id) {
        await this.thirdAddressService.remove(this.selectedElement.AddressId.Id);
      }
      await this.thirdContactInformationsService.remove(this.selectedElement.Id);
    }
    this.selectedElement = undefined;
    this.deleteConfirm?.close();
    NotificationsService.sendSuccess("Record deleted");
    this.updateContact.emit();
  }
  public async onSaveClicked(element: ThirdContactInformation) {
    if (this.checkBeforeSave(element) == false) return;

    this.editingId = 0;
    this.editContactValue = 0;
    this.editQualifierValue = 0;

    // Change favorite
    // ---------------
    if (element.IsFavorite == true) {
      await this.checkIsFavorite(element);
    }

    // Remove unused space
    // -------------------
    element.Value = element.Value?.trim();

    // Address
    // -------
    if (element.ContactTypeId?.Code === 'Address' && element.AddressId) {
      if (!element.AddressId?.Id) {
        if (this.third != undefined) {
          element.AddressId.ThirdId = this.third;
        }
        element.AddressId = await this.thirdAddressService.insert(this.thirdAddressService.format(element.AddressId));
      } else {
        element.AddressId = await this.thirdAddressService.update(element.AddressId.Id, this.thirdAddressService.format(element.AddressId));
      }
    } else {
      delete element.AddressId;
    }

    // Qualifier
    // ---------
    if (element.ContactQualifierId && !element.ContactQualifierId?.Id && element.ContactQualifierId.Name && element.ContactQualifierId.Name.replace(/\s/g, '').length) {
      delete (element.ContactQualifierId as any).this;

      const translatableString = await this.translatableStringsService.insert({
        Code: "ContactQualifier." + element.ContactQualifierId.Name
      });

      for (const language of this.languagesService.languages) {
        await this.translationsService.insert({
          TranslatableStringId: translatableString,
          LanguageId: language,
          Value: language.IsoCode === 'fr' ? element.ContactQualifierId.Name : ''
        });
      }

      element.ContactQualifierId.NameTranslationId = translatableString;
      element.ContactQualifierId.Code = element.ContactQualifierId.Name;
      element.ContactQualifierId = await this.thirdContactQualifiersService.insert(element.ContactQualifierId);
    }
    if (element.ContactQualifierId && element.ContactQualifierId.Name && !element.ContactQualifierId.Name.replace(/\s/g, '').length) {
      element.ContactQualifierId = undefined;
      NotificationsService.sendErrorMessage('Qualifier cannot be empty');
    }

    // Create or upate
    // ---------------
    if (!element.Id) {
      element = await this.thirdContactInformationsService.insert(this.thirdContactInformationsService.format(element));
      NotificationsService.sendSuccess("Record created");
    }
    else {
      await this.thirdContactInformationsService.update(element.Id, this.thirdContactInformationsService.format(element));
      NotificationsService.sendSuccess("Record updated");
    }

    // reload element
    // --------------
    if (!element.Id) return;
    const option: any = this.thirdContactInformationsService.defaultOptions;
    element = await this.thirdContactInformationsService.findByID(element.Id, option);
    var index = this.elements.findIndex((item: ThirdContactInformation) => (item.Id == null || item.Id == undefined) || item.Id == element.Id);
    this.elements[index] = element;
    this.sortElements();
  }

  public checkBeforeSave(element: ThirdContactInformation) {
    var result = true;
    if (element.ContactTypeId == undefined) {
      NotificationsService.sendErrorMessage(this.translateService.instant("Field is required: %s"), [this.translateService.instant("Contact type")])
      result = false;
    }
    if (element.Value == '' && (element.AddressId == null || element.AddressId == undefined)) {
      NotificationsService.sendErrorMessage(this.translateService.instant("Field is required: %s"), [this.translateService.instant("Value")])
      result = false;
    }
    return result;
  }

  public sortElements() {
    this.elements.sort((a: any, b: any) => b.IsFavorite - a.IsFavorite);
  }

  public async checkIsFavorite(element: ThirdContactInformation) {
    var favoriteElement = this.elements.find((c: ThirdContactInformation) => c.IsFavorite == true && c.ContactTypeId?.Id == element.ContactTypeId?.Id && c.Id != element.Id);
    if (favoriteElement != null && favoriteElement.Id) {
      favoriteElement.IsFavorite = false;
      await this.thirdContactInformationsService.update(favoriteElement.Id, this.thirdContactInformationsService.format(favoriteElement));
    }
  }

  public onCustomValue(e: ThirdContactInformation, value: any) { }

  public onCHangeQualifierEditingMode(e: ThirdContactInformation, newValue: any) {
    e.ContactQualifierId = newValue;
  }
  public async onChangeQualifier(e: ThirdContactInformation, newValue: any) {

    if (this.changeQualifierEnable == true) return;
    this.changeQualifierEnable = true;
    var newElement = { ...e };
    if (!e.Id) {
      await this.onSaveClicked(e);
      return;
    }
    var oldElement = await this.thirdContactInformationsService.findByID(e.Id);

    // if old and new element qualifier is egal
    if (newValue.Id && oldElement.ContactQualifierId && oldElement.ContactQualifierId.Id && newValue.Id == oldElement.ContactQualifierId.Id) {
      this.changeQualifierEnable = false
      return;
    }

    // if not value
    if (newValue.Name && oldElement.ContactQualifierId && oldElement.ContactQualifierId.Name && newValue.Name == oldElement.ContactQualifierId.Name) {
      this.changeQualifierEnable = false
      return;
    }
    if (newValue.Name == "" && oldElement.ContactQualifierId == null) {
      this.changeQualifierEnable = false
      return;
    }
    if (oldElement.Id != undefined && newValue.Id == undefined && oldElement.ContactQualifierId != undefined && oldElement.ContactQualifierId.Id != undefined) {
      await this.thirdContactInformationsService.removeThirdontactQualifier(oldElement.Id)
      if (newValue.Name == '') {
        newElement.ContactQualifierId = undefined;
      }
    }
    newElement.ContactQualifierId = newValue;
    await this.onSaveClicked(newElement);
    this.changeQualifierEnable = false;
  }

  public onEditValue(id: number | undefined) {
    if (this.editingId != 0) {
      NotificationsService.sendErrorMessage(this.translateService.instant("Please save contact information"));
      return;
    }
    this.editContactValue = id;
    this.editQualifierValue = 0;
  }

  public onEditQualifierValue(id: number | undefined) {
    if (this.editingId != 0) {
      NotificationsService.sendErrorMessage(this.translateService.instant("Please save contact information"));
      return;
    }
    this.editQualifierValue = id;
    this.editContactValue = 0;
  }

  // Address
  // -------
  public async onEditAddressClicked(element: ThirdContactInformation): Promise<void> {
    if (!element.AddressId) {
      element.AddressId = new ThirdAddress();
      element.AddressId.ThirdId = await this.thirdsService.getInstance();
    }

    this.editedAddress = element.AddressId;
    this.editedAddressElement = element;
    this.editAddressModal?.open();
  }

  // Contact Type
  // ------------
  public async onChangeContactType(element: ThirdContactInformation) {
    if (element.Id || !element.ContactTypeId || !element.ContactTypeId.Id) return;
    var contactId = element.ContactTypeId.Id;
    var existingElement = (this.elements.find((e: ThirdContactInformation) => e != element && e.ContactTypeId != undefined && e.ContactTypeId.Id == contactId));
    if (existingElement == undefined) {
      element.IsFavorite = true;
    }
    else {
      element.UsedForQuote = false;
      element.UsedForDelivery = false;
      element.UsedForInvoice = false;
      element.UsedForNewsletter = false;
    }
  }

  // Toggle button
  // -------------
  public toggleButton(field: string, element: ThirdContactInformation) {
    if (this.canUpdate == false || !this.authService.hasPermission(this.basePermissionKey + '.contacts.update')) return;
    switch (field) {
      case "UsedForQuote":
        element.UsedForQuote = !element.UsedForQuote
        break;
      case "UsedForDelivery":
        element.UsedForDelivery = !element.UsedForDelivery
        break;
      case "UsedForInvoice":
        element.UsedForInvoice = !element.UsedForInvoice
        break;
      case "UsedForNewsletter":
        element.UsedForNewsletter = !element.UsedForNewsletter
        break;
      case "IsPublic":
        element.IsPublic = !element.IsPublic
        break;
      case "IsFavorite":
        element.IsFavorite = !element.IsFavorite
        break;
    }

    this.editingId !== element.Id ? this.onSaveClicked(element) : null;
  }
}
