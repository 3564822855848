<syslink-modal
  width="33vw"
  #modal
  [title]="title"
  (onValidate)="onValidate($event)"
>
  <div class="row">
    <syslink-input-text
      label="Subject"
      [(model)]="modal.data.Subject"
    ></syslink-input-text>
  </div>
  <div class="row">
    <div class="col-6">
      <syslink-select
        label="Third"
        displayKey="Fullname"
        [store]="thirdsService.store"
        [(model)]="modal.data.ThirdId"
        [filter]="[['IsSupplier eq true'], 'or', ['IsCustomer eq true']]"
        [canAdd]="authService.hasPermission('settings-thirds-thirds') && authService.hasPermission('basics.thirds.thirds.list.add')"
        [canView]="authService.hasPermission('settings-thirds-thirds')"
        detailsUrl="/basics/thirds/thirds/"
        [canRefresh]="true"
      ></syslink-select>
    </div>
    <div class="col-3 py-2">
      <syslink-switch
        [(model)]="modal.data.IsBillable"
        type="box"
        [disabled]="
          (modal.data.BilledTime != 0 || modal.data.AmountInvoiced != 0) &&
          modal.data.IsBillable
        "
        [allowDisableMessage]="true"
        disableMessage="{{
          'There is already a time or an amount billed' | translate
        }}"
        [falseText]="'Not billable' | translate"
        [trueText]="'Billable' | translate"
      ></syslink-switch>
    </div>
    <div class="col-3">
      <syslink-input-date
        label="Deadline"
        format="date"
        type="date"
        [(model)]="modal.data.EndDate"
        [min]="modal.data.StartDate"
      ></syslink-input-date>
    </div>
    <!-- <div class="col-3 py-2" *ngIf="modal.data.IsBillable">
      <syslink-switch
        [(model)]="modal.data.IsFixedPrice"
        type="box"
        [disabled]="
          (modal.data.IsFixedPrice && modal.data.AmountInvoiced != 0) ||
          (!modal.data.IsFixedPrice && modal.data.WorkedTime != 0)
        "
        [allowDisableMessage]="true"
        disableMessage="{{
          modal.data.IsFixedPrice && modal.data.AmountInvoiced != 0
            ? ('Amount already invoiced on Fixed price' | translate)
            : ('Worked time already invoiced' | translate)
        }}"
        [falseText]="'Time' | translate"
        falseColor="primary"
        trueColor="secondary"
        [trueText]="'Fixed price' | translate"
      ></syslink-switch>
    </div> -->
  </div>
  <!-- <div class="col-3">
      <syslink-input-number
        *ngIf="modal.data.IsBillable && modal.data.IsFixedPrice"
        label="Amount fixed price"
        [(model)]="modal.data.FixedPrice"
        [min]="0"
      ></syslink-input-number>
    </div> -->
  <!-- <div class="col-2"></div>
  <div class="col-4">
    <syslink-input-duration
      label="Estimated time"
      [textColor]="modal.data.EstimatedTimeState?.Color"
      [(model)]="modal.data.EstimatedTime"
    ></syslink-input-duration>
  </div> -->
  <div class="row">
    <syslink-wysiwyg
      name="Description"
      label="Description"
      [(model)]="modal.data.Description"
      [table]="false"
      height="auto"
      class="mt-1"
    ></syslink-wysiwyg>
  </div>

  <!-- <hr /> -->

  <div class="row mb-3">
    <div class="col-6">
      <syslink-tagbox
        #usersTagbox
        label="Users"
        valueKey="Oid"
        [store]="usersService.store"
        displayKey="Name"
        [(model)]="modal.data.Users"
        [filter]="['IsActive eq true']"
      ></syslink-tagbox>
    </div>
    <div class="col-6">
      <syslink-tagbox
        #userGroupsTagbox
        label="UserGroups"
        [store]="userGroupsService.store"
        displayKey="Name"
        [select]="['Id', 'Name']"
        [(model)]="modal.data.UserGroups"
        [filter]="['IsActive eq true']"
      ></syslink-tagbox>
    </div>
  </div>

  <syslink-button
    *ngIf="modal.data.Id"
    [text]="'Add workTime'"
    (onClick)="onAddWorkTimeButtonClicked()"
  ></syslink-button>
  <!-- <div class="row">
    <div class="col-4">
      <syslink-input-date
        label="Start date"
        format="date"
        type="date"
        [(model)]="modal.data.StartDate"
      ></syslink-input-date>
    </div>
    <div class="col-4">
      <syslink-input-date
        label="Deadline"
        format="date"
        type="date"
        [(model)]="modal.data.EndDate"
        [min]="modal.data.StartDate"
      ></syslink-input-date>
    </div>
    <div class="col-4">
      <syslink-input-duration
        label="Estimated time"
        [textColor]="modal.data.EstimatedTimeState?.Color"
        [(model)]="modal.data.EstimatedTime"
      ></syslink-input-duration>
    </div>
  </div> -->

  <!-- <div class="row">
    <div class="col-3">
      <syslink-switch
        [(model)]="modal.data.IsPlannable"
        type="box"
        [disabled]="modal.data.ScheduledTime != 0 && modal.data.IsPlannable"
        [allowDisableMessage]="true"
        disableMessage="{{ 'Scheduled time already Exists' | translate }}"
        [falseText]="'Not plannable' | translate"
        [trueText]="'Plannable' | translate"
      ></syslink-switch>
    </div>
    <div class="col-3">
      <syslink-switch
        [(model)]="modal.data.IsPerformable"
        type="box"
        [disabled]="modal.data.WorkedTime != 0 && modal.data.IsPerformable"
        [allowDisableMessage]="true"
        disableMessage="{{ 'Worked time already Exists' | translate }}"
        [falseText]="'Not performable' | translate"
        [trueText]="'Performable' | translate"
      ></syslink-switch>
    </div>
    <div class="col-3">
      <syslink-switch
        [(model)]="modal.data.IsBillable"
        type="box"
        [disabled]="
          (modal.data.BilledTime != 0 || modal.data.AmountInvoiced != 0) &&
          modal.data.IsBillable
        "
        [allowDisableMessage]="true"
        disableMessage="{{ 'There is already a time or an amount billed' | translate }}"
        [falseText]="'Not billable' | translate"
        [trueText]="'Billable' | translate"
      ></syslink-switch>
    </div>
   
  </div> -->

  <!-- <div class="row">
    <div class="col-3">
      <syslink-input-duration
        *ngIf="modal.data.IsPlannable && modal.data.Id"
        label="Scheduled time"
        [(model)]="modal.data.ScheduledTime"
        [disabled]="true"
      ></syslink-input-duration>
    </div>
    <div class="col-3">
      <syslink-input-duration
        *ngIf="modal.data.IsPerformable && modal.data.Id"
        label="Worked time"
        [(model)]="modal.data.WorkedTime"
        [disabled]="true"
      ></syslink-input-duration>
    </div>
    <div class="col-3">
      <syslink-input-duration
        *ngIf="modal.data.IsBillable && modal.data.Id"
        label="Billed time"
        [(model)]="modal.data.BilledTime"
        [disabled]="true"
      ></syslink-input-duration>
    </div>
   
  </div> -->

  <ng-container *ngIf="modal.data.Id">
    <hr />

    <div class="my-3 text-center">
      <syslink-link
        text="View more"
        (onClick)="onViewMoreClicked()"
      ></syslink-link>
    </div>
  </ng-container>
</syslink-modal>

<app-work-time-modal
  #worktimeModal
  (validate)="onValidateWorkTimeCreation($event)"
  [canEditTask]="false"
></app-work-time-modal>