<div class="mb-1 toolbar">
    <dx-toolbar>
        <ng-container *ngFor="let action of actions">
            <dxi-item
                *ngIf="action.visible"
                [locateInMenu]="action.inMenu"
                [location]="action.location" 
                showText="inMenu"
                [widget]="action.widget"
                [options]="action"
            ></dxi-item>
        </ng-container>
    </dx-toolbar>
</div>

<hr *ngIf="withLine" class="m-0">

<syslink-modal
    #fileManagerModal
    title="Files"
    [noFooter]="true"
>
    <syslink-file-manager
        #fileManager
        [options]="{
            permissions: {
                create: true,
                copy: true,
                move: true,
                delete: true,
                rename: true,
                upload: true,
                download: true
            }
        }"
    ></syslink-file-manager>
</syslink-modal>

