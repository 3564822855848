import { Injectable } from '@angular/core';
import { ApiService } from '../../../core/api.service';
import { ODataService } from '../../../core/services/oData.service';
import { SaleDocumentLine } from './sale-document-line.model';

@Injectable({
  providedIn: 'root'
})
export class SaleDocumentLinesService<T extends SaleDocumentLine> extends ODataService<T> {
  public url: string = 'saleDocumentLine';
  public documentType: string = "";

  constructor(
    public api: ApiService
  ) {
    super();
  }

  // Format lines
  // ------------
  public formatLines(lines: T[]) {
    lines.forEach(line => {
      if (line.LineType == "post" || line.LineType == "text" || line.LineType == "page") {
        this.clearColumnsPost(line);
      }
      if (line.LineType == "text" || line.LineType == "page") {
        this.clearColumnsText(line);
      }
      if(line.LineType != "product"){
        line.ExTaxPurchasePrice = undefined;
      }
    });
    return lines;
  }

  private clearColumnsPost(line: any) { // TODO: check type
    line.Quantity = undefined;
    line.Discount = undefined;
    line.ExTaxSaleGrossPrice = undefined;
    line.ExTaxGrossPrice = undefined;
    line.ForcedPrice = undefined;
    line.HourlyRate = undefined;
    line.Labor = undefined;
    line.Margin = undefined;
    line.ExTaxUnitPrice = undefined;
    line.TaxAmount = undefined;
  }

  private clearColumnsText(line: any) { // TODO: check type
    this.clearColumnsPost(line);
    line.ExTaxTotalPrice = undefined;
    line.InTaxTotalPrice = undefined;
    line.SaleDocumentLineDiscountOperations = undefined;
    line.IsPenaltyFixed = undefined;
    line.PenaltyValue = undefined;
  }

  public async updateParentPrice(targetParentId: number) {
    return await this.api.sendRequest('/api/SaleDocumentLine/' + targetParentId + '/updateParent/', 'POST');
  }

  public async changeLineNo(saleQuoteLine: T, targetElementIsBefore: boolean = true) {
    return await this.api.sendRequest('/api/SaleDocumentLine/' + saleQuoteLine.Id + '/changeLineNo', 'POST', {
      SelectedLine: saleQuoteLine.LineNo,
      TargetElementIsBefore: targetElementIsBefore
    }
    );
  }

  public override async insert(data: any): Promise<any> {
    data.DocumentType = this.documentType;
    var result = await this.api.sendRequest('/api/SaleDocumentLine/AddDocumentLine', 'POST', data);
    return result;
  }

  // Format
  // ------
  public override format(params?: Partial<T>): T {
    var result: any = {
      ...params,
      HeaderId: params?.HeaderId?.Id,
      ParentId: params?.ParentId != null && params?.ParentId != undefined ? params?.ParentId?.Id : null,
      ProductId: params?.ProductId?.Id,
      TaxRateId: params?.TaxRateId?.Id,
      UnitId: params?.UnitId?.Id,
    };

    delete result["Margin"];
    delete result["Discount"];
    delete result["Children"];
    delete result["SaleDocumentLineDiscountOperations"];

    return result;
  }
}
