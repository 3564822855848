import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseGridComponent } from 'projects/libraries/syslink-components/src/lib/grid/base-grid/base-grid.component';
import { Payment } from '../payment.model';
import { SyslinkToolbarAction } from 'projects/libraries/syslink-components/src/public-api';
import { SyslinkColumn } from 'projects/libraries/syslink-components/src/lib/helpers/SyslinkColumn';
import { PaymentStatesService } from '../payment-states/payment-states.service';

@Component({
  selector: 'app-payment-grid',
  templateUrl: './payment-grid.component.html',
  styleUrls: ['./payment-grid.component.scss']
})
export class PaymentGridComponent extends BaseGridComponent<Payment> {
  public override canDelete: boolean = true;

  @Input() storageKey: string = 'grid-products';
  @Input() toolbaActions: SyslinkToolbarAction[] = [];

  @Input() items: Payment[] | undefined;
  @Output() public itemsChange: EventEmitter<any> = new EventEmitter<any>();

  constructor(public paymentStatesService: PaymentStatesService) {
    super();
  }
  public columns: SyslinkColumn[] = [
    new SyslinkColumn({ field: "PaymentTypeId.Name", label: "Payment Type", editable: false }),
    new SyslinkColumn({ field: "PaymentMethodPrimary.Name", label: "Payment method", cellTemplate: 'text-cell', editable: false }),
    new SyslinkColumn({ field: "Amount", label: "Amount", cellTemplate: 'currency-cell', editable: false }),
    new SyslinkColumn({ field: "RoundedAmount", label: "Rounded amount", type: 'number', cellTemplate: 'currency-cell', editable: false }),
    new SyslinkColumn({ field: "Date", label: "Date", type: 'datetime', editable: true }),
    new SyslinkColumn({ field: "Reference", label: "Reference", editable: false }),
    new SyslinkColumn({ field: "Note", label: "Comment", editable: true, cellTemplate: 'html-cell', editCellTemplate: 'edit-html-cell' }),
    new SyslinkColumn({ field: "PaymentStateId", label: "Payment status", cellTemplate: 'select-value', editCellTemplate: "select-cell", data: { service: this.paymentStatesService, displayKey: 'Name' } }),
  ];
}
