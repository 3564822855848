import { BasicEntity } from "../../../core/models/basic-entity";
import { AssignementPriceCoef } from "../../shared/assignement-price-coefs/assignement-price-coef.model";
import { Timetable } from "../timetables/timetable";

export class TimetableLine extends BasicEntity {
    public StartHour?: Date;
    public EndHour?: Date;
    public Day?:number;
    public Date?: Date;
    public AssignementPriceCoefs?:AssignementPriceCoef[];
    public TimetableId?:Timetable;
    public FormattedName?:string;

    constructor(element?: Partial<TimetableLine>) {
        super();
        Object.assign(this, element);
    }
}
