import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PurchaseDocumentLine } from '../../purchase-document-lines/purchase-document-line.model';
import { PurchaseDocumentLinesService } from '../../purchase-document-lines/purchase-document-lines.service';
import { PurchaseDocument } from '../purchase-document.model';
import { PurchaseDocumentsService } from '../purchase-documents.service';
import { ConfigurationsService } from '../../../../base/modules/configurations/configurations.service';
import { getNew } from 'projects/libraries/syslink-components/src/lib/helpers/tools';
import { PurchaseDocumentStatus } from '../../purchase-document-statuses/purchase-document-status.model';

@Component({
  selector: 'app-purchase-document-form',
  templateUrl: './purchase-document-form.component.html',
  styleUrls: ['./purchase-document-form.component.scss']
})
export class PurchaseDocumentFormComponent<
  TPurchaseDocument extends PurchaseDocument,
  TPurchaseDocumentService extends PurchaseDocumentsService<PurchaseDocument, PurchaseDocumentStatus>,
  TPurchaseDocumentLine extends PurchaseDocumentLine,
  TPurchaseDocumentLineService extends PurchaseDocumentLinesService<PurchaseDocumentLine>
> {
  @Input() public element: TPurchaseDocument = <TPurchaseDocument>getNew<TPurchaseDocument>();
  @Output() public elementChange: EventEmitter<TPurchaseDocument> = new EventEmitter<TPurchaseDocument>();

  @Output() public dateChange: EventEmitter<Date> = new EventEmitter<Date>();
  
  @Input() public disabled: boolean = false;
  @Input() public reportType: string = '';
  @Input() public subModuleCode: string = '';

  constructor(
    public configurationsService: ConfigurationsService
  ) { }

  public getAmountRemainingColor() {
    var result = 'primary';
    const datetime = new Date(Date.now());
    const deadline = this.element.Deadline;

    if (!this.element || !this.element.Deadline || !this.element.Date || !deadline || !datetime || !this.element.AmountReceived || !this.element.AmountRemaining) return result;

    if (this.element.AmountReceived > this.element.AmountRemaining) {
      result = 'warning';
    }
    else if (this.element.AmountRemaining != 0 && deadline > datetime) {
      result = 'primary';
    }
    else if (this.element.AmountRemaining != 0 && deadline <= datetime) {
      result = 'danger';
    }
    else if (this.element.AmountRemaining == 0) {
      result = 'secondary';
    }

    return result;
  }

  public getDeadline() {
    if (this.reportType != "PurchaseOrder") return this.element.Deadline;
    switch (typeof this.element.Deadline) {
      case "string":
        return this.element.Date;
      case "object":
        return this.element.Deadline != undefined && this.element.Deadline.getFullYear() > 1 ? this.element.Deadline : undefined;
    }
    return undefined;
  }
  public onChangeDate(event: any) {
    if (!this.element.Deadline || this.reportType != "PurchaseOrder") {
      this.dateChange.emit(event); 
      return;
    }
    if (this.element.Date.getTime() > this.element.Deadline.getTime()) {
      this.element.Deadline = event
    }
  }

  public onChangeDeadLine(event: any) {
    if (!this.element.Deadline || this.reportType != "PurchaseOrder") return;
    if (event && this.element.Date.getTime() >= event.getTime()) {
      this.element.Deadline = this.element.Date
    }
    else {
      this.element.Deadline = event
    }
  }
}
