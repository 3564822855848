import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalComponent, PageComponent, WysiwygComponent } from 'projects/libraries/syslink-components/src/public-api';
import { MailTemplatesService } from './mail-templates.service';
import { MailTemplateParamsService } from '../mail-template-params/mail-template-params.service';
import { MentionTemplateData } from 'devextreme/ui/html_editor';
import { MailTemplateParam } from '../mail-template-params/mail-template-param.model';
import { MailTemplate } from './mail-template.model';
import { MailTemplateTypesService } from '../mail-template-types/mail-template-types.service';
import { MailTemplateGridComponent } from './mail-template-grid/mail-template-grid.component';

@Component({
  selector: 'app-mail-templates',
  templateUrl: './mail-templates.component.html',
  styleUrls: ['./mail-templates.component.scss']
})
export class MailTemplatesComponent extends PageComponent implements OnInit {
  public expand: string[] = ['MailTemplateTypeId'];

  public informationLabel: string = this.translateService.instant("To add a document field, enter the # character");

  @ViewChild('grid') private grid?: MailTemplateGridComponent;

  private mailTemplateParams: Array<MailTemplateParam> = [];
  public editedMailTemplate: MailTemplate = new MailTemplate();

  constructor(
    public mailTemplatesService: MailTemplatesService,
    public mailTemplateTypesService: MailTemplateTypesService,
    public mailTemplateParamsService: MailTemplateParamsService
  ) {
    super();
  }

  override ngOnInit(): void {
    super.ngOnInit();
  }

  public async loadMentions(mailTemplateTypeCode: string = ''): Promise<void> {
    this.mailTemplateParams = await this.mailTemplateParamsService.load({
      expand: ['MailTemplateTypeId'],
      filter: [`MailTemplateTypeId.Code eq '${mailTemplateTypeCode}' or MailTemplateTypeId eq null`]
    });

    this.mentions[0].dataSource = this.mailTemplateParams;
    this.wysiwygSubject?.updateOptions({ mentions: this.mentions });
    this.wysiwygContent?.updateOptions({ mentions: this.mentions });
  }

  // Modal
  // -----
  @ViewChild('editModal') private editModal?: ModalComponent;
  public async onMailTemplateEdit(mailTemplate: MailTemplate): Promise<void> {
    this.editedMailTemplate = JSON.parse(JSON.stringify(mailTemplate));
    await this.loadMentions(this.editedMailTemplate.MailTemplateTypeId?.Code);
    this.editModal?.open();
  }

  public async onMailTemplateValidate(): Promise<void> {
    if (this.editedMailTemplate.Id) {
      // SAVE
      // ----
      await this.mailTemplatesService.update(this.editedMailTemplate.Id, this.editedMailTemplate);
      this.editModal?.close();
    } else {
      // CREATE
      // ------
      await this.mailTemplatesService.insert(this.editedMailTemplate);
      this.editModal?.close();
    }

    this.grid?.grid?.grid?.instance.refresh();
  }

  public async onMailTemplateCreate(): Promise<void> {
    this.editedMailTemplate = new MailTemplate();
    await this.loadMentions(this.editedMailTemplate.MailTemplateTypeId?.Code);
    this.editModal?.open();
  }

  // Mentions
  @ViewChild('wysiwygSubject') private wysiwygSubject?: WysiwygComponent;
  @ViewChild('wysiwygContent') private wysiwygContent?: WysiwygComponent;
  public mentions: Array<any> = [
    {
      marker: '#',
      itemTemplate: (e: MailTemplateParam): string => `${e.Code} - <i>${e.Name}</i>`,
      template: (e: MentionTemplateData): string => `{{${e.value}}}`,
      displayExpr: 'Code',
      valueExpr: 'Code',
      searchExpr: ['Name', 'Code'],
      dataSource: []
    }
  ];
}
