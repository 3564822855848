import { Component } from '@angular/core';
import { SyslinkColumn } from 'projects/libraries/syslink-components/src/lib/helpers/SyslinkColumn';
import { User } from '../user.model';
import { UsersService } from '../users.service';
import { BaseGridComponent } from 'projects/libraries/syslink-components/src/lib/grid/base-grid/base-grid.component';

@Component({
  selector: 'app-user-grid',
  templateUrl: './user-grid.component.html',
  styleUrls: ['./user-grid.component.scss']
})
export class UserGridComponent extends BaseGridComponent<User> {

  override key: string = "Oid";
  override storageKey?: string | undefined = "users-grid"
  override detailsUrl: string = "/securities/users/";

  public override columns: SyslinkColumn[] = [
    new SyslinkColumn({ field: 'Oid', label: 'Id', filterable: false, headerFilterable: false, editable: false, visible: false }),
    new SyslinkColumn({ field: 'UserName', label: 'Name' }),
    new SyslinkColumn({ field: 'Email', label: 'Email', editable: false }),
  ];

  constructor(
    public usersService: UsersService,
  ) { super(); }

  // public onValidateColumnPermission(column: SyslinkColumn) {
  //   console.log();
  //   if (column.field == 'Oid'){
  //     return false;
  //   }
  //   else{
  //     return false;
  //   }
  // }
}
