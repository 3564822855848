import { Component, Input, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConfirmModalComponent, ModalComponent, NotificationsService, PageComponent } from 'projects/libraries/syslink-components/src/public-api';
import { PurchaseOrder } from '../purchase-orders/purchase-order.model';
import { PurchaseOrdersService } from './purchase-orders.service';
import { PurchaseOrderGridComponent } from '../purchase-order-grid/purchase-order-grid.component';
import { PaymentsComponent } from '../../../base/payments/payments.component';
import { Payment } from '../../../base/payments/payment.model';
import { ContextMenuItemAction } from 'projects/libraries/syslink-components/src/lib/context-menus/context-menu-item-action';
import { PurchaseContexMenuItemActionCode } from '../../purchase-documents/purchase-documents/purchase-documents.service';
import { ContextMenuItemClickEvent } from 'devextreme/ui/file_manager';
import { PurchaseOrderHeaderHeaderStatusesService } from '../purchase-order-header-header-statuses/purchase-order-header-header-statuses.service';
import { PurchaseOrderHeaderStatus } from '../purchase-order-header-statuses/purchase-order-header-status.model';
import { PurchaseOrderStatusSelectModalComponent } from '../purchase-order-status/purchase-order-status-select-modal/purchase-order-status-select-modal.component';
import { DocumentCreateModalComponent } from '../../../base/documents/document-create-modal/document-create-modal.component';

@Component({
  selector: 'app-purchase-orders',
  templateUrl: './purchase-orders.component.html',
  styleUrls: ['./purchase-orders.component.scss']
})
export class PurchaseOrdersComponent extends PageComponent {
  @Input() public filter: string | string[] = "";
  public expand: Array<string> = ['ThirdId', 'ResponsibleUserId', 'Statuses', 'CurrentStatusLink.StatusId'];

  @Input() public items?: PurchaseOrder[];
  @Input() public canRefresh: boolean = false;
  @Input() public canDelete: boolean = false;
  @Input() public delete: Function = (e: any) => {};

  @ViewChild('createModal') createModal?: DocumentCreateModalComponent
  @ViewChild('grid') grid?: PurchaseOrderGridComponent;
  @ViewChild("confirmModal") confirmModal?: ConfirmModalComponent;
  @ViewChild("statusModal") statusModal?: PurchaseOrderStatusSelectModalComponent;
  @ViewChild("paymentModal") paymentModal?: PaymentsComponent;
  
  public supplierFilter: string | string[] = ["Roles/any(r:r/Code eq 'supplier')"];
  
  // ContextMenuItemAction
  // ---------------------
  public contextMenuItems: ContextMenuItemAction[] = [
    { code: PurchaseContexMenuItemActionCode.PrintToPDF, text: "Preview to PDF", icon: "print", onItemClick: (e: any) => this.onMenuItemClicked(e), visible: this.authService.hasPermission('purchases.orders.list.contextMenu.preview') },
    { code: PurchaseContexMenuItemActionCode.PrintToZIP, text: "Download to ZIP", icon: "print", onItemClick: (e: any) => this.onMenuItemClicked(e), visible: this.authService.hasPermission('purchases.orders.list.contextMenu.preview') },
    { code: PurchaseContexMenuItemActionCode.Status, text: "Change status", icon: "edit", onItemClick: (e: any) => this.onMenuItemClicked(e), visible: this.authService.hasPermission('purchases.orders.list.contextMenu.changeStatus') },
    { code: PurchaseContexMenuItemActionCode.payment, text: "Add payment", icon: "money", onItemClick: (e: any) => this.onMenuItemClicked(e), visible: this.authService.hasPermission('purchases.orders.list.contextMenu.addPayment') }
  ];

  // Payment
  // -------
  public paymentElement: Payment[] = [];
  public paymentDocument?: PurchaseOrder;

  constructor(
    public purchaseOrdersService: PurchaseOrdersService,
    public override activatedRoute: ActivatedRoute,
    private purchaseOrderHeaderHeaderStatusesService: PurchaseOrderHeaderHeaderStatusesService
  ) { super(); }

  public onAddButtonClicked() {
    this.createModal?.open();
  };

  public onCreateValidated(element: any) {
    if (!element.ThirdId.Id) {
      NotificationsService.sendErrorMessage("Please select third");
      return;
    }
    if (element.ThirdId.Blocked == true) {
      NotificationsService.sendErrorMessage("Third is blocked");
      return;
    }
    this.router.navigateByUrl('/purchases/orders/new?ThirdId=' + element.ThirdId.Id + (element.Subject != undefined ? '&Subject=' + element.Subject : ''));
  }

  // ContextMenu
  // -----------
  public async onMenuItemClicked(e: ContextMenuItemClickEvent) {

    if (!this.grid) return;

    switch (e.itemData.code) {
      // Printing
      // ---------
      case PurchaseContexMenuItemActionCode.PrintToPDF:
        this.showConfirmModal("Would you like preview selected rows?", e.itemData);
        break;
      case PurchaseContexMenuItemActionCode.PrintToZIP:
        this.showConfirmModal("Would you like download selected rows?", e.itemData);
        break;
      case PurchaseContexMenuItemActionCode.Status:
        this.showStatusModal("Would you like change status selected rows?", e.itemData);
        break;
      case PurchaseContexMenuItemActionCode.payment:
        await this.showPaymentModal("Would you like add payment", e.itemData);
        break;
    }
  }

  private showConfirmModal(text: string, data: ContextMenuItemAction) {
    if (!this.confirmModal) return;

    this.confirmModal.title = data.text ? data.text : "Confirm";
    this.confirmModal.content = text;
    this.confirmModal.open({ rows: this.grid?.getSelectedRows(data.rowData), action: data.code });
  }
  private showStatusModal(text: string, data: ContextMenuItemAction) {
    if (!this.statusModal) return;

    this.statusModal.title = data.text ? data.text : "Confirm";
    this.statusModal.content = text;
    this.statusModal.open({ rows: this.grid?.getSelectedRows(data.rowData), action: data.code });
  }
  private async showPaymentModal(text: string, data: ContextMenuItemAction) {
    if (this.grid?.getSelectedRows(data.rowData) && this.grid?.getSelectedRows(data.rowData)?.length > 1) {
      NotificationsService.sendErrorMessage("Please select only one line");
      return;
    }
    this.paymentDocument = await this.purchaseOrdersService.findByID(this.grid?.getSelectedRows(data.rowData)[0].Id, { expand: this.purchaseOrdersService.defaultOptions.expand })
    // TODO add better method
    // Wait component is loaded
    setTimeout(async () => {
      if (!this.paymentModal || !this.paymentDocument) return;
      this.paymentElement = this.paymentDocument.Payments;
      await this.paymentModal.onAddPaymentClicked();
    }, 100);
  }

  public async onConfirmModalValidated(modalData: { rows: PurchaseOrder[], action: string } | any) {
    switch (modalData.action) {
      case PurchaseContexMenuItemActionCode.PrintToPDF:
        await this.purchaseOrdersService.printRows(modalData.rows, "pdf", "PurchaseOrder");
        break;
      case PurchaseContexMenuItemActionCode.PrintToZIP:
        await this.purchaseOrdersService.printRows(modalData.rows, "zip", "PurchaseOrder");
        break;
      case PurchaseContexMenuItemActionCode.Status:
        await this.updateStatus(modalData.rows, modalData.OrderStatus);
        break;
    }

    this.grid?.refresh();
    this.confirmModal?.close();
    this.statusModal?.close();
  }
  // ------------------------------------------------------------------------------------------------

  // Actions
  // -------
  private async updateStatus(selectedRows: PurchaseOrder[], status: PurchaseOrderHeaderStatus) {
    if (!this.purchaseOrdersService.canUpdateStatus(selectedRows, status)) return;
    selectedRows.sort((a, b) => { return a.Date.getTime() - b.Date.getTime(); });

    for (var element of selectedRows) {
      if (element.Id) {
        const link: any = {
          HeaderId: { Id: element.Id },
          StatusId: { Id: status.Id },
          Date: new Date()
        };
        await this.updateDocumentStatus(link);
      }
    }
    NotificationsService.sendSuccess('Status updated');
  }
  private async updateDocumentStatus(link: any) {
    await this.purchaseOrderHeaderHeaderStatusesService.insert(this.purchaseOrderHeaderHeaderStatusesService.format(link));
  }
}