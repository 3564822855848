import { Component, Input, ViewChild } from '@angular/core';
import { Task } from '../task.model';
import { BaseModalComponent } from 'projects/libraries/syslink-components/src/lib/modal/base-modal/base-modal.component';
import { FilterDescriptor } from 'devextreme/data';
import { SelectComponent } from 'projects/libraries/syslink-components/src/public-api';
import { TasksService } from '../tasks.service';

@Component({
  selector: 'app-task-select-modal',
  templateUrl: './task-select-modal.component.html',
  styleUrls: ['./task-select-modal.component.scss']
})
export class TaskSelectModalComponent  extends BaseModalComponent<Task>{
  @Input() public selectFilters: FilterDescriptor | Array<FilterDescriptor>;
  @Input() public expand: FilterDescriptor | Array<FilterDescriptor>;
  @Input() public showNewInvoiceButton: boolean = false;
  @Input() public title: string = "Select invoice";
  @Input() public content?: string;
  @Input() public data: any;
  @ViewChild('select') select?: SelectComponent;

  constructor(public tasksService: TasksService) {
    super();
  }
}