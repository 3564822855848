import { Component, Input, ViewChild } from '@angular/core';
import { SyslinkColumn } from 'projects/libraries/syslink-components/src/lib/helpers/SyslinkColumn';
import { Third } from '../../../third.model';
import { ViewComponent } from 'projects/libraries/syslink-components/src/lib/helpers/view/view.component';
import { SaleInvoicesService } from 'projects/erp-app/src/app/sales/sale-invoices/sale-invoices/sale-invoices.service';
import { SaleInvoiceGridComponent } from 'projects/erp-app/src/app/sales/sale-invoices/sale-invoice-grid/sale-invoice-grid.component';

@Component({
  selector: 'app-third-document-relation-sale-invoices',
  templateUrl: './third-document-relation-sale-invoices.component.html',
  styleUrls: ['./third-document-relation-sale-invoices.component.scss']
})
export class ThirdDocumentRelationSaleInvoicesComponent  extends ViewComponent  {
  @Input() public element: Third = new Third();
  
  constructor(
    private saleInvoicesService: SaleInvoicesService,
  ){
    super();
  }
  @ViewChild('invoiceGridComponent') invoiceGridComponent: SaleInvoiceGridComponent = new SaleInvoiceGridComponent(this.saleInvoicesService);
  public SaleInvoiceColumns: SyslinkColumn[] = [
    new SyslinkColumn({ field: "No", label: "GRID.CAPTION.NO", width: 150, sortIndex: 2, sortDirection: 'desc' }),
    new SyslinkColumn({ field: "Subject", label: "Subject" }),
    new SyslinkColumn({ field: "CurrentStatusLink.StatusId.Name", label: "Status", cellTemplate: 'statusText-cell', data: { getStatusLabel: this.invoiceGridComponent.getStatusCellLabel, getStatusDate: this.invoiceGridComponent.getStatusCellDate, getStatusClass: this.invoiceGridComponent.getStatusCellClass, getStatusColor: this.invoiceGridComponent.getStatusCellColor }, width: 120 }),
    new SyslinkColumn({ field: "Deadline", label: 'SaleInvoice.Validity', type: "date", cellTemplate: "template-cell", width: 80, data: { template: "TemplateDeadline", currentDate: new Date(), getAmountRemaining: this.invoiceGridComponent.getAmountRemainingCellLabel, getCellOverdue: this.invoiceGridComponent.getCellOverdue } }),
    new SyslinkColumn({ field: "ExternalReference", label: "External reference", visible: true, width: 150 }),
    new SyslinkColumn({ field: "ExTaxTotal", label: "exTaxTotal", type: "number", cellTemplate: 'currency-cell', data: { subModuleCode: 'Sales.Invoice', key: 'Precision.SaleInvoiceHeader.ExTaxTotal' }, width: 80 }),
    new SyslinkColumn({ field: "InTaxTotal", label: "Total", type: "number", cellTemplate: 'currency-cell', data: { subModuleCode: 'Sales.Invoice', key: 'Precision.SaleInvoiceHeader.InTaxTotal' }, width: 80 }),
    new SyslinkColumn({ field: "ContractId.No", label: "Contract", width: 150 }),
    new SyslinkColumn({ field: "PeriodLabel", label: "Period", filterable: false, headerFilterable: false, sortable: false }),
    new SyslinkColumn({ field: "ResponsibleUserId.Fullname", label: "Responsible", width: 120 }),
    new SyslinkColumn({ field: "Date", label: "Date", type: "date", visible: false, width: 150 }),
    new SyslinkColumn({ field: "AmountRemaining", label: 'Remaining amount', type: "number", visible: false, cellTemplate: 'currency-cell', data: { subModuleCode: 'Sales.Invoice', key: 'Precision.SaleInvoiceHeader.AmountRemaining' }, width: 80, filterable: false, headerFilterable: false, sortable: false }),
    new SyslinkColumn({ field: "FormattedStructuredCommunication", label: 'Structured communication', visible: false, editable: false }),
    new SyslinkColumn({ field: "CurrentStatusLink.StatusId.Sequence", label: 'status order', sortIndex: 1, sortDirection: 'asc', width: 0, visible: false, filterable: false, headerFilterable: false, sortable: false })
  ];
  onAddButtonClicked() {
    window.open('/sales/invoices/new?ThirdId='+this.element.Id);
  }
}