import { Entity } from "../../core/models/entity";

export class Bank  extends Entity {
    public Code:string = "";
    public IsVisible?:boolean ;
    public Bic?:string;
    
    constructor(entity?: Partial<Bank>) {
        super();
        Object.assign(this, entity);
    }
}
