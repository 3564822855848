import { Component, Input } from '@angular/core';
import { CreditsafeData } from '../creditsafe.model';

@Component({
  selector: 'app-creditsafe-payment-data',
  templateUrl: './creditsafe-payment-data.component.html',
  styleUrls: ['./creditsafe-payment-data.component.scss']
})
export class CreditsafePaymentDataComponent {
  @Input() element: CreditsafeData = new CreditsafeData();

  public getField(field: string): string | number | undefined {
    if (!this.element.Report?.PaymentData) return undefined;
    return (this.element.Report?.PaymentData as any)[field];
  }
}
