import { Injectable } from '@angular/core';
import { StockMoveDocumentStatusesService } from '../../stock-move-documents/stock-move-document-statuses/stock-move-document-statuses.service';
import { StockMoveOutgoingHeaderStatus } from './stock-move-outgoing-header-status.model';

@Injectable({
  providedIn: 'root'
})
export class StockMoveOutgoingHeaderStatusesService extends StockMoveDocumentStatusesService<StockMoveOutgoingHeaderStatus> {
  public override url: string = 'StockMoveOutgoingHeaderStatus';
 
  constructor() {
    super();
  }
}
