import { Entity } from "../../../core/models/entity";
import { SupplierCatalog } from "../supplier-catalog.model";

export class SupplierCatalogDiscountOperations extends Entity {

    constructor(params?: Partial<SupplierCatalogDiscountOperations>) {
        super(params);
        Object.assign(this, params);
    }

    public Value?: number;
    public IsDiscountFixed?: boolean;
    public Order?: number;
    public RealValue?: number;
    public DiscountOperationId?: number;
    public SupplierCatalogId?: SupplierCatalog;
}
