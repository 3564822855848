import { Injectable } from '@angular/core';
import { ODataService } from '../../core/services/oData.service';
import { Language } from './language';

@Injectable({
  providedIn: 'root'
})
export class LanguagesService extends ODataService<Language> {
  public url: string = 'Language';

  public languages: Language[] = [];

  public async loadAvailableLanguages(): Promise<void> {
    this.languages = await this.load();
  }
}
