import { DEFAULT_CURRENCY_CODE, Inject, Injector, ModuleWithProviders, NgModule } from '@angular/core';
import { AppInjectorService } from './services/app-injector.service';
import { PageComponent } from './helpers/page/page.component';
import { ViewComponent } from './helpers/view/view.component';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { LinkComponent } from './link/link.component';
import { RouterModule } from '@angular/router';
import { CommonModule, CurrencyPipe, DATE_PIPE_DEFAULT_OPTIONS, DatePipe } from '@angular/common';
import { GridComponent } from './grid/grid.component';
import { DxButtonModule, DxChartModule, DxCheckBoxModule, DxColorBoxModule, DxContextMenuModule, DxDataGridModule, DxDateBoxModule, DxDrawerModule, DxDropDownButtonModule, DxFileManagerModule, DxHtmlEditorModule, DxMenuModule, DxNumberBoxModule, DxPopoverModule, DxPopupModule, DxProgressBarModule, DxSchedulerModule, DxScrollViewModule, DxSelectBoxModule, DxSortableModule, DxSwitchModule, DxTabPanelModule, DxTabsModule, DxTagBoxModule, DxTextAreaModule, DxTextBoxModule, DxToolbarModule, DxTreeListModule, DxTreeViewModule } from 'devextreme-angular';
import { ButtonComponent } from './button/button.component';
import { DrawerComponent } from './drawer/drawer.component';
import { TitleComponent } from './title/title.component';
import { ColorPickerComponent, InputTextComponent, ProgressBarComponent, SelectComponent, SyslinkCurrencyPipe, UserMenuComponent } from '../public-api';
import { SwitchComponent } from './form/switch/switch.component';
import { CardComponent } from './card/card.component';
import { FlagComponent } from './flag/flag.component';
import { CheckboxComponent } from './form/checkbox/checkbox.component';
import { TextareaComponent } from './form/textarea/textarea.component';
import { WysiwygComponent } from './form/wysiwyg/wysiwyg.component';
import { ModalComponent } from './modal/modal.component';
import { ConfirmModalComponent } from './modal/confirm/confirm.component';
import { TagboxComponent } from './form/tagbox/tagbox.component';
import { EditTranslationsCellComponent } from './grid/edit-cells/translations-cell/edit-translations-cell.component';
import { TranslationsModalComponent } from './grid/edit-cells/translations-modal/translations-modal.component';
import { InputNumberComponent } from './form/input-number/input-number.component';
import { InputDateComponent } from './form/input-date/input-date.component';
import { NotificationsService } from './notifications/notifications.service';
import { TabsComponent } from './tabs/tabs.component';
import { TabComponent } from './tabs/tab/tab.component';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { GoogleMapComponent } from './google-map/google-map.component';
import { GooglePlacesComponent } from './google-places/google-places.component';
import { AutoformComponent } from './form/autoform/autoform.component';
import { StockCardComponent } from './stock-card/stock-card.component';
import { TreelistComponent } from './treelist/treelist.component';
import { ArrowStepperItemComponent } from './arrow-stepper-item/arrow-stepper-item.component';
import { CrudComponent } from './helpers/crud-component/crud-component.component';
import { DEFAULT_HTTP_REQUEST_TIMEOUT, DEFAULT_NOTIFICATIONS_DISPLAYTIME, DEFAULT_DATE_FORMAT, DEFAULT_PAGE_SIZE, DEFAULT_PAGE_SIZES } from './helpers/defaults';
import { StatusTextCellComponent } from './grid/cells/status-text-cell/status-text-cell.component';
import { ConfirmPasswordComponent } from './modal/confirm-password/confirm-password.component';
import { TranslateModule } from '@ngx-translate/core';
import { FileManagerComponent } from './file-manager/file-manager.component';
import { SchedulerComponent } from './scheduler/scheduler.component';
import { ThirdModalComponent } from './modal/third-modal/third-modal.component';
import { CustomTagboxComponent } from './form/custom-tagbox/custom-tagbox.component';
import { BadgeComponent } from './badge/badge.component';
import { InputDurationComponent } from './form/input-duration/input-duration.component';
import { DurationCellComponent } from './grid/cells/duration-cell/duration-cell.component';
import { TagComponent } from './tag/tag.component';
import { BadgeListCellComponent } from './grid/cells/badge-list-cell/badge-list-cell.component';
import { ColoredDateCellComponent } from './grid/cells/colored-date-cell/colored-date-cell.component';
import { KanbanComponent } from './kanban/kanban.component';
import { KanbanCardComponent } from './kanban/kanban-card/kanban-card.component';
import { PopoverComponent } from './popover/popover.component';
import { BooleanCellComponent } from './grid/cells/boolean-cell/boolean-cell.component';
import { ButtonCellComponent } from './grid/cells/button-cell/button-cell.component';
import { IconCellComponent } from './grid/cells/icon-cell/icon-cell.component';
import { ComponentContainerDirective } from './component-container/component-container.directive';
import { ComponentContainerEntry, ComponentContainerService } from './component-container/component-container.service';
import { BaseModalComponent } from './modal/base-modal/base-modal.component';
import { TimeCellComponent } from './grid/cells/time-cell/time-cell.component';
import { TagCellComponent } from './grid/cells/tag-cell/tag-cell.component';
import { DayCellComponent } from './grid/cells/day-cell/day-cell.component';
import { SelectDayComponent } from './form/select-day/select-day.component';
import { ChartComponent } from './chart/chart.component';

export class Config {
  getToken: () => string | null = () => '';
  getPrecision: (subModuleCode: string, key: string) => number = () => 2;
  useStateStoring: boolean = true;
  defaultInputStylingMode: 'outlined' | 'underlined' | 'filled' = 'underlined';
  componentContainers: ComponentContainerEntry[] = []
  baseUrl: string = '';
}

@NgModule({
  imports: [
    TranslateModule,
    CommonModule,
    RouterModule,
    DxDataGridModule,
    DxSwitchModule,
    DxButtonModule,
    DxScrollViewModule,
    DxTreeViewModule,
    DxDrawerModule,
    DxSelectBoxModule,
    DxTextBoxModule,
    DxContextMenuModule,
    DxCheckBoxModule,
    DxColorBoxModule,
    DxTextAreaModule,
    DxHtmlEditorModule,
    DxPopupModule,
    DxTagBoxModule,
    DxDropDownButtonModule,
    DxNumberBoxModule,
    DxDateBoxModule,
    DxTabsModule,
    DxToolbarModule,
    GooglePlaceModule,
    DxTreeListModule,
    DxFileManagerModule,
    DxSchedulerModule,
    DxSortableModule,
    DxPopoverModule,
    DxChartModule,
    DxMenuModule
  ],
  declarations: [
    PageComponent,
    ViewComponent,
    BreadcrumbsComponent,
    LinkComponent,
    GridComponent,
    ButtonComponent,
    DrawerComponent,
    TitleComponent,
    InputTextComponent,
    SelectComponent,
    SwitchComponent,
    CardComponent,
    FlagComponent,
    CheckboxComponent,
    ColorPickerComponent,
    TextareaComponent,
    WysiwygComponent,
    ModalComponent,
    ConfirmModalComponent,
    TagboxComponent,
    UserMenuComponent,
    EditTranslationsCellComponent,
    TranslationsModalComponent,
    InputNumberComponent,
    InputDateComponent,
    TabsComponent,
    TabComponent,
    ToolbarComponent,
    GoogleMapComponent,
    GooglePlacesComponent,
    AutoformComponent,
    StockCardComponent,
    TreelistComponent,
    ArrowStepperItemComponent,
    CrudComponent,
    StatusTextCellComponent,
    ConfirmPasswordComponent,
    SchedulerComponent,
    ThirdModalComponent,
    CustomTagboxComponent,
    SyslinkCurrencyPipe,
    BadgeComponent,
    InputDurationComponent,
    DurationCellComponent,
    ProgressBarComponent,
    TagComponent,
    BadgeListCellComponent,
    ColoredDateCellComponent,
    KanbanComponent,
    KanbanCardComponent,
    PopoverComponent,
    BooleanCellComponent,
    ButtonCellComponent,
    IconCellComponent,
    ComponentContainerDirective,
    BaseModalComponent,
    BadgeComponent,
    InputDurationComponent,
    EditTranslationsCellComponent,
    TranslationsModalComponent,
    TimeCellComponent,
    TagCellComponent,
    DayCellComponent,
    SelectDayComponent,
    FileManagerComponent,
    SchedulerComponent,
    ChartComponent
  ],
  exports: [
    LinkComponent,
    GridComponent,
    BreadcrumbsComponent,
    ButtonComponent,
    DrawerComponent,
    PageComponent,
    TitleComponent,
    SelectComponent,
    InputTextComponent,
    SwitchComponent,
    CardComponent,
    FlagComponent,
    CheckboxComponent,
    ColorPickerComponent,
    TextareaComponent,
    WysiwygComponent,
    ModalComponent,
    ConfirmModalComponent,
    TagboxComponent,
    UserMenuComponent,
    InputNumberComponent,
    InputDateComponent,
    TabsComponent,
    TabComponent,
    ToolbarComponent,
    GoogleMapComponent,
    GooglePlacesComponent,
    AutoformComponent,
    StockCardComponent,
    TreelistComponent,
    ArrowStepperItemComponent,
    ConfirmPasswordComponent,
    SchedulerComponent,
    ThirdModalComponent,
    CustomTagboxComponent,
    SyslinkCurrencyPipe,
    BadgeComponent,
    InputDurationComponent,
    ProgressBarComponent,
    TagComponent,
    BadgeListCellComponent,
    ColoredDateCellComponent,
    KanbanComponent,
    PopoverComponent,
    ComponentContainerDirective,
    BadgeComponent,
    InputDurationComponent,
    EditTranslationsCellComponent,
    TranslationsModalComponent,
    SelectDayComponent,
    FileManagerComponent,
    SchedulerComponent,
    ChartComponent
  ],
  providers: [
    NotificationsService,
    [
      { provide: DEFAULT_HTTP_REQUEST_TIMEOUT, useValue: 20000 },
      { provide: DEFAULT_NOTIFICATIONS_DISPLAYTIME, useValue: 5000 },
      { provide: DEFAULT_DATE_FORMAT, useValue: 'dd/MM/yyyy HH:mm:ss' },
      { provide: DEFAULT_CURRENCY_CODE, useValue: 'EUR' },
      { provide: DEFAULT_PAGE_SIZE, useValue: 20 },
      { provide: DEFAULT_PAGE_SIZES, useValue: [20] },
      { provide: DATE_PIPE_DEFAULT_OPTIONS, useValue: { dateFormat: 'dd/MM/yyyy HH:mm:ss' } }
    ],
    CurrencyPipe,
    DatePipe,
    ComponentContainerService
  ]
})
export class SyslinkComponentsModule {
  constructor(
    injector: Injector,
    @Inject('config') config: Config
  ) {
    AppInjectorService.injector = injector;
    AppInjectorService.config = config;
  }

  static forRoot(config: Partial<Config>): ModuleWithProviders<SyslinkComponentsModule> {
    return {
      ngModule: SyslinkComponentsModule,
      providers: [
        SyslinkComponentsModule, { provide: 'config', useValue: { ...new Config(), ...config } }
      ]
    }
  }
}
