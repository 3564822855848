import { DocumentHeaderStatus } from "../../../base/documents/document-header-statuses/document-header-status.model";
import { SaleDocumentStatus } from "../sale-document-statuses/sale-document-status.model";
import { SaleDocument } from "../sale-documents/sale-document.model";

export class SaleDocumentHeaderStatus extends DocumentHeaderStatus {
    public override HeaderId?: SaleDocument;
    public override StatusId?: SaleDocumentStatus;

    constructor(entity?: Partial<SaleDocumentHeaderStatus>) {
        super();
        Object.assign(this, entity);
    }
}
