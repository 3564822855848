<div class="row">
    <div class="col-sm-3">
        <syslink-input-text
            name="ExternalReference"
            label="External reference"
            [(model)]="element.ExternalReference"
            [disabled]="reportType!='SaleContract' && disabled"
        ></syslink-input-text>
    </div>

    <div *ngIf="reportType!='SaleOrder' && reportType!='SaleContract'" class="col-sm-3">        
        <syslink-select
            name="DocumentDelay"
            [label]="reportType + '.DocumentDelay' | translate"
            [store]="documentDelaysService.store"
            [(model)]="element.DocumentDelayId"
            [disabled]="reportType!='SaleContract' && disabled"
            (modelChange)="documentDelayChanged.emit($event)"
        ></syslink-select>
    </div>

    <div class="col-sm-3">
        <syslink-input-number
            name="GlobalDiscountValue"
            label="Global discount value(%)"
            [(model)]="element.GlobalDiscountValue"
            [disabled]="reportType!='SaleContract' && disabled"
            (focusOut)="globalDiscountChanged.emit($event)"
            [min]="0"
            [max]="100"
        ></syslink-input-number>
    </div>
    <div class="col-sm-3">
        <syslink-input-text
            [label]="'Number plate' | translate"
            [(model)]="element.NumberPlate"
        ></syslink-input-text>
    </div>
    <div
        class="col-sm-3"
        *ngIf="authService.hasPermission('sales-contracts') && reportType=='SaleInvoice'"
    >
        <syslink-select
            #contractSelect
            [label]="'Contrat'"
            [store]="saleContractsService.store"
            [(model)]="element.ContractId"
            [filter]="filterContractList"
            displayKey="NameFormatted"
            (modelChange)="onChangeContract()"
            [canRefresh]="authService.hasPermission('sales.contracts.list.view')"
            [canAdd]="authService.hasPermission('sales.contracts.list.add')"
            [canView]="authService.hasPermission('sales.contracts.list.view')"
            detailsUrl="/sales/contracts/"
            (onAdd)="onCreateContract.emit()"
            [disabled]="!authService.hasPermission('sales.contracts.list.view')"
        ></syslink-select>
    </div>
    <div
        class="col-sm-3"
        *ngIf="element.ContractId"
    >
        <syslink-input-date
            [name]="'Start date'| translate"
            [(model)]="element.DateFrom"
            type="date"
            format="date"
        ></syslink-input-date>
    </div>
    <div
        class="col-sm-3"
        *ngIf="element.ContractId"
    >
        <syslink-input-date
            [name]="'End date'| translate"
            [(model)]="element.DateTo"
            type="date"
            format="date"
        ></syslink-input-date>
    </div>
    <!-- <div class="col-sm-3">
        <syslink-select
            label="'Origin' "
            [store]="originsService.store"
            [(model)]="element.OriginId"
        ></syslink-select>
    </div> -->
</div>

<div class="row">
    <div class="col-12">
        <syslink-wysiwyg
            name="htmlEditor-Note"
            label="Note"
            [(model)]="element.Note"
            [table]="false"
            [multilineToolbar]="true"
            height="200"
            [disabled]="reportType!='SaleContract' && disabled"
        ></syslink-wysiwyg>
    </div>
</div>