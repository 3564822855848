<syslink-toolbar [actions]="toolbarActions"></syslink-toolbar>
<div class="row">
    <div class="col-2">
        <div
            class="text-primary text-center py-3"
            style="font-size: 4rem;"
        >
            <i class="{{modulesService.getIcon(element.Code)}}"></i>
        </div>
    </div>
    <div class="col-10 pt-2 pb-3">
        <h1>{{element.Name || element.Code}}</h1>
        <p>{{element.description}}</p>
    </div>
</div>
<div class="row">
    <syslink-tabs>
        <syslink-tab
            [label]="subModule.Name??'' "
            *ngFor="let subModule of element.SubModules"
            [visible]="authService.hasPermission(basePermissionKey+'.'+subModule.Code+'.view')"
        >
            <syslink-autoform
                *ngIf="authService.hasPermission(basePermissionKey+'.'+subModule.Code+'.view')"
                [fields]="subModule.Configurations"
                [getStore]="getAutoformStore"
                [getItems]="getAutoformItems"
            > </syslink-autoform>
            <div
                componentContainer
                *ngIf="authService.hasPermission(basePermissionKey+'.'+subModule.Code+'.view')"
                [key]="'ConfigurationComponentContainer_'+subModule.Code"
                [data]="subModule"
            >
            </div>
        </syslink-tab>
    </syslink-tabs>
</div>