import { Injectable } from '@angular/core';
import { LoadOptions } from 'devextreme/data';
import { ConfigurationsService } from '../../base/modules/configurations/configurations.service';
import { TaxRate } from '../../base/tax-rates/tax-rate';
import { ODataService } from '../../core/services/oData.service';
import { Supplier } from './supplier.model';
import { DeliveryTime } from '../../base/delivery-times/delivery-time.model';
import { DocumentDelay } from '../../base/documents/document-delays/document-delay';
import { ApiService } from '../../core/api.service';

@Injectable({
  providedIn: 'root'
})
export class SuppliersService extends ODataService<Supplier> {
  public url = 'supplier';
  public override defaultOptions: LoadOptions = {
    expand: [
      'ThirdId', 'PurchaseTaxRateId', 'DeliveryTimeId','PurchaseInvoiceDocumentDelayId', 'PurchaseCreditNoteDocumentDelayId'
    ]
  };
  constructor(
    private api: ApiService,
    private configurationsService: ConfigurationsService
  ) {
    super();
  }

  public override format(element: Supplier): any {
    var result: any = {
      ...element,
      PurchaseTaxRateId: element.PurchaseTaxRateId.Id,
      DeliveryTimeId: element.DeliveryTimeId?.Id,
      PurchaseInvoiceDocumentDelayId: element.PurchaseInvoiceDocumentDelayId?.Id,
      ThirdId: element.ThirdId?.Id,
    };

    return result;
  }

  public override async getInstance(params?: Partial<Supplier>): Promise<Supplier> {
    return new Supplier({
      PurchaseTaxRateId: new TaxRate({ Id: await this.configurationsService.getConfigurationAsNumber('purchaseTaxRate', 'Thirds.Suppliers', 'Thirds') }),
      //   DeliveryTimeId: new DeliveryTime({ Id: await this.configurationsService.getConfigurationAsNumber('defaultDeliveryTime', 'Thirds.Suppliers', 'Thirds') }),
      PurchaseInvoiceDocumentDelayId: new DeliveryTime({ Id: await this.configurationsService.getConfigurationAsNumber('PurchaseInvoiceValidity', 'Thirds.Suppliers', 'Thirds') }),
      PurchaseCreditNoteDocumentDelayId: new DeliveryTime({ Id: await this.configurationsService.getConfigurationAsNumber('PurchaseCreditNoteValidity', 'Thirds.Suppliers', 'Thirds') }),
      ...params
    });
  }
  public async getSupplierByThirdId(thirdId: number): Promise<Supplier> {
    let options = {
      ...this.defaultOptions,
      filter: "ThirdId.Id eq " + thirdId,
      expand: ["PurchaseTaxRateId"]
    };
    var result = await this.load(options);
    return result[0];
  }

  public async getSupplierDocumentDelayByThirdId(thirdId: number): Promise<DocumentDelay> {
    // let options = {
    //   ...this.defaultOptions,
    //   filter: "ThirdId.Id eq " + thirdId,
    //   expand: ["DocumentDelayId"],
    //   select: ["DocumentDelayId"]
    // };
    // var result = await this.load(options);
    // return result[0].DocumentDelayId ? result[0].DocumentDelayId : new DocumentDelay();
    return  new DocumentDelay();
  }
  public async getSupplierDeliveryTimeByThirdId(thirdId: number): Promise<DeliveryTime> {
    let options = {
      ...this.defaultOptions,
      filter: "ThirdId.Id eq " + thirdId,
      expand: ["DeliveryTimeId"],
      select: ["DeliveryTimeId"]
    };
    var result = await this.load(options);
    return result[0].DeliveryTimeId ? result[0].DeliveryTimeId : new DocumentDelay();
  }
  
  public async getStatistics(elementId: number, year: number) {
    return await this.api.sendRequest('/api/Supplier/GetStatistics/' + elementId + '/' + year, 'GET');
  }
}
