import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ClickEvent } from 'devextreme/ui/button';

@Component({
  selector: 'syslink-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent {
  // Content
  // -------
  @Input() public text?: string;
  @Input() public icon: string = '';
  @Input() public hint?: string;
  @Input() public link: string | null = null;
  @Input() public name: string = '';

  // Styles
  // ------
  @Input() public visible: boolean = true;
  @Input() public disabled: boolean = false;
  @Input() public type: 'back' | 'danger' | 'default' | 'normal' | 'success' = 'default';
  @Input() public color: string = '';
  @Input() public class: string = '';
  @Input() public mode: 'text' | 'outlined' | 'contained' = 'contained';
  @Input() public size: 'big' | 'default' = 'default';
  @Input() public width?: number | string;
  @Input() public height?: number | string;
  @Input() public activeStateEnabled: boolean = false;
  @Input() public focusStateEnabled: boolean = false;
  @Input() public hoverStateEnabled: boolean = true;

  // Event
  // -----
  @Output() public onClick: EventEmitter<ClickEvent> = new EventEmitter<ClickEvent>();

  ngOnInit(): void {
    switch (this.size) {
      case 'big':
        this.width = 120;
        this.height = 40;
        break;

      default:
        break;
    }
  }
}
