export class CreditsafeData {
    public Report?: ReportCreditSafe;
    public CompanyId?: string;
    public DateOfOrder?: Date;
    public Language?: string;
    public UserId?: string;
}
class IssuedShareCapitalCreditSafe {
    public Value?: number;
}

class EmployeesInformationsCreditSafe {
    public Year?: number;
    public NumberOfEmployees?: number;
}

class ProfitAndLossCreditSafe {
    public GrossOperatingMargin?: number;
    public OperatingCharges?: number;
    public EmployeeCosts?: number;
    public OtherEmployeeCosts?: number;
    public AmortizationDepreciation?: number;
    public OperatingResult?: number;
    public FinancialIncome?: number;
    public FinancialCharges?: number;
    public ResultsOnOrdOpBefTax?: number;
    public ResultsYearBeforeTax?: number;
    public Taxation?: number;
    public ResultsOnOrdOpAftTax?: number;
    public NetResult?: number;
    public ProfitYearAppropriated?: number;
}

class balanceSheetsCreditSafe {
    public TotalFixedAssets?: number;
    public TangibleFixedAssets?: number;
    public LandAndBuilding?: number;
    public PlantAndMachinery?: number;
    public FurnitureAndVehicles?: number;
    public LeasingAndOtherSimilarRights?: number;
    public OtherTangibleAssets?: number;
    public FinancialFixedAssets?: number;
    public TotalCurrentAssets?: number;
    public Inventories?: number;
    public FinaotherStocksncialFixedAssets?: number;
    public TradeDebtors?: number;
    public OtherAmountsReceivable?: number;
    public Cash?: number;
    public MiscellaneousCurrentAssets?: number;
    public TotalAssets?: number;
    public TotalShareholdersEquity?: number;
    public IssuedShareCapital?: number;
    public Reserves?: number;
    public Creditors?: number;
    public OtherLongTermLoans?: number;
    public OtherLongTermLiabilities?: number;
    public TotalLongTermDebts?: number;
    public CurrentPortionLongTermDebt?: number;
    public FinancialDebts?: number;
    public TradeCreditors?: number;
    public TaxesRemunAndSocialSecurity?: number;
    public MiscellaneousCurrentLiabilities?: number;
    public TotalCurrentLiabilities?: number;
    public TotalLiabilities?: number;
}

class FinancialStatementsCreditSafe {
    public Type?: string;
    public YearEndDate?: Date;
    public NumberOfWeeks?: number;
    public ProfitAndLoss?: ProfitAndLossCreditSafe;
    public BalanceSheets?: balanceSheetsCreditSafe;
    public OtherFinancials?: OtherFinancialsCreditSafe;
    public Ratios?: RatiosCreditSafe;
    public Currency?: string;
}

class ShareCapitalStructureCreditSafe {
    public IssuedShareCapital?: IssuedShareCapitalCreditSafe;
    public ShareCapitalCurrency?: string;
    public ShareHolders?: ThirdCreditSafe[];
}

class OtherInformationsCreditSafe {
    public EmployeesInformation?: EmployeesInformationsCreditSafe[];
}

class ReportCreditSafe {
    public CompanyId?: string;
    public Language?: string;
    public CompanySummary?: BasicInformationCreditSafe;
    public CompanyIdentification?: CompanyIdentificationCreditSafe;
    public CreditScore?: CreditScoreCreditSafe;
    public ContactInformation?: ContactInformationCreditSafe;
    public ShareCapitalStructure?: ShareCapitalStructureCreditSafe;
    public Directors?: DirectorsCreditSafe;
    public OtherInformation?: OtherInformationsCreditSafe;
    public FinancialStatements?: FinancialStatementsCreditSafe[];
    public LocalFinancialStatements?: FinancialStatementsCreditSafe[];
    public PaymentData?: PaymentDataCreditSafe;
    public AdditionalInformation?: AdditionalInformationsCreditSafe;
}

class EquityFigureCreditSafe {
    public Currency?: string;
    public Value?: number;
}

class ProviderValueCreditSafe {
    public MaxValue?: number;
    public MinValue?: number;
    public Value?: number;
}

class CreditRatingCreditSafe {
    public CommonValue?: string;
    public CommonDescription?: string;
    public CreditLimit?: EquityFigureCreditSafe;
    public ProviderValue?: ProviderValueCreditSafe;
    public ProviderDescription?: string;
    public Pod?: number;
}

class CompanyStatusCreditSafe {
    public Status?: string;
    public ProviderStatus?: string;
    public ProviderCode?: string;
    public Description?: string;
}

class BasicInformationCreditSafe {
    public BusinessName?: string;
    public RegisteredCompanyName?: string;
    public CompanyNumber?: string;
    public CompanyRegistrationNumber?: string;
    public GgsId?: string;
    public Country?: string;
    public VatRegistrationNumber?: string;
    public CompanyRegistrationDate?: string;
    public OperationsStartDate?: Date;
    public LegalForm?: LegalFormCreditSafe;
    public CompanyStatus?: CompanyStatusCreditSafe;
    public PrincipalActivity?: PrincipalActivityCreditSafe;
    public MainActivity?: PrincipalActivityCreditSafe;
    public ContactAddress?: AddressCreditSafe;
    public LatestShareholdersEquityFigure?: EquityFigureCreditSafe;
    public CreditRating?: CreditRatingCreditSafe;
}

class PrincipalActivityCreditSafe {
    public Code?: string;
    public Classification?: string;
    public Description?: string;
}

class DirectorsCreditSafe {
    public CurrentDirectors?: ThirdCreditSafe[];
}

class ContactInformationCreditSafe {
    public MainAddress?: AddressCreditSafe;
    public OtherAddresses?: AddressCreditSafe[];
    public WebSites?: string[];
}

class PaymentDataCreditSafe {
    public PaymentDataCurrency?: string;
    public PaymentsOnFile?: number;
    public PaymentsOnTime?: number;
    public PaymentsPaidLate?: number;
    public TotalInvoiceValues?: number;
    public AverageInvoiceValues?: number;
    public TotalBalanceStillOwing?: number;
    public HighestInvoiceValueOutstanding?: number;
    public NumberofPaymentsStillOwingLate?: number;
    public TotalBalanceStillOwingLate?: number;
    public HighestInvoiceValueOutstandingLate?: number;
    public NumberofPaymentsSentLegal?: number;
    public PaymentsStillOwing?: number;
}

class RatiosCreditSafe {
    public ReturnonCapitalEmployed?: number;
    public ReturnonTotalAssetsEmployed?: number;
    public ReturnonNetAssetsEmployed?: number;
    public CurrentRatio?: number;
    public LiquidityRatioAcidTest?: number;
    public CurrentDebtRatio?: number;
    public Gearing?: number;
    public EquityinPercentage?: number;
    public TotalDebtRatio?: number;
}

class OtherFinancialsCreditSafe {
    public Cashflow?: number;
    public NetWorth?: number;
    public WorkingCapital?: number;
}

class AddressCreditSafe {
    public SimpleValue?: string;
    public Street?: string;
    public HouseNumber?: string;
    public City?: string;
    public PostalCode?: string;
    public Telephone?: string;
    public Country?: string;
}

class PositionsCreditSafe {
    public DateAppointed?: Date;
    public PositionName?: string;
}

class LegalFormCreditSafe {
    public ProviderCode?: string;
    public Description?: string;
    public AdditionalData?: AdditionalDataCreditSafe;
}

class AdditionalDataCreditSafe {
    public FunctionType?: string;
}

class ThirdCreditSafe {
    public Id?: string;
    public Name?: string;
    public FirstNames?: string;
    public FirstName?: string;
    public MiddleName?: string;
    public SurName?: string;
    public DirectorType?: string;
    public Address?: AddressCreditSafe;
    public Gender?: string;
    public ShareholderType?: string;
    public PercentSharesHeld?: number;
    public Positions?: PositionsCreditSafe[];
    public StartDate?: Date;
}

class RatingHistoryCreditSafe {
    public Date?: Date;
    public CompanyValue?: number;
    public Pod?: number;
    public RatingDescription?: string;
}

class CreditLimitHistoryCreditSafe {
    public Date?: Date;
    public Value?: number;
    public LimitCurrency?: string;
}

class IndividualShareholdersCreditSafe {
    public StartDate?: Date;
    public Forename?: string;
    public Surname?: string;
    public MiddleName?: string;
    public StreetName?: string;
    public PostalTown?: string;
    public PostalCode?: string;
    public HouseNumber?: string;
    public Country?: string;
    public PercentageOwned?: number;
}

class BranchesCreditSafe {
    public StartDate?: Date;
    public Status?: string;
    public BranchUnitNumber?: string;
    public BranchUnitName?: string;
    public Address?: string;
}

class AdditionalInformationsCreditSafe {
    public Misc?: MiscCreditSafe;
    public IndustryQuartileAnalysis?: IndustryQuartileAnalysisCreditSafe;
    public IndustryComparison?: IndustryComparisonCreditSafe;
    public JointIndustrialCommittee?: JointIndustrialCommitteeCreditSafe[];
    public CompanySignificantEvents?: SignificantEventsCreditSafe[];
    public Commentaries?: CommentariesCreditSafe[];
    public SocialBalanceSheets?: SocialBalanceSheetsCreditSafe[];
    public RatingHistory?: RatingHistoryCreditSafe[];
    public CreditLimitHistory?: CreditLimitHistoryCreditSafe[];
    public IndividualShareholders?: IndividualShareholdersCreditSafe[];
    public Branches?: BranchesCreditSafe[];
}

class SocialBalanceSheetsCreditSafe {
    public ReportingYearEnd?: Date;
    public Currency?: string;
    public FullTimeEmployees?: number;
    public TotalFteEmployees?: number;
    public HoursWorkedFTEmployees?: number;
    public HoursWorkedTotal?: number;
    public PersonnelChargesFTEmployees?: number;
    public PersonnelChargesTotal?: number;
    public UnlimitedDurationContractsFT?: number;
    public LimitedDurationContractsFT?: number;
    public UnlimitedDurationContractsTotalFte?: number;
    public LimitedDurationContractsTotalFte?: number;
    public MenSecondaryEducationFT?: number;
    public MenSecondaryEducationTotalFte?: number;
    public WomenSecondaryEducationFT?: number;
    public WomenSecondaryEducationTotalFte?: number;
    public WhiteCollarWorkerFT?: number;
    public BlueCollarWorkerFT?: number;
    public WhiteCollarWorkerTotalFte?: number;
    public BlueCollarWorkerTotalFte?: number;
    public NewStartersFT?: number;
    public LeaversFT?: number;
    public NewStartersTotalFte?: number;
    public LeaversTotalFte?: number;
}

class CommentariesCreditSafe {
    public Type?: string;
    public CommentaryText?: string;
    public PositiveOrNegative?: string;
}

class SignificantEventsCreditSafe {
    public EventDate?: Date;
    public EventDescription?: string;
    public EventDetails?: string;
}

class JointIndustrialCommitteeCreditSafe {
    public JicCodec?: string;
    public Description?: string;
}

class IndustryComparisonCreditSafe {
    public ActivityCode?: string;
    public ActivityDescription?: string;
    public IndustryAverageDaySalesOutstanding?: number;
    public IndustryAveragePaymentExpectationDays?: number;
    public IndustryAverageCreditRating?: number;
    public IndustryAverageCreditLimit?: number;
    public IndustryAverageCreditLimitCurrency?: string;
}

class PaymentExpectationDaysCreditSafe {
    public PaymentExpectationsLower?: number;
    public PaymentExpectationsMedian?: number;
    public PaymentExpectationsUpper?: number;
}

class DaySalesOutstandingCreditSafe {
    public DaySalesOutstandingLower?: number;
    public DaySalesOutstandingMedian?: number;
    public DaySalesOutstandingUpper?: number;
}

class IndustryQuartileAnalysisCreditSafe {
    public PaymentExpectationDays?: PaymentExpectationDaysCreditSafe;
    public DaySalesOutstanding?: DaySalesOutstandingCreditSafe;
}

class MiscCreditSafe {
    public LiableForVat?: boolean;
    public CourtDataFlag?: number;
    public SummonsFlag?: number;
    public ProtestedBillsFlag?: number;
    public BranchUnitNumber?: string;
    public NssoNumber?: string;
    public EmployeeClass?: string;
}

class CreditScoreCreditSafe {
    public CurrentCreditRating?: CreditRatingCreditSafe;
    public PreviousCreditRating?: CreditRatingCreditSafe;
    public LatestRatingChangeDate?: Date;
}

class CompanyIdentificationCreditSafe {
    public BasicInformation?: BasicInformationCreditSafe;
    public ActivityClassifications?: ActivityClassificationsCreditSafe[];
}
class ActivityClassificationsCreditSafe {
    public Classification?: string;
    public Activities?: PrincipalActivityCreditSafe[];
}
