import { Injectable } from '@angular/core';
import { ODataService } from '../../core/services/oData.service';
import { DeliveryTime } from './delivery-time.model';
import { LoadOptions } from 'devextreme/data';

@Injectable({
  providedIn: 'root'
})
export class DeliveryTimesService extends ODataService<DeliveryTime> {
  public override url: string = 'DeliveryTime';
  
  public override defaultOptions: LoadOptions = {
    expand: [
      'NameTranslationId',
    ]
  };
}
