<syslink-title text="Principal informations"></syslink-title>
<div class="row">
  <!-- Current -->
  <div class="col-6 border-end border-dark">
    <div class="viesGrid">
      <syslink-checkbox
        [(model)]="usedCurrentDenomination"
        (modelChange)="
          usedCurrentDenominationChange.emit(usedCurrentDenomination)
        "
      ></syslink-checkbox>
      <syslink-input-text
        class="h-100"
        label="Denomination"
        [(model)]="currentElement.Name"
        [disabled]="true"
      ></syslink-input-text>
    </div>
    <div class="viesGrid">
      <syslink-checkbox
        [(model)]="usedCurrentLegalForm"
        (modelChange)="usedCurrentLegalFormChange.emit(usedCurrentLegalForm)"
      ></syslink-checkbox>
      <syslink-input-text
        class="h-100"
        label="Legal form"
        [(model)]="currentElement.LegalFormId.Name"
        [disabled]="true"
      ></syslink-input-text>
    </div>
    <div class="viesGrid">
      <syslink-checkbox
        [(model)]="usedCurrentCountry"
        (modelChange)="usedCurrentCountryChange.emit(usedCurrentCountry)"
      ></syslink-checkbox>
      <syslink-input-text
        class="h-100"
        label="Country"
        [(model)]="currentElement.CountryId.Name"
        [disabled]="true"
      ></syslink-input-text>
    </div>
  </div>
  <!-- New -->
  <div class="col-6">
    <div class="viesGrid">
      <syslink-checkbox
        [model]="!usedCurrentDenomination"
        (modelChange)="
          usedCurrentDenomination = !$event;
          usedCurrentDenominationChange.emit(usedCurrentDenomination)
        "
      ></syslink-checkbox>
      <syslink-input-text
        class="h-100"
        label="Denomination"
        [(model)]="newElement.Name"
        [disabled]="true"
      ></syslink-input-text>
    </div>
    <div class="viesGrid">
      <syslink-checkbox
        [model]="!usedCurrentLegalForm"
        (modelChange)="
          usedCurrentLegalForm = !$event;
          usedCurrentLegalFormChange.emit(usedCurrentLegalForm)
        "
      ></syslink-checkbox>
      <syslink-input-text
        class="h-100"
        label="Legal form"
        [(model)]="newElement.LegalFormId.Name"
        [disabled]="true"
      ></syslink-input-text>
    </div>
    <div class="viesGrid">
      <syslink-checkbox
        [model]="!usedCurrentCountry"
        (modelChange)="
          usedCurrentCountry = !$event;
          usedCurrentCountryChange.emit(usedCurrentCountry)
        "
      ></syslink-checkbox>
      <syslink-input-text
        class="h-100"
        label="Country"
        [(model)]="newElement.CountryId.Name"
        [disabled]="true"
      ></syslink-input-text>
    </div>
  </div>
</div>

<syslink-title text="Contact informations"></syslink-title>
<div class="row">
  <!-- Current -->
  <div class="col-6 border-end border-dark">
    <app-third-contacts
      [elements]="currentElement.ContactInformations"
      [canAdd]="false"
      [canDelete]="false"
      [canUpdate]="false"
      [canCheck]="true"
    ></app-third-contacts>
  </div>
  <!-- New -->
  <div class="col-6">
    <app-third-contacts
      [elements]="newElement.ContactInformations"
      [canAdd]="false"
      [canDelete]="false"
      [canUpdate]="false"
      [canCheck]="true"
    ></app-third-contacts>
  </div>
</div>
