import { Injectable } from '@angular/core';
import { LoadOptions } from 'devextreme/data';
import { PurchaseDocumentHeaderStatusesService } from '../../purchase-documents/purchase-document-header-status/purchase-document-header-statuses.service';
import { PurchaseCreditNoteHeaderHeaderStatus } from './purchase-credit-note-header-header-status.model';

@Injectable({
  providedIn: 'root'
})
export class PurchaseCreditNoteHeaderHeaderStatusesService extends PurchaseDocumentHeaderStatusesService<PurchaseCreditNoteHeaderHeaderStatus> {
  public override url: string = 'PurchaseCreditNoteHeaderHeaderStatus';
  public override defaultOptions: LoadOptions = {
    expand: ["StatusId"]
  };

}
