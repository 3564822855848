<syslink-modal
  width="33vw"
  #modal
  title="Edit multiple task"
  (onValidate)="onValidate($event)"
  (onCancel)="close()"
>
  <div class="row">
    <div class="col-6">
      <syslink-select
        label="Third"
        displayKey="Fullname"
        [store]="thirdsService.store"
        [(model)]="modal.data.ThirdId"
        [canAdd]="true"
        detailsUrl="/thirds/customers/"
        [canRefresh]="true"
      ></syslink-select>
    </div>
  </div>
  <syslink-select
    label="Status"
    [store]="taskStatusesService.store"
    [(model)]="modal.data.Status"
  ></syslink-select>
  <div class="row">
    <div class="col-6">
      <syslink-input-date
        label="Start date"
        format="date"
        type="date"
        [(model)]="modal.data.StartDate"
      ></syslink-input-date>
    </div>
    <div class="col-6">
      <syslink-input-date
        label="Deadline"
        format="date"
        type="date"
        [(model)]="modal.data.EndDate"
      ></syslink-input-date>
    </div>
  </div>
</syslink-modal>
