import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { InputComponent } from '../input/input.component';
import DataSource from 'devextreme/data/data_source';
import ODataStore from 'devextreme/data/odata/store';
import { SelectComponent } from '../select/select.component';

@Component({
  selector: 'syslink-custom-tagbox',
  templateUrl: './custom-tagbox.component.html',
  styleUrls: ['./custom-tagbox.component.scss']
})
export class CustomTagboxComponent extends InputComponent {

  // Select
  // ------
  @Input() canRefresh: boolean = false;
  @Input() canAdd: boolean = false;
  @Input() canView: boolean = false;
  @Input() public allowAddCustomItem: boolean = true;

  // Remote items
  // ------------
  public dataSource: DataSource = new DataSource({});
  @Input() public store?: ODataStore;
  @Input() public expand?: string | string[];
  @Input() public select?: string | string[];
  @Input() public filter?: string | string[];

  @Input() public displayKey: string = 'Name';

  @Output() onCustomeValue: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('syslink_select') public syslink_select: SelectComponent = new SelectComponent();

  public onChanged(e: any) {
    if (!e.Id) return;

    this.model.push(e);
    this.modelChange.emit(this.model);
    this.syslink_select.selectComponent?.instance.reset();
  }

  public onCustomItem(e: any) {
    this.onCustomeValue.emit(e);
  }

  public onRemoveElement(e: any) {
    this.model = this.model.filter((i: any) => i.Id != e.Id);
    this.modelChange.emit(this.model);
  }
}
