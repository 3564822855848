import { Component } from '@angular/core';
import { AppInjectorService } from 'projects/libraries/syslink-components/src/lib/services/app-injector.service';
import { SaleQuoteHeaderHeaderStatusesService } from '../sale-quote-header-header-statuses/sale-quote-header-header-statuses.service';
import { SaleQuoteHeaderHeaderStatus } from '../sale-quote-header-header-statuses/sale-quote-header-header-status.model';
import { SaleQuoteHeaderStatusesService } from '../sale-quote-header-statuses/sale-quote-header-statuses.service';
import { SaleQuoteHeaderStatus } from '../sale-quote-header-statuses/sale-quote-header-status.model';
import { SaleQuoteLinesService } from '../sale-quote-lines/sale-quote-lines.service';
import { SaleQuoteLine } from '../sale-quote-lines/sale-quote-line.model';
import { SaleQuotesService } from '../sale-quotes/sale-quotes.service';
import { SaleQuote } from '../sale-quotes/sale-quote.model';
import { SaleDocumentDetailsComponent } from '../../sale-documents/sale-documents/sale-document-details/sale-document-details.component';

@Component({
  selector: 'app-sale-quote-details',
  templateUrl: '../../sale-documents/sale-documents/sale-document-details/sale-document-details.component.html',
  styleUrls: ['./sale-quote-details.component.scss']
})
export class SaleQuoteDetailsComponent extends SaleDocumentDetailsComponent<
  SaleQuote,
  SaleQuotesService,
  SaleQuoteLine,
  SaleQuoteLinesService,
  SaleQuoteHeaderStatus,
  SaleQuoteHeaderStatusesService,
  SaleQuoteHeaderHeaderStatus,
  SaleQuoteHeaderHeaderStatusesService
>{

  protected override loadDependencies() {
    this.saleDocumentService = AppInjectorService.injector.get(SaleQuotesService);
    this.saleDocumentLinesService = AppInjectorService.injector.get(SaleQuoteLinesService);
    this.saleDocumentStatusesService = AppInjectorService.injector.get(SaleQuoteHeaderStatusesService);
    this.saleDocumentHeaderStatusesService = AppInjectorService.injector.get(SaleQuoteHeaderHeaderStatusesService);

    this.reportType = "SaleQuote";
    this.subModuleCode = "Sales.Quote";
    this.defaultStatusCode = "Quote.Draft";
    this.documentLinesColumns = [...this.saleDocumentService.getDefaultDocumentLineColumns(this.subModuleCode,this.reportType)];
  }

  public override canEditDocument(): boolean {
    return !this.hasActiveStatus("Quote.Confirmed");
  }

}
