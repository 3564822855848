import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import FileSystemItem from 'devextreme/file_management/file_system_item';
import RemoteFileSystemProvider from 'devextreme/file_management/remote_provider';
import { AppInjectorService } from '../services/app-injector.service';
import { lastValueFrom } from 'rxjs';
import { ErrorOccurredEvent, dxFileManagerOptions } from 'devextreme/ui/file_manager';
import { DxFileManagerComponent } from 'devextreme-angular';
import { NotificationsService } from '../notifications/notifications.service';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'projects/erp-app/src/app/core/api.service';

export class SyslinkFileSystemItem extends FileSystemItem {
  public rootName?: string;
}

@Component({
  selector: 'syslink-file-manager',
  templateUrl: './file-manager.component.html',
  styleUrls: ['./file-manager.component.scss']
})
export class FileManagerComponent implements OnInit, AfterViewInit {
  @Input() public endpointUrl?: string;

  private _entityType?: string;
  @Input() public set entityType(value: string | undefined) {
    this._entityType = value;
    this.initFileSystemProvider();
  }
  public get entityType(): undefined | string { return this._entityType };

  private _entityId?: number | string;
  @Input() public set entityId(value: number | undefined | string) {
    this._entityId = value;
    this.initFileSystemProvider();
  }
  public get entityId(): undefined | number | string { return this._entityId };

  private baseUrl: string = `${AppInjectorService.config.baseUrl}/api/FileSystem`;

  private get url(): string | undefined {
    if (this.entityType === 'Header') return undefined; // TODO: Understand why the component from toolbat get the "entityType" parameter at page first load
    if (this.entityType && this.entityId) {
      return `${this.baseUrl}/${this.entityType}/${this.entityId}`
    }
    return this.endpointUrl;
  }

  public rootFolderName: string = '';

  @Input() public options: dxFileManagerOptions = {};

  @Input() public fileSystemProvider?: RemoteFileSystemProvider;
  @Input() public selectedItemKeys: Array<string> = [];
  @Output() public selectedItemKeysChange: EventEmitter<Array<string>> = new EventEmitter<Array<string>>();

  @ViewChild('fileManager') private fileManager?: DxFileManagerComponent;

  constructor(
    private http: HttpClient,
    private translateService: TranslateService,
    private apiService: ApiService
  ) { }

  ngOnInit(): void {
    this.initFileSystemProvider();
  }

  ngAfterViewInit(): void {
    this.initOptions();
  }

  // Provider
  // --------
  private initFileSystemProvider(): void {
    if (!this.url) {
      this.fileSystemProvider = undefined;
      return;
    }

    this.setRootFolderName();

    this.fileSystemProvider = new RemoteFileSystemProvider({
      endpointUrl: this.url,
      requestHeaders: {
        'authorization': `Bearer ${AppInjectorService.config.getToken()}`
      }
    });

    this.fileSystemProvider.downloadItems = async (items: SyslinkFileSystemItem[]) => {
      const formData = new FormData();
      const pathInfoList: any[] = [];

      for (const item of items) {
        pathInfoList.push([...(<any>item).pathInfo, { key: item.key, name: item.name }]);
      }

      formData.append('command', 'Download');
      formData.append('arguments', JSON.stringify({ pathInfoList: pathInfoList }));

      const headers = new HttpHeaders({
        'authorization': `Bearer ${AppInjectorService.config.getToken()}`
      });

      const blob = await lastValueFrom<Blob>(this.http.post(this.baseUrl, formData, { headers, responseType: 'blob' }));

      if (await this.isDownloadErrorFile(blob)) {
        NotificationsService.sendErrorMessage('One or more file you are trying to download no longer exists');
      } else {
        this.downloadFile(blob, pathInfoList.length > 1 ? this.translateService.instant('download') + '.zip' : items[0].name);
      }
    };
  }

  // Options
  // -------
  private initOptions(): void {
    this.fileManager?.instance.option(this.options);
  }

  // Download
  // --------
  private downloadFile(blob: Blob, filename: string = ''): void {
    let url = window.URL.createObjectURL(blob);

    let link = document.createElement('a');
    link.setAttribute('style', 'display: none');
    link.href = url;
    link.download = filename;

    document.body.appendChild(link);
    link.click();
    link.remove();

    window.URL.revokeObjectURL(url);
  }

  // Download Error Check
  // --------------------
  private async isDownloadErrorFile(blob: Blob): Promise<boolean> {
    const blobContent = await blob.text();
    let JSONcontent;

    try {
      JSONcontent = JSON.parse(blobContent);
    } catch (error) {
      return false;
    }

    return JSONcontent.hasOwnProperty('success') && JSONcontent.success === false;
  }

  // Error
  // -----
  public onErrorOccurred(error: ErrorOccurredEvent) {
    if (error.errorText) {
      error.errorText = this.translateService.instant(error.errorText);
    }
  }

  // Root Folder Name
  // ----------------
  private async setRootFolderName() {
    try {
      const rootDirectory: SyslinkFileSystemItem = await this.apiService.sendRequest(`/api/FileSystem/${this.entityType}/${this.entityId}?command=GetDirContents&arguments={"pathInfo":[]}`, 'GET');
      if (!rootDirectory.rootName) rootDirectory.rootName = this.translateService.instant('Files');
      if (!rootDirectory.rootName) return;
      this.rootFolderName = rootDirectory.rootName;
    } catch (_error) { }
  }
}
