import { Injectable } from '@angular/core';
import { LoadOptions } from 'devextreme/data';
import { LanguagesService } from '../../base/languages/languages.service';
import { TranslatableString } from '../../base/translations/translatable-strings/translatable-string';
import { TranslatableStringsService } from '../../base/translations/translatable-strings/translatable-strings.service';
import { Translation } from '../../base/translations/translations/translation';
import { TranslationsService } from '../../base/translations/translations/translations.service';
import { ODataService } from '../../core/services/oData.service';
import { AccountType } from '../account-types/account-type.model';
import { Account } from './account.model';

@Injectable({
  providedIn: 'root'
})
export class AccountsService extends ODataService<Account> {
  public url = 'Account';
  public override defaultOptions: LoadOptions = {
    expand: [],
    select: ['AccountTypeId.Id', 'AccountTypeId.Name', 'ParentAccountId.Id']
  };
  constructor(
    public translationsService: TranslationsService,
    public languagesService: LanguagesService,
    public translatableStringsService: TranslatableStringsService
  ) {
    super();
  }
  public getDefaultOptions() {
    return this.defaultOptions;
  }

  public override async format(params: Partial<Account>): Promise<Account> {
    var result: any = {
      ...params,
      AccountTypeId: params?.AccountTypeId?.Id,
      ParentId: params?.ParentId?.Id,
      ParentAccountId: params?.ParentId?.Id
    };
    // if (result.NameTranslationId == null) {
    //   const translatableString: TranslatableString = await this.translatableStringsService.insert({
    //     Code: result.Code
    //   });
    //   for (const language of this.languagesService.languages) {
    //     await this.translationsService.insert({
    //       TranslatableStringId: translatableString,
    //       LanguageId: language,
    //       Value: language.IsoCode === 'fr' ? result.Name : ''
    //     });
    //   }
    //   result.NameTranslationId = translatableString;
    // }
    // else {
    //   // todo add update name
    //   result.NameTranslationId.Translations[0].Value="test";
    //   if (result.NameTranslationId?.TranslatedValue) {
    //     result.NameTranslationId.Translations =result.NameTranslationId.Translations.map((translation: Translation) => {
    //       return {
    //         ...translation,
    //         Value: translation.LanguageId.IsoCode === 'fr' ? result.Name : result.Name
    //       }
    //     });
    //     //result = event.oldData;
    //   } 
    // }
    return result;
  }
  public override async getInstance(params?: Partial<Account>): Promise<Account> {
    var account = new Account({
      ...params,
      AccountTypeId: new AccountType(params?.AccountTypeId)
    });
    return account;
  }
}
