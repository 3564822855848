import { Component, EventEmitter, Input, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { ConfirmModalComponent } from '../../../public-api';
import { AfterAddEvent } from './events/AfterAddEvent';
import { AfterDeleteEvent } from './events/AfterDeleteEvent';
import { AfterUpdateEvent } from './events/AfterUpdateEvent';
import { BeforeAddEvent } from './events/BeforeAddEvent';
import { BeforeDeleteEvent } from './events/BeforeDeleteEvent';
import { BeforeUpdateEvent } from './events/BeforeUpdateEvent';
import { FilterDescriptor, SelectDescriptor } from 'devextreme/data';
import { ViewComponent } from '../view/view.component';

/**
 * Le problème de ce composant est le fait de l'utiliser pour les grilles ET les listes
 * Les typage d'event ne sont pas les mêmes
 * Et ça nous amème à devoir les typer en "any" ici
 * 
 * Ex :
 *    import { InitNewRowEvent } from 'devextreme/ui/data_grid';
 *    import { InitNewRowEvent } from 'devextreme/ui/tree_list';
 */

@Component({
  selector: 'syslink-crud-component',
  templateUrl: './crud-component.component.html',
  styleUrls: ['./crud-component.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CrudComponent extends ViewComponent {
  // Prepare row
  // -----------
  @Output() onPrepareRow: EventEmitter<any> = new EventEmitter<any>();

  // Create
  // ------
  @Input() public canAdd: boolean = true;
  @Output() beforeAdd: EventEmitter<BeforeAddEvent> = new EventEmitter<BeforeAddEvent>();
  @Output() add: EventEmitter<any> = new EventEmitter<any>();
  @Output() afterAdd: EventEmitter<AfterAddEvent> = new EventEmitter<AfterAddEvent>();

  // Update
  // ------
  @Input() public canUpdate: boolean = true;
  @Input() update?: Function;
  @Output() beforeUpdate: EventEmitter<BeforeUpdateEvent> = new EventEmitter<BeforeUpdateEvent>();
  @Output() afterUpdate: EventEmitter<AfterUpdateEvent> = new EventEmitter<AfterUpdateEvent>();

  // Delete
  // ------
  @Input() public canDelete: boolean = true;
  // @Input() delete?: Function;
  @Output() beforeDelete: EventEmitter<BeforeDeleteEvent> = new EventEmitter<BeforeDeleteEvent>();
  @Output() delete: EventEmitter<any> = new EventEmitter<any>();
  @Output() afterDelete: EventEmitter<AfterDeleteEvent> = new EventEmitter<AfterDeleteEvent>();


  //TODO:List???
  @Input() public expand?: string | string[];
  @Input() public select?: SelectDescriptor<any>;
  @Input() public filter?: FilterDescriptor | Array<FilterDescriptor>;

  @ViewChild('deleteConfirm') public deleteConfirm?: ConfirmModalComponent;

  @Input() storageKey?: string;

  @Input() public canRefresh: boolean = false;
  @Input() public canFilter: boolean = true;
  @Input() public canView: boolean = true;
  @Input() public canSearch: boolean = true;
  @Input() public canSort: boolean = true;
  @Input() public canExport: boolean = true;
  @Input() public canDblClck: boolean = true;
}


