import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LoadOptions } from 'devextreme/data';
import { Account } from '../../accountings/accounts/account.model';
import { TaxType } from '../../accountings/tax-types/tax-type.model';
import { Currency } from '../../base/currencies/currency.model';
import { ConfigurationsService } from '../../base/modules/configurations/configurations.service';
import { TaxRate } from '../../base/tax-rates/tax-rate';
import { Unit } from '../../base/units/unit.model';
import { ODataService } from '../../core/services/oData.service';
import { ProductCategory } from '../product-categories/product-category.model';
import { Product } from './product.model';
import { BehaviorSubject } from 'rxjs';
import { StockLocation } from '../../stocks/stock-location/stock-location.model';
import { environment } from 'projects/erp-app/src/environments/environment';
import { ProductStatisticsDTO } from './product-statistics/product-statistics-dto';

@Injectable({
  providedIn: 'root'
})
export class ProductsService extends ODataService<Product> {
  public url = 'Product';
  public override defaultOptions: LoadOptions = {
    expand: [
      'BrandId',
      'AllCustomerCatalogs',
      'ProductCategoryId.SaleAccountId',
      'ProductCategoryId.PurchaseAccountId',
      'DefaultUnitId',
      'SaleTaxRateId',
      'PurchaseTaxRateId',
      'CurrencyId',
      'FilteredCustomerCatalogs.MarginId',
      'FilteredCustomerCatalogs.DiscountId',
      'FilteredCustomerCatalogs.PenaltyId',
      'FilteredCustomerCatalogs.TaxRateId',
      'FilteredCustomerCatalogs.ProductId',
      'FilteredCustomerCatalogs.ProductCategoryId',
      'FilteredCustomerCatalogs.PriceListId',
      'FilteredCustomerCatalogs.CustomerId.ThirdId',
      'SupplierCatalogs.SupplierId.ThirdId',
      'SupplierCatalogs.CurrencyId',
      'SupplierCatalogs.UnitId',
      'SupplierCatalogs.TaxRateId',
      'SupplierCatalogs.BrandId',
      'SupplierCatalogs.DiscountId',
      'SupplierCatalogs.ProductId',
      'DefaultSupplierCatalogId.UnitId',
      'DefaultSupplierCatalogId.CurrencyId',
      'SaleTaxTypeId',
      'PurchaseTaxTypeId',
      'SaleAccountId',
      'PurchaseAccountId',
      'DefaultStockLocationId'
    ]
  };


  constructor(
    private configurationsService: ConfigurationsService,
    private translateService: TranslateService
  ) {
    super();
  }

  public override format(element: Partial<Product>): Product {
    var result: any = {
      ...element,
      BrandId: element.BrandId?.Id,
      CurrencyId: element.CurrencyId?.Id,
      DefaultSupplierCatalogId: element.DefaultSupplierCatalogId?.Id,
      DefaultUnitId: element.DefaultUnitId?.Id,
      ProductCategoryId: element.ProductCategoryId?.Id,
      PurchaseAccountId: element.PurchaseAccountId?.Id,
      PurchaseTaxRateId: element.PurchaseTaxRateId?.Id,
      PurchaseTaxTypeId: element.PurchaseTaxTypeId?.Id,
      SaleAccountId: element.SaleAccountId?.Id,
      SaleTaxRateId: element.SaleTaxRateId?.Id,
      SaleTaxTypeId: element.SaleTaxTypeId?.Id,
      DefaultStockLocationId: element.DefaultStockLocationId?.Id
    };

    delete result.AllCustomerCatalogs;
    delete result.DefaultCustomerCatalogs;
    delete result.FilteredCustomerCatalogs;
    delete result.ProductCategoryCustomerCatalogs;
    delete result.StockMoveHistorical;
    delete result.PurchasePriceStatus;
    delete result.SupplierCatalogs;
    delete result.IsFavorite;
    delete result.SalePriceInTax;
    delete result.PurchasePriceInTax;
    return result;
  }

  public override async getInstance(params?: Partial<Product>): Promise<Product> {
    var product = new Product({
      Name: this.translateService.instant('New product'),
      Purchasable: await this.configurationsService.getConfigurationAsBoolean('purchasable', 'Products.General', 'Products'),
      Sellable: await this.configurationsService.getConfigurationAsBoolean('sellable', 'Products.General', 'Products'),
      Stockable: await this.configurationsService.getConfigurationAsBoolean('stockable', 'Products.General', 'Products'),
      Blocked: await this.configurationsService.getConfigurationAsBoolean('blocked', 'Products.General', 'Products'),
      DefaultUnitId: new Unit({ Id: await this.configurationsService.getConfigurationAsNumber('unit', 'Products.General', 'Products') }),
      CurrencyId: new Currency({ Id: await this.configurationsService.getConfigurationAsNumber('currency', 'Products.General', 'Products') }),
      PurchaseTaxRateId: new TaxRate({ Id: await this.configurationsService.getConfigurationAsNumber('purchaseTaxRate', 'Products.General', 'Products') }),
      SaleTaxRateId: new TaxRate({ Id: await this.configurationsService.getConfigurationAsNumber('saleTaxRate', 'Products.General', 'Products') }),
      ProductCategoryId: new ProductCategory({ Id: await this.configurationsService.getConfigurationAsNumber('category', 'Products.General', 'Products') }),
      SaleTaxTypeId: new TaxType({ Id: await this.configurationsService.getConfigurationAsNumber('saleTaxType', 'Accountings.Products', 'Accountings') }),
      PurchaseTaxTypeId: new TaxType({ Id: await this.configurationsService.getConfigurationAsNumber('purchaseTaxType', 'Accountings.Products', 'Accountings') }),
      DefaultStockLocationId: new StockLocation({ Id: await this.configurationsService.getConfigurationAsNumber('defaultStockLocation', 'Products.General', 'Products') }),
      SaleAccountId: params?.ProductCategoryId != null && params?.ProductCategoryId.SaleAccountId != null ? params?.ProductCategoryId.SaleAccountId : new Account({ Id: await this.configurationsService.getConfigurationAsNumber('saleAccount', 'Accountings.Products', 'Accountings') }),
      PurchaseAccountId: params?.ProductCategoryId != null && params?.ProductCategoryId.PurchaseAccountId != null ? params?.ProductCategoryId.PurchaseAccountId : new Account({ Id: await this.configurationsService.getConfigurationAsNumber('purchaseAccount', 'Accountings.Products', 'Accountings') }),
      Stock: 0,
      ForcedSalePrice: 0,
    });

    return product;
  }

  public async getStockAudits(productId: number) {
    return this.apiService.sendRequest('/api/product/' + productId + '/audits', 'POST', {
      "Properties": ["Stock"]
    });
  }

  public setSupplierCatalogIsFavorite(productId: number, supplierId: number) {
    return this.apiService.sendRequest('/api/product/' + productId + '/setFavoriteSupplier/' + supplierId, 'POST');
  }

  // Product status observer
  private _statusSwitchSubscription = new BehaviorSubject<ProductDetailStatusTab>('Purchasable');
  public statusSwitchSubscription$ = this._statusSwitchSubscription.asObservable();
  public onStatusSwitch(status: ProductDetailStatusTab): void {
    this._statusSwitchSubscription.next(status);
  }

  public getStatisticsForProduct(productId : number,startYear? : number, endYear? : number) : Promise<ProductStatisticsDTO[]>{
    let url = `/api/product/${productId}/Statistics?`;
    let queryParam = [
      {name:"startYear",value:startYear},
      {name:"endYear",value:endYear}
    ];
    let queryParamString = queryParam.filter(param => param.value).map(param=>`${param.name}=${param.value}`).join('&');

    url += queryParamString;
    return this.apiService.sendRequest(url,"GET");
  }
}

export type ProductDetailStatusTab = 'Purchasable' | 'Sellable' | 'Stockable' | 'Other';
