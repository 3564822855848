import { Account } from "../../accountings/accounts/account.model";
import { TaxType } from "../../accountings/tax-types/tax-type.model";
import { Currency } from "../../base/currencies/currency.model";
import { TaxRate } from "../../base/tax-rates/tax-rate";
import { Unit } from "../../base/units/unit.model";
import { Entity } from "../../core/models/entity";
import { StockLocation } from "../../stocks/stock-location/stock-location.model";
import { Brand } from "../brands/brand.model";
import { CustomerCatalog } from "../customer-catalogs/customer-catalog.model";
import { ProductCategory } from "../product-categories/product-category.model";
import { SupplierCatalog } from "../supplier-catalogs/supplier-catalog.model";

export class Product extends Entity {
    public No?: string;
    public Name?: string;
    public InternalReference?: string;
    public ManufacturerReference?: string;
    public Purchasable: boolean = false;
    public Sellable: boolean = false;
    public Labor?: number;
    public Description?: string;
    public Stock: number = 0;
    public ForcedSalePrice?: number;
    public ProductCategoryId: ProductCategory = new ProductCategory();
    public BrandId?: Brand;
    public Make?: string;
    public SaleTaxRateId: TaxRate = new TaxRate();
    public DefaultUnitId: Unit = new Unit();
    public CurrencyId: Currency = new Currency();
    public UseCustomerCatalog?: boolean;
    public Blocked: boolean = false;
    public Stockable: boolean = false;
    public PurchaseTaxRateId: TaxRate = new TaxRate();
    public InternalNote?: string;

    public SalePrice?: number;
    public PurchasePrice?: number;
    public SalePriceInTax?: number;
    public PurchasePriceInTax?: number;

    public DefaultSupplierCatalogId?: SupplierCatalog;
    public PurchasePriceStatus?: string;
    public SupplierCatalogs: SupplierCatalog[] = [];
    public DefaultCustomerCatalogs: CustomerCatalog[] = [];
    public CustomerCatalogs: CustomerCatalog[] = [];
    public ProductCategoryCustomerCatalogs: CustomerCatalog[] = [];
    public AllCustomerCatalogs: CustomerCatalog[] = [];
    public FilteredCustomerCatalogs: CustomerCatalog[] = [];
    public DefaultStockLocationId?: StockLocation;

    // Stock
    // -----
    public StockMoveHistorical?: any;
    //public thirdUpdatedId?:number;
    public ReservedStock?: number;
    public AvailableStock?: number;
    public DeliveryStockQty: number = 0;
    public ReceiptStockQty: number = 0;
    public StockMove: number = 0;
    public StockLocationProducts: any[] = [];

    // Accounting
    // ----------
    public SaleTaxTypeId: TaxType = new TaxType();
    public SaleAccountId: Account = new Account();
    public PurchaseTaxTypeId: TaxType = new TaxType();
    public PurchaseAccountId: Account = new Account();

    constructor(entity?: Partial<Product>) {
        super(entity);
        Object.assign(this, entity);
    }
}
