import { Component, Input, OnInit } from '@angular/core';
import { InputComponent } from '../input/input.component';

@Component({
  selector: 'syslink-input-date',
  templateUrl: './input-date.component.html',
  styleUrls: ['./input-date.component.scss']
})
export class InputDateComponent extends InputComponent implements OnInit {
  @Input() public type: 'datetime' | 'date' | 'time' = 'datetime';
  @Input() public format?: 'datetime' | 'date' | 'shortDatetime' | 'time' | string = undefined;
  public displayFormat: string = '';
  @Input() public interval: number = 30;
  @Input() public showClearButton:boolean = false;

  @Input() public min: Date | number | string | undefined;

  public acceptCustomValue: boolean = false;
  public openOnFieldClick: boolean = true;

  public useMaskBehavior: boolean = true;

  ngOnInit(): void {
    if (!this.format) {
      this.format = this.type;
    }

    switch (this.format) {
      case 'datetime':
        this.displayFormat = 'dd/MM/yyyy HH:mm:ss';
        break;
      case 'date':
        this.displayFormat = 'dd/MM/yyyy';
        break;
      case 'time':
        this.displayFormat = 'HH:mm:ss';
        break;
      case 'shortDatetime':
        this.displayFormat = 'dd/MM/yyyy HH:mm';
        break;
      default:
        this.displayFormat = this.format;
    }
  }
}
