import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ConfirmModalComponent, DrawerItem, ModalComponent, NotificationsService, userMenuItem } from '../../../../../../libraries/syslink-components/src/public-api';
import { AuthService } from '../../auth/auth.service';
import { LanguagesService } from '../../../base/languages/languages.service';
import { TranslateService } from '@ngx-translate/core';
import { UsersService } from '../../auth/users/users.service';
import { ConfirmPasswordComponent } from 'projects/libraries/syslink-components/src/lib/modal/confirm-password/confirm-password.component';
import { User } from '../../auth/users/user.model';
import { ModuleInfoService } from '../../../base/modules/module-info/module-info.service';
import { ModuleInfo } from '../../../base/modules/module-info/module-info.model';
import { environment } from '../../../../environments/environment';
import { Subscription } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { DrawerMenuItemsService } from '../../../base/menus/drawer-menu-items/drawer-menu-items.service';
import { UserPermission } from '../../auth/user-permissions/user-permission.model';

@Component({
  selector: 'app-admin',
  templateUrl: 'admin.component.html',
  styleUrls: ['admin.component.scss']
})
export class AdminComponent implements OnInit, OnDestroy {
  @ViewChild('logoutConfirm') public logoutConfirm: ConfirmModalComponent = new ConfirmModalComponent();
  @ViewChild('profileModal') public profileModal: ModalComponent = new ModalComponent();
  @ViewChild('aboutModal') public aboutModal: ModalComponent = new ModalComponent();
  @ViewChild('passwordConfirm') public passwordConfirm: ConfirmPasswordComponent = new ConfirmPasswordComponent(this.translateService);//ConfirmPasswordComponent

  private shouldUpdateLanguage: boolean = false;
  public currentUser: User = new User();
  public checkUserPassword: boolean = false;
  public moduleInfo: ModuleInfo = new ModuleInfo();
  public versionText: string = '';

  constructor(
    private languagesService: LanguagesService,
    public authService: AuthService,
    public translateService: TranslateService,
    public usersService: UsersService,
    private moduleInfoService: ModuleInfoService,
    private router: Router,
    private drawerMenuItemsService: DrawerMenuItemsService
  ) {
    this.navigationSubscription = this.router.events.subscribe(async (event) => {
      if (event instanceof NavigationEnd) {
        this.router.navigated = false;
      }
    });
  }

  async ngOnInit() {
    this.languagesService.loadAvailableLanguages();
    if (this.authService.user) {
      this.currentUser = this.authService.user;

      // Fetching DrawwerMenuItems depending on userPermissions of Type 'MenuItem'
      // -------------------------------------------------------------------------
      var menuItemsPermissions: UserPermission[] = this.currentUser.AllPermissions.filter(e => e.Type?.Code == "MenuItem");
      var menuItemsPermissionCodes: string[] = menuItemsPermissions.map(e => e.Code);

      this.drawerItems = this.filterPermissions((await this.drawerMenuItemsService.get("items"))??[], menuItemsPermissionCodes);
    }

    this.moduleInfo = (await this.moduleInfoService.load({ filter: ["Name eq 'ApplicationModule'"] }))[0];
    this.versionText = `© ${environment.erpName} ${this.moduleInfo.Version}`;
  }

  private filterPermissions(items?: DrawerItem[], menuItemsPermissionCodes: string[] = []): DrawerItem[] {
    items = items?.filter(e => menuItemsPermissionCodes.includes(e.id)) || [];
    items.forEach((child: DrawerItem) => {
      child.items = this.filterPermissions(child.items, menuItemsPermissionCodes);
    });

    return items || [];
  }

  private navigationSubscription: Subscription;
  ngOnDestroy() {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }

  public userMenuItems: userMenuItem[] = [
    { id: 'profile', text: 'Profile', icon: 'fa-solid fa-user', click: () => this['onProfileButtonClicked']() },
    { id: 'logout', text: 'Logout', icon: 'fa-solid fa-right-from-bracket', click: () => { this.logoutConfirm.open(); } },
    { id: 'about', text: 'About', icon: 'fa-solid fa-circle-info', click: () => { this.aboutModal.open(); } },
    // {
    //   id: 'local-storage-clear', text: 'Clear local storage', icon: 'fa-solid fa-trash', click: () => {
    //     Object.keys(localStorage).forEach((key: string) => {
    //       if (key === 'access_token') return;
    //       localStorage.removeItem(key);
    //     });
    //   }
    // }
  ]

  public drawerItems: DrawerItem[] = [];

  // Profile
  // -------
  onProfileButtonClicked() {
    if (this.authService.user != undefined) {
      this.currentUser = this.authService.user;
    }
    this.profileModal.open();
  }
  onProfileModalValidate() {
    if (this.checkUserPassword == true) {
      this.passwordConfirm.open();
    }
    else {
      this.saveProfile('');
    }
  }

  public async saveProfile(password: any) {
    if (!this.currentUser || !this.currentUser.Oid) return;
    this.passwordConfirm.close();
    if (this.currentUser.Password != null || this.currentUser.PasswordConfirmation != null) {
      if (this.currentUser.Password != this.currentUser.PasswordConfirmation) {
        NotificationsService.sendErrorMessage("Password and password confirmation is not correct");
        return;
      }
      await this.usersService.updatePassword(this.currentUser);
    }

    await this.usersService.update(this.currentUser.Oid, this.usersService.format(this.currentUser));

    await this.authService.reloadUser();
    this.profileModal.close();
    NotificationsService.sendSuccess("Record updated");
    // if (this.shouldUpdateLanguage) {
    //   this.updateLanguage();
    // }
  }

}
