import { Component, Input, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConfirmModalComponent, ModalComponent, NotificationsService, PageComponent } from 'projects/libraries/syslink-components/src/public-api';
import { ThirdsService } from '../../../thirds/thirds/thirds.service';
import { SaleOrder } from '../sale-orders/sale-order.model';
import { SaleOrdersService } from './sale-orders.service';
import { SaleOrderGridComponent } from '../sale-order-grid/sale-order-grid.component';
import { PaymentsComponent } from '../../../base/payments/payments.component';
import { Payment } from '../../../base/payments/payment.model';
import { ContextMenuItemAction } from 'projects/libraries/syslink-components/src/lib/context-menus/context-menu-item-action';
import { SaleContexMenuItemActionCode } from '../../sale-documents/sale-documents/sale-documents.service';
import { ContextMenuItemClickEvent } from 'devextreme/ui/file_manager';
import { SaleOrderHeaderHeaderStatusesService } from '../sale-order-header-header-statuses/sale-order-header-header-statuses.service';
import { SaleOrderHeaderStatus } from '../sale-order-header-statuses/sale-order-header-status.model';
import { SaleOrderStatusSelectModalComponent } from '../sale-order-status/sale-order-status-select-modal/sale-order-status-select-modal.component';

@Component({
  selector: 'app-sale-orders',
  templateUrl: './sale-orders.component.html',
  styleUrls: ['./sale-orders.component.scss']
})
export class SaleOrdersComponent extends PageComponent {
  @Input() public filter: string | string[] = "";
  public expand: Array<string> = ['ThirdId', 'ResponsibleUserId', 'Statuses', 'CurrentStatusLink.StatusId'];
  public customerFilter: string | string[] = ["Roles/any(r:r/Code eq 'customer')"];

  @Input() public items?: SaleOrder[];
  @Input() public canRefresh: boolean = false;
  @Input() public canDelete: boolean = false;
  @Input() public delete: Function = (e: any) => {};

  @ViewChild('initModal') initModal?: ModalComponent;
  @ViewChild('grid') grid?: SaleOrderGridComponent;
  @ViewChild("confirmModal") confirmModal?: ConfirmModalComponent;
  @ViewChild("statusModal") statusModal?: SaleOrderStatusSelectModalComponent;
  @ViewChild("paymentModal") paymentModal?: PaymentsComponent;

  // ContextMenuItemAction
  // ---------------------
  public contextMenuItems: ContextMenuItemAction[] = [
    { code: SaleContexMenuItemActionCode.PrintToPDF, text: "Preview to PDF", icon: "print", onItemClick: (e: any) => this.onMenuItemClicked(e), visible: this.authService.hasPermission('sales.orders.list.contextMenu.preview') },
    { code: SaleContexMenuItemActionCode.PrintToZIP, text: "Download to ZIP", icon: "print", onItemClick: (e: any) => this.onMenuItemClicked(e), visible: this.authService.hasPermission('sales.orders.list.contextMenu.preview') },
    { code: SaleContexMenuItemActionCode.Status, text: "Change status", icon: "edit", onItemClick: (e: any) => this.onMenuItemClicked(e), visible: this.authService.hasPermission('sales.orders.list.contextMenu.changeStatus') },
    { code: SaleContexMenuItemActionCode.payment, text: "Add payment", icon: "money", onItemClick: (e: any) => this.onMenuItemClicked(e), visible: this.authService.hasPermission('sales.orders.list.contextMenu.addPayment') }
  ];

  // Payment
  // -------
  public paymentElement: Payment[] = [];
  public paymentDocument?: SaleOrder;

  constructor(
    public saleOrdersService: SaleOrdersService,
    public override activatedRoute: ActivatedRoute,
    public thirdsService: ThirdsService,
    private saleOrderHeaderHeaderStatusesService: SaleOrderHeaderHeaderStatusesService
  ) { super(); }

  public onAddButtonClicked() {
    this.initModal?.open();
  };

  public onCreateValidated(e: any) {
    if (!e.ThirdId.Id) {
      NotificationsService.sendErrorMessage("Please select third");
      return;
    }
    if (e.ThirdId.Blocked == true) {
      NotificationsService.sendErrorMessage("Third is blocked");
      return;
    }
    this.router.navigateByUrl('/sales/orders/new?ThirdId=' + e.ThirdId.Id + (e.Subject != undefined ? '&Subject=' + e.Subject : ''));
  }


  // ContextMenu
  // -----------
  public async onMenuItemClicked(e: ContextMenuItemClickEvent) {

    if (!this.grid) return;

    switch (e.itemData.code) {
      // Printing
      // ---------
      case SaleContexMenuItemActionCode.PrintToPDF:
        this.showConfirmModal("Would you like preview selected rows?", e.itemData);
        break;
      case SaleContexMenuItemActionCode.PrintToZIP:
        this.showConfirmModal("Would you like download selected rows?", e.itemData);
        break;
      case SaleContexMenuItemActionCode.Status:
        this.showStatusModal("Would you like change status selected rows?", e.itemData);
        break;
      case SaleContexMenuItemActionCode.payment:
        await this.showPaymentModal("Would you like add payment", e.itemData);
        break;
    }
  }

  private showConfirmModal(text: string, data: ContextMenuItemAction) {
    if (!this.confirmModal) return;

    this.confirmModal.title = data.text ? data.text : "Confirm";
    this.confirmModal.content = text;
    this.confirmModal.open({ rows: this.grid?.getSelectedRows(data.rowData), action: data.code });
  }
  private showStatusModal(text: string, data: ContextMenuItemAction) {
    if (!this.statusModal) return;

    this.statusModal.title = data.text ? data.text : "Confirm";
    this.statusModal.content = text;
    this.statusModal.open({ rows: this.grid?.getSelectedRows(data.rowData), action: data.code });
  }
  private async showPaymentModal(text: string, data: ContextMenuItemAction) {
    if (this.grid?.getSelectedRows(data.rowData) && this.grid?.getSelectedRows(data.rowData)?.length > 1) {
      NotificationsService.sendErrorMessage("Please select only one line");
      return;
    }
    this.paymentDocument = await this.saleOrdersService.findByID(this.grid?.getSelectedRows(data.rowData)[0].Id, { expand: this.saleOrdersService.defaultOptions.expand })
    // TODO add better method
    // Wait component is loaded
    setTimeout(async () => {
      if (!this.paymentModal || !this.paymentDocument) return;
      this.paymentElement = this.paymentDocument.Payments;
      await this.paymentModal.onAddPaymentClicked();
    }, 100);
  }

  public async onConfirmModalValidated(modalData: { rows: SaleOrder[], action: string } | any) {
    switch (modalData.action) {
      case SaleContexMenuItemActionCode.PrintToPDF:
        await this.saleOrdersService.printRows(modalData.rows, "pdf", "SaleOrder");
        break;
      case SaleContexMenuItemActionCode.PrintToZIP:
        await this.saleOrdersService.printRows(modalData.rows, "zip", "SaleOrder");
        break;
      case SaleContexMenuItemActionCode.Status:
        await this.updateStatus(modalData.rows, modalData.OrderStatus);
        break;
    }

    this.grid?.refresh();
    this.confirmModal?.close();
    this.statusModal?.close();
  }
  // ------------------------------------------------------------------------------------------------

  // Actions
  // -------
  private async updateStatus(selectedRows: SaleOrder[], status: SaleOrderHeaderStatus) {
    if (!this.saleOrdersService.canUpdateStatus(selectedRows, status)) return;
    selectedRows.sort((a, b) => { return a.Date.getTime() - b.Date.getTime(); });

    for (var element of selectedRows) {
      if (element.Id) {
        const link: any = {
          HeaderId: { Id: element.Id },
          StatusId: { Id: status.Id },
          Date: new Date()
        };
        await this.updateDocumentStatus(link);
      }
    }
    NotificationsService.sendSuccess('Status updated');
  }
  private async updateDocumentStatus(link: any) {
    let documentStatus = await this.saleOrderHeaderHeaderStatusesService.insert(this.saleOrderHeaderHeaderStatusesService.format(link));
  }
}