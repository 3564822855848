<syslink-tabs>
    <syslink-tab
        *ngIf="showSaleQuote"
        label="Sale quotes"
        key="SaleQuote"
        [visible]="authService.hasPermission(this.basePermissionKey + '.saleQuote.tab.view')"
    >
        <app-sale-quote-grid
            #saleQuoteGrid
            *ngIf="itemsSaleQuoteId!= undefined"
            [permissionKey]="'saleQuote.list'"
            (add)="addSaleQuote()"
            (delete)="onDeleteRelation.bind(this)"
            [canDelete]="true"
            [filter]="['Id in ('+(itemsSaleQuoteId).join(',')+')']"
            [canAdd]="false"
            [canUpdate]="false"
            [canDelete]="false"
            (refreshClicked)="onRefreshClicked.emit()"
        ></app-sale-quote-grid>
    </syslink-tab>

    <syslink-tab
        *ngIf="showSaleOrder"
        label="Sale orders"
        key="SaleOrder"
        [visible]="authService.hasPermission(this.basePermissionKey + '.saleOrder.tab.view')"
    >
        <app-sale-order-grid
            #saleOrderGrid
            *ngIf="itemsSaleOrderId!= undefined"
            [permissionKey]="'saleOrder.list'"
            (delete)="onDeleteRelation.bind(this)"
            [canDelete]="true"
            (add)="addSaleOrder()"
            [filter]="['Id in ('+(itemsSaleOrderId).join(',')+')']"
            [canAdd]="false"
            [canUpdate]="false"
            [canDelete]="false"
            (refreshClicked)="onRefreshClicked.emit()"
        ></app-sale-order-grid>
    </syslink-tab>

    <syslink-tab
        *ngIf="showSaleInvoice"
        [label]="'Sale invoices' | translate"
        key="SaleInvoice"
        [visible]="authService.hasPermission(this.basePermissionKey + '.saleInvoice.tab.view')"
    >
        <app-sale-invoice-grid
            *ngIf="itemsSaleInvoiceId!= undefined"
            #saleInvoiceGrid
            [permissionKey]="'saleInvoice.list'"
            (add)="addSaleInvoice()"
            (delete)="onDeleteRelation.bind(this)"
            [canDelete]="false"
            [filter]="['Id in ('+(itemsSaleInvoiceId).join(',')+')']"
            (refreshClicked)="onRefreshClicked.emit()"
        ></app-sale-invoice-grid>
    </syslink-tab>

    <syslink-tab
        *ngIf="showSaleCreditNote"
        label="Sale credit notes"
        key="SaleCreditNote"
        [visible]="authService.hasPermission(this.basePermissionKey + '.saleCreditNote.tab.view')"
    >
        <app-sale-credit-note-grid
            #saleCreditNoteGrid
            *ngIf="itemsSaleCreditNoteId!= undefined"
            [permissionKey]="'saleCreditNote.list'"
            (add)="addSaleCreditNote()"
            (delete)="onDeleteRelation.bind(this)"
            [canDelete]="true"
            [filter]="['Id in ('+(itemsSaleCreditNoteId).join(',')+')']"
            [canAdd]="false"
            [canUpdate]="false"
            [canDelete]="false"
            (refreshClicked)="onRefreshClicked.emit()"
        ></app-sale-credit-note-grid>
    </syslink-tab>

    <syslink-tab
        *ngIf="showSaleContract"
        label="Contracts"
        key="SaleContract"
        [visible]="authService.hasPermission(this.basePermissionKey + '.saleContract.tab.view')"
    >
        <app-sale-contract-grid
            *ngIf="itemsSaleContractId!= undefined"
            #saleContractGrid
            [permissionKey]="'saleContract.list'"
            [canDelete]="true"
            [filter]="['Id in ('+(itemsSaleContractId).join(',')+')']"
            [canAdd]="false"
            [canUpdate]="false"
            [canDelete]="false"
            (refreshClicked)="onRefreshClicked.emit()"
        ></app-sale-contract-grid>
    </syslink-tab>

    <syslink-tab
        *ngIf="(reportType == 'SaleInvoice' || reportType == 'SaleCreditNote') && documentId>0"
        label="Tasks"
        key="Task"
        [visible]="authService.hasPermission(this.basePermissionKey + '.task.tab.view')"
    >
        <app-task-grid
            #taskGrid
            [permissionKey]="'task.list'"
            [filter]="['SaleInvoiceLineId.HeaderId.Id eq '+documentId]"
            [canAdd]="false"
        ></app-task-grid>
    </syslink-tab>

    <syslink-tab
        *ngIf="(reportType == 'SaleInvoice' || reportType == 'SaleCreditNote') && documentId>0"
        label="Work times"
        key="WorkTime"
        [visible]="authService.hasPermission(this.basePermissionKey + '.worktime.tab.view')"
    >
        <app-work-time-grid
            #workTimeGrid
            [permissionKey]="'WorkTime.list'"
            [filter]="['SaleInvoiceLineId.HeaderId.Id eq '+documentId]"
            [canAdd]="false"
            [toolbaActions]="gridWorkTimeToolbarActions"
        ></app-work-time-grid>
    </syslink-tab>

    <syslink-tab
        *ngIf="showPurchaseOrder"
        label="Purchase orders"
        key="PurchaseOrder"
        [visible]="authService.hasPermission(this.basePermissionKey + '.purchaseOrder.tab.view')"
    >
        <app-purchase-order-grid
            #purchaseOrderGrid
            *ngIf="itemsPurchaseOrderId!= undefined"
            [permissionKey]="'purchaseOrder.list'"
            (delete)="onDeleteRelation.bind(this)"
            [canDelete]="true"
            (add)="addPurchaseOrder()"
            [filter]="['Id in ('+(itemsPurchaseOrderId).join(',')+')']"
            [canAdd]="false"
            [canUpdate]="false"
            [canDelete]="false"
            (refreshClicked)="onRefreshClicked.emit()"
        ></app-purchase-order-grid>
    </syslink-tab>

    <syslink-tab
        *ngIf="showPurchaseInvoice"
        [label]="'Purchase invoices' | translate"
        key="PurchaseInvoice"
        [visible]="authService.hasPermission(this.basePermissionKey + '.purchaseInvoice.tab.view')"
    >
        <app-purchase-invoice-grid
            *ngIf="itemsPurchaseInvoiceId!= undefined"
            #purchaseInvoiceGrid
            [permissionKey]="'purchaseInvoice.list'"
            (add)="addPurchaseInvoice()"
            (delete)="onDeleteRelation.bind(this)"
            [canDelete]="false"
            [filter]="['Id in ('+(itemsPurchaseInvoiceId).join(',')+')']"
            (refreshClicked)="onRefreshClicked.emit()"
        ></app-purchase-invoice-grid>
    </syslink-tab>

    <syslink-tab
        *ngIf="showPurchaseCreditNote"
        label="Purchase credit notes"
        key="PurchaseCreditNote"
        [visible]="authService.hasPermission(this.basePermissionKey + '.purchaseCreditNote.tab.view')"
    >
        <app-purchase-credit-note-grid
            #purchaseCreditNoteGrid
            *ngIf="itemsPurchaseCreditNoteId!= undefined"
            [permissionKey]="'purchaseCreditNote.list'"
            (add)="addPurchaseCreditNote()"
            (delete)="onDeleteRelation.bind(this)"
            [canDelete]="true"
            [filter]="['Id in ('+(itemsPurchaseCreditNoteId).join(',')+')']"
            [canAdd]="false"
            [canUpdate]="false"
            [canDelete]="false"
            (refreshClicked)="onRefreshClicked.emit()"
        ></app-purchase-credit-note-grid>
    </syslink-tab>

    <syslink-tab
        *ngIf="showStockMoveCustomerOutgoing"
        label="Customeroutgoings"
        key="Customeroutgoings"
        [visible]="authService.hasPermission(this.basePermissionKey + '.customerOutgoing.tab.view')"
    >
        <app-stock-move-outgoing-grid
            #stockMoveCustomerOutgoingGrid
            *ngIf="itemsStockMoveCustomerOutgoing!= undefined"
            [permissionKey]="'customerOutgoing.list'"
            [filter]="['Id in ('+(itemsStockMoveCustomerOutgoing).join(',')+') '+ filterCustomerExtension]"
            detailsUrl="/stockmoves/customeroutgoings"
            storageKey="stock-move-customeroutgoings"
            (refreshClicked)="onRefreshClicked.emit()"
        ></app-stock-move-outgoing-grid>
    </syslink-tab>
    <syslink-tab
        *ngIf="showStockMoveCustomerIncoming"
        label="Customerincomings"
        key="Customerincomings"
        [visible]="authService.hasPermission(this.basePermissionKey + '.customerIncoming.tab.view')"
    >
        <app-stock-move-incoming-grid
            #stockMoveCustomerIncomingGrid
            *ngIf="itemsStockMoveCustomerIncoming!= undefined"
            [permissionKey]="'customerIncoming.list'"
            [filter]="['Id in ('+(itemsStockMoveCustomerIncoming).join(',')+') '+ filterCustomerExtension]"
            detailsUrl="/stockmoves/customerincomings"
            storageKey="stock-move-customerincomings"
            (refreshClicked)="onRefreshClicked.emit()"
        ></app-stock-move-incoming-grid>
    </syslink-tab>
    <syslink-tab
        *ngIf="showStockMoveSupplierOutgoing"
        label="Supplieroutgoings"
        key="Supplieroutgoings"
        [visible]="authService.hasPermission(this.basePermissionKey + '.supplierOutgoing.tab.view')"
    >
        <app-stock-move-outgoing-grid
            #stockMoveSupplierOutgoingGrid
            *ngIf="itemsStockMoveSupplierOutgoing!= undefined"
            [permissionKey]="'supplierOutgoing.list'"
            [filter]="['Id in ('+(itemsStockMoveSupplierOutgoing).join(',')+') '+ filterSupplierExtension]"
            detailsUrl="/stockmoves/supplieroutgoings"
            storageKey="stock-move-supplieroutgoings"
            (refreshClicked)="onRefreshClicked.emit()"
        ></app-stock-move-outgoing-grid>
    </syslink-tab>
    <syslink-tab
        *ngIf="showStockMoveSupplierIncoming"
        label="Supplierincomings"
        key="Supplierincomings"
        [visible]="authService.hasPermission(this.basePermissionKey + '.supplierIncoming.tab.view')"
    >
        <app-stock-move-incoming-grid
            #stockMoveSupplierIncomingGrid
            *ngIf="itemsStockMoveSupplierIncoming!= undefined"
            [permissionKey]="'supplierIncoming.list'"
            [filter]="['Id in ('+(itemsStockMoveSupplierIncoming).join(',')+') '+ filterSupplierExtension]"
            detailsUrl="/stockmoves/supplierincomings"
            storageKey="stock-move-supplierincomings"
            (refreshClicked)="onRefreshClicked.emit()"
        ></app-stock-move-incoming-grid>
    </syslink-tab>

    <syslink-tab
        *ngIf="showStockMoveInternal"
        label="Internal"
        key="Internal"
        [visible]="authService.hasPermission(this.basePermissionKey + '.internalStockMove.tab.view')"
    >
        <app-stock-move-incoming-grid
            #stockMoveInternalGrid
            *ngIf="itemsStockMoveInternal!= undefined"
            [permissionKey]="'internal.list'"
            [filter]="['Id in ('+(itemsStockMoveInternal).join(',')+') ']"
            detailsUrl="/stockmoves/internalstockmoves"
            storageKey="stock-move-internal"
            (refreshClicked)="onRefreshClicked.emit()"
        ></app-stock-move-incoming-grid>
    </syslink-tab>

</syslink-tabs>