import { Injectable } from '@angular/core';
import { PurchaseDocumentStatusesService } from '../../purchase-documents/purchase-document-statuses/purchase-document-statuses.service';
import { PurchaseInvoiceHeaderStatus } from './purchase-invoice-header-status.model';

@Injectable({
  providedIn: 'root'
})
export class PurchaseInvoiceHeaderStatusesService extends PurchaseDocumentStatusesService<PurchaseInvoiceHeaderStatus> {
  public override url: string = 'PurchaseInvoiceHeaderStatus';
 
  constructor() {
    super();
  }
}
