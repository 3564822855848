import { Component, Input } from '@angular/core';
import { User } from '../user.model';
import { UsersService } from '../users.service';
import { BaseModalComponent } from 'projects/libraries/syslink-components/src/lib/modal/base-modal/base-modal.component';

@Component({
  selector: 'app-user-tagbox-modal',
  templateUrl: './user-tagbox-modal.component.html',
  styleUrls: ['./user-tagbox-modal.component.scss']
})
export class UserTagboxModalComponent extends BaseModalComponent<User> {

  @Input() public title: string = "Select user";
  @Input() public content?: string;

  constructor(public usersService: UsersService) {
    super();
  }
  public reload(users: User[] = []) {
    if (!this.modal) return;
    this.modal.data.Users = users ;
  }
}