import { Injectable } from '@angular/core';
import { ApiService } from '../../../core/api.service';

@Injectable({
  providedIn: 'root'
})
export class DrawerMenuItemsService extends ApiService {
  public override url: string="/api/drawer";
 

}
