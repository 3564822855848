import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TaxRatesService } from '../../../base/tax-rates/tax-rates.service';
import { Supplier } from '../supplier.model';
import { DeliveryTimesService } from '../../../base/delivery-times/delivery-times.service';
import { DocumentDelaysService } from '../../../base/documents/document-delays/document-delays.service';

@Component({
  selector: 'app-supplier-form',
  templateUrl: './supplier-form.component.html',
  styleUrls: ['./supplier-form.component.scss']
})
export class SupplierFormComponent implements OnInit {
  
  @Input() public element: Supplier = new Supplier();
  @Output() public elementChange: EventEmitter<Supplier> = new EventEmitter<Supplier>();
  
  constructor(
    public taxRatesService:TaxRatesService,
    public deliveryTimesService:DeliveryTimesService,
    public documentDelaysService:DocumentDelaysService
   ) { }

  ngOnInit(): void {
  }
}
