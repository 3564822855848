import { Injectable } from '@angular/core';
import { BasicEntity } from '../../core/models/basic-entity';
import { ODataService } from '../../core/services/oData.service';

export class ThirdTitle extends BasicEntity { }

@Injectable({
  providedIn: 'root'
})
export class ThirdTitlesService extends ODataService<ThirdTitle> {
  public url = 'ThirdTitle';
}
