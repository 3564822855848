import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SyslinkColumn } from 'projects/libraries/syslink-components/src/lib/helpers/SyslinkColumn';
import { PageComponent, SyslinkGridOption } from 'projects/libraries/syslink-components/src/public-api';
import { UserRole } from './user-role.model';
import { UserRolesService } from './user-roles.service';

@Component({
  selector: 'app-user-roles',
  templateUrl: './user-roles.component.html',
  styleUrls: ['./user-roles.component.scss']
})
export class UserRolesComponent extends PageComponent {
  @Input() filter: string | string[] = "";

  public columns: SyslinkColumn[] = [
    new SyslinkColumn({ field: 'Id', label: 'Id', visible: false }),
    new SyslinkColumn({ field: 'Code', label: 'Code' }),
    new SyslinkColumn({ field: 'Name', label: 'Name' }),
  ];
  public storageKey: string = 'grid-user-roles';
  constructor(
    public userRolesService: UserRolesService,
    protected override activatedRoute: ActivatedRoute
  ) {
    super();
  }

  public addNew() {
    this.router.navigate(['new'], { relativeTo: this.activatedRoute });
  }

  public onDoubleClicked(data: UserRole) {
    this.router.navigate([data.Id], { relativeTo: this.activatedRoute });
  }
}