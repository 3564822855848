import { Injectable } from '@angular/core';
import { ODataService } from '../../core/services/oData.service';
import { LegalForm } from './legal-form.model';

@Injectable({
  providedIn: 'root'
})
export class LegalFormsService extends ODataService<LegalForm> {
  public url = 'LegalForm';
  
  public override async getInstance(params?: Partial<LegalForm>): Promise<LegalForm> {
    return new LegalForm(params);
  }
}
