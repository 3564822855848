import { DocumentHeaderStatus } from "../../../base/documents/document-header-statuses/document-header-status.model";
import { PurchaseDocumentStatus } from "../purchase-document-statuses/purchase-document-status.model";
import { PurchaseDocument } from "../purchase-documents/purchase-document.model";

export class PurchaseDocumentHeaderStatus extends DocumentHeaderStatus {
    public override HeaderId?: PurchaseDocument;
    public override StatusId?: PurchaseDocumentStatus;

    constructor(entity?: Partial<PurchaseDocumentHeaderStatus>) {
        super();
        Object.assign(this, entity);
    }
}
