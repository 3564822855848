import { BasicEntity } from "../../core/models/basic-entity";

export class LegalForm extends BasicEntity {
    public Description? : string =""; 
    
    constructor(basicEntity?: Partial<BasicEntity>) {
        super(basicEntity);
        Object.assign(this, basicEntity);
    }
 }

