import { Component, Input, ViewChild } from '@angular/core';
import { Third } from '../third.model';
import { BaseGridComponent } from 'projects/libraries/syslink-components/src/lib/grid/base-grid/base-grid.component';
import { GridComponent } from 'projects/libraries/syslink-components/src/public-api';
import { SyslinkColumn } from 'projects/libraries/syslink-components/src/lib/helpers/SyslinkColumn';
import { ThirdsService } from '../thirds.service';
import { BooleanCellData } from 'projects/libraries/syslink-components/src/lib/grid/cells/boolean-cell/boolean-cell.component';

@Component({
  selector: 'app-third-grid',
  templateUrl: './third-grid.component.html',
  styleUrls: ['./third-grid.component.scss']
})
export class ThirdGridComponent extends BaseGridComponent<Third> {

  public override canDelete: boolean = true;
  @Input() storageKey: string = 'grid-thirds';

  @ViewChild('gridComponent') public gridComponent?: GridComponent;

  public columns: SyslinkColumn[] = [
    new SyslinkColumn({ field: 'No', label: 'Id', width: 120 }),
    new SyslinkColumn({ field: 'Fullname', label: 'fullname' }),
    new SyslinkColumn({ field: 'Alias', label: 'Alias' }),
    //new SyslinkColumn({ field: 'CustomerId.PriceListId.NameTranslationId.TranslatedValue', label: 'PriceList' }),
    new SyslinkColumn({ field: 'DisplayAddress', label: 'Adresse' }),
    new SyslinkColumn({ field: 'DisplayPhoneNumber', label: 'Phone number' }),
    new SyslinkColumn({ field: 'DisplayEmail', label: 'Professional email' }),
    new SyslinkColumn({ field: 'CustomerId.Balance', label: 'Balance', cellTemplate:'currency-cell' }),
    new SyslinkColumn({ field: 'VatNumber', label: 'Vat number' }),
    new SyslinkColumn({ field: 'Blocked', label: 'Blocked', type: 'boolean', cellTemplate: 'boolean-cell', data: new BooleanCellData({ trueColor: 'danger', falseColor: 'success' }) })
  ];

  constructor(
     public thirdsService: ThirdsService,
  ) {
    super();
  }

}
