<syslink-grid
    #gridComponent
    [canView]="canView"
    [canSearch]="canSearch"
    [canRefresh]="canRefresh"
    [canAdd]="canAdd"
    [canSort]="canSort"
    [canUpdate]="canUpdate"
    [canDelete]="canDelete"
    [canFilter]="canFilter"
    [canExport]="canExport"
    [canDblClck]="canDblClck"
    [items]="items"
    [columns]="columns"
    [storageKey]="storageKey"
    [key]="'StockLocationId'"
></syslink-grid>