import { Injectable } from '@angular/core';
import { LoadOptions } from 'devextreme/data';
import { ODataService } from '../../core/services/oData.service';
import { Payment } from './payment.model';
import { PaymentStatesService } from './payment-states/payment-states.service';


@Injectable({
  providedIn: 'root'
})
export class PaymentsService extends ODataService<Payment> {
  public url = 'Payment';
  public override defaultOptions: LoadOptions = {
    expand: [
      'PaymentMethodPrimary',
      'ParentId',
      'PaymentStateId',
      'PaymentTypeId'
    ]
  };
  constructor(private paymentStatesService: PaymentStatesService) {
    super();
  }

  public override format(params?: Partial<Payment>): Payment {
    var result: any = {
      ...params,
      PaymentMethodPrimary: params?.PaymentMethodPrimary?.Id,
      ParentId: params?.ParentId?.Id,
    };
    delete result.AmountToReturn;
    delete result.onAdd;
    let date: Date | undefined = result.Date;
    if (!date || date.getFullYear().toString() == "1") {
      delete result.Date;
    }
    return result;
  }

  public override async getInstance(payment?: Partial<Payment>): Promise<Payment> {
    let element = new Payment({
      Date: new Date(),
      PaymentStateId: (await this.paymentStatesService.load({ filter: ["Code eq 'paid'"] }))[0],
      ...payment,
    });

    return element;
  }

}
