import { Injectable } from '@angular/core';
import { StockMoveDocumentStatusesService } from '../../stock-move-documents/stock-move-document-statuses/stock-move-document-statuses.service';
import { StockMoveInternalHeaderStatus } from './stock-move-internal-header-status.model';

@Injectable({
  providedIn: 'root'
})
export class StockMoveInternalHeaderStatusesService extends StockMoveDocumentStatusesService<StockMoveInternalHeaderStatus> {
  public override url: string = 'StockMoveInternalHeaderStatus';
 
  constructor() {
    super();
  }
}
