import { Component, Input, OnInit } from '@angular/core';
import { CreditsafeData } from '../creditsafe.model';
import { ChartSerie } from 'projects/libraries/syslink-components/src/lib/helpers/ChartSeries';
import { CreditsafesService } from '../creditsafe.service';

@Component({
  selector: 'app-creditsafe-rating-history',
  templateUrl: './creditsafe-rating-history.component.html',
  styleUrls: ['./creditsafe-rating-history.component.scss']
})
export class CreditsafeRatingHistoryComponent implements OnInit {
  @Input() element: CreditsafeData = new CreditsafeData();
  public data: any = [];
  public seriesCompanyValue: ChartSerie[] = [
    new ChartSerie({ argumentField: "Date", valueField: "CompanyValue", name: "CompanyValue" })
  ];
  public seriesPod: ChartSerie[] = [
    new ChartSerie({ argumentField: "Date", valueField: "Pod", name: "PrevisionOfDefault(Pod)", label: { visible: false } })
  ];

  constructor(
    private creditsafesService: CreditsafesService
  ) { }

  ngOnInit(): void {
    (this.element.Report?.AdditionalInformation?.RatingHistory ?? []).reverse().map((e: any) => {
      this.data.push({
        Date: this.creditsafesService.formatDate(new Date(e.Date)),
        Pod: e.Pod ? (e.Pod) * 100 : undefined,
        CompanyValue: e.CompanyValue ? (e.CompanyValue * 1) : undefined,
      })
    });
  }
}
