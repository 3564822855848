<div class="row row-gap-2 align-items-center">
  <ng-container *ngFor="let field of fields; let i = index">
    <div
      class="col-{{
        field.ColumnWidth == null
          ? field.Type === 'text'
            ? 6
            : 3
          : field.ColumnWidth
      }} py-2"
      [ngSwitch]="field.Type"
    >
      <!-- ONO TO ONE -->
      <ng-container *ngSwitchCase="'one-to-one'">
        <syslink-select
          [label]="field.Name"
          [store]="getStore(field)"
          [model]="fieldsSelectValues[i]"
          (modelChange)="field.Value = $event"
          [disabled]="field.Disabled ?? false"
        ></syslink-select>
      </ng-container>

      <!-- ONE TO MANY -->
      <ng-container *ngSwitchCase="'one-to-many'">
        <!-- <syslink-tagbox [name]="field.Key" [label]="field.Name" [store]="getStore(field)" [model]="field.Value | number" (modelChange)="field.Value = $event"></syslink-tagbox> -->
      </ng-container>

      <!-- STRING -->
      <ng-container *ngSwitchCase="'string'">
        <syslink-input-text
          [name]="field.Key"
          [label]="field.Name"
          [(model)]="field.Value"
          [disabled]="field.Disabled ?? false"
        ></syslink-input-text>
      </ng-container>

      <!-- STRING -->
      <ng-container *ngSwitchCase="'color'">
        <syslink-color-picker
          [name]="field.Key"
          [label]="field.Name"
          [(model)]="field.Value"
          [disabled]="field.Disabled ?? false"
        ></syslink-color-picker>
      </ng-container>

      <!-- BOOLEAN -->
      <ng-container *ngSwitchCase="'bool'">
        <syslink-switch
          [name]="field.Key"
          [label]="field.Name"
          [model]="field.Value === 'true' ? 1 : 0"
          (modelChange)="field.Value = $event ? 'true' : 'false'"
          [disabled]="field.Disabled ?? false"
        ></syslink-switch>
      </ng-container>

      <!-- DECIMAL -->
      <ng-container *ngSwitchCase="'decimal'">
        <syslink-input-number
          [name]="field.Key"
          [label]="field.Name"
          [(model)]="field.Value"
          type="number"
          [disabled]="field.Disabled ?? false"
          [min]="field.Key.includes('Precision.') ? 0 : undefined"
          [max]="field.Key.includes('Precision.') ? 10 : undefined"
        ></syslink-input-number>
      </ng-container>

      <!-- TEXT -->
      <ng-container *ngSwitchCase="'text'">
        <syslink-wysiwyg
          [name]="field.Key"
          [label]="field.Name"
          [multilineToolbar]="true"
          [(model)]="field.Value"
          height="250"
          [disabled]="field.Disabled ?? false"
        ></syslink-wysiwyg>
      </ng-container>

      <ng-container *ngSwitchDefault>
        {{ "Configuration error: wrong type" | translate }} {{ field.Type }}
      </ng-container>
    </div>

    <!-- <div class="w-100" *ngIf="goToLine(i)"></div> -->
  </ng-container>
</div>
