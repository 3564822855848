import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LoadOptions } from 'devextreme/data';
import { ApiService } from '../../api.service';
import { ODataService } from '../../services/oData.service';
import { UserPermission } from './user-permission.model';

@Injectable({
  providedIn: 'root'
})
export class UserPermissionsService extends ODataService<UserPermission> {
  public url = 'UserPermission';

  public override defaultOptions: LoadOptions = {
    expand: ["Users",
      "UserRoles",
      "UserGroups"
    ]
  };
  
  constructor(
    private api: ApiService,
    private translateService:TranslateService
  ) {
    super();
  }
  public override format(element: Partial<UserPermission>): UserPermission {
    var result: any = {
      ...element,
    };
    return result;
  }

  public override async getInstance(params?: Partial<UserPermission>): Promise<UserPermission> {
    var permission = new UserPermission({
     Code:this.translateService.instant('New User permission'),
     IsActive:true,
      ...params
    });
    return permission;
  }
  
  public async removeUser(userPermissionId: number, userId: number) {
    return await this.api.sendRequest('/api/odata/UserPermission/' + userPermissionId + '/Users/' + userId + '/$ref', 'DELETE');
  }
  public async removeUserRole(userPermissionId: number, roleId: number) {
    return await this.api.sendRequest('/api/odata/UserPermission/' + userPermissionId + '/UserRoles/' + roleId + '/$ref', 'DELETE');
  }
  public async removeUserGroup(userPermissionId: number, groupId: number) {
    return await this.api.sendRequest('/api/odata/UserPermission/' + userPermissionId + '/UserGroups/' + groupId + '/$ref', 'DELETE');
  }
}
