<ng-container 
    *ngIf="hasAtLeastOneTabVisible()"
>
    <dx-tabs
        #tabsContainer
        itemTemplate="itemTemplate"
        [(selectedIndex)]="selectedId"
        [elementAttr]="{class: 'syslink-tabs'}"
        (onItemClick)="selectTab($event.itemIndex)"
    >
        <ng-container *dxTemplate="let item of 'itemTemplate'">
            <span id="tab-{{item.key}}">{{item.label | translate}}</span>
        </ng-container>
    </dx-tabs>
    <div class="syslink-tab p-3">
        <ng-content></ng-content>
    </div>
</ng-container>

<!-- <li *ngFor="let tab of dynamicTabs" (click)="selectTab(tab)" [class.active]="tab.active">
    <a href="#">{{tab.label}} <span class="tab-close" *ngIf="tab.isCloseable" (click)="closeTab(tab)">x</span></a>
</li> -->
<!-- <ng-template dynamic-tabs #container></ng-template> -->