import { Component, Input } from '@angular/core';
import { InputComponent } from '../input/input.component';

@Component({
  selector: 'syslink-input-duration',
  templateUrl: './input-duration.component.html',
  styleUrls: ['./input-duration.component.scss']
})
export class InputDurationComponent extends InputComponent {
  @Input() public textColor?: string = '';
  public override class: string = 'py-2';

  public getHours(): number {
    return Math.floor(this.model / 60);
  }

  public getMinutes(): number {
    return Math.floor(this.model % 60);
  }

  public getValue(): string {
    let hours: string = this.getHours().toString();
    let minutes: string = this.getMinutes().toString().padStart(2, '0');

    if (hours === '0') hours = hours.padStart(2, '0');

    return `${hours}:${minutes}`;
  }

  public onValueChanged(value: string): void {
    let [hours, minutes] = value.split(':');

    if (Number(minutes) >= 60) minutes = '0';

    this.model = Number(minutes) + (Number(hours) * 60);
    this.modelChange.emit(this.model);
  }

  public onClick(event: any): void {
    const target = event.target;
    const separatorPosition: number = target.value.indexOf(':');

    if (target.selectionStart > separatorPosition) {
      target.selectionStart = separatorPosition + 1;
      target.selectionEnd = separatorPosition + 1 + 2;
    } else {
      target.selectionStart = 0;
      target.selectionEnd = separatorPosition;
    }
  }
}