import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';

// Inspired by : https://supportcenter.devexpress.com/ticket/details/t964826/how-to-implement-google-places-for-a-form-item
@Component({
  selector: 'syslink-google-places',
  templateUrl: './google-places.component.html',
  styleUrls: ['./google-places.component.scss']
})
export class GooglePlacesComponent implements AfterViewInit {

  @ViewChild('googlePlacesAutocomplete') public googlePlacesAutocomplete?: any;

  @Output() onPlace: EventEmitter<any> = new EventEmitter<any>();
  @Input() label: string = "Search for an address";
  public input?: any;

  constructor() { }

  ngAfterViewInit() {
    this.input = this.googlePlacesAutocomplete?.element.nativeElement.querySelector('input');
    this.input.placeholder = '';
    const autocomplete = new google.maps.places.Autocomplete(this.input,
      {
        types: ['geocode']
      }
    );

    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      const place = autocomplete.getPlace()
      this.onPlace.emit(place);
      this.input.value = null;
    });
  }
}
