import { Component, Input, ViewChild } from '@angular/core';
import { BaseModalComponent } from 'projects/libraries/syslink-components/src/lib/modal/base-modal/base-modal.component';
import { ActionGroup } from '../action-group.model';
import { ActionGroupsService } from '../action-groups.service';
import { FilterDescriptor } from 'devextreme/data';
import { SelectComponent } from 'projects/libraries/syslink-components/src/public-api';

@Component({
  selector: 'app-action-group-select-modal',
  templateUrl: './action-group-select-modal.component.html',
  styleUrls: ['./action-group-select-modal.component.scss']
})
export class ActionGroupSelectModalComponent extends BaseModalComponent<ActionGroup> {

  private _selectFilters: FilterDescriptor | Array<FilterDescriptor>[] = ['ModuleId.Enabled eq true and SubModuleId.Enabled eq true'];
  @Input()
  set selectFilters(value: FilterDescriptor | Array<FilterDescriptor>) {
    if (value != undefined || value.length != 0) {
      this._selectFilters = [value + ' and ModuleId.Enabled eq true and SubModuleId.Enabled eq true'];
      return;
    }
    this._selectFilters = ['ModuleId.Enabled eq true and SubModuleId.Enabled eq true'];
  }
  get selectFilters(): FilterDescriptor | Array<FilterDescriptor>[] {
    return this._selectFilters;
  }

  @ViewChild('select') select?: SelectComponent;

  constructor(public actionGroupsService: ActionGroupsService) {
    super();
  }

}
