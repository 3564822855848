import { Injectable } from '@angular/core';
import { LoadOptions } from 'devextreme/data';
import { ApiService } from '../../../core/api.service';
import { PurchaseDocumentLinesService } from '../../purchase-documents/purchase-document-lines/purchase-document-lines.service';
import { PurchaseOrderLine } from './purchase-order-line.model';

@Injectable({
  providedIn: 'root'
})
export class PurchaseOrderLinesService extends PurchaseDocumentLinesService<PurchaseOrderLine> {
  public override url: string = 'PurchaseOrderLine';
  public override documentType: string = 'PurchaseOrder';
  public override defaultOptions: LoadOptions = {
    expand: ['Discount', 'ParentId']
  };

  constructor(public override api: ApiService) {
    super(api);
  }

  public override async getInstance(order?: Partial<PurchaseOrderLine>): Promise<any> {
    let element = {
      ...order,
    };
    return element;
  }

  public async updateParentId(purchaseOrderLine: PurchaseOrderLine, targetParentId: number) {
    return await this.api.sendRequest('/api/PurchaseDocumentLine/' + purchaseOrderLine.Id + '/changeParent/' + targetParentId, 'POST');
  }
}
