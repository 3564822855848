import { Component, EventEmitter, Input, Output } from '@angular/core';
import { StockMoveDocumentLine } from '../../stock-move-document-lines/stock-move-document-line.model';
import { StockMoveDocumentLinesService } from '../../stock-move-document-lines/stock-move-document-lines.service';
import { StockMoveDocument } from '../stock-move-document.model';
import { StockMoveDocumentsService } from '../stock-move-documents.service';
import { ConfigurationsService } from '../../../../base/modules/configurations/configurations.service';
import { getNew } from 'projects/libraries/syslink-components/src/lib/helpers/tools';
import { StockMoveDocumentStatus } from '../../stock-move-document-statuses/stock-move-document-status.model';

@Component({
  selector: 'app-stock-move-document-form',
  templateUrl: './stock-move-document-form.component.html',
  styleUrls: ['./stock-move-document-form.component.scss']
})
export class StockMoveDocumentFormComponent<
  TStockMoveDocument extends StockMoveDocument,
  TStockMoveDocumentService extends StockMoveDocumentsService<StockMoveDocument, StockMoveDocumentStatus>,
  TStockMoveDocumentLine extends StockMoveDocumentLine,
  TStockMoveDocumentLineService extends StockMoveDocumentLinesService<StockMoveDocumentLine>
> {
  @Input() public element: TStockMoveDocument = <TStockMoveDocument>getNew<TStockMoveDocument>();
  @Output() public elementChange: EventEmitter<TStockMoveDocument> = new EventEmitter<TStockMoveDocument>();

  @Input() public disabled: boolean = false;
  @Input() public reportType: string = '';
  @Input() public subModuleCode: string = '';

  constructor(
    public configurationsService: ConfigurationsService
  ) { }

  public getDeadline() {
   switch (typeof this.element.Deadline) {
      case "string":
        return this.element.Date;
      case "object":
        return this.element.Deadline != undefined && this.element.Deadline.getFullYear() > 1 ? this.element.Deadline : undefined;
    }
    return undefined;
  }
  public onChangeDate(event: any) {
    if (!this.element.Deadline ) return;
    if (this.element.Date.getTime() > this.element.Deadline.getTime()) {
      this.element.Deadline = event
    }
  }

  public onChangeDeadLine(event: any) {
    if (!this.element.Deadline) return;
    if (event && this.element.Date.getTime() >= event.getTime()) {
      this.element.Deadline = this.element.Date
    }
    else {
      this.element.Deadline = event
    }
  }
}
