import { Component, Input } from '@angular/core';
import { Supplier } from '../supplier.model';
import { BaseGridComponent } from 'projects/libraries/syslink-components/src/lib/grid/base-grid/base-grid.component';
import { SyslinkColumn } from 'projects/libraries/syslink-components/src/lib/helpers/SyslinkColumn';
import { BooleanCellData } from 'projects/libraries/syslink-components/src/lib/grid/cells/boolean-cell/boolean-cell.component';
import { SuppliersService } from '../suppliers.service';

@Component({
  selector: 'app-supplier-grid',
  templateUrl: './supplier-grid.component.html',
  styleUrls: ['./supplier-grid.component.scss']
})
export class SupplierGridComponent extends BaseGridComponent<Supplier> {
  override storageKey?: string | undefined = "suppliers-grid";

  @Input() public columns: SyslinkColumn[] = [
    new SyslinkColumn({ field: 'ThirdId.No', label: 'Id', width: 80, sortIndex: 1, sortDirection: 'desc' }),
    new SyslinkColumn({ field: 'ThirdId.Fullname', label: 'fullname' }),
    new SyslinkColumn({ field: 'ThirdId.DisplayAddress', label: 'Adresse' }),
    new SyslinkColumn({ field: 'ThirdId.DisplayPhoneNumber', label: 'Phone number', width: 200 }),
    new SyslinkColumn({ field: 'ThirdId.DisplayEmail', label: 'Professional email', width: 200 }),
    new SyslinkColumn({ field: 'ThirdId.VatNumber', label: 'Vat number', width: 120 }),
    new SyslinkColumn({ field: 'ThirdId.Alias', label: 'Alias' }),
    new SyslinkColumn({ field: 'ThirdId.Blocked', label: 'Blocked', type: 'boolean', cellTemplate: 'boolean-cell', data: new BooleanCellData({ type: 'box' }), width: 100 }),
    new SyslinkColumn({ field: 'ThirdId.Id', technical: true }),
  ];

  constructor(
    public suppliersService: SuppliersService,

  ) {
    super();
  }
  public override expand?: string | string[] =['ThirdId'];
  public override select?: string | string[] = [ ...this.columns.map((c: SyslinkColumn) => c.field)]
  public override filter: string | string[] = ["ThirdId.HasSupplierRole eq true"];

}
