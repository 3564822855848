import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Timetable } from '../timetable';
import { ViewComponent } from 'projects/libraries/syslink-components/src/lib/helpers/view/view.component';
import { TimetableLineModalComponent } from '../../timetable-lines/timetable-line-modal/timetable-line-modal.component';
import { TimetableLinesService } from '../../timetable-lines/timetable-lines.service';
import { TimetableLineGridComponent } from '../../timetable-lines/timetable-line-grid/timetable-line-grid.component';
import { NotificationsService } from 'projects/libraries/syslink-components/src/public-api';

@Component({
  selector: 'app-timetable',
  templateUrl: './timetable.component.html',
  styleUrls: ['./timetable.component.scss']
})
export class TimetableComponent extends ViewComponent {

  @Input() element: Timetable = new Timetable();
  @Output() elementChange: EventEmitter<Timetable> = new EventEmitter<Timetable>();

  @ViewChild('modal') modal?: TimetableLineModalComponent;
  @ViewChild('grid') grid?: TimetableLineGridComponent;

  constructor(
    private timetableLinesService: TimetableLinesService
  ) {
    super();
  }

  public async beforeAdd(e: any) {
    if (!this.modal || !this.element || !this.element.Id) return;
    this.modal.open();
    this.modal.modal.data = await this.timetableLinesService.getInstance({
      TimetableId: this.element
    });
  }

  public async onCreateValidateButtonClicked(e: any) {
    await this.timetableLinesService.insert(this.timetableLinesService.format(e));
    this.modal?.close();
    this.grid?.refresh();
    NotificationsService.sendSuccess("Element created");
  }
}
