import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ViewComponent } from '../../../../../../libraries/syslink-components/src/lib/helpers/view/view.component';
import { Third } from '../third.model';
import { ThirdTitlesService } from '../../third-titles/third-titles.service';

@Component({
  selector: 'app-third-private-form',
  templateUrl: './third-private-form.component.html',
  styleUrls: ['./third-private-form.component.scss']
})
export class ThirdPrivateFormComponent extends ViewComponent {
  @Input() public element: Third = new Third();
  @Input() public disabled: boolean = false;
  @Output() public elementChange: EventEmitter<Third> = new EventEmitter<Third>();

  constructor(
    public thirdTitlesService: ThirdTitlesService
  ) {
    super();
  }
}
