import { Entity } from "../../core/models/entity";

export class Brand extends Entity {
    public Code?:string;
    public Name?:string;
    constructor(entity?: Partial<Brand>) {
        super(entity);
        Object.assign(this, entity);
    }
}
