import { Component, Input, OnInit } from '@angular/core';
import { DXCell } from '../dxcell';

@Component({
  selector: 'syslink-badge-list-cell',
  templateUrl: './badge-list-cell.component.html',
  styleUrls: ['./badge-list-cell.component.scss']
})
export class BadgeListCellComponent implements OnInit {
  @Input() cell: DXCell = new DXCell();
  @Input() data: BadgeListCellData = new BadgeListCellData();

  @Input() items: any[] = [];
  ngOnInit(): void {
    this.items = this.cell.value.map((e: any) => {
      return {
        "text": this.format(e[this.data.contentKey] || ""),
        "backgroundColor": e[this.data.backgroundColorKey] || "white",
        "foregroundColor": e[this.data.foregroundColorKey] || "black",
        "title": e[this.data.contentKey] || ""
      }
    })
  }


  private format(input: string) {
    const words = input.split(' ');
    const initials = words.map(word => word.charAt(0).toUpperCase()).join('');
    return initials;
  }
}

export class BadgeListCellData {
  contentKey: string = "Name"
  backgroundColorKey: string = "BackgroundColor";
  foregroundColorKey: string = "ForegroundColor";

  constructor(entity?: Partial<BadgeListCellData>) {
    Object.assign(this, entity);
  }
}
