import { Injectable } from '@angular/core';
import { ODataService } from '../../../core/services/oData.service';
import { TimetableLine } from './timetable-line';
import { LoadOptions } from 'devextreme/data';
import { NotificationsService } from 'projects/libraries/syslink-components/src/public-api';

@Injectable({
  providedIn: 'root'
})
export class TimetableLinesService extends ODataService<TimetableLine> {
  public override url: string = "TimetableLine";
  public override defaultOptions: LoadOptions = {
    expand: []
  };

  // TODO : #4395 Problème si trop de record et que les données sont paginées
  public override onLoaded: (result: any[], loadOptions: LoadOptions<any>) => void = (result, loadOptions) => {
    if (loadOptions.sort !== 'Day' && (!Array.isArray(loadOptions.sort) || !loadOptions.sort.some(s => (s as any).selector === 'Day'))) return;

    result.sort((a: any, b: any) => {
      if (a.Day === 1 && b.Day !== 1) {
        return 1;
      } else if (a.Day !== 1 && b.Day === 1) {
        return -1;
      } else {
        return a.Day - b.Day;
      }
    });

    if (Array.isArray(loadOptions.sort) && (loadOptions.sort.find(s => (s as any).selector === 'Day') as any).desc) {
      result.reverse();
    }
  }

  public override format(element: Partial<TimetableLine>): TimetableLine {
    var result: any = {
      ...element,
      TimetableId: element.TimetableId?.Id
    };
    delete result.FormattedName;
    let date: Date = result.Date;
    if (!date || date.getFullYear().toString() == "1") {
      delete result.date;
    }
    return result;
  }

  public override async getInstance(params?: Partial<TimetableLine> | undefined): Promise<any> {
    var result = new TimetableLine({
      Date: undefined,
      ...params
    });

    return result;
  }

  public isValid(element: TimetableLine): boolean {
    if (element.Day == undefined && element.Date == undefined) {
      NotificationsService.sendErrorMessage("Please select a day");
      return false;
    }

    if (element.Date != undefined && element.Day != undefined && element.Day != 0 && element.Date.getFullYear() > 1) {
      NotificationsService.sendErrorMessage("The date and day cannot be filled in at the same time");
      return false;
    }

    if (element.StartHour && element.EndHour && element.StartHour.getTime() > element.EndHour.getTime()) {
      NotificationsService.sendErrorMessage("Start date must be before end Date");
      return false;
    }

    if ((element.StartHour && (element.StartHour.getMinutes() % 15) != 0) || (element.EndHour && (element.EndHour.getMinutes() % 15) != 0)) {
      NotificationsService.sendErrorMessage("The hours must have a 15-minute increment.");
      return false;
    }

    return true;
  }

}
