import { Injectable } from '@angular/core';
import { ODataService } from '../../../core/services/oData.service';
import { PurchaseDocumentStatus } from './purchase-document-status.model';

@Injectable({
  providedIn: 'root'
})
export class PurchaseDocumentStatusesService<TPurchaseDocumentStatus extends PurchaseDocumentStatus> extends ODataService<TPurchaseDocumentStatus> {
  public url: string = 'PurchaseDocumentHeaderStatus';
 
  constructor() {
    super();
  }
}
