import { Component, Input, Output, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DxPopupComponent } from 'devextreme-angular';
import { PositionConfig } from 'devextreme/animation/position';
import { ToolbarItem } from 'devextreme/ui/popup';

@Component({
  selector: 'syslink-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {
  @Input() public title: string = '';

  private _validateText: string = 'Validate';
  @Input() set validateText(value: string) {
    this._validateText = value;
    this.updateToolbarItems();
  }
  private _secondaryText: string = 'Secondary';
  @Input() set secondaryText(value: string) {
    this._secondaryText = value;
    this.updateToolbarItems();
  }
  private _cancelText: string = 'Cancel';
  @Input() set cancelText(value: string) {
    this._cancelText = value;
    this.updateToolbarItems();
  }

  @Input() public width: string = '50vw';
  @Input() public height: string = 'auto';
  @Input() public maxHeight: string = '90vh';
  @Input() public noFooter: boolean = false;
  @Input() public shading: string = 'rgba(0, 0, 0, 0.7)';

  @Input() public closeBtn: boolean = true;
  @Input() public hideOnOutside: boolean = false;
  @Input() public drag: boolean = true;
  @Input() public fullScreen: boolean = false;
  @Input() public position: string | PositionConfig | Function = '';
  @Input() public resize: boolean = false;
  @Input() public deferRendering: boolean = true;

  @Input() public data: any = {};

  @Output() public onValidate: EventEmitter<any> = new EventEmitter<any>()
  @Output() public onCancel: EventEmitter<any> = new EventEmitter<any>()
  @Output() public onSecondary: EventEmitter<any> = new EventEmitter<any>()

  public visible: boolean = false;

  @Input() public secondaryButton: boolean = false;

  public toolbarItems: Array<ToolbarItem> = [];

  constructor(
    private translateService?: TranslateService
  ) { }

  ngOnInit(): void {
    this.updateToolbarItems();
  }

  public onValidateBtnClicked() {
    if (this.onValidate.observed) {
      this.onValidate.emit(this.data);
    } else {
      this.close();
    }
  }

  public onSecondaryBtnClicked() {
    if (this.onSecondary.observed) {
      this.onSecondary.emit(this.data);
    } else {
      this.close();
    }
  }

  public onCancelBtnClicked() {
    if (this.onCancel.observed) {
      this.onCancel.emit(this.data);
    } else {
      this.close();
    }
  }

  private updateToolbarItems(): void {

    if (this.noFooter) return;

    this.toolbarItems = [
      {
        widget: 'dxButton',
        toolbar: 'bottom',
        location: 'after',
        options: {
          elementAttr: { id: 'modal-validate' },
          text: this.translateService?.instant(this._validateText),
          type: 'default',
          onClick: this.onValidateBtnClicked.bind(this)
        }
      },
      {
        widget: 'dxButton',
        toolbar: 'bottom',
        location: 'before',
        options: {
          elementAttr: { id: 'modal-cancel' },
          text: this.translateService?.instant(this._cancelText),
          onClick: this.onCancelBtnClicked.bind(this)
        }
      }
    ];

    if (this.secondaryButton) {
      this.toolbarItems.push({
        widget: 'dxButton',
        toolbar: 'bottom',
        location: 'center',
        options: {
          elementAttr: { id: 'modal-secondary' },
          text: this.translateService?.instant(this._secondaryText),
          onClick: this.onSecondaryBtnClicked.bind(this)
        }
      });
    }
  }

  public toggle(data?: any) {
    if (this.visible) {
      this.close();
    } else {
      this.open(data);
    }
  }

  public open(data?: any) {
    this.data = data || {};
    this.visible = true;
  }

  public close() {
    this.visible = false;
  }
}
