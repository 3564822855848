import { Injectable } from '@angular/core';
import { ODataService } from 'projects/erp-app/src/app/core/services/oData.service';
import { ThirdContactType } from './third-contact-type.model';
import { LoadOptions } from 'devextreme/data';

@Injectable({
  providedIn: 'root'
})
export class ThirdContactTypesService extends ODataService<ThirdContactType> {
  public url = 'ContactType';
  public override defaultOptions: LoadOptions = {
    expand: []
  };
}