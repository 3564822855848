<syslink-title text="Products"></syslink-title>
<app-product-grid
    #productGrid
    [expand]="expand"[permissionKey]="'list'"
    [canRefresh]="canRefresh"
    (add)="addNewProduct()"
    (rowDoubleClicked)="onDoubleClicked($event)"
    [toolbarActions]="toolbarItems"
></app-product-grid>


<syslink-modal
    #GoodsArrivalModal
    [title]="'Goods arrival'"
    width="550px"
    (onValidate)="onGoodsArrivalValidated()"
    validateText="Validate"
    cancelText="Cancel"
>
    <syslink-select
        label="Reference"
        [store]="productsService.store"
        [(model)]="goodsArrivalModalData.productId"
        (modelChange)="onGoodsArrivalProductSelected($event)"
        [select]="['Id', 'InternalReference']"
        [filter]="['Stockable eq true']"
        valueKey="Id"
        displayKey="InternalReference"
        [allowClearing]="true"
        [canAdd]="true"
        [canRefresh]="true"
    ></syslink-select>

    <ng-container *ngIf="goodsArrivalModalData.product">
        <syslink-input-text
            label="Name"
            [model]="goodsArrivalModalData.product.Name"
            [disabled]="true"
        ></syslink-input-text>

        <div class="my-2 row d-flex align-items-center">
            <div class="col-4">
                <label>{{'Purchase price excl. VAT' | translate}}</label>
            </div>
            <div class="col-5 offset-3">
                <div class="product-header-price-box">
                    <div class="border py-2 h6 text-gray text-center m-0">{{goodsArrivalModalData.product.DefaultSupplierCatalogId?.ExTaxNetPrice}} {{goodsArrivalModalData.product.DefaultSupplierCatalogId?.CurrencyId?.Symbol}}</div>
                </div>
            </div>
        </div>

        <div class="my-2 row d-flex align-items-center">
            <div class="col-4">
                <label>{{'Selling price excl. VAT' | translate}}</label>
            </div>
            <div class="col-5 offset-3">
                <div class="product-header-price-box">
                    <div class="py-2 h6 text-white text-center bg-primary m-0">{{goodsArrivalModalData.product.SalePrice}} {{goodsArrivalModalData.product.CurrencyId.Symbol}}</div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-6">
                <syslink-input-number
                    label="Actual stock"
                    [model]="goodsArrivalModalData.product.Stock"
                    [disabled]="true"
                ></syslink-input-number>
            </div>
            <div class="col-6">
                <syslink-input-number
                    label="Quantity to add"
                    [(model)]="goodsArrivalModalData.stockQuantityToAdd"
                    [min]="0"
                ></syslink-input-number>
            </div>
        </div>
    </ng-container>


    <!-- <syslink-select
        *ngIf="!saleQuoteHeader.NumberPlate?.length"
        name="third"
        [label]="'Third' | translate"
        [store]="thirdsService.store"
        [filter]="customerFilter"
        [select]="['Fullname','Id','Blocked']"
        displayKey="Fullname"
        [(model)]="saleQuoteHeader.ThirdId"
        detailsUrl="/thirds/customers/"
        [canAdd]="true"
        [allowClearing]="true"
    ></syslink-select>
    <syslink-select
        *ngIf="saleQuoteHeader.NumberPlate?.length"
        name="third"
        [label]="'Third' | translate"
        [store]="NumberPlatesService.store"
        [(model)]="saleQuoteHeader.ThirdId"
        [filter]="['Value eq \'' + saleQuoteHeader.NumberPlate + '\'']"
        [expand]="['CustomerId.ThirdId']"
        [displayKey]="'CustomerId.ThirdId.Fullname'"
        [allowClearing]="true"
    ></syslink-select>
    <syslink-input-text
        name="subject"
        [label]="'Subject' | translate"
        [(model)]="saleQuoteHeader.Subject"
    ></syslink-input-text> -->

