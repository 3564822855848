<app-payment-grid
  *ngIf="showGrid"
  [permissionKey]="'finance.payment.list'"
  [items]="element"
  (itemsChange)="paymentsChange.emit()"
  [toolbaActions]="paymentToolbarActions"
  (rowDoubleClicked)="rowDoubleClicked($event)"
  (beforeDelete)="onPaymentDelete($event)"
  (afterUpdate)="onGridUpdatePayment($event.data)"
></app-payment-grid>

<app-payment-modal
  #paymentModal
  *ngIf="payment"
  [(payment)]="payment"
  [reportType]="reportType"
  [third]="document?.ThirdId"
  [amountRemaining]="document?.AmountRemaining"
  [filterPaymentType]="filterPaymentType"
  [refundablePayments]="refundablePayments"
  [documentNameFormatted]="document?.NameFormatted"
  (paymentChange)="paymentsChange.emit($event); payment=undefined"
></app-payment-modal>