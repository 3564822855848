import { Component, OnInit, ViewChild } from '@angular/core';
import { MailModalComponent } from 'projects/erp-app/src/app/mails/mail-modal/mail-modal.component';
import { SyslinkColumn } from 'projects/libraries/syslink-components/src/lib/helpers/SyslinkColumn';
import { ConfirmModalComponent, ModalComponent, NotificationsService, PageComponent, SelectComponent, SyslinkToolbarActionButton, SyslinkToolbarActionDropDownButton, SyslinkToolbarActionMenu, SyslinkToolbarFileButton } from 'projects/libraries/syslink-components/src/public-api';
import { DocumentStatusesComponent } from '../../document-statuses/document-statuses.component';
import { MailModel } from 'projects/erp-app/src/app/connectors/mailjet/mail.model';
import { MailTemplateType } from 'projects/erp-app/src/app/mails/mail-template-types/mail-template-type.model';
import { DocumentHeaderStatus } from '../../document-header-statuses/document-header-status.model';
import { ReportsService } from 'projects/erp-app/src/app/connectors/reports/reports.service';
import { DocumentDelay } from '../../document-delays/document-delay';
import { DocumentDatasService } from '../../document-datas/document-datas.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Document } from '../document.model';
import { DocumentsService } from '../documents.service';
import { Third } from 'projects/erp-app/src/app/thirds/thirds/third.model';
import { MailTemplateTypesService } from 'projects/erp-app/src/app/mails/mail-template-types/mail-template-types.service';
import { ThirdContactInformation } from 'projects/erp-app/src/app/thirds/thirds/third-contacts/third-contact-informations/third-contact-information.model';
import { DocumentLine } from '../../document-lines/document-line.model';
import { DocumentCopyModalComponent } from '../../document-copy-modal/document-copy-modal.component';

@Component({
  selector: 'app-document-details',
  templateUrl: './document-details.component.html',
  styleUrls: ['./document-details.component.scss']
})
export class DocumentDetailsComponent extends PageComponent implements OnInit {

  public documentUrlController: string = "";

  public element: any;
  public reportType: string = '';
  protected subModuleCode: string = '';
  protected defaultStatusCode: string = '';

  public selectedKey: number[] = [];
  public toDocument: string = "";
  public documentLinesColumns: SyslinkColumn[] = [];

  // ViewChild
  @ViewChild('deleteConfirm') deleteConfirm: ConfirmModalComponent = new ConfirmModalComponent();
  @ViewChild('emailModal') public emailModal?: MailModalComponent;
  @ViewChild('documentStatuses') documentStatuses: DocumentStatusesComponent = new DocumentStatusesComponent();
  @ViewChild('copyModal') public copyModal?: DocumentCopyModalComponent;
  @ViewChild('copyModalThirdSelect') public copyModalThirdSelect?: SelectComponent;

  public mailModel?: MailModel;
  public supplierFilter: string | string[] = ["Roles/any(r:r/Code eq 'supplier')"];
  public mailTemplateType: MailTemplateType = new MailTemplateType();
  public customerFilter: string | string[] = ["Roles/any(r:r/Code eq 'customer')"];
  public copyModalFilter: string | string[] = "";

  // Contract
  // --------
  public createFromContractFromDate: Date = new Date();
  public createFromContractToDate: Date = new Date();

  constructor(
    public ngxUiLoaderService: NgxUiLoaderService,
    public reportsService: ReportsService,
    public documentDatasService: DocumentDatasService,
    public documentService: DocumentsService,
    public mailTemplateTypesService: MailTemplateTypesService,
  ) {
    super();
  }

  protected loadDependencies(): void { }

  public canEditDocument(): boolean {
    return true;
  }

  public hasActiveStatus(statusCode: string): boolean {
    return this.element.Statuses?.find((status: DocumentHeaderStatus) => status.StatusId?.Code === statusCode) != undefined;
  }

  public async defaultInitFromParams(element: any) {
    if (this.activatedRoute.snapshot.queryParamMap.has('ThirdId')) {
      element = {
        ...element,
        ThirdId: { Id: this.activatedRoute.snapshot.queryParamMap.get('ThirdId') }
      };
    }
    if (this.activatedRoute.snapshot.queryParamMap.has('Subject')) {
      element = {
        ...element,
        Subject: this.activatedRoute.snapshot.queryParamMap.get('Subject')
      };
    }
    if (this.activatedRoute.snapshot.queryParamMap.has('NumberPlate')) {
      element = {
        ...element,
        NumberPlate: this.activatedRoute.snapshot.queryParamMap.get('NumberPlate')
      };
    }
    return element;
  }

  override ngOnInit(): void {
    super.ngOnInit();
  }
  public async refresh() {
    this.updateBreadCrumb(this.element.No);
    this.updateToolbar();
    this.initStatusBar();
    await this.updateDocumentData(this.element);
    this.ngxUiLoaderService.stop();
  }

  public updateToolbar() {
    this.toolbarActions = [];
    this.toolbarActions.push(new SyslinkToolbarActionButton({ code: 'save', icon: 'save', text: 'Save', onClick: async () => { await this.update() }, hotkey: 'control.s', visible: this.authService.hasPermission(this.basePermissionKey + '.update') }));
    if (!this.element.Id) return;

    this.toolbarActions.push(new SyslinkToolbarFileButton({ entityType: `${this.reportType}Header`, entityId: this.element.Id }));
    this.toolbarActions.push(new SyslinkToolbarActionButton({ code: 'delete', icon: 'trash', text: 'Delete', onClick: () => { this.onDeleteBtnClicked() }, visible: this.authService.hasPermission(this.basePermissionKey + '.delete') }));

    if (this.reportType != "SaleContract" && this.reportType != "PurchaseInvoice" && this.reportType != "PurchaseCreditNote") {
      this.toolbarActions.push(new SyslinkToolbarActionButton({ code: 'preview', icon: 'print', text: 'Preview', onClick: () => { this.onPreviewButtonClicked() }, hotkey: 'control.p', visible: this.authService.hasPermission(this.basePermissionKey + '.preview') }));
    }
    if (this.reportType != "SaleContract" && this.reportType != "PurchaseInvoice" && this.reportType != "PurchaseCreditNote" && !this.reportType.includes("Stock")) {
      this.toolbarActions.push(new SyslinkToolbarActionButton({ code: 'email', icon: 'email', text: 'Send mail', onClick: () => { this.onSendMailButtonClicked() }, visible: this.authService.hasPermission(this.basePermissionKey + '.sendMail') }));
    }
    if (this.reportType != "SaleContract") {

      this.toolbarActions.push(new SyslinkToolbarActionMenu({
        items: [
          {
            icon: 'fa-solid fa-copy', items: [
              {
                // Sales
                // -----
                icon: 'fa-solid fa-euro-sign', text: 'Sale', items: [
                  { text: 'Transform to Quote', onClick: () => { this.transform('Sale', 'SaleQuote'); }, visible: this.reportType !== 'SaleQuote' && this.authService.hasPermission(this.basePermissionKey + '.transformToSaleQuote') },
                  { text: 'Transform to Order', onClick: () => { this.transform('Sale', 'SaleOrder'); }, visible: this.reportType !== 'SaleOrder' && this.authService.hasPermission(this.basePermissionKey + '.transformToSaleOrder') },
                  { text: 'Transform to Invoice', onClick: () => { this.transform('Sale', 'SaleInvoice'); }, visible: this.reportType !== 'SaleInvoice' && this.authService.hasPermission(this.basePermissionKey + '.transformToSaleInvoice') },
                  { text: 'Transform to Credit note', onClick: () => { this.transform('Sale', 'SaleCreditNote'); }, visible: this.reportType !== 'SaleCreditNote' && this.authService.hasPermission(this.basePermissionKey + '.transformToSaleCreditNote') },
                  { text: 'Transform to Contract', onClick: () => { this.transform('Sale', 'SaleContract'); }, visible: this.reportType !== 'SaleContract' && this.authService.hasPermission(this.basePermissionKey + '.transformToSaleContract') }
                ]
              },
              {
                // Purchases
                // ---------
                icon: 'fa-solid fa-cart-shopping', text: 'Purchase', items: [
                  { text: 'Transform to Order', onClick: () => { this.transform('Purchase', 'PurchaseOrder'); }, visible: this.reportType !== 'PurchaseOrder' && this.authService.hasPermission(this.basePermissionKey + '.transformToPurchaseOrder') },
                  { text: 'Transform to Invoice', onClick: () => { this.transform('Purchase', 'PurchaseInvoice'); }, visible: this.reportType !== 'PurchaseInvoice' && this.authService.hasPermission(this.basePermissionKey + '.transformToPurchaseInvoice') },
                  { text: 'Transform to Credit note', onClick: () => { this.transform('Purchase', 'PurchaseCreditNote'); }, visible: this.reportType !== 'PurchaseCreditNote' && this.authService.hasPermission(this.basePermissionKey + '.transformToPurchaseCreditNote') },
                ]
              },
              {
                // Stocks
                // -----
                icon: 'fa-solid fa-warehouse', text: 'Stock', items: [
                  { text: 'Transform to Customerincomings', onClick: () => { this.transform('StockMove', 'StockMoveCustomerIncoming'); }, visible: this.reportType !== 'StockMoveCustomerIncoming' && this.authService.hasPermission(this.basePermissionKey + '.transformToStockMoveCustomerIncoming') },
                  { text: 'Transform to Customeroutgoings', onClick: () => { this.transform('StockMove', 'StockMoveCustomerOutgoing'); }, visible: this.reportType !== 'StockMoveCustomerOutgoing' && this.authService.hasPermission(this.basePermissionKey + '.transformToStockMoveCustomerOutgoing') },
                  { text: 'Transform to Supplieroutgoings', onClick: () => { this.transform('StockMove', 'StockMoveSupplierOutgoing'); }, visible: this.reportType !== 'StockMoveSupplierOutgoing' && this.authService.hasPermission(this.basePermissionKey + '.transformToStockMoveSupplierOutgoing') },
                  { text: 'Transform to Supplierincomings', onClick: () => { this.transform('StockMove', 'StockMoveSupplierIncoming'); }, visible: this.reportType !== 'StockMoveSupplierIncoming' && this.authService.hasPermission(this.basePermissionKey + '.transformToStockMoveSupplierIncoming') },
                  { text: 'Transform to StockMoveInternal', onClick: () => { this.transform('StockMove', 'StockMoveInternal'); }, visible: this.reportType !== 'StockMoveInternal' && this.authService.hasPermission(this.basePermissionKey + '.transformToStockMoveInternal') },
                ]
              },
              {
                // Copy
                // ----
                beginGroup: true, text: 'Copy', visible: this.authService.hasPermission(this.basePermissionKey + '.copy'), onClick: () => {
                  this.reportType.includes("Sale") || this.reportType.includes("Customer") ? this.updatecopyModalFilter("customer") : this.updatecopyModalFilter("supplier");
                  if (!this.copyModal) return;
                  this.copyModal.open({
                    subject: this.element.Subject,
                    showThird: this.reportType.includes("StockMoveInternal") ? false : true,
                    showSubject: this.reportType.includes("Stock") ? false : true,
                    title: "Copy",
                    thirdFilter: this.copyModalFilter,
                    selectedKey: this.selectedKey
                  });
                }
              },
              {
                // New version
                // -----------
                text: 'New version', visible: this.authService.hasPermission(this.basePermissionKey + '.transformTo' + this.reportType), onClick: () => {
                  let mainReportType;
                  let subReportType;

                  if (this.reportType.includes('Sale')) { mainReportType = 'Sale'; }
                  else if (this.reportType.includes('Purchase')) { mainReportType = 'Purchase'; }
                  else if (this.reportType.includes('StockMove')) { mainReportType = 'StockMove'; }
                  else { return; }
                  subReportType = this.reportType.replace(mainReportType, '');

                  this.transform(mainReportType, mainReportType + subReportType);
                }
              }
            ]
          }
        ]
      }));
    }
  }

  public async updateDocumentData(e: any) { }

  public initStatusBar() { }


  public async update(showMessage: boolean = true) { }
  // delete
  // --------------
  public onDeleteBtnClicked() {
    this.deleteConfirm.open();
  }
  public async onPreviewButtonClicked() {
    if (!this.element.Id) return;
    await this.update();
    this.reportsService.preview(this.element.Id, this.reportType.includes("StockMove") && this.reportType != "StockMoveInternal" ? "StockMove" : this.reportType, this.element.No + ".pdf",this.reportType);
  }

  // Document Delay
  // --------------
  public async onDocumentDelayChanged(delay: DocumentDelay) {
    if (!this.authService.hasPermission(this.basePermissionKey + '.update')) {
      NotificationsService.sendErrorMessage("You do not have the required permission!");
      return;
    }
    await this.updateDocumentDelay(delay);
  }
  public async updateDocumentDelay(delay: DocumentDelay) { }
  //-------------------------------------------------------------------------
  // Global discount
  // ---------------
  public async onGlobalDiscountChanged() {
    await this.update();
  }
  //-------------------------------------------------------------------------

  // Global discount
  // ---------------
  public async saveDocumentData() {
    if (!this.element.DocumentDataCollection) return;
    for (const element of this.element.DocumentDataCollection) {
      if (!element.Id) {
        await this.documentDatasService.insert(this.documentDatasService.format(element));
      }
      else {
        await this.documentDatasService.update(element.Id, this.documentDatasService.format(element));
      }
    }
  }
  //-------------------------------------------------------------------------

  // Prices
  // ------
  public updatePrices(document: Document) {
    this.element.DocumentFinances = document.DocumentFinances;
    this.element.ExTaxTotal = document.ExTaxTotal;
    this.element.InTaxTotal = document.InTaxTotal;
    this.element.AmountReceived = document.AmountReceived;
    this.element.AmountRemaining = document.AmountRemaining;
  }
  //-------------------------------------------------------------------------

  // Mail
  // ----
  public async onSendMailButtonClicked() {
    if (this.mailModel == undefined) {
      this.mailModel = new MailModel();

      switch (this.reportType) {
        case "SaleQuote":
          this.mailTemplateType = (await this.mailTemplateTypesService.load({ filter: ["Code eq 'SaleQuote'"] }))[0];
          this.mailModel.ToThirdContactInformationId.push(...this.element.ThirdId.ContactInformations.filter((c: ThirdContactInformation) => c.ContactTypeId?.Code == 'email' && c.UsedForQuote == true));
          break;
        case "SaleOrder":
          this.mailTemplateType = (await this.mailTemplateTypesService.load({ filter: ["Code eq 'SaleOrder'"] }))[0];
          this.mailModel.ToThirdContactInformationId.push(...this.element.ThirdId.ContactInformations.filter((c: ThirdContactInformation) => c.ContactTypeId?.Code == 'email' && c.UsedForQuote == true));
          break;
        case "SaleInvoice":
          this.mailTemplateType = (await this.mailTemplateTypesService.load({ filter: ["Code eq 'SaleInvoice'"] }))[0];
          this.mailModel.ToThirdContactInformationId.push(...this.element.ThirdId.ContactInformations.filter((c: ThirdContactInformation) => c.ContactTypeId?.Code == 'email' && c.UsedForInvoice == true));
          break;
        case "SaleCreditNote":
          this.mailTemplateType = (await this.mailTemplateTypesService.load({ filter: ["Code eq 'SaleCreditNote'"] }))[0];
          this.mailModel.ToThirdContactInformationId.push(...this.element.ThirdId.ContactInformations.filter((c: ThirdContactInformation) => c.ContactTypeId?.Code == 'email' && c.UsedForInvoice == true));
          break;
        case "PurchaseOrder":
          this.mailTemplateType = (await this.mailTemplateTypesService.load({ filter: ["Code eq 'PurchaseOrder'"] }))[0];
          this.mailModel.ToThirdContactInformationId.push(...this.element.ThirdId.ContactInformations.filter((c: ThirdContactInformation) => c.ContactTypeId?.Code == 'email' && c.UsedForQuote == true));
          break;
        default:
          this.mailModel.ToThirdContactInformationId.push(...this.element.ThirdId.ContactInformations.filter((c: ThirdContactInformation) => c.ContactTypeId?.Code == 'email'));
          break;
      }

      this.mailModel.ReportType = this.reportType;
      this.mailModel.HeaderId = this.element.Id?.toString() ?? "0";
      this.mailModel.FileName = this.reportsService.updateFileName(this.reportType, this.element.No + ".pdf");
      if (!this.emailModal) return;
      this.emailModal.mailTemplateType = this.mailTemplateType;
      await this.emailModal.loadMailTemplateData();
    }
    await this.emailModal?.open();
  }
  //-------------------------------------------------------------------------

  // SelectedKey
  // ---------
  public onSelectedKeyChange(selectedKeys: number[]) {
    this.selectedKey = selectedKeys;
  }

  //-------------------------------------------------------------------------
  // public async onPrintButtonClicked() {
  //   if (!this.element.Id) return;
  //   await this.update();
  //   await this.reportsService.print(this.element.Id, this.reportType, this.element.No + ".pdf");
  // }

  // Transform
  // ---------
  public transform(type: string, totype: string) {
    if (!this.copyModal) return;
    // Transform
    // ---------
    if ((this.reportType.includes("Sale") && type == "Sale") || (this.reportType.includes("Purchase") && type == "Purchase")) {
      this.documentTransform(totype);
      return;
    }
    if ((this.reportType.includes("StockMove") && type == "StockMove" && totype.includes("Customer") && this.element.ThirdId.HasCustomerRole) || (this.reportType.includes("StockMove") && type == "StockMove" && totype.includes("Supplier") && this.element.ThirdId.HasSupplierRole)) {
      this.documentTransform(totype);
      return;
    }
    else if ((this.reportType.includes("StockMove") && type == "StockMove" && !this.element.ThirdId.HasCustomerRole)) {
      this.updatecopyModalFilter("customer");
      if (this.element.Lines.filter((l: DocumentLine) => l.LineType == "product" && l.ProductId && l.ProductId.Sellable == false).length > 0) {
        NotificationsService.sendInfo("Some lines may not be sellable");
      }
      this.documentTransform(totype, true);
      return;
    }
    else if ((this.reportType.includes("StockMove") && type == "StockMove" && !this.element.ThirdId.HasSupplierRole)) {
      this.updatecopyModalFilter("supplier");
      if (this.element.Lines.filter((l: DocumentLine) => l.LineType == "product" && l.ProductId && l.ProductId.Purchasable == false).length > 0) {
        NotificationsService.sendInfo("Some lines may not be sellable");
      }
      this.documentTransform(totype, true);
      return;
    }
    else if (this.reportType.includes("StockMove") && totype == "StockMoveInternal") {
      this.documentTransform(totype, false);
      return;
    }

    // Convert
    // -------
    if ((this.reportType.includes("Sale") && type == "Purchase")) {
      this.updatecopyModalFilter("supplier");
      if (this.element.Lines.filter((l: DocumentLine) => l.LineType == "product" && l.ProductId && l.ProductId.Purchasable == false).length > 0) {
        NotificationsService.sendInfo("Some lines may not be purchasable");
      }
    }
    else if ((this.reportType.includes("Purchase") && type == "Sale")) {
      this.updatecopyModalFilter("customer");
      if (this.element.Lines.filter((l: DocumentLine) => l.LineType == "product" && l.ProductId && l.ProductId.Sellable == false).length > 0) {
        NotificationsService.sendInfo("Some lines may not be sellable");
      }
    }
    else if ((this.reportType.includes("StockMove") && type == "Purchase")) {
      this.updatecopyModalFilter("supplier");
      if (this.element.Lines.filter((l: DocumentLine) => l.LineType == "product" && l.ProductId && l.ProductId.Purchasable == false).length > 0) {
        NotificationsService.sendInfo("Some lines may not be purchasable");
      }
    }
    else if ((this.reportType.includes("StockMove") && type == "Sale")) {
      this.updatecopyModalFilter("customer");
      if (this.element.Lines.filter((l: DocumentLine) => l.LineType == "product" && l.ProductId && l.ProductId.Sellable == false).length > 0) {
        NotificationsService.sendInfo("Some lines may not be sellable");
      }
    }
    else if ((this.reportType.includes("Sale") && totype.includes("Supplier"))) {
      this.updatecopyModalFilter("supplier");
    }
    else if ((this.reportType.includes("Purchase") && totype.includes("Customer"))) {
      this.updatecopyModalFilter("customer");
    }

    if ((totype.includes("StockMove"))) {
      if (this.element.Lines.filter((l: DocumentLine) => l.LineType == "product" && l.ProductId && l.ProductId.Stockable == false).length > 0) {
        NotificationsService.sendInfo("Some lines may not be stockable");
      }
    }

    if (!this.copyModal) return;
    this.copyModal.open({
      subject: this.element.Subject,
      type: type,
      toType: totype,
      showThird: totype.includes("StockMoveInternal") || ((totype.includes("Customer") && this.reportType.includes("Sale")) || (totype.includes("Supplier") && this.reportType.includes("Purchase"))) ? false : true,
      showSubject: totype.includes("StockMove") ? false : true,
      title: "Copy",
      thirdFilter: this.copyModalFilter,
      selectedKey: this.selectedKey
    })
  }

  public updatecopyModalFilter(role: string = "customer" || "supplier") {
    if (role == "customer") {
      this.copyModalFilter = this.customerFilter;
    }
    if (role == "supplier") {
      this.copyModalFilter = this.supplierFilter;
    }
    this.copyModalThirdSelect?.forceReload();
  }

  public documentTransform(toType: string, showThird: boolean = false) {
    if (this.selectedKey.length != 0 || showThird) {
      this.copyModal?.open({
        toDocument: toType,
        showThird: showThird,
        title: "Transform",
        thirdFilter: this.copyModalFilter,
        selectedKey: this.selectedKey
      });
      return;
    }
    this.swithToTransformModel(toType, undefined);
  }

  public swithToTransformModel(toType: string, selectedKey: number[] | undefined, third: Third | undefined = undefined) {
    switch (toType) {
      case "SaleQuote":
        this.onTransformTo('Quote', '../../quotes/', false, "", selectedKey);
        break;
      case "SaleOrder":
        this.onTransformTo('Order', '../../orders/', false, "", selectedKey);
        break;
      case "SaleInvoice":
        this.onTransformTo('Invoice', '../../invoices/', false, "", selectedKey);
        break;
      case "SaleCreditNote":
        this.onTransformTo('CreditNote', '../../credit-notes/', false, "", selectedKey);
        break;
      case "SaleContract":
        this.onTransformTo('Contract', '../../contracts/', false, "", selectedKey);
        break;
      case "PurchaseOrder":
        this.onTransformTo('Order', '../../orders/', false, "", selectedKey);
        break;
      case "PurchaseInvoice":
        this.onTransformTo('Invoice', '../../invoices/', false, "", selectedKey);
        break;
      case "PurchaseCreditNote":
        this.onTransformTo('CreditNote', '../../credit-notes/', false, "", selectedKey);
        break;
      case "StockMoveCustomerIncoming":
        this.onTransformTo('StockMoveIncoming', '../../customerincomings/', false, "customer", selectedKey, third);
        break;
      case "StockMoveCustomerOutgoing":
        this.onTransformTo('StockMoveOutgoing', '../../customeroutgoings/', false, "customer", selectedKey, third);
        break;
      case "StockMoveSupplierOutgoing":
        this.onTransformTo('StockMoveOutgoing', '../../supplieroutgoings/', false, "supplier", selectedKey, third);
        break;
      case "StockMoveSupplierIncoming":
        this.onTransformTo('StockMoveIncoming', '../../supplierincomings/', false, "supplier", selectedKey, third);
        break;
      case "StockMoveInternal":
        this.onTransformTo('StockMoveInternal', '../../internalstockmoves/', false, "", selectedKey);
        break;
    }
  }

  public async onTransformTo(toType: string, toUrl: string, newTab: boolean = false, type: string = "", idList?: number[], third: Third | undefined = undefined, fromDate: Date = new Date(), toDate: Date = new Date()) {
    if (!this.element.Id) return;
    this.ngxUiLoaderService.start();
    let id = await this.documentService?.TransformToType(this.documentUrlController, toType, this.element.Id, type, idList, third?.Id, fromDate, toDate);
    this.goToUrl(toUrl + id, undefined, undefined, newTab);
    this.ngxUiLoaderService.stop();
  }

  //-------------------------------------------------------------------------
  // Copy
  // ----
  public async onCopyButtonClicked(e: any) {
    if (!this.element.Id) return;
    if (e.data.toDocument != null && e.data.toDocument != "") {
      if (((e.data.toDocument.includes('Supplier') && this.reportType.includes('Customer')) || (e.data.toDocument.includes('Customer') && this.reportType.includes('Supplier'))) && (!e.data.ThirdId || !e.data.ThirdId.Id)) {
        NotificationsService.sendErrorMessage("Third cannot be empty");
        return;
      }
      await this.swithToTransformModel(e.data.toDocument, e.data.selectedKey, e.data.ThirdId);
      return;
    }
    if ((e.data.toType && !e.data.toType.includes("StockMove")) && (!e.data.ThirdId || !e.data.ThirdId.Id)) {
      NotificationsService.sendErrorMessage("Third cannot be empty");
      return;
    }
    this.ngxUiLoaderService.start();

    if (e.data.type != null && e.data.type != "") {
      await this.convert(e.data, e.data.selectedKey);
    }
    else {
      await this.copy(e.data, e.data.selectedKey);
    }
    this.copyModal?.close();
    this.ngxUiLoaderService.stop();
  }

  public async copy(e: { subject: string, ThirdId: Third }, selectedKey?: number[]) {
    if (this.reportType != "StockMoveInternal" && (!e.ThirdId || !e.ThirdId.Id)) {
      NotificationsService.sendErrorMessage("Third cannot be empty");
      return;
    }
    if (this.reportType == "SaleQuote") {
      await this.onCopyTo('Quote', '../../quotes/', false, e.subject, e.ThirdId.Id ?? 0, selectedKey);
    }
    else if (this.reportType == "SaleOrder" || this.reportType == "PurchaseOrder") {
      await this.onCopyTo('Order', '../../orders/', false, e.subject, e.ThirdId.Id ?? 0, selectedKey);
    }
    else if (this.reportType == "SaleInvoice" || this.reportType == "PurchaseInvoice") {
      await this.onCopyTo('Invoice', '../../invoices/', false, e.subject, e.ThirdId.Id ?? 0, selectedKey, this.createFromContractFromDate, this.createFromContractToDate);
    }
    else if (this.reportType == "SaleCreditNote" || this.reportType == "PurchaseCreditNote") {
      await this.onCopyTo('CreditNote', '../../credit-notes/', false, e.subject, e.ThirdId.Id ?? 0, selectedKey);
    }
    else if (this.reportType == "SaleContract") {
      await this.onCopyTo('Contract', '../../contracts/', false, e.subject, e.ThirdId.Id ?? 0, selectedKey);
    }
    else if (this.reportType == "StockMoveSupplierIncoming") {
      await this.onCopyTo('StockMoveIncoming', '../../supplierincomings/', false, "", e.ThirdId.Id ?? 0, selectedKey);
    }
    else if (this.reportType == "StockMoveSupplierOutgoing") {
      await this.onCopyTo('StockMoveOutgoing', '../../supplieroutgoings/', false, "", e.ThirdId.Id ?? 0, selectedKey);
    }
    else if (this.reportType == "StockMoveCustomerOutgoing") {
      await this.onCopyTo('StockMoveOutgoing', '../../customeroutgoings/', false, "", e.ThirdId.Id ?? 0, selectedKey);
    }
    else if (this.reportType == "StockMoveCustomerIncoming") {
      await this.onCopyTo('StockMoveIncoming', '../../customerincomings/', false, "", e.ThirdId.Id ?? 0, selectedKey);
    }
    else if (this.reportType == "StockMoveInternal") {
      await this.onCopyTo('StockMoveInternal', '../../internalstockmoves/', false, "", 0, selectedKey);
    }
  }
  public async onCopyTo(toType: string, toUrl: string, newTab: boolean = false, subject: string, third: number, idList?: number[], fromDate: Date = new Date(), toDate: Date = new Date()) {
    if (!this.element.Id) return;
    this.ngxUiLoaderService.start();
    let id = await this.documentService?.CopyToType(this.documentUrlController, toType, this.element.Id, subject, third, idList, fromDate, toDate);
    this.goToUrl(toUrl + id, undefined, undefined, newTab);
    this.ngxUiLoaderService.stop();
  }
  //-------------------------------------------------------------------------

  public async convert(e: { subject: string, ThirdId: Third, type: string, toType: string }, selectedKey?: number[]) {

    if (e.toType == "SaleQuote" && e.type == 'Sale') {
      await this.onConvertTo('SaleQuote', '/sales/quotes/', true, e.subject, e.ThirdId.Id, selectedKey);
    }
    else if (e.toType == "SaleOrder" && e.type == 'Sale') {
      await this.onConvertTo('SaleOrder', '/sales/orders/', true, e.subject, e.ThirdId.Id, selectedKey);
    }
    else if (e.toType == "SaleInvoice" && e.type == 'Sale') {
      await this.onConvertTo('SaleInvoice', '/sales/invoices/', true, e.subject, e.ThirdId.Id, selectedKey);
    }
    else if (e.toType == "SaleCreditNote" && e.type == 'Sale') {
      await this.onConvertTo('SaleCreditNote', '/sales/credit-notes/', true, e.subject, e.ThirdId.Id, selectedKey);
    }
    else if (e.toType == "SaleContract" && e.type == 'Sale') {
      await this.onConvertTo('SaleContract', '/sales/contracts/', true, e.subject, e.ThirdId.Id, selectedKey);
    }
    else if (e.toType == "PurchaseOrder" && e.type == 'Purchase') {
      await this.onConvertTo('PurchaseOrder', '/purchases/orders/', true, e.subject, e.ThirdId.Id, selectedKey);
    }
    else if (e.toType == "PurchaseInvoice" && e.type == 'Purchase') {
      await this.onConvertTo('PurchaseInvoice', '/purchases/invoices/', true, e.subject, e.ThirdId.Id, selectedKey);
    }
    else if (e.toType == "PurchaseCreditNote" && e.type == 'Purchase') {
      await this.onConvertTo('PurchaseCreditNote', '/purchases/credit-notes/', true, e.subject, e.ThirdId.Id, selectedKey);
    }
    else if (e.toType == "StockMoveCustomerOutgoing" && e.type == 'StockMove') {
      await this.onConvertTo('StockMoveCustomerOutgoing', '/stockmoves/customeroutgoings/', true, e.subject, e.ThirdId?.Id, selectedKey);
    }
    else if (e.toType == "StockMoveCustomerIncoming" && e.type == 'StockMove') {
      await this.onConvertTo('StockMoveCustomerIncoming', '/stockmoves/customerincomings/', true, e.subject, e.ThirdId?.Id, selectedKey);
    }
    else if (e.toType == "StockMoveSupplierIncoming" && e.type == 'StockMove') {
      await this.onConvertTo('StockMoveSupplierIncoming', '/stockmoves/supplierincomings/', true, e.subject, e.ThirdId?.Id, selectedKey);
    }
    else if (e.toType == "StockMoveSupplierOutgoing" && e.type == 'StockMove') {
      await this.onConvertTo('StockMoveSupplierOutgoing', '/stockmoves/supplieroutgoings/', true, e.subject, e.ThirdId?.Id, selectedKey);
    }
    else if (e.toType == "StockMoveInternal" && e.type == 'StockMove') {
      await this.onConvertTo('StockMoveInternal', '/stockmoves/internalstockmoves/', true, e.subject, e.ThirdId?.Id, selectedKey);
    }
  }

  public async onConvertTo(toType: string, toUrl: string, newTab: boolean = false, subject: string, third: number = 0, idList?: number[], fromDate: Date = new Date(), toDate: Date = new Date()) {
    if (!this.element.Id) return;
    this.ngxUiLoaderService.start();
    let id = await this.documentService?.ConvertToType(this.documentUrlController, toType, this.element.Id, subject, third, idList, fromDate, toDate);
    this.goToUrl(toUrl + id, undefined, undefined, newTab);
    this.ngxUiLoaderService.stop();
  }
}