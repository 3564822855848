import { Injectable } from '@angular/core';
import { ODataService } from '../../../core/services/oData.service';
import { Timetable } from './timetable';

@Injectable({
  providedIn: 'root'
})
export class TimetablesService extends ODataService<Timetable> {
  public override url: string = "Timetable";
}
