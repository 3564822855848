import { Injectable } from '@angular/core';
import { LoadOptions } from 'devextreme/data';
import { ODataService } from '../../../core/services/oData.service';
import { PostalCode } from './postal-code';

@Injectable({
  providedIn: 'root'
})
export class PostalCodeService extends ODataService<PostalCode>{
  public url: string = "PostalCode";
  constructor() {
    super();
  }

  public override defaultOptions: LoadOptions<PostalCode> = {
    expand: [
      "Localities"
    ]
  }

  public override format(element: PostalCode): any {
    var result: any = {
      ...element,
    };
    return result;
  }

  public override async getInstance(params?: Partial<PostalCode>): Promise<PostalCode> {
    const result = new PostalCode({
      ...params
    })
    return result;
  }
}
