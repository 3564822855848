import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Task } from '../task.model';
import { WorkTypesService } from '../../../works/work-types/work-types.service';
import { ThirdsService } from 'projects/erp-app/src/app/thirds/thirds/thirds.service';
import { UserGroupsService } from 'projects/erp-app/src/app/core/auth/user-groups/user-groups.service';
import { ModalComponent, NotificationsService, TagboxComponent } from 'projects/libraries/syslink-components/src/public-api';
import { Router } from '@angular/router';
import { TasksService } from '../tasks.service';
import { UsersService } from 'projects/erp-app/src/app/core/auth/users/users.service';
import { TranslateService } from '@ngx-translate/core';
import { ViewComponent } from 'projects/libraries/syslink-components/src/lib/helpers/view/view.component';
import { WorkTimesService } from '../../../work-times/work-times/work-times.service';
import { WorkTimeModalComponent } from '../../../work-times/work-times/work-time-modal/work-time-modal.component';
import { WorkTime } from '../../../work-times/work-times/work-time';

@Component({
  selector: 'app-task-modal',
  templateUrl: './task-modal.component.html',
  styleUrls: ['./task-modal.component.scss']
})
export class TaskModalComponent extends ViewComponent {
  @Input() title: string = "Create new Task";
  @Output() public validate: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild("modal") modal?: ModalComponent;
  @ViewChild("usersTagbox") usersTagbox?: TagboxComponent;
  @ViewChild("userGroupsTagbox") userGroupsTagbox?: TagboxComponent;
  @ViewChild('worktimeModal') worktimeModal?: WorkTimeModalComponent;

  constructor(
    public WorkTypesService: WorkTypesService,
    public tasksService: TasksService,
    public thirdsService: ThirdsService,
    public userGroupsService: UserGroupsService,
    public translateService: TranslateService,
    public usersService : UsersService,
    protected override router: Router,
    private workTimesService: WorkTimesService,
  ) {
    super();
  }

  public open(task: Task) {
    this.modal?.open(task);
  }
  public close() {
    this.modal?.close();
  }


  async onValidate(e: any) {
    if (!this.authService.hasPermission('time-management.tasks.modals.update')) {
      NotificationsService.sendErrorMessage("You do not have the required permission!");
      return;
    }

    if (this.tasksService.validateElementInformations(this.modal?.data) == false) return;
    if (this.validate.observed) {
      this.validate.emit(e);
    }
    else {
      await this.tasksService.createOrUpdate(this.tasksService.format(this.modal?.data));
      await this.tasksService.updateRelations(this.modal?.data);
      this.modal?.close();
    }
  }

  onViewMoreClicked() {
    if (this.modal?.data.Id) {
      this.router.navigateByUrl('/time-management/tasks/' + this.modal?.data.Id);
    }
    else {
      this.tasksService.saveInstance(this.modal?.data);
      this.router.navigateByUrl('/time-management/tasks/new');
    }
  }

  // WorkTime
  // --------
  public async onAddWorkTimeButtonClicked() {
    if(!this.modal) return;
    this.worktimeModal?.open(await this.workTimesService.getInstance({
      ThirdId: this.modal.data.ThirdId,
      TaskId: this.modal.data,
    }));
  }

  public async onValidateWorkTimeCreation(e: WorkTime) {
    try {
      await this.tasksService.createOrUpdate(this.tasksService.format(this.modal?.data));
      await this.tasksService.updateRelations(this.modal?.data);

      if (!e.Id) {
        await this.workTimesService.insert(await this.workTimesService.format(e));
        NotificationsService.sendSuccess("Record created");
      }
      else {
        await this.workTimesService.update(e.Id, await this.workTimesService.format(e));
        NotificationsService.sendSuccess("Record created");
      }

      this.worktimeModal?.close();
    } catch (_error) { }
  }
}
