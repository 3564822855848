<div class="row">
    <div class="col-10">
        <syslink-input-text
            label="Product"
            [(model)]="searchInput"
            (onEnterKey)="onEnterKeyPressed($event)"
            (dblclick)="onMultipleResults('')"
        ></syslink-input-text>
    </div>
    <div class="col-2 d-flex align-items-center">
        <a
            class="btn btn-link p-0 ms-2 d-inline-block"
            (click)="resetDatasource()"
        >
            <i class="fa-solid fa-rotate-right"></i>
        </a>
        <a
            class="btn btn-link p-0 ms-2 d-inline-block"
            (click)="onShowAdvancedSearchButtonClicked($event)"
        >
            <i class="fas fa-magnifying-glass"></i>
        </a>
    </div>
</div>

<syslink-modal
    #productModal
    title="Add product"
    width="85%"
    height="80%"
    [deferRendering]="false"
    shading=""
    [resize]="true"
    validateText="Close"
>
    <app-readonly-product-grid
        #productGrid
        [expand]="expand"
        [permissionKey]="permissionKey"
        (rowDoubleClicked)="onRowDblClicked($event)"
        storageKey="grid-product-picker"
        (add)="onAddNewProductBtn.emit()"
        [filter]="filters"
    ></app-readonly-product-grid>
</syslink-modal>
