import { Injectable } from '@angular/core';
import { ODataService } from '../../../core/services/oData.service';
import { TranslatableString } from './translatable-string';
import { LoadOptions } from 'devextreme/data';

@Injectable({
  providedIn: 'root'
})
export class TranslatableStringsService extends ODataService<TranslatableString> {
  public url: string = 'TranslatableString';

  public async findByCode(code: string, options: LoadOptions = {}): Promise<TranslatableString | null> {
    const mainFilter = ["code", "=", code];
    if (options.filter instanceof Array && options.filter.length >= 0) {
      options.filter = [mainFilter, 'and', ...options.filter];
    } else {
      options.filter = mainFilter
    }

    let requestResult = await this.load({
      ...options
    });

    if (requestResult.length > 1) {
      throw `Multiple translatable string found with the given code.`;
    }

    return requestResult.length === 0 ? null : requestResult[0];
  }
}
