import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LanguagesService } from 'projects/erp-app/src/app/base/languages/languages.service';
import { User } from '../user.model';

@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss']
})
export class UserFormComponent {
  @Input() user: User = new User();
  @Output() userChange: EventEmitter<User> = new EventEmitter<User>();
  
  @Input() columnWidth: number = 3;
  @Input() disabled: boolean = false;
  @Input() hidePassword: boolean = false;

  constructor(
    public languagesService: LanguagesService
  ) { }
}
