import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ViewComponent } from 'projects/libraries/syslink-components/src/lib/helpers/view/view.component';
import { DocumentRelation } from '../../document-relations/document-relation.model';
import { SaleInvoiceGridComponent } from 'projects/erp-app/src/app/sales/sale-invoices/sale-invoice-grid/sale-invoice-grid.component';
import { SaleContractGridComponent } from 'projects/erp-app/src/app/sales/sale-contracts/sale-contract-grid/sale-contract-grid.component';
import { SaleQuoteGridComponent } from 'projects/erp-app/src/app/sales/sale-quotes/sale-quote-grid/sale-quote-grid.component';
import { SaleOrderGridComponent } from 'projects/erp-app/src/app/sales/sale-orders/sale-order-grid/sale-order-grid.component';
import { SaleCreditNoteGridComponent } from 'projects/erp-app/src/app/sales/sale-credit-notes/sale-credit-note-grid/sale-credit-note-grid.component';
import { PurchaseInvoiceGridComponent } from 'projects/erp-app/src/app/purchases/purchase-invoices/purchase-invoice-grid/purchase-invoice-grid.component';
import { PurchaseOrderGridComponent } from 'projects/erp-app/src/app/purchases/purchase-orders/purchase-order-grid/purchase-order-grid.component';
import { PurchaseCreditNoteGridComponent } from 'projects/erp-app/src/app/purchases/purchase-credit-notes/purchase-credit-note-grid/purchase-credit-note-grid.component';
import { StockMoveIncomingGridComponent } from 'projects/erp-app/src/app/stocks/stock-move-incomings/stock-move-incoming-grid/stock-move-incoming-grid.component';
import { StockMoveOutgoingGridComponent } from 'projects/erp-app/src/app/stocks/stock-move-outgoings/stock-move-outgoing-grid/stock-move-outgoing-grid.component';
import { StockMoveInternalGridComponent } from 'projects/erp-app/src/app/stocks/stock-move-internals/stock-move-internal-grid/stock-move-internal-grid.component';
import { TaskGridComponent } from 'projects/erp-app/src/app/time-management/tasks/tasks/task-grid/task-grid.component';
import { WorkTimeGridComponent } from 'projects/erp-app/src/app/time-management/work-times/work-times/work-time-grid/work-time-grid.component';
import { SaleInvoicesService } from 'projects/erp-app/src/app/sales/sale-invoices/sale-invoices/sale-invoices.service';
import { DocumentRelationsService } from '../../document-relations/document-relation.service';
import { ReportsService } from 'projects/erp-app/src/app/connectors/reports/reports.service';
import { SyslinkToolbarAction, SyslinkToolbarActionButton } from 'projects/libraries/syslink-components/src/public-api';

@Component({
  selector: 'app-document-relations',
  templateUrl: './document-relations.component.html',
  styleUrls: ['./document-relations.component.scss']
})
export class DocumentRelationsComponent extends ViewComponent {

  @Input() public documentId: number = 0;
  @Input() public service: any;
  @Input() public reportType: string = '';
  public _documentRelations: DocumentRelation[] = [];
  @Input() set documentRelations(value: DocumentRelation[]) {
    this._documentRelations = value;
    this.loadData();
    this.loadTimeManagement();
  }
  get documentRelations() {
    return this._documentRelations;
  }

  // Sale
  // ----
  @Input() public showSaleQuote: boolean = true;
  @Input() public showSaleOrder: boolean = true;
  @Input() public showSaleInvoice: boolean = true;
  @Input() public showSaleCreditNote: boolean = true;
  @Input() public showSaleContract: boolean = true;
  @Input() public addSaleQuote: Function = () => { };
  @Input() public addSaleOrder: Function = () => { };
  @Input() public addSaleInvoice: Function = () => { };
  @Input() public addSaleCreditNote: Function = () => { };
  @ViewChild('saleInvoiceGrid') public saleInvoiceGrid?: SaleInvoiceGridComponent;
  @ViewChild('saleContractGrid') public saleContractGrid?: SaleContractGridComponent;
  @ViewChild('saleQuoteGrid') public saleQuoteGrid?: SaleQuoteGridComponent;
  @ViewChild('saleOrderGrid') public saleOrderGrid?: SaleOrderGridComponent;
  @ViewChild('saleCreditNoteGrid') public saleCreditNoteGrid?: SaleCreditNoteGridComponent;
  public itemsSaleQuoteId?: number[] = undefined
  public itemsSaleOrderId?: number[] = undefined
  public itemsSaleInvoiceId?: number[] = undefined
  public itemsSaleCreditNoteId?: number[] = undefined
  public itemsSaleContractId?: number[] = undefined

  // Purchase
  // --------
  @Input() public showPurchaseOrder: boolean = true;
  @Input() public showPurchaseInvoice: boolean = true;
  @Input() public showPurchaseCreditNote: boolean = true;
  @Input() public addPurchaseOrder: Function = () => { };
  @Input() public addPurchaseInvoice: Function = () => { };
  @Input() public addPurchaseCreditNote: Function = () => { };
  @ViewChild('purchaseInvoiceGrid') public purchaseInvoiceGrid?: PurchaseInvoiceGridComponent;
  @ViewChild('purchaseOrderGrid') public purchaseOrderGrid?: PurchaseOrderGridComponent;
  @ViewChild('purchaseCreditNoteGrid') public purchaseCreditNoteGrid?: PurchaseCreditNoteGridComponent;
  public itemsPurchaseOrderId?: number[] = undefined
  public itemsPurchaseInvoiceId?: number[] = undefined
  public itemsPurchaseCreditNoteId?: number[] = undefined

  // TimeManagment
  // -------------
  @Input() public addTaskGrid: Function = () => { };
  @Input() public addWorkTimeGrid: Function = () => { };
  @ViewChild('taskGrid') public taskGrid?: TaskGridComponent;
  @ViewChild('workTimeGrid') public workTimeGrid?: WorkTimeGridComponent;

  public gridWorkTimeToolbarActions: SyslinkToolbarAction[] = [
    new SyslinkToolbarActionButton({
      icon: 'fa-solid fa-magnifying-glass',
      onClick: () => this.exportDocumentRelation(),
      location: 'before'
    })
  ];

  // Stock
  // -----
  @Input() public showStockMoveCustomerIncoming: boolean = true;
  @Input() public showStockMoveCustomerOutgoing: boolean = true;
  @Input() public showStockMoveSupplierIncoming: boolean = true;
  @Input() public showStockMoveSupplierOutgoing: boolean = true;
  @Input() public showStockMoveInternal: boolean = true;
  @Input() public addStockMoveCustomerIncoming: Function = () => { };
  @Input() public addStockMoveCustomerOutgoing: Function = () => { };
  @Input() public addStockMoveSupplierIncoming: Function = () => { };
  @Input() public addStockMoveSupplierOutgoing: Function = () => { };
  @Input() public addStockMoveInternal: Function = () => { };
  @ViewChild('stockMoveCustomerIncomingGrid') public stockMoveCustomerIncomingGrid?: StockMoveIncomingGridComponent;
  @ViewChild('stockMoveCustomerOutgoingGrid') public stockMoveCustomerOutgoingGrid?: StockMoveOutgoingGridComponent;
  @ViewChild('stockMoveSupplierIncomingGrid') public stockMoveSupplierIncomingGrid?: StockMoveIncomingGridComponent;
  @ViewChild('stockMoveSupplierOutgoingGrid') public stockMoveSupplierOutgoingGrid?: StockMoveIncomingGridComponent;
  @ViewChild('stockMoveInternalGrid') public stockMoveInternalGrid?: StockMoveInternalGridComponent;
  public itemsStockMoveCustomerIncoming?: number[] = undefined
  public itemsStockMoveCustomerOutgoing?: number[] = undefined
  public itemsStockMoveSupplierIncoming?: number[] = undefined
  public itemsStockMoveSupplierOutgoing?: number[] = undefined
  public itemsStockMoveInternal?: number[] = undefined
  public _type: string = '';
  // @Input() set type(value: string) {
  //   this._type = value;
  //   this.filterExtension = undefined;
  //   this.filterExtension = "and Type eq '" + this._type + "'";
  // }
  // get type() {
  //   return this._type;
  // }
  public filterCustomerExtension?: string = "and Type eq 'customer'";
  public filterSupplierExtension?: string = "and Type eq 'supplier'";

  @Output() public onRefreshClicked: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private reportsService: ReportsService,
    public saleInvoicesService: SaleInvoicesService,
    public documentRelationsService: DocumentRelationsService
  ) {
    super();
  }

  public async loadData() {
    this.loadSale();
    this.loadPurchase();
    this.loadStock();
  }

  public loadSale() {
    var items: number[] = [];
    this.documentRelations.filter((e: any) =>
      (e.ToType == "SaleQuoteHeader" && e.ToId != this.documentId) ||
      (e.FromType == "SaleQuoteHeader" && e.FromId != this.documentId))
      .map((e: any) => {
        items.push(e.ToType == "SaleQuoteHeader" && e.ToId != this.documentId ? e.ToId : e.FromId);
      });
    this.itemsSaleQuoteId = items;

    var items: number[] = [];
    this.documentRelations.filter((e: any) =>
      (e.ToType == "SaleOrderHeader" && e.ToId != this.documentId) ||
      (e.FromType == "SaleOrderHeader" && e.FromId != this.documentId))
      .map((e: any) => {
        items.push(e.ToType == "SaleOrderHeader" && e.ToId != this.documentId ? e.ToId : e.FromId);
      });
    this.itemsSaleOrderId = items;

    var items: number[] = [];
    this.documentRelations.filter((e: any) =>
      (e.ToType == "SaleInvoiceHeader" && e.ToId != this.documentId) ||
      (e.FromType == "SaleInvoiceHeader" && e.FromId != this.documentId))
      .map((e: any) => {
        items.push(e.ToType == "SaleInvoiceHeader" && e.ToId != this.documentId ? e.ToId : e.FromId);
      });
    this.itemsSaleInvoiceId = items;

    var items: number[] = [];
    this.documentRelations.filter((e: any) =>
      (e.ToType == "SaleCreditNoteHeader" && e.ToId != this.documentId) ||
      (e.FromType == "SaleCreditNoteHeader" && e.FromId != this.documentId))
      .map((e: any) => {
        items.push(e.ToType == "SaleCreditNoteHeader" && e.ToId != this.documentId ? e.ToId : e.FromId);
      });
    this.itemsSaleCreditNoteId = items;

    var items: number[] = [];
    this.documentRelations.filter((e: any) =>
      (e.ToType == "SaleContractHeader" && e.ToId != this.documentId) ||
      (e.FromType == "SaleContractHeader" && e.FromId != this.documentId))
      .map((e: any) => {
        items.push(e.ToType == "SaleContractHeader" && e.ToId != this.documentId ? e.ToId : e.FromId);
      });
    this.itemsSaleContractId = items;

    this.saleQuoteGrid?.grid?.grid?.instance.getDataSource().filter(['Id in (' + (this.itemsSaleQuoteId).join(',') + ')']);
    this.saleOrderGrid?.grid?.grid?.instance.getDataSource().filter(['Id in (' + (this.itemsSaleOrderId).join(',') + ')']);
    this.saleInvoiceGrid?.grid?.grid?.instance.getDataSource().filter(['Id in (' + (this.itemsSaleInvoiceId).join(',') + ')']);
    this.saleCreditNoteGrid?.grid?.grid?.instance.getDataSource().filter(['Id in (' + (this.itemsSaleCreditNoteId).join(',') + ')']);
    this.saleContractGrid?.grid?.grid?.instance.getDataSource().filter(['Id in (' + (this.itemsSaleContractId).join(',') + ')']);
    this.saleQuoteGrid?.grid?.grid?.instance.refresh();
    this.saleOrderGrid?.grid?.grid?.instance.refresh();
    this.saleInvoiceGrid?.grid?.grid?.instance.refresh();
    this.saleCreditNoteGrid?.grid?.grid?.instance.refresh();
    this.saleContractGrid?.grid?.grid?.instance.refresh();
  }

  public loadPurchase() {
    var items: number[] = [];
    this.documentRelations.filter((e: any) =>
      (e.ToType == "PurchaseOrderHeader" && e.ToId != this.documentId) ||
      (e.FromType == "PurchaseOrderHeader" && e.FromId != this.documentId))
      .map((e: any) => {
        items.push(e.ToType == "PurchaseOrderHeader" && e.ToId != this.documentId ? e.ToId : e.FromId);
      });
    this.itemsPurchaseOrderId = items;

    var items: number[] = [];
    this.documentRelations.filter((e: any) =>
      (e.ToType == "PurchaseInvoiceHeader" && e.ToId != this.documentId) ||
      (e.FromType == "PurchaseInvoiceHeader" && e.FromId != this.documentId))
      .map((e: any) => {
        items.push(e.ToType == "PurchaseInvoiceHeader" && e.ToId != this.documentId ? e.ToId : e.FromId);
      });
    this.itemsPurchaseInvoiceId = items;

    var items: number[] = [];
    this.documentRelations.filter((e: any) =>
      (e.ToType == "PurchaseCreditNoteHeader" && e.ToId != this.documentId) ||
      (e.FromType == "PurchaseCreditNoteHeader" && e.FromId != this.documentId))
      .map((e: any) => {
        items.push(e.ToType == "PurchaseCreditNoteHeader" && e.ToId != this.documentId ? e.ToId : e.FromId);
      });
    this.itemsPurchaseCreditNoteId = items;

    this.purchaseOrderGrid?.grid?.grid?.instance.getDataSource().filter(['Id in (' + (this.itemsPurchaseOrderId).join(',') + ')']);
    this.purchaseInvoiceGrid?.grid?.grid?.instance.getDataSource().filter(['Id in (' + (this.itemsPurchaseInvoiceId).join(',') + ')']);
    this.purchaseCreditNoteGrid?.grid?.grid?.instance.getDataSource().filter(['Id in (' + (this.itemsPurchaseCreditNoteId).join(',') + ')']);
    this.purchaseOrderGrid?.grid?.grid?.instance.refresh();
    this.purchaseInvoiceGrid?.grid?.grid?.instance.refresh();
    this.purchaseCreditNoteGrid?.grid?.grid?.instance.refresh();
  }

  public loadStock() {
    var items: number[] = [];
    this.documentRelations.filter((e: any) =>
      (e.ToType == "StockMoveOutgoingHeader" && e.ToId != this.documentId) ||
      (e.FromType == "StockMoveOutgoingHeader" && e.FromId != this.documentId))
      .map((e: any) => {
        items.push(e.ToType == "StockMoveOutgoingHeader" && e.ToId != this.documentId ? e.ToId : e.FromId);
      });
    this.itemsStockMoveCustomerOutgoing = items;

    var items: number[] = [];
    this.documentRelations.filter((e: any) =>
      (e.ToType == "StockMoveIncomingHeader" && e.ToId != this.documentId) ||
      (e.FromType == "StockMoveIncomingHeader" && e.FromId != this.documentId))
      .map((e: any) => {
        items.push(e.ToType == "StockMoveIncomingHeader" && e.ToId != this.documentId ? e.ToId : e.FromId);
      });
    this.itemsStockMoveCustomerIncoming = items;

    var items: number[] = [];
    this.documentRelations.filter((e: any) =>
      (e.ToType == "StockMoveOutgoingHeader" && e.ToId != this.documentId) ||
      (e.FromType == "StockMoveOutgoingHeader" && e.FromId != this.documentId))
      .map((e: any) => {
        items.push(e.ToType == "StockMoveOutgoingHeader" && e.ToId != this.documentId ? e.ToId : e.FromId);
      });
    this.itemsStockMoveSupplierOutgoing = items;

    var items: number[] = [];
    this.documentRelations.filter((e: any) =>
      (e.ToType == "StockMoveIncomingHeader" && e.ToId != this.documentId) ||
      (e.FromType == "StockMoveIncomingHeader" && e.FromId != this.documentId))
      .map((e: any) => {
        items.push(e.ToType == "StockMoveIncomingHeader" && e.ToId != this.documentId ? e.ToId : e.FromId);
      });
    this.itemsStockMoveSupplierIncoming = items;

    var items: number[] = [];
    this.documentRelations.filter((e: any) =>
      (e.ToType == "StockMoveInternalHeader" && e.ToId != this.documentId) ||
      (e.FromType == "StockMoveInternalHeader" && e.FromId != this.documentId))
      .map((e: any) => {
        items.push(e.ToType == "StockMoveInternalHeader" && e.ToId != this.documentId ? e.ToId : e.FromId);
      });
    this.itemsStockMoveInternal = items;

    this.stockMoveCustomerIncomingGrid?.grid?.grid?.instance.getDataSource().filter(['Id in (' + (this.itemsStockMoveCustomerIncoming).join(',') + ')']);
    this.stockMoveCustomerOutgoingGrid?.grid?.grid?.instance.getDataSource().filter(['Id in (' + (this.itemsStockMoveCustomerOutgoing).join(',') + ')']);
    this.stockMoveSupplierIncomingGrid?.grid?.grid?.instance.getDataSource().filter(['Id in (' + (this.itemsStockMoveSupplierIncoming).join(',') + ')']);
    this.stockMoveSupplierOutgoingGrid?.grid?.grid?.instance.getDataSource().filter(['Id in (' + (this.itemsStockMoveSupplierOutgoing).join(',') + ')']);
    this.stockMoveInternalGrid?.grid?.grid?.instance.getDataSource().filter(['Id in (' + (this.itemsStockMoveInternal).join(',') + ')']);
    this.stockMoveCustomerIncomingGrid?.grid?.grid?.instance.refresh();
    this.stockMoveCustomerOutgoingGrid?.grid?.grid?.instance.refresh();
    this.stockMoveSupplierIncomingGrid?.grid?.grid?.instance.refresh();
    this.stockMoveSupplierOutgoingGrid?.grid?.grid?.instance.refresh();
    this.stockMoveInternalGrid?.grid?.grid?.instance.refresh();
  }

  public loadTimeManagement() {
    this.taskGrid?.grid?.grid?.instance.refresh();
    this.workTimeGrid?.grid?.grid?.instance.refresh();
  }

  public onDeleteRelation(e: any) {
    const relationId = e.data.DocumentRelationId;
    this.service.deleteRelation(relationId).then((r: any) => {
      console.log(r);
    });
  }

  public exportDocumentRelation() {
    this.reportsService.previewWorkTimes([this.documentId], 'Invoice', 'Worktime', 'SaleDocument');
  }
}
