import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'syslink-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss']
})
export class TagComponent {
  @Input() public name: string = '';
  @Input() public fill: boolean = false;

  @Input() public value: string | undefined;
  @Output() public valueChange: EventEmitter<string> = new EventEmitter<string>();
  @Input() public color: string| undefined;
  @Output() public colorChange: EventEmitter<string> = new EventEmitter<string>();

}
