import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'projects/erp-app/src/environments/environment';
import { AppInjectorService } from 'projects/libraries/syslink-components/src/lib/services/app-injector.service';
import { NotificationsService } from 'projects/libraries/syslink-components/src/public-api';
import { lastValueFrom } from 'rxjs';
import { Third } from '../../thirds/thirds/third.model';
import { ThirdsService } from '../../thirds/thirds/thirds.service';
import { SignalrService } from '../../connectors/signalr/signalr.service';
import { User } from './users/user.model';
import { UsersService } from './users/users.service';
import { TranslationsService } from '../../base/translations/translations/translations.service';

export class AuthInfo {
  userName: string = '';
  password: string = '';
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public url = environment.base_url + '/api/authentication/authenticate';
  public user?: User;

  static localStorageKey: string = 'access_token';

  constructor(
    public jwtHelper: JwtHelperService,
    public router: Router,
    protected HttpClient: HttpClient,
    private signalR: SignalrService,
    private translateService: TranslateService,
    private usersService: UsersService
  ) {
  }

  static getToken(): string | null {
    return localStorage.getItem(AuthService.localStorageKey);
  }

  public setToken(token: string): void {
    localStorage.setItem(AuthService.localStorageKey, token);
  }

  static deleteToken(): void {
    localStorage.removeItem(AuthService.localStorageKey);
  }

  public isExpired(): Promise<boolean> | boolean {
    return AuthService.getToken() === null && this.jwtHelper.isTokenExpired();
  }

  public async reloadUser(): Promise<User | undefined> {
    const token = AuthService.getToken();
    if (!token) return;
    const userId = this.jwtHelper.decodeToken(token).UserId;
    const userLoadOptions = {
      filter: [
        ['Oid eq ' + userId],
        'and',
        'UserPermissions.any(p: p/Enabled eq true)',
        'and',
        'UserPermissions.any(p: p/ModuleId.any(p: p/Enabled eq true))',
        'and',
        'UserPermissions.any(p: p/SubModuleId.any(p: p/Enabled eq true))',
        'and',
        'UserRoles.any(r: r/Enabled eq true and r/Permissions.any(p: p/Enabled eq true and p/ModuleId.any(a: a/Enabled eq true) and p/SubModuleId.any(s: s/Enabled eq true)))',
        'and',
        'ActionGroups.any(r: r/Enabled eq true and r/Permissions.any(p: p/Enabled eq true and p/ModuleId.any(a: a/Enabled eq true) and p/SubModuleId.any(s: s/Enabled eq true)))'
      ],
      select: [
        'UserPermissions.Code',
        'UserPermissions.Enabled',
        'UserPermissions.ModuleId.Enabled',
        'UserPermissions.SubModuleId.Enabled',
        'UserPermissions.Type.Code',
        'UserRoles.Id',
        'UserRoles.Permissions.Code',
        'UserRoles.Permissions.Enabled',
        'UserRoles.Permissions.Type.Code',
        'UserRoles.Permissions.ModuleId.Enabled',
        'UserRoles.Permissions.SubModuleId.Enabled',
        'ActionGroups.Permissions.Code',
        'ActionGroups.Permissions.Enabled',
        'ActionGroups.Permissions.Type.Code',
        'ActionGroups.Permissions.ModuleId.Enabled',
        'ActionGroups.Permissions.SubModuleId.Enabled'
      ],
      expand: [
        'ThirdId',
        'LanguageId',
        'UserPermissions.Type',
        'UserRoles.Permissions.Type',
        'Groups',
        'ActionGroups.Permissions.Type'
      ]
    };

    const users = await this.usersService.load(userLoadOptions);
    this.user = users[0] || null;
    if (this.user) {
      this.user.AllPermissions = [
        ...this.user?.UserPermissions, 
        ...this.user.UserRoles.flatMap(e => e.Permissions), 
        ...this.user.ActionGroups.flatMap(e => e.Permissions)
      ];
    }
    this.user.AllPermissions = this.user.AllPermissions.filter(e => e.Enabled == true && e.ModuleId && e.ModuleId.Enabled == true && e.SubModuleId && e.SubModuleId.Enabled == true);
    await TranslationsService.updateTranslations(this.user?.LanguageId?.IsoCode ?? TranslationsService.currentLanguage);
    return this.user;
  }

  public async getUserThird(): Promise<Third | undefined> {
    const token = AuthService.getToken();
    if (!token) return;
    const thirdId = this.jwtHelper.decodeToken(token).ThirdId;
    if (thirdId) {
      return AppInjectorService.injector.get(ThirdsService).findByID(thirdId);
    }
    return;
  }

  public async login(userLogin: AuthInfo): Promise<void> {
    try {
      const response = await lastValueFrom<any>(this.HttpClient.post(this.url, userLogin));

      if (response.token) {
        // this.signalR.connect();

        this.setToken(response.token);
        await this.reloadUser();

        var redirect = localStorage.getItem(environment.redirectOnLoginUrlKey);
        if (redirect != null) {
          localStorage.removeItem(environment.redirectOnLoginUrlKey);
          this.router.navigateByUrl(redirect);
        } else {
          this.router.navigateByUrl('/');
        }
      }
    } catch (error: any) {
      NotificationsService.sendErrorMessage(this.translateService.instant(error.error));
    }

  }

  public logout(): void {
    // this.signalR.disconnect();
    AuthService.deleteToken();
    NotificationsService.sendInfo(this.translateService.instant('AuthService.Logout'));
    this.router.navigate(['login']);
  }

  // Permissions
  // -----------
  public hasPermission(code: string): boolean {
    if (!this.user) return false;
    return this.user.AllPermissions.some(p => p.Code === code);
  }

  public canRead(code: string): boolean {
    return this.hasPermission(`${code}.Read`);
  }

  public canWrite(code: string): boolean {
    return !this.hasPermission(`${code}.Write`);
  }
}
