import { DxDataGridComponent } from "devextreme-angular";
import { DxiDataGridColumn } from "devextreme-angular/ui/nested/base/data-grid-column-dxi";
import { Row } from "devextreme/ui/data_grid";
import { Item } from 'devextreme/ui/context_menu';

export class ContextMenuItemAction implements Item {
    public code?: string;

    public column?: DxiDataGridColumn;
    public columnIndex?: number;

    public row?: Row;
    public rowIndex?: number;

    public component?: DxDataGridComponent;

    public items?: ContextMenuItemAction[] = []

    public onItemClick?: Function = () => { };

    /**
     * Specifies whether a group separator is displayed over the item.
     */
    beginGroup?: boolean;
    /**
     * Specifies if a menu is closed when a user clicks the item.
     */
    closeMenuOnClick?: boolean;
    /**
     * Specifies whether the menu item responds to user interaction.
     */
    disabled?: boolean;
    /**
     * Specifies the menu item&apos;s icon.
     */
    icon?: string;
    /**
     * Specifies whether or not a user can select a menu item.
     */
    selectable?: boolean;
    /**
     * Specifies whether or not the item is selected.
     */
    selected?: boolean;
    /**
     * Specifies the text inserted into the item element.
     */
    text?: string;
    /**
     * Specifies whether or not the menu item is visible.
     */
    visible?: boolean;

    public rowData?: any;

    constructor(entity?: Partial<ContextMenuItemAction>) {
        Object.assign(this, entity);
    }
}
