import { BasicEntity } from "../../core/models/basic-entity";
import { MailTemplateParam } from "../mail-template-params/mail-template-param.model";

export class MailTemplateType extends BasicEntity {
    public MailTemplateParams?:MailTemplateParam[];
   
    constructor(entity?: Partial<MailTemplateType>) {
        super();
        Object.assign(this, entity);
    }
}
