import { Entity } from "../../core/models/entity";
import { MailTemplateType } from "../mail-template-types/mail-template-type.model";

export class MailTemplate extends Entity {
    public MailTemplateTypeId?: MailTemplateType;
    public Code?: string;
    public Name?: string;
    public Subject?: string;
    public Content?: string;
    public AttachFile?:boolean;

    constructor(entity?: Partial<MailTemplate>) {
        super();
        Object.assign(this, entity);
    }
}
