import { Component, Input, OnInit } from '@angular/core';
import ODataStore from 'devextreme/data/odata/store';
import { AutoformField } from './models/autoform-field';

@Component({
  selector: 'syslink-autoform',
  templateUrl: './autoform.component.html',
  styleUrls: ['./autoform.component.scss']
})
export class AutoformComponent implements OnInit {
  @Input() fields: AutoformField[] = [];
  public fieldsSelectValues: any[] = [];

  @Input() getStore: Function = (field: AutoformField) => ODataStore
  @Input() getItems: Function = (field: AutoformField) => []

  ngOnInit(): void {
    // this.fields.forEach(e => {
    //   if (e.Key.includes(".")) {
    //     const splittedKey =  e.Key.split('.', 1);

    //     let child:AutoformField = {
    //       Key: splittedKey[1],
    //       Name: e.Name,
    //       Value: e.Value,

    //     };
        
    //     e.Type = "Group";
    //     e.Key = splittedKey[0];
    //     e.Children?.push(child);
        
    //   }
    // });

    this.fieldsSelectValues = this.fields.map((f: AutoformField) => {
      if (f.Type == 'one-to-many') {
        return [];
      }
      return { Id: +f.Value };
    });
  }
  // public goToLine(index: number) {
  //   console.log((index + 1) * 3);
  //   return ((index + 1) * 3) % 12 === 0;
  // }
}
