import { Injectable } from '@angular/core';
import { ODataService } from '../../core/services/oData.service';
import { Currency } from './currency.model';

@Injectable({
  providedIn: 'root'
})
export class CurrenciesService extends ODataService<Currency> {
  public url = 'Currency';
}
