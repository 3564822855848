import { UserGroup } from "../../../core/auth/user-groups/user-group.model";
import { User } from "../../../core/auth/users/user.model";
import { Entity } from "../../../core/models/entity";
import { SaleContract } from "../../../sales/sale-contracts/sale-contracts/sale-contract.model";
import { SaleInvoiceLine } from "../../../sales/sale-invoices/sale-invoice-lines/sale-invoice-line.model";
import { SaleQuoteLine } from "../../../sales/sale-quotes/sale-quote-lines/sale-quote-line.model";
import { Third } from "../../../thirds/thirds/third.model";
import { WorkTime } from "../../work-times/work-times/work-time";
import { WorkTimeState } from "../../works/work-time-states/work-time-state";
import { WorkType } from "../../works/work-types/work-type.model";
import { Work } from "../../works/works/work.model";
import { TaskPriority } from "../task-priorities/task-priority.model";
import { TaskTag } from "../task-tags/task-tag.model";
import { TaskTaskStatus } from "../task-task-statuses/task-task-status.model";

export class Task extends Entity implements Work {
    // Work
    // ----
    public IsBillable: boolean = false;
    public Description: string = "";

    // Task
    // --------
    public No: string = "";
    public Subject: string = "";
    public ExternalRef: string = "";
    public InternalRef: string = "";
    public Note?: string;
    // public IsPlannable: boolean = false;
    public StartDate: Date = new Date();
    public EndDate?: Date;
    public DeadlineState: WorkTimeState = new WorkTimeState();
    public IsPerformable: boolean = false;
    public EstimatedTime: number = 0;
    public EstimatedTimeState: WorkTimeState = new WorkTimeState();
    public IsFixedPrice: boolean = false;
    public IsBilled: boolean = false;
    public FixedPrice?: number;
    public BillableTime : number = 0;

    // Navigation properties
    // ---------------------
    // Work
    // ----
    public TypeId: WorkType = new WorkType;
    public ThirdId?: Third;
    public Users: User[] = [];

    // Task
    // --------
    public SaleInvoiceLineId?: SaleInvoiceLine;
    public SaleQuoteLineId?: SaleQuoteLine;
    public SaleContractId?: SaleContract;
    public TaskPriorityId?: TaskPriority;
    public UserGroups: UserGroup[] = [];
    public Tags: TaskTag[] = [];
    public Statuses?: TaskTaskStatus[];
    public CurrentStatusLink?: TaskTaskStatus;
    public WorkTimeTasks: WorkTime[] = [];

    // Calculated properties
    // ---------------------
    // Work
    // ----
    public UsersList?: string;
    public AmountInvoiced: number = 0;

    // Task
    // --------
    public BilledTime: number = 0;
    public BilledTimeState: WorkTimeState = new WorkTimeState();
    // public ScheduledTime: number = 0;
    // public ScheduledTimeState: WorkTimeState = new WorkTimeState();
    public WorkedTime: number = 0;
    public WorkedTimeState: WorkTimeState = new WorkTimeState();

    constructor(entity?: Partial<Task>) {
        super();
        Object.assign(this, entity);
    }
}
export enum TaskTimeStateCode {
    BilledTimeState = "BilledTimeState",
    // ScheduledTimeState = "ScheduledTimeState",
    WorkedTimeState = "WorkedTimeState",
    DeadlineState = "DeadlineState",
    EstimatedTimeState = "EstimatedTimeState",
}
