import { Injectable } from '@angular/core';
import { ODataService } from '../../../core/services/oData.service';
import { PaymentMethod } from './payment-method';
import { LoadOptions } from 'devextreme/data';

@Injectable({
  providedIn: 'root'
})
export class PaymentMethodsService extends ODataService<PaymentMethod> {
  public url = 'PaymentMethod';
  public override defaultOptions: LoadOptions = {
    filter: ['IsVisible eq true']
  };
}