import { Injectable } from '@angular/core';
import { ODataService } from '../../../core/services/oData.service';
import { LoadOptions } from 'devextreme/data';
import { Task } from './task.model';
import { Third } from '../../../thirds/thirds/third.model';
import { ApiService } from '../../../core/api.service';
import { UserGroup } from '../../../core/auth/user-groups/user-group.model';
import { UserGroupsService } from '../../../core/auth/user-groups/user-groups.service';
import { WorkTypesService } from '../../works/work-types/work-types.service';
import { TaskTag } from '../task-tags/task-tag.model';
import { TaskTagsService } from '../task-tags/task-tags.service';
import { UsersService } from '../../../core/auth/users/users.service';
import { WorkType } from '../../works/work-types/work-type.model';
import { User } from '../../../core/auth/users/user.model';
import { NotificationsService } from 'projects/libraries/syslink-components/src/public-api';
import { TranslateService } from '@ngx-translate/core';
import { ConfigurationsService } from '../../../base/modules/configurations/configurations.service';
import { WorkTimeInvoicingLine } from '../../work-times/work-time-invoicing-line/work-time-invoicing-line.model';
import { jsonToOdataFormat } from 'projects/libraries/syslink-components/src/lib/helpers/tools';
import { TaskStatus } from '../task-statuses/task-status.model';

@Injectable({
  providedIn: 'root'
})
export class TasksService extends ODataService<Task> {
  public url = 'Task';
  private instance?: Task;

  public override defaultOptions: LoadOptions = {
    expand: [
      'TypeId',
      'Statuses.StatusId',
      'CurrentStatusLink.StatusId',
      'Users',
      'UserGroups',
      'ThirdId',
      'SaleQuoteLineId',
      'SaleContractId',
      'Tags',
      // Time States
      'EstimatedTimeState',
      'DeadlineState',
      'BilledTimeState',
      // 'ScheduledTimeState',
      'WorkedTimeState',
      'TaskPriorityId',
      'SaleInvoiceLineId',
      'WorkTimeTasks.SaleInvoiceLineId'
    ]
  };

  constructor(
    private workTypesService: WorkTypesService,
    private api: ApiService,
    private userGroupsService: UserGroupsService,
    private taskTagsService: TaskTagsService,
    private usersService: UsersService,
    private translateService: TranslateService,
    private configurationsService: ConfigurationsService
  ) {
    super();
  }

  public override format(element: Partial<Task>): Task {
    var userGroups: any[] = [];
    element.UserGroups?.forEach((group: UserGroup) => {
      userGroups.push(this.userGroupsService.format(group));
    });

    var users: any[] = [];
    element.Users?.forEach((user) => {
      users.push(this.usersService.format(user));
    });

    var tags: any[] = [];
    element.Tags?.forEach((tag: TaskTag) => {
      tags.push(this.taskTagsService.format(tag));
    });

    var result: any = {
      ...element,
      Users: users,
      UserGroups: userGroups,
      Tags: tags,
      ThirdId: element.ThirdId?.Id ?? null,
      TypeId: element.TypeId?.Id ?? null,
      TaskPriorityId: element.TaskPriorityId?.Id ?? null,
      SaleQuoteLineId: element.SaleQuoteLineId?.Id ?? null,
      SaleContractId: element.SaleContractId?.Id ?? null,
      CurrentStatusLink: element.CurrentStatusLink?.Id ?? null,
    };

    delete result.BilledTimeState;
    delete result.WorkedTimeState;
    delete result.DeadlineState;
    delete result.ScheduledTimeState;
    delete result.EstimatedTimeState;
    delete result.Statuses;
    delete result.UserGroupsList;
    delete result.FormattedName;
    delete result.ScheduledTime;
    delete result.UsersList;
    delete result.AmountInvoiced;
    delete result.BilledTime;
    delete result.WorkedTime;
    delete result.CurrentStatusLink;
    delete result.StartDateDay;

    // let endDate: Date = result.EndDate;
    // console.log(!endDate);
    if (result.EndDate?.getFullYear().toString() == "1") {
      delete result.EndDate;
    }


    return result;
  }

  public saveInstance(params?: Partial<Task>) {
    this.instance = new Task(params);
  }

  public override async getInstance(params?: Partial<Task>): Promise<Task> {
    if (!this.instance) {
      this.instance = new Task({
        TypeId: await this.workTypesService.findByID(1),
        Users: [],
        UserGroups: [],
        FixedPrice: 0,
        // IsPlannable: await this.configurationsService.getConfigurationAsBoolean("Task.IsPlannable", "TimeManagement.Task", "TimeManagement"),
        IsPerformable: await this.configurationsService.getConfigurationAsBoolean("Task.IsPerformable", "TimeManagement.Task", "TimeManagement"),
        IsBillable: await this.configurationsService.getConfigurationAsBoolean("Task.IsBillable", "TimeManagement.Task", "TimeManagement"),
        IsFixedPrice: await this.configurationsService.getConfigurationAsBoolean("Task.IsFixedPrice", "TimeManagement.Task", "TimeManagement"),
        ...params
      });
    }

    const result: Task = this.instance;
    this.instance = undefined;

    return result;
  }

  public async updateRelations(localElement: Task) {
    if (!localElement.Id) return;

    const element = await this.findByID(localElement.Id, {
      select: ['Id', 'Users.Oid', 'UserGroups.Id', 'Tags.Id']
    });

    await this.updateUsers(localElement, element);
    await this.updateUserGroups(localElement, element);
    await this.updateUserTags(localElement, element);
  }

  public async updateUsers(localElement: Task, element: Task) {
    localElement.Users.forEach((user: User) => {
      var oid = user.Oid?.toString()

      let index = element.Users.findIndex(t => t.Oid == oid);
      if (index > -1) {
        element.Users.splice(index, 1);
      }
    });

    // Remove old Users
    // ----------------
    element.Users.forEach(async (user: User) => {
      if (localElement.Id && user.Oid) {
        await this.removeAssignes(localElement.Id, user.Oid.toString());
      }
    });
  }

  public async updateUserGroups(localElement: Task, element: Task) {
    localElement.UserGroups.forEach((group: UserGroup) => {
      let index = element.UserGroups.findIndex(t => t.Id == group.Id);
      if (index > -1) {
        element.UserGroups.splice(index, 1);
      }
    });

    // Remove old User Groups
    // --------------------
    element.UserGroups.forEach(async (group: UserGroup) => {
      if (localElement.Id && group.Id) {
        await this.removeUserGroups(localElement.Id, group.Id);
      }
    });
  }
  public async updateUserTags(localElement: Task, element: Task) {
    localElement.Tags.forEach((tag: TaskTag) => {
      let index = element.Tags.findIndex(t => t.Id == tag.Id);
      if (index > -1) {
        element.Tags.splice(index, 1);
      }
    });
    // Remove old Tag
    // --------------
    element.Tags.forEach(async (tag: TaskTag) => {
      if (localElement.Id && tag.Id) {
        await this.removeTags(localElement.Id, tag.Id);
      }
    });
  }

  public async tryUpdateMultiples(elements: Task[], fields: Array<{ field: string, value: any }>) {
    if (!this.validateFieldValueAllowed(elements, fields)) return;

    var updateValues: any = {};
    fields.forEach(fieldDatas => {
      updateValues[fieldDatas.field] = fieldDatas.value;
    });
    await this.updateMultiples(elements.map(row => row.Id), updateValues);
  }

  public validateFieldValueAllowed(elements: Task[], fields: Array<{ field: string, value: any }>): boolean {
    var result = true;
    fields.forEach(fieldDatas => {

      if (this.requireSingleElement(fieldDatas.field) && elements.length > 1) {
        NotificationsService.sendErrorMessage("Please select only one line");
        result = false;
        return;
      }

      switch (fieldDatas.field) {
        case "IsBillable":
          result = fieldDatas.value ? this.canMarkAsBillable(elements) : this.canMarkAsNotBillable(elements);
          break;
        // case "IsPlannable":
        //   result = fieldDatas.value ? this.canMarkAsSchedulable(elements) : this.canMarkAsNotSchedulable(elements);
        //   break;
        case "IsPerformable":
          result = fieldDatas.value ? this.canMarkAsWorkable(elements) : this.canMarkAsNotWorkable(elements);
      }
    });

    return result;
  }

  public requireSingleElement(actionCode: string): boolean {
    switch (actionCode) {
      // case TaskActionCode.Schedule: return true;
      case TaskActionCode.Work: return true;
      default: return false;
    }
  }

  public can(actionCode: TaskActionCode, elements: Task[], value?: Partial<Task>) {
    switch (actionCode) {
      // case TaskActionCode.Schedule: return this.canSchedule(elements, value);
      case TaskActionCode.Work: return this.canWork(elements, value);
      case TaskActionCode.Invoice: return this.canInvoice(elements, value);
      case TaskActionCode.MarkAsNotBillable: return this.canMarkAsNotBillable(elements, value);
      case TaskActionCode.MarkAsNotWorkable: return this.canMarkAsNotWorkable(elements, value);
      default: throw "Action on Task is not allowed";
    }
  }

  // Invoicing validations
  // ---------------------
  // Invoice
  // -------
  private canInvoice(elements: Task[], value?: Partial<Task>) {
    var result: boolean = true;

    if (!elements?.some(row => row.IsBillable)) {
      NotificationsService.sendErrorMessage("One or more selected line(s) are not billable");
      result = false;
    }

    if (elements?.filter(row => row.IsBilled).length == elements?.length) {
      NotificationsService.sendErrorMessage("One or more selected line(s) are already billed");
      result = false;
    }

    if (elements?.some(row => !row.ThirdId)) {
      NotificationsService.sendErrorMessage("Third is required");
      result = false;
    }

    if (elements?.reduce((thirdIds: Set<number>, row: Task) => {
      if (row.ThirdId?.Id)
        thirdIds.add(row.ThirdId.Id)

      return thirdIds;
    }, new Set<number>()).size > 1) {
      NotificationsService.sendErrorMessage("Third must be unique");
      result = false;
    }

    return result;
  }

  // Billable
  // --------
  private canMarkAsBillable(elements: Task[], value?: Partial<Task>) {
    var result: boolean = true;

    // Checking it still not billable elements
    // ---------------------------------------
    if (elements.filter(row => row.IsBillable).length == elements.length) {
      NotificationsService.sendErrorMessage("All selected line(s) are already billable");
      result = false;
    }

    return result;
  }

  // Not billable
  // ------------
  private canMarkAsNotBillable(elements: Task[], value?: Partial<Task>) {
    var result: boolean = true;

    // Checking it still not billable elements
    // ---------------------------------------
    if (elements.filter(row => !row.IsBillable).length == elements.length) {
      NotificationsService.sendErrorMessage("All selected line(s) are already not billable");
      result = false;
    }

    if (elements?.some(row => row.BilledTime > 0)) {
      NotificationsService.sendErrorMessage("One or more selected line(s) are already worked");
      result = false;
    }

    if (elements?.some(row => row.IsBilled)) {
      NotificationsService.sendErrorMessage("One or more selected line(s) are already billed");
      result = false;
    }
    return result;
  }
  // -------------------------------------------------------------------------------------------------------

  // Work times validations
  // ----------------------
  // Work
  // ----
  private canWork(elements: Task[], value?: Partial<Task>) {
    var result: boolean = true;

    if (elements?.some(row => !row.IsPerformable)) {
      NotificationsService.sendErrorMessage("One or more selected line(s) are not workable");
      result = false;
    }
    return result;
  }

  // Workable
  // --------------
  private canMarkAsWorkable(elements: Task[], value?: Partial<Task>) {
    var result: boolean = true;

    // Checking it still not billable elements
    // ---------------------------------------
    if (elements.filter(row => row.IsPerformable).length == elements.length) {
      NotificationsService.sendErrorMessage("All selected line(s) are already worked");
      result = false;
    }

    return result;

  }

  // Not Workable
  // ------------
  private canMarkAsNotWorkable(elements: Task[], value?: Partial<Task>) {
    var result: boolean = true;

    // Checking it still not billable elements
    // ---------------------------------------
    if (elements.filter(row => !row.IsPerformable).length == elements.length) {
      NotificationsService.sendErrorMessage("All selected line(s) are already not worked");
      result = false;
    }

    if (elements?.some(row => row.WorkedTime > 0)) {
      NotificationsService.sendErrorMessage("One or more selected line(s) are already worked");
      result = false;
    }

    return result;
  }

  // -------------------------------------------------------------------------------------------------------

  // Scheduling validations
  // ----------------------
  // Schedule
  // --------
  // private canSchedule(elements: Task[], value?: Partial<Task>) {
  //   var result: boolean = true;

  //   if (elements?.some(row => !row.IsPlannable)) {
  //     NotificationsService.sendErrorMessage("One or more selected line(s) are not schedulable");
  //     result = false;
  //   }

  //   return result;
  // }

  // // Schedulable
  // // -----------
  // private canMarkAsSchedulable(elements: Task[], value?: Partial<Task>) {
  //   var result: boolean = true;

  //   // Checking it still not billable elements
  //   // ---------------------------------------
  //   if (elements.filter(row => row.IsPlannable).length == elements.length) {
  //     NotificationsService.sendErrorMessage("All selected line(s) are already schedulable");
  //     result = false;
  //   }

  //   if (elements?.some(row => row.ScheduledTime > 0)) {
  //     NotificationsService.sendErrorMessage("One or more selected line(s) are already scheduled");
  //     result = false;
  //   }
  //   return result;

  // }

  // // Not Schedulable
  // // ---------------
  // private canMarkAsNotSchedulable(elements: Task[], value?: Partial<Task>) {
  //   var result: boolean = true;

  //   // Checking it still not billable elements
  //   // ---------------------------------------
  //   if (elements.filter(row => !row.IsPlannable).length == elements.length) {
  //     NotificationsService.sendErrorMessage("All selected line(s) are already not schedulable");
  //     result = false;
  //   }

  //   return result;
  // }

  public canUpdateStatus(elements: Task[], status: TaskStatus): boolean {
    var result: boolean = true;

    // Checking status is not empty
    // ----------------------------
    if (status == null) {
      NotificationsService.sendErrorMessage("Status is empty");
      result = false;
    }

    return result;
  }
  // -------------------------------------------------------------------------------------------------------

  public async removeAssignes(TaskId: number, userId: string) {
    return await this.api.sendRequest('/api/odata/Task/' + TaskId + '/Users/' + userId + '/$ref', 'DELETE');
  }
  public async removeUserGroups(TaskId: number, userGroupId: number) {
    return await this.api.sendRequest('/api/odata/Task/' + TaskId + '/UserGroups/' + userGroupId + '/$ref', 'DELETE');
  }
  public async removeTags(TaskId: number, tagId: number) {
    return await this.api.sendRequest('/api/odata/Task/' + TaskId + '/Tags/' + tagId + '/$ref', 'DELETE');
  }

  public invoice(taskIds: any): Promise<void> {
    return this.apiService.sendRequest(`/api/TimeManagement/invoice`, "POST", taskIds);
  }

  public getInvoicingLines(tasks: Task[]): Promise<WorkTimeInvoicingLine[]> {
    let firstTask = tasks.shift();
    let queryParams = firstTask != undefined ? tasks.reduce((prev, curr) => prev += `&taskIds=${curr.Id}`, `taskIds=${firstTask.Id}`) : "";
    return this.apiService.sendRequest(`/api/TimeManagement/taskInvoicing?${queryParams}`).then((value: any[]) => {
      value = value.map(el => jsonToOdataFormat(el));
      return value;
    });
  }


  public getTaskFilter(thirds: Array<Partial<Third>>, types: Array<Partial<WorkType>>, users: Array<Partial<User>>, userGroups: Array<Partial<UserGroup>>): any[] | undefined {
    let filters = [];
    const andOperator = "and";
    let userGroupInClauseValue = userGroups.filter(item => item?.Id != undefined).map(item => item.Id).join(',');
    if (userGroupInClauseValue.length > 0) {
      let userGroupFilterString = `UserGroups/any(userGroup:userGroup/Id in (${userGroupInClauseValue}))`;

      filters.push([userGroupFilterString]);
      filters.push(andOperator);
    }

    if (users.length > 0) {
      let userInClauseValue = users.filter(item => item?.Oid != undefined).map(item => item.Oid).join(",");
      let userFilterString = `Users/any(user:user/Oid in (${userInClauseValue}))`;

      let filter: any[] = [userFilterString];
      let userGroupInClauseValue = users.flatMap(user => user.Groups?.map(group => group?.Id)).filter(groupId => groupId).join(",");
      if (userGroupInClauseValue.length > 0) {
        filter = [[userFilterString], "or", [`UserGroups/any(userGroup:userGroup/Id in (${userGroupInClauseValue}))`]]
      }

      filters.push(filter);
      filters.push(andOperator);
    }

    let thirdInClauseValue = thirds.filter(item => item?.Id != undefined).map(item => item.Id).join(',');
    if (thirdInClauseValue.length) {
      let thirdFilterString = `ThirdId.Id in (${thirdInClauseValue})`;

      filters.push([thirdFilterString]);
      filters.push(andOperator);
    }

    let typeInClauseValue = types.filter(item => item?.Id != undefined).map(item => item.Id).join(',');
    if (typeInClauseValue.length > 0) {
      let typeFilterString = `TypeId.Id in (${typeInClauseValue})`;
      filters.push([typeFilterString]);
    }

    if (filters.length > 0 && filters[filters.length - 1] == andOperator) {
      filters.pop();
    }

    if (filters.length == 0) return undefined;

    return filters;
  }

  public getTypeFilters(tasks: Array<Partial<Task>>): any[] | undefined {
    if (tasks.length == 0) return undefined;
    return ["Id in (" + tasks.filter(task => task?.TypeId?.Id != null).map(task => task.TypeId?.Id).join(',') + ")"];
  }

  public getThirdFilters(tasks: Array<Partial<Task>>): any[] | undefined {
    if (tasks.length == 0) return undefined;
    return ["Id in (" + tasks.filter(task => task?.ThirdId?.Id != null).map(task => task?.ThirdId?.Id).join(',') + ")"];
  }

  public getUserFilters(tasks: Array<Partial<Task>>, userGroups: Array<Partial<UserGroup>>): any[] | undefined {
    let userIdsFromTask = tasks.filter(task => task).flatMap(task => task.Users?.map(user => user.Oid)) ?? [];
    let userIdsFromTaskGroups = tasks.filter(task => task).flatMap(task => task.UserGroups?.flatMap(group => group.Users?.map(user => user?.Oid) ?? []) ?? []);
    let userIdsFromUserGroups = userGroups?.filter(userGroups => userGroups).flatMap(userGroup => userGroup.Users?.map(user => user.Oid)) ?? [];

    let userIds = new Set<string>();

    for (let value of [...userIdsFromUserGroups, ...userIdsFromTask, ...userIdsFromTaskGroups]) {
      if (value) userIds.add(`'${value}'`);
    }

    if (userIds.size == 0) return undefined;
    return [
      "Oid in (" + [...userIds].join(",") + ")"
    ];

  }

  public getUserGroupFilters(tasks: Array<Partial<Task>>, users: Array<Partial<User>>): any[] | undefined {
    let userGroupIdsFromTask = tasks.filter(task => task).flatMap(task => task.Users?.flatMap(user => user.Groups?.map(group => group.Id) ?? []) ?? []);
    let userGroupIdsFromUserGroups = users.filter(users => users).flatMap(user => user.Groups?.map(group => group.Id) ?? []);

    let userGroupIds = new Set<number>();

    for (let value of [...userGroupIdsFromUserGroups, ...userGroupIdsFromTask]) {
      if (value) userGroupIds.add(value);
    }

    if (userGroupIds.size == 0) return undefined;
    return [
      [...userGroupIds].reduce(
        (acc, curr) => acc + curr + ", ",
        "Id in ("
      ) + ")"
    ];

  }

  public validateElementInformations(element: Task) {
    let result = true;

    // Checking taskType is not empty
    // ------------------------------
    if (element.TypeId == undefined) {
      NotificationsService.sendErrorMessage("Field is required: %s", [this.translateService.instant("Type")])
      result = false;
    }
    // Checking start date is not empty
    // ------------------------------
    if (element.StartDate == undefined || element.StartDate == null) {
      NotificationsService.sendErrorMessage(this.translateService.instant("Field is required: %s"), [this.translateService.instant("Start date")])
      result = false;
    }
    // Checking subject is not empty
    // --------------------------------------------
    if (element.Subject == "") {
      NotificationsService.sendErrorMessage(this.translateService.instant("Field is required: %s"), [this.translateService.instant("Subject")])
      result = false;
    }
    // Checking third is not empty
    // --------------------------
    if (element.ThirdId == undefined) {
      NotificationsService.sendErrorMessage(this.translateService.instant("Field is required: %s"), [this.translateService.instant("Third")])
      result = false;
    }
    // Checking user or userGroup is not empty
    // ---------------------------------------
    if ((element.Users.length + element.UserGroups.length) == 0) {
      NotificationsService.sendErrorMessage(this.translateService.instant("Field is required: %s"), [this.translateService.instant("user or user group")])
      result = false;
    }

    // Checking start and end date relation
    // ------------------------------------
    if (element.EndDate && (new Date(element.StartDate.getFullYear(), element.StartDate.getMonth(), element.StartDate.getDate()) > new Date(element.EndDate.getFullYear(), element.EndDate.getMonth(), element.EndDate.getDate()))) {
      NotificationsService.sendErrorMessage("Start date must be before end date");
      result = false;
    }

    return result;
  }
}

export enum TaskActionCode {
  // Schedule = 'Schedule',
  // MarkAsNotSchedulable = 'MarkAsNotSchedulable',
  // MarkAsSchedulable = 'MarkAsSchedulable',
  Work = 'Work',
  MarkAsNotWorkable = 'MarkAsNotWorkable',
  MarkAsWorkable = 'MarkAsWorkable',
  Invoice = 'Invoice',
  MarkAsNotBillable = 'MarkAsNotBillable',
  MarkAsBillable = 'MarkAsBillable',
  AssignToMe = 'AssignToMe',
  AssignToUsers = 'AssignToUsers',
  AssignToGroups = 'AssignToGroups',
  Edit = 'Edit',
  Delete = 'Delete',
  TimesheetsByGroup = 'TimesheetsByGroup',
  TimesheetsByList = 'TimesheetsByList',
  ChangeStatus = 'ChangeStatus'
}
