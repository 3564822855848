<app-payments
  [element]="element.Payments"
  [document]="element"
  (paymentsChange)="paymentsChange.emit($event)"
  [reportType]="reportType"
  [filterPaymentType]="reportType == 'PurchaseOrder'?['Code eq \'Advance\'']:''"
></app-payments>
<div class="row mt-3">
  <div class="col-6">
    <app-purchase-document-finance-grid
      [permissionKey]="'finance.taxes.list'"
      [items]="element.DocumentFinances"
    ></app-purchase-document-finance-grid>
  </div>
</div>
<div class="row">
  <div class="col-2">
    <div class="d-flex">
      <span class="pt-3">+++&nbsp;</span>
      <syslink-input-text
        label="Structured communication"
        [(model)]="element.StructuredCommunication"
      ></syslink-input-text>
      <span class="pt-3">&nbsp;+++</span>
    </div>
  </div>
</div>