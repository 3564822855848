<syslink-title [text]="getFormattedTitle()"></syslink-title>

<!-- Toolbar -->
<syslink-toolbar [actions]="toolbarActions"></syslink-toolbar>

<!-- Product -->
<div class="mt-4">
  <app-product-form
    [(element)]="element"
    (productPurchasableChanged)="save()"
  ></app-product-form>
</div>
<div class="mt-4">
  <syslink-tabs #productTabs>
    <syslink-tab
      key="Purchasable"
      [visible]="authService.hasPermission(this.basePermissionKey + '.purchase.tab.view') && element.Purchasable"
      label="Purchase"
    >
      <app-product-purchase-form
        *ngIf="element.Purchasable"
        [(element)]="element"
        (supplierCatalogChanged)="save()"
      ></app-product-purchase-form>
    </syslink-tab>
    <syslink-tab
      key="Sellable"
      [visible]="authService.hasPermission(this.basePermissionKey + '.sale.tab.view') && element.Sellable && element.FilteredCustomerCatalogs != null"
      label="Sale"
    >
      <app-product-sale-form
        *ngIf="element.Sellable && element.FilteredCustomerCatalogs != null"
        [(element)]="element"
        (customerCatalogChanged)="save()"
        (basePriceChanged)="save()"
      ></app-product-sale-form>
    </syslink-tab>
    <syslink-tab
      key="Stockable"
      [visible]="authService.hasPermission(this.basePermissionKey + '.stock.tab.view') && element.Stockable"
      label="Stock"
    >
      <app-product-stock-form
        *ngIf="element.Stockable"
        [(element)]="element"
      ></app-product-stock-form>
    </syslink-tab>
    <syslink-tab
      key="Other"
      label="Other"
      [visible]="authService.hasPermission(this.basePermissionKey + '.other.tab.view') && element.Stockable"
    >
      <app-product-other-form [(element)]="element"></app-product-other-form>
    </syslink-tab>
    <syslink-tab
      key="Statistics"
      label="Statistics"
      [visible]="authService.hasPermission(this.basePermissionKey + '.statistics.tab.view') && (element.Sellable || element.Purchasable)"
      *ngIf="element.Id"
    >
      <app-product-statistics [element]="element" [productId]="element.Id"></app-product-statistics>
    </syslink-tab>
  </syslink-tabs>
</div>

<syslink-confirm-modal
  #deleteConfirm
  title="Delete"
  content="DeleteConfirmMessage"
  (onValidate)="productDelete()"
  validateText="Confirm"
  cancelText="Cancel"
></syslink-confirm-modal>
