<syslink-select
    #ThirdSelect
    [label]="label"
    [store]="thirdsService.store"
    [filter]="filter"
    [expand]="expand"
    displayKey="Fullname"
    [model]="element.ThirdId"
    (modelChange)="onThirdChanged($event)"
    [detailsUrl]="detailsUrl"
    [canAdd]="filter[0].includes('customer')?(authService.hasPermission('thirds-customers') && authService.hasPermission('thirds.customers.list.add')):(authService.hasPermission('thirds-suppliers') && authService.hasPermission('thirds.suppliers.list.add'))"
    [canView]="filter[0].includes('customer')?authService.hasPermission('thirds-customers'):authService.hasPermission('thirds-suppliers')"
    [disabled]="!canEdit"
    [canRefresh]="true"
></syslink-select>
<ng-container *ngIf="element.ThirdId && element.ThirdId.Id">
    <div
        id='documentData'
        class="mt-3 p-3 border border-secondary"
    >
        <syslink-input-text
            label="Name"
            [(model)]="element.ThirdId.Fullname"
            [disabled]="true"
        ></syslink-input-text>
        <syslink-select
            label="Addresses"
            [store]="thirdAddressService.store"
            [filter]="'ContactInformations/any(c:c/ThirdId/Id eq '+ element.ThirdId.Id + ')'"
            [(model)]="element.AddressId"
            [displayKey]="'FormattedAddress'"
            [disabled]="!canEdit"
            (modelChange)="onAddressesChanged($event)"
        ></syslink-select>
        <div
            id="address"
            class="mt-3"
        >
            <syslink-textarea
                label="Address"
                [model]="element.AddressId?.FormattedAddress"
                [disabled]="true"
            ></syslink-textarea>
        </div>
        <syslink-input-text
            label="Vat number"
            [(model)]="element.VatNumber"
            [disabled]="true"
        ></syslink-input-text>
    </div>

    <!-- Contact -->
    <syslink-select
        label="Contact"
        [items]="element.ThirdId.ThirdContacts"
        displayKey="Fullname"
        valueKey="Id"
        [model]="element.ContactId.Id"
        [disabled]="!canEdit"
        (modelChange)="onContactChanged($event)"
    ></syslink-select>
    <div
        *ngIf="element.ContactId"
        class="mt-3 p-3 border border-secondary"
    >
        <syslink-input-text
            label="Name"
            [disabled]="true"
            [model]="element.ContactId.Fullname"
        ></syslink-input-text>
        <syslink-input-text
            label="Email"
            [disabled]="true"
            [model]="element.ContactId.DisplayEmail"
        ></syslink-input-text>
        <syslink-input-text
            label="Phone"
            [disabled]="true"
            [model]="element.ContactId.DisplayPhoneNumber"
        ></syslink-input-text>
    </div>
    <ng-container *ngIf="showDelivery">
        <syslink-select
            label="DeliveryAddress"
            [store]="thirdAddressService.store"
            [filter]="'ContactInformations/any(c:c/ThirdId/Id eq '+ element.ThirdId.Id + '  and c/UsedForDelivery eq true)'"
            [(model)]="element.DeliveryAddressId"
            [displayKey]="'FormattedAddress'"
            [disabled]="!canEdit"
        ></syslink-select>
    </ng-container>
</ng-container>

<!-- Use for -->
<!-- ------------------------------------- -->
<!-- <div
    class="row mt-3 me-3 p-3"
    *ngIf="element.ThirdId && element.ThirdId.Id"
>
    <div class="col-12">
        <label>{{'UseFor' | translate }}</label>
    </div>
    <div class="col-4">
        <syslink-checkbox
            name="Quotes"
            label="Quotes"
            [(model)]="element.UsedForQuote"
            (modelChange)="onUsedChange($event, 'UsedForQuote')"
        ></syslink-checkbox>
    </div>
    <div class="col-4">
        <syslink-checkbox
            name="Invoice"
            label="Invoice"
            [(model)]="element.UsedForInvoice"
            (modelChange)="onUsedChange($event, 'UsedForInvoice')"
        ></syslink-checkbox>
    </div>
    <div class="col-4">
        <syslink-checkbox
            name="Contract"
            label="Contract"
            [(model)]="element.UsedForContract"
            (modelChange)="onUsedChange($event, 'UsedForContract')"
        ></syslink-checkbox>
    </div>
</div> -->