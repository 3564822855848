import { Component, EventEmitter, Input, Output } from '@angular/core';
import { InputComponent } from '../input/input.component';

@Component({
  selector: 'syslink-input-text',
  templateUrl: './input-text.component.html',
  styleUrls: ['./input-text.component.scss']
})
export class InputTextComponent extends InputComponent {
  /**
   * Text input type
   */
  @Input() public type: 'email' | 'password' | 'search' | 'tel' | 'text' | 'url' = 'text';

  /**
   * Example : 000/00.00.00
   * 0 - Digit
   * 9 - Digit | space
   * # - Digit | space | '+' | '-'
   * L - Literal
   * C - Character && !space
   * c - Character
   * A - Alphanumeric
   * a - Alphanumeric | space
   */
  @Input() public mask: string = '';

  /**
   * Character user for mask
   */
  @Input() public maskChar: string = '_';

  /**
   * Define if a placeholder should be shown
   */
  @Input() public placeholder: boolean = true;

  /**
   * Specifies the maximum number of characters you can enter into the textbox.
   */
  @Input() public maxLength: string | number = "null";

  /**
   * On enter key press add event
   */
  @Output('onEnterKey') public onEnterKey: EventEmitter<any> = new EventEmitter<any>();

  public override class: string = 'py-2';
}