import { Injectable } from '@angular/core';
import { ODataService } from '../../core/services/oData.service';
import { TaxRate } from './tax-rate';
import { LoadOptions } from 'devextreme/data';

@Injectable({
  providedIn: 'root'
})
export class TaxRatesService extends ODataService<TaxRate> {
  public url = 'TaxRate';
  public override defaultOptions: LoadOptions = {
   filter:['IsVisible eq true']
  };
}