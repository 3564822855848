import { Injectable } from '@angular/core';
import { LoadOptions } from 'devextreme/data';
import { ApiService } from '../../../core/api.service';
import { StockMoveDocumentLinesService } from '../../stock-move-documents/stock-move-document-lines/stock-move-document-lines.service';
import { StockMoveInternalLine } from './stock-move-internal-line.model';

@Injectable({
  providedIn: 'root'
})
export class StockMoveInternalLinesService extends StockMoveDocumentLinesService<StockMoveInternalLine> {
  public override url: string = 'StockMoveInternalLine';
  public override documentType: string = 'StockMoveInternal';
  public override defaultOptions: LoadOptions = {
    expand: ['Discount', 'ParentId']
  };

  constructor(public override api: ApiService) {
    super(api);
  }

  public override async getInstance(internal?: Partial<StockMoveInternalLine>): Promise<any> {
    let element = {
      ...internal,
    };
    return element;
  }

  public async updateParentId(StockMoveInternalLine: StockMoveInternalLine, targetParentId: number) {
    return await this.api.sendRequest('/api/StockMoveDocumentLine/' + StockMoveInternalLine.Id + '/changeParent/' + targetParentId, 'POST');
  }
}
