<syslink-modal
  #modal
  title="Select user"
  (onValidate)="onValidateButtonClicked($event)"
  width="350"
>
  <syslink-select
    label="User"
    [store]="usersService.store"
    [(model)]="modal.data"
    displayKey="Name"
    [filter]="['IsActive eq true']"
  ></syslink-select>

</syslink-modal>
