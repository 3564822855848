import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ViewComponent } from '../../../../../../libraries/syslink-components/src/lib/helpers/view/view.component';
import { CountriesService } from '../../../base/addresses/countries/countries.service';
import { OriginsService } from '../../../base/origins/origins.service';
import { LanguagesService } from '../../../base/languages/languages.service';
import { ThirdRolesService } from '../third-roles/third-roles.service';
import { Third } from '../third.model';
import { SubjugationCategoriesService } from '../../../accountings/subjugation-categories/subjugation-category.service';

@Component({
  selector: 'app-third-form',
  templateUrl: './third-form.component.html',
  styleUrls: ['./third-form.component.scss']
})
export class ThirdFormComponent extends ViewComponent {
  @Input() public element: Third = new Third();
  @Output() public elementChange: EventEmitter<Third> = new EventEmitter<Third>();
  @Output() public isProfessionalChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() public thirdRolesChange: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    // public originsService: OriginsService,
    public thirdRolesService: ThirdRolesService
  ) {
    super();
  }
  public onIsProfessionalChanged(e: any) {
    this.element.IsProfessional = !this.element.IsProfessional;
    this.isProfessionalChange.emit(e)
  }


}
