<div *ngIf="reportType == 'SaleOrder' || reportType == 'SaleInvoice' || reportType == 'SaleCreditNote'">
  <app-payments
    [element]="element.Payments"
    [document]="element"
    (paymentsChange)="paymentsChange.emit($event)"
    [reportType]="reportType"
    [filterPaymentType]="reportType == 'SaleOrder'?['Code eq \'Advance\'']:''"
  ></app-payments>
</div>
<div class="row mt-3">
  <div class="col-6">
    <app-sale-document-finance-grid
    [permissionKey]="'finance.taxes.list'"
    [items]="element.DocumentFinances"
    ></app-sale-document-finance-grid>
  </div>
</div>
<div class="row" *ngIf="reportType != 'SaleOrder'">
  <div class="col-2">
    <syslink-input-text
      label="Structured communication"
      [disabled]="true"
      [model]="getStructuredCommunication()"
    ></syslink-input-text>
  </div>
</div>
