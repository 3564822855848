export class ChartSerie{
  public argumentField?: string;
  public valueField?: string;
  public barWidth?: number;
  public type?: 'line' | 'bar' | 'stackedBar' | 'area' | 'spline' | 'stepArea' | 'stepLine' | 'scatter' | 'candlestick' | 'stock' | 'rangeArea' = 'line';
  public name?:string;
  public label?:any={visible:true,format: "fixedPoint", customizeText:"customizeLabel"}

  customizeLabel(pointInfo:any) {
    return `${pointInfo.valueText} unités`;
  }

  constructor(element: Partial<ChartSerie>) {
    Object.assign(this, element);
  }
}
