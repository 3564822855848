import { Injectable } from '@angular/core';
import { ODataService } from '../../../core/services/oData.service';
import { WorkTime } from './work-time';
import { ThirdsService } from '../../../thirds/thirds/thirds.service';
import { AuthService } from '../../../core/auth/auth.service';
import { WorkTypesService } from '../../works/work-types/work-types.service';
import { LoadOptions } from 'devextreme/data';
import { UsersService } from '../../../core/auth/users/users.service';
import { WorkTimeInvoicingLine } from '../work-time-invoicing-line/work-time-invoicing-line.model';
import { NotificationsService } from 'projects/libraries/syslink-components/src/public-api';
import { Task } from '../../tasks/tasks/task.model';
@Injectable({
  providedIn: 'root'
})
export class WorkTimesService extends ODataService<WorkTime> {

  public override url: string = "WorkTime";
  private instance: WorkTime | undefined;
  public override defaultOptions: LoadOptions<any> = {
    expand: [
      'ThirdId',
      'UserId.ThirdId',
      'TypeId',
      'TaskId',
      'TaskId.ThirdId',
      'SaleInvoiceLineId.HeaderId'
    ]
  }

  constructor(
    private authService: AuthService,
    private workTypesService: WorkTypesService,
    private thirdsService: ThirdsService,
    private usersService: UsersService,
  ) {
    super();
  }

  public override format(element: Partial<WorkTime>): WorkTime {
    var result: any = {
      ...element,
      ThirdId: element.ThirdId?.Id,
      TypeId: element.TypeId?.Id,
      UserId: element.UserId?.Oid,
      TaskId: element.TaskId?.Id,
    };

    delete result.Duration;

    return result;
  }

  public override async getInstance(params?: Partial<WorkTime> | undefined): Promise<any> {
    const currentUser = this.authService.user != undefined ? this.usersService.format(this.authService.user) : undefined;
    const defaultWorktype = await this.workTypesService.findByID(1);

    // Calculate dates and durations
    // -----------------------------
    let startDate = new Date();
    
    startDate.setSeconds(0);
    startDate.setMilliseconds(0);    

    let endDate = new Date(startDate);
    endDate.setMinutes(endDate.getMinutes() + 15);

    if (!this.instance) {
      this.instance = new WorkTime({
        TypeId: defaultWorktype,
        StartDate: startDate,
        EndDate: endDate,
        UserId: currentUser,
        ...params
      });
    }

    this.instance.updateDuration();

    const result: Partial<WorkTime> = this.instance;
    this.instance = undefined;
    return result;
  }

  public invoice(invoiceAssignementDTO: any): Promise<void> {
    return this.apiService.sendRequest(`/api/TimeManagement/invoice`, "POST", invoiceAssignementDTO);
  }

  public addInvoiceLine(invoiceId: number, workTimeInvoicingLines: WorkTimeInvoicingLine[]): Promise<void> {
    return this.apiService.sendRequest(`/api/odata/${this.url}/invoice/${invoiceId}`, "POST", workTimeInvoicingLines);
  }

  public getInvoicingLines(thirdId: number): Promise<WorkTimeInvoicingLine[]> {
    return this.apiService.sendRequest(`/api/odata/${this.url}/invoicingLines/${thirdId}`);
  }
  // -------------------------------------------------------------------------------------------------------

  public async tryUpdateMultiples(elements: WorkTime[], fields: Array<{ field: string, value: any }>) {
    if (!this.validateFieldValueAllowed(elements, fields)) return;

    var updateValues: any = {};
    fields.forEach(fieldDatas => {
      updateValues[fieldDatas.field] = fieldDatas.value;
    });
    await this.updateMultiples(elements.map(row => row.Id), updateValues);
  }
  public validateFieldValueAllowed(elements: WorkTime[], fields: Array<{ field: string, value: any }>): boolean {
    var result = true;
    fields.forEach(fieldDatas => {
      switch (fieldDatas.field) {
        case "IsBillable":
          result = fieldDatas.value ? this.canMarkAsBillable(elements) : this.canMarkAsNotBillable(elements);
          break;
      }
    });

    return result;
  }
  public can(actionCode: WorkTimeActionCode, elements: WorkTime[], value?: Partial<WorkTime>) {
    switch (actionCode) {
      case WorkTimeActionCode.Invoice: return this.canInvoice(elements, value);
      default: throw "Action on Task is not allowed";
    }
  }
  // Invoicing validations
  // ---------------------
  // Invoice
  // -------
  private canInvoice(elements: WorkTime[], value?: Partial<WorkTime>) {
    var result: boolean = true;

    if (elements?.some(row => row.IsBillable == false)) {
      NotificationsService.sendErrorMessage("One or more selected line(s) are not billable");
      result = false;
    }

    if (elements?.filter(row => row.IsBilled).length == elements?.length) {
      NotificationsService.sendErrorMessage("One or more selected line(s) are already billed");
      result = false;
    }

    if (elements?.some(row => !row.ThirdId)) {
      NotificationsService.sendErrorMessage("Third is required");
      result = false;
    }

    if (elements?.reduce((thirdIds: Set<number>, row: WorkTime) => {
      if (row.ThirdId?.Id)
        thirdIds.add(row.ThirdId.Id)

      return thirdIds;
    }, new Set<number>()).size > 1) {
      NotificationsService.sendErrorMessage("Third must be unique");
      result = false;
    }

    return result;
  }
  // Billable
  // --------
  private canMarkAsBillable(elements: WorkTime[], value?: Partial<WorkTime>) {
    var result: boolean = true;

    // Checking it still not billable elements
    // ---------------------------------------
    if (elements.filter(row => row.IsBillable).length == elements.length) {
      NotificationsService.sendErrorMessage("All selected line(s) are already billable");
      result = false;
    }

    if (elements?.filter(row => row.TaskId && !row.TaskId.IsBillable).length) {
      NotificationsService.sendErrorMessage("One or more selected line(s) are linked to not billable task");
      result = false;
    }

    return result;
  }

  // Not billable
  // ------------
  private canMarkAsNotBillable(elements: WorkTime[], value?: Partial<WorkTime>) {
    var result: boolean = true;

    // Checking it still not billable elements
    // ---------------------------------------
    if (elements.filter(row => !row.IsBillable).length == elements.length) {
      NotificationsService.sendErrorMessage("All selected line(s) are already not billable");
      result = false;
    }

    if (elements?.some(row => row.IsBilled == true)) {
      NotificationsService.sendErrorMessage("One or more selected line(s) are already billed");
      result = false;
    }

    return result;
  }

  // Task validations
  // ---------------------
  // Task
  // -------
  public canTask(elements: WorkTime[],task:Task|undefined, value?: Partial<WorkTime>) {
    var result: boolean = true;

    if(task==undefined){
      NotificationsService.sendErrorMessage('Task cannot be empty');
      result = false;
    }

    if (elements?.some(row => !row.ThirdId)) {
      NotificationsService.sendErrorMessage("Third is required");
      result = false;
    }

    if (elements?.reduce((thirdIds: Set<number>, row: WorkTime) => {
      if (row.ThirdId?.Id)
        thirdIds.add(row.ThirdId.Id)

      return thirdIds;
    }, new Set<number>()).size > 1) {
      NotificationsService.sendErrorMessage("Third must be unique");
      result = false;
    }

    if (task?.ThirdId?.Id!=elements[0]?.ThirdId?.Id) {
      NotificationsService.sendErrorMessage("Third is different");
      result = false;
    }

    return result;
  }

  // -------------------------------------------------------------------------------------------------------
}
export enum WorkTimeActionCode {
  Task = 'Task',
  Invoice = 'Invoice',
  MarkAsNotBillable = 'MarkAsNotBillable',
  MarkAsBillable = 'MarkAsBillable',
  Delete = 'Delete',

}
