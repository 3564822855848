import { Component } from '@angular/core';
import { PageComponent } from 'projects/libraries/syslink-components/src/public-api';

@Component({
  selector: 'app-assignement-price-coefs',
  templateUrl: './assignement-price-coefs.component.html',
  styleUrls: ['./assignement-price-coefs.component.scss']
})
export class AssignementPriceCoefsComponent extends PageComponent {

}
