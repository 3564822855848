import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { LanguagesService } from 'projects/erp-app/src/app/base/languages/languages.service';
import { UserGroup } from '../user-group.model';
import { TranslationsModalComponent } from '../../../../../../../libraries/syslink-components/src/public-api';
import { AuthService } from '../../auth.service';

@Component({
  selector: 'app-user-group-form',
  templateUrl: './user-group-form.component.html',
  styleUrls: ['./user-group-form.component.scss']
})
export class UserGroupFormComponent {
  @Input() element: UserGroup = new UserGroup();
  @Output() elementChange: EventEmitter<UserGroup> = new EventEmitter<UserGroup>();

  constructor(
    public languagesService: LanguagesService,
    public authService: AuthService
  ) { }

  // Translations
  // ------------
  @Input() public languages: any[] = [];
  @ViewChild('translationsCellModal') public translationsCellModal?: TranslationsModalComponent;

  public onTranslationsUpdate(newValue: string) {
    if (!this.element.NameTranslationId) return;

    this.element.NameTranslationId.TranslatedValue = newValue;

    const currentTranslation = this.element.NameTranslationId.Translations.find(t => t.LanguageId.Id === this.authService.user?.LanguageId?.Id);
    if (currentTranslation) {
      currentTranslation.Value = newValue;
    }
  }

  public onTranslationsBtnClicked() {
    this.translationsCellModal?.open(this.element.NameTranslationId?.Translations || [], this.element.NameTranslationId);
  }

  public onTranslationsModalValidated(e: any) {
    if (!this.element.NameTranslationId) return;
    this.element.NameTranslationId.Translations = e;
    this.element.NameTranslationId.TranslatedValue = this.element.NameTranslationId.Translations.find(t => t.LanguageId.Id === this.authService.user?.LanguageId?.Id)?.Value;
  }
}
