<div
  *ngIf="!disabled"
  class="d-flex justify-content-between align-items-center mb-3"
>
  <div class="col-3">
  </div>
  <div class="col-6">
    <app-product-picker
      *ngIf="authService.hasPermission(this.basePermissionKey + '.content.product.add') && this.element.Id"
      [permissionKey]="'productPicker.list'"
      (noResult)="onProductNotFound($event)"
      [sellable]="type =='customer'? true : false"
      [purchasable]="type =='supplier'? true : false"
      [stockable]="true"
      (singleResult)="onAddLine('product', $event)"
      [searchFields]="['Name', 'InternalReference']"
      (onAddNewProductBtn)="onCreateProduct()"
    ></app-product-picker>
  </div>
  <div class="col-3">
  </div>
</div>

<app-document-lines
  [permissionKey]="'content.list'"
  [documentLines]="element.Lines"
  (deleteLine)="onDeleteLine($event)"
  [(selectedKey)]="selectedKey"
  (selectedKeyChange)="onSelectedKeyChange($event)"
  (updateLine)="onUpdateLine($event)"
  (onReorder)="OnReorder($event)"
  [columns]="columns"
  [reportType]="reportType"
  [subModuleCode]="subModuleCode"
  [disabled]="disabled"
></app-document-lines>

<syslink-modal
  #createNewProduct
  title="Create"
  width="500px"
  validateText="Yes"
  cancelText="No"
  (onValidate)="onCreateProduct()"
>
  <div class="text-center p-3">
    {{ 'Searched text not found.' | translate }} <br />
    {{ 'Do you want to create a new One?' | translate }}
  </div>
</syslink-modal>