import { Directive, Input, ViewContainerRef } from '@angular/core';
import { ComponentContainerService } from './component-container.service';

@Directive({
  selector: '[componentContainer]'
})
export class ComponentContainerDirective {

  @Input() key: string = "";
  @Input() data: any;

  constructor(
    public viewContainerRef: ViewContainerRef,
    public ComponentContainerService: ComponentContainerService
  ) {
  }

}
