import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PaymentMethodsService } from '../../../../base/payments/payment-methods/payment-methods.service';
import { PriceListsService } from '../../price-lists/price-lists.service';
import { TaxRatesService } from '../../../../base/tax-rates/tax-rates.service';
import { Customer } from '../customer.model';
import { DocumentDelaysService } from '../../../../base/documents/document-delays/document-delays.service';

@Component({
  selector: 'app-customer-form',
  templateUrl: './customer-form.component.html',
  styleUrls: ['./customer-form.component.scss']
})

export class CustomerFormComponent implements OnInit{
 
  @Input() public element: Customer = new Customer();
  @Output() public elementChange: EventEmitter<Customer> = new EventEmitter<Customer>();

  constructor(
   public documentDelaysService:DocumentDelaysService,
   public paymentMethodsService:PaymentMethodsService,
   public priceListsService:PriceListsService,
   public taxRatesService:TaxRatesService
  ) { }

  ngOnInit(): void {
  }
}
