<div class="row">
    <div class="col-4">
        <table class="table table-hover">
            <tbody>
                <tr *ngFor="let list of [
                    {label: 'Currency', key: 'PaymentDataCurrency'},
                    {label: 'PaymentsOnFile', key: 'PaymentsOnFile'},
                    {label: 'PaymentsOnTime', key: 'PaymentsOnTime'},
                    {label: 'PaymentsPaidLate', key: 'PaymentsPaidLate'},
                    {label: 'TotalInvoiceValues', key: 'TotalInvoiceValues'},
                    {label: 'AverageInvoiceValues', key: 'AverageInvoiceValues'},
                    {label: 'PaymentsStillOwing', key: 'PaymentsStillOwing'},
                    {label: 'TotalBalanceStillOwing', key: 'TotalBalanceStillOwing'},
                    {label: 'HighestInvoiceValueOutstanding', key: 'HighestInvoiceValueOutstanding'},
                    {label: 'NumberofPaymentsStillOwingLate', key: 'NumberofPaymentsStillOwingLate'},
                    {label: 'TotalBalanceStillOwingLate', key: 'TotalBalanceStillOwingLate'},
                    {label: 'HighestInvoiceValueOutstandingLate', key: 'HighestInvoiceValueOutstandingLate'},
                    {label: 'NumberofPaymentsSentLegal', key: 'NumberofPaymentsSentLegal'}
                ]">
                    <th>{{list.label| translate}}</th>
                    <td class="text-end">{{getField(list.key) || '-'}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>