import { Component, Input } from '@angular/core';

@Component({
  selector: 'syslink-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss']
})
export class BadgeComponent {
  @Input() public imageUrl?: string;
  @Input() public content: string = '';
  @Input() public title: string = '';
  @Input() public color: string = '#03a9f4';
  @Input() public backgroundColor: string = 'white';
  @Input() public foregroundColor: string = 'black';

  @Input() public size: 'small' | 'medium' | 'big' = 'medium';
}
