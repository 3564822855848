<syslink-modal
    #modal
    [title]="title"
    width="450px"
    (onValidate)="onValidateButtonClicked(element)"
    validateText="Validate"
    cancelText="Cancel"
>
    <syslink-select
        [label]="'Number plate' | translate"
        [store]="NumberPlatesService.store"
        [(model)]="element.NumberPlate"
        [valueKey]="'Value'"
        [displayKey]="'Value'"
        [allowClearing]="true"
        (onCustomValue)="onNumberPlateCustomValue($event)"
    ></syslink-select>
    <syslink-select
        *ngIf="!element.NumberPlate?.length && showThird && thirdFilter"
        name="third"
        label="Third"
        [store]="thirdsService.store"
        [filter]="thirdFilter"
        [select]="['Fullname','Id','Blocked']"
        displayKey="Fullname"
        [(model)]="element.ThirdId"
        [detailsUrl]="detailsUrl"
        [canAdd]="thirdFilter[0].includes('customer')?(authService.hasPermission('thirds-customers') && authService.hasPermission('thirds.customers.list.add')):(authService.hasPermission('thirds-suppliers') && authService.hasPermission('thirds.suppliers.list.add'))"
        [canView]="thirdFilter[0].includes('customer')?authService.hasPermission('thirds-customers'):authService.hasPermission('thirds-suppliers')"
        [canRefresh]="true"
        [allowClearing]="true"
    ></syslink-select>
    <syslink-select
        *ngIf="element.NumberPlate?.length"
        name="third"
        [label]="'Third' | translate"
        [store]="NumberPlatesService.store"
        [filter]="['Value eq \'' + element.NumberPlate + '\'']"
        [expand]="['CustomerId.ThirdId']"
        [displayKey]="'CustomerId.ThirdId.Fullname'"
        [canAdd]="true"
        [canView]="true"
        [canRefresh]="true"
        [allowClearing]="true"
        (modelChange)="element.ThirdId = $event.CustomerId.ThirdId"
    ></syslink-select>
    <syslink-input-text
        *ngIf="showSubject"
        name="subject"
        label="Subject"
        [(model)]="element.Subject"
    ></syslink-input-text>
</syslink-modal>