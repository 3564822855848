import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateLoader, TranslateModuleConfig, TranslateService } from '@ngx-translate/core';
import frMessages from "devextreme/localization/messages/fr.json";
import enMessages from "devextreme/localization/messages/en.json";
import { locale, loadMessages } from "devextreme/localization";
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { environment } from 'projects/erp-app/src/environments/environment';
import { ODataService } from '../../../core/services/oData.service';
import { Translation } from './translation';
import { AppInjectorService } from 'projects/libraries/syslink-components/src/lib/services/app-injector.service';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TranslationsService extends ODataService<Translation> {
  public url: string = 'Translation';

  static labelTranslationsUrl: string = environment.base_url + '/api/labelTranslations/';
  static devExtremLocales: any[] = [enMessages, frMessages];
  static allowedLanguages: string[] = environment.allowedLanguages;
  static currentLanguage: string = environment.defaultLanguage;

  /**
   * 
   * @param language TranslationsService.allowedLanguages
   * @returns 
   */
  static async updateTranslations(language: string): Promise<any> {
    if (!TranslationsService.allowedLanguages.includes(language)) return;

    TranslationsService.currentLanguage = language;

    TranslationsService.setDevExtremLocale();
    await TranslationsService.setTranslateServiceLocale();
  }

  /**
   * Set the devextremen current Language
   */
  private static setDevExtremLocale() {
    TranslationsService.devExtremLocales.forEach(local => loadMessages(local));
    locale(TranslationsService.currentLanguage);
  }

  /**
   * set the locale used for .instant("") and TranslatePipe
   */
  private static async setTranslateServiceLocale() {
    const translateService: TranslateService = AppInjectorService.injector.get(TranslateService);
    translateService.setDefaultLang('fr');
    await lastValueFrom(translateService.use(TranslationsService.currentLanguage));
  }

  /**
   * Generate the defaults translation module configuration
   * @returns TranslateModuleConfig
   */
  static getTranslateModuleConfig(): TranslateModuleConfig {
    return {
      defaultLanguage: environment.defaultLanguage,
      loader: {
        provide: TranslateLoader,
        useFactory: ((http: HttpClient) => new TranslateHttpLoader(http, TranslationsService.labelTranslationsUrl, '')),
        deps: [HttpClient]
      }
    };
  }
}
