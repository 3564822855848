import { Injectable } from '@angular/core';
import { ODataService } from '../../../core/services/oData.service';
import { StockMoveDocumentStatus } from './stock-move-document-status.model';

@Injectable({
  providedIn: 'root'
})
export class StockMoveDocumentStatusesService<TStockMoveDocumentStatus extends StockMoveDocumentStatus> extends ODataService<TStockMoveDocumentStatus> {
  public url: string = 'StockMoveDocumentHeaderStatus';
 
  constructor() {
    super();
  }
}
