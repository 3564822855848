<div class="form">
    <div class="row">
        <div class="col-1">
            <div
                class="text-primary text-center w-100 h5 py-1 m-0"
                style="font-size:5rem;"
            >
                <i class='fa-solid fa-user'></i>
            </div>
        </div>
        <div class="col-3">
            <syslink-input-text
                label="Code"
                [(model)]="element.Code"
            ></syslink-input-text>
            <syslink-input-text
                label="Name"
                [(model)]="element.Name"
            ></syslink-input-text>
        </div>
        <div class="col-8">
        </div>
        <div class="col-1">
            <syslink-switch
                [(model)]="element.IsActive"
                type="box"
                [falseText]="'Blocked' | translate"
                [trueText]="'Active' | translate"
            ></syslink-switch>
        </div>
    </div>
</div>