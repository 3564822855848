import { Injectable } from '@angular/core';
import { ODataService } from '../../../core/services/oData.service';
import { LoadOptions } from 'devextreme/data';
import { TaskTaskStatus } from './task-task-status.model';
import { Task } from '../tasks/task.model';
import { TaskStatus } from '../task-statuses/task-status.model';

@Injectable({
  providedIn: 'root'
})
export class TaskTaskStatusesService extends ODataService<TaskTaskStatus> {
  public url = 'TaskTaskStatus';
  public override defaultOptions: LoadOptions = {
    expand: ['TaskId', 'StatusId']
  };

  constructor(
  ) {
    super();
  }

  public override format(element: Partial<TaskTaskStatus>): TaskTaskStatus {
    var result: any = {
      ...element,
      TaskId:element.TaskId?.Id,
      StatusId:element.StatusId?.Id
    };
    return result;
  }

  public override async getInstance(params?: Partial<TaskTaskStatus>): Promise<TaskTaskStatus> {
    var element = new TaskTaskStatus({
      Date: new Date(),
      TaskId: new Task(),
      StatusId: new TaskStatus(),
      ...params
    });
    return element;
  }
}
