<div class="d-flex py-2">
  <syslink-arrow-stepper-item
    class="w-100 flex-grow"
    *ngFor="let item of items"
    (clicked)="onItemClicked(item)"
    [item]="item"
  >
    <div class="h-100 d-flex flex-column justify-content-center align-items-center">
      <span class="fw-bold text-white text-center">{{ item.data.status.Name }}</span>
      <ng-container *ngIf="item.data.documentStatus">
        <span class="fw-bold text-white text-center">{{ item.data.documentStatus.Date |date }}</span>
      </ng-container>
    </div>
  </syslink-arrow-stepper-item>
</div>

<syslink-confirm-modal
  #changeStatusConfirmModal
  title="{{'Change status'}}"
  content="{{'Confirm change status'}}"
  (onValidate)="onChangeStatusConfirmed()"
  [validateText]="'Confirm'"
  [cancelText]="'Cancel'"
></syslink-confirm-modal>