import { Component, Input } from '@angular/core';
import { AssignementPriceCoefService } from '../assignement-price-coef.service';
import { BaseGridComponent } from 'projects/libraries/syslink-components/src/lib/grid/base-grid/base-grid.component';
import { AssignementPriceCoef } from '../assignement-price-coef.model';
import { SyslinkColumn } from 'projects/libraries/syslink-components/src/lib/helpers/SyslinkColumn';
import { WorkTypesService } from '../../../works/work-types/work-types.service';
import { TimetableLinesService } from '../../../timetables/timetable-lines/timetable-lines.service';
import { PriceCoefsService } from '../../price-coefs/price-coefs.service';
import { NotificationsService } from 'projects/libraries/syslink-components/src/public-api';

@Component({
  selector: 'app-assignement-price-coef-grid',
  templateUrl: './assignement-price-coef-grid.component.html',
  styleUrls: ['./assignement-price-coef-grid.component.scss']
})
export class AssignementPriceCoefGridComponent extends BaseGridComponent<AssignementPriceCoef> {
  override storageKey?: string | undefined = "AssignementPriceCoef-grid";
  @Input() public columns: SyslinkColumn[] = [
    new SyslinkColumn({ field: "WorkTypeId", type: "object", label: "Work types", cellTemplate: 'select-value', editCellTemplate: "select-cell", data: { service: this.workTypesService, displayKey: 'Name' } }),
    new SyslinkColumn({ field: "TimetableLineId", type: "object", label: "Timetables", cellTemplate: 'select-value', editCellTemplate: "select-cell", filterable: false, headerFilterable: false, sortable: false, data: { service: this.timetableLinesService, displayKey: 'FormattedName', sort: "Day" } }),
    new SyslinkColumn({ field: "PriceCoefId", type: "object", label: "Pricecoefs", cellTemplate: 'select-value', editCellTemplate: "select-cell", data: { service: this.priceCoefsService, displayKey: 'Name' } })
  ];

  constructor(
    public assignementPriceCoefService: AssignementPriceCoefService,
    public workTypesService: WorkTypesService,
    public timetableLinesService: TimetableLinesService,
    public priceCoefsService: PriceCoefsService
  ) {
    super();
    this.expand = this.assignementPriceCoefService.defaultOptions.expand;
  }

  public onBeforeAdd(e: any) {
    if (!e.data.WorkTypeId) {
      NotificationsService.sendErrorMessage('The field work type cannot be empty'); // Traduction : "Le champ type de travaux ne peut pas être vide"
      e.cancel = true;
      return;
    }

    if (!e.data.TimetableLineId) {
      NotificationsService.sendErrorMessage('The field timetable line cannot be empty'); // Traduction : "Le champ horaire ne peut pas être vide"
      e.cancel = true;
      return;
    }

    if (!e.data.PriceCoefId) {
      NotificationsService.sendErrorMessage('The field price coef cannot be empty'); // Traduction : "Le champ coefficient de prix ne peut pas être vide"
      e.cancel = true;
      return;
    }

    e.cancel = new Promise<void>(async (resolve, reject) => {
      if (!await this.can(e.data)) {
        reject();
      }
      resolve();
    });
    e.data.TimetableLineId = { Id: e.data.TimetableLineId.Id }
  }

  public onBeforeUpdate(e: any) {
    e.cancel = new Promise<void>(async (resolve, reject) => {
      const oldElement: Partial<AssignementPriceCoef> = JSON.parse(JSON.stringify(e.oldData));
      const newElement: Partial<AssignementPriceCoef> = JSON.parse(JSON.stringify(e.newData));
      // Check if already exist
      const assignementPriceCoef: AssignementPriceCoef = {
        ...oldElement,
        ...newElement
      };
      if (!await this.can(assignementPriceCoef)) {
        reject();
      }
      resolve();
    });

    if (!e.newData.TimetableLineId) return;
    e.newData.TimetableLineId = { Id: e.newData.TimetableLineId.Id }
  }

  private async can(element: AssignementPriceCoef): Promise<boolean> {
    var result = true;

    const filter: Array<any> = [
      ['WorkTypeId.Id', '=', element.WorkTypeId?.Id || null],
      ['TimetableLineId.Id', '=', element.TimetableLineId?.Id || null],
      ['PriceCoefId.Id', '=', element.PriceCoefId?.Id || null]
    ];

    const sameAssignementPriceCoef: AssignementPriceCoef[] = await this.assignementPriceCoefService.load({ filter, select: ['Id'] });
    
    if (sameAssignementPriceCoef.length && sameAssignementPriceCoef[0].Id !== element.Id) {      
      NotificationsService.sendErrorMessage('This assignement price coef overlap with another one.');
      result = false;
    }
    return result;
  }
}
