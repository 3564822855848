import { Component, Input, OnInit } from '@angular/core';
import { DXCell } from '../dxcell';
import { SwitchType } from '../../../form/switch/switch.component';

@Component({
  selector: 'syslink-boolean-cell',
  templateUrl: './boolean-cell.component.html',
  styleUrls: ['./boolean-cell.component.scss']
})
export class BooleanCellComponent implements OnInit {
  @Input() cell: DXCell = new DXCell();
  @Input() data: BooleanCellData = new BooleanCellData();

  @Input() getColor?: Function;
  @Input() getText?: Function;

  ngOnInit(): void {
    this.data = this.data || new BooleanCellData();
    if (this.data.type == 'box' && !this.data.trueText && !this.data.falseText) {
      this.data.trueText = "True";
      this.data.falseText = "False";
    }
  }

}

export class BooleanCellData {
  public disabled: boolean = true;
  public trueText?: string;
  public trueColor: string = "success";
  public falseText?: string;
  public falseColor: string = "danger";
  public type: SwitchType = "switch";
  public getColor?: Function;
  public getText?: Function;

  constructor(entity?: Partial<BooleanCellData>) {
    Object.assign(this, entity);
  }
}
