import { BasicEntity } from "../../core/models/basic-entity";

export class StockLocation extends BasicEntity{
    public DepartmentId?:any;
    public ParentId?:StockLocation;
    
    constructor(entity?: Partial<StockLocation>) {
        super(entity);
        Object.assign(this, entity);
    }

}
