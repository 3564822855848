<ng-container *ngIf="element">
    <syslink-title
        *ngIf="element.No"
        [text]="element.No"
    ></syslink-title>

    <syslink-toolbar [actions]="toolbarActions"></syslink-toolbar>

    <app-document-statuses
        #documentStatuses
        [statuses]="availableStatuses"
        [documentStatuses]="element.Statuses"
        (statusChanged)="onDocumentStatusChanged($event)"
    ></app-document-statuses>

    <div class="mt-3 mb-5">
        <app-purchase-document-form
            [(element)]="element"
            [reportType]="reportType"
            [subModuleCode]="subModuleCode"
            (dateChange)="onDateChanged()"
            [disabled]="!canEditDocument()"
        ></app-purchase-document-form>
    </div>

    <div class="mt-4">
        <syslink-tabs>
            <syslink-tab
                label="Content"
                key="Content"
                [visible]="authService.hasPermission(this.basePermissionKey + '.content.tab.view')"
            >
                <app-purchase-document-content
                    [(element)]="element"
                    [reportType]="reportType"
                    [subModuleCode]="subModuleCode"
                    (selectedKeyChange)="onSelectedKeyChange($event)"
                    (elementLineChange)="onElementLineChange($event)"
                    [PurchaseDocumentService]="PurchaseDocumentService"
                    [PurchaseDocumentLinesService]="PurchaseDocumentLinesService"
                    [columns]="documentLinesColumns"
                    [disabled]="!canEditDocument()"
                ></app-purchase-document-content>
            </syslink-tab>

            <syslink-tab
                label="Third"
                key="Third"
                [visible]="authService.hasPermission(this.basePermissionKey + '.third.tab.view')"
            >
                <app-purchase-document-third
                    #purchaseDocumentThird
                    [(element)]="element"
                    [reportType]="reportType"
                    (documentData)="update(false)"
                    [disabled]="!canEditDocument()"
                ></app-purchase-document-third>
            </syslink-tab>

            <syslink-tab
                [label]="'Finance' | translate"
                key="Finance"
                [visible]="authService.hasPermission(this.basePermissionKey + '.finance.tab.view')"
            >
                <app-purchase-document-finance
                    [(element)]="element"
                    [reportType]="reportType"
                    (paymentsChange)="onPaymentChange($event)"
                ></app-purchase-document-finance>
            </syslink-tab>


            <syslink-tab
                label="Other"
                key="Other"
                [visible]="authService.hasPermission(this.basePermissionKey + '.other.tab.view')"
            >
                <app-purchase-document-other
                    #purchaseDocumentOther
                    [(element)]="element"
                    [reportType]="reportType"
                    (documentDelayChanged)="onDocumentDelayChanged($event)"
                    (globalDiscountChanged)="update()"
                    [disabled]="!canEditDocument()"
                ></app-purchase-document-other>
            </syslink-tab>
        </syslink-tabs>
    </div>
    <div
        class="mt-4"
        *ngIf="element.Id"
    > <app-document-relations
            #saleDocumentRelation
            [reportType]="reportType"
            [documentId]="element.Id"
            [service]="PurchaseDocumentService"
            [documentRelations]="element.DocumentRelations"
            (onRefreshClicked)="reloadDocumentRelation()"
        ></app-document-relations>
    </div>

    <app-mail-modal
        #emailModal
        *ngIf="mailTemplateType && mailModel"
        [(mailModel)]="mailModel"
        [mailTemplateType]="mailTemplateType"
        [element]="element"
    ></app-mail-modal>

    <syslink-confirm-modal
        #deleteConfirm
        title="Delete"
        content="DeleteConfirmMessage"
        (onValidate)="onDeleteConfirmed()"
        validateText="Confirm"
        cancelText="Cancel"
    ></syslink-confirm-modal>

    <app-document-copy-modal
        #copyModal
        (validate)="onCopyButtonClicked($event)"
    ></app-document-copy-modal>
</ng-container>