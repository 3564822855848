import { Component, Input } from '@angular/core';
import { InputComponent } from '../input/input.component';

@Component({
  selector: 'syslink-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss']
})
export class TextareaComponent extends InputComponent {
  @Input() public override mode: 'outlined' | 'underlined' | 'filled' = 'outlined';
  @Input() public height: string = '100';
}
