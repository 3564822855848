<div class="row">
    <div class="col-10">
    </div>
    <div class="col-4">
        <syslink-input-number
            [max]="getMaxDate()"
            [min]="2023"
            label="Year"
            [(model)]="selectedYear"
            (modelChange)="onChangeDate($event)"
        ></syslink-input-number>
    </div>
</div>
<ng-container *ngIf="element.HasCustomerRole">
    <syslink-chart
        *ngIf="authService.hasPermission(this.basePermissionKey + '.statistics.tab.SaleQuoteStatistic')"
        [title]="'Sale quotes' | translate"
        [legendHeader]="saleQuoteLegendHeader"
        [data]="saleQuoteData"
        [series]="series"
        [width]="400"
    ></syslink-chart>
    <syslink-chart
        *ngIf="authService.hasPermission(this.basePermissionKey + '.statistics.tab.SaleInvoiceStatistic')"
        [title]="'Sale invoices' | translate"
        [legendHeader]="saleInvoiceLegendHeader"
        [data]="saleInvoiceData"
        [series]="series"
        [width]="400"
    ></syslink-chart>
    <syslink-chart
        *ngIf="authService.hasPermission(this.basePermissionKey + '.statistics.tab.SaleCreditNoteStatistic')"
        [title]="'Sale credit notes' | translate"
        [legendHeader]="saleCreditNoteLegendHeader"
        [data]="saleCreditNoteData"
        [series]="series"
        [width]="400"
    ></syslink-chart>
</ng-container>

<ng-container *ngIf="element.HasSupplierRole">
    <syslink-chart
        *ngIf="authService.hasPermission(this.basePermissionKey + '.statistics.tab.PurchaseInvoiceStatistic')"
        [title]="'Purchase invoices' | translate"
        [legendHeader]="purchaseInvoiceLegendHeader"
        [data]="purchaseInvoiceData"
        [series]="series"
        [width]="400"
    ></syslink-chart>
    <syslink-chart
        *ngIf="authService.hasPermission(this.basePermissionKey + '.statistics.tab.PurchaseCreditNoteStatistic')"
        [title]="'Purchase credit notes' | translate"
        [legendHeader]="purchaseCreditNoteLegendHeader"
        [data]="purchaseCreditNoteData"
        [series]="series"
        [width]="400"
    ></syslink-chart>
</ng-container>