import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { NotificationsService } from '../../../../libraries/syslink-components/src/public-api';

@Injectable()
export class AppHttpInterceptor implements HttpInterceptor {
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // let customReq;
        // if (this.usersAuthenticationService.isLogged()) {
        //   customReq = request.clone({
        //     headers: request.headers.set('Authorization', 'Bearer ' + this.usersAuthenticationService.getToken())
        //   });
        // }
        // else {
        //   customReq = request.clone();
        // }

        return next
            .handle(request)
            .pipe(
                // tap((ev: HttpEvent<any>) => { this.onHttpEvent(ev) }),
                catchError((response: HttpErrorResponse) => {
                    console.error(response);

                    switch (response.status) {
                        case 400:
                            NotificationsService.sendErrorMessage(response.error);
                            break;
                        case 500:
                            NotificationsService.sendErrorMessage(response.message);
                            break;

                        default:
                            break;
                    }

                    return throwError(response);
                })
            );
    }

    //   onHttpEvent(e) {
    //     if (e.status && e.status == 200 && e.body && e.body.status && e.body.status == "error") {
    //       if (e.body.message == "Unauthorized") {
    //         localStorage.removeItem(environment.tokenKey);
    //         location.reload();
    //       }
    //     }
    //   }
}
