import { EventEmitter, Injectable, Output } from '@angular/core';
import { NotificationsService } from 'projects/libraries/syslink-components/src/public-api';

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  public error: EventEmitter<any> = new EventEmitter<any>();

  public sendError(e: any) {
    if (this.error.observed) this.error.emit(e);
    else {
      NotificationsService.sendError(e);
    }
  }

  constructor() {}
}
