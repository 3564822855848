import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UserRole } from '../user-role.model';

@Component({
  selector: 'app-user-role-form',
  templateUrl: './user-role-form.component.html',
  styleUrls: ['./user-role-form.component.scss']
})
export class UserRoleFormComponent {
  @Input() element: UserRole = new UserRole();
  @Output() elementChange: EventEmitter<UserRole> = new EventEmitter<UserRole>();

  constructor(
  ) { }
}
