<!-- TODO: REWORK DOUBLE CLICK TO UPDATE -->
<div class="d-flex border p-0 my-2" (dblclick)="null">
    <div class="col-4 d-flex flex-column align-items-center p-0">
        <div class="bg-secondary text-white text-center w-100 fw-bold py-2 m-0">
            {{boxText}}
        </div>
        <div class="text-primary bg-light text-center h-100 w-100 h5 py-1 m-0">
            <i class='fa-solid fa-{{icon}}'></i>
        </div>
    </div>
    <div class="col-8 d-flex flex-column p-0 ps-2">
        <div class="d-flex justify-content-between align-items-center">
            <span class="text-primary mt-1 mb-2 h6">{{ title }}</span>
            <i *ngIf="id" id="{{id}}" role="button" class="fa-solid fa-ellipsis-vertical px-2"></i>
        </div>
        <div class="d-flex justify-content-between">
            <ng-content></ng-content>
            <syslink-checkbox
                *ngIf="showSelector"
                [(model)]="selected"
                (modelChange)="selectorChanged($event)"
            ></syslink-checkbox>
        </div>
    </div>
</div>

<dx-context-menu *ngIf="id" [dataSource]="contextItems" id="{{id}}-context-menu" target="#{{id}}" showEvent="dxclick" (onItemClick)="onCtxMenuItemClicked($event)"></dx-context-menu>