import { Injectable } from '@angular/core';
import { LoadOptions } from 'devextreme/data';
import { ODataService } from '../../core/services/oData.service';
import { SubjugationCategory } from './subjugation-category.model';

@Injectable({
  providedIn: 'root'
})
export class SubjugationCategoriesService extends ODataService<SubjugationCategory> {
  public url: string = 'SubjugationCategory';
  public override defaultOptions: LoadOptions = {
    expand: []
  };
 
  constructor() {
    super();
  }
}
