<app-stock-move-outgoing-grid
    *ngIf="filter"
    #GridComponent
    [permissionKey]="'stockMoveCustomerOutgoings.list'"
    [canUpdate]="false"
    [filter]="filter"
    (add)="onAddButtonClicked()"
    detailsUrl="/stockmoves/customeroutgoings"
    storageKey="stock-move-customeroutgoings"
    [columns]="columns"
></app-stock-move-outgoing-grid>