import { Component, Input, ViewChild } from '@angular/core';
import { SyslinkColumn } from 'projects/libraries/syslink-components/src/lib/helpers/SyslinkColumn';
import { Third } from '../../../third.model';
import { ViewComponent } from 'projects/libraries/syslink-components/src/lib/helpers/view/view.component';
import { TaskGridComponent } from 'projects/erp-app/src/app/time-management/tasks/tasks/task-grid/task-grid.component';
import { TasksService } from 'projects/erp-app/src/app/time-management/tasks/tasks/tasks.service';
import { WorkTimesService } from 'projects/erp-app/src/app/time-management/work-times/work-times/work-times.service';
import { BooleanCellData } from 'projects/libraries/syslink-components/src/lib/grid/cells/boolean-cell/boolean-cell.component';
import { BadgeListCellData, ColoredDateCellData } from 'projects/libraries/syslink-components/src/public-api';
import { Task, TaskTimeStateCode } from 'projects/erp-app/src/app/time-management/tasks/tasks/task.model';
import { TaskModalComponent } from 'projects/erp-app/src/app/time-management/tasks/tasks/task-modal/task-modal.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-third-document-relation-time-management-tasks',
  templateUrl: './third-document-relation-time-management-tasks.component.html',
  styleUrls: ['./third-document-relation-time-management-tasks.component.scss']
})
export class ThirdDocumentRelationTimeManagementTasksComponent extends ViewComponent  {
  @Input() public element: Third = new Third();
  
  @ViewChild("taskModal") taskModal?: TaskModalComponent;
  @ViewChild("taskGrid") taskGrid?: TaskGridComponent;
  
  constructor(
    private tasksService: TasksService,
    private workTimesService: WorkTimesService,
    private translateService: TranslateService
  ){
    super();
  }
  
  @ViewChild('taskGridComponent') taskGridComponent: TaskGridComponent = new TaskGridComponent(this.tasksService, this.workTimesService);
  public TaskColumns: SyslinkColumn[] = [
    new SyslinkColumn({ field: "No", label: 'GRID.CAPTION.NO', sortIndex: 1, sortDirection: 'desc', width: 120 }),
    new SyslinkColumn({ field: "Subject", label: "Subject" }),
    new SyslinkColumn({ field: "ExternalRef", label: "External reference", width: 100, visible: false }),
    new SyslinkColumn({ field: "EstimatedTime", label: "Estimated time", filterable: false, headerFilterable: false, sortable: false, width: 60, cellTemplate: "duration-cell", data: { key: TaskTimeStateCode.EstimatedTimeState, getColor: this.taskGridComponent.getDurationCellColor, getValue: this.taskGridComponent.getDurationCellValue, getVisibility: this.taskGridComponent.getDurationCellVisibility }, visible: false }),
    new SyslinkColumn({ field: "WorkedTime", label: "Worked time", filterable: false, headerFilterable: false, sortable: false, width: 60, cellTemplate: "duration-cell", data: { key: TaskTimeStateCode.WorkedTimeState, getColor: this.taskGridComponent.getDurationCellColor, getValue: this.taskGridComponent.getDurationCellValue, getVisibility: this.taskGridComponent.getDurationCellVisibility }, visible: false }),
    new SyslinkColumn({ field: "BilledTime", label: "Billed time", filterable: false, headerFilterable: false, sortable: false, width: 60, cellTemplate: "duration-cell", data: { key: TaskTimeStateCode.BilledTimeState, getColor: this.taskGridComponent.getDurationCellColor, getValue: this.taskGridComponent.getDurationCellValue, getVisibility: this.taskGridComponent.getDurationCellVisibility }, visible: false }),
    new SyslinkColumn({ field: "CurrentStatusLink.StatusId.Name", label: "Status", cellTemplate: 'statusText-cell', data: { getStatusLabel: this.taskGridComponent.getStatusCellLabel, getStatusDate: this.taskGridComponent.getStatusCellDate, getStatusClass: this.taskGridComponent.getStatusCellClass, getStatusColor: this.taskGridComponent.getStatusCellColor }, width: 120 }),
    new SyslinkColumn({ field: "Users", label: "Users", width: 100, cellTemplate: 'badge-list-cell', filterable: false, headerFilterable: false, sortable: false, data: new BadgeListCellData({ contentKey: 'Name' }) }),
    new SyslinkColumn({ field: "UserGroups", label: "UserGroups", width: 100, cellTemplate: 'badge-list-cell', filterable: false, headerFilterable: false, sortable: false, data: new BadgeListCellData({ contentKey: 'Name' }) }),
    new SyslinkColumn({ field: "EndDate", label: "Deadline", type: "date", width: 100, cellTemplate: 'colored-date-cell', data: new ColoredDateCellData({ getColor: this.taskGridComponent.getEndDateColor, getFontWeight: this.taskGridComponent.getEndDateFontWeight }) }),
    new SyslinkColumn({ field: "TaskPriorityId.Name", label: "Priority", width: 100, visible: false }),
    new SyslinkColumn({ field: "IsPlannable", label: "Plannable", type: "boolean", width: 100, cellTemplate: 'boolean-cell', data: new BooleanCellData({ type: 'box' }) }),
    new SyslinkColumn({ field: "IsPerformable", label: "Performable", type: "boolean", width: 100, cellTemplate: 'boolean-cell', data: new BooleanCellData({ type: 'box' }) }),
    new SyslinkColumn({ field: "IsBillable", label: "Billable", type: "boolean", width: 100, cellTemplate: 'boolean-cell', data: new BooleanCellData({ type: 'box' }) }),
    new SyslinkColumn({ field: "IsBilled", label: "Billed", type: "boolean", width: 100, cellTemplate: 'boolean-cell', data: new BooleanCellData({ type: 'box' }), }),
    new SyslinkColumn({ field: "IsFixedPrice", label: "Is fixed price", type: "boolean", width: 100, cellTemplate: 'boolean-cell', data: new BooleanCellData({ type: 'box' }), }),
  ];   
  
  public async onAddButtonClicked() {
    if (this.taskModal) {
      this.taskModal.title = this.translateService.instant("Create new Task");
    }
    this.taskModal?.open(await this.tasksService.getInstance({
      ThirdId: this.element
    }));
  }

  public async onValidateModalTaskButtonClicked(e: Task) {
    await this.tasksService.createOrUpdate(e);
    this.taskModal?.close();
    this.taskGrid?.refresh();
  }
  public async onTaskRowDoubleClicked(e: Task) {
    if (this.taskModal) {
      this.taskModal.title = this.translateService.instant("Edit task");
    }
    this.taskModal?.open(new Task(e));
  };
}