import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Third } from '../third.model';
import { CountriesService } from '../../../base/addresses/countries/countries.service';
import { ThirdContactInformation } from '../third-contacts/third-contact-informations/third-contact-information.model';

@Component({
  selector: 'app-third-vies-form',
  templateUrl: './third-vies-form.component.html',
  styleUrls: ['./third-vies-form.component.scss']
})
export class ThirdViesFormComponent implements OnInit {
  @Input() public currentElement: Third = new Third();
  @Output() public currentElementChange: EventEmitter<Third> = new EventEmitter<Third>();

  @Input() public newElement: Third = new Third();
  @Output() public newElementChange: EventEmitter<Third> = new EventEmitter<Third>();

  @Input() public usedCurrentDenomination?: boolean = true;
  @Output() public usedCurrentDenominationChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() public usedCurrentLegalForm?: boolean = true;
  @Output() public usedCurrentLegalFormChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() public usedCurrentCountry?: boolean = true;
  @Output() public usedCurrentCountryChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    public countriesService: CountriesService,
  ) {
  }
  ngOnInit(): void {
    this.usedCurrentDenomination = this.currentElement.Name ? true : false;
    this.usedCurrentDenominationChange.emit(this.usedCurrentDenomination);
    
    this.usedCurrentLegalForm = this.currentElement.LegalFormId?.Name ? true : false;
    this.usedCurrentLegalFormChange.emit(this.usedCurrentLegalForm);

    this.usedCurrentCountry = this.currentElement.CountryId?.Name ? true : false;
    this.usedCurrentCountryChange.emit(this.usedCurrentCountry);
    

    this.loadContactInformations();
  }

  public loadContactInformations() {
    this.currentElement.ContactInformations.forEach((element: ThirdContactInformation) => {
      element.IsChecked = this.currentElement.ContactInformations.length != 0;
    })
    this.newElement.ContactInformations.forEach((element: ThirdContactInformation) => {
      element.IsChecked =  this.currentElement.ContactInformations.length == 0;
    })
  }
}
