import { BasicEntity } from "../../../core/models/basic-entity";

export enum PeriodicityIds {
    Daily = 1,
    Weekly = 2,
    Monthly = 3,
    Quarterly = 4,
    Annual = 5,
    Biannual = 6,
    None = 7
}

export class Periodicity extends BasicEntity {
    constructor(entity?: Partial<Periodicity>) {
        super();
        Object.assign(this, entity);
    }
}
