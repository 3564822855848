<syslink-title text="{{ 'Stock Move Supplier Incomings' }}"></syslink-title>
<app-stock-move-incoming-grid
  #grid
  [permissionKey]="'list'"
  [canUpdate]="false"
  (add)="onAddButtonClicked()"
  [contextMenuItems]="contextMenuItems"
  [expand]="expand"
  detailsUrl="/stockmoves/supplierincomings"
  storageKey="stock-move-supplierincomings"
  [filter]="filter"
>
</app-stock-move-incoming-grid>
<app-document-create-modal
  #createModal
  title="Create supplier incoming"
  [thirdFilter]="supplierFilter"
  detailsUrl="/thirds/suppliers/"
  (validate)="onCreateValidated($event)"
  [showSubject]="false"
></app-document-create-modal>
<syslink-confirm-modal
  #confirmModal
  (onValidate)="onConfirmModalValidated($event)"
></syslink-confirm-modal>
<app-stock-move-incoming-status-select-modal
  #statusModal
  (validate)="onConfirmModalValidated($event)"
></app-stock-move-incoming-status-select-modal>
