<syslink-title [text]="formattedTitle"></syslink-title>

<!-- Toolbar -->
<syslink-toolbar
  *ngIf="toolbarActions.length"
  [actions]="toolbarActions"
></syslink-toolbar>

<!-- Third -->
<div class="mt-4">
  <app-third-form
    [(element)]="element"
    (thirdRolesChange)="onThirdRoleChanged($event)"
    (isProfessionalChange)="onIsProfessionalChanged($event)"
  ></app-third-form>
</div>
<div class="row">
  <div class="col-6">
    <div class="mt-4">
      <syslink-tabs #infoTabs>
        <syslink-tab
          key="Third.Private"
          [visible]="authService.hasPermission(this.basePermissionKey + '.private.tab.view') && !element.IsProfessional"
          label="Third.Private"
        >
          <app-third-private-form
            *ngIf="element"
            [(element)]="element"
            [disabled]="isAutocompleteLoading"
          ></app-third-private-form>
        </syslink-tab>

        <syslink-tab
          key="Professional"
          label="Professional"
          [visible]="authService.hasPermission(this.basePermissionKey + '.professionnal.tab.view') && (element.IsProfessional ?? false)"
        >
          <app-third-professional-form
            [(element)]="element"
            [disabled]="isAutocompleteLoading"
            [isAutocompleteLoading]="isAutocompleteLoading"
            (onVatChange)="getThirdDataSummaryFromVat()"
            (onThirdFullAutocomplete)="getThirdDataCompleteFromVat()"
            [vatDisabled]="isAutocompleteLoading"
          ></app-third-professional-form>
        </syslink-tab>
        <syslink-tab
          key="Customer"
          [visible]="authService.hasPermission(this.basePermissionKey + '.customer.tab.view') && element.Roles != null && hasCustomerRole()"
          label="Customer"
        >
          <app-customer-form
            *ngIf="element.CustomerId"
            [(element)]="element.CustomerId"
          ></app-customer-form>
        </syslink-tab>
        <syslink-tab
          key="Supplier"
          [visible]="authService.hasPermission(this.basePermissionKey + '.supplier.tab.view') && element.Roles != null && hasSupplierRole()"
          label="Supplier"
        >
          <app-supplier-form
            *ngIf="element.SupplierId"
            [(element)]="element.SupplierId"
          ></app-supplier-form>
        </syslink-tab>
        <syslink-tab
          key="User"
          [visible]="authService.hasPermission(this.basePermissionKey + '.user.tab.view') && element.Roles != null && hasUserRole()"
          label="User"
        >
          <syslink-select
            [store]="usersService.store"
            displayKey="Name"
            [(model)]="element.UserId"
            [allowClearing]="true"
            [expand]="['LanguageId']"
          ></syslink-select>
          <app-user-form
            *ngIf="element.UserId"
            [(user)]="element.UserId"
          ></app-user-form>
        </syslink-tab>
        <syslink-tab
          key="Other"
          label="Other"
          [visible]="authService.hasPermission(this.basePermissionKey + '.other.tab.view')"
        >
          <app-third-other-form [(element)]="element"></app-third-other-form>
        </syslink-tab>
        <syslink-tab
        key="Statistics"
        label="Statistics"
        [visible]="authService.hasPermission(this.basePermissionKey + '.statistics.tab.view') && (element.HasCustomerRole == true || element.HasSupplierRole == true)"
      >
      <app-third-statistics [element]="element"></app-third-statistics>
       
      </syslink-tab>
      </syslink-tabs>
    </div>
  </div>

  <!-- Communications / Adresses / Relations -->
  <!-- Tabs -->
  <div class="col-6">
    <div class="mt-4">
      <syslink-tabs>
        <syslink-tab
          label="Contacts"
          [visible]="authService.hasPermission(this.basePermissionKey + '.contacts.tab.view')"
        >
          <app-third-contacts
            [(elements)]="element.ContactInformations"
            (updateContact)="reloadContactInformation()"
            [third]="element"
          ></app-third-contacts>
        </syslink-tab>
        <syslink-tab
          label="Relations"
          [visible]="authService.hasPermission(this.basePermissionKey + '.relations.tab.view')"
        >
          <app-third-relations
            [third]="element"
            [(elements)]="element.ThirdRelations"
            [isLocal]="element.Id ? false : true"
            (create)="onThirdRelationChanged($event, true)"
            (update)="onThirdRelationChanged($event)"
            (delete)="onThirdRelationDeleted($event)"
          ></app-third-relations>
        </syslink-tab>
      </syslink-tabs>
    </div>
  </div>
</div>

<!-- Documents -->
<!-- Tabs -->
<div
  class="mt-4"
  *ngIf="hasCustomerRole()"
>
  <syslink-tabs *ngIf="element.Id">
    <syslink-tab
      label="Sale quotes"
      [visible]="authService.hasPermission(this.basePermissionKey + '.saleQuote.tab.view')"
    >
      <app-third-document-relation-sale-quotes [element]="element"></app-third-document-relation-sale-quotes>
    </syslink-tab>
    <syslink-tab
      label="Sale orders"
      [visible]="authService.hasPermission(this.basePermissionKey + '.saleOrder.tab.view')"
    >
      <app-third-document-relation-sale-orders [element]="element"></app-third-document-relation-sale-orders>

    </syslink-tab>
    <syslink-tab
      label="Sale invoices"
      [visible]="authService.hasPermission(this.basePermissionKey + '.saleInvoice.tab.view')"
    >
      <app-third-document-relation-sale-invoices [element]="element"></app-third-document-relation-sale-invoices>
    </syslink-tab>
    <syslink-tab
      label="Sale credit notes"
      [visible]="authService.hasPermission(this.basePermissionKey + '.saleCreditNote.tab.view')"
    >
      <app-third-document-relation-sale-credit-notes [element]="element"></app-third-document-relation-sale-credit-notes>
    </syslink-tab>
    <syslink-tab
      label="Sale contracts"
      [visible]="authService.hasPermission(this.basePermissionKey + '.saleContract.tab.view')"
    >
      <app-third-document-relation-sale-contracts [element]="element"></app-third-document-relation-sale-contracts>
    </syslink-tab>

    <!-- <syslink-tab
      key="Planification"
      label="Plannable"
    >
      <div class="row">
        <div class="col-8">
          <app-appointment-scheduler
            #scheduler
            [canRefresh]="true"
            (add)="onAddAppointmentButtonClicked()"
            [filter]="['ThirdId.Id eq ' + element.Id]"
            (validate)="onValidateAppointmentCreation($event)"
            (selectionChanged)="onSchedulerSelectionChanged($event)"
            [canEditType]="false"
            [canEditTask]="false"
          ></app-appointment-scheduler>

          <app-appointment-modal
            #appointmentModal
            (validate)="onValidateAppointmentCreation($event)"
          ></app-appointment-modal>
        </div>
        <div class="col-4">
          <app-appointment-grid
            #appointmenGrid
            [permissionKey]="'schedulings.list'"      
            [filter]="['ThirdId.Id eq ' + element.Id]"
            storageKey="third-details-appointments"
            [columns]="appointmentColumns"
            (add)="onAddAppointmentButtonClicked()"
            (rowDoubleClicked)="appointmentModal.open($event)"
          ></app-appointment-grid>
        </div>
      </div>
    </syslink-tab> -->

    <syslink-tab
      label="Tasks"
      [visible]="authService.hasPermission(this.basePermissionKey + '.task.tab.view')"
    >
      <app-third-document-relation-time-management-tasks [element]="element"></app-third-document-relation-time-management-tasks>
    </syslink-tab>

    <syslink-tab
      label="Worktimes"
      [visible]="authService.hasPermission(this.basePermissionKey + '.workTime.tab.view')"
    >
      <app-third-document-relation-time-management-work-times [element]="element"></app-third-document-relation-time-management-work-times>

    </syslink-tab>
    <syslink-tab
      label="Customeroutgoings"
      [visible]="authService.hasPermission(this.basePermissionKey + '.customerOutgoings.tab.view')"
    >
      <app-third-document-relation-stock-stock-move-customer-outgoings [element]="element"></app-third-document-relation-stock-stock-move-customer-outgoings>
    </syslink-tab>
    <syslink-tab
      label="Customerincomings"
      [visible]="authService.hasPermission(this.basePermissionKey + '.customerIncomings.tab.view')"
    >
      <app-third-document-relation-stock-stock-move-customer-incomings [element]="element"></app-third-document-relation-stock-stock-move-customer-incomings>
    </syslink-tab>
  </syslink-tabs>
</div>
<div
  class="mt-4"
  *ngIf="hasSupplierRole()"
>
  <syslink-tabs *ngIf="element.Id">
    <syslink-tab
      label="Purchase orders"
      [visible]="authService.hasPermission(this.basePermissionKey + '.purchaseOrder.tab.view')"
    >
      <app-third-document-relation-purchase-orders [element]="element"></app-third-document-relation-purchase-orders>

    </syslink-tab>
    <syslink-tab
      label="Purchase invoices"
      [visible]="authService.hasPermission(this.basePermissionKey + '.purchaseInvoice.tab.view')"
    >
      <app-third-document-relation-purchase-invoices [element]="element"></app-third-document-relation-purchase-invoices>

    </syslink-tab>
    <syslink-tab
      label="Purchase credit notes"
      [visible]="authService.hasPermission(this.basePermissionKey + '.purchaseCreditNote.tab.view')"
    >
      <app-third-document-relation-purchase-credit-notes [element]="element"></app-third-document-relation-purchase-credit-notes>
    </syslink-tab>
    <syslink-tab
      label="Supplieroutgoings"
      [visible]="authService.hasPermission(this.basePermissionKey + '.supplierOutgoings.tab.view')"
    >
      <app-third-document-relation-stock-stock-move-supplier-outgoings [element]="element"></app-third-document-relation-stock-stock-move-supplier-outgoings>
    </syslink-tab>
    <syslink-tab
      label="Supplierincomings"
      [visible]="authService.hasPermission(this.basePermissionKey + '.supplierIncomings.tab.view')"
    >
      <app-third-document-relation-stock-stock-move-supplier-incomings [element]="element"></app-third-document-relation-stock-stock-move-supplier-incomings>
    </syslink-tab>
  </syslink-tabs>
</div>
<!-- Modal -->
<syslink-confirm-modal
  #deleteConfirm
  title="Delete"
  content="DeleteConfirmMessage"
  cancelText="Cancel"
  validateText="Confirm"
  (onValidate)="delete($event)"
></syslink-confirm-modal>

<syslink-third-modal
  #thirdModaltransfert
  content="Transfer document(s) to another third"
  [store]="thirdsService.store"
  (onValidate)="thirdModaltransfertValidate($event)"
  [filter]="thirdModaltransfertFilter"
  [extraFields]="thirdModaltransfertFields"
></syslink-third-modal>

<!-- Modification modal -->
<syslink-modal
  #updateThirdAutocomplete
  title="Select the data"
  width="1200px"
  height="700px"
  (onValidate)="onModalModificationReplace()"
  validateText="Validate"
>
  <app-third-vies-form
    *ngIf="thirdAutocompleted && thirdAutocompleted.Name"
    [(currentElement)]="element"
    [(newElement)]="thirdAutocompleted"
    [(usedCurrentCountry)]="usedCurrentCountry"
    [(usedCurrentDenomination)]="usedCurrentDenomination"
    [(usedCurrentLegalForm)]="usedCurrentLegalForm"
  ></app-third-vies-form>
</syslink-modal>

<!-- CreditSafe -->
<app-creditsafe-modal
  #creditsafeModal
  [element]="creditSafeElement"
  [vatNumber]="element.VatNumber"
></app-creditsafe-modal>