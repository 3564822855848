import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private authService: AuthService,
    private router: Router
  ) { }

  // Default guard function
  // ----------------------
  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    switch (route.url.toString()) {
      case 'login': return this.canActivateLoginPage(route, state);
      case '': return this.canActivateAdminPages(route, state);
      default: return true;
    }
  }

  // Specific page functions
  // -----------------------
  private canActivateLoginPage(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!this.authService.isExpired()) {
      // Already logged => redirect to admin
      return this.router.navigateByUrl('/');
    }
    else {
      return true;
    }
  }

  private canActivateAdminPages(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.authService.isExpired()) {
      localStorage.setItem(environment.redirectOnLoginUrlKey, state.url)
      return this.router.navigateByUrl('/login');
    }
    else {
      return true;
    }
  }
}