<syslink-grid
    #grid
    [gridName]="storageKey"
    [canView]="true"
    [canAdd]="canAdd"
    [canView]="canView"
    [canSearch]="canSearch"
    [canRefresh]="canRefresh"
    [canSort]="canSort"
    [canDelete]="canDelete"
    [canFilter]="canFilter"
    [canExport]="canExport"
    [canDblClck]="canDblClck"
    [service]="actionGroupsService"
    [columns]="columns"
    [filter]="filter"
    [canUpdate]="false"
    [storageKey]="storageKey"
    (add)="onAddButtonClicked()"
    (beforeDelete)="onDeleteClicked($event)"
    (doubleClick)="onDoubleClicked($event)"
    [select]="select"
    [expand]="expand"
></syslink-grid>