import { Entity } from "projects/erp-app/src/app/core/models/entity";
import { Third } from "../../third.model";
import { ThirdCommunicationLabel } from "../third-communication-labels/third-communication-label.model";
import { ThirdCommunicationType } from "../third-communication-types/third-communication-type.model";

export class ThirdCommunicationMethod extends Entity {
    public ThirdId?: Third;
    public Value?: string;
    public CommunicationLabelId?: ThirdCommunicationLabel;
    public CommunicationTypeId?: ThirdCommunicationType;
    public IsPublic: boolean = true;

    constructor(thirdCommunicationMethod?: Partial<ThirdCommunicationMethod>) {
        super(thirdCommunicationMethod);
        Object.assign(this, thirdCommunicationMethod);
    }
}