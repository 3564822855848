<syslink-modal
    #modal
    title="Creditsafe"
    [noFooter]="true"
    width="90vw"
    height="80vh"
>   
    <app-creditsafe
        #creditsafeDetail
        *ngIf="vatNumber"
        [vatNumber]="vatNumber"
        [element]="element"
    ></app-creditsafe>
</syslink-modal>