import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DocumentDataComponent } from 'projects/erp-app/src/app/base/documents/document-datas/document-data.component';
import { ThirdsService } from 'projects/erp-app/src/app/thirds/thirds/thirds.service';
import { StockMoveDocumentLine } from '../../stock-move-document-lines/stock-move-document-line.model';
import { StockMoveDocumentLinesService } from '../../stock-move-document-lines/stock-move-document-lines.service';
import { StockMoveDocument } from '../stock-move-document.model';
import { StockMoveDocumentsService } from '../stock-move-documents.service';
import { getNew } from 'projects/libraries/syslink-components/src/lib/helpers/tools';
import { StockMoveDocumentStatus } from '../../stock-move-document-statuses/stock-move-document-status.model';
import { PageComponent } from 'projects/libraries/syslink-components/src/public-api';
@Component({
  selector: 'app-stock-move-document-third',
  templateUrl: './stock-move-document-third.component.html',
  styleUrls: ['./stock-move-document-third.component.scss']
})
export class StockMoveDocumentThirdComponent<
  TStockMoveDocument extends StockMoveDocument,
  TStockMoveDocumentService extends StockMoveDocumentsService<StockMoveDocument, StockMoveDocumentStatus>,
  TStockMoveDocumentLine extends StockMoveDocumentLine,
  TStockMoveDocumentLineService extends StockMoveDocumentLinesService<StockMoveDocumentLine>
> extends PageComponent implements OnInit {
  @Input() public element: TStockMoveDocument = <TStockMoveDocument>getNew<TStockMoveDocument>();
  @Output() public elementChange: EventEmitter<TStockMoveDocument> = new EventEmitter<TStockMoveDocument>();
  @Output() documentData: EventEmitter<any> = new EventEmitter<any>();
  @Input() public reportType: string = '';
  @Input() public disabled: boolean = false;
  
  public _type: string = '';
  @Input() set type(value: string) {
    this._type = value;
    this.thirdDocumentDataFilters = (this.type == 'customer' ? this.customerFilter : this.supplierFilter);
    this.loadDocumentDataFilter();
  }
  get type() {
    return this._type;
  }

  @ViewChild('ThirdDocumentData') private ThirdDocumentData?: DocumentDataComponent;
  @ViewChild('IntermediateThirdDocumentData') private IntermediateThirdDocumentData?: DocumentDataComponent;

  public customerFilter: string | string[] = ["Roles/any(r:r/Code eq 'customer')"];
  public supplierFilter: string | string[] = ["Roles/any(r:r/Code eq 'supplier')"];

  public thirdDocumentDataFilters: string | string[] = [];
  public IntermediateThirdDocumentDataFilters: string | string[] = [];

  constructor(
    public thirdsService: ThirdsService
  ) {
    super();
  }
  override async ngOnInit(): Promise<void> {
    this.loadDocumentDataFilter();
  }

  public loadDocumentDataFilter() {
    if (this.element.ThirdId) {
      this.IntermediateThirdDocumentDataFilters = [(this.type == 'customer' ? this.customerFilter[0] : this.supplierFilter[0]) + " and Id ne " + this.element.ThirdId.Id?.toString()];
      this.IntermediateThirdDocumentData?.ThirdSelect?.selectComponent?.instance.getDataSource().filter(this.IntermediateThirdDocumentDataFilters);
      this.IntermediateThirdDocumentData?.ThirdSelect?.selectComponent?.instance.getDataSource().reload();
    }
    if (this.element.IntermediateThirdId) {
      this.thirdDocumentDataFilters = [(this.type == 'customer' ? this.customerFilter[0] : this.supplierFilter[0]) + " and Id ne " + this.element.IntermediateThirdId.Id?.toString()];
      this.ThirdDocumentData?.ThirdSelect?.selectComponent?.instance.getDataSource().filter(this.thirdDocumentDataFilters);
      this.ThirdDocumentData?.ThirdSelect?.selectComponent?.instance.getDataSource().reload();
    }
  }
  public documentDataThirdChange(e: any) {
    this.element.DocumentDataCollection[this.element.ThirdDocumentDataIndex] = e;
    this.element.ThirdId = this.element.DocumentDataCollection[this.element.ThirdDocumentDataIndex].ThirdId;
    this.documentData.emit();
    this.loadDocumentDataFilter();
  }
  public documentDataIntermediateThirdChange(e: any) {
    if (this.element.IntermediateThirdIdDocumentDataIndex == undefined || this.element.IntermediateThirdIdDocumentDataIndex < 0) {
      var thirdDocument = this.element.DocumentDataCollection[this.element.ThirdDocumentDataIndex];
      e.UsedForOrder = !thirdDocument.UsedForOrder;
      e.UsedForInvoice = !thirdDocument.UsedForInvoice;
      this.element.DocumentDataCollection.push(e);
    }
    else {
      this.element.DocumentDataCollection[this.element.IntermediateThirdIdDocumentDataIndex] = e;
    }
    this.element.IntermediateThirdId = e.ThirdId;
    this.documentData.emit();
    this.loadDocumentDataFilter();
  }
  public documentDataUsedChange(e: any) {

  }
}
