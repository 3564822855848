import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NotificationsService } from 'projects/libraries/syslink-components/src/public-api';
import { TaxRatesService } from '../../../base/tax-rates/tax-rates.service';
import { SupplierCatalogDiscountOperationService } from '../../supplier-catalogs/supplier-catalog-discount-operation.service';
import { SupplierCatalog } from '../../supplier-catalogs/supplier-catalog.model';
import { SupplierCatalogsService } from '../../supplier-catalogs/supplier-catalog.service';
import { Product } from '../product.model';
import { ProductsService } from '../product.service';
import { AuthService } from '../../../core/auth/auth.service';
import { InitNewRowEvent as InitNewRowEventGrid } from 'devextreme/ui/data_grid';
import { InitNewRowEvent as InitNewRowEventTree } from 'devextreme/ui/tree_list';

@Component({
  selector: 'app-product-supplier-catalogs',
  templateUrl: './product-supplier-catalogs.component.html',
  styleUrls: ['./product-supplier-catalogs.component.scss']
})
export class ProductSupplierCatalogsComponent {
  @Input() public element: Product = new Product();
  @Output() public elementChange: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    public supplierCatalogsService: SupplierCatalogsService,
    public supplierCatalogDiscountOperationService: SupplierCatalogDiscountOperationService,
    public productsService: ProductsService,
    public taxRatesService: TaxRatesService,
    public authService:AuthService
  ) { }

  // New SupplierCatalog
  // -------------------
  public onNewElement(event: InitNewRowEventGrid<SupplierCatalog> | InitNewRowEventTree<SupplierCatalog>) {
    event.promise = new Promise(async (resolve) => {
      event.data = await this.supplierCatalogsService.getInstanceWithProduct(this.element, event.data);
      resolve();
    })
  }

  public async onBeforeInserting(event: any) {
    if (!this.element.Id) return;

    event.cancel = new Promise<void>(async (resolve, reject) => {
      const supplierCatalog: SupplierCatalog = event.data;

      if (!supplierCatalog.SupplierId) {
        NotificationsService.sendErrorMessage('A supplier catalog must be linked to a supplier');
        return reject();
      }

      const createdSupplierCatalog = await this.supplierCatalogsService.insert(this.supplierCatalogsService.format(supplierCatalog));
      if (createdSupplierCatalog.IsFavorite == true && this.element.Id != null && createdSupplierCatalog.Id != null) {
        await this.productsService.setSupplierCatalogIsFavorite(this.element.Id, createdSupplierCatalog.Id);
      }
      const discount: any = supplierCatalog.DiscountId;
      if (discount) {
        discount.SupplierCatalogId = createdSupplierCatalog.Id;
        discount.DiscountOperationId = 1
        await this.supplierCatalogDiscountOperationService.insert(discount);
      }

      NotificationsService.sendInfo('Record created');
      this.elementChange.emit(event);

      resolve()
    });;
  }

  // Delete SupplierCatalog
  // ----------------------
  public async onBeforeDelete(event: any) {
    if (this.element.SupplierCatalogs.length === 1) {
      NotificationsService.sendWarning('A purchasable product must have at least 1 supplier catalog');
      event.cancel = true;
      return;
    }

    if (!event.key) return;

    await this.supplierCatalogsService.remove(event.key);

    NotificationsService.sendInfo('Record deleted');
    this.elementChange.emit(event);
  }

  // Update SupplierCatalog
  // ----------------------
  public async onBeforeUpdate(event: any) {
    if (event.newData.hasOwnProperty('IsFavorite') && event.newData.IsFavorite == true) {
      await this.productsService.setSupplierCatalogIsFavorite(event.oldData.ProductId.Id, event.oldData.Id);
      return;
    }

    const supplierCatalog: SupplierCatalog = new SupplierCatalog({
      ...event.oldData,
      ...event.newData
    });

    await this.supplierCatalogsService.update(event.key, this.supplierCatalogsService.format(supplierCatalog));
    if (supplierCatalog.DiscountId?.Id) {
      await this.supplierCatalogDiscountOperationService.update(supplierCatalog.DiscountId.Id, supplierCatalog.DiscountId);
    }
  }

  public onAfterUpdate(event: any) {
    this.elementChange.emit(event);
  }
}