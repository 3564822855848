import { Injectable } from '@angular/core';
import { ODataService } from '../../core/services/oData.service';
import { MailTemplateParam } from './mail-template-param.model';
import { LoadOptions } from 'devextreme/data';

@Injectable({
  providedIn: 'root'
})
export class MailTemplateParamsService extends ODataService<MailTemplateParam> {
  public url = 'MailTemplateParam';
  public override defaultOptions: LoadOptions = {
    expand: ['MailTemplateTypeId']
  };
}
