import { Component, Input, ViewChild } from '@angular/core';
import { Product } from '../product.model';
import { BaseGridComponent } from 'projects/libraries/syslink-components/src/lib/grid/base-grid/base-grid.component';
import { SyslinkColumn } from 'projects/libraries/syslink-components/src/lib/helpers/SyslinkColumn';
import { BooleanCellData } from 'projects/libraries/syslink-components/src/lib/grid/cells/boolean-cell/boolean-cell.component';
import { ProductsService } from '../product.service';
import { AuthService } from '../../../core/auth/auth.service';
import { GridComponent } from 'projects/libraries/syslink-components/src/public-api';

@Component({
  selector: 'app-product-grid',
  templateUrl: './product-grid.component.html',
  styleUrls: ['./product-grid.component.scss']
})
export class ProductGridComponent extends BaseGridComponent<Product> {

  public override canDelete: boolean = true;
  @Input() storageKey: string = 'grid-products';

  @ViewChild('gridComponent') public gridComponent?: GridComponent;

  public columns: SyslinkColumn[] = [
    new SyslinkColumn({ field: 'No', label: 'Id', width: 80, sortable: true, sortIndex: 0, sortDirection: 'desc' }),
    new SyslinkColumn({ field: 'InternalReference', label: 'Internal reference', width: 200 }),
    new SyslinkColumn({ field: 'Name', label: 'Name' }),
    new SyslinkColumn({ field: 'ProductCategoryId.Name', label: 'Products Categories', width: 200 }),
    new SyslinkColumn({ field: 'Stock', label: 'Stock', width: 80, type: 'number', technical : this.authService.hasPermission('root-stock-moves') }),
    new SyslinkColumn({ field: 'StockMove', label: 'Stock', width: 80, type: 'number', technical : !this.authService.hasPermission('root-stock-moves') }),
    new SyslinkColumn({ field: 'DefaultUnitId.Name', label: 'Unit', width: 120 }),
    new SyslinkColumn({ field: 'SalePrice', label: 'Sale price ex tax', data: { subModuleCode: 'Products.General', key: 'Precision.Product.SalePriceExTax' }, cellTemplate: 'currency-cell', width: 120, type: 'number', filterable: false, headerFilterable: false, sortable: false }),
    new SyslinkColumn({ field: 'PurchasePrice', label: 'Purchase price ex tax', data: { subModuleCode: 'Products.General', key: 'Precision.Product.SalePriceInTax' }, cellTemplate: 'currency-cell', width: 120, type: 'number', filterable: false, headerFilterable: false, sortable: false }),
    new SyslinkColumn({ field: 'SalePriceInTax', label: 'Sale price in tax', data: { subModuleCode: 'Products.General', key: 'Precision.Product.PurchasePriceExTax' }, cellTemplate: 'currency-cell', width: 120, type: 'number', filterable: false, headerFilterable: false, sortable: false }),
    new SyslinkColumn({ field: 'PurchasePriceInTax', label: 'Purchase price in tax', data: { subModuleCode: 'Products.General', key: 'Precision.Product.PurchasePriceInTax' }, cellTemplate: 'currency-cell', width: 120, type: 'number', filterable: false, headerFilterable: false, sortable: false }),
    new SyslinkColumn({ field: 'Purchasable', label: 'Purchasable', type: 'boolean', cellTemplate: 'boolean-cell', data: new BooleanCellData({ type: 'box', }), width: 80 }),
    new SyslinkColumn({ field: 'Sellable', label: 'Sellable', type: 'boolean', cellTemplate: 'boolean-cell', data: new BooleanCellData({ type: 'box', }), width: 80 }),
    new SyslinkColumn({ field: 'Stockable', label: 'Stockable', type: 'boolean', cellTemplate: 'boolean-cell', data: new BooleanCellData({ type: 'box', }), width: 80 }),
    new SyslinkColumn({ field: 'Blocked', label: 'Blocked', type: 'boolean', cellTemplate: 'boolean-cell', data: new BooleanCellData({ type: 'box', trueColor: 'danger', falseColor: 'success' }), width: 80 })
  ];

  constructor(
    public productsService: ProductsService,
  ) {
    super();
  }

}
