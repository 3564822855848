
export abstract class Entity {
    public Id?: number;
    public CreatedAt?: Date;
    public UpdatedAt?: Date;
    public DeletedAt?: Date;

    constructor(entity?: Partial<Entity>) {
        Object.assign(this, entity);
    }
}
