import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'syslink-link',
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.scss']
})
export class LinkComponent {
  /** Target url */
  @Input() public url: string = '';

  /** Url params */
  @Input() public params: { [index: string]: string } = {};

  /** Set true if the url should be opened in a new tab */
  @Input() public newTab: boolean = false;

  /** Class */
  @Input() public class: string = '';

  /** Icon */
  @Input() public icon?: string;

  /** Text */
  @Input() public text?: string;

  @Output() public onClick: EventEmitter<void> = new EventEmitter<void>();

  public onLinkClick(e: any): void {
    if (this.onClick.observed) {
      e.preventDefault();
      this.onClick.emit();
    }
  }
}
