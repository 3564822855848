<syslink-modal
    #modal
    [title]="title"
    [width]="width"
    (onValidate)="onModalValidate()"
    (onCancel)="onModalCancel()"
>

    <syslink-input-text
        name="currentPassword"
        type="password"
        [(model)]="password"
    ></syslink-input-text>
</syslink-modal>