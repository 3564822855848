import { Component, Input } from '@angular/core';

@Component({
  selector: 'syslink-title',
  templateUrl: './title.component.html',
  styleUrls: ['./title.component.scss']
})
export class TitleComponent {
  @Input() public text: string = 'Title';
  @Input() public shouldTranslate: boolean = true;
}
