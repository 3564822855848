<syslink-title text="User permission"></syslink-title>

<syslink-grid
    [store]="userPermissionsService.store"
    [columns]="columns"
    [canUpdate]="false"
    [canDelete]="false"
    (add)="addNew()"
    (doubleClick)="onDoubleClicked($event)"
    [storageKey]="storageKey"
    [filter]="filter"
></syslink-grid>