import { Injectable } from '@angular/core';
import { ODataService } from 'projects/erp-app/src/app/core/services/oData.service';
import { ThirdContactQualifier } from './third-contact-qualifier.model';
import { LoadOptions } from 'devextreme/data';

@Injectable({
  providedIn: 'root'
})
export class ThirdContactQualifiersService extends ODataService<ThirdContactQualifier> {
  public url = 'ContactQualifier';
  public override defaultOptions: LoadOptions = {
    expand: []
  };
}