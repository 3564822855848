import { EventEmitter, Injectable, Output } from '@angular/core';
import ODataStore from 'devextreme/data/odata/store';
import { FilterDescriptor, LoadOptions } from 'devextreme/data';
import { NotificationsService } from 'projects/libraries/syslink-components/src/public-api';
import { AuthService } from '../auth/auth.service';
import { environment } from 'projects/erp-app/src/environments/environment';
import { ApiService } from '../api.service';
import { AppInjectorService } from 'projects/libraries/syslink-components/src/lib/services/app-injector.service';
import { Router } from '@angular/router';
import { getNew } from 'projects/libraries/syslink-components/src/lib/helpers/tools';
import { ErrorService } from '../error.service';

type onLoadedEvent = (result: Array<any>, loadOptions: LoadOptions) => void;

@Injectable({
  providedIn: 'root',
})
export abstract class ODataService<T> {
  public abstract url: string;
  public key: string = 'Id';
  public keyType: string = 'Int32';
  public defaultOptions: LoadOptions = {};
  public onLoaded?: onLoadedEvent

  @Output() static error: EventEmitter<any> = new EventEmitter<any>();

  public get store(): ODataStore {
    return ODataService.generateStore(this.url, this.key, this.keyType, this.onLoaded);
  }

  public get archivedStore(): ODataStore {
    return ODataService.generateStore(`${this.url}/archived`, this.key, this.keyType, this.onLoaded);
  }

  protected apiService: ApiService;

  constructor() {
    this.apiService = AppInjectorService.injector.get(ApiService);
  }

  public static generateStore(url: string, key: string = 'Id', keyType: string = "Int32", onLoaded?: onLoadedEvent) {
    return new ODataStore({
      url: environment.base_url + '/api/odata/' + url,
      key: key,
      version: 4,
      withCredentials: true,
      keyType: keyType,
      beforeSend(options) {
        options.headers = {
          Authorization: 'Bearer ' + AuthService.getToken(),
          'Content-Type': 'application/json;odata.metadata=minimal;odata.streaming=true',
          'Accept-Language': "fr-BE",
        }
      },
      errorHandler: (e: any) => {
        switch (e.httpStatus) {
          case 500:
            // Cheat fix when login error on backend restart
            if (e.message.includes('Login failed for')) {
              AuthService.deleteToken();
              var router = AppInjectorService.injector.get(Router);
              localStorage.setItem(environment.redirectOnLoginUrlKey, router.url);
              window.location.reload();
            } else {
              e.message = e.errorDetails.message;
              AppInjectorService.injector.get(ErrorService).sendError(e);
            }
            break;

          case 401:
            NotificationsService.sendErrorMessage('Webservice.Unauthorized');
            AuthService.deleteToken();
            var router = AppInjectorService.injector.get(Router);
            localStorage.setItem(environment.redirectOnLoginUrlKey, router.url);
            window.location.reload();
            break;

          default:
            e.message = e.errorDetails ? e.errorDetails.message : e.message;
            AppInjectorService.injector.get(ErrorService).sendError(e);
        }
      },
      onLoaded
    });
  }

  public load(options: LoadOptions = {}): Promise<Array<T>> {
    return this.store.load({
      ...this.defaultOptions,
      ...options
    });
  }

  public insert(values: Partial<T>): Promise<T> {
    return this.store.insert(values);
  }

  public update(id: number | string, values: Partial<T>): Promise<T> {
    return this.store.update(id, values);
  }

  public createOrUpdate(element: any): Promise<T> {
    if (element[this.key]) {
      return this.update(element[this.key], this.format(element));
    }
    else {
      return this.insert(this.format(element));
    }
  }

  public remove(id: number | string): Promise<void> {
    return this.store.remove(id);
  }

  public findByID(id: number | string, options: { expand?: string | string[], select?: string | string[] } = {}): Promise<T> {
    return this.store.byKey(id, {
      expand: this.defaultOptions.expand,
      select: <string | string[]>this.defaultOptions.select,
      ...options
    });
  }

  public find(id: number, options: { expand?: string | string[], select?: string | string[] } = {}): Promise<T> {
    return this.store.byKey(id, {
      expand: this.defaultOptions.expand,
      select: <string | string[]>this.defaultOptions.select,

      ...options
    });
  }

  public restore(id: number): Promise<void> {
    return this.apiService.sendRequest(`/api/odata/${this.url}/restore/${id}`, 'POST');
  }

  public filter(filters: FilterDescriptor | Array<FilterDescriptor>) {
    return this.store.load({
      ...this.defaultOptions,
      filter: filters
    });
  }

  public updateMultiples(Ids: any, Fields: any): Promise<void> {
    return this.apiService.sendRequest(`/api/odata/${this.url}/multiple`, "POST", { Ids, Fields });
  }

  public async getInstance(params?: Partial<T>): Promise<any> { return getNew<T>(params) };
  public format(params?: Partial<T>): any { return params };
  }



