<syslink-modal
  width="33vw"
  #modal
  [title]="title"
  [data]="modal.data"
  (onValidate)="onValidateButtonClicked($event)"
>
  <div class="row">
    <div class="col-6">
      <syslink-select
        label="Third"
        [store]="thirdsService.store"
        [(model)]="modal.data.ThirdId"
        [filter]="[['IsSupplier eq true'], 'or', ['IsCustomer eq true']]"
        displayKey="Fullname"
        [select]="['Id', 'Fullname']"
        [allowClearing]="true"
        (modelChange)="onThirdChanged($event)"
        [disabled]="modal.data.TaskId?.Id != undefined"
        [canAdd]="authService.hasPermission('settings-thirds-thirds') && authService.hasPermission('basics.thirds.thirds.list.add')"
        [canView]="authService.hasPermission('settings-thirds-thirds')"
        [canRefresh]="true"
        detailsUrl="/basics/thirds/thirds/"
      ></syslink-select>
    </div>
    <div class="col-6">
      <syslink-select
        label="Type"
        [store]="workTypesService.store"
        [(model)]="modal.data.TypeId"
        (modelChange)="onTypeChanged($event)"
        [disabled]="!canEditType"
      ></syslink-select>
    </div>
  </div>
  <div class="row mb-3">
    <div class="col-6">
      <syslink-select
        label="User"
        [store]="usersService.store"
        [filter]="usersFilters"
        [expand]="['ThirdId.TaskThirds','Groups']"
        (modelChange)="onUserChanged($event)"
        displayKey="Name"
        [(model)]="modal.data.UserId"
      ></syslink-select>
    </div>
    <div class="col-6">
      <syslink-select
        #taskSelect
        [hidden]="hideTaskField"
        label="Task"
        [store]="tasksService.store"
        displayKey="FormattedName"
        [filter]="taskFilters"
        [expand]="['Users', 'ThirdId', 'UserGroups', 'UserGroups.Users']"
        [(model)]="modal.data.TaskId"
        [select]="['Id', 'FormattedName', 'ThirdId.Id', 'ThirdId.Fullname', 'IsFixedPrice', 'IsBillable']"
        (modelChange)="onTaskChanged($event)"
        [allowClearing]="true"
        [canAdd]="canEditTask"
        [canRefresh]="canEditTask"
        detailsUrl="/time-management/tasks/"
        [disabled]="!canEditTask"
      ></syslink-select>
    </div>
  </div>
  <div class="row">
    <div class="col-6">
        <syslink-switch
        *ngIf="(modal.data.TaskId ? (!modal.data.TaskId.IsFixedPrice) : true)"
        [(model)]="modal.data.IsBillable"
        type="box"
        [falseText]="'Not billable' | translate"
        [trueText]="'Billable' | translate"
        [disabled]="modal.data.TaskId && !modal.data.TaskId.IsBillable"
      ></syslink-switch>
    </div>
  </div>
  <syslink-wysiwyg
    name="Description"
    label="Description"
    [(model)]="modal.data.Description"
    [table]="false"
    height="200"
    class="my-3"
  ></syslink-wysiwyg>

  <div class="row mb-3">
    <div class="col-5">
      <syslink-input-date
        label="Start date"
        format="shortDatetime"
        type="datetime"
        [(model)]="modal.data.StartDate"
        (modelChange)="onStartDateChanged($event)"
      ></syslink-input-date>
    </div>
    <div class="col-5">
      <syslink-input-date
        label="End date"
        format="shortDatetime"
        type="datetime"
        [(model)]="modal.data.EndDate"
        (modelChange)="onEndDateChanged($event)"
      ></syslink-input-date>
    </div>
    <div class="col-2">
      <syslink-input-duration
        label="Duration"
        [disabled]="true"
        [(model)]="modal.data.Duration"
      ></syslink-input-duration>
    </div>
  </div>
</syslink-modal>