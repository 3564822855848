import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ModalComponent } from '../../../modal/modal.component';

@Component({
  selector: 'syslink-translations-modal',
  templateUrl: './translations-modal.component.html',
  styleUrls: ['./translations-modal.component.scss']
})
export class TranslationsModalComponent {
  @Input() public translations: any[] = [];
  @Input() public languages: any[] = [];

  @Output() onValidated: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('modal') public modal: ModalComponent = new ModalComponent();

  public open(translations: any[], NameTranslationId: any): void {
    this.translations = [];

    const incommingTranslations: any[] = JSON.parse(JSON.stringify(translations));

    this.languages.forEach((language: any) => {
      const translation: any | undefined = incommingTranslations.find((translation: any) => translation.LanguageId.Id === language.Id);

      this.translations.push(translation || {
        LanguageId: language,
        Value: undefined
      });
    });

    this.modal.open({ NameTranslationId, translations: this.translations });
  }

  public onTranslationModalValidate() {
    let translatableString = this.modal.data.NameTranslationId;
    translatableString.Translations = this.translations;
    translatableString.Translations = this.modal.data.translations.filter((e: any) => e.Value != undefined);

    this.onValidated.emit(translatableString.Translations);
    this.modal.close();
  }
}
