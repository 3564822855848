import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DocumentDataComponent } from 'projects/erp-app/src/app/base/documents/document-datas/document-data.component';
import { DocumentData } from 'projects/erp-app/src/app/base/documents/document-datas/document-data.model';
import { ThirdsService } from 'projects/erp-app/src/app/thirds/thirds/thirds.service';
import { SaleDocumentLine } from '../../sale-document-lines/sale-document-line.model';
import { SaleDocumentLinesService } from '../../sale-document-lines/sale-document-lines.service';
import { SaleDocument } from '../sale-document.model';
import { SaleDocumentsService } from '../sale-documents.service';
import { getNew } from 'projects/libraries/syslink-components/src/lib/helpers/tools';
import { WorkTimesService } from 'projects/erp-app/src/app/time-management/work-times/work-times/work-times.service';
import { TasksService } from 'projects/erp-app/src/app/time-management/tasks/tasks/tasks.service';
import { SaleDocumentStatus } from '../../sale-document-statuses/sale-document-status.model';
import { PageComponent } from 'projects/libraries/syslink-components/src/public-api';
@Component({
  selector: 'app-sale-document-third',
  templateUrl: './sale-document-third.component.html',
  styleUrls: ['./sale-document-third.component.scss']
})
export class SaleDocumentThirdComponent<
  TSaleDocument extends SaleDocument,
  TSaleDocumentService extends SaleDocumentsService<SaleDocument, SaleDocumentStatus>,
  TSaleDocumentLine extends SaleDocumentLine,
  TSaleDocumentLineService extends SaleDocumentLinesService<SaleDocumentLine>
> extends PageComponent implements OnInit {
  @Input() public element: TSaleDocument = <TSaleDocument>getNew<TSaleDocument>();
  @Output() public elementChange: EventEmitter<TSaleDocument> = new EventEmitter<TSaleDocument>();
  @Output() documentData: EventEmitter<any> = new EventEmitter<any>();
  @Input() public reportType: String = '';
  @Input() public disabled: boolean = false;

  @ViewChild('ThirdDocumentData') private ThirdDocumentData?: DocumentDataComponent;
  @ViewChild('IntermediateThirdDocumentData') private IntermediateThirdDocumentData?: DocumentDataComponent;

  public thirdDocumentDataFilters: string | string[] = ["Roles/any(r:r/Code eq 'customer')"];
  public IntermediateThirdDocumentDataFilters: string | string[] = ["Roles/any(r:r/Code eq 'customer')"];

  public canUpdateThird: boolean = true;

  constructor(
    public thirdsService: ThirdsService,
    private workTimesService: WorkTimesService,
    private tasksService: TasksService
  ) {
    super();
  }
  override async ngOnInit(): Promise<void> {
    this.loadDocumentDataFilter();
    if (this.reportType == "SaleInvoice" && this.element.Lines) {
      await this.loadCanChangeThird();
    }
  }

  public loadDocumentDataFilter() {
    if (this.element.ThirdId) {
      this.IntermediateThirdDocumentDataFilters = ["Roles/any(r:r/Code eq 'customer') and Id ne " + this.element.ThirdId.Id?.toString()];
      this.IntermediateThirdDocumentData?.ThirdSelect?.selectComponent?.instance.getDataSource().filter(this.IntermediateThirdDocumentDataFilters);
      this.IntermediateThirdDocumentData?.ThirdSelect?.selectComponent?.instance.getDataSource().reload();
    }
    if (this.element.IntermediateThirdId) {
      this.thirdDocumentDataFilters = ["Roles/any(r:r/Code eq 'customer') and Id ne " + this.element.IntermediateThirdId.Id?.toString()];
      this.ThirdDocumentData?.ThirdSelect?.selectComponent?.instance.getDataSource().filter(this.thirdDocumentDataFilters);
      this.ThirdDocumentData?.ThirdSelect?.selectComponent?.instance.getDataSource().reload();
    }
  }
  public documentDataThirdChange(e: any) {
    this.element.DocumentDataCollection[this.element.ThirdDocumentDataIndex] = e;
    this.element.ThirdId = this.element.DocumentDataCollection[this.element.ThirdDocumentDataIndex].ThirdId;

    if (this.element.ThirdId.CustomerId != undefined) {
      switch (this.reportType) {
        case 'SaleQuote':
          if (this.element.ThirdId.CustomerId.SaleQuoteDocumentDelayId != undefined)
            this.element.DocumentDelayId = this.element.ThirdId.CustomerId?.SaleQuoteDocumentDelayId;
          break;
        case 'SaleInvoice':
          if (this.element.ThirdId.CustomerId.SaleInvoiceDocumentDelayId != undefined)
            this.element.DocumentDelayId = this.element.ThirdId.CustomerId?.SaleInvoiceDocumentDelayId;
          break;
        case 'SaleCreditNote':
          if (this.element.ThirdId.CustomerId.SaleCreditNoteDocumentDelayId != undefined)
            this.element.DocumentDelayId = this.element.ThirdId.CustomerId?.SaleCreditNoteDocumentDelayId;
          break;
      }
    }
    this.documentData.emit();
    this.loadDocumentDataFilter();
  }

  public documentDataIntermediateThirdChange(e: any) {
    if (this.element.IntermediateThirdIdDocumentDataIndex == undefined || this.element.IntermediateThirdIdDocumentDataIndex < 0) {
      var thirdDocument = this.element.DocumentDataCollection[this.element.ThirdDocumentDataIndex];
      e.UsedForQuote = !thirdDocument.UsedForQuote;
      e.UsedForOrder = !thirdDocument.UsedForOrder;
      e.UsedForInvoice = !thirdDocument.UsedForInvoice;
      e.UsedForContract = !thirdDocument.UsedForContract;
      this.element.DocumentDataCollection.push(e);
    }
    else {
      this.element.DocumentDataCollection[this.element.IntermediateThirdIdDocumentDataIndex] = e;
    }
    this.element.IntermediateThirdId = e.ThirdId;
    this.documentData.emit();
    this.loadDocumentDataFilter();
  }
  public documentDataUsedChange(e: any) {
    if (!e.element.Id) return;
    this.element.DocumentDataCollection.forEach((document: DocumentData) => {
      switch (e.type) {
        case "UsedForQuote":
          document.UsedForQuote = document.ThirdId.Id != e.element.ThirdId.Id ? !e.used : e.used;
          break;
        case "UsedForOrder":
          document.UsedForOrder = document.ThirdId.Id != e.element.ThirdId.Id ? !e.used : e.used;
          break;
        case "UsedForInvoice":
          document.UsedForInvoice = document.ThirdId.Id != e.element.ThirdId.Id ? !e.used : e.used;
          break;
        case "UsedForContract":
          document.UsedForContract = document.ThirdId.Id != e.element.ThirdId.Id ? !e.used : e.used;
          break;
      }
    });
    this.documentData.emit();
  }
  public async loadCanChangeThird() {
    if (!this.element.Lines || this.element.Lines.length < 1) return;
    var workTimes = await this.workTimesService.load({ select: ['Id'], expand: [], filter: ['SaleInvoiceLineId.Id  in (' + Array.from(this.element.Lines.map(l => l.Id)).join(',') + ')'] });
    var tasks = await this.tasksService.load({ select: ['Id'], expand: [], filter: ['SaleInvoiceLineId.Id  in (' + Array.from(this.element.Lines.map(l => l.Id)).join(',') + ')'] });
    if (workTimes.length > 0 || tasks.length > 0) {
      this.canUpdateThird = false;
      return;
    }
    this.canUpdateThird = true;
  }
}
