<div class="form">
    <div class="row">
        <div class="col-6">
            <syslink-select label="Payment method" [store]="paymentMethodsService.store"
                [(model)]="element.PaymentMethodId" [filter]="['IsVisible eq true']"></syslink-select>
        </div>
        <div class="col-6">
            <syslink-select label="Price list" [store]="priceListsService.store"
                [(model)]="element.PriceListId"></syslink-select>
        </div>
    </div>
    <div class="row">
        <div class="col-6">
            <syslink-input-number label="Hourly Rate" [(model)]="element.HourlyRate"></syslink-input-number>
        </div>
        <div class="col-6">
            <syslink-select label="Sale tax rate" [store]="taxRatesService.store" [filter]="['IsVisible eq true']"
                [(model)]="element.SaleTaxRateId"></syslink-select>
        </div>
    </div>
    <div class="row">
        <div class="col-6">
            <syslink-select label="Sale quotes DocumentDelay" [store]="documentDelaysService.store"
                [(model)]="element.SaleQuoteDocumentDelayId"></syslink-select>
        </div>
        <div class="col-6">
            <syslink-select label="Sale invoices DocumentDelay" [store]="documentDelaysService.store"
                [(model)]="element.SaleInvoiceDocumentDelayId"></syslink-select>
        </div>
    </div>
    <div class="row">
        <div class="col-6">
            <syslink-select label="Sale credit notes DocumentDelay" [store]="documentDelaysService.store"
                [(model)]="element.SaleCreditNoteDocumentDelayId"></syslink-select>
        </div>
    </div>
</div>