import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DocumentDataComponent } from 'projects/erp-app/src/app/base/documents/document-datas/document-data.component';
import { DocumentData } from 'projects/erp-app/src/app/base/documents/document-datas/document-data.model';
import { ThirdsService } from 'projects/erp-app/src/app/thirds/thirds/thirds.service';
import { PurchaseDocumentLine } from '../../purchase-document-lines/purchase-document-line.model';
import { PurchaseDocumentLinesService } from '../../purchase-document-lines/purchase-document-lines.service';
import { PurchaseDocument } from '../purchase-document.model';
import { PurchaseDocumentsService } from '../purchase-documents.service';
import { getNew } from 'projects/libraries/syslink-components/src/lib/helpers/tools';
import { PurchaseDocumentStatus } from '../../purchase-document-statuses/purchase-document-status.model';
import { PageComponent } from 'projects/libraries/syslink-components/src/public-api';
@Component({
  selector: 'app-purchase-document-third',
  templateUrl: './purchase-document-third.component.html',
  styleUrls: ['./purchase-document-third.component.scss']
})
export class PurchaseDocumentThirdComponent<
  TPurchaseDocument extends PurchaseDocument,
  TPurchaseDocumentService extends PurchaseDocumentsService<PurchaseDocument, PurchaseDocumentStatus>,
  TPurchaseDocumentLine extends PurchaseDocumentLine,
  TPurchaseDocumentLineService extends PurchaseDocumentLinesService<PurchaseDocumentLine>
> extends PageComponent implements OnInit {
  @Input() public element: TPurchaseDocument = <TPurchaseDocument>getNew<TPurchaseDocument>();
  @Output() public elementChange: EventEmitter<TPurchaseDocument> = new EventEmitter<TPurchaseDocument>();
  @Output() documentData: EventEmitter<any> = new EventEmitter<any>();
  @Input() public reportType: String = '';
  @Input() public disabled: boolean = false;

  @ViewChild('ThirdDocumentData') private ThirdDocumentData?: DocumentDataComponent;
  @ViewChild('IntermediateThirdDocumentData') private IntermediateThirdDocumentData?: DocumentDataComponent;

  public thirdDocumentDataFilters: string | string[] = ["Roles/any(r:r/Code eq 'supplier')"];
  public IntermediateThirdDocumentDataFilters: string | string[] = ["Roles/any(r:r/Code eq 'supplier')"];

  constructor(
    public thirdsService: ThirdsService
  ) {
    super();
  }
  override async ngOnInit(): Promise<void> {
    this.loadDocumentDataFilter();
  }

  public loadDocumentDataFilter() {
    if (this.element.ThirdId) {
      this.IntermediateThirdDocumentDataFilters = ["Roles/any(r:r/Code eq 'supplier') and Id ne " + this.element.ThirdId.Id?.toString()];
      this.IntermediateThirdDocumentData?.ThirdSelect?.selectComponent?.instance.getDataSource().filter(this.IntermediateThirdDocumentDataFilters);
      this.IntermediateThirdDocumentData?.ThirdSelect?.selectComponent?.instance.getDataSource().reload();
    }
    if (this.element.IntermediateThirdId) {
      this.thirdDocumentDataFilters = ["Roles/any(r:r/Code eq 'supplier') and Id ne " + this.element.IntermediateThirdId.Id?.toString()];
      this.ThirdDocumentData?.ThirdSelect?.selectComponent?.instance.getDataSource().filter(this.thirdDocumentDataFilters);
      this.ThirdDocumentData?.ThirdSelect?.selectComponent?.instance.getDataSource().reload();
    }
  }
  public documentDataThirdChange(e: any) {
    this.element.DocumentDataCollection[this.element.ThirdDocumentDataIndex] = e;
    this.element.ThirdId = this.element.DocumentDataCollection[this.element.ThirdDocumentDataIndex].ThirdId;

    if (this.element.ThirdId.SupplierId != undefined) {
      switch (this.reportType) {
        case 'PurchaseInvoice':
          if (this.element.ThirdId.SupplierId.PurchaseInvoiceDocumentDelayId != undefined)
            this.element.DocumentDelayId = this.element.ThirdId.SupplierId?.PurchaseInvoiceDocumentDelayId;
          break;
        case 'PurchaseCreditNote':
          if (this.element.ThirdId.SupplierId.PurchaseCreditNoteDocumentDelayId != undefined)
            this.element.DocumentDelayId = this.element.ThirdId.SupplierId?.PurchaseCreditNoteDocumentDelayId;
          break;
      }
    }
    this.documentData.emit();
    this.loadDocumentDataFilter();
  }
  public documentDataIntermediateThirdChange(e: any) {
    if (this.element.IntermediateThirdIdDocumentDataIndex == undefined || this.element.IntermediateThirdIdDocumentDataIndex < 0) {
      var thirdDocument = this.element.DocumentDataCollection[this.element.ThirdDocumentDataIndex];
      e.UsedForOrder = !thirdDocument.UsedForOrder;
      e.UsedForInvoice = !thirdDocument.UsedForInvoice;
      this.element.DocumentDataCollection.push(e);
    }
    else {
      this.element.DocumentDataCollection[this.element.IntermediateThirdIdDocumentDataIndex] = e;
    }
    this.element.IntermediateThirdId = e.ThirdId;
    this.documentData.emit();
    this.loadDocumentDataFilter();
  }
  public documentDataUsedChange(e: any) {
    if (!e.element.Id) return;
    this.element.DocumentDataCollection.forEach((document: DocumentData) => {
      switch (e.type) {
        case "UsedForOrder":
          document.UsedForOrder = document.ThirdId.Id != e.element.ThirdId.Id ? !e.used : e.used;
          break;
        case "UsedForInvoice":
          document.UsedForInvoice = document.ThirdId.Id != e.element.ThirdId.Id ? !e.used : e.used;
          break;
      }
    });
    this.documentData.emit();
  }
}
