<table class="table table-hover">
    <thead>
        <tr>
            <th></th>
            <ng-container *ngFor="let item of items">
                <th class="text-end">{{creditsafesService.gridGetYear(item.ReportingYearEnd)}}</th>
            </ng-container>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let list of dataList">
            <th>{{list | translate}}</th>

            <ng-container *ngFor="let item of items; let index = index">                
                <td class="text-end">{{creditsafesService.getDataValue(list, item) || '-'}}</td>
            </ng-container>
        </tr>
    </tbody>
</table>