import { DeliveryTime } from "../../base/delivery-times/delivery-time.model";
import { DocumentDelay } from "../../base/documents/document-delays/document-delay";
import { TaxRate } from "../../base/tax-rates/tax-rate";
import { Entity } from "../../core/models/entity";
import { Third } from "../thirds/third.model";

export class Supplier extends Entity {
    public ThirdId: Third = new Third();
    public PurchaseTaxRateId: TaxRate = new TaxRate();
    public DeliveryTimeId: DeliveryTime = new DeliveryTime();
    public PurchaseInvoiceDocumentDelayId?: DocumentDelay;
    public PurchaseCreditNoteDocumentDelayId?: DocumentDelay;

    constructor(supplier?: Partial<Supplier>) {
        super();
        Object.assign(this, supplier);
    }
}
