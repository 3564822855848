import { Location } from "@angular/common";
import { ActivatedRoute, NavigationExtras, Router } from "@angular/router";

/**
 * Simple object check.
 * @param item
 * @returns {boolean}
 */
function isObject(item: any): boolean {
  return item && typeof item === 'object' && !Array.isArray(item);
}
/**
 * Deep merge two objects.
 * @param target
 * @param ...sources
 */
export function merge(target: any, ...sources: any): any {
  if (!sources.length)
    return target;
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key])
          Object.assign(target, { [key]: {} });
        merge(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }

  return merge(target, ...sources);
}


export function fromDotNotation(obj: any, key: string) {
  return key.split('.').reduce((previous: any, current: any, index: number) => {
    return previous[current];
  }, obj);
}

export function jsonToOdataFormat(source: any) {
  let result: any = {};
  Object.entries(source).forEach((keyValuePair: [string, any]) => {
    const key = keyValuePair[0];
    let value = keyValuePair[1];
    if (value != null && Array.isArray(value)) {
      value=value.map(e=>jsonToOdataFormat(e));
    } else if (value != null && typeof value === 'object') {
      value = jsonToOdataFormat(value);
    }
    result[capitalize(key)] = value;
  });
  return result;
}

export function capitalize(source: string): string {
  return source.charAt(0).toUpperCase() + source.slice(1);
}

export function rtrim(source: string, replacement: string): string {
  return source.replace(new RegExp(replacement + "*$"), '');
};

export function getNew<T>(type?: any, ...args: any): T | undefined {
  return type ? new type(...args) : undefined;
}

export function goToUrl(router: Router, activatedRoute: ActivatedRoute, location: Location, path: string, relative: boolean = true, reload: boolean = true, newTab: boolean = false, queryParams?: any) {
  let extras: NavigationExtras = {};
  extras.relativeTo = relative ? activatedRoute : undefined;
  extras.queryParams = queryParams;

  if (reload) {
    if (newTab) {
      let newRelativeUrl = router.createUrlTree([path], extras);
      let baseUrl = window.location.href.replace(router.url, '');
      window.open(baseUrl + newRelativeUrl, '_blank');
    }
    else {
      router.navigate([path], extras);
    }

  } else {
    const url = router.createUrlTree([path], extras).toString()
    location.go(url);
  }
}