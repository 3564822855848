import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { AuthService } from '../../core/auth/auth.service';
import { environment } from 'projects/erp-app/src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SignalrService {

  private static connection?: signalR.HubConnection;
  public url: string = environment.base_url + '/paymentHub';

  constructor() {
    if(!AuthService.getToken()){
      //console.log("no token available");
    }

    if (!SignalrService.connection && AuthService.getToken())
      //Initialise la connexion
      SignalrService.connection = new signalR.HubConnectionBuilder()
        .withUrl(this.url,
          {
            accessTokenFactory: () => AuthService.getToken()!,
            // skipNegotiation: true,
            // transport: signalR.HttpTransportType.WebSockets
          })
        .withAutomaticReconnect()
        .build();

  }

  connect(): void {
    //Commence la connexion
    if (SignalrService.connection)
      SignalrService.connection.start()
        .then(() => {

          //Rejoins le groupe
          if (SignalrService.connection)
            SignalrService.connection.invoke("JoinGroup").then(() => {
              //Ecoute si il y a une nouvelle connexion dans le même groupe
              if (SignalrService.connection)
                SignalrService.connection.on("NewConnection", (message: any) => {
                  //console.log(message);
                })
            });
        }).catch((err: any) => {
          //console.log(err)
        });
  }



  disconnect(): void {
    if (SignalrService.connection)
      SignalrService.connection.stop()
        .catch((err: any) =>{
          //console.log(err)
        });
  }

  getConnectionId(): string | null {
    return SignalrService.connection ? SignalrService.connection.connectionId : null;
  }

  //Ecoute un message
  listenMessage(methodNameReceiver: string): void {    
    if (SignalrService.connection)
      SignalrService.connection.on(methodNameReceiver, (message: string) => {
        //console.log(`Reçu : ${methodNameReceiver} = ${message}`);
      })
  }

  //Ecoute un message et redirige l'onglet courrant
  listenRedirection(methodNameReceiver: string): void {
    if (SignalrService.connection)
      SignalrService.connection.on(methodNameReceiver, (url: string) => {
        location.replace(url)
      })
  }


  //Envoie un message à un client spécifique
  // sendMessage(methodNameApi: string, methodNameReceiver: string, clientReceiverId: string, message: string): void {
  //   console.log(`envoyé à ${clientReceiverId} = ${methodNameApi} ${methodNameReceiver} : ${message} `);
  //   this.connection.send(methodNameApi, methodNameReceiver, clientReceiverId, message)
  //     .catch(error => console.log(error));
  // }


  //Envoie un message à tous les fils
  // sendMessageToAllSons(methodNameApi: string, methodNameReceiver: string, message: string): void{
  //   this.sonConnectionIds.forEach(sonConnectionId => {
  //     console.log(`envoyé à ${sonConnectionId} = ${methodNameApi} ${methodNameReceiver} : ${message} `);
  //   });
  //   this.connection.send(methodNameApi, methodNameReceiver, this.sonConnectionIds, message)
  //       .catch(error => console.log(error));
  // }

  //Ecoute un message et ajoute l'envoyeur comme un de ses fils
  // updateSons(methodNameReceiver: string): void {
  //   this.connection.on(methodNameReceiver, (clientId: string) => {
  //     console.log(`recu IdFils = ${clientId}`);
  //     if (!this.sonConnectionIds.includes(clientId)) {
  //       this.sonConnectionIds.push(clientId);
  //     }
  //   })
  // }


}
