<div class="{{class}}" [hidden]="hidden">
    <!-- Besoin de trouver une meilleur solution d'affichage de label  =>  Placeholder à la place -->
    <label *ngIf="labelMode !== 'hidden'" class="mb-2">{{ getLabel()|translate }}</label>
    <dx-html-editor
        #htmlEditor
        class="mt-2"
        [style.minHeight]="minHeight"

        [id]="name"
        [height]="height"
        [width]="width"
        [stylingMode]="mode"
        [readOnly]="disabled"
        [allowSoftLineBreak]="allowSoftLineBreak"

        [placeholder]="getLabel() | translate"

        [value]="model"
        (onValueChanged)="onValueChanged($event)"
    >
        <!-- Toolbar -->
        <ng-container *ngIf="toolbar">
            <dxo-toolbar [multiline]="multilineToolbar">
                <dxi-item name="undo"></dxi-item>
                <dxi-item name="redo"></dxi-item>

                <dxi-item name="separator"></dxi-item>

                <dxi-item name="size" [acceptedValues]="sizeOptions"></dxi-item>
                <dxi-item name="font" [acceptedValues]="fontOptions"></dxi-item>

                <dxi-item name="separator"></dxi-item>

                <dxi-item name="bold"></dxi-item>
                <dxi-item name="italic"></dxi-item>
                <dxi-item name="strike"></dxi-item>
                <dxi-item name="underline"></dxi-item>

                <dxi-item name="separator"></dxi-item>

                <dxi-item name="alignLeft"></dxi-item>
                <dxi-item name="alignCenter"></dxi-item>
                <dxi-item name="alignRight"></dxi-item>
                <dxi-item name="alignJustify"></dxi-item>

                <dxi-item name="separator"></dxi-item>

                <dxi-item name="orderedList"></dxi-item>
                <dxi-item name="bulletList"></dxi-item>

                <dxi-item name="separator"></dxi-item>

                <dxi-item name="header" [acceptedValues]="headerOptions"></dxi-item>

                <dxi-item name="separator"></dxi-item>

                <dxi-item name="color"></dxi-item>
                <dxi-item name="background"></dxi-item>

                <dxi-item name="separator"></dxi-item>

                <dxi-item name="link"></dxi-item>
                <dxi-item *ngIf="image" name="image"></dxi-item>

                <dxi-item name="separator"></dxi-item>

                <dxi-item name="clear"></dxi-item>
                <dxi-item name="codeBlock"></dxi-item>
                <dxi-item name="blockquote"></dxi-item>

                <ng-container *ngIf="table">
                    <dxi-item name="separator"></dxi-item>

                    <dxi-item name="insertTable"></dxi-item>
                    <dxi-item name="deleteTable"></dxi-item>
                    <dxi-item name="insertRowAbove"></dxi-item>
                    <dxi-item name="insertRowBelow"></dxi-item>
                    <dxi-item name="deleteRow"></dxi-item>
                    <dxi-item name="insertColumnLeft"></dxi-item>
                    <dxi-item name="insertColumnRight"></dxi-item>
                    <dxi-item name="deleteColumn"></dxi-item>
                </ng-container>
            </dxo-toolbar>

            <!-- Image upload -->
            <ng-container *ngIf="image">
                <dxo-media-resizing [enabled]="true"></dxo-media-resizing>
                <dxo-image-upload [tabs]="imageCurrentTab" [fileUploadMode]="fileUploadMode"></dxo-image-upload>
            </ng-container>

            <!-- Table -->
            <ng-container *ngIf="table">
                <dxo-table-context-menu [enabled]="true"></dxo-table-context-menu>
                <dxo-table-resizing [enabled]="true"></dxo-table-resizing>
            </ng-container>
        </ng-container>

        <!-- Mention -->
        <!-- <dxi-mention *ngIf="mention" [valueExpr]="mention.valueExpr" [displayExpr]="mention.displayExpr" [searchExpr]="mention.searchExpr" [dataSource]="mention.dataSource"></dxi-mention> -->
    </dx-html-editor>
</div>