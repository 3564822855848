import { Injectable } from '@angular/core';
import { LoadOptions } from 'devextreme/data';
import { ApiService } from '../../../core/api.service';
import { StockMoveDocumentLinesService } from '../../stock-move-documents/stock-move-document-lines/stock-move-document-lines.service';
import { StockMoveOutgoingLine } from './stock-move-outgoing-line.model';

@Injectable({
  providedIn: 'root'
})
export class StockMoveOutgoingLinesService extends StockMoveDocumentLinesService<StockMoveOutgoingLine> {
  public override url: string = 'StockMoveOutgoingLine';
  public override documentType: string = 'StockMoveOutgoing';
  public override defaultOptions: LoadOptions = {
    expand: ['Discount', 'ParentId']
  };

  constructor(public override api: ApiService) {
    super(api);
  }

  public override async getInstance(outgoing?: Partial<StockMoveOutgoingLine>): Promise<any> {
    let element = {
      ...outgoing,
    };
    return element;
  }

  public async updateParentId(StockMoveOutgoingLine: StockMoveOutgoingLine, targetParentId: number) {
    return await this.api.sendRequest('/api/StockMoveDocumentLine/' + StockMoveOutgoingLine.Id + '/changeParent/' + targetParentId, 'POST');
  }
}
