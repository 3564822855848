<dx-data-grid
  #grid
  *ngIf="canView"
  [attr.id]="gridName"
  [remoteOperations]="items ? false : true"
  (onRowDblClick)="onRowDblClick($event)"
  (onInitNewRow)="onPrepareRow.emit($event)"
  (onRowInserting)="beforeAdd.emit($event)"
  (onRowInserted)="afterAdd.emit($event)"
  (onRowUpdating)="beforeUpdate.emit($event)"
  (onRowUpdated)="afterUpdate.emit($event)"
  (onRowRemoving)="beforeDelete.emit($event)"
  (onRowRemoved)="afterDelete.emit($event)"
  (onExporting)="onExporting($event)"
  (onOptionChanged)="onOptionChanged($event)"
  [(selectedRowKeys)]="selectedKeys"
  (onInitialized)="onInitialize($event)"
  [columnAutoWidth]="true"
  (onContextMenuPreparing)="onContextMenuPreparing($event)"
>
  <dxo-state-storing
    *ngIf="stateStoringEnabled && storageKey"
    [enabled]="true"
    type="localstorage"
    [storageKey]="storageKey"
    [savingTimeout]="100"
  ></dxo-state-storing>

  <ng-container *ngFor="let column of columns; let columnIndex = index">
    <dxi-column
      *ngIf="showColumn(column)"
      [dataField]="column.field"
      [name]="column.name"
      [dataType]="column.type"
      [sortIndex]="column.sortIndex"
      [sortOrder]="column.sortDirection"
      [calculateSortValue]="
        column.type === 'object'
          ? column.field + '.' + getColumn(column.field)?.data?.displayKey
          : column.field
      "
      [calculateFilterExpression]="
        column.type === 'collection'
          ? calculateCollectionFilterExpression(column) : 
          column.type === 'object' ? calculateObjectFilterExpression : undefined
      "
      [caption]="column.label ?? column.field | translate"
      headerCellTemplate="headerCellTemplate"
      [cellTemplate]="column.cellTemplate"
      [editCellTemplate]="column.editCellTemplate"
      [allowEditing]="column.editable"
      [allowSorting]="canSort && column.sortable"
      [allowFiltering]="canFilter && column.filterable"
      [allowHeaderFiltering]="canFilter && column.headerFilterable"
      [width]="column.width"
      [visible]="column.visible"
      [showEditorAlways]="
        column.editCellTemplate === 'boolean-cell'
          ? true
          : column.showEditorAlways
      "
    >
      <!-- HEADER Template -->
      <ng-container *dxTemplate="let header of 'headerCellTemplate'">
        <span
          [id]="'headerColumn_' + (header.column.dataField).replaceAll('.', '_')"
          [title]="header.column.title || ''"
        >{{
          header.column.caption
          }}</span>
      </ng-container>

      <!-- CELL Templates -->
      <ng-container *dxTemplate="let cell of 'currency-cell'">
        <div class="text-end">
          {{
          cell.value
          | syslinkCurrency
          : getColumn(cell.column.dataField)?.data?.subModuleCode
          : getColumn(cell.column.dataField)?.data?.key
          }}
        </div>
      </ng-container>

      <ng-container *dxTemplate="let cell of 'date-cell'">
        <div
          class="text-end"
          *ngIf="cell.value.getFullYear()>1"
        >
          {{ cell.value | date : "dd/MM/yyyy" }}
        </div>
      </ng-container>
      <ng-container *dxTemplate="let cell of 'datetime-cell'">
        <div
          class="text-end"
          *ngIf="cell.value.getFullYear()>1"
        >
          {{ cell.value | date : "dd/MM/yyyy HH:mm" }}
        </div>
      </ng-container>

      <ng-container *dxTemplate="let cell of 'text-cell'">
        {{ cell.value }}
      </ng-container>

      <ng-container *dxTemplate="let cell of 'number-cell'">
        <syslink-input-number [(model)]="cell.value"></syslink-input-number>
      </ng-container>

      <ng-container *dxTemplate="let cell of 'link-cell'">
        <a href="{{ getColumn(cell.column.dataField)?.data.link }}{{
            cell.value
          }}">{{ getColumn(cell.column.dataField)?.data.text ?? cell.value }}</a>
      </ng-container>

      <ng-container *dxTemplate="let cell of 'html-cell'">
        <dx-html-editor
          class="border-none"
          [readOnly]="true"
          [(value)]="cell.value"
          [elementAttr]="{ class: 'custom-html-editor' }"
        ></dx-html-editor>
      </ng-container>

      <ng-container *dxTemplate="let cell of 'statusText-cell'">
        <app-status-text-cell
          [cell]="cell"
          [getStatusLabel]="
            getColumn(cell.column.dataField)?.data.getStatusLabel
          "
          [getStatusDate]="getColumn(cell.column.dataField)?.data.getStatusDate"
          [getStatusClass]="
            getColumn(cell.column.dataField)?.data.getStatusClass
          "
          [getStatusColor]="
            getColumn(cell.column.dataField)?.data.getStatusColor
          "
        ></app-status-text-cell>
      </ng-container>

      <ng-container *dxTemplate="let cell of 'select-value'">
        <ng-container>
          {{
          fromDotNotation(
          cell,
          getColumn(cell.column.dataField)?.data.displayKey
          )
          }}
        </ng-container>
      </ng-container>

      <ng-container *dxTemplate="let cell of 'discount-cell'">
        {{
        cell.data[getColumn(cell.column.dataField)?.data.discountTypeField]
        ? cell.value
        : cell.value * 100
        }}
        {{
        cell.data[getColumn(cell.column.dataField)?.data.discountTypeField]
        ? "€"
        : "%"
        }}
      </ng-container>

      <ng-container *dxTemplate="let cell of 'object-discount-cell'">
        <ng-container *ngIf="isNewObjectDiscountCell(cell)">
          {{
          fromDotNotation(
          cell,
          getColumn(cell.column.dataField)?.data.discountTypeField
          )
          ? cell.value.Value
          : cell.value.Value * 100
          }}
          {{
          fromDotNotation(
          cell,
          getColumn(cell.column.dataField)?.data.discountTypeField
          )
          ? "€"
          : "%"
          }}
        </ng-container>
      </ng-container>

      <!-- EDIT Templates -->
      <ng-container *dxTemplate="let cell of 'translations-cell'">
        <syslink-edit-translations-cell
          (modelUpdate)="cell.setValue($event)"
          (onTranslationsBtnClicked)="
            onTranslationsBtnClicked(
              cell.data['NameTranslationId'].Translations,
              cell
            )
          "
          [model]="cell.value"
          [canShowIcon]="cell.data.Id"
        ></syslink-edit-translations-cell>
      </ng-container>

      <ng-container *dxTemplate="let cell of 'boolean-cell'">
        <syslink-boolean-cell
          [cell]="cell"
          [data]="getColumn(cell.column.dataField)?.data"
          [getColor]="getColumn(cell.column.dataField)?.data.getColor"
          [getText]="getColumn(cell.column.dataField)?.data.getText"
        ></syslink-boolean-cell>
      </ng-container>

      <ng-container *dxTemplate="let cell of 'icon-cell'">
        <syslink-icon-cell
          [cell]="cell"
          [data]="getColumn(cell.column.dataField)?.data"
        ></syslink-icon-cell>
      </ng-container>

      <ng-container *dxTemplate="let cell of 'time-cell'">
        <syslink-time-cell
          [cell]="cell"
          [data]="getColumn(cell.column.dataField)?.data"
        ></syslink-time-cell>
      </ng-container>

      <ng-container *dxTemplate="let cell of 'day-cell'">
        <syslink-day-cell
          [cell]="cell"
          [data]="getColumn(cell.column.dataField)?.data"
        ></syslink-day-cell>
      </ng-container>

      <ng-container *dxTemplate="let cell of 'edit-day-cell'">
        <syslink-select-day
          [model]="cell.value"
          (modelChange)="cell.setValue($event)"
        ></syslink-select-day>
      </ng-container>

      <ng-container *dxTemplate="let cell of 'select-cell'">
        <syslink-select
          class=""
          [store]="getColumn(cell.column.dataField)?.data.service.store"
          [expand]="getColumn(cell.column.dataField)?.data.expand"
          [select]="getColumn(cell.column.dataField)?.data.select"
          [filter]="getColumn(cell.column.dataField)?.data.filter"
          [sort]="getColumn(cell.column.dataField)?.data.sort"
          [(model)]="cell.value"
          (modelChange)="cell.setValue($event)"
          [displayKey]="getColumn(cell.column.dataField)?.data.displayKey"
          [allowClearing]="true"
        ></syslink-select>
      </ng-container>

      <ng-container *dxTemplate="let cell of 'select-enum-cell'">
        <syslink-select
          class=""
          labelMode="hidden"
          [displayKey]="'name'"
          valueKey="name"
          [items]="getColumn(cell.column.dataField)?.data.enum"
          [model]="cell.value"
          (modelChange)="cell.setValue($event)"
        ></syslink-select>
      </ng-container>

      <ng-container *dxTemplate="let cell of 'edit-html-cell'">
        <syslink-wysiwyg
          *ngIf="cell.data.lineType !== 'post'"
          labelMode="hidden"
          height="150px"
          [table]="false"
          [image]="false"
          class="bg-white"
          [multilineToolbar]="false"
          [model]="cell.value"
          (modelChange)="cell.setValue($event)"
        ></syslink-wysiwyg>
        <syslink-input-text
          *ngIf="cell.data.lineType === 'post'"
          class=""
          [model]="cell.value"
          (modelChange)="cell.setValue($event)"
        ></syslink-input-text>
      </ng-container>

      <!-- TODO: amélioration #3382 -->
      <!-- <ng-container *dxTemplate="let cell of 'select-enum-cell-done'">
        <syslink-select
          class=""
          labelMode="hidden"
          [displayKey]="'name'"
          valueKey="name"
          [items]="generateItemFromEnum(getColumn(cell.column.dataField)?.data.enum)"
          [model]="cell.value"
          (modelChange)="cell.setValue($event)"
        ></syslink-select>
      </ng-container> -->

      <ng-container *dxTemplate="let cell of 'rrule-cell'">
        <div class="text-end capitalize-text">
          {{ getRruleText(cell.value) }}
        </div>
      </ng-container>

      <ng-container *dxTemplate="let cell of 'edit-discount-cell'">
        <div class="d-flex">
          <syslink-input-number
            class=""
            [min]="0"
            [max]="
              cell.data[getColumn(cell.column.dataField)?.data.discountTypeField]
                ? undefined
                : 100
            "
            labelMode="hidden"
            [model]="
              cell.data[getColumn(cell.column.dataField)?.data.discountTypeField]
                ? cell.value
                : cell.value * 10000 / 100
            "
            (modelChange)="
              onDiscountCellUpdated(
                $event,
                cell,
                cell.data[
                  getColumn(cell.column.dataField)?.data.discountTypeField
                ]
              )
            "
          ></syslink-input-number>

          <syslink-select
            class=""
            labelMode="hidden"
            [displayKey]="'name'"
            valueKey="id"
            [items]="[
              { id: 0, name: '%' },
              { id: 1, name: '€' }
            ]"
            [model]="
              cell.data[getColumn(cell.column.dataField)?.data.discountTypeField]
                ? 1
                : 0
            "
            (modelChange)="
              onDiscountTypeChange(
                $event,
                cell,
                getColumn(cell.column.dataField)?.data.discountTypeField
              )
            "
          ></syslink-select>
        </div>
      </ng-container>

      <ng-container *dxTemplate="let cell of 'edit-object-discount-cell'">
        <div class="d-flex">
          <ng-container>
            <syslink-input-number
              class=""
              labelMode="hidden"
              [min]="0"
              format="#0.#####%"
              [max]="
                cell.data[
                  getColumn(cell.column.dataField)?.data.discountTypeField
                ]
                  ? undefined
                  : 100
              "
              [model]="
                fromDotNotation(
                  cell,
                  getColumn(cell.column.dataField)?.data.discountTypeField
                )
                  ? cell.value.Value
                  : cell.value.Value
              "
              (modelChange)="
                onObjectDiscountCellUpdated(
                  $event,
                  cell,
                  fromDotNotation(
                    cell,
                    getColumn(cell.column.dataField)?.data.discountTypeField
                  )
                )
              "
            ></syslink-input-number>

            <syslink-select
              class=""
              labelMode="hidden"
              [displayKey]="'name'"
              valueKey="id"
              [items]="[
                { id: 0, name: '%' },
                { id: 1, name: '€' }
              ]"
              [model]="
                fromDotNotation(
                  cell,
                  getColumn(cell.column.dataField)?.data.discountTypeField
                )
                  ? 1
                  : 0
              "
              (modelChange)="
                onObjectDiscountTypeChange(
                  $event,
                  cell,
                  getColumn(cell.column.dataField)?.data.discountTypeField
                )
              "
            ></syslink-select>
          </ng-container>
        </div>
      </ng-container>

      <ng-container *dxTemplate="let cell of 'duration-cell'">
        <syslink-duration-cell
          [cell]="cell"
          [data]="getColumn(cell.column.dataField)?.data"
        ></syslink-duration-cell>
      </ng-container>
      <ng-container *dxTemplate="let cell of 'badge-list-cell'">
        <syslink-badge-list-cell
          [cell]="cell"
          [data]="getColumn(cell.column.dataField)?.data"
        ></syslink-badge-list-cell>
      </ng-container>
      <ng-container *dxTemplate="let cell of 'colored-date-cell'">
        <syslink-colored-date-cell
          [cell]="cell"
          [data]="getColumn(cell.column.dataField)?.data"
        ></syslink-colored-date-cell>
      </ng-container>

      <ng-container *dxTemplate="let cell of 'edit-time-cell'">
        <syslink-input-date
          [(model)]="cell.value"
          [type]="'time'"
          [format]="'HH:mm'"
          [interval]="15"
          (modelChange)="cell.setValue($event)"
        ></syslink-input-date>
      </ng-container>

      <!-- CUSTOM Template -->
      <ng-container *dxTemplate="let itemData of 'template-cell'; let itemIndex = index">
        <div *ngIf="
            getColumn(itemData.column.dataField)?.data.template ==
            'TemplateDeadline'
          ">
          <div *ngIf="
              getColumn(itemData.column.dataField)?.data.getAmountRemaining(
                itemData
              ) == '0'
            ">
            <div class="text-end">
              {{ itemData.value | date : "dd/MM/yyyy" }}
            </div>
          </div>
          <div *ngIf="
              getColumn(itemData.column.dataField)?.data.getAmountRemaining(
                itemData
              ) != '0' &&
              getColumn(itemData.column.dataField)?.data.getCellOverdue(
                itemData
              ) > 0
            ">
            <div class="text-end TemplateDeadlineRed fw-bold">
              {{ itemData.value | date : "dd/MM/yyyy" }}
              {{ " - " }}
              {{
              getColumn(itemData.column.dataField)?.data.getCellOverdue(
              itemData
              )
              }}
              {{ "day(s)" | translate }}
            </div>
          </div>
          <div *ngIf="
              getColumn(itemData.column.dataField)?.data.getAmountRemaining(
                itemData
              ) != '0' &&
              getColumn(itemData.column.dataField)?.data.getCellOverdue(
                itemData
              ) <= 0
            ">
            <div class="text-end TemplateDeadlineOrange fw-bold">
              {{ itemData.value | date : "dd/MM/yyyy" }}
            </div>
          </div>
        </div>
        <!-- <ng-template [ngTemplateOutlet]="column.template" [ngTemplateOutletContext]="{ itemData: itemData, itemIndex: itemIndex }"></ng-template> -->
        <div *ngIf="
        getColumn(itemData.column.dataField)?.data.template ==
        'colored-currency-cell'
      ">
          <div
            *ngIf="
          getColumn(itemData.column.dataField)?.data.showValue(itemData)
        "
            class="text-{{getColumn(itemData.column.dataField)?.data.getColor(itemData)}}"
          >
            {{ itemData.value | syslinkCurrency }}
          </div>
        </div>
      </ng-container>
    </dxi-column>
  </ng-container>
  <dxi-column
    *ngIf="options.editing?.allowDeleting"
    [id]="'headerColumn_editing'"
    type="buttons"
  ></dxi-column>

  <!-- Archives -->
  <dxi-column
    *ngIf="isInArchivedView"
    cellTemplate="cellTemplate"
    width="50px"
  >
    <ng-container *dxTemplate="let cell of 'cellTemplate'">
      <div class="w-100 d-flex justify-content-center align-items-center">
        <i
          class="fa-solid fa-clock-rotate-left"
          role="button"
          (click)="confirmRestore.data = cell.data.Id; confirmRestore.open()"
        ></i>
      </div>
    </ng-container>
  </dxi-column>
</dx-data-grid>

<syslink-translations-modal
  #translationsCellModal
  (onValidated)="onTranslationsModalValidated($event)"
  [languages]="languages"
></syslink-translations-modal>

<!-- Archives -->
<syslink-confirm-modal
  #confirmRestore
  title="GRID.ARCHIVED.CONFIRM.TITLE"
  content="GRID.ARCHIVED.CONFIRM.CONTENT"
  (onValidate)="onRestoreValidate()"
  validateText="Confirm"
  cancelText="Cancel"
></syslink-confirm-modal>