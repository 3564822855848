<syslink-grid
  #grid
  [canView]="canView"
  [canSearch]="canSearch"
  [canRefresh]="canRefresh"
  [canAdd]="canAdd"
  [canSort]="canSort"
  [canFilter]="canFilter"
  [canExport]="canExport"
  [canDblClck]="canDblClck"
  [canDelete]="canDelete"
  [canUpdate]="canUpdate"
  (add)="add.emit($event)"
  [columns]="columns"
  [service]="timetableLinesService"
  [filter]="filter"
  [expand]="expand"
  (beforeUpdate)="onBeforeUpdate($event)"
></syslink-grid>