import { Component } from '@angular/core';
import { SignalrService } from '../../../connectors/signalr/signalr.service';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent {

  public checkout: string = "";

  constructor(
    private signalrService: SignalrService
  ) {
    this.signalrService.connect();
    this.signalrService.listenRedirection("NewPayment");
    //this.signalrService.listenRedirection("NewRefund");

  }


}
