import { Component, Input, ViewChild } from '@angular/core';
import { SyslinkColumn } from 'projects/libraries/syslink-components/src/lib/helpers/SyslinkColumn';
import { Third } from '../../../third.model';
import { ViewComponent } from 'projects/libraries/syslink-components/src/lib/helpers/view/view.component';
import { PurchaseInvoicesService } from 'projects/erp-app/src/app/purchases/purchase-invoices/purchase-invoices/purchase-invoices.service';
import { PurchaseInvoiceGridComponent } from 'projects/erp-app/src/app/purchases/purchase-invoices/purchase-invoice-grid/purchase-invoice-grid.component';

@Component({
  selector: 'app-third-document-relation-purchase-invoices',
  templateUrl: './third-document-relation-purchase-invoices.component.html',
  styleUrls: ['./third-document-relation-purchase-invoices.component.scss']
})
export class ThirdDocumentRelationPurchaseInvoicesComponent extends ViewComponent  {
  @Input() public element: Third = new Third();
  
  constructor(
    private purchaseInvoicesService: PurchaseInvoicesService,
  ){
    super();
  }

  @ViewChild('invoiceGridComponent') purchaseInvoiceGridComponent: PurchaseInvoiceGridComponent = new PurchaseInvoiceGridComponent(this.purchaseInvoicesService);
  public PurchaseInvoiceColumns: SyslinkColumn[] = [
    new SyslinkColumn({ field: "No", label: "GRID.CAPTION.NO", width: 150, sortIndex: 2, sortDirection: 'desc' }),
    new SyslinkColumn({ field: "Subject", label: "Subject" }),
    new SyslinkColumn({ field: "CurrentStatusLink.StatusId.Name", label: "Status", cellTemplate: 'statusText-cell', data: { getStatusLabel: this.purchaseInvoiceGridComponent.getStatusCellLabel, getStatusDate: this.purchaseInvoiceGridComponent.getStatusCellDate, getStatusClass: this.purchaseInvoiceGridComponent.getStatusCellClass, getStatusColor: this.purchaseInvoiceGridComponent.getStatusCellColor }, width: 120 }),
    new SyslinkColumn({ field: "Deadline", label: 'PurchaseInvoice.Validity', type: "date", cellTemplate: "template-cell", width: 80, data: { template: "TemplateDeadline", currentDate: new Date(), getAmountRemaining: this.purchaseInvoiceGridComponent.getAmountRemainingCellLabel, getCellOverdue: this.purchaseInvoiceGridComponent.getCellOverdue } }),
    new SyslinkColumn({ field: "ExternalReference", label: "External reference", visible: true, width: 150 }),
    new SyslinkColumn({ field: "ExTaxTotal", label: "exTaxTotal", type: "number", cellTemplate: 'currency-cell', data: { subModuleCode: 'Purchases.Invoice', key: 'Precision.PurchaseInvoiceHeader.ExTaxTotal' }, width: 80 }),
    new SyslinkColumn({ field: "InTaxTotal", label: "Total", type: "number", cellTemplate: 'currency-cell', data: { subModuleCode: 'Purchases.Invoice', key: 'Precision.PurchaseInvoiceHeader.InTaxTotal' }, width: 80 }),
    new SyslinkColumn({ field: "ResponsibleUserId.Fullname", label: "Responsible", width: 120 }),
    new SyslinkColumn({ field: "Date", label: "Date", type: "date", visible: false, width: 150 }),
    new SyslinkColumn({ field: "AmountRemaining", label: 'Remaining amount', type: "number", visible: false, cellTemplate: 'currency-cell', data: { subModuleCode: 'Purchases.Invoice', key: 'Precision.PurchaseInvoiceHeader.AmountRemaining' }, width: 80, filterable: false, headerFilterable: false, sortable: false }),
    new SyslinkColumn({ field: "FormattedStructuredCommunication", label: 'Structured communication', visible: false, editable: false }),
    new SyslinkColumn({ field: "CurrentStatusLink.StatusId.Sequence", label: 'status order', sortIndex: 1, sortDirection: 'asc', width: 0, visible: false, filterable: false, headerFilterable: false, sortable: false })
  ];
  onAddButtonClicked() {
    window.open('/purchases/invoices/new?ThirdId='+this.element.Id);
  }
 
}