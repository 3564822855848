import { Component, ViewChild } from '@angular/core';
import { ConfirmModalComponent, ModalComponent, NotificationsService, PageComponent, SyslinkToolbarAction, SyslinkToolbarActionButton } from 'projects/libraries/syslink-components/src/public-api';
import { SaleInvoicesService } from './sale-invoices.service';
import { SaleInvoice } from './sale-invoice.model';
import { ContextMenuItemAction } from 'projects/libraries/syslink-components/src/lib/context-menus/context-menu-item-action';
import { ContextMenuItemClickEvent } from 'devextreme/ui/file_manager';
import { SaleInvoiceGridComponent } from '../sale-invoice-grid/sale-invoice-grid.component';
import { SaleInvoiceStatusSelectModalComponent } from '../sale-invoice-status/sale-invoice-status-select-modal/sale-invoice-status-select-modal.component';
import { SaleInvoiceHeaderStatus } from '../sale-invoice-header-statuses/sale-invoice-header-status.model';
import { SaleInvoiceHeaderHeaderStatusesService } from '../sale-invoice-header-header-statuses/sale-invoice-header-header-statuses.service';
import { SaleInvoiceHeaderHeaderStatus } from '../sale-invoice-header-header-statuses/sale-invoice-header-header-status.model';
import { PaymentsComponent } from '../../../base/payments/payments.component';
import { Payment } from '../../../base/payments/payment.model';
import { SaleContexMenuItemActionCode } from '../../sale-documents/sale-documents/sale-documents.service';
import { ReportsService } from '../../../connectors/reports/reports.service';
import { DocumentCreateModalComponent } from '../../../base/documents/document-create-modal/document-create-modal.component';
import { jsonToOdataFormat } from 'projects/libraries/syslink-components/src/lib/helpers/tools';

@Component({
  selector: 'app-sale-invoices',
  templateUrl: './sale-invoices.component.html',
  styleUrls: ['./sale-invoices.component.scss']
})
export class SaleInvoicesComponent extends PageComponent {

  @ViewChild('createModal') createModal?: DocumentCreateModalComponent;
  @ViewChild('grid') grid?: SaleInvoiceGridComponent;
  @ViewChild("confirmModal") confirmModal?: ConfirmModalComponent;
  @ViewChild("statusModal") statusModal?: SaleInvoiceStatusSelectModalComponent;
  @ViewChild("paymentModal") paymentModal?: PaymentsComponent;
  @ViewChild("globalReportModal") globalReportModal?: ModalComponent;

  // ContextMenuItemAction
  // ---------------------
  public contextMenuItems: ContextMenuItemAction[] = [
    { code: SaleContexMenuItemActionCode.PrintToPDF, text: "Preview to PDF", icon: "print", onItemClick: (e: any) => this.onMenuItemClicked(e), visible: this.authService.hasPermission('sales.invoices.list.contextMenu.preview') },
    { code: SaleContexMenuItemActionCode.PrintToZIP, text: "Download to ZIP", icon: "print", onItemClick: (e: any) => this.onMenuItemClicked(e), visible: this.authService.hasPermission('sales.invoices.list.contextMenu.preview') },
    { code: SaleContexMenuItemActionCode.Status, text: "Change status", icon: "edit", onItemClick: (e: any) => this.onMenuItemClicked(e), visible: this.authService.hasPermission('sales.invoices.list.contextMenu.changeStatus') },
    { code: SaleContexMenuItemActionCode.payment, text: "Add payment", icon: "money", onItemClick: (e: any) => this.onMenuItemClicked(e), visible: this.authService.hasPermission('sales.invoices.list.contextMenu.addPayment') }
  ];

  public customerFilter: string | string[] = ["Roles/any(r:r/Code eq 'customer')"];

  // Payment
  // -------
  public paymentElement: Payment[] = [];
  public paymentDocument?: SaleInvoice;

  public override toolbarActions: SyslinkToolbarAction[] = [
    new SyslinkToolbarActionButton({ hint: this.translateService.instant('Preview global report'), icon: 'chart', onClick: () => { this.globalReportModalOpen() }, visible: this.authService.hasPermission('sales.invoices.list.toolbarAction.previewGlobalReport') })
  ];
  public globalResult?: { InTaxTotal: number, ExTaxTotal: number, AmountRemaining: number, ExTaxUnvalidatedTotalAmount: number, InTaxUnvalidatedTotalAmount: number, InTaxOverdueAmount: number };

  constructor(
    private saleInvoicesService: SaleInvoicesService,
    private saleInvoiceHeaderHeaderStatusesService: SaleInvoiceHeaderHeaderStatusesService,
    private reportsService: ReportsService
  ) {
    super();
  }

  public onAddButtonClicked() {
    this.createModal?.open();
  };

  public onCreateValidated(element: any) {
    if (!element.ThirdId.Id) {
      NotificationsService.sendErrorMessage("Please select third");
      return;
    }
    if (element.ThirdId.Blocked == true) {
      NotificationsService.sendErrorMessage("Third is blocked");
      return;
    }
    
    this.router.navigateByUrl('/sales/invoices/new?ThirdId=' + element.ThirdId.Id + (element.Subject != undefined ? '&Subject=' + element.Subject : '')+(element.NumberPlate != undefined ? '&NumberPlate=' + element.NumberPlate : ''));
  }

  // ContextMenu
  // -----------
  public async onMenuItemClicked(e: ContextMenuItemClickEvent) {

    if (!this.grid) return;

    switch (e.itemData.code) {
      // Printing
      // ---------
      case SaleContexMenuItemActionCode.PrintToPDF:
        this.showConfirmModal("Would you like preview selected rows?", e.itemData);
        break;
      case SaleContexMenuItemActionCode.PrintToZIP:
        this.showConfirmModal("Would you like download selected rows?", e.itemData);
        break;
      case SaleContexMenuItemActionCode.Status:
        this.showStatusModal("Would you like change status selected rows?", e.itemData);
        break;
      case SaleContexMenuItemActionCode.payment:
        await this.showPaymentModal("Would you like add payment", e.itemData);
        break;
    }
  }

  private showConfirmModal(text: string, data: ContextMenuItemAction) {
    if (!this.confirmModal) return;

    this.confirmModal.title = data.text ? data.text : "Confirm";
    this.confirmModal.content = text;
    this.confirmModal.open({ rows: this.grid?.getSelectedRows(data.rowData), action: data.code });
  }
  private showStatusModal(text: string, data: ContextMenuItemAction) {
    if (!this.statusModal) return;

    this.statusModal.title = data.text ? data.text : "Confirm";
    this.statusModal.content = text;
    this.statusModal.open({ rows: this.grid?.getSelectedRows(data.rowData), action: data.code });
  }
  private async showPaymentModal(text: string, data: ContextMenuItemAction) {
    if (this.grid?.getSelectedRows(data.rowData) && this.grid?.getSelectedRows(data.rowData)?.length > 1) {
      NotificationsService.sendErrorMessage("Please select only one line");
      return;
    }
    this.paymentDocument = await this.saleInvoicesService.findByID(this.grid?.getSelectedRows(data.rowData)[0].Id, { expand: this.saleInvoicesService.defaultOptions.expand })
    // TODO add better method
    // Wait component is loaded
    setTimeout(async () => {
      if (!this.paymentModal || !this.paymentDocument) return;
      this.paymentElement = this.paymentDocument.Payments;
      await this.paymentModal.onAddPaymentClicked();
    }, 100);
  }

  public async onConfirmModalValidated(modalData: { rows: SaleInvoice[], action: string } | any) {
    switch (modalData.action) {
      // Invoicing
      // ---------
      case SaleContexMenuItemActionCode.PrintToPDF:
        await this.saleInvoicesService.printRows(modalData.rows, "pdf", "SaleInvoice");
        break;
      case SaleContexMenuItemActionCode.PrintToZIP:
        await this.saleInvoicesService.printRows(modalData.rows, "zip", "SaleInvoice");
        break;
      case SaleContexMenuItemActionCode.Status:
        await this.updateStatus(modalData.rows, modalData.InvoiceStatus);
        break;
    }

    this.grid?.refresh();
    this.confirmModal?.close();
    this.statusModal?.close();
  }
  // ------------------------------------------------------------------------------------------------

  // Actions
  // -------
  private async updateStatus(selectedRows: SaleInvoice[], status: SaleInvoiceHeaderStatus) {
    if (!this.saleInvoicesService.canUpdateStatus(selectedRows, status)) return;
    selectedRows.sort((a, b) => { return a.Date.getTime() - b.Date.getTime(); });

    for (var element of selectedRows) {
      if (element.Id) {
        const link: SaleInvoiceHeaderHeaderStatus = {
          HeaderId: element,
          StatusId: { Id: status.Id },
          Date: new Date()
        };
        await this.updateDocumentStatus(link);
      }
    }
    NotificationsService.sendSuccess('Status updated');
  }
  private async updateDocumentStatus(link: any) {
    let documentStatus = await this.saleInvoiceHeaderHeaderStatusesService.insert(this.saleInvoiceHeaderHeaderStatusesService.format(link));
  }

  // Global report
  // -------------
  public globalReportModalOpen() {
    if (!this.globalReportModal) return;
    const currentDate = new Date();
    this.globalReportModal.open({ maxDate: currentDate.getFullYear(), date: currentDate.getFullYear(), byMonth: true });
  }

  public previewSaleGlobalReportPreview() {
    this.reportsService.previewSaleGlobalReportPreview(this.globalReportModal?.data.date, this.globalReportModal?.data.byMonth);
    this.globalReportModal?.close();
  }

  // ------------------------------------------------------------------------------------------------

  public async onFilterValueChange(e: any[]) {
    var request = await this.saleInvoicesService.getStatistics(e);
    if(!request){
      this.globalResult = undefined;
      return;
    }
    this.globalResult = jsonToOdataFormat(request);
  }
}