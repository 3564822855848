<syslink-modal
    #modal
    [title]="title"
    [data]="modal.data"
    (onValidate)="onValidateButtonClicked($event)"
    width="300px"
>
    <div class="row">
        <div class="col-12">
            <syslink-select-day
                name="Day"
                [(model)]="modal.data.Day"
            ></syslink-select-day>
        </div>

        <div class="col-12">
            <syslink-input-date
                name="Start"
                label="Start"
                [(model)]="modal.data.StartHour"
                type="time"
                format="HH:mm"
                [interval]="15"   
            ></syslink-input-date>
        </div>

        <div class="col-12">
            <syslink-input-date
                name="End"
                label="End"
                [(model)]="modal.data.EndHour"
                type="time"
                format="HH:mm"
                [interval]="15"   
            ></syslink-input-date>
        </div>

        <div class="col-12">
            <syslink-input-date
                name="Date"
                [(model)]="modal.data.Date"
                type="date"
                [showClearButton]="true"
            ></syslink-input-date>
        </div>
    </div>
</syslink-modal>