import { Injectable } from '@angular/core';
import { ODataService } from 'projects/erp-app/src/app/core/services/oData.service';
import { ThirdCommunicationLabel } from '../third-communication-labels/third-communication-label.model';
import { ThirdCommunicationType } from '../third-communication-types/third-communication-type.model';
import { ThirdCommunicationMethod } from './third-communication-method.model';

@Injectable({
  providedIn: 'root'
})
export class ThirdCommunicationMethodsService extends ODataService<ThirdCommunicationMethod> {
  public url = 'CommunicationMethod';

  public async getCommunicationsByThird(thirdId: number): Promise<ThirdCommunicationMethod[]> {
    let options = {
      ...this.defaultOptions,
      filter: "ThirdId.Id eq " + thirdId,
      expand: ["CommunicationLabelId", "CommunicationTypeId", "ThirdId"]
    };
    return await this.load(options);
  }
  public override async getInstance(params?: Partial<ThirdCommunicationMethod>): Promise<any> {
    return new ThirdCommunicationMethod({
      CommunicationLabelId: new ThirdCommunicationLabel({ Id: 1 }),
      CommunicationTypeId: new ThirdCommunicationType({ Id: 1 }),
      ...params
    });
  };

  public override format(element: Partial<ThirdCommunicationMethod>): any {
    var result: any = {
      ...element,
      CommunicationLabelId: element.CommunicationLabelId?.Id,
      CommunicationTypeId: element.CommunicationTypeId?.Id,
      ThirdId: element.ThirdId?.Id,
    };

    return result;
  }

}
