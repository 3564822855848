import { Injectable } from '@angular/core';
import { ODataService } from '../../../core/services/oData.service';
import { LoadOptions } from 'devextreme/data';
import { TranslateService } from '@ngx-translate/core'
import { TaskStatus } from './task-status.model';

@Injectable({
  providedIn: 'root'
})
export class TaskStatusesService extends ODataService<TaskStatus> {
  public url = 'TaskStatus';
  public override defaultOptions: LoadOptions = {
    expand: ['NameTranslationId.Translations.LanguageId']
  };

  constructor(
    private translateService: TranslateService
  ) {
    super();
  }

  public override format(element: Partial<TaskStatus>): TaskStatus {
    var result: any = {
      ...element,
    };
    delete result.Tasks;
    return result;
  }

  public override async getInstance(params?: Partial<TaskStatus>): Promise<TaskStatus> {
    var element = new TaskStatus({
      Name: this.translateService.instant('New task status'),
      ...params
    });
    return element;
  }
}
