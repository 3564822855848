import { BasicEntity } from "../../../core/models/basic-entity";

export class DocumentDelay extends BasicEntity {
    public Value?: number;
    public EndMonth?: boolean;

    constructor(documentDelay?: Partial<DocumentDelay>) {
        super();
        Object.assign(this, documentDelay);
    }
}