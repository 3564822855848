import { Component, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { ModalComponent } from '../modal.component';

@Component({
  selector: 'syslink-confirm-modal',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmModalComponent {
  @Input() public title: string = '';
  @Input() public content: string = '';
  @Input() public cancelText: string = 'Cancel';
  @Input() public validateText: string = 'Confirm';
  @Input() public width: string = '300px';
  @Input() public height: string = 'auto';

  @Output() public onValidate: EventEmitter<any> = new EventEmitter<any>()
  @Output() public onCancel: EventEmitter<any> = new EventEmitter<any>()

  @ViewChild('modal') public modal: ModalComponent = new ModalComponent();
  
  public data: any;

  public open(data: any = null): void {
    this.modal.data = data;
    this.modal.open(data);
  }

  public close(): void {
    this.modal.close();
  }

  public onModalValidate(data:any): void {
    if (this.onValidate.observed) {
      this.onValidate.emit(data);
    } else {
      this.modal.close();
    }
  }

  public onModalCancel(): void {
    if (this.onCancel.observed) {
      this.onCancel.emit(this.modal.data);
    } else {
      this.modal.close();
    }
  }

}
