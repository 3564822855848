import { Injectable } from '@angular/core';
import { Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { ConfigurationsService } from '../../base/modules/configurations/configurations.service';

@Injectable({
  providedIn: 'root'
})
export class FetchConfigurationResolver implements Resolve<any> {
  constructor(
    private configurationsService: ConfigurationsService,
  ) { }

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    let { subModules } = route.data;
    if (!subModules) return false;

    if (!Array.isArray(subModules)) subModules = [subModules];

    const configurations: any = {};
    
    for (const subModule of subModules) {
      configurations[subModule] = await this.configurationsService.getConfigurations(subModule);
    }

    this.configurationsService.setConfigurations(configurations);

    return true;
  }
}
