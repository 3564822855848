<syslink-title text="Thirds"></syslink-title>

<app-third-grid
storageKey="grid-thirds"
[permissionKey]="'list'"
(add)="onAddButtonClicked()"
(rowDoubleClicked)="onRowDoublelick($event)"
[canUpdate]="false"
[canDelete]="false"
></app-third-grid>
