<syslink-title text="{{ 'Sale quotes' }}"></syslink-title>

<ng-container *ngIf="globalResult">
  <div class="row gap-2">
    <div class="offset-4 col-1 rounded text-center border border-dark">
      <div class="h-100 d-flex flex-column justify-content-between">
        <p class="h6 fw-bold my-2"> {{'Total amount' | translate}}</p>
        <div>
          <p class="my-1">{{globalResult.ExTaxTotal | currency}} H.T.V.A</p>
          <p class="my-1">{{globalResult.InTaxTotal | currency}} T.T.C</p>
        </div>
      </div>
    </div>
    <div class="col-1 rounded bg-gray text-center border border-secondary">
      <div class="h-100 d-flex flex-column justify-content-between">
        <p class="h6 fw-bold my-2"> {{'Draft amount' | translate}}</p>
        <div>
          <p class="my-1">{{globalResult.DraftExTaxTotal | currency}} H.T.V.A</p>
          <p class="my-1">{{globalResult.DraftInTaxTotal | currency}} T.T.C</p>
        </div>
      </div>
    </div>
    <div class="col-1 rounded bg-accent text-center border border-secondary">
      <div class="h-100 d-flex flex-column justify-content-between">
        <p class="h6 fw-bold my-2"> {{'Transmitted amount' | translate}}</p>
        <div>
          <p class="my-1">{{globalResult.TransmittedExTaxTotal | currency}} H.T.V.A</p>
          <p class="my-1">{{globalResult.TransmittedInTaxTotal | currency}} T.T.C</p>
        </div>
      </div>
    </div>
    <div class="col-1 rounded text-center border border-secondary bg-success">
      <div class="h-100 d-flex flex-column justify-content-between">
        <p class="h6 fw-bold my-2"> {{'Confirmed amount' | translate}}</p>
        <div>
          <p class="my-1">{{globalResult.ConfirmedExTaxTotal | currency}} H.T.V.A</p>
          <p class="my-1">{{globalResult.ConfirmedInTaxTotal | currency}} T.T.C</p>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<app-sale-quote-grid
  [permissionKey]="'list'"
  [canUpdate]="false"
  (add)="onAddButtonClicked()"
  [items]="items"
  [expand]="expand"
  (onFilterValueChange)="onFilterValueChange($event)"
>
</app-sale-quote-grid>
<app-document-create-modal
  #initModal
  title="Create Quote"
  [thirdFilter]="customerFilter"
  detailsUrl="/thirds/customers/"
  (validate)="onCreateValidated($event)"
></app-document-create-modal>
