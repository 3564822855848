
export class MolliePayment {
    public Amount?: number;
    public IdMethod?: string;
    public TerminalId?: string;
    public Description?: string;
    public PaymentId?: number;

    //-------------------------------------------------------

    constructor(entity?: Partial<MolliePayment>) {
        Object.assign(this, entity);
    }
}
