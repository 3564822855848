import { Injectable } from '@angular/core';
import { ApiService } from '../../core/api.service';
import { jsonToOdataFormat } from 'projects/libraries/syslink-components/src/lib/helpers/tools';

@Injectable({
  providedIn: 'root'
})
export class CreditsafesService {
  public url: string = '/api/third/informations';

  constructor(
    private api: ApiService
  ) { }

  public async getThirdStatisticsByVat(vat: string) {
    try {
      var result = await this.api.sendRequest(this.url + '/statistic/' + vat, 'POST');
      result.Data = JSON.parse(result.Data);
      return jsonToOdataFormat(result.Data)
    } catch (e: any) {
      return undefined;
    }
  }

  public gridGetYear(e: string) {
    const specificDate = new Date(e);
    return specificDate.getFullYear();
  }

  public getDataValue(data: string, e: any) {
    var temp = data.split('.');
    if (temp.length > 1) {
      return (e[temp[0]])[temp[1]];
    }
    else if (temp.length = 1) {
      if (typeof e[data] === "number") {
        return e[data];
      }
      else if (this.isDate(e[data])) {
        return this.formatDate(new Date(e[data]))
      }
      else if (typeof e[data] === 'object') {

      }
      else {
        return e[data];
      }
    }

    return null;
  }

  public async GetCreditSafeRemainingLimitAsync() {
    return await this.api.sendRequest('/api/creditsafe/creditSafeRemainingLimit');
  }

  public formatDate(date: any) {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  }

  private isDate(str: any) {
    return !isNaN(Date.parse(str));
  }

}
