import { Injectable } from '@angular/core';
import { LoadOptions } from 'devextreme/data';
import { StockMoveDocumentsService } from '../../stock-move-documents/stock-move-documents/stock-move-documents.service';
import { StockMoveOutgoing } from './stock-move-outgoing.model';
import { StockMoveOutgoingHeaderStatus } from '../stock-move-outgoing-header-statuses/stock-move-outgoing-header-status.model';

@Injectable({
  providedIn: 'root'
})
export class StockMoveOutgoingsService extends StockMoveDocumentsService<StockMoveOutgoing, StockMoveOutgoingHeaderStatus> {
  public override url: string = 'StockMoveOutgoingHeader';
  
  public override defaultOptions: LoadOptions = {
    expand: [
     'CurrentStatusLink.StatusId',
      'ResponsibleUserId',
      'Lines($orderby=LineNo;$expand=UnitId,Children,ParentId,ToStockLocationId)',
      'Statuses.StatusId',
      'DocumentDataCollection.ThirdId.ThirdContacts',
      'DocumentDataCollection.ThirdId.Addresses',
      'DocumentDataCollection.ContactId',
      'DocumentDataCollection.AddressId',
      'DocumentDataCollection.DeliveryAddressId',
      'ThirdId.ContactInformations.ThirdId',
      'ThirdId.ContactInformations.ContactTypeId',
      'DocumentRelations'
    ]
  };

  public override async getInstance(outgoing?: Partial<StockMoveOutgoing>): Promise<any> {

    let element = {
      Date: new Date(),
      ...outgoing,
    };
    element = await this.refreshResponsibleUser(element);
    // element = await this.refreshThirdDeliveryTimeFromSupplier(element);
    return element;
  }
}

export enum StockMoveOutgoingContextMenuItemActionCode {
  PrintToZIP = 'PrintToZIP',
  PrintToPDF = 'PrintToPDF',
  Status = 'Status'
}