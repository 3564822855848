import { Entity } from "../../../core/models/entity";
import { Third } from "../../../thirds/thirds/third.model";
import { DocumentData } from "../document-datas/document-data.model";
import { DocumentDelay } from "../document-delays/document-delay";
import { DocumentHeaderStatus } from "../document-header-statuses/document-header-status.model";
import { DocumentFinance } from "../document-finances/document-finance.model";
import { DocumentLine } from "../document-lines/document-line.model";
import { Payment } from "../../payments/payment.model";

export class Document extends Entity {
    constructor(entity?: Partial<Document>) {
        super();
        Object.assign(this, entity);
    }

    public Subject?: string;
    public ExternalReference?: string;
    public IsGlobalDiscountFixed?: string;
    public GlobalDiscountValue?: number;
    public GlobalDiscountDescription?: string;
    public Note?: string;
    public NameFormatted?: string;
    public No?: string;
    public ExTaxTotal: number = 0;
    public InTaxTotal: number = 0;
    public Date: Date = new Date();
    public Deadline?: Date;
    public DocumentFinances?: DocumentFinance[];
    public Payments: Payment[]=[];

    public NumberPlate?: string;

    // Third
    // -----
    public ThirdId: Third = new Third();
    public IntermediateThirdId: Third = new Third();
  

    // Responsible User
    // ----------------
    public ResponsibleUserId?: Third;
    public ResponsibleUserPhone?: any;
    
    // Document Delay
    // --------------
    public DocumentDelayId: DocumentDelay = new DocumentDelay();

    public SelectedRow?: number;
    public SelectedRows?: number[];

    public AmountRemaining?: number;
    public AmountReceived?: number;
    public StructuredCommunication?: string;

    // Statuses
    // --------
    public Statuses?: DocumentHeaderStatus[];
    public CurrentStatusLink?: DocumentHeaderStatus;

    // Document Datas
    // --------------
    public DocumentDataCollection: DocumentData[] = [];
    public ThirdDocumentDataIndex: number = -1;
    public IntermediateThirdIdDocumentDataIndex: number = -1;
    public ThirdDocumentData?: DocumentData;
    public IntermediateThirdIdDocumentData?: DocumentData;

    // For documentRelation
    // --------------------
    public DocumentType?: string;

    // Lines
    // -----
    public Lines?: DocumentLine[] = [];
}
