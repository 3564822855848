<div class="row">
    <div class="col-sm-3">
        <syslink-input-text
            name="ExternalReference"
            label="External reference"
            [(model)]="element.ExternalReference"
            [disabled]="disabled"
        ></syslink-input-text>
    </div>

    <div class="col-sm-3" *ngIf="reportType!='PurchaseOrder'">
        <syslink-select
            name="DocumentDelay"
            [label]="reportType + '.DocumentDelay' | translate"
            [store]="documentDelaysService.store"
            [(model)]="element.DocumentDelayId"
            [disabled]="disabled"
            (modelChange)="documentDelayChanged.emit($event)"
        ></syslink-select>
    </div>

    <div class="col-sm-3" *ngIf="reportType==='PurchaseOrder'">
        <syslink-select
            name="DeliveryTime"
            label="Deliverytime"
            [store]="deliveryTimesService.store"
            [(model)]="element.DeliveryTimeId"
            [disabled]="disabled"
        ></syslink-select>
    </div>

    <div class="col-sm-3">
        <syslink-input-number
            name="GlobalDiscountValue"
            label="Global discount value(%)"
            [(model)]="element.GlobalDiscountValue"
            [disabled]="disabled"
            (focusOut)="globalDiscountChanged.emit($event)"
            [min]="0"
            [max]="100"
        ></syslink-input-number>
    </div>
</div>

<div class="row">
    <div class="col-12">
        <syslink-wysiwyg
            name="htmlEditor-Note"
            label="Note"
            [(model)]="element.Note"
            [table]="false"
            [multilineToolbar]="true"
            height="200"
            [disabled]="disabled"
        ></syslink-wysiwyg>
    </div>
</div>