import { Component } from '@angular/core';
import { TimetableLinesService } from '../timetable-lines.service';
import { BaseGridComponent } from 'projects/libraries/syslink-components/src/lib/grid/base-grid/base-grid.component';
import { TimetableLine } from '../timetable-line';
import { SyslinkColumn } from 'projects/libraries/syslink-components/src/lib/helpers/SyslinkColumn';
import { ColoredDateCellData } from 'projects/libraries/syslink-components/src/public-api';

@Component({
  selector: 'app-timetable-line-grid',
  templateUrl: './timetable-line-grid.component.html',
  styleUrls: ['./timetable-line-grid.component.scss']
})
export class TimetableLineGridComponent extends BaseGridComponent<TimetableLine> {

  public override columns: SyslinkColumn[] = [
    new SyslinkColumn({ field: "Day", cellTemplate: 'day-cell', editCellTemplate: 'edit-day-cell', sortDirection: 'asc' }),
    new SyslinkColumn({ field: "StartHour", label: "Start", type: "datetime", cellTemplate: 'time-cell', editCellTemplate: 'edit-time-cell' }),
    new SyslinkColumn({ field: "EndHour", label: "End", type: "datetime", cellTemplate: 'time-cell', editCellTemplate: 'edit-time-cell' }),
    new SyslinkColumn({ field: "Duration", label: "Duration", type: "number", cellTemplate: 'duration-cell', width: 100, editable: false }),
    new SyslinkColumn({ field: "Date", label: "Date",type: "date", cellTemplate: 'colored-date-cell', data: new ColoredDateCellData({ getColor: this.getEndDateColor, getFontWeight: this.getEndDateFontWeight }) })
  ];
  public override detailsUrl: string = "";
  override storageKey?: string | undefined;

  constructor(
    public timetableLinesService: TimetableLinesService
  ) {
    super();
    this.expand = this.timetableLinesService.defaultOptions.expand;
  }

  // Date Cell
  // ---------
  getEndDateColor() {
    return "#000000";
  }
  getEndDateFontWeight() {
    return '';
  }

  public onBeforeUpdate(e: any) {
    e.cancel = new Promise<void>(async (resolve, reject) => {
      const element: TimetableLine = {
        ...e.oldData,
        ...e.newData,
      };
      if (!this.timetableLinesService.isValid(element)) reject();

      resolve();
    });
  }

}
