import { Entity } from "../../../core/models/entity";
import { CustomerCatalog } from "../customer-catalog.model";

export class CustomerCatalogDiscountOperations extends Entity {

    constructor(params?: Partial<CustomerCatalogDiscountOperations>) {
        super(params);
        Object.assign(this, params);
    }

    public Value?: number;
    public IsDiscountFixed?: boolean;
    public Order?: number;
    public RealValue?: number;
    public DiscountOperationId?: number;
    public CustomerCatalogId?: CustomerCatalog;
}
