import { Injectable } from '@angular/core';
import { LoadOptions } from 'devextreme/data';
import { ApiService } from '../../../core/api.service';
import { SaleDocumentLinesService } from '../../sale-documents/sale-document-lines/sale-document-lines.service';
import { SaleCreditNoteLine } from './sale-credit-note-line.model';

@Injectable({
  providedIn: 'root'
})
export class SaleCreditNoteLinesService extends SaleDocumentLinesService<SaleCreditNoteLine> {
  public override url: string = 'SaleCreditNoteLine';
  public override documentType: string = 'SaleCreditNote';
  public override defaultOptions: LoadOptions = {
    expand: ['Margin', 'Discount', 'ParentId']
  };

  constructor(public override api: ApiService) {
    super(api);
  }

  public override async getInstance(quote?: Partial<SaleCreditNoteLine>): Promise<any> {
    let element = {
      ...quote,
    };
    return element;
  }

  public async updateParentId(saleCreditNoteLine: SaleCreditNoteLine, targetParentId: number) {
    return await this.api.sendRequest('/api/SaleDocumentLine/' + saleCreditNoteLine.Id + '/changeParent/' + targetParentId, 'POST');
  }
}
