<syslink-title [text]="currentVersion"></syslink-title>
<div *ngFor="let versionDetail of versionDetails">
    <div *ngIf="versionDetail.Version == currentVersion">
        <h3
            *ngIf="versionDetail.Title"
            class="fw-bold"
        >{{ versionDetail.Title | translate }}</h3>
        {{versionDetail.Description}}
    </div>
</div>

<br />

<syslink-title [text]="'Other version(s)'"></syslink-title>
<div *ngFor="let version of versions">
    <div
        *ngIf="version != currentVersion"
        class="p-1"
    >
        <syslink-button
            name="version"
            [text]="version"
            (click)="changeVersionDetail(version)"
        ></syslink-button>
    </div>
</div>