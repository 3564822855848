import { Injectable } from '@angular/core';
import { ODataService } from 'projects/erp-app/src/app/core/services/oData.service';
import { RelationType } from 'projects/erp-app/src/app/thirds/thirds/third-relations/third-relation-types/third-relation-type';

@Injectable({
  providedIn: 'root'
})
export class ThirdRelationTypesService extends ODataService<RelationType> {
  public url = 'ThirdRelationType';
}
